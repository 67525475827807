import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styles from './style.module.css';

const ContextMenu: React.FC<{
  buttons: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onClickOutside?: () => void;
}> = ({ buttons, onClickOutside }) => {
  const overlayRef = useRef(null);
  useOnClickOutside(overlayRef, onClickOutside);
  
  return (
    <div ref={overlayRef} className={styles.menu}>
      {buttons}
    </div>
  );
};

export default ContextMenu;
