import { Pagination as AntPagination } from 'antd';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import { SortOrder } from '~/api/leads';
import './style.css';

interface PaginationProps {
  defaultCurrent?: number;
  className?: string;
  total?: number;
  current?: number;
  defaultPageSize?: number;
  disabled?: boolean;
  hideOnSinglePage?: boolean;
  onChange?: (page: number, sort?: SortOrder) => void;
  handleBlur?: (page?: number) => void;
  pageSize?: number;
  totalPages?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  defaultCurrent = 1,
  className,
  total = 1,
  current = 1,
  defaultPageSize = 10,
  disabled,
  hideOnSinglePage,
  onChange,
  handleBlur,
  pageSize = 10,
  totalPages = 1,
}) => {
  const paginationRef = useRef<HTMLDivElement>();

  function handleInput(this: HTMLInputElement, e: InputEvent) {
    const minValue = 1;
    const value = parseInt(e.data, 10);

    if (value < minValue) {
      this.value = minValue.toString();
    }

    if (value > totalPages) {
      this.value = totalPages.toString();
    }
  }

  function handleKeyPress(this: HTMLInputElement, e: KeyboardEvent) {
    const value = parseInt(e.key, 10);

    if (isNaN(value)) {
      e.preventDefault();
    }
  }

  function onBlur(this: HTMLInputElement) {
    if (this.value === '') {
      this.value = current.toString();
    }

    if (+this.value !== current) {
      handleBlur(+this.value);
    }
  }

  useEffect(() => {
    const input = paginationRef.current.firstElementChild.children[1].firstElementChild as HTMLInputElement;

    if (handleBlur && input) {
      input.addEventListener('blur', onBlur);
      input.addEventListener('keypress', handleKeyPress);
      input.addEventListener('input', handleInput);

      return () => {
        input.removeEventListener('blur', onBlur);
        input.removeEventListener('keypress', handleKeyPress);
        input.removeEventListener('input', handleInput);
      };
    }

    return undefined;
  }, [handleBlur]);

  const handleChange = (page: number) => {
    onChange?.(page);
  };

  return (
    <div
      ref={paginationRef}
      className={cx('pagination', {}, className)}
    >
      <AntPagination
        simple
        defaultCurrent={defaultCurrent}
        total={total || 1}
        current={current}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        disabled={disabled}
        hideOnSinglePage={hideOnSinglePage}
        onChange={handleChange}
      />
    </div>
  );
};

export default Pagination;
