import React from 'react';

const mjml2html = require('mjml-browser');

interface IMailingElementWrapper<T> {
  sectionFunction: (props: T) => string | void;
  sectionProps: T;
}

export function MailingElementWrapper<T>({
  sectionFunction,
  sectionProps,
}: IMailingElementWrapper<T>) {
  const htmlOutput = mjml2html(
    `<mjml>
        <mj-body width='600px'>
          ${sectionFunction(sectionProps)}
        </mj-body>
    </mjml>
  `
  );

  return (
    <div
      className={'mail_section'}
    >
      {!!htmlOutput.errors.length && (
        <div>
          Фрагмент письма сгенерирован с ошибками. Пожалуйста исправьте их.{' '}
          <br />
          {htmlOutput.errors.map((v: { message: string }) => (
            <div key={v.message}>{v.message}</div>
          ))}
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: htmlOutput.html }} />
    </div>
  );
}
