import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Input from '~/components/ui/Input';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import './style.css';

const EntityCount: React.FC<{
  businessRule: BusinessRulesModel;
  index: number;
  maxItemsInRule: number;
}> = observer(({ businessRule, index, maxItemsInRule }) => {
  const [entityCountString, setEntityCountString] = useState<string>(
    `${businessRule.get('recommendationOfWhat').entities[index].itemCount} тов.`
  );

  const handleChangeValue = (value: string) => {
    setEntityCountString(+value ? value : '');
  };

  const conversionValue = (value: string) => {
    const currentValueNumber = +value === 0 ? 1 : +value;
    const allowedBalanceEntityCount =
      maxItemsInRule -
      businessRule
        .get('recommendationOfWhat')
        .entities.reduce((previousValue, item, ind) => {
          return ind === index ? previousValue : previousValue + item.itemCount;
        }, 0);
    const newValueNumber =
      allowedBalanceEntityCount > currentValueNumber
        ? currentValueNumber
        : allowedBalanceEntityCount;

    businessRule.setEntityCount(index, newValueNumber);
    setEntityCountString(`${newValueNumber} тов.`);
  };

  const deconversionValue = () => {
    setEntityCountString(
      businessRule
        .get('recommendationOfWhat')
        .entities[index].itemCount.toString()
    );
  };

  useEffect(() => {
    setEntityCountString(
      `${
        businessRule.get('recommendationOfWhat').entities[index].itemCount
      } тов.`
    );
  }, [
    businessRule.get('recommendationOfWhat').entities,
    businessRule.get('recommendationOfWhat').entities.length,
    businessRule.get('recommendationOfWhat').entities[index].id,
  ]);

  return (
    <Input
      className="business-rule-editor-entity-count__inpun"
      value={entityCountString}
      onChangeValue={handleChangeValue}
      onFocus={deconversionValue}
      onBlur={conversionValue}
      size={'large'}
      block
    />
  );
});

export default EntityCount;
