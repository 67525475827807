import { action, observable } from 'mobx';
import ConstructorStore, { IConstructorStore } from '~/stores/constructorStore';
import Store from '~/stores/store';

interface IErrorMessage {
  title: any;
  body: any;
}

const defaultError = {
  title: 'Что-то пошло не так',
  body: 'Попробуйте еще раз',
};

export class MailingGeneralStore<T> extends Store {
  @observable constructorStore: IConstructorStore<T>;
  @observable selectedId: number;
  @observable isBeforeClosePopup: boolean = false;
  @observable isMailingWasCreatedPopup: boolean = false;
  @observable isPreviewShown = false;
  @observable isLoading: boolean = false;
  @observable errorMessage: IErrorMessage = defaultError;
  @observable isErrorMessageShown: boolean = false;
  @observable firstRender: boolean = true;
  @observable isSavedMailing: boolean = false;
  @observable isShowEmailNotRegisterMessage: boolean = false; // Статус окна о не подтвержденном Email-е

  constructor(steps: T[]) {
    super();
    this.constructorStore = new ConstructorStore(steps);
  }

  @action
  setSelectedId = (selectedId: number) => {
    this.selectedId = selectedId;
  }

  @action
  setIsBeforeClosePopup = (status: boolean) => {
    this.isBeforeClosePopup = status;
  }

  @action
  setIsMailingWasCreatedPopup = (status: boolean) => {
    this.isMailingWasCreatedPopup = status;
  }

  @action
  setIsPreviewShown = (status: boolean) => {
    this.isPreviewShown = status;
  }

  @action
  setIsLoading = (status: boolean) => {
    this.isLoading = status;
  }

  setErrorMessage = (errorMessage: IErrorMessage) => {
    this.errorMessage = errorMessage;
  }

  @action
  setIsErrorMessageShown = (status: boolean) => {
    this.isErrorMessageShown = status;
    !status && this.setErrorMessage(defaultError);
  }

  @action
  setFirstRender = (status: boolean) => {
    this.firstRender = status;
  }

  @action
  setIsSavedMailing = (status: boolean) => {
    this.isSavedMailing = status;
  }

  /**
   * Управляет статусом окна о не подтвержденном Email-е
   * @param status 
   */
  @action
  setIsShowEmailNotRegisterMessage = (status: boolean) => {
    this.isShowEmailNotRegisterMessage = status;
  }
}

export default MailingGeneralStore;
