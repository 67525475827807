import moment from 'moment';
import React, { Component } from 'react';

interface Props {
  timestamp: number;
  withoutSuffix?: boolean;
  whenReady?: React.ReactNode;
}

class Timer extends Component<Props> {
  timer: number;

  runTimer = () => {
    this.timer = window.setTimeout(() => {
      this.forceUpdate();
      this.runTimer();
    }, 1000);
  }

  componentDidMount() {
    this.runTimer();
  }

  componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  render() {
    const {
      timestamp,
      withoutSuffix,
      whenReady,
    } = this.props;

    if (timestamp === 0) return null;

    const currentTs = new Date().getTime();
    const timeLeft = Math.round(timestamp - currentTs);

    if (timeLeft < 0) return whenReady;

    return moment
      .duration(timeLeft)
      .humanize(!withoutSuffix)
    ;
  }
}

export default Timer;
