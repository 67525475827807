import React, { useCallback } from 'react';
import './style.css';

interface ISwitchProps {
  checked: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const Switch: React.FC<ISwitchProps> = ({
  checked = false,
  onChange,
  disabled = false,
  className = null,
}) => {
  const handleChange = useCallback(
    (status: boolean) => {
      if (disabled) return;
      onChange?.(status);
    },
    [onChange]
  );

  return (
    <label className={`component-ui-switch__block ${className ? className : ''}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => handleChange(!checked)}
        disabled={disabled}
      />
      <span className="component-ui-switch__span component-ui-switch__shadow" />
    </label>
  );
};

export default Switch;
