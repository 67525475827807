import { action, computed, observable, reaction, IObservableArray } from 'mobx';
import Scenarios from '~/api/scenarios';
import { IWheelSector } from '~/components/generic/Wheel/types';
import ScenarioModel from '~/models/ScenarioModel';
import ScenarioTemplateModel from '~/models/ScenarioTemplateModel';
import StepsStore, { IConstructorStore } from '~/stores/constructorStore';
import Store from '~/stores/store';
import { getError } from '~/utils/getError';
import WheelOfFortuneModel from '../model/WheelOfFortuneModel';

export const menuItems = [
  {
    key: 'StepWheelPatterns',
    title: '1. Оформление',
    disabled: true,
  },
  {
    key: 'StepGifts',
    title: '2. Подарки',
    disabled: true,
  },
  {
    key: 'StepGeneralView',
    title: '3. Общий вид',
    disabled: true,
  },
  {
    key: 'StepChanceToWin',
    title: '4. Шанс выигрыша',
    disabled: true,
  },
];


export class WheelOfFortuneStore extends Store {
  @observable wheelOfFortune: WheelOfFortuneModel;
  @observable inputGifts: ISectorB[] = [];
  @observable targetSectorToEdit: ISectorB;
  @observable stepsStore: IConstructorStore<WheelEditorSteps>;
  @observable selectedId: number | string | null = null;
  @observable isConstructorOpen: boolean = false;
  @observable isBeforeClosePopup: boolean = false;
  @observable isPreviewShown = false;
  @observable isConsent: boolean = true;

  @observable isScenariosLoading = false;
  @observable isTemplatesLoading = false;

  @observable templateGroups: IObservableArray<IScenarioTemplateGroup> = [] as IObservableArray<IScenarioTemplateGroup>;
  @observable templates: IObservableArray<ScenarioTemplateModel> = [] as IObservableArray<ScenarioTemplateModel>;

  @observable scenarios: IObservableArray<ScenarioModel> = [] as IObservableArray<ScenarioModel>;
  @observable statistics: IObservableArray<IWidgetStatistics> = [] as IObservableArray<IWidgetStatistics>;

  @observable selectedTemplate: IWheelTemplate | undefined;
  @observable selectedParent: ScenarioTemplateModel;
  @observable errorText: string | Element;

  constructor() {
    super();
    reaction(() => this.isConstructorOpen, this.setWheelOfFortuneInstance);
    reaction(() => this.inputGifts.length, this.setSectorCount);

    this.stepsStore = new StepsStore([
      'StepWheelPatterns',
      'StepGifts',
      'StepGeneralView',
      'StepChanceToWin',
    ]);

    this.inputGifts = [
      {
        name: '',
        coupon: '',
        probability: 0,
      },
      {
        name: '',
        coupon: '',
        probability: 0,
      },
    ];
  }

  getSectorsCount = (winSectorsNumber: number) => {

    switch (winSectorsNumber) {
      case 2:
        return 8;

      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return winSectorsNumber * 2;

      default:
        return winSectorsNumber * 2;
    }
  }

  @action
  save = async () => {

    const savedData = this.selectedTemplate;

    savedData.params.title.value = this.wheelOfFortune.get('title');
    savedData.params.message.value = this.wheelOfFortune.get('message');
    savedData.params.buttonText.value = this.wheelOfFortune.get('buttonText');

    savedData.params.couponBorderColor.value = this.wheelOfFortune.get('sectorUIParams')[1].backgroundColor;
    savedData.params.buttonBackgroundColor.value = this.wheelOfFortune.get('sectorUIParams')[1].backgroundColor;
    savedData.params.buttonTextColor.value = this.wheelOfFortune.get('sectorUIParams')[1].textColor;

    savedData.params.extraHTMLConfiguration.sectorUIParams = this.wheelOfFortune.get('sectorUIParams');
    savedData.params.extraHTMLConfiguration.sectors = this.wheelOfFortune.get('sectors');

    savedData.params.extraHTMLConfiguration.sectorCount =
      this.getSectorsCount(this.wheelOfFortune.get('sectors')?.length);


    const template = this.selectedParent;


    template.get('triggerGroups')[0].triggers[0] = savedData;

    try {
      // @ts-ignore
      const templateModel = new ScenarioTemplateModel(null, template.copy());

      return await templateModel.save();

    } catch (error) {
      this.errorText = getError({ error });

      return false;
    }
  }

  @action
  fetchScenarios = async () => {
    this.isScenariosLoading = true;

    try {
      const scenariosListResponse = await Scenarios.list();
      const scenariosStatsResponse = await Scenarios.statisticsList();


      const list = scenariosListResponse.data.payload;
      const stats = scenariosStatsResponse.data.payload;

      this.statistics.replace(stats);

      const wheelWidgetsKeyword = 'FortuneWheel';

      await this.scenarios.replace(list
        .filter(scenario => scenario.name === wheelWidgetsKeyword)
        .map(d => new ScenarioModel(this, d))
      );


    } catch (e) {
      console.log({ e });
    } finally {
      this.isScenariosLoading = false;
    }
  }

  @action
  fetchTemplates = async () => {
    this.isTemplatesLoading = true;

    try {

      const templates: ScenarioTemplateModel[] = [];
      const wheelTemplateList = await Scenarios.wheelTemplateList();
      const groups = wheelTemplateList.data.payload;
      this.templateGroups.replace(groups);

      groups.forEach((group, groupIndex) => {
        group.scenarios.forEach((template) => {
          const model = new ScenarioTemplateModel(this, template);
          model.setGroup(groupIndex);
          templates.push(model);
        });
      });

      this.templates.replace(templates);

    } catch (e) {

    } finally {
      this.isTemplatesLoading = false;
    }

  }

  @action
  setSectorCount = () => {
    this.wheelOfFortune?.update({ sectorCount: this.inputGifts.length });
  }

  setWheelOfFortuneInstance = async () => {

    if (!this.isConstructorOpen) {
      this.selectedParent = null;
      this.selectedTemplate = null;
      this.stepsStore.changeCurrentStep('StepWheelPatterns');
      this.wheelOfFortune = new WheelOfFortuneModel(this,   {
        type: 'wheelOfFortune',
        sectors: [
          {
            name: '',
            coupon: '',
            probability: 0,
          },
          {
            name: '',
            coupon: '',
            probability: 0,
          },
        ],
        sectorUIParams: [
          {
            backgroundColor: '#B8DFFB',
            textColor: '#333333',
          },
          {
            backgroundColor: '#DFF0FF',
            textColor: '#333333',
          },
        ],
        sectorCount: 8,
        arrowColor: '#fff',
        isMobile: true,
      });
      await this.fetchTemplates();

      return;
    }

    if (!this.selectedId) {
      this.selectedParent = this.templates[0];
      this.selectedTemplate = this.selectedParent?.get('triggerGroups')[0].triggers[0];
    } else {

      const template = new ScenarioTemplateModel(null, { id: parseInt(this.selectedId.toString(), 10) });

      this.selectedTemplate = this.templates[0]?.get('triggerGroups')[0].triggers[0];


      template.fetch().then(() => {
        this.selectedParent = template;
        this.selectedTemplate = this.selectedParent.get('triggerGroups')[0].triggers[0];
      });

    }


    this.wheelOfFortune = new WheelOfFortuneModel(this,   {
      type: 'wheelOfFortune',
      sectors: [
        {
          name: '',
          coupon: '',
          probability: 0,
        },
        {
          name: '',
          coupon: '',
          probability: 0,
        },
      ],
      sectorUIParams: [
        {
          backgroundColor: '#B8DFFB',
          textColor: '#333333',
        },
        {
          backgroundColor: '#DFF0FF',
          textColor: '#333333',
        },
      ],
      sectorCount: 8,
      arrowColor: '#fff',
      isMobile: true,
    });
  }

  @action
  getSectorByIndex = (index: number) => {
    this.targetSectorToEdit = this.wheelOfFortune
      .get('sectors')
      .find((_, i) => i === index);
  }

  @action
  closeEmptySector = (index: number) => {
    this.inputGifts.splice(index, 1);
  }

  @action
  addEmptySector = () => {
    this.inputGifts.push({
      name: '',
      coupon: '',
      probability: 0,
    });
  }

  @action
  changeSectorName = (event: any, index: number) => {
    this.inputGifts[index].name = event.target.value;
  }

  @action
  changePromoCode = (event: any, index: number) => {
    this.inputGifts[index].coupon = event.target.value;
  }

  @action
  changeChance = (event: any, index: number) => {
    this.inputGifts[index].probability = parseInt(event.target.value, 10) || 0;
  }

  @action
  setIsConsentWheelCreate = (isConsent: boolean) => {
    this.isConsent = isConsent;
  }

  @action
  setSectorUIParams = (sectorParams: ISectorParams[]) => {
    this.wheelOfFortune.setSectorParams(sectorParams);
  }

  @action
  setTargetArrowColor = (arrowColor: string) => {
    this.wheelOfFortune.update({ arrowColor });
  }

  @action
  setIsConstructorOpen = (isConstructorOpen: boolean) => {
    this.isConstructorOpen = isConstructorOpen;
  }

  @action
  setSelectedId = (selectedId: number | string | null) => {
    this.selectedId = selectedId;
  }

  @action
  getSectors = () => {
    let temp: IWheelSector[] = [];
    const sectorCount = this.inputGifts.length;

    switch (sectorCount) {
      case 1:
        temp = Array.from(Array(8).fill(this.inputGifts[0])).map((item, i) => {
          const n = Number(i + 1);
          const isEven = n === 0 || !!(n && !(n % 2));

          return isEven ? {
            ...item,
            backgroundColor: this.wheelOfFortune.get('sectorUIParams')[0]
              .backgroundColor,
            textColor: this.wheelOfFortune.get('sectorUIParams')[0]
              .textColor,
          } : {
            ...item,
            backgroundColor: this.wheelOfFortune.get('sectorUIParams')[1]
              .backgroundColor,
            textColor: this.wheelOfFortune.get('sectorUIParams')[1]
              .textColor,
          };
        });
        break;
      case 2:
        temp = Array.from(Array(8)).map((_, i) => {
          const n = Number(i + 1);
          const isEven = n === 0 || !!(n && !(n % 2));

          return isEven ? {
            ...this.inputGifts[0],
            backgroundColor: this.wheelOfFortune.get('sectorUIParams')[0]
              .backgroundColor,
            textColor: this.wheelOfFortune.get('sectorUIParams')[0]
              .textColor,
          } : {
            ...this.inputGifts[1],
            backgroundColor: this.wheelOfFortune.get('sectorUIParams')[1]
              .backgroundColor,
            textColor: this.wheelOfFortune.get('sectorUIParams')[1]
              .textColor,
          };
        });
        break;
      default:
        temp = Array.from(Array(2).fill(this.inputGifts).flat()).map(
          (item, i) => {
            const n = Number(i + 1);
            const isEven = n === 0 || !!(n && !(n % 2));

            return {
              ...item,
              backgroundColor: isEven
                ? this.wheelOfFortune.get('sectorUIParams')[0].backgroundColor
                : this.wheelOfFortune.get('sectorUIParams')[1].backgroundColor,
              textColor: isEven
                ? this.wheelOfFortune.get('sectorUIParams')[0].textColor
                : this.wheelOfFortune.get('sectorUIParams')[1].textColor,
            };
          }
        );
        break;
    }
    this.wheelOfFortune.setSectors(this.inputGifts);

    return temp;
  }

  @computed
  get hasAllGiftNameFilled() {

    return this.inputGifts.some(value => value.name === '');
  }
}

export default new WheelOfFortuneStore();
