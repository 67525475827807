import { action, observable } from 'mobx';
import Store from '~/stores/store';
// import logger from '~/utils/logger';

// const log = logger.register('Common');

export class AppStore extends Store {
  @observable isDemo = false;

  @action
  setDemo = () => this.isDemo = true

  @action
  unsetDemo = () => this.isDemo = false
}

export default new AppStore();
