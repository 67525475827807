import React, { ReactNode } from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface INumberedBlockProps {
  className?: string;
  number?: number;
  isDisabled?: boolean;
  isDone?: boolean;
  children: ReactNode;
}

class NumberedBlock extends React.Component<INumberedBlockProps> {
  render() {
    return (
      <div
        className={`numbered-block__container ${
          this.props.className ? this.props.className : ''
        } ${this.props.isDisabled ? 'numbered-block__is_disabled' : ''}`}
      >
        {this.props.number > 0 && !this.props.isDone && (
          <div className="numbered-block__icon">
            <label className="numbered-block__number">
              {this.props.number}
            </label>
          </div>
        )}
        {this.props.isDone && (
          <div className="numbered-block__icon __is-done">
            <Icon name={'Done'} />
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default NumberedBlock;
