import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import './style.css';

interface Props {
  onCopy?: () => void;
  text?: string;
  className?: string;
  readonly?: boolean;
}

const ToCopyInput: React.FC<Props> = ({
  onCopy,
  text,
  className,
  readonly = true,
}) => {
  return (
    <div className={`${className}`}>
      <Input className="to-copy-input__text" value={text} {...readonly} />
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button block icon="Copy" size="large">
          Скопировать ссылку
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default ToCopyInput;
