import moment from 'moment';
import React from 'react';
import { SERVICE_INFO } from '~/screens/Auth/AttendanceScreen/ServiceSelection/constants';
import { formatValue } from '~/screens/Auth/AttendanceScreen/TariffInfo/utils';
import { TCurrentServices } from '~/stores/serviceStore';
import { TariffStore } from '~/stores/tariffStore';
import './style.css';

interface Props {
  currentServices: TCurrentServices[];
  tariffStore: TariffStore;
}

export const TariffInfoCard = (props: Props) => {
  const { currentServices, tariffStore } = props;

  const chosenTariffInfo = (code: string) => {
    try {
      return tariffStore
        .getChosenTariffInfo()[0]
        ?.get('services')
        .filter((v: any) => v.code === code)[0].price;
    } catch (e) {
      return 0;
    }
  };

  const value = tariffStore.getChosenTariffInfo()[0].price;
  const formattedDate = tariffStore.paymentDate?.length && moment(tariffStore.paymentDate[0]).format('LL');

  return (
    <div className="tariff-info">
      <span className="_header tariff-info-header">Информация по тарифу</span>
        <>
          <div className="_items">
            <div className="_row">
              <span>Тариф</span>
              <span className="_tariff">до 200 000 посещений</span>
            </div>
            {currentServices.map((services: TCurrentServices) => {

              return (
                SERVICE_INFO[services.code] && (
                  <div className="_row" key={services.code}>
                    <span>{SERVICE_INFO[services.code].title}</span>
                    <span>{formatValue(chosenTariffInfo(services.code))} ₽</span>
                  </div>
                )
              );
            })}
            <div className="_row">
              <span>Ежемесячный платеж</span>
              <span>{formatValue(value)}  ₽</span>
            </div>
            <div className="_row">
              <span>Первый платеж</span>
              <span>{formattedDate}</span>
            </div>
          </div>
          <div className="_row">
            <span className="_to-pay">Сейчас к оплате</span>
            <span className="_to-pay">0 ₽</span>
          </div>
        </>
    </div>
  );
};
