import React from 'react';
import TabSwitch from '~/components/ui/TabSwitch';

interface Props {
  onChange: (preset: DatePreset) => void;
  value: DatePreset;
}

const DatePresetSwitch: React.FC<Props> = ({
  onChange,
  value,
}) => (
  <TabSwitch
    className="date-preset-switch"
    items={[
      { key: 'all', label: 'Всё время' },
      { key: 'today', label: 'Сегодня' },
      { key: 'yesterday', label: 'Вчера' },
      { key: 'week', label: 'Неделя' },
      { key: 'month', label: 'Месяц' },
      { key: 'year', label: 'Год' },
    ]}
    value={value}
    onChange={onChange}
  />
);

export default DatePresetSwitch;
