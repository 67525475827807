import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';

const MANUAL_HINT = [
  'Выгрузите базу данных ваших клиентов в Exсel-файл',
  'Скопируйте столбец с e-mail получателей без названия столбца',
  'Вставьте скопированные адреса в поле ввода списка получателей',
];

const FILE_HINT = [
  'Скачайте и заполните файл шаблона или используйте собственный файл',
  'Убедитесь, что столбец с адресами пользователей имеет название «email» (с маленькой буквы, без лишних символов)',
  'Загрузите файл с помощью иконки «Скрепки» или перетащив документ в область',
];

interface IAddressBaseHintProps {
  isManualMethod: boolean;
}

const AddressBaseHint: React.FC<IAddressBaseHintProps> = ({
  isManualMethod,
}) => {
  const hint = isManualMethod ? MANUAL_HINT : FILE_HINT;
  
  return (
    <IconWithTooltip
      iconProps={{ name: 'Question' }}
      tooltipProps={{
        title: (
          <ol style={{ padding: '0 0 0 16px' }}>
            {hint.map((text, index) => {
              return <li key={index}>{text}</li>;
            })}
          </ol>
        ),
        placement: 'bottomLeft',
      }}
    />
  );
};

export default AddressBaseHint;
