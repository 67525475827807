import React from 'react';
import { getWinArrowStyles } from '~/components/generic/Wheel/helpers';
import styles from './styles.module.css';
import { IWinArrow } from './types';

export const WinArrow = ({ arrowColor, wheelSize }: IWinArrow) => {
  return (
    <div className={styles.winArrowContainer}>
      <div className={styles.winArrow} style={getWinArrowStyles(wheelSize, arrowColor)} />
    </div>
  );
};
