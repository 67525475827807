import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import { SwitchUI } from '~/components/SwitchUI';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import { Instructions } from '~/screens/LeadsSettingsScreen/Instructions/Instructions';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { BitrixInstructions } from '~/screens/LeadsSettingsScreen/bitrix/BitrixInstructions';
import { PlugBitrix } from '~/screens/LeadsSettingsScreen/bitrix/PlugBitrix';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const BitrixSettings = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;

  const isBitrixLoading = leadSettings.get('isBitrixLoading');
  const isBitrixSet = leadSettings.get('isBitrixSet');
  const bitrixError = leadSettings.get('bitrixError');

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isDisablePopupOpen, setIsDisablePopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    !isMounted && leadsStore.leadSettings.getBitrixIntegrationState();
  }, [isMounted]);

  const isSuccessShow = isBitrixSet && !isBitrixLoading && !bitrixError;
  const isPlugFormShow = !isBitrixSet && !isBitrixLoading && !bitrixError;

  const disableBitrix = async () => {
    await leadSettings.disableBitrix();
    setIsDisablePopupOpen(false);
  };

  return (
    <SwitchUI elementCode={'LeadSettings#Bitrix'}>
      <>
        <div className="leads-settings__inner-container">
          {isBitrixLoading && <Loading status={'Loading'} onClick={() => null} />}
          {bitrixError && (
            <Loading status={'Error'} onClick={() => window.location.reload()} />
          )}
          {isPlugFormShow && (
            <>
              <h3 className="leads-settings__description">
                Для того, чтобы получать заявки в «Битрикс24», вам нужно:
              </h3>
              <BitrixInstructions />
              <PlugBitrix />
              <Instructions type={'bitrix'} />
            </>
          )}
          {isSuccessShow && (
            <SettingsItem
              title="Ура! Теперь вы можете получать заявки с виджетов прямо в Битрикс24! "
              Content={(
                <Button
                  className="tgm-settings__content-btn"
                  type="grey"
                  onClick={() => setIsDisablePopupOpen(true)}
                  block
                >
                  Отключить сбор заявок в Битрикс24
                </Button>
              )}
              isSuccess
            />
          )}
        </div>
        {isDisablePopupOpen && (
          <Message
            onApply={disableBitrix}
            visible={isDisablePopupOpen}
            title="Отключить сбор заявок в Битрикс24?"
            textTop={'При продолжении операции, заявки больше не будут приходить в личный кабинет Битрикс24, пока Вы снова их не подключите.'}
            applyText={'Отключить'}
            cancelText={'Отмена'}
            onClose={() => setIsDisablePopupOpen(false)}
            showIcon={false}
          />
        )}
      </>
    </SwitchUI>

  );
});
