import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import Link from '~/components/ui/Link';
import { mainLoginUrl, setPasswordUrl, yandexMetrikaId } from '~/constants';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import { secondsToTime } from '~/utils/secondsToTime';
import useStores from '~/utils/useStores';
import { checkErrorHasTime } from '~/utils/validationUtils/checkErrorHasTime';
import '../../Auth/style.css';

export const SMS_CODE_LENGTH = 5;

const TIMEOUT_SECONDS = 299;

const RegisterScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };

  const {
    isOfferFetching,
    phone,
    isRecovery,
    isAuthorized,
    setPhone,
    isFetchingOnRegistration,
    offerError,
    registrationStep,
    registrationPhoneError,
    smsCodeError,
    smsRegCode,
  } = authStore;

  // @ts-ignore
  let timeoutID;
  const code = smsRegCode;

  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const [isMounted, setIsMounted] = useState(false);
  const [timeout, setTimeoutManual] = useState(-1);
  const [sendSmsAgain, setSendSmsAgain] = useState(false);
  const [isSmsSuccess, setIsSmsSuccess] = useState(false);

  useEffect(() => {
    if (timeout > 0) {
      timeoutID = setTimeout(() => {
        if (timeout === 1) {
          setSendSmsAgain(true);
        }

        !registrationPhoneError && setTimeoutManual(timeout - 1);

        registrationPhoneError && setTimeoutManual(-1);
      }, 1000);
    }

    return () => {
      if (isSmsSuccess) {
        timeoutID = null;
        authStore.clearRegistrationData();
      }
    };
  }, [timeout]);

  useEffect(() => {
    if (!isMounted) {
      (async () => {
        setIsMounted(true);

        if (isAuthorized) {
          history.push(firstPageAfterAuthorization(authStore.clientState, authStore.currentUser.rightsModel));
        }

        const currentLocation = history.location.pathname;

        if (currentLocation.includes('recovery')) {
          authStore.setIsRecovery(true);
          authStore.setIsRegistration(false);
        }

        if (currentLocation.includes('register')) {
          authStore.setIsRegistration(true);
          authStore.setIsRecovery(false);
        }
      })();
    }
  }, [isRecovery]);

  const onSubmit = async () => {

    if (step === 1) {
      await authStore?.sendPhoneNumber();

      // @ts-ignore
      isRecovery ? ym(yandexMetrikaId, 'reachGoal', 'recovery') : ym(yandexMetrikaId, 'reachGoal', 'lkregnum');
    }

    if (step === 2) {
      const result = await authStore?.sendCode();

      if (result) {
        // @ts-ignore
        !isRecovery && ym(yandexMetrikaId, 'reachGoal', 'lkregnumsms');
        setIsSmsSuccess(true);
        history.push(setPasswordUrl);
      }
    }

    timeout === -1 &&
      !registrationPhoneError &&
      setTimeoutManual(TIMEOUT_SECONDS);

    registrationPhoneError && setTimeoutManual(-1);
  };

  const submitSmsAgain = async () => {
    await authStore?.sendPhoneNumber();
    setSendSmsAgain(false);
    !authStore.registrationPhoneError && setTimeoutManual(TIMEOUT_SECONDS);
  };

  const onChangeSmsRegCode = (e: any) => {
    const smsRegCode = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;

    if (
      smsRegCode === '' ||
      (smsRegCode.length <= SMS_CODE_LENGTH &&
        typeof parseInt(smsRegCode, 10) === 'number' &&
        !isNaN(parseInt(smsRegCode, 10))
        && reg.test(smsRegCode))
    ) {
      authStore?.setSmsRegCode(e.target.value);
    }
  };

  const step = registrationStep;

  const buttonText = () => {
    if (step === 1 && !isRecovery) return 'Продолжить';

    if (step === 1 && isRecovery) return 'Продолжить';

    return 'Подтвердить';
  };

  const onPhoneFocus = () => phone.length === 0 && setPhone('+');
  const label = !phone.length ? 'Номер телефона' : undefined;
  const codeLabel = !code.length ? 'Введите код из sms' : undefined;

  const isButtonLoading = isFetchingOnRegistration || isOfferFetching;

  const [error, setError] = useState<string | Element>('');

  useEffect(() => {
    const finalError = offerError || registrationPhoneError || smsCodeError;
    const checkOnReplace = checkErrorHasTime(finalError);

    if (checkOnReplace) {
      setTimeoutManual(-1);
      if (typeof finalError === 'string') {
        setError(
          finalError.replace(
            checkOnReplace[0],
            secondsToTime(Math.floor(parseInt(checkOnReplace[1], 10) / 1000))
          )
        );
      } else {
        setError(finalError);
      }
    } else {
      setError(finalError);
    }
  });

  const isButtonDisabled =
    (step === 1 && (isButtonLoading || phone.length < 11)) ||
    (code.length < SMS_CODE_LENGTH && step === 2);
  const phoneInputDisabled = step !== 1;
  const codeInputDisabled = step !== 2;

  const getTimeoutString = (timeout: number) => {
    const reminder = timeout % 60;
    const wholeNumber = Math.floor(timeout / 60);

    if (wholeNumber >= 1) {
      return `${wholeNumber} мин. ${reminder} с.`;
    }

    return `${reminder} с.`;
  };

  const timeoutMessage = () => {
    return `Повторная отправка кода возможна через: ${getTimeoutString(
      timeout
    )}`;

    // const checkOnTimeError = (typeof smsCodeError === 'string' && smsCodeError.match(/\{(.*)\}/i)) || '';

    // return typeof smsCodeError === 'string' ? smsCodeError.replace(checkOnTimeError[0], secondsToTime(timeout)) : '';
  };

  const getTitle = () => {
    if (step === 1 && isRecovery)
      return 'Для восстановления пароля введите номер телефона';

    if (step === 1 && !isRecovery) return 'Регистрация';

    return (
      <>
        <div>Введите код</div>
        <div className="auth-screen-form__subtitle">
          Мы отправили код подтверждения на номер <br />
          {authStore?.getFormattedPhone()}
        </div>
      </>
    );
  };

  const authLinkText = 'Я вспомнил пароль.';

  const showTimeout = !registrationPhoneError && timeout > 0;

  return (
    <Screen className="auth-screen">
      <form className="auth-screen-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('auth-screen-form__title', '_isRegistration')}>
          {getTitle()}
        </div>

        <div className="auth-screen-form__row">
          {step === 1 && (
            <Input
              name="regTel"
              value={phone}
              onFocus={onPhoneFocus}
              onChangePhone={setPhone}
              ref={register({ required: true })}
              style={{ height: 60 }}
              placeholder={label}
              size="large"
              type="regTel"
              failure={!!registrationPhoneError}
              disabled={phoneInputDisabled}
              block
            />
          )}
        </div>
        {step === 2 && (
          <div className="auth-screen-form__row">
            <Input
              name="code"
              value={code}
              onChange={onChangeSmsRegCode}
              placeholder={codeLabel}
              style={{ height: 60 }}
              size="large"
              type="text"
              failure={!!smsCodeError}
              disabled={codeInputDisabled}
              block
            />
          </div>
        )}
        <div className="auth-screen-form__row">
          <Button
            loading={isButtonLoading}
            size="x-large"
            type="main"
            tag="button"
            htmlType="submit"
            disabled={isButtonDisabled}
            block
          >
            {buttonText()}
          </Button>
        </div>
        {showTimeout && (
          <div className="auth-screen-form__timeout">{timeoutMessage()}</div>
        )}
        {sendSmsAgain && timeout === 0 && (
          <div
            className={cx('auth-screen-form__timeout', '_link')}
            onClick={submitSmsAgain}
          >
            Отправить sms повторно.
          </div>
        )}
        <div className="auth-screen-form__error">{error}</div>
        {isRecovery && (
          <div className="auth-screen-form__row">
            <span className="auth-screen-form__text">
              {authLinkText}&nbsp;
              <Link to={mainLoginUrl}>
                <span className="auth-screen-form__link">
                  Войти в личный кабинет
                </span>
              </Link>
            </span>
          </div>
        )}
        {step === 1 && !isRecovery && (
          <div className="auth-screen-form__row">
            <span className="auth-screen-form__text">
              Регистрируясь, вы соглашаетесь с условиями &nbsp;
              <Link
                to="https://sberlead.ru/offer"
                target="_blank"
                className="auth-screen-form__link"
              >
                <span className="auth-screen-form__link">оферты</span>&nbsp;
              </Link>
              и&nbsp;
              <Link to="https://sberlead.ru/privacypolicy" target="_blank">
                <span className="auth-screen-form__link">
                  политикой конфиденциальности
                </span>
              </Link>
            </span>
          </div>
        )}
      </form>
    </Screen>
  );
});

export default React.memo(RegisterScreen);
