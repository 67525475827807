export const mjmlText = ({
  text,
  height,
  padding = '0px 0px',
  fontFamily,
  fontSize,
  backgroundColor = '#ffffff',
  color = '#000000',
  align = 'left',
  letterSpacing = '-.01em',
  fontWeight = '400',
  verticalAlign = 'top',
}: IMailingText) => {
  const heightParam = height ? `height="${height}"` : '';

  return `
    <mj-section
    padding="0"
    background-color="${backgroundColor}"
    >
    <mj-column padding="0">
      <mj-text
      font-family=${fontFamily}
      font-size=${fontSize}
      ${heightParam}
      padding="${padding}"
      color="${color}"
      align="${align}"
      letter-spacing="${letterSpacing}"
      vertical-align="${verticalAlign}"
      font-weight="${fontWeight}"
      >
        ${text}
      </mj-text>
    </mj-column>
  </mj-section>
`;
};
