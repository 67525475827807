import { observer } from 'mobx-react';
import React from 'react';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import ParamBlock from './ParamBlock';
import styles from './style.module.css';

const RecommendationsApiBlock: React.FC = observer(() => {
  const { authStore, feedApiStore } = useStores() as {
    authStore: AuthStore;
    feedApiStore: FeedApiStore;
  };
  const { currentOrg } = authStore;
  const { feedApi } = feedApiStore;

  return (
    <>
      <div className={styles.title}>Рекомендации готовы к интеграции</div>
      <ParamBlock
        className={styles.param_block}
        infoDesc={'Ключ авторизации'}
        paramName={'apiKey'}
        param={currentOrg.get('apiKey')}
        buttonName={'Копировать ключ'}
        tooltip={'Для полного доступа к методам API, передвайте параметр apiKey при запросе. Значение apiKey является секретным и не должно передаваться третьим лицам. В случае утечки, следует запросить новое значение у аккаунт-менеджера'}
        />
      <ParamBlock
        className={styles.param_block}
        infoDesc={'Идентификатор данных для получения аналитики'}
        paramName={'analyticsId'}
        param={currentOrg.get('analyticsId')}
        buttonName={'Копировать идентификатор аналитики'}
        tooltip={'Идентификатор данных для получения аналитики'}
      />
      <ParamBlock
        className={styles.param_block}
        infoDesc={'Идентификатор ленты'}
        paramName={'feedId'}
        param={feedApi.get('externalKey')}
        buttonName={'Копировать внешний ключ'}
        tooltip={'Идентификатор ленты'}
        />
    </>
  );
});

export default RecommendationsApiBlock;
