import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import Button from '~/components/ui/Button';
import Tabs, { Item } from '~/components/ui/Menu/Tabs';
import QuizModel from '~/screens/QuizScreen/stores/QuizModel';
import {
  QuizStore,
  TQuizConstructorStep,
} from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IConstructor {
  onClose: () => void;
  tabs: Item[];
  title: string;
  subtitle: string;
  onTabsChange: (key: string) => void;
  tabValue?: string;
  content: JSX.Element;
}

export const Constructor = observer(({
  onClose,
  tabs,
  title,
  subtitle,
  onTabsChange,
  tabValue,
  content,
}: IConstructor) => {
  const headerRef = useRef(null);
  const contentRef = useRef(null);

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { templateData, isRequiredStepsCreated } = quizStore || {};
  const { errors = [] } = templateData || {};

  useEffect(() => {
    const headerHeight = headerRef.current.getBoundingClientRect().height;
    const content = contentRef.current;
    if (headerHeight && typeof headerHeight === 'number') {
      content.style.height = `calc( 100vh - ${headerHeight + 65}px )`;
    }
  });

  useEffect(() => {
    if (quizStore.selectedId) {
      quizStore.quizConstructorStep === 1 &&
        quizStore.setQuizConstructorStep(3);

      if (typeof quizStore.quizExample !== 'undefined') return;

      const quizExample = quizStore.quizList.filter(
        v => v.template.id === quizStore.selectedId
      )[0];
      quizStore.quizExample = new QuizModel(null, quizExample.template.body);
      quizStore.templateData = quizExample.template;
    } else {
      console.log('this is new ');
    }
  });

  const onSave = async () => {
    quizStore.setIsSaving(true);

    setTimeout(async () => {
      if (await quizStore.save('save')) {
        quizStore.resetOnClose();
      }
    }, 500);
  };

  const onNext = async () => {
    if (quizStore.quizConstructorStep === 3) {
      quizStore.setQuizConstructorStep(4);

      return;
    }

    quizStore.setIsSaving(true);

    setTimeout(async () => {
      if (await quizStore.save('save-and-run')) {
        quizStore.resetOnClose();
      }
    }, 500);

  };

  const isNextButtonDisabled = !isRequiredStepsCreated;

  const isErrorsShown =
    errors?.length > 0 && [3, 4].includes(quizStore.quizConstructorStep);

  return (
    <div className={styles.constructor}>
      <Button
        onClick={onClose}
        icon={'Close'}
        type={'default'}
        className={styles.closeButton}
      >
        Закрыть конструктор
      </Button>
      <div className={styles.header} ref={headerRef}>
        <div className={styles.center}>
          <Tabs
            items={tabs}
            onChange={onTabsChange}
            value={tabValue || tabs[0]?.key}
            className={styles.tabs}
          />
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          {isErrorsShown && (
            <div className={styles.errors}>
              {errors.map((v: { message: string }, k: number) => {
                return <div key={k}>{v.message}</div>;
              })}
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(
          styles.content,
          quizStore.quizConstructorStep === 3 && styles.isSettings
        )}
        ref={contentRef}
      >
        {content}
      </div>
      <div className={styles.footer}>
        {[3, 4].includes(quizStore.quizConstructorStep) && (
          <div className={styles.footerActions}>
            <Button
              type={'grey'}
              disabled={quizStore.quizConstructorStep === 3 && !!quizStore.selectedId}
              className={quizStore.quizConstructorStep === 3 && !!quizStore.selectedId && styles.disabledBackButton}
              onClick={() =>
                quizStore.setQuizConstructorStep(
                  (quizStore.quizConstructorStep - 1) as TQuizConstructorStep
                )
              }
            >
              Назад
            </Button>
            <Button onClick={onSave} type={'grey'}>
              Сохранить
            </Button>
            <Button onClick={onNext} disabled={isNextButtonDisabled}>
              {' '}
              {quizStore.quizConstructorStep === 3
                ? 'Продолжить: Разместить квиз'
                : 'Сохранить и запустить квиз'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});
