import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

type ScenarioTemplateGroupsResponse = IResponse<IScenarioTemplateGroup[]>;

type ScenarioTemplateResponse = IResponse<[{
  '@type': 'ScenarioTemplatePayload';
  scenarioTemplate: IScenarioTemplate;
}]>;

type ScenariosListResponse = IResponse<IScenario[]>;

type ScenarioSaveResponse = IResponse;

type ScenarioRemoveResponse = IResponse<IScenario[]>;

type ScenarioPauseResponse = IResponse<IScenario[]>;

type ScenarioPlayResponse = IResponse<IScenario[]>;

type ScenarioStatisticsResponse = IResponse<IWidgetStatistics[]>;

type ScenarioPostResponse = IResponse<[{
  '@type': 'ScenarioPostPayload';
  imageCode: string;
}]>;

type imageData = {
  imageData: string | ArrayBuffer;
};

type GetScenariosCountResponse = IResponse<[GetScenariosCountPayload]>;

export type TScenarioEditingStatus = 'editing' | 'edited' | 'pre-saved';

const Scenarios = {
  templateList: (): AxiosPromise<ScenarioTemplateGroupsResponse> =>
    actionGet('/admin/ScenarioTemplateAdmin', { type: 'list' }),

  feedbackTemplateList: (): AxiosPromise<ScenarioTemplateGroupsResponse> =>
    actionGet('/admin/ScenarioTemplateAdmin', { type: 'requests' }),

  wheelTemplateList: (): AxiosPromise<ScenarioTemplateGroupsResponse> =>
    actionGet('/admin/ScenarioTemplateAdmin', { type: 'fortuneWheel' }),

  template: (scenarioId: number): AxiosPromise<ScenarioTemplateResponse> =>
    actionGet('/admin/ScenarioTemplateAdmin', { scenarioId }),

  save: (template: IScenarioTemplate): AxiosPromise<ScenarioSaveResponse> =>
    actionSet('/admin/ScenarioTemplateAdmin', null, {
      '@type': 'ScenarioTemplatePayload',
      'scenarioTemplate': template,
    }),

  list: (): AxiosPromise<ScenariosListResponse> =>
    actionGet('/admin/ScenarioAdmin', null),

  remove: (scenarioId: number): AxiosPromise<ScenarioRemoveResponse> =>
    actionSet('/admin/ScenarioAdmin', { scenarioId, delete: true }, null),

  pause: (scenarioId: number): AxiosPromise<ScenarioPauseResponse> =>
    actionSet('/admin/ScenarioAdmin', { scenarioId, active: false }, null),

  play: (scenarioId: number): AxiosPromise<ScenarioPlayResponse> =>
    actionSet('/admin/ScenarioAdmin', { scenarioId, active: true }, null),

  statisticsList: (): AxiosPromise<ScenarioStatisticsResponse> =>
    actionGet('/admin/ScenarioStatisticAdmin', {}),

  loadFile: (data: imageData): AxiosPromise<ScenarioPostResponse> =>
    actionSet('/image', data, null),

  getScenariosCount: (): AxiosPromise<GetScenariosCountResponse> =>
    actionGet('/admin/ScenarioAdmin', {
      action: 'scenarioCount',
    }
  ),
};

export default Scenarios;
