import React, { Component, ErrorInfo } from 'react';
import { AppStore } from '~/stores/appStore';
import './style.css';

interface Props {
  appStore?: AppStore;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      const title = this.state.error && this.state.error.toString();
      const jsStack = this.state.error && this.state.error.stack;
      const reactStack = this.state.errorInfo.componentStack;
      const stack = `${reactStack}\n\n${jsStack}`;

      return (
        <div className="error-boundary">
          <pre><h1 style={{ lineHeight: 1.2 }}>{title}</h1>{stack}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
