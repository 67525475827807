import { Dropdown } from 'antd';
import React from 'react';
import styles from './style.module.css';

const SupportingButton: React.FC<{
  overlay: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onClick: () => void;
  isVisibleContext?: boolean;
  className?: string;
  noShadow?: boolean;
}> = ({ overlay, onClick, isVisibleContext, className = false, noShadow = false }) => {
  return (
    <Dropdown
      overlay={overlay}
      placement="bottomRight"
      visible={isVisibleContext}
    >
      <div
        className={`${styles.circle_button} ${className ? className : ''} ${noShadow ? '' : styles.shadow}`}
        onClick={(even) => {
          even.stopPropagation();
          onClick();
        }}
      >
        <div className={styles.points_wrapper}>
          {[1, 2, 3].map((item) => {
            return <div key={item} className={styles.point} />;
          })}
        </div>
      </div>
    </Dropdown>
  );
};

export default SupportingButton;
