import React, { useState } from 'react';
import { NotificationProps } from '~/components/Notification';
import ItemPosition from '~/components/notification-editor/RadioGrid/ItemPosition';
import Button from '~/components/ui/Button';
import FormItem from '~/components/ui/FormItem';
import InputDropdown from '~/components/ui/InputDropdown';
import Message from '~/components/ui/Message';
import { MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET } from '~/constants';
import '../style.css';

export const imageSizes = [
  { key: 'original', value: 'Оригинал' },
  { key: 'fill', value: 'Заполнить' },
  { key: 'fit', value: 'Уместить' },
];

interface IImageEditor {
  notificationParams: NotificationProps;
  onChangeNotificationParams: (params: NotificationProps) => void;
}

/**
 *
 * @param {NotificationProps} notificationParams Параметры виджета
 * @param {function} onChangeNotificationParams Функция изменения параметров виджета.
 *
 * @return
 */
export const ImageEditor: React.FC<IImageEditor> = ({
  notificationParams,
  onChangeNotificationParams,
}: IImageEditor): JSX.Element => {
  const onChangeImage = (imageBase64: string): void => {
    onChangeNotificationParams({ ...notificationParams, imageBase64 });
  };

  const [fileName, setFileName] = useState<string>('');
  const [{
    isShownErrorDialogBox,
    titleErrorDialogBox,
    textErrorDialogBox,
  }, setIsShownErrorDialogBox] = useState<message>(
    {
      isShownErrorDialogBox: false,
      titleErrorDialogBox: null,
      textErrorDialogBox: null,
    }
  );

  const onChangeFile = () => {
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.setAttribute('accept', 'image/jpeg, image/png');
    inputFile.click();
    inputFile.onchange = (e: any) => {
      const file = e.currentTarget.files[0];
      if (file.size > MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET) {
        setFileName('Превышен максимальный размер');
        setIsShownErrorDialogBox({
          isShownErrorDialogBox: true,
          titleErrorDialogBox: 'Превышен максимальный размер :(',
          textErrorDialogBox: 'Попробуйте другое изображение',
        });

        return;
      }
      const readerFile = new FileReader();
      readerFile.readAsDataURL(file);
      readerFile.onloadend = async () => {
        const regex = new RegExp('(.*?)\.(jpg|png|jpeg)$');
        if (!(regex.test(file?.name?.toLowerCase()))) {
          setFileName('Тип не совпадает');
          setIsShownErrorDialogBox({
            isShownErrorDialogBox: true,
            titleErrorDialogBox: 'Тип не совпадает :(',
            textErrorDialogBox: 'Доступный формат: png, jpg, jpeg',
          });
        } else {
          onChangeImage(readerFile.result.toString());
          setFileName(file.name);
        }

      };
    };
  };

  const closeErrorDialogBox = () => {
    setIsShownErrorDialogBox({
      isShownErrorDialogBox: false,
      titleErrorDialogBox: null,
      textErrorDialogBox: null,
    });
  };

  const defaultImageSize = imageSizes[0].key;

  return (
    <>
      <section className="scenario-editor-sidebar__section">
        <FormItem title="Изображение" hint="Эта кнопка позволяет заменить текущую картинку на виджете на вашу. После нажатия на кнопку откроется окно выбора файла с компьютера, где вы можете выбрать картинку подходящего формата (png, jpg, jpeg) и размера (до 500 Кбайт). После выбора файла и сохранения выбора изображение на макете поменяется на ваше." noReservedPlaceForMessage>
          <Button onClick={onChangeFile} type="black-outline" block>
            Выбрать изображение
          </Button>
          {fileName.length <= 30 ? fileName : `${fileName.slice(0, 30)}...`}
          <Message
            title={titleErrorDialogBox}
            textTop={textErrorDialogBox}
            applyText="OK"
            visible={isShownErrorDialogBox}
            onApply={closeErrorDialogBox}
            onClose={closeErrorDialogBox}
            onExit={closeErrorDialogBox}
          />
          <div className="scenario-editor-sidebar__informer">
            <p>
              Для выбора/замены изображения нажмите на кнопку «Выбрать
              изображение»
            </p>
          </div>
        </FormItem>
      </section>

      <section className="scenario-editor-sidebar__section">
        <FormItem
          title="Размер"
          hint={() => {
            return (
              <>
                Здесь вы можете выбрать размер изображения в выпадающем списке
                <ul>
                  <li>Оригинал - размер изображения не меняется</li>
                  <li>Заполнить - изображение растягивается на весь виджет</li>
                  <li>Уместить - изображение вмещается в размер окна с виджетом</li>
                </ul>
                Макет слева отображает, как будет выглядеть ваше изображение в выбранном размере.
              </>
            );
          }}
          noReservedPlaceForMessage
        >
          <InputDropdown
            size="default"
            items={imageSizes}
            selectedKey={notificationParams.imageSize || defaultImageSize}
            onChange={(imageSize: TNotificationImageSize) => {
              onChangeNotificationParams({ ...notificationParams, imageSize });
            }}
          />
        </FormItem>
      </section>

      <section className="scenario-editor-sidebar__section">
        <ItemPosition
          hint="Тут вы можете выбрать в какой части виджета будет располагаться изображение, нажав на соответствующий квадрат внизу. Макет слева отображает, как будет выглядеть виджет в зависимости от выбранного расположения у картинки."
          title="Позиция"
          align={notificationParams.imagePosition}
          onSelect={(imagePosition: INotificationAlignment) => {
            onChangeNotificationParams({
              ...notificationParams,
              imagePosition,
            });
          }}
        />
      </section>
    </>
  );
};
