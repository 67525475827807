import { action, computed, observable } from 'mobx';
import { Auth } from '~/api';
import Tariffs, { ETariffsCode } from '~/api/tariffs';
import TariffModel from '~/models/TariffModel';
import Store from '~/stores/store';

export class TariffStore extends Store {
  @observable availableTariffs: Array<TariffModel>;
  @observable minPrice: number;
  @observable maxPrice: number;
  @observable chosenTariff: string;
  @observable isTariffSuccessfullyChosen: boolean = false;
  @observable currentTariffs: Array<ITariff> = [];
  @observable domain: string;
  @observable loading: boolean;
  @observable errorMessage: string;
  @observable domainErrorMessage: string;
  @observable sendApplication: boolean = false;
  @observable freePeriod: string;
  @observable paymentDate: string[];
  @observable currentTariffCode: string = '';
  @observable fetchedCurrentTariff: boolean;
  @observable isIndividualTariff: boolean = false;

  constructor() {
    super();
  }

  @action
  setDomain = (domain: string) => {
    this.domain = domain;
  }

  @action
  setErrorMessage = (message: string): void => {
    this.errorMessage = message;
  }

  @action
  setDomainErrorMessage = (message: string): void => {
    this.domainErrorMessage = message;
  }

  @action
  setIsTariffSuccessfullyChosen = () => {
    this.isTariffSuccessfullyChosen = true;
  }

  @action
  setSendApplication = (status: boolean) => {
    this.sendApplication = status;
  }

  @action
  setCurrentTariffCode = (code: string) => {
    this.currentTariffCode = code;
  }

  @action
  addDomain = async (domain: string) => {
    this.loading = true;

    this.setDomainErrorMessage('');

    try {
      const model: IDomainInfo = {
        '@type': 'DomainInfo',
        'origin': domain,
        'status': 'CREATE',
      };

      await Auth.addDomain(model);

    } catch (e) {
      const error = e.response.data.errorData.errorText.split(':')[0];
      this.setDomainErrorMessage(error);

      throw e;
    } finally {
      this.loading = false;
    }
  }

  fetchAvailableTariffs = async () => {
    try {
      const response = await Tariffs.getAvailableTariffs(this.currentTariffCode);
      this.availableTariffs = response?.data?.payload.map((v: ITariff) => new TariffModel(this, v));

      this.minPrice = Math.min(...this.availableTariffs
        ?.map(item => item.price));
      this.maxPrice = Math.max(...this.availableTariffs
        ?.map(item => item.price));
      this.paymentDate = this.availableTariffs?.map(item => item.paymentDate);

      this.chooseTariff(this.tariffOptions[0].code);

    } catch (e) {
      console.log({ e });
    }
  }

  @action
  chooseTariff = (tariff: string) => {
    this.chosenTariff = tariff;
  }

  getChosenTariffInfo = () => {
    return this.tariffOptions.filter((v: TariffModel) => {

      return v.code === this.chosenTariff;
    });
  }

  @action
  applyTariff = async () => {
    this.loading = true;
    this.setErrorMessage('');

    try {
      await Tariffs.setTariff({
        currentTarplanCode: this.currentTariffCode,
        nextTarplanCode: this.chosenTariff,
      });
      this.setIsTariffSuccessfullyChosen();
      await this.fetchCurrentTariff();
    } catch (e) {
      this.setErrorMessage(e?.response?.data?.errorData?.errorText?.split(':')[0]);
    }

    this.loading = false;

  }

  @action
  fetchCurrentTariff = async () => {
    this.fetchedCurrentTariff = true;
    try {
      const response = await Tariffs.getCurrentTariff();
      if (response.data.payload[0].ui.description === 'Индивидуальный тариф') {
        this.isIndividualTariff = true;
      }

      this.currentTariffs = response.data.payload;
      const freeTariffs = this.currentTariffs.filter(v => v.price === 0);
      this.freePeriod = freeTariffs[0].dateTo;
      this.setCurrentTariffCode(response.data.payload[0].code);
    } catch (e) {
      this.errorMessage = e?.response?.data?.errorData?.errorText?.split(':')[0];
      throw e;
    } finally {
      this.fetchedCurrentTariff = false;
    }
  }

  @computed
  get tariffOptions() {
    return this.availableTariffs ? this.availableTariffs.sort((a, b) => a.minVisit - b.minVisit) : [];
  }

  @computed
  get step() {

    return this.tariffOptions?.length ? this.tariffOptions[0].maxVisit - this.tariffOptions[0].minVisit : 0;
  }

  @computed
  get minValue() {
    return this.tariffOptions?.length ? this.tariffOptions[0].minVisit : 10;
  }

  @computed
  get maxValue() {
    return this.tariffOptions?.length ? this.tariffOptions[this.tariffOptions.length - 2]?.maxVisit : 2000000;
  }

  @computed
  get sendButtonName() {
    return this.sendApplication ? 'Отправить заявку' : 'Попробовать бесплатно';
  }

  @computed
  get paidTariff() {
    return this.currentTariffs?.filter(
      (tariff: ITariff) => tariff.code !== ETariffsCode.PROMO
    )[0];
  }

  @computed
  get isNotPaidTariff() {
    return this.currentTariffs?.every((item: ITariff) => !item.isPaid);
  }
}

export default new TariffStore();
