import { Alert, Button, Empty, Modal, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Access from '~/components/Access';
import OldPageHeader from '~/components/__deprecated/OldPageHeader';
import RoleModel from '~/models/RoleModel';
import OldScreen from '~/screens/__deprecated/OldScreen';
import { AuthStore } from '~/stores/authStore';
import { RolesStore } from '~/stores/rolesStore';

interface Props {
  rolesStore?: RolesStore;
  authStore?: AuthStore;
}

interface Store {
  isDeleteModalVisible: boolean;
  removingRoleID: number;
  deleteRoleError: IResponse;
}

@inject('rolesStore', 'authStore')
@observer
class RolesScreen extends Component<Props> {
  state: Store = {
    isDeleteModalVisible: false,
    removingRoleID: null,
    deleteRoleError: undefined,
  };

  async componentDidMount() {
    await this.props.rolesStore.fetchRoles();
    await this.props.rolesStore.fetchRights();
  }

  showDeleteConfirm = (role: RoleModel) => {
    this.setState({
      isDeleteModalVisible: true,
      removingRoleID: role.get('id'),
    });
  }

  cancelDeleteConfirm = () => {
    this.setState({
      isDeleteModalVisible: false,
    });
  }

  handleDeleteConfirm = async () => {
    this.setState({ deleteRoleError: undefined });

    try {
      await this.props.rolesStore.deleteRole(this.state.removingRoleID);
      this.setState({ isDeleteModalVisible: false });
    } catch (e) {
      this.setState({ deleteRoleError: e.response.data });
    }
  }

  renderColumns = (): ColumnProps<RoleModel>[] => {
    const cols = [];

    cols.push({
      key: 'id',
      title: 'ID',
      render: (role: RoleModel) => role.get('id'),
    });

    cols.push({
      key: 'name',
      title: 'Роль',
      render: (role: RoleModel) => <strong>{role.get('name')}</strong>,
    });

    cols.push({
      key: 'rights',
      title: 'Права',
      render: (role: RoleModel) => {
        return <Tag color="green">Все права</Tag>;
      },
    }); 

    cols.push({
      key: 'actions',
      render: this.renderActions,
    });

    return cols;
  }

  renderActions = (role: RoleModel) => {
    return (
      <div className="table__actions">
        <Access to="ADMIN">
          <Link to={`/roles/${role.get('id')}`}>
            <Button>
              Редактировать
            </Button>
          </Link>
        </Access>
        <Access to="ADMIN">
          <Button
            type="default"
            onClick={() => this.showDeleteConfirm(role)}
          >
            Удалить
          </Button>
        </Access>
      </div>
    );
  }

  render() {
    const { rolesStore } = this.props;
    const { isDeleteModalVisible, removingRoleID, deleteRoleError } = this.state;

    const removingRole = rolesStore.getRoleById(removingRoleID);

    return (
      <OldScreen
        className="roles-screen"
      >
        <OldPageHeader
          title="Роли"
          extra={(
            <Access to="ADMIN">
              <Link to="/roles/create">
                <Button type="primary">Создать роль</Button>
              </Link>
            </Access>
          )}
        />

        <Table
          className="roles-screen__table table"
          loading={rolesStore.isRolesLoading}
          columns={this.renderColumns()}
          dataSource={Array.from(rolesStore.roles)}
          locale={{ emptyText: <Empty description={false} /> }}
          size="small"
          pagination={false}
        />

        <Modal
          title="Удаление"
          visible={isDeleteModalVisible}
          onOk={this.handleDeleteConfirm}
          okText="Да"
          onCancel={this.cancelDeleteConfirm}
          cancelText="Отменить"
          confirmLoading={rolesStore.isRoleDeleting}
        >
          {deleteRoleError && (
            <Alert
              message={deleteRoleError.errorData.errorText}
              type="error"
              showIcon
              style={{ marginBottom: 24 }}
            />
          )}

          Вы уверены, что хотите удалить роль <strong>«{removingRole && removingRole.get('name')}»</strong>?
        </Modal>
      </OldScreen>
    );
  }
}

export default RolesScreen;
