import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import MailingStatistics from '~/screens/MailingScreen/components/MailingList/MailingStatistics';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const SCREEN_TITLE = 'Автоматические Email рекомендации';
interface IMailingListProps {
  hasRightToModify?: boolean;
}

const MailingList: React.FC<IMailingListProps> = observer(({
  hasRightToModify = false,
}) => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  return (
        <Screen
          header={SCREEN_TITLE}
          documentTitle={SCREEN_TITLE}
          className={styles.mailingScreen}
          footer={(
            <Button icon="Plus" onClick={() => {
              hasRightToModify && mailingStore.constructorStore.setIsConstructorOpen(true);
            }} disabled={!hasRightToModify}>
              Создать
            </Button>
          )}
        >
          <div className={styles.block}>
            <MailingStatistics hasRightToModify={hasRightToModify} />
          </div>
        </Screen>
  );
});

export default MailingList;