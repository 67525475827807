import { IAction } from '~/components/generic/Promo/Actions';
import { getActionButton } from '~/components/generic/Promo/utilities';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import { redirectToClickform } from '~/utils/redirectToClickform';

export const emptyScreenButtonsCreator = (hasRight: boolean): IAction[] => {
  const {
    hasAccounts,
    setIsSendSayWindowOpen,
  } = SendSayAccountElementsStore;

  return getActionButton(
    [
      !hasAccounts && hasRight,
      hasAccounts && hasRight,
      !hasRight,
    ], [
      {
        onClick: () => setIsSendSayWindowOpen(true),
        icon: 'Plus',
        type: 'default',
        name: 'Добавить учетную запись Sendsay',
      }, {
        onClick: massMailingStore.createMassMailing,
        icon: 'Plus',
        type: 'default',
        name: 'Создать ручную рассылку',
      }, {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default',
        name: 'Оставить заявку на подключение',
      },
    ]
  );
};
