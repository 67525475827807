import { observer } from 'mobx-react';
import React, { Component } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Steps from '~/components/ui/Steps';
import Screen from '~/screens/Screen';
import { setGtm } from '~/utils/setGtm';

interface State {
  current: number;
  copied: boolean;
}

@observer
class GuideScreenGTM extends Component {
  state: State = {
    current: 0,
    copied: false,
  };

  handleNextStepClick = () => {
    this.setState({ current: this.state.current + 1 });
  }

  handleCopyCode = () => {
    this.setState({ copied: true, current: this.state.current + 1 });
  }

  render() {
    /**
     * Отправка события site_using_GTM для GA
     */
    setGtm('site_using_GTM');

    const items = [{}, {}, {}, {}, {}, {}, {}];

    return (
      <Screen className="guide-cms">
        <div className="guide-navigation">
          <Button className="guide-back-button" type="text" icon="ArrowMLeft" size="x-small" to="/guide">
            К выбору способа подключения
          </Button>
          <h2 className="guide-name">
            Установка кода через Google Tag Manager
          </h2>
        </div>
        <div className="guide-for-dev__container">
          {this.state.current === 0 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Войдите в свой аккаунт в системе Google Tag Manager</h1>
              <p className="guide-cms__text">
                Войдите в свой рабочий аккаунт в системе GTM. В разделе «Рабочая область»
                перейдите в подраздел «Теги» и нажмите на «Создать».
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 1 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Измените тег на<br />&laquo;Пользовательский-HTML&raquo;</h1>
              <p className="guide-cms__text">
                Измените конфигурацию тега на
                &laquo;Пользовательский HTML&raquo; тип.
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 2 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-for-dev__title">Скопируйте код</h1>
              <CodeToCopy onCopy={this.handleCopyCode} />
            </div>
          )}
          {this.state.current === 3 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Вставьте скопированный код после тега &lt;body&gt; или &lt;head&gt;</h1>
              <p className="guide-cms__text">
                Вернитесь в редактор кода и вставьте код сразу после
                открытия тега &lt;body&gt;. Рекомендуем разместить его
                внутри элементов head или body как можно ближе к
                началу страницы. Нажмите сохранить.             
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 4 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Измените конфигурация тега<br />и триггера</h1>
              <p className="guide-cms__text">
                Вставьте скопированный код счетчика Сервиса в
                поле для скриптов, отметьте галочкой &laquo;Поддержка
                функции document write()&raquo; и в Настройке активации
                тега выберите &laquo;Без ограничений&raquo;.
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 5 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Опубликуйте изменения</h1>
              <p className="guide-cms__text">
                После сохранения настроек в открывшимся окне
                перетащите созданный тег в описание версии,
                задайте ее название и нажмите "Опубликовать".
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 6 && (
            <div className="guide-cms-message__wrapper">
              <h1 className="guide-for-dev__title">Проверьте подключение</h1>
              <CheckCodeButton />
            </div>
          )}
        </div>
        <Steps items={items} current={this.state.current} />
        <div className="guide-for-dev__link-wrapper">
          <Link className="guide-for-dev__link" to="/guide/gtm">
            Подробная инструкция
          </Link>
        </div>
      </Screen>
    );
  }
}

export default React.memo(GuideScreenGTM);
