import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import BorderComponent from '~/components/notification-editor/BorderComponent';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FontSettings from '~/components/notification-editor/FontSettings';
import FormItem from '~/components/ui/FormItem';

const HandButtonsParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({ isActive, configuration, onChange, onChangeCssParam }) => {

  const {
    buttonColor,
    buttonOpacity,
    hasButtonBorder,
    buttonBorderOpacity,
    buttonBorderWidth,
    buttonBorderColor,
    buttonFontFamily,
    buttonTextColor,
    buttonTextOpacity,
    buttonFontWeight,
    buttonFontSize,
    buttonFontItalic,
    buttonTextLineThrough,
    buttonTextUnderline,
  } = configuration;

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FormItem
          className="feed-editor-appearance__font"
          overlayClassName="feed-editor-appearance__tooltip"
          title="Цвет кнопок"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={buttonColor}
            alpha={buttonOpacity}
            onChange={data =>
              onChange(
                {
                  ...configuration,
                  buttonColor: data.color,
                  buttonOpacity: data.alpha,
                },
                true
              )
            }
            className="feed-editor-appearance__color-picker"
          />
        </FormItem>
      </section>
      <section className="feed-editor-appearance__section">
        <BorderComponent
          className="feed-editor-appearance__font"
          limit={8}
          title="Обводка кнопки"
          overlayClassName="feed-editor-appearance__tooltip"
          hint={(
            <>
              В этом блоке вы можете:
              <ul className="feed-editor-appearance__tooltip-list">
                <li>
                  поменять толщину линии вокруг кнопки (можно поставить значения
                  от 0 до 8)
                </li>
                <li>
                  выбрать цвет линии вокруг кнопки и ее НЕпрозрачность в
                  процентах. Если вы не знаете обозначение желаемого цвета,
                  нажмите на квадрат с цветом. В открывшемся окне первой строкой
                  выберите нужный вам цветовой спектр и выберите цвет на палитре
                  выше (палитра выше соответствует выбранному спектру), второй
                  строкой выберите нужный вам процент непрозрачности линии
                  вокруг кнопки (можно устанавливать только значения кратные
                  10-ти).
                </li>
                <li>
                  скрыть/показать линию вокруг кнопки нажав на значок глаза
                </li>
              </ul>
              Макет слева отображает, как будет выглядеть кнопка на карточке
              товара.
            </>
          )}
          borderWidth={buttonBorderWidth}
          onBorderWidthChange={buttonBorderWidth =>
            onChange(
              {
                ...configuration,
                buttonBorderWidth,
              },
              true
            )
          }
          colorPickerProps={{
            alpha: buttonBorderOpacity,
            color: buttonBorderColor,
            onChange: ({ alpha, color }) =>
              onChange(
                {
                  ...configuration,
                  buttonBorderColor: color,
                  buttonBorderOpacity: alpha,
                },
                true
              ),
          }}
          enabled={hasButtonBorder}
          onChangeState={hasButtonBorder =>
            onChange(
              {
                ...configuration,
                hasButtonBorder,
              },
              true
            )
          }
        />
      </section>
      <section className="feed-editor-appearance__section">
        <FontSettings
          type="title"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          className="feed-editor-appearance__font"
          data={{
            fontFamily: buttonFontFamily,
            color: buttonTextColor,
            alpha: buttonTextOpacity,
            fontWeight: buttonFontWeight,
            fontSize: buttonFontSize,
            isItalic: buttonFontItalic,
            isLineThrough: buttonTextLineThrough,
            isUnderline: buttonTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                buttonFontFamily: data.fontFamily,
                buttonTextColor: data.color,
                buttonTextOpacity: data.alpha,
                buttonFontWeight: data.fontWeight,
                buttonFontSize: data.fontSize as TFontSize,
                buttonFontItalic: data.isItalic,
                buttonTextLineThrough: data.isLineThrough,
                buttonTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandButtonsParams;
