import React, { ReactNode } from 'react';
import FormItem from '~/components/ui/FormItem';
import './style.css';

interface IParamCssProps {
  onChangeCssParam: (params: string) => void;
  value?: string;
  title?: string;
  hint?: ReactNode;
  message?: string;
}

const ParamCss: React.FC<IParamCssProps> = ({
  onChangeCssParam,
  value = '',
  title = null,
  hint = null,
  message = 'Если не будет каких-либо стилей, то будут использованы стандартные',
}) => (
  <FormItem
    noHTMLLabel
    title={title}
    hint={hint}
    message={message}
    style={{ marginTop: 20 }}
  >
    <textarea
      className="generic-ssad-sidebar-css-param__textarea"
      rows={13}
      value={value.replace(/; +/gm, ';\n')}
      onChange={event =>
        onChangeCssParam(event.target.value.replace(/;\n/gm, '; '))
      }
    />
  </FormItem>
);

export default ParamCss;
