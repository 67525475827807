import React from 'react';
import Message from '../ui/Message';
import './style.css';

interface IWidgetFromNonCustomersProps {
  visible: boolean;
  toggle: (props: boolean) => void;
}

const WidgetFromNonCustomers = (props: IWidgetFromNonCustomersProps) => {
  
  const handleClose = () => {
    props.toggle(false);
  };

  const handleApplyClick = () => {
    location.href = 'https://sberlead.ru/rko';
    props.toggle(false);
  };

  const mainContentWidget = () => {
    return (
      <>
        <img
          src="assets/nonCustomers/Horizontal_widget-non-customers.svg"
          alt="non-customres"
          className="_img"
        />
        <div className="_main-content">
          <span className="_widget-title">
            У Вашей компании <br />
            не открыт счет <br />
            в СберБанке?
          </span>
          <span className="_widget-text-top">
            Откройте РКО и получите <br />
            специальные условия на СберЛид
          </span>
          <button
            className="_widget-btn"
            onClick={handleApplyClick}
          >Подробнее</button>
        </div>
        
      </>
    );
  };

  return (
    <>
      <Message
        className="widget-from-non-customers"
        visible={props.visible}
        textTop={mainContentWidget()}
        onClose={handleClose}
        onExit={handleClose}
        showLine={false}
      />
    </>
  );
};

export default WidgetFromNonCustomers;
