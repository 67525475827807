import cx from 'classnames';
import React from 'react';
import './style.css';

export interface RadioButtonProps {
  checked: boolean;
  size?: 'medium' | 'small';
  disabled?: boolean;
  onCheck: (value: string | number) => void;
  name?: string;
  label: React.ReactNode;
  value?: string | number;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  size = 'medium',
  disabled,
  onCheck,
  name,
  label,
  value,
}) => {
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => onCheck(e.target.value);

  return (
    <div
      className={cx('radio-button', {
        _checked: checked,
        [`_size-${size}`]: size,
        _disabled: disabled,
      })}
    >
      <label
        className="radio-button__label"
      >
        <input 
          className="radio-button__input"
          type="radio"
          name={name}
          checked={checked}
          onChange={handleCheck}
          value={value ?? ''}
          
        />
        <span className="radio-button__circle" />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;

