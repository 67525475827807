import qs from 'query-string';
import { TARIFF_TAB } from '~/constants';

/**
 * Возвращает страницу для редиректа после успешной авторизации или регистрации
 * @param clientState
 * @param rightsModel - список прав пользователя дефолтное значение ['LIST_SCENARIOS'] для сохранения
 *  старого поведения, когда параметры не переданы
 * @returns
 */
export const firstPageAfterAuthorization = (
  clientState: string = '',
  rightsModel: IRightCode[] = ['LIST_SCENARIOS']
): string => {
  if (
    clientState === 'CODE_NOT_SET' ||
    clientState === 'USER_PROFILE_NOT_FILLED'
  ) {
    return `/settings?tab=${TARIFF_TAB}`;
  }

  const params = qs.parse(location.search) as { redirect: string };
  if (params.redirect) {
    return params.redirect;
  }

  // Временное решение для лент, чтобы спрятать раздел виджетов
  // Поскольку в основном у всех пользователей есть виджеты, то эту проверку оставил первой
  // TODO: Надо будет как-то переделать или доработать логику первых страниц по правам
  // задача на выпиливание или правку https://jira.sberbank.ru/browse/ARCH-522
  if (rightsModel && rightsModel.includes('VIEW_FEEDS')) return '/feed';

  if (rightsModel && rightsModel.includes('VIEW_FEEDS_LITE'))
    return '/feed-constructor';

  return '/widgets';
};
