import { IFeedConfiguration } from 'feed';
import React from 'react';
import CategoryActiveButton from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Categories/CategoryActiveButton';
import CategoryButton from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Categories/CategoryButton';

interface ICategoriesProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
}

export const Categories: React.FC<ICategoriesProps> = ({
  configuration,
  onChange,
}) => {
  return (
    <>
      <CategoryButton configuration={configuration} onChange={onChange} />
      <CategoryActiveButton configuration={configuration} onChange={onChange} />
    </>
  );
};