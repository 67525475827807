import { observer } from 'mobx-react';
import React from 'react';
import './style.css';

const ScenarioEditorSegments: React.FC = observer(() => (
  <div className="scenario-editor-segments">
    Здесь настройки выставлены по-умолчанию.<br />
    Переходите к следующему шагу.
  </div>
));

export default ScenarioEditorSegments;
