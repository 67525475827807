import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import Loading from '~/components/Loading';
import MailingPreview from '~/components/generic/MailingPreview';
import SendSayAccountElements from '~/components/generic/SendSayAccountElements';
import Message from '~/components/ui/Message';
import EmptyScreen from '~/screens/MassMailingScreen/components/EmptyScreen';
import MassMailingConstructor from '~/screens/MassMailingScreen/components/MassMailingConstructor';
import MassMailingList from '~/screens/MassMailingScreen/components/MassMailingList';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const MassMailingScreen: React.FC = observer(() => {
  const { authStore } = useStores() as {
    authStore: AuthStore;
  };
  const {
    mailingStatistics,
    isLoading,
    isBeforeClosePopup,
    isMailingWasCreatedPopup,
    selectedId,
    isErrorMessageShown,
    setIsErrorMessageShown,
    fetchMailingList,
    setIsBeforeClosePopup,
    errorMessage,
    setIsMailingWasCreatedPopup,
    constructorStore,
    isPreviewShown,
    mailingInstance,
    setIsPreviewShown,
    sendTestLetter,
  } = massMailingStore;
  const { isConstructorOpen, setIsConstructorOpen } = constructorStore;

  // Права для управления
  const hasRight = authStore.currentUser.hasRightTo('MODIFY_MASS_MAILING');
  // Лоадер
  const showLoader = isLoading;
  // Статистика
  const showStatistics = mailingStatistics?.length > 0 && !showLoader;
  // Заглушка
  const showEmptyScreen = !showStatistics && !showLoader;

  const onClose = () => {
    setIsBeforeClosePopup(false);
  };

  const onApply = () => {
    setIsBeforeClosePopup(false);
    setIsConstructorOpen(false);
  };

  const closePopupDescription = selectedId ? 'редактирование' : 'создание';

  useEffect(() => {
    fetchMailingList();
  }, []);

  return (
    <>
      {showLoader && <Loading status={'Loading'} onClick={() => null} />}
      {showEmptyScreen && <EmptyScreen hasRight={hasRight} />}
      {showStatistics && <MassMailingList hasRightToModify={hasRight} />}
      <MassMailingConstructor isConstructorOpen={isConstructorOpen} />
      <MailingPreview
        configuration={mailingInstance?.get('configuration')}
        isPreviewShown={isPreviewShown}
        setIsPreviewShown={setIsPreviewShown}
        email={mailingInstance?.get('authorizationEmail')?.address ?? ''}
        sendTestLetter={sendTestLetter}
        errorMessage={errorMessage.body}
        unsubscribeLinkHref={'/mockMassMailLinkResult'}
      />
      <SendSayAccountElements />
      {isErrorMessageShown && (
        <Message
          visible
          applyText={'Хорошо'}
          onApply={() => setIsErrorMessageShown(false)}
          title={errorMessage.title}
          textTop={errorMessage.body as string}
          showIcon={false}
        />
      )}
      {isBeforeClosePopup && (
        <Message
          className={styles.errorMessage}
          visible
          onClose={onClose}
          onApply={onApply}
          onExit={onClose}
          applyText={`Прекратить ${closePopupDescription}`}
          cancelText={`Отмена`}
          title={`Прекратить ${closePopupDescription} рассылки?`}
          textTop="Настройки и данные не сохранятся"
          showIcon={false}
        />
      )}
      {isMailingWasCreatedPopup && (
        <Message
          visible
          showIcon={false}
          onApply={() => {
            setIsMailingWasCreatedPopup(false);
          }}
          applyText={'Хорошо'}
          title={(
            <div>
              <Icon name={'CheckmarkXxl'} className={styles.popupIcon} />
              Рассылка создана! Не забудьте её запустить
            </div>
          )}
        />
      )}
    </>
  );
});

export default MassMailingScreen;
