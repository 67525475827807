import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import Profile from '~/api/profile';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Tabs from '~/components/ui/Menu/Tabs';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { useGetHelpEmailHook } from '~/customHooks/useGetHelpEmail/useGetHelpEmail';
import Screen from '~/screens/Screen';
import { scrollReaction } from '~/utils/scrollReaction';
import './style.css';

const InstallGuideDeveloperScreen: React.FC = () => {
  const items = [
    {
      key: '1',
      title: '1. Подключение через CMS-систему сайта (на примере Tilda)',
      scrollToId: 'link',
    },
    {
      key: '2',
      title: '2. Подключение через Google Tag Manager',
      scrollToId: 'tag',
    },
    { key: '3', title: '3. Подключение через HTML ', scrollToId: 'html' },
  ];

  const [value, changeValue] = useState<string>('1');

  const reaction = () => {
    scrollReaction(items, changeValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(reaction, 50));

    return () => {
      window.removeEventListener('scroll', reaction);
    };
  }, [value]);

  const urlParams = new URL(location.toString()).searchParams;
  const [clientCode, setClientCode] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const response = await Profile.clientCode(urlParams.get('analyticsId'));
        setClientCode(response?.data?.payload[0]?.counter);
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  const clientCodeComponent = <ToCopyArea text={clientCode} />;

  const helpEmail = useGetHelpEmailHook();

  return (
    <Screen className="guide-html-screen developer-install">
      <div className="guide-html__container">
        <Button
          type="text"
          icon="ArrowMLeft"
          size="x-small"
          to="/guide/developer"
        >
          Назад
        </Button>
        <h1 className="guide-html__title">
          Инструкция по подключению «СберЛид» к сайту
        </h1>
        <h2 className="guide-html__subtitle" id={items[0].scrollToId}>
          Вариант 1. Подключение через CMS-систему сайта (на примере Tilda)
        </h2>
        <p className="guide-html-list__item-txt">
          1. Перейдите в административную панель сайта, в раздел «Настройки»
          (или аналогичный раздел для администрирования в вашей CMS), выберите
          нужный проект (сайт), если их несколько.
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/cms2.jpg"
            alt=""
            width="860"
            height="552"
            className="developer-install-image"
          />
        </div>
        <p className="guide-html-list__item-txt">
          2. В открывшемся разделе найдите и откройте редактор кода. В Tilda
          разделе «Еще» нажмите «Редактировать код» в разделе “HTML-код для
          вставки внутрь head”
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/cms3.jpg"
            alt=""
            width="860"
            height="526"
            className="developer-install-image"
          />
        </div>
        <p className="guide-html-list__item-txt">
          3. Скопируйте код подключения СберЛид (весь текст из блока ниже)
        </p>
        {clientCodeComponent}
        <p className="guide-html-list__item-txt">
          4. Вставьте скопированный код первой строкой (или сразу после тега ,
          если он есть) и нажмите Сохранить
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/cms4.jpg"
            alt=""
            width="860"
            height="522"
            className="developer-install-image"
          />
        </div>
        <p className="guide-html-list__item-txt">
          5. Примените изменения, нажмите «Опубликовать все страницы»
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/cms5.jpg"
            alt=""
            width="860"
            height="526"
            className="developer-install-image"
          />
        </div>
        <p className="guide-html-list__item-txt">
          6. Зайдите в ЛК СберЛид по адресу lk.sberlead.ru, проверьте
          подключение: при правильном подключении через 2 часа после добавления
          кода вы увидите данныев разделе Главная.
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i5.png"
            alt=""
            width="860"
            height="471"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          7. В случае ошибок при подключении или отсутствующих данных в ЛК
          –напишите нам на clickstream@sberbank.ru со скриншотами и описанием
          проблем
        </p>
        <h2
          className="guide-html__subtitle developer-install-indent-top"
          id={items[1].scrollToId}
        >
          Вариант 2. Подключение через Google Tag Manager
        </h2>
        <p className="guide-html-list__item-txt">
          1. Этот способ подойдет в том случае, если вы уже используете GTM на
          своем сайте. Пройдите на стартовую страницу GTM&nbsp;
          <Link to="https://tagmanager.google.com/">
            https://tagmanager.google.com/
          </Link>
          , войдите в свой рабочий аккаунт. В разделе «Рабочая область»
          перейдите в подраздел «Теги» и нажмите на "Создать".
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i6.png"
            alt=""
            width="860"
            height="190"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          2. Назовите тег любым именем и нажмите на изменение конфигурации тега
          (значок карандаша в правом углу)
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i7.png"
            alt=""
            width="860"
            height="165"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          3. Выберите "Пользовательский HTML" тип тега
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i8.png"
            alt=""
            width="400"
            height="572"
            className="developer-install-user-html-image"
          />
        </div>
        <p className="guide-html-list__item-txt">
          4. Скопируйте код подключения СберЛид(весь текст из блока ниже)
        </p>
        {clientCodeComponent}
        <p className="guide-html-list__item-txt">
          5. Вставьте скопированный код в открывшемся окне в поле для скриптов,
          отметьте галочкой "Поддержка функции document write()" и в настройке
          активации тега выберите "Без ограничений"
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i9.png"
            alt=""
            width="860"
            height="414"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          6. Ниже нажмите на редактирование Триггера (карандаш в правом верхнем
          углу).
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i10.png"
            alt=""
            width="860"
            height="322"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          7. В открывшемся окне выберите «All Pages» («Все страницы»).
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i11.png"
            alt=""
            width="860"
            height="101"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          8. После этого сохраните настройку, нажав на кнопку «Сохранить»
          наверху страницы
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i12.png"
            alt=""
            width="860"
            height="129"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          9. Далее для публикации изменений вернитесь в раздел «Обзор» и нажмите
          «Отправить».
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i13.png"
            alt=""
            width="860"
            height="202"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          10. Настройте конфигурацию отправки. В открывшемся окне перетащите
          созданный тег в поле описание версии, введите любое название версии и
          нажмите «Опубликовать».
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i14.png"
            alt=""
            width="860"
            height="446"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          11. Зайдите в ЛК СберЛид по адресу lk.sberlead.ru, проверьте
          подключение: при правильном подключении через 2 часа после добавления
          кода вы увидите данные в разделе Главная.
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i15.png"
            alt=""
            width="860"
            height="472"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          12. В случае ошибок при подключении или отсутствующих данных в ЛК
          –напишите нам на {helpEmail} со скриншотами и
          описанием проблем
        </p>
        <h2
          className="guide-html__subtitle developer-install-indent-top"
          id={items[2].scrollToId}
        >
          Вариант 3. Подключение через HTML
        </h2>
        <p className="guide-html-list__item-txt">
          1. Откройте область(обычно это хостинг),где хранятся ресурсы вашего
          сайта
        </p>
        <p className="guide-html-list__item-txt">
          2. Откройте для редактирования нужный html-файл с корневой страницей
        </p>
        <p className="guide-html-list__item-txt">
          3. Скопируйте код подключения СберЛид(весь текст из блока ниже)
        </p>
        {clientCodeComponent}
        <p className="guide-html-list__item-txt">
          4. Вставьте скопированный код в начало (или сразу после тега{' '}
          {'<head>'}) и сохраните изменения в html файле
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i16.png"
            alt=""
            width="860"
            height="283"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          5. Зайдите в ЛК СберЛид по адресу lk.sberlead.ru, проверьте
          подключение: при правильном подключении через 2 часа после добавления
          кода вы увидите данныев разделе Главная
        </p>
        <div className="developer-install__img-wrapper">
          <img
            src="./../assets/images/i17.png"
            alt=""
            width="860"
            height="472"
            className="developer-install-image _border-radius"
          />
        </div>
        <p className="guide-html-list__item-txt">
          6. В случае ошибок при подключении или отсутствующих данных в ЛК
          –напишите нам на {helpEmail} со скриншотами и
          описанием проблемы
        </p>
      </div>
      <Tabs
        items={items}
        value={value}
        onChange={changeValue}
        direction="vertical"
        className="guide-html__tabs"
        withScroll
      />
    </Screen>
  );
};

export default InstallGuideDeveloperScreen;
