import React from 'react';
import TextAreaWithNote, { TextAreaWithNoteProps } from '~/components/ui/TextArea/TextAreaWithNote';

interface TextTitleProps {
  textAreaWithNoteProps?: TextAreaWithNoteProps;
  value?: string;
  onChange?: (value: string) => void;
  hint?: string;
}

const TextTitleBlock: React.FC<TextTitleProps> = ({
  textAreaWithNoteProps,
  value,
  onChange,
  hint,
}) => {
  return (
    <div className="text-title-block">
      <TextAreaWithNote
        title="Текст заголовка"
        customNote="Не больше 50 символов"
        textAreaProps={{
          placeholder: 'Дарим скидку 10% на всё!',
          maxLength: 50,
          rows: 2,
          value,
          onChangeValue: onChange,
        }}
        hint={hint}
        {...textAreaWithNoteProps}
      />
    </div>
  );
};

export default TextTitleBlock;
