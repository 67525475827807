import React from 'react';
import { HorizontalScroll } from '~/components/generic/HorizontalScroll';
import { CommonStatistic } from '~/screens/QuizScreen/components/CommonStatistic';
import {
  getStatistic,
  TStatisticFromBack,
} from '~/screens/QuizScreen/components/QuizList/QuizItem';
import { Question } from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics/Question';
import {
  QuizStore,
} from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const QuestionsStatistics = () => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const { detailStatistic } = quizStore;
  const { questionsStatistic } = detailStatistic || {};

  if (!questionsStatistic?.length) return <div>Статистики пока нет</div>;

  const { common } = detailStatistic || {};

  return (
    <div>
      <CommonStatistic
        statistics={
          getStatistic(
            common as unknown as TStatisticFromBack // TODO typings
          ) || []
        }
      />
      <div className={styles.questionsWrap}>
        <HorizontalScroll>
          <>
            {questionsStatistic?.map((v, k) => (
              <>
                {/* @ts-ignore TODO typings */}
                <Question {...v} key={k} />
              </>
            ))}
          </>
        </HorizontalScroll>
      </div>
    </div>
  );
};
