import React from 'react';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FormItem from '~/components/ui/FormItem';
import commonStyles from '../style.module.css';

const LetterEditElement: React.FC<IEditElementProps> = ({ configuration, onEdit }) => {

  return (
    <>
      <div className={commonStyles.head}>{configuration.uiName}</div>

      <section className={commonStyles.section}>
        <FormItem
          title="Общий фон письма"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={configuration.params.backgroundColor}
            alpha={Math.round((configuration.params?.backgroundOpacity || 1) * 100)}
            onChange={({ alpha, color }) => {
              onEdit(configuration.name, 'backgroundColor', color);
              onEdit(configuration.name, 'backgroundOpacity', alpha / 100);
            }}
          />
        </FormItem>
      </section>
    </>
  );
};

export default LetterEditElement;
