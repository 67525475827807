import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconWithTooltip from '~/components/IconWithTooltip';
import DubleInfoBlock from '~/components/generic/DubleInfoBlock';
import Button from '~/components/ui/Button';
import styles from './style.module.css';

interface IParamBlockProps {
  infoDesc: string;
  paramName: string;
  param: string;
  buttonName: string;
  tooltip: string;
  className?: string;
}

const ParamBlock: React.FC<IParamBlockProps> = ({
  infoDesc,
  paramName,
  param,
  buttonName,
  tooltip,
  className = null,
}) => {
  return (
    <DubleInfoBlock
      className={className ? className : ''}
      topContent={(
        <div className={styles.top_info_block}>
          <div className={styles.info_desc}>
            {infoDesc}
            <IconWithTooltip
              iconProps={{ name: 'Question' }}
              tooltipProps={{ title: tooltip, placement: 'bottomLeft' }}
            />
          </div>
          <div className={styles.param_name}>{paramName}</div>
          <div className={styles.param}>{param}</div>
        </div>
      )}
      bottomContent={(
        <CopyToClipboard text={param}>
          <Button
            block
            size={'x-small'}
            icon="Copy"
            type="text"
            style={{
              color: 'var(--Green-400)',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              margin: '-5px 0',
            }}
          >
            {buttonName}
          </Button>
        </CopyToClipboard>
      )}
    />
  );
};

export default ParamBlock;
