import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { clickform } from '~/constants';
import { TariffStore } from '~/stores/tariffStore';
import { urlTruncateProtocol } from '~/utils/urlTruncateProtocol';
import useStores from '~/utils/useStores';
import { validateDomain } from '~/utils/validationUtils/domain';
import './style.css';

interface ISecondStep {
  filledDomain: string;
  onSend: () => void;
}

const showErrorMessage = (message: string) => {
  if (message.trim() === 'ROW_ALREADY_EXISTS') {
    return 'Доменное имя уже добавлено';
  }

  if (message.includes('Instantiation of ')) {
    return 'Поле не заполнено. Введите адрес вашего сайта';
  }

  if (message.includes('INNER_ARGUMENTS_FAIL')) {
    return 'Некорректное доменное имя';
  }

  return message;
};

export const SecondStepOfChoosingTariff = observer((props: ISecondStep) => {
  const { tariffStore } = useStores() as { tariffStore: TariffStore };

  const { onSend, filledDomain } = props;

  const [showCheckmark, setShowCheckmark] = useState(false);
  const [newDomain, setNewDomain] = useState<string>(filledDomain);

  const onChange = (insertedDomain: string) => {
    setShowCheckmark(validateDomain(insertedDomain));
    setNewDomain(insertedDomain);
    tariffStore.setDomain(insertedDomain);
  };

  const isErrorMessage =
    tariffStore.domainErrorMessage || tariffStore.errorMessage;

  return (
    <div className="second-step">
      <span className="_header _enter-address">Укажите адрес вашего сайта</span>
      <div className="_items">
        <div className="_item">
          <Input
            className="_input"
            failure={!!tariffStore.domainErrorMessage}
            placeholder="exampleshop.ru"
            disabled={!!filledDomain}
            success={showCheckmark}
            onChangeValue={insertedDomain =>
              onChange(urlTruncateProtocol(insertedDomain))
            }
            value={newDomain}
          />
        </div>
        <div className={cx('attendance__form-row', '_error')}>
          {isErrorMessage &&
            showErrorMessage(
              tariffStore.domainErrorMessage || tariffStore.errorMessage
            )}
        </div>
        <div className="_item">
          <div className="_description">
            В случае, если у вас более 200 000 посещений в месяц,<br />
            <a href={clickform}>оставьте заявку на индивидуальный расчет.</a>
          </div>
        </div>
        <div className="_item">
          <Button
            className="_button"
            size="large"
            type="main"
            tag="button"
            onClick={onSend}
            loading={tariffStore.loading}
          >
            {tariffStore.sendButtonName}
          </Button>
        </div>
      </div>
    </div>
  );
});
