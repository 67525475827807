import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

const СhoiceInfo: React.FC<IChoiceInfoProps> = ({ device }) => (
  <>
    <div className="feed-editor-step-selector-device-sidebar-choice-info__title">
      Выберите место на сайте для ленты рекомендаций
    </div>
    <div className="feed-editor-step-selector-device-sidebar-choice-info__text">
      Пролистните страницу до нужного места, наведите курсор мыши на нужную
      область и кликните по ней. В этом месте появится блок с примером ленты
      рекомендаций.
    </div>
    {device === 'mobile' && <Icon className="feed-editor-placement__icon" name="FeedPlacementMobile" />}
    {device === 'desktop' && <Icon className="feed-editor-placement__icon" name="FeedPlacement" />}
  </>
);

export default СhoiceInfo;
