import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icons } from '~/components/Icon';
import { Promo } from '~/components/generic/Promo';
import { TButton } from '~/components/ui/ButtonLike';
import { promoMaterials } from '~/screens/ScenariosScreen/EmptyScreen/consts';
import { IEmptyScreen } from '~/screens/ScenariosScreen/EmptyScreen/types';
import { SettingsStore } from '~/stores/settingsStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

export const EmptyScreen = observer(
  ({
    isFeedback,
    onCreate,
    isRightsForWidgets,
    onVideoShown,
  }: IEmptyScreen) => {
    const { settingsStore } = useStores() as { settingsStore: SettingsStore };

    const [isNonIndividual, setIsNonIndividual] = useState<boolean>(false);
    const [isActionButtonLoading, setIsActionButtonLoading] =
      useState<boolean>(!isRightsForWidgets);

    useEffect(() => {

      if (isRightsForWidgets) return;

      (async () => {
        setIsActionButtonLoading(true);
        await settingsStore.getServices();

        const isNonIndividualCheck = !!settingsStore.services.filter((v, k) => {
          return v.code === 'SCENARIO_SRV' && v.price !== 0 && !v.isActive;
        })?.length;

        setIsNonIndividual(isNonIndividualCheck);
        setIsActionButtonLoading(false);

      })();
    }, []);

    const history = useHistory();

    const type = isFeedback ? 'leads' : 'widgets';

    const onRedirect = () => {
      if (!isNonIndividual) {
        redirectToClickform();
      }

      history.push('/settings');
    };

    const actionButton = () => {
      if (isActionButtonLoading) {
        return {
          type: 'default' as TButton,
          name: '',
          loading: isActionButtonLoading,
        };
      }

      return isRightsForWidgets
        ? {
          icon: 'Plus' as Icons,
          type: 'default' as TButton,
          onClick: onCreate,
          name: isFeedback ? 'Создать окно сбора заявок' : 'Создать виджет',
          loading: isActionButtonLoading,
        }
        : {
          icon: 'Plus' as Icons,
          type: 'default' as TButton,
          onClick: onRedirect,
          name: isNonIndividual ? 'Подключить' : 'Создать заявку',
          loading: isActionButtonLoading,
        };
    };

    const videoShownButton = {
      icon: 'PlayOutline' as Icons,
      type: 'green-outline' as TButton,
      onClick: onVideoShown,
      name: isFeedback
        ? 'Как создать окно сбора заявок?'
        : 'Как создать виджет?',
    };

    const actions = [actionButton(), videoShownButton];

    const { header, subheader, img, items, text1, text2 } =
      promoMaterials[type];

    return (
      <Promo
        actions={actions}
        header={header}
        img={img}
        items={items}
        subheader={subheader}
        text1={text1}
        text2={text2}
      />
    );
  }
);
