import { AxiosPromise } from 'axios';
import { actionGet, post } from './client';

interface ClientCodePayload {
  '@type': string;
  counter: string;
  analyticsId: string;
}

export interface IAgreementsTot {
  tot: boolean;
  sber: boolean;
}

export interface IAgreementsSbs {
  sbs: boolean;
  sber: boolean;
}

export type TConsentToMarketing = IAgreementsSbs | IAgreementsTot;

interface IUserAgreements {
  '@type': string;
  data: TConsentToMarketing;
}

type ClientCodeResponse = IResponse<ClientCodePayload[]>;

type CloseContractResponse = IResponse<CloseContractPayload>;

type GetUserAgreementsResponse = IResponse<IUserAgreements[]>;

const Profile = {
  clientCode: (analyticsId: string): AxiosPromise<ClientCodeResponse> => actionGet('/admin/ClientCode', {
    action: 'get',
    analyticsId,
  }),

  closeContract: (): AxiosPromise<CloseContractResponse> => post('/admin/contractClose', { action: 'set' }),

  getUserAgreements: (): AxiosPromise<GetUserAgreementsResponse> => post('/admin/UserAgreements', { action: 'get' }),

  setUserAgreements: (payload: IUserAgreements) => post('/admin/UserAgreements', { action: 'set', payload }),

};

export default Profile;
