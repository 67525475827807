import { IFeedConfiguration } from 'feed';
import React, { useEffect, useState } from 'react';
import Caption from '~/components/generic/Caption';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import './style.css';

const FunctionName: React.FC<{
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
}> = ({ configuration, onChange, onBlur }) => {
  const DESCRIPTION_MESSAGE =
    'Например для добавления в корзину: clientProductAddToBasket';

  const [isActiveCheckBox, setIsActiveCheckBox] = useState<boolean>(false);
  const [isErrorMessage, setIsErroeMessage] = useState<boolean>(false);
  const [descriptionMessage, setDescriptionMessage] = useState<string>(
    DESCRIPTION_MESSAGE
  );

  useEffect(() => {
    setIsActiveCheckBox(!!configuration.clickFunctionName);
  }, []);

  const toggleCheckBox = () => {
    if (isActiveCheckBox) {
      onChange({ ...configuration, clickFunctionName: null });
    }
    setIsActiveCheckBox(!isActiveCheckBox);
  };

  const isValidFunctionName = (functionName: string): boolean => {
    if (functionName === '') return true;
    if (typeof functionName !== 'string') return false;
    if (!/^([$_]|[^\0-/])+$/.test(functionName)) return false;
    try {
      const test = new Function(`let ${functionName}`);

      return !!test;
    } catch (error) {
      return false;
    }
  };

  const validationFunctionName = (functionName: string): void => {
    if (isValidFunctionName(functionName)) {
      setDescriptionMessage(DESCRIPTION_MESSAGE);
      setIsErroeMessage(false);

      return;
    }

    setDescriptionMessage('Ошибка в имени функции!');
    setIsErroeMessage(true);
  };

  return (
    <>
      <FormItem
        overlayClassName="feed-editor-appearance__tooltip"
        noHTMLLabel
        message={'Кликом по кнопке в товаре будет вызвана указанная функция'}
        className="feed-editor-appearance__font"
      >
        <Checkbox
          label="Добавить функцию кнопке"
          className="feed-editor-appearance__checkbox"
          checked={isActiveCheckBox}
          onCheck={toggleCheckBox}
          hint={
            'Клик по кнопке вызовет переданную Вами функцию из глобальной переменной window и передаст в нее productId'
          }
        />
      </FormItem>
      {isActiveCheckBox && (
        <>
          <FormItem
            title={'Функция кнопки'}
            overlayClassName="feed-editor-appearance__tooltip"
            noHTMLLabel
            message={descriptionMessage}
            failure={isErrorMessage}
            className="feed-editor-appearance__font"
          >
            <Input
              value={configuration.clickFunctionName}
              onChangeValue={(value) => {
                onChange({ ...configuration, clickFunctionName: value });
                validationFunctionName(value);
              }}
              maxLength={25}
              onBlur={() => onBlur(configuration)}
              block
              placeholder={'clientProductAddToBasket'}
              failure={isErrorMessage}
            />
          </FormItem>
          <Caption backgroundColor={'yellow'} className={'feed-editor-appearance-button__caption'}>
            Функция может вернуть новый текст для кнопки (String)
          </Caption>
        </>
      )}
    </>
  );
};

export default FunctionName;
