import cx from 'classnames';
import { IFeedConfiguration, TFontSize } from 'feed';
import RcColorPicker from 'rc-color-picker';
import React, { useCallback, useEffect, useRef, useState, ReactNode } from 'react';
import Icon from '~/components/Icon';
import Label from '~/components/notification-editor/LabelColorEditor/Label';
import Checkbox from '~/components/ui/CheckBox';
import Input from '~/components/ui/Input';
import Tooltip from '~/components/ui/Tooltip';
import {
  alphaStepVerification,
  colorToView,
  completeHexCode,
  formatColor,
  isValidHexColor,
} from '~/utils/hexUtils';
import './style.css';

interface ColorData {
  color?: string;
  alpha?: number;
}

export interface ILabelColorEditorData {
  labelColor: string;
  labelOpacity: number;
}

interface ILabelColorEditorProps {
  text: string;
  labelBackgroundColor: string;
  labelBackgroundOpacity: number;
  feedConfiguration: IFeedConfiguration;
  onChangeColor: (data: ILabelColorEditorData) => void;
  onChangeName: (name: string) => void;
  isPossibleTextEditing?: boolean;
  step?: number;
  isShowCheckBox?: boolean;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
  disabledCheckBox?: boolean;
  isShowTrashButton?: boolean;
  disabledTrashIcon?: boolean;
  onDelete?: () => void;
  hasAlias?: boolean;
  aliasName?: string;
  onChangeAlias?: (name: string) => void;
  hint?: ReactNode;
  placeholder?: string;
  isShowMoveIcon?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const LabelColorEditor: React.FC<ILabelColorEditorProps> = ({
  text,
  labelBackgroundColor,
  labelBackgroundOpacity = 100,
  feedConfiguration,
  onChangeColor,
  isPossibleTextEditing = false,
  onChangeName = null,
  step = 10,
  isShowCheckBox = false,
  checked = false,
  onCheck = null,
  disabledCheckBox = false,
  isShowTrashButton = false,
  disabledTrashIcon = false,
  onDelete = null,
  hasAlias = false,
  aliasName = '',
  onChangeAlias = null,
  hint = null,
  placeholder = '',
  isShowMoveIcon = false,
  onOpen,
  onClose,
}) => {
  const {
    labelTextColor,
    labelTextColorOpacity = 100,
    labelFontFamily,
    labelFontWeight,
    labelFontSize,
    labelFontItalic,
    labelTextLineThrough,
    labelTextUnderline,
  } = feedConfiguration;
  const labelColorEditorRef = useRef<HTMLDivElement>(null);
  const labelInputRef = useRef(null);
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const [colorHexValue, setColorHexValue] = useState<string>(
    formatColor(labelBackgroundColor, true)
  );
  const [alphaValue, setAlphatValue] = useState<number>(labelBackgroundOpacity ?? 100);
  const [prevSuccessValue, setPrevSuccessValue] = useState(
    formatColor(labelBackgroundColor, true)
  );

  useEffect(() => {
    labelInputRef?.current?.focus();
    setColorHexValue(formatColor(labelBackgroundColor, true));
    setIsChecked(checked);
  }, [isEditingMode, checked, text, labelBackgroundColor]);

  const onPickerChange = useCallback(
    (colorData: ColorData) => {
      const [labelColor, labelOpacity] = [colorData.color, colorData.alpha];
      const alphaVerified = alphaStepVerification(labelOpacity, step);
      setAlphatValue(alphaVerified);
      setColorHexValue(labelColor);
      onChangeColor({ labelColor, labelOpacity: alphaVerified });
    },
    [labelBackgroundColor, labelBackgroundOpacity ?? 100, onChangeColor]
  );

  const onColorBlur = useCallback(() => {
    const completedColor = completeHexCode(colorHexValue);
    const finalColorValue = isValidHexColor(completedColor)
      ? completedColor
      : prevSuccessValue;
    setPrevSuccessValue(finalColorValue);
    setColorHexValue(finalColorValue);
    onChangeColor({
      labelColor: finalColorValue,
      labelOpacity: alphaValue,
    });
  }, [colorHexValue, alphaValue, onChangeColor]);

  const onColorFocus = useCallback(
    () => setColorHexValue(formatColor(labelBackgroundColor)),
    [labelBackgroundColor]
  );

  const onColorChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const tempColor: string = e.currentTarget.value;
      const currentBackgroundColor =
        tempColor[0] === '#' ? tempColor.slice(1) : tempColor;
      if (
        currentBackgroundColor.length > 0 &&
        !/^[0-9A-F]+$/i.test(currentBackgroundColor)
      ) {
        return;
      }
      setColorHexValue(currentBackgroundColor);
    },
    [labelBackgroundColor]
  );

  const onClickEditorIcon = (status: boolean) => {
    setIsEditingMode(status);
  };

  const elemAfterHint = !!hint ? (
    <Tooltip placement={'rightTop'} title={hint} width={'310px'}>
      <Icon name={'Question'} style={{ width: 24 }} />
    </Tooltip>
  ) : '';

  return (
    <div className="label-color-editor__wrapper">
      {isShowMoveIcon && (
        <Icon name="Move" className="label-color-editor__icon-move" />
      )}
      <div className="label-color-editor__block" ref={labelColorEditorRef}>
        {isShowCheckBox && (
          <Checkbox
            className={'label-color-editor__checkbox'}
            checked={isChecked}
            onCheck={(checked) => {
              setIsChecked(checked);
              if (onCheck) onCheck(checked);
            }}
            disabled={disabledCheckBox}
          />
        )}
        <div className="label-color-editor__label">
          {!isEditingMode && (
            <Label
              text={text}
              labelBackgroundColor={colorToView(colorHexValue)}
              labelBackgroundOpacity={alphaValue}
              labelShadow={false}
              labelTextColor={labelTextColor}
              labelTextColorOpacity={labelTextColorOpacity ?? 100}
              labelFontFamily={labelFontFamily}
              labelFontWeight={labelFontWeight}
              labelFontSize={labelFontSize as TFontSize}
              labelFontItalic={labelFontItalic}
              labelTextLineThrough={labelTextLineThrough}
              labelTextUnderline={labelTextUnderline}
              onClick={() => isPossibleTextEditing && onClickEditorIcon(true)}
            />
          )}
          {isEditingMode && (
            <Input
              ref={labelInputRef}
              className={'label-color-editor__input'}
              value={text ?? ''}
              maxLength={12}
              onChangeValue={onChangeName} 
              onBlur={() => {
                setIsEditingMode(false);
              }}
            />
          )}
          {isPossibleTextEditing && !isEditingMode && (
            <Icon
              name={'Pencil'}
              className={'label-color-editor__icon'}
              onClick={() => onClickEditorIcon(true)}
            />
          )}
        </div>
        <Input
          className={cx('label-color-editor__hex')}
          value={colorHexValue ?? ''}
          maxLength={7}
          elemBefore={(
            <RcColorPicker
              className={'label-color-editor__color-button'}
              color={colorToView(colorHexValue)}
              alpha={alphaValue}
              style={{ zIndex: 1004 }}
              onChange={onPickerChange}
              onBlur={onPickerChange}
              getCalendarContainer={() => labelColorEditorRef.current}
              onOpen={onOpen}
              onClose={onClose}
            />
          )}
          onBlur={onColorBlur}
          onFocus={onColorFocus}
          onChange={onColorChange}
        />
      </div>
      {hasAlias && (
        <Input
          className={'label-color-editor__alias'}
          elemAfter={elemAfterHint}
          placeholder={placeholder}
          value={aliasName ?? ''}
          onChangeValue={onChangeAlias}
        />
      )}
      {isShowTrashButton && (
        <Icon name={'Trash'} className={cx('label-color-editor__trash-icon', {
          _disabled: disabledTrashIcon,
        })} onClick={() => onDelete !== null && !disabledTrashIcon ? onDelete() : undefined} />
      )}
    </div>
  );
};

export default LabelColorEditor;
