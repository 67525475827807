import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import './style.css';

interface BorderRadiusSelectorProps {
  borderRadius?: number;
  onChangeValue?: (value: number) => void | PromiseLike<any>;
  hint?: string;
}

const getValueInRange = (value: number) => {
  const MAXIMUM_PIXELS = 32;

  if (value > MAXIMUM_PIXELS) {
    return MAXIMUM_PIXELS;
  }

  return Math.abs(value);
};


const BorderRadiusSelector: React.FC<BorderRadiusSelectorProps> = ({ onChangeValue, borderRadius, hint }) => {

  const handleChange = useCallback((text: string) => {
    if (!text.length) {
      onChangeValue?.(0);
    }

    if (isNaN(parseInt(text, 10))) {
      return;
    }

    if (parseInt(text, 10).toString() !== text && text[0] !== '0') {
      return;
    }

    const trimmed = (text || '0').substring(0, 5);
    const parseValue = parseInt(trimmed, 10) || 0;
    const value = getValueInRange(parseValue);
    onChangeValue?.(value);
  }, [borderRadius, onChangeValue]);

  return (
    <div className="border-radius-selector">
      <FormItem
        hint={hint}
        noHTMLLabel
        noReservedPlaceForMessage
        title="Скругление углов"
      >
        <Input
          type="tel"
          size="default"
          className="border-radius-selector__input"
          elemBefore={<Icon name="BorderRadius" className="border-radius-selector__icon" />}
          value={borderRadius?.toString() || ''}
          onChangeValue={handleChange}
        />
      </FormItem>
    </div>
  );
};

export default BorderRadiusSelector;
