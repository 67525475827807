import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import Button from '~/components/ui/Button';
import './style.css';

interface IFooterButtonProps {
  isIFrameAccessible: boolean;
  setIsIFrameAccessible: (status: boolean) => void;
}

const FooterButton: React.FC<IFooterButtonProps> = ({
  isIFrameAccessible,
  setIsIFrameAccessible,
}) => (
  <Button
    className="component-generic-selector-design-footer-button__button"
    type="text"
    onClick={() => {
      setIsIFrameAccessible(!isIFrameAccessible);
    }}
    icon={isIFrameAccessible ? 'IconNoFrame' : 'IconFrame'}
  >
    <div className={'component-generic-selector-design-footer-button__content'}>
      {isIFrameAccessible
        ? 'Выбрать расположение в отдельной вкладке или на смартфоне'
        : 'Выбрать расположение во встроенном окне'}
      <IconWithTooltip
        tooltipProps={{
          placement: 'bottomLeft',
          title: `${
            isIFrameAccessible
              ? 'Если ваш сайт не грузится во встроенном окне или мобильная версия отображается некорректно, нажмите на эту кнопку.'
              : 'Если вам нужно переключиться на изначальный вид сайта во встроенном окне, нажмите на эту кнопку'
          }`,
          width: '298px',
        }}
        iconProps={{
          name: 'Question',
        }}
        iconWrapperClassName={
          'component-generic-selector-design-footer-button__tooltip'
        }
      />
    </div>
  </Button>
);

export default FooterButton;
