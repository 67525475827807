import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentCrossedPriceCssParam = (configuration: IFeedConfiguration): string => {
  const {
    cardCrossedPriceFontFamily,
    cardCrossedPriceColor,
    cardCrossedPriceOpacity,
    cardCrossedPriceFontWeight,
    cardCrossedPriceFontSize,
    cardCrossedPriceFontItalic,
    cardCrossedPriceTextLineThrough,
    cardCrossedPriceTextUnderline,
  } = configuration;
  
  return `
    font-family: ${cardCrossedPriceFontFamily};
    color: ${hexToRgb(
      cardCrossedPriceColor,
      cardCrossedPriceOpacity
    )};
    font-weight: ${fontWeightValues[cardCrossedPriceFontWeight]};
    font-size: ${cardCrossedPriceFontSize}px;
    font-style: ${cardCrossedPriceFontItalic
      ? 'italic'
      : 'normal'
    };
    text-decoration: ${getTextDecoration(
      cardCrossedPriceTextUnderline,
      cardCrossedPriceTextLineThrough
    )};`
    .replace(/(\r\n|\n|\r|↵)/gm, '')
    .trim();
};