import React, { useState } from 'react';
import HelpBlock from '~/components/generic/HelpBlock';
import Drawer from '~/components/ui/Drawer';
import SwitchButton from '~/components/ui/SwitchButton';
import { useGetHelpEmailHook } from '~/customHooks/useGetHelpEmail/useGetHelpEmail';
import { CMSInstructions } from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/CMSInstructions';
import {
  CreatingInstructions
} from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/CreatingInstructions';
import {
  switchButtonsInstructions
} from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/consts';
import { IInstructions } from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/types';
import styles from './style.module.css';


export const Instructions = ({
  isInstructionsShown,
  hideInstructions,
}: IInstructions) => {
  const [activeKey, setActiveKey] = useState<string>('CMS');

  return (
    <Drawer visible={isInstructionsShown} onClose={hideInstructions}>
      <div className={styles.instructionsWrap}>
        <div className={styles.instructions}>
          <div className={styles.header}>Как создать и добавить sitemap</div>
          <SwitchButton
            activeKey={activeKey}
            onClick={setActiveKey}
            buttons={switchButtonsInstructions}
            className={styles.switcher}
            isEqualWidth={false}
          />
          {activeKey === 'CMS' && <CMSInstructions />}
          {activeKey === 'creating' && <CreatingInstructions />}
        </div>
        <HelpBlock
          mail={useGetHelpEmailHook()}
          title="Если возникли сложности свяжитесь с нами и мы поможем с подключением"
        />
      </div>
    </Drawer>
  );
};
