import cx from 'classnames';
import React from 'react';
import styles from './style.module.css';

interface ICodeElement {
  text: string;
  color: string;
}

type ILine = ICodeElement[];

interface ICode {
  lines: ILine[];
  className?: string;
}

export const Code = ({ lines, className }: ICode) => {
  return (
    <div className={cx(styles.code, className)}>
      {lines.map((line, key) => (
        <div key={key}>
          {line.map(({ text, color }, k) => (
            <span key={k} style={{ color }}>
              {text}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};
