import axios, { AxiosRequestConfig } from 'axios';
import { isObservableObject, toJS } from 'mobx';
import eventEmitter from '~/utils/eventEmitter';
import logger from '~/utils/logger';

export const path = (path: string) => {
  const resultBaseUrl = path.includes('/api/unsubscribe')
    ? '/clickstream-analytics-email-bh'
    : process.env.API_PATH;

  return `${resultBaseUrl || ''}${path}`;
};

const log = logger.register('API', 10);
const err = logger.register('API Error', 15);

const createREST = (method: 'POST' | 'GET' | 'PATCH' | 'PUT' | 'DELETE') => {
  return async <T>(
    tail: string,
    data?: Record<string, any>,
    absolutePath?: boolean
  ) => {
    const timeStart = new Date().getTime();

    let logData = isObservableObject(data) ? toJS(data) : data;

    if (logData && ['POST', 'PATCH', 'PUT'].includes(method)) {
      if (logData.password) {
        logData = Object.assign({}, logData, { password: '' });
      }
    }

    log('←', method, tail, logData);

    const config: AxiosRequestConfig = {
      method,
      data,
      url: absolutePath ? tail : path(tail),
    };

    const token = localStorage.getItem('token');
    if (token) {
      config.headers = { 'X-CSRF-Token': token };
    }

    if (method === 'GET') {
      delete config.data;
      config.params = data;
    }

    if (method === 'POST') {
      config.withCredentials = true;
    }

    try {
      const res = await axios(config);

      if (
        res &&
        res.data &&
        res.data.errorData &&
        res.data.errorData.errorFlag
      ) {
        throw { response: res };
      }

      const delta = new Date().getTime() - timeStart;

      log(
        '→',
        method,
        tail,
        `— ${res.status}`,
        `— ${delta.toFixed(0)}ms`,
        res.data
      );

      return res;
    } catch (e) {
      const res = e.response;

      if (e.message === 'Network Error') {
        // throw ...
      }

      if (!res) throw e;

      const delta = new Date().getTime() - timeStart;
      err('→', method, tail, `— ${res.status}`, `— ${delta}ms`, res.data);

      if (res?.data?.errorData?.errorType === 'UNAUTHORIZED') {
        eventEmitter.emit('logout');
      } else {
        throw e;
      }

      return res;
    }
  };
};

export const get = createREST('GET');
export const post = createREST('POST');
export const patch = createREST('PATCH');
export const put = createREST('PUT');
export const del = createREST('DELETE');

export const actionSet = <T, K>(tail: string, requestData: T, payload: K) => {
  return post(tail, { action: 'set', requestData, payload });
};

export const actionGet = <T = Record<string, string>>(
  tail: string,
  requestData: T,
  payload?: any
) => {
  return post(tail, { action: 'get', requestData, payload });
};
