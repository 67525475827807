import { action, get } from 'mobx';
import moment from 'moment';
import Model from '~/models/Model';
import { initialMailInstance } from '~/screens/MassMailingScreen/stores/initial';

export default class MassMailingModel extends Model<IMassMailingModel> {
  selectSection = (name: TMassMailItemName) => {
    this.update({
      configuration: this.get('configuration').map(item => ({
        ...item,
        selected: name === item.name,
      })),
    });
  }

  @get
  getSelectedSection = () => {
    const hasSelectedSection = this.get('configuration').filter(
      item => item.selected
    );

    return hasSelectedSection?.length ? hasSelectedSection[0] : undefined;
  }

  changeValue = (sectionName: string, propName: string, value: string) => {
    this.update({
      configuration: this.get('configuration').map((item) => {
        if (sectionName === item.name) {
          return { ...item, params: { ...item.params, [propName]: value } };
        } else return item;
      }),
    });
  }

  getConvertedConfiguration = (configuration: IMassMailConfiguration[]): IMassMailConfiguration[] => {
    return configuration.map((item: IMassMailConfiguration) => {
      return { ...item, configuration: undefined, selected: item.type === 'letter' };
    });
  }

  @action
  clearDataToSend = () => {
    this.update(initialMailInstance);
    this.parent.selectedId = undefined;
  }

  @get
  getDataToSend = (): IMassMailingToSendTemplate => ({
    '@type': this.get('type'),
    'id': this.get('id'),
    'name': this.get('name'),
    'senderEmailAddress': this.get('authorizationEmail')?.address || '',
    'emailTemplate': {
      code: '_',
      data: {
        configuration: this.getConvertedConfiguration(
          this.get('configuration')
        ),
      },
    },
    'emailTrigger': {
      '@type': 'MassMail',
      'startSendDateTime':
        this.get('authorizationEmail')?.startSendDateTime ??
          moment().add(15, 'm').toISOString(true),
      'emailData': this.get('authorizationEmail')?.emailData || {
        status: 'NOT_MODIFIED',
        data: [],
      },
      'emailTopic': this.get('authorizationEmail')?.emailTopic || '',
      'cartUrl':
        (this.get('configuration').filter(v => v.name === 'goToCart')[0].params
          .href ?? '') as string,
    },
    'senderName': this.get('authorizationEmail')?.senderName || '',
    'imageCodes': this.get('imageCodes') || [],
  })
}
