import React from 'react';
import TextAreaWithNote, { TextAreaWithNoteProps } from '~/components/ui/TextArea/TextAreaWithNote';

interface TextTitleProps {
  textAreaWithNoteProps?: TextAreaWithNoteProps;
  value?: string;
  onChange?: (value: string) => void;
  hint?: string;
}

const CouponBlock: React.FC<TextTitleProps> = ({
  textAreaWithNoteProps,
  value,
  onChange,
  hint,
}) => {
  return (
    <div className="coupon-block">
      <TextAreaWithNote
        title="Промокод"
        customNote="Если на вашем сайте при оформлении заказа нельзя ввести промокод, предложите клиентам указывать его в комментариях или сообщать оператору по телефону."
        hint={hint}
        textAreaProps={{
          placeholder: 'ZX6C85',
          maxLength: 12,
          rows: 1,
          value,
          onChangeValue: onChange,
        }}
        {...textAreaWithNoteProps}
      />
    </div>
  );
};

export default CouponBlock;
