import { IFeedConfiguration, TFeedKeyElement } from 'feed';
import React, { useState } from 'react';
import {
  Button,
  CardTitle,
  Categories,
  Content,
  Description,
  FeedTitle,
  General,
  Image,
  Label,
  Price
} from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools';
import ButtonLike from '~/components/ui/ButtonLike';
import Dropdown from '~/components/ui/Dropdown';
import './style.css';

interface IFeedEditorAppearanceProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
  device: Device;
  feedMode: FeedMode;
  activeMenu: StepDesignMenuElements;
  setActiveMenu: (activeMenu: StepDesignMenuElements) => void;
}

const FeedEditorAppearance: React.FC<IFeedEditorAppearanceProps> = ({
  configuration,
  onChange,
  onBlur,
  device,
  feedMode,
  activeMenu,
  setActiveMenu,
}) => {
  const [isFixNextButom, setIsFixNextButom] = useState<boolean>(false);
  const [isCompressMenu, setIsCompressMenu] = useState<boolean>(false);

  const handleChangeActiveMenu = (key: StepDesignMenuElements) => {
    setIsFixNextButom(false);
    setActiveMenu(key);
  };

  const {
    hasFeedTitle,
    hasCardTitle,
    hasCardDescription,
    hasCardPrice,
    hasCardButton,
    isGroupByCategory,
    sortingElements,
    labelMaxCount,
    labels,
  } = configuration;

  const fullItems: StepDesignMenuItem[] = [
    {
      key: 'CONTENT',
      value: 'Содержание ленты',
      checked: true,
      nodeElement: (
        <Content
          handleChangeActiveMenu={handleChangeActiveMenu}
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'GENERAL',
      value: 'Общие настройки',
      checked: true,
      nodeElement: (
        <General
          configuration={configuration}
          onChange={onChange}
          onBlur={onBlur}
          device={device}
        />
      ),
    }, {
      key: 'FEED_TITLE',
      value: 'Заголовок ленты',
      checked: hasFeedTitle,
      nodeElement: (
        <FeedTitle
          configuration={configuration}
          onChange={onChange}
          onBlur={onBlur}
        />
      ),
    }, {
      key: 'CATEGORIES',
      value: 'Категории',
      checked: true,
      nodeElement: (
        <Categories
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'IMAGE',
      value: 'Изображение',
      checked: true,
      nodeElement: (
        <Image
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'LABEL',
      value: 'Теги',
      checked: true,
      nodeElement: (
        <Label
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'CARD_TITLE',
      value: 'Наименование товара',
      checked: hasCardTitle,
      nodeElement: (
        <CardTitle
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'DESCRIPTION',
      value: 'Описание',
      checked: hasCardDescription,
      nodeElement: (
        <Description
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'PRICE',
      value: 'Цена',
      checked: hasCardPrice,
      nodeElement: (
        <Price
          configuration={configuration}
          onChange={onChange}
        />
      ),
    }, {
      key: 'BUTTON',
      value: 'Кнопка',
      checked: hasCardButton,
      nodeElement: (
        <Button
          configuration={configuration}
          onChange={onChange}
          onBlur={onBlur}
        />
      ),
    },
  ];

  const items = fullItems
    .filter(item =>
      item.key === 'CATEGORIES' && !isGroupByCategory
      || item.key === 'LABEL'
        && ((feedMode === 'pro' && (labelMaxCount < 1 
          || labels.filter(label => label.isActive && label.aliasName)?.length <= 0))
        || (feedMode === 'lite' && labels?.length <= 0))
        ? null
        : item
    ).sort((a, b) =>
      sortingElements.indexOf(a.key as TFeedKeyElement) - sortingElements.indexOf(b.key as TFeedKeyElement)
    );
  const activeMenuIndex = items.findIndex(item => item.key === activeMenu);

  const getNextStepIndex = (): number => {
    let nextActiveStep = 0;
    for (
      let index = activeMenuIndex + 1;
      index < items.length;
      index++
    ) {
      const element = items[index];
      if (element.checked) {
        nextActiveStep = index;
        break;
      }
    }

    return nextActiveStep;
  };

  const nextMenu = activeMenuIndex === items.length - 1 ? 0 : getNextStepIndex();
  const showFinalStepMenu = nextMenu ? false : true;
  const nextStepName = `Далее: ${items[nextMenu]?.value}`;

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight;
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    if (scrollHeight - containerHeight - 1 < scrollTop && !showFinalStepMenu) {
      setIsFixNextButom(true);
    }
    setIsCompressMenu(scrollTop > 10 ? true : false);
  };

  return (
    <>
      <div className={`feed-editor-appearance__menu-container ${isCompressMenu ? '__hidden' : ''}`}>
        <div className={`feed-editor-appearance-menu-container__header ${isCompressMenu ? '__hidden' : ''}`}>
          <div className="feed-editor-appearance-menu-container__name">
            Настроить
          </div>
          <div className="feed-editor-appearance-menu-container__counter">
            {activeMenuIndex + 1}/{items.length}
          </div>
        </div>
        <Dropdown
          className="feed-editor-appearance-menu-container__dropdown"
          selectedItemKey={activeMenu}
          items={items}
          onSelect={handleChangeActiveMenu}
          size={'large'}
        />
      </div>
      <div
        className={`feed-editor-appearance__tools ${isCompressMenu ? '__hidden' : ''}`}
        onScroll={scrollHandler}
      >
        {items
          .find(element => element.key === activeMenu)
          ?.nodeElement
        }
        <div className={`feed-editor-appearance__next-step ${isFixNextButom ? '__fix' : ''}`}>
          {showFinalStepMenu && (
            <div className="feed-editor-appearance__final-step">
              <div className="feed-editor-appearance-final-step__title">
                Отлично, вы все заполнили!
              </div>
            </div>
          )}
          {!showFinalStepMenu && (
            <ButtonLike
              className="feed-editor-appearance-next-step__button"
              type={'grey'}
              onClick={() => handleChangeActiveMenu(items[nextMenu].key)}
              block
            >
              {nextStepName.length > 31
                ? nextStepName.slice(0, 31) + '...'
                : nextStepName
              }
            </ButtonLike>
          )}
        </div>
      </div>
    </>
  );
};

export default FeedEditorAppearance;
