import { IQuestionStep } from 'feed';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Chip from '~/components/generic/Chip';
import SwitchButton from '~/components/ui/SwitchButton';
import { QuestionItem } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/QuestionEditor/LibraryOfQuestions/components/QuestionItem';
import { Info } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/Info';
import {
  ESampleType,
  IQuizTemplate,
  QuizStore,
} from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface ILibraryOfQuestions {
  setIsMaxQuestionsAdded: () => void;
}

export const LibraryOfQuestions: React.FC<ILibraryOfQuestions> = observer(
  ({ setIsMaxQuestionsAdded }: ILibraryOfQuestions) => {
    const { quizStore } = useStores() as {
      quizStore: QuizStore;
    };
    const {
      templateData,
      quizTemplates,
      setTemplateData,
      quizExample,
      currentStep,
      sampleType,
    } = quizStore;


    const questions = quizExample
      ?.get('steps')
      .map((v, k) => ({ step: v, key: k }))
      .filter(v => v.step.type === 'question');

    const [selectedName, setSelectedName] = useState<string | undefined>(
      templateData?.labels[0] || 'Все шаблоны'
    );

    const addQuestion = (question: IQuestionStep) => {

      const steps = quizExample.get('steps');

      if (questions.length >= 10) {
        setIsMaxQuestionsAdded();

        return steps;
      }

      const questionStepsLength = steps.filter(
        step => step.type === 'question'
      )?.length;
      steps.splice(questionStepsLength + 1, 0, question);

      return steps;
    };

    const replaceQuestion = (question: IQuestionStep) => {
      const steps = [...quizExample.get('steps')];
      steps.splice(currentStep, 1, question);

      return steps;
    };

    const addOrReplaceQuestion = (
      question: IQuestionStep,
      isNewQuestion: boolean
    ) => {

      quizExample.update({
        steps: isNewQuestion
          ? addQuestion(question)
          : replaceQuestion(question),
      });
    };

    const getFilteredTemplate = (): IQuizTemplate[] => {
      return quizTemplates.filter((template) => {
        if (!sampleType || sampleType === 'CUSTOM')
          return template.type === 'QUIZ';

        return template.type === sampleType;
      });
    };

    const getFilteredQuestions = (): IQuestionStep[] => {
      const filteredQuestions: IQuestionStep[] = [];
      getFilteredTemplate()
        .filter(
          template =>
            template.labels
              .map((label: string) => label.trim())
              .includes(selectedName) || selectedName === 'Все шаблоны'
        )
        .forEach((template) => {
          filteredQuestions.push(
            ...template.body.steps.filter(step => step.type === 'question')
          );
        });

      return filteredQuestions;
    };

    const getFilteredLabels = (): string[] => {
      const filteredLabel: string[] = ['Все шаблоны'];
      getFilteredTemplate().forEach((template) => {
        filteredLabel.push(...template.labels.map(label => label.trim()));
      });

      return Array.from(new Set(filteredLabel));
    };

    return (
      <>
        <div className={styles.wrapper}>
          <SwitchButton
            activeKey={
              sampleType && sampleType !== 'CUSTOM' ? sampleType : 'QUIZ'
            }
            onClick={(key) => {
              quizStore.setSampleType(key as ESampleType);
              setTemplateData({ ...templateData, type: key });
            }}
            buttons={[
              {
                key: 'QUIZ',
                name: 'Викторина',
              },
              {
                key: 'SURVEY',
                name: 'Квиз-опрос',
              },
            ]}
          />
        </div>
        <div className={styles.chips}>
          {getFilteredLabels().map((label, key) => {
            return (
              <Chip
                key={key}
                name={label}
                id={label}
                onClick={setSelectedName}
                isActive={label === selectedName}
              />
            );
          })}
        </div>
        <div className={styles.wrapper}>
          <Info
            text={
              'Постарайтесь добавить к вопросу из нашей библиотеке что-то своё, сделав его уникальным'
            }
          />
        </div>
        <div>
          {getFilteredQuestions().map((question, index) => {
            return (
              <QuestionItem
                key={index.toString()}
                question={question as IQuestionStep}
                onClick={addOrReplaceQuestion}
              />
            );
          })}
        </div>
      </>
    );
  }
);
