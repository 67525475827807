import { ColumnProps } from 'antd/lib/table';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Icon from '~/components/Icon';
import Notification from '~/components/Notification';
import PageHeader from '~/components/PageHeader';
import PresetCard from '~/components/PresetCard';
import ScenarioEditor from '~/components/ScenarioEditor';
import { Video } from '~/components/generic/Video';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import Message from '~/components/ui/Message';
import SidebarTabs, { SidebarTab } from '~/components/ui/SidebarTabs';
import Spinner from '~/components/ui/Spinner';
import Table from '~/components/ui/Table';
import TableCell from '~/components/ui/Table/TableCell';
import Toolbar from '~/components/ui/Toolbar';
import VideoMessage from '~/components/ui/VideoMessage';
import { TARIFF_TAB } from '~/constants';
import ScenarioModel from '~/models/ScenarioModel';
import ScenarioTemplateModel from '~/models/ScenarioTemplateModel';
import LoadingScreen from '~/screens/LoadingScreen';
import { EmptyScreen } from '~/screens/ScenariosScreen/EmptyScreen';
import hintsForTheTable from '~/screens/ScenariosScreen/HintsForTheTable';
import ScenariosStore from '~/screens/ScenariosScreen/store';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { setGtm } from '~/utils/setGtm';
import './style.css';

type Props = RouteComponentProps & {
  authStore: AuthStore;
};

interface State {
  selectedGroup: number;
  selectedPreset: string;
  selectedScenario: number;
  isScenarioLoading: boolean;
  isHowVisible: boolean;
  isTemplatesVisible: boolean;
  isErrorMessageVisible: boolean;
  isErrorWhenCheckScriptException: boolean;
  isCreateWidgetVideoShown: boolean;
  isStatisticVideoShown: boolean;
}

const feedbackWidgetsURL = '/feedback-widgets';

const emptyState: State = {
  selectedGroup: 0,
  selectedPreset: null,
  selectedScenario: null,
  isScenarioLoading: false,
  isHowVisible: false,
  isTemplatesVisible: false,
  isErrorMessageVisible: false,
  isErrorWhenCheckScriptException: false,
  isCreateWidgetVideoShown: false,
  isStatisticVideoShown: false,
};

@inject('authStore')
@observer
class ScenariosScreen extends Component<Props, State> {

  store: ScenariosStore = new ScenariosStore();

  state: State = { ...emptyState };

  isFeedback = this.props.location.pathname === feedbackWidgetsURL;

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.isFeedback = nextProps.location.pathname === feedbackWidgetsURL;
      this.setState({ ...emptyState });
    }
  }

  async componentDidMount() {
    /**
     * Отправка события enter_to_leads_constructor для GA
     */
    if (this.isFeedback) setGtm('enter_to_leads_constructor');

    await Promise.all([
      this.store.fetchScenarios(),
      this.store.fetchTemplates(),
      this.store.getScenariosCount(),
    ]);
  }

  filterStatsByScenarioId = (scenarioId: number) => {
    const statsElement = this.store.statistics.filter(
      statsElement => statsElement.scenarioId === scenarioId
    )[0];

    return statsElement;
  }

  handleSidebarTabsChange = (selectedGroup: string) => {
    this.setState({ selectedGroup: parseInt(selectedGroup, 10) });
  }

  handleClickHow = () => {
    this.setState({ isHowVisible: true });
  }

  handleCloseHow = () => {
    this.setState({ isHowVisible: false });
  }

  handleClickCreate = () => {
    this.setState({ isTemplatesVisible: true });
  }

  handleCloseCreate = () => {
    this.setState({ isTemplatesVisible: false });
  }

  handleClickPreset = (id: string) => {
    this.setState({ selectedPreset: id });
  }

  handleClosePreset = () => {
    if (this.store.scenarioEditingStatus === 'edited') {
      this.setState({ selectedPreset: null });
    } else {
      this.store.setScenarioEditingStatus('editing');
    }
  }

  handleSaveScenario = async () => {
    this.setState({
      selectedPreset: null,
      selectedScenario: null,
      isTemplatesVisible: false,
    });

    await this.store.getScenariosCount();
    await this.store.fetchScenarios();
  }

  handleClickRow = async (scenario: ScenarioModel) => {
    this.setState({ selectedScenario: scenario.get('id') });

    if (!scenario.template) {
      scenario.template = new ScenarioTemplateModel(this, {
        id: scenario.get('id'),
      });
    }

    await scenario.template.fetch();
  }

  handleCloseScenario = () => {
    if (this.store.scenarioEditingStatus === 'edited') {
      this.setState({ selectedScenario: null });
    } else {
      this.store.setScenarioEditingStatus('editing');
    }
  }

  getScenarioListColums = (): ColumnProps<ScenarioModel>[] => {

    return [
      {
        className: 'scenarios-screen-list-table__status',
        key: 'status',
        title: 'Статус',
        render: (_, scenario) => {
          let icon: React.ReactNode;
          let title: string;
          let hoverContent: React.ReactNode;

          if (scenario.isActive) {
            title = 'Запущен';
            icon = <Icon name="PlayS" style={{ color: '#00BF6D' }} />;
            hoverContent = (
              <Button
                icon="PauseS"
                type="black-outline"
                loading={scenario.isPausing}
                disabled={scenario.isPausing}
                onClick={(e) => {
                  e.stopPropagation();

                  scenario.pause().then(() => {
                    if (scenario.errorWhenChangingStatus) {
                      this.setState({ isErrorMessageVisible: true });
                    }
                  });
                }}
              >
                Остановить
              </Button>
            );
          }

          if (scenario.isPaused) {
            title = 'Остановлен';
            icon = <Icon name="PauseS" style={{ color: '#808080' }} />;
            hoverContent = (
              <Button
                icon="PlayS"
                type="black-outline"
                loading={scenario.isActivating}
                disabled={scenario.isActivating}
                onClick={(e) => {
                  e.stopPropagation();

                  scenario.activate().then(() => {
                    if (scenario.errorWhenChangingStatus) {
                      this.setState({ isErrorMessageVisible: true });
                    }
                    if (scenario.errorWhenCheckScriptException) {
                      this.setState({ isErrorWhenCheckScriptException: true });
                    }
                  });
                }}
              >
                Запустить
              </Button>
            );
          }

          return (
            <TableCell icon={icon} title={title} hoverContent={hoverContent} />
          );
        },
      },
      {
        key: 'name',
        title: 'Название сценария',
        render: (_, scenario) => {
          return (
            <TableCell
              title={scenario.get('name')}
              subtitle={scenario.get('description')}
            />
          );
        },
      },
      {
        key: 'type',
        title: 'Устройства показа',
        render: (_, scenario) => {
          let title: JSX.Element = (
            <div className="scenarios-screen-scenarios-list-colum-type__wrapper">
              <Icon
                name="DeviceDesktop"
                className={'scenarios-screen-scenarios-list-colum-type__icon'}
              />
              <div className="scenarios-screen-scenarios-list-colum-type__label">
                Компьютеры
                <br />и планшеты
              </div>
            </div>
          );
          const isMobile = scenario.get('isMobile');
          if (isMobile) {
            title = (
              <div className="scenarios-screen-scenarios-list-colum-type__wrapper">
                <Icon
                  name="DevicePhone"
                  className={'scenarios-screen-scenarios-list-colum-type__icon'}
                />
                <div className="scenarios-screen-scenarios-list-colum-type__label">
                  Мобильные
                  <br />
                  устройства
                </div>
              </div>
            );
          }

          return (
            <TableCell
              title={isMobile === null || isMobile === undefined ? '' : title}
            />
          );
        },
      },
      {
        key: 'date',
        title: 'Дата создания',
        render: (_, scenario) => {
          if (!scenario.get('created_date')) return null;

          const date = moment(scenario.get('created_date'), 'x');

          return <TableCell title={date.format('DD.MM.YYYY')} />;
        },
      },
      {
        key: 'shown',
        title: hintsForTheTable('Показы'),
        render: (_, scenario) => {
          const statsElement = this.filterStatsByScenarioId(scenario.get('id'));

          return (
            <TableCell
              title={`${statsElement.widgetShowPercent}%`}
              subtitle={`${statsElement.widgetShowCount}/${statsElement.visitsTotalCount}`}
            />
          );
        },
      },
      {
        key: 'interactions',
        title: hintsForTheTable('Взаимодействия'),
        render: (_, scenario) => {
          const statsElement = this.filterStatsByScenarioId(scenario.get('id'));

          return (
            <TableCell
              title={`${statsElement.widgetIteractionPercent}%`}
              subtitle={`${statsElement.widgetIteractionCount}/${statsElement.widgetShowCount}`}
            />
          );
        },
      },
      {
        className: 'scenarios-screen-list-table__action',
        key: 'action',
        title: '',
        render: (_, scenario) => {
          return (
            <Button
              tag="a"
              type="text"
              loading={scenario.isRemoving}
              onClick={async (e) => {
                e.stopPropagation();
                await scenario.remove();
                await this.store.getScenariosCount();
              }}
            >
              Удалить
            </Button>
          );
        },
      },
    ];
  }

  renderCreateVideo = () => (
    <VideoMessage
      visible={this.state.isCreateWidgetVideoShown}
      title={'Как создать виджеты?'}
      onClose={() => this.setState({ isCreateWidgetVideoShown: false })}
      onExit={() => this.setState({ isCreateWidgetVideoShown: false })}
      videoSrc="https://www.youtube.com/embed/g-qX1LIds-0"
    />
  )

  renderScenariosTemplates = () => {
    if (this.store.getTemplateGroups(this.isFeedback).length === 0) return null;

    const tabs: SidebarTab[] = this.store
      .getTemplateGroups(this.isFeedback)
      .map((group, index) => {
        return { key: String(index), label: group.ui.caption };
      });

    const currentGroup = this.store.getTemplateGroups(this.isFeedback)[
      this.state.selectedGroup
    ];

    const description = (
      <div dangerouslySetInnerHTML={{ __html: currentGroup.ui.description }} />
    );

    return (
      <Drawer
        visible={this.state.isTemplatesVisible}
        onClose={this.handleCloseCreate}
        title="Создать виджет"
        size="large"
      >
        <>
          <SidebarTabs
            tabs={tabs}
            value={String(this.state.selectedGroup)}
            onChange={this.handleSidebarTabsChange}
          >
            <div className="scenarios-screen-subscreen">
              <a
                className="scenarios-screen-subscreen__how"
                onClick={this.handleClickHow}
              >
                Как работают эти виджеты?
              </a>
              <div className="scenarios-screen-subscreen__title">
                {currentGroup.ui.caption}
              </div>
              {currentGroup.ui.subTitle && (
                <div className="scenarios-screen-subscreen__sub-title">
                  {currentGroup.ui.subTitle}
                </div>
              )}

              <div className="scenarios-screen-subscreen__list">
                {this.store
                  .getTemplatesByGroupIndex(
                    this.state.selectedGroup,
                    this.isFeedback
                  )
                  ?.map(template => (
                    <PresetCard
                      className="scenarios-screen-subscreen__preset"
                      id={template.id}
                      key={template.key}
                      title={template.get('ui').name}
                      onClick={this.handleClickPreset}
                      tag={template.triggerParams.tag}
                      tagColor={template.triggerParams.tagColor}
                    >
                      <Notification
                        className="scenarios-screen-subscreen__notification"
                        {...template.triggerParams}
                      />
                    </PresetCard>
                  ))}
                <Video
                  title="Как настроить виджет?"
                  content="Мы подготовили подробную видеоинструкцию, с помощью которой вы легко настроите любой виджет"
                  link="https://www.youtube.com/embed/g-qX1LIds-0"
                  previewImage="/assets/images/videoPreview/CreateWidgets.png"
                  className="scenarios-screen-video-manual"
                />
              </div>
            </div>
          </SidebarTabs>

          <Drawer
            visible={this.state.isHowVisible}
            onClose={this.handleCloseHow}
            size="x-small"
            title={currentGroup.ui.caption}
            description={description}
            className="scenarios-screen-subscreen__drawer"
          />

          <Drawer
            visible={!!this.state.selectedPreset}
            title={currentGroup ? `Виджет «${currentGroup.ui.caption}»` : ''}
            onClose={this.handleClosePreset}
            size="large"
            flexBody
          >
            <div className="scenarios-screen-widget-button">
              <Button
                type="black-outline"
                className="scenario-editor-configure__button"
                onClick={() => this.setState({ isCreateWidgetVideoShown: true })}
              >
                <div className="scenario-editor-configure__button-body">
                  <div><Icon name="PlayS" className="scenario-editor-configure__button-icon" /></div>
                  <span>Как создать виджеты?</span>
                </div>
              </Button>
              {this.state.isCreateWidgetVideoShown && this.renderCreateVideo()}
            </div>
            {this.state.selectedPreset && (
              <ScenarioEditor
                scenarioEditingStatus={this.store.scenarioEditingStatus}
                setScenarioEditingStatus={this.store.setScenarioEditingStatus}
                preset={this.store
                  .getTemplateByScenarioCode(
                    this.state.selectedPreset,
                    this.isFeedback
                  )
                  ?.copy()}
                onClose={this.handleClosePreset}
                onSave={this.handleSaveScenario}
              />
            )}
          </Drawer>
        </>
      </Drawer>

    );
  }

  renderScenariosList = () => {
    const {
      availableScenarios,
      availableFeedbackScenarios,
      scenariosCount,
      isMessageShown,
      showMessage,
      hideMessage,
    } = this.store;
    const scenarios = this.isFeedback
      ? this.store.feedbackScenarios
      : this.store.scenarios;
    const selectedScenario = scenarios.find(
      scenario => scenario.get('id') === this.state.selectedScenario
    );
    const rowData = Array.from(
      this.isFeedback ? availableFeedbackScenarios : availableScenarios
    );
    const sortedRowData = rowData.sort(
      (a, b) => Number(b.get('created_date')) - Number(a.get('created_date'))
    );

    const hideErrorMessage = () => {
      this.setState({ isErrorMessageVisible: false });
      this.setState({ isErrorWhenCheckScriptException: false });
    };

    const redirectToSettings = () => {
      const { history } = this.props;
      history.push(`/settings?tab=${TARIFF_TAB}`);
    };

    const msgPaymentError = () => {
      return (
        <>
          <div className="scenarios-screen-list__error-img">
            <img
              className="_error-block"
              src="assets/paymentError/payment-error.svg"
              alt=""
              width="200"
            />
          </div>
          <span className="_title">Платеж не прошел</span>
        </>
      );
    };

    return (
      <div className="scenarios-screen-list">
        <Toolbar
          className="scenarios-screen-list__toolbar"
          left={(
            <Button
              icon="Plus"
              onClick={
                scenariosCount.currentCount === scenariosCount.maxCount
                  ? showMessage
                  : this.handleClickCreate
              }
            >
              Создать виджет
            </Button>
          )}
        />

        <Table
          className="scenarios-screen-list__table scenarios-screen-list-table"
          columns={this.getScenarioListColums()}
          rows={sortedRowData}
          rowKey={scenario => scenario.key}
          rowClassName={scenario =>
            `scenarios-screen-list-table__row _id-${scenario.key}`
          }
          rowHover
          onRowClick={this.handleClickRow}
        />
        {this.state.isErrorMessageVisible && (
          <Message
            visible={this.state.isErrorMessageVisible}
            className="scenarios-screen-list__msg-desc"
            title={msgPaymentError()}
            textTop="Работа сервиса приостановлена, т.к. подписка не была оплачена. Пожалйста, проверьте наличие денежных средств на карте, привязанной к вашей учетной записи. Сервис автоматически разблокируется после списания средств"
            applyText="OK"
            showLine={false}
            onApply={hideErrorMessage}
            onClose={hideErrorMessage}
            onExit={hideErrorMessage}
          />
        )}

        {this.state.isErrorWhenCheckScriptException && (
          <Message
            visible={this.state.isErrorWhenCheckScriptException}
            className="scenarios-screen-list__msg-script-exception"
            title={(
              <span>Если не подключить сайт, этот <br /> раздел не сможет работать 🙄</span>
            )}
            textTop="Подключайте сайт и пользуйтесь на здоровье!"
            applyText="Подключить сайт"
            cancelText="Закрыть"
            showLine={false}
            onApply={redirectToSettings}
            onClose={hideErrorMessage}
            onExit={hideErrorMessage}
          />
        )}

        <Drawer
          visible={!!this.state.selectedScenario}
          onClose={this.handleCloseScenario}
          title="Редактирование виджета"
          size="large"
          flexBody
        >
          {selectedScenario?.template?.isFetching && <Spinner />}
          {selectedScenario?.template?.isFetched && (
            <ScenarioEditor
              scenarioEditingStatus={this.store.scenarioEditingStatus}
              setScenarioEditingStatus={this.store.setScenarioEditingStatus}
              preset={selectedScenario.template.copy()}
              onClose={this.handleCloseScenario}
              onSave={this.handleSaveScenario}
            />
          )}
        </Drawer>
        {this.state.isTemplatesVisible && this.renderScenariosTemplates()}
        <Message
          title="Создано максимальное количество виджетов на тарифном плане"
          textTop="Чтобы создать новый виджет, необходимо удалить один существующий."
          applyText="OK"
          visible={isMessageShown}
          onApply={hideMessage}
          onClose={hideMessage}
          onExit={hideMessage}
        />
      </div>
    );
  }

  render() {
    const { authStore } = this.props;
    const rightsForWidgets = authStore.currentUser.rightsModel.find((e: { toString: () => string }) => e.toString() === 'LIST_SCENARIOS');

    const {
      availableScenarios,
      availableFeedbackScenarios,
      scenariosCount,
      isScenariosLoading,
      isTemplatesLoading,
      isFetchingScenariosCount,
      activeScenariosCount,
      isFetchingPrivacyPolicy,
    } = this.store;

    if (
      (isScenariosLoading && isTemplatesLoading) ||
      isFetchingScenariosCount ||
      isFetchingPrivacyPolicy
    ) {
      return <LoadingScreen />;
    }

    const isScenariosExisting = this.isFeedback
      ? availableFeedbackScenarios.length > 0
      : availableScenarios.length > 0;

    if (!this.state.isTemplatesVisible && (!rightsForWidgets || !isScenariosExisting)) {
      return (
        <>
          <EmptyScreen
            isFeedback={this.isFeedback}
            isRightsForWidgets={!!rightsForWidgets}
            onCreate={this.handleClickCreate}
            onVideoShown={() => {
              this.setState({ isCreateWidgetVideoShown: true });
            }}
          />
          {this.state.isCreateWidgetVideoShown && this.renderCreateVideo()}
        </>

      );
    }

    return (
      <Screen className="scenarios-screen" noPadding>
        <PageHeader
          title="Виджеты"
          className="scenarios-screen__title"
          aside={(
            <>
              {isScenariosExisting && (
                <>
                  <div className="scenarios-screen-widget-list-button">
                    <Button
                      type="black-outline"
                      className="scenario-editor-configure__button"
                      onClick={() => this.setState({ isStatisticVideoShown: true })}
                    >
                      <div className="scenario-editor-configure__button-body">
                        <div><Icon name="PlayS" className="scenario-editor-configure__button-icon" /></div>
                        <span>Сбор статистики виджета</span>
                      </div>
                    </Button>
                    {this.state.isStatisticVideoShown && (
                      <VideoMessage
                        visible={this.state.isStatisticVideoShown}
                        title={'Эффективность виджетов'}
                        onClose={() => this.setState({ isStatisticVideoShown: false })}
                        onExit={() => this.setState({ isStatisticVideoShown: false })}
                        videoSrc="https://www.youtube.com/embed/mxdcMd2Fv_4"
                      />
                    )}
                  </div>
                  <div className="scenarios-screen__status">
                    Запущено виджетов: {activeScenariosCount}/
                    {scenariosCount.currentCount}
                  </div>
                </>
              )}
            </>
          )}
        />
        {
          isScenariosExisting
            ? this.renderScenariosList()
            : this.renderScenariosTemplates()
        }
      </Screen>
    );
  }
}

export default ScenariosScreen;
