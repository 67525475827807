import React from 'react';
import { Arrow } from '~/components/generic/Wheel/components/Arrow';
import { IArrow } from '~/components/generic/Wheel/components/Arrow/types';
import { TSectors } from '~/components/generic/Wheel/types';

interface IArrows {
  sectors: TSectors;
  radius: number;
}

export const Arrows = ({ sectors, radius }: IArrows) => {
  const degree = 360 / sectors.length;

  return (
    <>
      {sectors.map((sector, key) => {
        const arrowProps: IArrow = {
          radius,
          arrowNumber: key + 1,
          degree,
        };

        return <Arrow {...arrowProps} key={key} />;
      })}
    </>
  );
};
