import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwitchUI } from '~/components/SwitchUI';
import Message from '~/components/ui/Message';
import Screen from '~/screens/Screen';
import { EmptyWheels } from '~/screens/WheelOfFortune/components/EmptyWheels';
import { WheelConstructor } from '~/screens/WheelOfFortune/components/WheelConstructor';
import { WheelList } from '~/screens/WheelOfFortune/components/WheelList';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

export const WheelOfFortune = observer(() => {
  const { wheelOfFortuneStore, authStore } = useStores() as {
    authStore: AuthStore;
    wheelOfFortuneStore: WheelOfFortuneStore;
  };

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await Promise.all([
        wheelOfFortuneStore.fetchTemplates(),
        wheelOfFortuneStore.fetchScenarios(),
      ]);
    })();
  }, []);

  const isWheelEnabled = authStore.currentUser.rightsModel.find(
    (e: { toString: () => string }) =>
      e.toString() === 'LIST_FORTUNE_WHEEL_SCENARIOS'
  );

  const { isConstructorOpen, scenarios } = wheelOfFortuneStore || {};

  const isNoServiceScreen = !isWheelEnabled;
  const isNoWheelsScreen = isWheelEnabled && scenarios.length === 0;
  const isWheelsListScreen = isWheelEnabled && scenarios.length > 0;

  const [isErrorMessageVisible, setIsErrorMessageVisible] =
    useState<boolean>(false);

  const [isScriptErrorMessageVisible, setIsScriptErrorMessageVisible] =
    useState<boolean>(false);

  const [isSuccessMessageVisible, setIsSuccessMessageVisible] =
    useState<boolean>(false);

  const msgPaymentError = () => {
    return (
      <>
        <div>
          <img
            className="_error-block"
            src="assets/paymentError/SadPanda.png"
            alt=""
            width="480"
            style={{ width: '480px' }}
          />
        </div>
        <span className="_title">Ой! Колесо фортуны не оплачено</span>
      </>
    );
  };

  const msgError = () => {
    return (
      <>
        <div>
          <img
            className="_error-block"
            src="assets/paymentError/SadPanda.png"
            alt=""
            width="480"
            style={{ width: '480px' }}
          />
        </div>
        <span className="_title">Ой! Что-то пошло не так!</span>
      </>
    );
  };

  const msgScriptError = () => {
    return (
      <>
        <div>
          <img
            className="_error-block"
            src="assets/paymentError/SadPanda.png"
            alt=""
            width="480"
            style={{ width: '480px' }}
          />
        </div>
        <span className="_title">Ой! У вас не подключен код</span>
      </>
    );
  };

  const msgScriptSuccess = () => {
    return (
      <>
        <div>
          <img
            className="_error-block"
            src="assets/paymentError/FunnyPanda.png"
            alt=""
            width="480"
            style={{ width: '480px' }}
          />
        </div>
        <span className="_title">Ура! Вы создали колесо фортуны!</span>
      </>
    );
  };

  const hideErrorMessage = () => setIsErrorMessageVisible(false);

  const hideScriptErrorMessage = () => setIsScriptErrorMessageVisible(false);

  const hideSuccessMessage = () => setIsSuccessMessageVisible(false);

  return (
    <SwitchUI elementCode={'WheelOfFortuneScreen'}>
      <Screen documentTitle={'Колесо фортуны'} noPadding>
        {(isNoServiceScreen || isNoWheelsScreen) && (
          <EmptyWheels />
        )}
        {isWheelsListScreen && (
          <WheelList
            setIsErrorMessageVisible={setIsScriptErrorMessageVisible}
            setIsScriptErrorMessageVisible={setIsScriptErrorMessageVisible}
          />
        )}
        {isConstructorOpen && (
          <WheelConstructor
            setIsErrorMessageVisible={setIsScriptErrorMessageVisible}
            setIsScriptErrorMessageVisible={setIsScriptErrorMessageVisible}
            setIsSuccessMessageVisible={setIsSuccessMessageVisible}
          />
        )}
        {!!wheelOfFortuneStore.errorText && (
          <Message
            visible={!!wheelOfFortuneStore.errorText}
            className="scenarios-screen-list__msg-desc"
            title={msgError()}
            textTop={wheelOfFortuneStore.errorText as string}
            applyText="Хорошо"
            showLine={false}
            onApply={() => (wheelOfFortuneStore.errorText = '')}
            onClose={hideErrorMessage}
            onExit={hideErrorMessage}
          />
        )}
        {isErrorMessageVisible && (
          <Message
            visible={isErrorMessageVisible}
            className="scenarios-screen-list__msg-desc"
            title={msgPaymentError()}
            textTop="Оплатите подписку, чтобы ваше колесо заработало и приносило пользу! Порадуйте панду!"
            applyText="Перейти в тарифы"
            showLine={false}
            onApply={() => history.push('/settings?tab=1')}
            onClose={hideErrorMessage}
            onExit={hideErrorMessage}
          />
        )}
        {isScriptErrorMessageVisible && (
          <Message
            visible={isScriptErrorMessageVisible}
            className="scenarios-screen-list__msg-desc"
            title={msgScriptError()}
            textTop={
              'Для запуска колеса фортуны требуется подключить код СберЛид на ваш сайт'
            }
            applyText="Перейти в подключение"
            showLine={false}
            onApply={() => history.push('/settings?tab=2')}
            onClose={hideScriptErrorMessage}
            onExit={hideScriptErrorMessage}
          />
        )}
        {isSuccessMessageVisible && (
          <Message
            visible={isSuccessMessageVisible}
            className="scenarios-screen-list__msg-desc"
            title={msgScriptSuccess()}
            applyText="Здорово!"
            showLine={false}
            onApply={hideSuccessMessage}
            onClose={hideSuccessMessage}
            onExit={hideSuccessMessage}
          />
        )}
      </Screen>
    </SwitchUI>
  );
});
