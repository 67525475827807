import React from 'react';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';

export const MacroCRMInstructions = () => {
  return (
    <>
      <SettingsItem
        Content={(
          <div className={'simple-text'}>
            Нажмите в меню: Компания {'>'} Интеграции {'>'} Другие интеграции{' '}
            {'>'} выберите СберЛид {'>'} Подключить интеграцию.
          </div>
        )}
        title={
          'Подключите готовую интеграцию в MacroCRM, создав входящий WebHook для работы с данными:'
        }
        number={1}
      />
      <SettingsItem
        Content={(
          <ol>
            <li>
              Нажмите в меню: Настройки {'>'} Настройка интеграции {'>'} Новая
              интеграция
            </li>
            <li>
              В поле Сущность выберите объект, который создали на первом шаге.
            </li>
          </ol>
        )}
        title={'Создайте интеграцию с объектом:'}
        number={2}
      />
    </>
  );
};
