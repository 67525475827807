export const currentCardCssParam = (): string => {

  return `
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 0);
    font-style: normal;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding: 10px;
    border-radius: 4px;
  `.replace(/(\r\n|\n|\r|↵)/gm, '').trim();
};