import cx from 'classnames';
import React, { Component } from 'react';
import Button from '~/components/ui/Button';
import Screen from '~/screens/Screen';
import './style.css';

class GuideScreen extends Component {
  render() {
    return (
      <Screen className="guide-screen">
        <div className={cx('guide-screen__container', '_first-step')}>
          <h1 className="guide-screen__heading">Для подключения сервиса нужно добавить код на сайт</h1>
          <div className="guide-screen__subcontainer">
            <h2 className="guide-screen__title">Кто администрирует ваш сайт?</h2>
            <Button
              block
              type="outline"
              className="guide-screen__btn"
              to="/guide/himself"
            >
              Я сам занимаюсь сайтом и имею доступ для его редактирования.
          </Button>
            <Button
              block
              type="outline"
              className="guide-screen__btn"
              to="/guide/developer"
            >
              Я не занимаюсь сайтом. У меня есть специалист в команде.
          </Button>
          </div>

        </div>
      </Screen>
    );
  }
}

export default React.memo(GuideScreen);
