import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

const hideOnSBSPaths = ['/partners', '/instructor'];

/**
 * Хук для проверки доступа к роутам в режиме СБС.
 *
 * @param route
 */
export const useHideOnSBSHook = (route: string) => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const { currentOrg } = authStore;
  const isSBS = currentOrg.get('contractOwnerCode') === 'SBS';

  return !(isSBS && hideOnSBSPaths.includes(route));
};
