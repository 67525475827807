import cx from 'classnames';
import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import FormItem from '~/components/ui/FormItem';
import RadioButton from '~/components/ui/RadioButton';
import './style.css';

type Type = 'mobile' | 'desctop';

interface NotificationTypeProps {
  isMobile?: boolean;
  isMobileConst?: boolean;
  onChange?: (isMobile: boolean) => void;
  hint?: React.ReactNode;
}

const mobileConstAlert = () => (
  <span className="notification-type__mobile-const-alert">
  Этот виджет несовместим с мобильными устройствами по техническим причинам
</span>
);

const NotificationType: React.FC<NotificationTypeProps> = ({
  onChange,
  isMobile = false,
  isMobileConst,
  hint,
}) => {
  const handleChange = useCallback(
    (key: Type) => {
      onChange?.(key === 'mobile');
    },
    [isMobile, onChange]
  );

  return (
    <div className="notification-type__box">
      <FormItem
        title="Где вы хотите показывать виджет?"
        hint={hint}
        noHTMLLabel
        noReservedPlaceForMessage
      />
      <div className="notification-type__container">
        <div
          className="notification-type__wrapper-item"
          onClick={() => handleChange('desctop')}
        >
          <div className="notification-type__item">
            <RadioButton
              size={'medium'}
              checked={!isMobile}
              onCheck={() => true}
              label
            />
          </div>
          <div className="notification-type__item-label">
            На компьютерах
            <br />и планшетах
          </div>
          <div className="notification-type__element-after">
            <Icon
              name="DeviceDesktop"
              className={cx('notification-type__item-icon', {
                _active: !isMobile,
              })}
            />
            <Icon
              name="DeviceTablet"
              className={cx('notification-type__item-icon', {
                _active: !isMobile,
              })}
            />
          </div>
        </div>

        <div
          className="notification-type__wrapper-item"
          onClick={isMobileConst ? null : () => handleChange('mobile')}
        >
          <div className="notification-type__item">
            <RadioButton
              size={'medium'}
              checked={isMobile}
              onCheck={() => true}
              label
              disabled={isMobileConst}
            />
          </div>
          <div className="notification-type__item-label">
            На мобильных
            <br />
            устройствах
          </div>
          <div className="notification-type__element-after">
            <Icon
              name="DevicePhone"
              className={cx('notification-type__item-icon', {
                _active: isMobile,
              })}
            />
          </div>
          {isMobileConst && mobileConstAlert()}
        </div>
      </div>
    </div>
  );
};

export default NotificationType;
