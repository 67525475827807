import React from 'react';
import Icon from '~/components/Icon';
import HelpBlock from '~/components/generic/HelpBlock';
import NumberedBlock from '~/components/generic/NumberedBlock';
import InfoBlock from '~/components/generic/SetCatalog/InfoBlock';
import SetCatalogConfigLink, {
  ListItem,
} from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import { useGetHelpEmailHook } from '~/customHooks/useGetHelpEmail/useGetHelpEmail';
import styles from './style.module.css';

export interface ISetCatalog {
  value: string;
  errorDesc: string[];
  onChange: (text: string) => void;
  isInvalid: boolean;
  onBlur: () => void;
  configLinkError: string;
  isConfigInvalid?: boolean;
  hasClickedOnInput?: boolean;
  notification?: string;
}

const listItems: ListItem[] = [
  {
    id: '1',
    text: 'Зайдите в CMS систему под правами администратора',
  },
  {
    id: '2',
    text: 'Создайте каталог в формате YML в CMS',
  },
  {
    id: '3',
    text: 'Скопируйте ссылку на каталог и вставьте в поле ниже',
  },
];

const SetCatalog: React.FC<ISetCatalog> = ({
  children,
  value,
  errorDesc,
  onChange,
  isInvalid,
  onBlur,
  configLinkError,
  isConfigInvalid = false,
  hasClickedOnInput = null,
  notification = null,
}) => (
  <div className={styles.setCatalog}>
    {isConfigInvalid && (
      <div className={styles.invalidConfig}>
        <h1 className={styles.invalidConfigTitle}>
          Каталог в формате YML содержит ошибки
        </h1>
        {errorDesc?.length > 0 &&
          errorDesc.map((errorLine: string, key: number) => {
            return (
              <p key={key} className={styles.invalidConfigInfo}>
                {errorLine}
              </p>
            );
          })}
      </div>
    )}
    <NumberedBlock>
      <SetCatalogConfigLink
        isInvalid={isInvalid || (isConfigInvalid && !hasClickedOnInput)}
        title="Вставьте ссылку на каталог товаров в формате YML"
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        placeholder="https://example.ru/catalog.yml"
        tooltipText={(
          <>
            Ссылка на страницу должна быть полной - включая «http://» или
            «https://»
          </>
        )}
        listItems={listItems}
        className={styles.config}
        titleTooltipText={(
          <>
            Это стандарт, разработанный Яндексом, основанный на стандарте XML.
            Единый формат позволяет быстро и качественно обрабатывать информацию
            о товарах и услугах.
            <br />
            <br />
            YML-файл регулярно скачивается роботом и обновленные данные
            импортируются в модуль, отправляющий триггерные письма.
          </>
        )}
        errorMessage={configLinkError}
        withIcon
      />
      {!!notification && (
        <div className={styles.recommendationBlock}>
          <Icon
            className={styles.recommendationBlockIcon}
            name="CheckedGreenCircle"
          />
          <div className={styles.recommendationBlockText}>
            {notification}
          </div>
        </div>
      )}
    </NumberedBlock>
    {children}
    <HelpBlock mail={useGetHelpEmailHook()} />
    <InfoBlock />
  </div>
);

export default SetCatalog;
