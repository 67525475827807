import './style.css';

import React from 'react';
import CheckGroup from '~/components/ui/CheckGroup';
import FormItem from '~/components/ui/FormItem';

export interface CheckedItems {
  hasImage?: boolean;
  hasMessage?: boolean;
  hasCoupon?: boolean;
  hasExpirationDate?: boolean;
}
interface NotificationElementsProps {
  checkedItems?: CheckedItems;
  disabledItems?: (keyof CheckedItems)[];
  onCheck?: (state: CheckedItems) => void;
  hint?: string;
}

const defaultCheckedItems: CheckedItems = {
  hasImage: false,
  hasMessage: false,
  hasCoupon: false,
  hasExpirationDate: false,
};

const NotificationElements: React.FC<NotificationElementsProps> = ({
  onCheck,
  checkedItems,
  disabledItems = [],
  hint,
}) => {

  const items = [
    {
      checked: checkedItems?.hasImage,
      disabled: disabledItems.includes('hasImage'),
      key: 'hasImage',
      label: 'Изображение',
    },
    {
      checked: checkedItems?.hasMessage,
      disabled: disabledItems.includes('hasMessage'),
      key: 'hasMessage',
      label: 'Дополнительный текст',
    },
    {
      checked: checkedItems?.hasCoupon,
      disabled: disabledItems.includes('hasCoupon'),
      key: 'hasCoupon',
      label: 'Промокод',
    },
    {
      checked: checkedItems?.hasExpirationDate,
      disabled: disabledItems.includes('hasExpirationDate'),
      key: 'hasExpirationDate',
      label: 'Срок действия акции',
    },
  ];

  const handleCheck = (key: string, checked: boolean) => {
    onCheck?.({ ...defaultCheckedItems, ...checkedItems, [key]: checked });
  };

  return (
    <div className="notification-elements">
      <FormItem
        hint={hint}
        noHTMLLabel
        noReservedPlaceForMessage
        title="Отображение элементов"
      >
        <CheckGroup
          onCheck={handleCheck}
          items={items}
        />
      </FormItem>
    </div>
  );
};

export default NotificationElements;

