import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { clickform } from '~/constants';
import LoadingScreen from '~/screens/LoadingScreen';
import SwitchService from '~/screens/SettingsScreen/MyTariff/SwitchService';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { TariffStore } from '~/stores/tariffStore';
import { formatNumbers } from '~/utils/formatNumbers';
import useStores from '~/utils/useStores';
import './style.css';

export const MyTariff = observer(() => {
  const dateFormat = 'MMMM YYYY';

  const { authStore } = useStores() as { authStore: AuthStore };
  const { tariffStore } = useStores() as { tariffStore: TariffStore };
  const { settingsStore } = useStores() as { settingsStore: SettingsStore };

  const { currentTariffs } = tariffStore;

  const totCurrentTarplan = currentTariffs?.find((item) => {
    return !item.code.indexOf('TP');
  });

  const tarplanConnectionDate = moment(totCurrentTarplan?.dateFrom).format(
    'DD.MM.YYYY'
  );

  const paidTariff = tariffStore.paidTariff;
  const isNotPaidTariff = tariffStore.isNotPaidTariff;

  const tariffPrice = formatNumbers(paidTariff?.price || 0);

  const visitsRange = () => {
    const minVisit = paidTariff?.ui?.minVisit ?? 0;
    const maxVisit = paidTariff?.ui?.maxVisit;

    if (!maxVisit) {
      return `Более ${formatNumbers(minVisit)} посещений в месяц`;
    }

    return `${formatNumbers(minVisit)} — ${formatNumbers(
      maxVisit
    )} посещений в месяц`;
  };

  const visits = visitsRange();

  const formattedDate = `${moment
    .utc(settingsStore.attendance?.date)
    .format(dateFormat)
    .toLocaleLowerCase()} г.`;
  const formattedAttendance = formatNumbers(
    settingsStore.attendance?.attendance
  );

  const [, setAttendance] = useState('0');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      settingsStore.getServices();
    } else {
      settingsStore.enabledServices();
      tariffStore.fetchCurrentTariff();
      settingsStore.fetchAttendance().then(() => {
        setAttendance(formatNumbers(settingsStore.attendance?.attendance));
      });
      authStore.fetchProfile();
    }
  }, [settingsStore.services]);

  const remoteService = (service: string, active: boolean) => {
    settingsStore.remoteService(service, active);
  };

  if (
    settingsStore.isFetching &&
    settingsStore.isFetchingServices &&
    tariffStore.fetchedCurrentTariff
  ) {
    return <LoadingScreen />;
  }

  return (
    <div className="settings-screen__my-tariff">
      <SwitchService
        remoteService={remoteService}
        isRedirect={settingsStore.isRedirect}
        services={settingsStore.services}
        inProcessing={settingsStore.inProcessing}
        tariffPrice={tariffPrice}
        visitsRange={visits}
        isLastActiveService={settingsStore.isLastActiveService}
        resetProcessing={settingsStore.resetProcessing}
        withIndividualCalculation={tariffStore.isIndividualTariff}
        tarplanConnectionDate={tarplanConnectionDate}
        isNotPaidTariff={isNotPaidTariff}
      />
      {!tariffStore.isIndividualTariff && (
        <div className="settings-screen__description-text">
          За {formattedDate} было {formattedAttendance} посещений вашего сайта. В случае, если у вас<br />
          более 200 000 посещений в месяц,{' '}
          <a href={clickform}>оставьте заявку на индивидуальный расчет</a>.
          При подключении или отключении услуг дата следующего списания не изменится.
        </div>
      )}
    </div>
  );
});
