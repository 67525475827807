import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import Link from '~/components/ui/Link';
import { recoveryUrl, yandexMetrikaId } from '~/constants';
import '~/screens/Auth/style.css';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import { getLoggingErrorWithTimer } from '~/utils/getLoggingErrorWithTimer';
import useStores from '~/utils/useStores';

type Props = {
  children?: ReactNode;
};
/** TODO удалить !!! не используется !!! */
const LoginScreen: React.FC<Props> = observer((props: Props) => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (authStore.isAuthorized) {
      history.push(firstPageAfterAuthorization(authStore.clientState, authStore.currentUser.rightsModel));
    }
  }, [authStore.isAuthorized]);

  const onSubmit = async (data: { login?: string; password?: string }) => {
    // @ts-ignore
    await ym(yandexMetrikaId, 'reachGoal', 'logtel');
    await authStore.login(data.login.replace(/[^\d]/g, ''), data.password);
  };

  return (
    <Screen className="auth-screen">
      <form className="auth-screen-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('auth-screen-form__title', '_isRegistration')}>
          Добро пожаловать в СберЛид
        </div>

        <div className="auth-screen-form__row">
          <Input
            name="login"
            ref={register({ required: true })}
            placeholder="Телефон"
            style={{ height: 60 }}
            value={phone}
            onChangePhone={setPhone}
            size="large"
            type="regTel"
            failure={!!errors.login}
            block
          />
        </div>

        <div className="auth-screen-form__row">
          <Input
            name="password"
            ref={register({ required: true })}
            placeholder="Пароль"
            style={{ height: 60 }}
            size="large"
            type="password"
            failure={!!errors.password}
            block
            withEye
          />
        </div>

        <div className="auth-screen-form__row">
          <Button
            size="x-large"
            type="main"
            tag="button"
            htmlType="submit"
            loading={authStore.isLoggingIn}
            block
          >
            Войти
          </Button>
        </div>

        <div className="auth-screen-form__error">
          {getLoggingErrorWithTimer(
            authStore?.loggingErrorResponse?.errorData?.errorText.split(':')[
              authStore.loggingErrorResponse.errorData.errorText.split(':')
                .length - 2
            ] || ''
            )}
        </div>

        <div className="auth-screen-form__row">
          <span className="auth-screen-form__text">
            Забыли пароль? &nbsp;
            <Link to={recoveryUrl}>
              <span className="auth-screen-form__link">Восстановить</span>
            </Link>
          </span>
        </div>
      </form>
    </Screen>
  );
});

export default React.memo(LoginScreen);
