import { IAction } from '~/components/generic/Promo/Actions';
import { getActionButton } from '~/components/generic/Promo/utilities';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

export const emptyScreenButtonsCreator = (hasRight: boolean): IAction[] => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };
  const {
    hasAccounts,
    setIsSendSayWindowOpen,
  } = SendSayAccountElementsStore;

  return getActionButton(
    [
      !hasAccounts && hasRight,
      hasAccounts && hasRight,
      !hasRight,
    ], [
      {
        onClick: () => setIsSendSayWindowOpen(true),
        icon: 'Plus',
        type: 'default',
        name: 'Добавить учетную запись Sendsay',
      }, {
        onClick: () => mailingStore.constructorStore.setIsConstructorOpen(true),
        icon: 'Plus',
        type: 'default',
        name: 'Создать автоматическую рассылку',
      }, {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default',
        name: 'Оставить заявку на подключение',
      },
    ]
  );
};
