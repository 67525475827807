import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '~/components/Icon';
import Loading from '~/components/Loading';
import Link from '~/components/ui/Link';
import SwitchButton from '~/components/ui/SwitchButton';
import { ContactsStatistics } from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics';
import { QuestionsStatistics } from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics';
import { IQuizItem, QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import styles from '~/screens/QuizScreen/style.module.css';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import localStyles from './style.module.css';

interface IQuizStatistic {
  quizId: string;
}

const QUIZ_STATISTIC_TYPES = [
  {
    key: 'common',
    name: 'Общая статистика',
  },
  {
    key: 'contacts',
    name: 'По контактам',
  },
];

export const QuizStatistic = observer(({ quizId }: IQuizStatistic) => {
  // @ts-ignore
  const { type, id } = useParams();

  const [activeKey, setActiveKey] = useState<string>(
    type || QUIZ_STATISTIC_TYPES[0].key
  );
  const onSwitchActiveKey = setActiveKey;

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const { detailStatistic, isStatisticLoading } = quizStore;

  const quizInfo = quizStore.quizList.filter(
    (v: IQuizItem) => v.template?.id?.toString() === quizId.toString()
  )[0];

  useEffect(() => {
    (() => {
      quizStore.fetchStatisticById(quizId);
    })();
  }, []);

  if (isStatisticLoading) {
    return <Loading status={'Loading'} onClick={() => null} />;
  }

  let allAnswers = 0;

  detailStatistic?.questionsStatistic?.map(v =>
    v?.answerStatistics.map((k: any) => {
      allAnswers = allAnswers + k.count;
    })
  );

  const quizName = quizInfo?.template?.name ||
    detailStatistic?.contactsStatistic[0]?.quizName || 'Наименование квиза';

  return (
    <Screen
      documentTitle={`Статистика квиза ${quizId}`}
      className={styles.screen}
    >
      <div className={styles.title}>Статистика</div>
      <div className={localStyles.quizInfoWrap}>
        <Link
          className={localStyles.arrowContainer} 
          to={type === 'contacts' ? '/quiz-leads' : '/quiz'}
        >
          <Icon name="ArrowLeft" className={localStyles.arrow} />
        </Link>
        <div className={localStyles.quizInfo}>
          <div className={localStyles.date}>
            Создано {quizInfo?.creationDate}
          </div>
          <div className={localStyles.name}>
            Квиз:{' '}
            {quizName}
          </div>
          <div className={localStyles.count}>
            Всего ответов: {allAnswers || 0}
          </div>
        </div>
      </div>
      <SwitchButton
        buttons={QUIZ_STATISTIC_TYPES}
        activeKey={activeKey}
        onClick={onSwitchActiveKey}
        isEqualWidth
        className={localStyles.switcher}
      />
      <div>
        {activeKey === 'common' && <QuestionsStatistics />}
        {activeKey === 'contacts' && <ContactsStatistics activeId={id} />}
      </div>
    </Screen>
  );
});
