import { observer } from 'mobx-react';
import React from 'react';
import BeforeEditMessage from '~/components/generic/FeedStatisticTable/TableMessages/BeforeEditMessage';
import PreviewMobileFeedMessage from '~/components/generic/FeedStatisticTable/TableMessages/PreviewMobileFeedMessage';
import RenameDialog from '~/components/generic/FeedStatisticTable/TableMessages/RenameDialog';
import Message from '~/components/ui/Message';
import Notice from '~/components/ui/Notice';

export type ChangeStatus = (status: boolean) => void;

interface ITableMessagesProps {
  feedStatisticsActiveRow: FeedProStatistics | FeedApiStatistics;
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics | FeedLiteStatistics
  ) => void;
  isChanging?: boolean;
  isMessageBeforeExitingOpen?: boolean;
  setIsMessageBeforeExitingOpen?: ChangeStatus;
  setIsConstructorOpen?: ChangeStatus;
  isStoppingDialogOpen?: boolean;
  setIsStoppingDialogOpen?: ChangeStatus;
  isShowWaitMessage?: boolean;
  setIsShowWaitMessage?: ChangeStatus;
  isRemoveDialogOpen?: boolean;
  setIsRemoveDialogOpen?: ChangeStatus;
  isShowExceedingLimitMessage?: boolean;
  setIsShowExceedingLimitMessage?: ChangeStatus;
  isBeforeEditOpen?: boolean;
  isFetchingRequest?: boolean;
  isShowPreviewMobileFeedMessage?: boolean;
  isOpenRenameDialog?: boolean;
  setIsOpenRenameDialog?: ChangeStatus;
  renameFeed?: (id: number, feedName: string) => Promise<void>;
  setIsBeforeEditOpen?: ChangeStatus;
  isOpenFinalDialog?: boolean;
  isShowTabooTypeChangesMessage?: boolean;
  setIsShowTabooTypeChangesMessage?: ChangeStatus;
  setCloseFeedConstuctor?: () => void;
}

const TableMessages: React.FC<ITableMessagesProps> = observer(
  ({
    feedStatisticsActiveRow,
    onClickActionButton,
    isChanging = false,
    isMessageBeforeExitingOpen = false,
    setIsMessageBeforeExitingOpen = null,
    setIsConstructorOpen = null,
    isStoppingDialogOpen = false,
    setIsStoppingDialogOpen = null,
    isShowWaitMessage = false,
    setIsShowWaitMessage = null,
    isRemoveDialogOpen = false,
    setIsRemoveDialogOpen = null,
    isShowExceedingLimitMessage = false,
    setIsShowExceedingLimitMessage = null,
    isBeforeEditOpen = false,
    isFetchingRequest = false,
    isShowPreviewMobileFeedMessage = false,
    isOpenRenameDialog = false,
    setIsOpenRenameDialog = null,
    renameFeed = null,
    setIsBeforeEditOpen = null,
    isOpenFinalDialog = false,
    isShowTabooTypeChangesMessage = false,
    setIsShowTabooTypeChangesMessage = null,
    setCloseFeedConstuctor = null,
  }) => (
    <>
      {isMessageBeforeExitingOpen && (
        <Message
          className="feed-screen__message-box"
          showIcon={false}
          onExit={() => setIsMessageBeforeExitingOpen(false)}
          onClose={() => setIsMessageBeforeExitingOpen(false)}
          onApply={() => {
            setIsMessageBeforeExitingOpen(false);
            setIsConstructorOpen(false);
            setCloseFeedConstuctor && setCloseFeedConstuctor();
          }}
          title={
            isChanging
              ? 'Прекратить редактирование?'
              : 'Прекратить создание ленты?'
          }
          textTop={
            isChanging
              ? 'При продолжении операции изменения не сохранятся.'
              : 'При продолжении операции изменения и данные не сохранятся.'
          }
          visible={isMessageBeforeExitingOpen}
          applyText={
            isChanging ? 'Прекратить редактирование' : 'Прекратить создание'
          }
          cancelText={
            'Отмена'
          }
        />
      )}

      {isStoppingDialogOpen && (
        <Message
          showIcon={false}
          visible={isStoppingDialogOpen}
          title="Хотите остановить ленту?"
          textTop="При продолжении операции, лента больше не будет показываться на сайте, пока Вы ее снова не запустите."
          applyText="Остановить"
          cancelText="Отмена"
          onClose={() => {
            setIsStoppingDialogOpen(false);
          }}
          onApply={() => {
            onClickActionButton('stop', feedStatisticsActiveRow);
          }}
          isErrorBlock={false}
        />
      )}

      {isShowWaitMessage && (
        <Message
          showIcon={false}
          visible={isShowWaitMessage}
          title="Нужно немного подождать. Идет проверка каталога."
          textTop="Загрузка и проверка каталога может занять до 30 минут, после чего будет доступна возможность редактирования, предпросмотра и запуска"
          applyText="Хорошо"
          onApply={async () => {
            setIsShowWaitMessage(false);
          }}
          isErrorBlock={false}
        />
      )}

      {isRemoveDialogOpen && (
        <Message
          showIcon={false}
          visible={isRemoveDialogOpen}
          title="Удалить ленту рекомендаций?"
          textTop="При продолжении операции лента будет удалена с Вашего сайта и из списка созданных лент."
          applyText="Удалить"
          cancelText="Отмена"
          onClose={() => setIsRemoveDialogOpen(false)}
          onApply={() => {
            onClickActionButton('delete', feedStatisticsActiveRow);
          }}
          isErrorBlock={false}
          applyLoading={isFetchingRequest}
        />
      )}

      {isShowExceedingLimitMessage && (
        <Message
          showIcon={false}
          visible={isShowExceedingLimitMessage}
          title="Создано максимальное количество лент"
          textTop="Вы создали максимальное количество лент рекомендаций. Для добавления новой удалите одну из имеющихся."
          applyText="Хорошо"
          onClose={() => setIsShowExceedingLimitMessage(false)}
          onApply={() => setIsShowExceedingLimitMessage(false)}
          isErrorBlock={false}
        />
      )}

      {isShowTabooTypeChangesMessage && (
        <Message
          showIcon={false}
          visible={isShowTabooTypeChangesMessage}
          title="Хотите поменять тип ленты?"
          textTop="Тип сохраненной ленты не меняется. Если вам нужна лента с похожими настройками — дублируйте ленту."
          applyText={'Дублировать'}
          cancelText={'Отмена'}
          onClose={() => setIsShowTabooTypeChangesMessage(false)}
          onApply={() => {
            setIsConstructorOpen(false);
            setIsShowTabooTypeChangesMessage(false);
            onClickActionButton('copy', feedStatisticsActiveRow);
          }}
          isErrorBlock={false}
        />
      )}

      {isBeforeEditOpen && (
        <BeforeEditMessage
          feedStatisticsActiveRow={feedStatisticsActiveRow}
          isBeforeEditOpen={isBeforeEditOpen}
          setIsBeforeEditOpen={setIsBeforeEditOpen}
          onClickActionButton={onClickActionButton}
        />
      )}

      {isShowPreviewMobileFeedMessage && <PreviewMobileFeedMessage />}

      <RenameDialog
        feedStatisticsActiveRow={feedStatisticsActiveRow}
        isOpenRenameDialog={isOpenRenameDialog}
        setIsOpenRenameDialog={setIsOpenRenameDialog}
        renameFeed={renameFeed}
      />

      <Notice visible={isOpenFinalDialog} />
    </>
  )
);

export default TableMessages;
