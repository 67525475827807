import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Tabs from '~/components/ui/Menu/Tabs';
import Screen from '~/screens/Screen';
import { scrollReaction } from '~/utils/scrollReaction';
import './style.css';

const items = [
  { key: '1', title: '1. Пройдите по ссылке', scrollToId: 'link' },
  { key: '2', title: '2. Измените конфигурацию тега', scrollToId: 'tag' },
  { key: '3', title: '3. Выберите "Пользовательский HTML" ', scrollToId: 'html' },
  { key: '4', title: '4. Скопируйте код', scrollToId: 'copy-code' },
  { key: '5', title: '5. Вставьте код', scrollToId: 'put-code' },
  { key: '6', title: '6. Нажмите на редактирование Триггера', scrollToId: 'trigger' },
  { key: '7', title: '7. Выберите “All pages”', scrollToId: 'all-pages' },
  { key: '8', title: '8. Сохраните настройку', scrollToId: 'save' },
  { key: '9', title: '9. Нажмите “Отправить”', scrollToId: 'send' },
  { key: '10', title: '10. Нажмите “Опубликовать”', scrollToId: 'push' },
  { key: '11', title: '11. Проверьте подключение', scrollToId: 'check' },
  { key: '12', title: '12. «Счётчик успешно установлен!»', scrollToId: 'success' },
  { key: '13', title: '«Счётчик не установлен»', scrollToId: 'fail' },
  { key: '14', title: '13. Поддержка Google', scrollToId: 'support' },
];

const InstallGTMScreen: React.FC = observer(() => {
  const [copied, setCopied] = useState<boolean>(false);
  const [value, changeValue] = useState<string>('1');

  const reaction = () => {
    scrollReaction(items, changeValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(reaction, 50));
    
    return () => {
      window.removeEventListener('scroll', reaction);
    };
  }, [value]);

  return (
    <Screen className="guide-html-screen gtm">
      <div className="guide-html__container">
        <Button type="text" icon="ArrowMLeft" size="x-small" to="/guide/himself/gtm">
          Назад
        </Button>
        <h1 className="guide-html__title">Установка кода через Google Tag Manager</h1>
        <p className="guide-html__txt">
          (Google Tag Manager) — это бесплатный сервис, который значительно упрощает работу с тегами на сайтах и в
          мобильных приложениях. С его помощью Вы можете легко менять код на страницах, добавлять новые фрагменты,
          интегрировать сайт с аналитическими системами, рекламными и прочими сервисами.
        </p>
        <p className="guide-html__txt">
          Этот способ имеет для Вас смысл только, если Вы уже используете GTM, и он установлен на ваш сайт, иначе Вам
          придется сначала разбираться с установкой кода GTM, а затем с кодом счетчика «СберЛид».
        </p>
        <h2 className="guide-html__title">GTM уже установлен на ваш сайт</h2>
        <h3 className="guide-html-list__item-title" id={items[0].scrollToId}>
          1. Пройдите на стартовую страницу GTM
        </h3>
        <p className="guide-html__txt">
          Войдите в свой рабочий аккаунт по ссылке
          <Link
            to="https://tagmanager.google.com/"
            className="guide-link">
            https://tagmanager.google.com/
          </Link>.
          В разделе «Рабочая область» перейдите в подраздел «Теги» и нажмите на "Создать".
        </p>
        <div className="install-gtm__image-1 image" />
        <h2 className="guide-html__subtitle" id={items[1].scrollToId} >
          2. Назовите тег и нажмите на изменение конфигурации тега
        </h2>
        <div className="install-gtm__image-2 image" />
        <h2 className="guide-html__subtitle" id={items[2].scrollToId}>
          3. Выберите "Пользовательский HTML" тип тега
        </h2>
        <div className="install-gtm__image-3 image" />
        <h2 className="guide-html__subtitle" id={items[3].scrollToId}>4. Скопируйте код </h2>
        <p className="guide-html__txt">Скопируйте код из личного кабинета «СберЛид». </p>
        <div className="guide-html__code-area">
          <CodeToCopy onCopy={() => setCopied(copied)} />
        </div>
        <h2 className="guide-html__subtitle" id={items[4].scrollToId}>5. Вставьте код в поле для скриптов</h2>
        <p className="guide-html__txt">
          Вставьте скопированный код в поле для скриптов, отметьте галочкой "Поддержка функции document write()"
          и в Настройке активации тега выберите "Без ограничений".
        </p>
        <div className="install-gtm__image-4 image" />
        <h2 className="guide-html__subtitle" id={items[5].scrollToId}>
          6. Нажмите на редактирование Триггера (карандаш в правом верхнем углу)
        </h2>
        <div className="install-gtm__image-5 image" />
        <h2 className="guide-html__subtitle" id={items[6].scrollToId}>
          7. В открывшемся окне выберите «All Pages» («Все страницы»)
        </h2>
        <div className="install-gtm__image-6 image" />
        <h2 className="guide-html__subtitle" id={items[7].scrollToId}>
          8. После этого сохраните настройку
        </h2>
        <div className="install-gtm__image-7 image" />
        <h2 className="guide-html__subtitle" id={items[8].scrollToId}>
          9. Вернитесь в «Обзор» и нажмите «Отправить»
        </h2>
        <div className="install-gtm__image-8 image" />
        <h2 className="guide-html__subtitle" id={items[9].scrollToId}>10. Нажмите «Опубликовать» </h2>
        <p className="guide-html__txt">
          В открывшемся окне перетащите созданный тег в описание версии, задайте название версии и
          нажмите «Опубликовать».
        </p>
        <div className="install-gtm__image-9 image" />
        <h2 className="guide-html__subtitle" id={items[10].scrollToId}>11. Проверьте подключение</h2>
        <p className="guide-html__txt">
          После установки кода счетчика вернитесь на начальный экран «СберЛид»
          и кликните на кнопку «Проверить код»
        </p>
        <CheckCodeButton />
        <h2 className="guide-html__subtitle" id={items[11].scrollToId}>12. «Счётчик успешно установлен!»</h2>
        <p className="guide-html__txt">
          Если код счетчика установлен корректно, то через некоторое время Вы заметите появление данных с
          вашего сайта и сможете начать с ними работать.
        </p>
        <div className="success-install__image image" />
        <h2 className="guide-html__subtitle" id={items[12].scrollToId}>«Счётчик не установлен»</h2>
        <p className="guide-html__txt">
          Если код счетчика установлен некорректно, то при нажатии кнопки «Проверить код», Вы не сможете перейти
          на Главную страницу Личного кабинета. В этом случае, проверьте корректность установки кода счетчика на
          своем сайте еще раз.
        </p>
        <h2 className="guide-html__subtitle" id={items[13].scrollToId}>13. Обратитесь в Поддержку Google</h2>
        <p className="guide-html__txt">
          Проверьте корректность установки кода счетчика на всех пройденных этапах установки, при необходимости,
          обратитесь в Поддержку Google
          <Link
            to="https://support.google.com/analytics/answer/6277302?hl=ru&ref_topic=6277290"
            className="guide-link"
          >
            https://support.google.com/analytics/answer/6277302?hl=ru&ref_topic=6277290
          </Link>
        </p>
        <div className="install-gtm__image-10 image" />
        <div className="guide-html__separator" />
      </div>
      <Tabs
        items={items}
        value={value}
        onChange={changeValue}
        direction="vertical"
        className="guide-html__tabs"
        withScroll
      />
    </Screen>
  );
});

export default React.memo(InstallGTMScreen);
