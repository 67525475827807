export enum TextFormat {
  'Italic' = 'italic',
  'Bold' = 'bold',
}
export enum TextDecoration {
  'NoDecoration' = 'no-decoration',
  'LineThrough' = 'line-through',
  'Underline' = 'underline',
}
export const textFormatBooleanToString = (isItalic: boolean) => isItalic ? TextFormat.Italic : TextFormat.Bold;

export const textDecorationBooleanToString = (isUnderline: boolean, isLineThrough: boolean) =>
isUnderline ? TextDecoration.Underline : isLineThrough ? TextDecoration.LineThrough : TextDecoration.NoDecoration;
