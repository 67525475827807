import cx from 'classnames';
import React from 'react';
import { Answer } from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics/Answer';
import styles from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics/style.module.css';
import { IQuestion } from '~/screens/QuizScreen/stores/quizStore';

export const Question = (question: IQuestion) => {
  const { text, answerStatistics, hasNoAnswers } = question;

  return (
    <div className={styles.question}>
      <div
        className={cx(styles.questionBody, hasNoAnswers && styles.hasNoAnswers)}
      >
        Вопрос: {text}
      </div>
      {answerStatistics.map((answer, key) => (
        <Answer {...answer} key={key} id={`${key + 1}`} />
      ))}
    </div>
  );
};
