import copy from 'fast-copy';
import { isEqual } from 'lodash';
import { action, observable, toJS } from 'mobx';

export default abstract class Model<T> {
  parent: any;
  backUpData: T = {} as T;

  @observable private data: T = {} as T;

  constructor(parent: any, data: Partial<T> = {}) {
    this.parent = parent;
    this.update(data);
    this.saveBackUp(data);
  }

  hasDataChange(): boolean {
    return !isEqual(this.data, this.backUpData);
  }

  get<K extends keyof T>(field: K) {
    return this.data[field];
  }

  has<K extends keyof T>(field: K) {
    return field in this.data && this.data[field] !== undefined && this.data[field] !== null;
  }

  copy(): T {
    return copy(toJS(this.data));
  }

  saveBackUp(obj: Partial<T>) {
    const keys = Object.keys(obj) as (keyof T)[];

    keys.forEach((key) => {
      this.backUpData[key] = obj[key];
    });
  }

  @action
  replace(obj: Partial<T>) {
    const keys = new Set<keyof T>([].concat(Object.keys(this.data), Object.keys(obj)));

    keys.forEach((key) => {
      this.data[key] = obj[key] ? obj[key] : undefined;
    });
  }

  @action
  update(obj: Partial<T>) {
    const keys = Object.keys(obj) as (keyof T)[];

    keys.forEach((key) => {
      this.data[key] = obj[key];
    });
  }

  @action
  clean = () => {
    this.data = {} as T;
  }
}
