import React from 'react';
import Button from '~/components/ui/Button';
import ToCopyArea from '~/components/ui/ToCopyArea';

interface IGuideGTMProps {
  onCloseDrawer: () => void;
  clientCode: string;
}

export const GuideGTM: React.FC<IGuideGTMProps> = ({
  onCloseDrawer,
  clientCode,
}) => {
  return (
    <div className="gtm__wrapper">
      <ol className="_list">
        <li className="_item">
          <span className="_title">
            Войдите в свой аккаунт в системе Google Tag Manager
          </span>
          <p>
            Войдите в свой рабочий аккаунт в системе GTM. В разделе «Рабочая
            область» перейдите в подраздел «Теги» и нажмите на «Создать».
          </p>
        </li>
        <li className="_item">
          <span className="_title">
            Измените тег на «Пользовательский-HTML»
          </span>
          <p className="_text">
            Измените конфигурацию тега на «Пользовательский HTML» тип.
          </p>
        </li>
        <li className="_item">
          <span className="_title">Скопируйте и вставьте код</span>
          <ToCopyArea
            text={clientCode}
            className="connection-guide-all__code"
          />
        </li>
        <li className="_item">
          <span className="_title">
            Вставьте скопированный код после тега {`<body>`} или {`<head>`}
          </span>
          <p className="_text">
            Вернитесь в редактор кода и вставьте код сразу после открытия тега
            {` <body>`}. Рекомендуем разместить его внутри элементов head или
            body как можно ближе к началу страницы. Нажмите сохранить.
          </p>
        </li>
        <li className="_item">
          <span className="_title">Измените конфигурация тега и триггера</span>
          <p className="_text">
            Вставьте скопированный код счетчика Сервиса в поле для скриптов,
            отметьте галочкой «Поддержка функции document write()» и в Настройке
            активации тега выберите «Без ограничений».
          </p>
        </li>
        <li className="_item">
          <span className="_title">Опубликуйте изменения</span>
          <p className="_text">
            После сохранения настроек в открывшимся окне перетащите созданный
            тег в описание версии, задайте ее название и нажмите "Опубликовать".
          </p>
        </li>
        <li className="_item">
          <span className="_title">Проверьте подключение</span>
          <p className="_text">
            Чтобы код заработал, просто перейдите на свой сайт, на который
            вставили код, а потом вернитесь и нажмите на кнопку «Проверить
            подключение»
          </p>
          <Button onClick={onCloseDrawer}>Проверить подключение</Button>
        </li>
      </ol>
    </div>
  );
};
