import TariffModel from '~/models/TariffModel';
import { TariffStore } from '~/stores/tariffStore';

const getTariffByValue = (value: number, tarriffOptions: any) => {
  return tarriffOptions?.filter(
    (v: { maxVisit: Boolean; minVisit: number }) => {
      if (!v.maxVisit && value >= v.minVisit) {
        return true;
      }

      return (
        typeof v.maxVisit === 'number' &&
        value >= v.minVisit &&
        value <= v.maxVisit
      );
    }
  )[0];
};

export const calcAmount = (value: number, tarriffOptions: any) => {
  return getTariffByValue(value, tarriffOptions)?.price;
};

export const getTariffName = (value: number, tarriffOptions: any) => {
  return getTariffByValue(value, tarriffOptions)?.code;
};

export const getPaymentDate = (value: number, tarriffOptions: any) => {
  return getTariffByValue(value, tarriffOptions)?.paymentDate;
};

const lesThenCoef = 10000;
const moreThenCoef = 100000;

export const calcAttendance = (value: number) => {
  if (value < moreThenCoef) {
    return Math.ceil(value / lesThenCoef) * lesThenCoef;
  } else {
    return Math.ceil(value / moreThenCoef) * moreThenCoef;
  }
};

export const formatValue = (value: number) =>
  !value ? 0 : value?.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

const defaultOptions: Array<any> = [];

export const getOptions = (tariffStore: TariffStore) => {
  if (tariffStore?.availableTariffs?.length > 0) {
    return tariffStore.availableTariffs.map((tariff: TariffModel) => {
      const value = `${
        !!tariff.maxVisit
          ? Math.round((tariff.maxVisit + tariff.minVisit) / 2)
          : Math.round(tariff.minVisit + 0.1 * tariff.minVisit)
      }`;

      const text = !!tariff.maxVisit
        ? `от ${formatValue(tariff.minVisit)} до ${formatValue(
            tariff.maxVisit
          )}`
        : `более ${formatValue(tariff.minVisit)}`;

      return { value, text };
    });
  }

  return defaultOptions;
};
