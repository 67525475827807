import React from 'react';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import styles from './style.module.css';

export const FloatTableData = ({
  icon,
  headData,
  subHeadData,
  label,
  labelNotification,
  backgroundColor = 'white',
}: IFloatTableData) => {
  return (
    <div className={styles.wrap} style={{ backgroundColor }}>
      <div className={styles.top}>
        <Icon name={icon} className={styles.icon} />
        <div className={styles.label}>{label}</div>
        <IconWithTooltip
          iconProps={{ name: 'Question' }}
          tooltipProps={{ title: labelNotification, placement: 'bottomLeft' }}
        />
      </div>
      <div className={styles.subHead}>{subHeadData}</div>
      <div className={styles.head}>{headData}</div>
    </div>
  );
};
