import { action, computed, observable } from 'mobx';
import { Roles } from '~/api';
import Model from '~/models/Model';

export default class RoleModel extends Model<IRole> {
  @observable isFetching = false;
  @observable isSaving = false;

  @action
  fetch = async () => {
    this.isFetching = true;

    try {
      const response = await Roles.get(this.get('id'));
      this.update(response.data.payload[0]);
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @action
  save = async () => {
    this.isSaving = true;

    try {
      if (this.has('id')) {
        const response = await Roles.update(this.get('id'), { ...this.copy(), state: 'ACTIVE' });
        this.update(response.data.payload[0]);
      } else {
        const response = await Roles.create(this.copy());
        this.update(response.data.payload[0]);
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @computed
  get isNew() {
    return !this.has('id');
  }

  @computed
  get key() {
    return this.get('id');
  }
}
