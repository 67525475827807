import cx from 'classnames';
import React from 'react';

interface ISettingsItem {
  title?: string;
  Content?: string | JSX.Element;
  isSuccess?: boolean;
  number?: number;
}

export const SettingsItem = ({
  title,
  Content,
  isSuccess,
  number,
}: ISettingsItem) => {
  const successIcon = isSuccess ? (
    <div className={cx('leads-settings__item-label', '_checked')}>&#10003;</div>
  ) : null;
  const modificationClassName = isSuccess ? '_success' : '_regular';

  const icon = number ? <div className="leads-settings__item-label">{number}</div> : null;

  return (
    <div className={cx('leads-settings__item', modificationClassName)}>
      {successIcon}
      {icon}
      {title && <div className="leads-settings__item-head">{title}</div>}
      <div className="leads-settings__item-content">{Content}</div>
    </div>
  );
};
