import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

interface ITypeSelectionCard {
  id: string;
  title: string;
  description: string;
  exampleText?: string;
  messageText?: string;
  image: string;
  onClick: (id: string, title: string) => void;
  isChosen: boolean;
  className?: string;
}

const TypeSelectionCard: React.FC<ITypeSelectionCard> = ({
  id,
  title,
  description,
  exampleText,
  messageText,
  image,
  onClick,
  isChosen,
  className= '',
}) => (
  <div
    className={cx(styles.card, isChosen ? styles.choice : '', className)}
    onClick={() => {
      onClick(id, title);
    }}
  >
    <img className={styles.img} src={image} alt={title} />
    <div className={styles.text_wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      {exampleText && (
        <div className={styles.example_text}>
          <span>Пример: </span>
          {exampleText}
        </div>
      )}
      {messageText && (
        <div className={styles.message_text_wrapper}>
          <div className={styles.message_text}>{messageText}</div>
        </div>
      )}
    </div>
    <Icon
      name={isChosen ? 'CheckedCircle' : 'EmptyCircle'}
      className={styles.icon}
    />
  </div>
);

export default TypeSelectionCard;
