import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import Loading from '~/components/Loading';
import Link from '~/components/ui/Link';
import Pagination from '~/components/ui/Pagination';
import Table from '~/components/ui/Table';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import styles from '~/screens/QuizScreen/style.module.css';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import localStyle from './style.module.css';

const processEmptyValue = (v: string) => v || '—';

const processDateTimeValue = (v: string) => moment(v).format('DD.MM.YYYY HH:mm:ss');

const getLeads = (rows: any[]) =>
  rows.map((lead, k) => ({
    ...lead,
    dateTime: processDateTimeValue(lead.dateTime),
    name: processEmptyValue(lead.name),
    email: processEmptyValue(lead.email),
    phone: processEmptyValue(lead.phone),
    answers: (
      <Link
        to={`/quiz/statistic/contacts/${lead.quizId}/${lead.id}`}
        key={k}
        className={localStyle.answers}
      >
        Посмотреть ответы
      </Link>
    ),
  }));

const columns = [
  {
    title: (
      <div className={localStyle.headCell} style={{ width: '102px' }}>
        Дата/время <Icon className={localStyle.arrow} name="ArrowSDown" />
      </div>
    ),
    dataIndex: 'dateTime',
    key: 'dateTime',
  },
  { title: 'Квиз', dataIndex: 'quizName', key: 'quizName', width: 200 },
  { title: 'Номер телефона', dataIndex: 'phone', key: 'phone' },
  { title: 'Электронная почта', dataIndex: 'email', key: 'email' },
  { title: 'Имя', dataIndex: 'name', key: 'name' },
  { title: '', dataIndex: 'answers', key: 'answers' },
];

export const QuizLeadsScreen = observer(() => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const { paginator, quizLeads, isLeadsLoading } = quizStore;

  const fetchLeads = async (pageNum?: number) => {
    await quizStore.fetchQuizLeads({
      quizId: undefined,
      paginator: { pageNum, pageSize: 25 },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchLeads(1);
    })();
  }, []);

  const rows = getLeads(quizLeads || []);

  if (isLeadsLoading) {
    return <Loading status={'Loading'} onClick={() => null} />;
  }

  return (
    <Screen documentTitle={'Заявки'} className={styles.screen}>
      <div className={styles.title}>Заявки</div>
      <Table
        // @ts-ignore
        columns={columns}
        rows={rows
          .map((v, k) => ({ ...v, key: k })) || []
        }
        className={localStyle.table}
      />

      <div className={styles.paginatorWrap}>
        <Pagination
          {...paginator}
          onChange={async (v) => {
            fetchLeads(v);
          }}
        />
      </div>
    </Screen>
  );
});
