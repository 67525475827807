import cx from 'classnames';
import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import Input from '~/components/ui/Input';
import styles from './style.module.css';

export interface ListItem {
  id: string;
  text: string;
  dontAddSequenceNumber?: boolean;
}

interface ISetCatalogConfigLink {
  title?: string;
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
  tooltipText?: React.ReactNode;
  listItems?: ListItem[];
  className?: string;
  withIcon?: boolean;
  titleTooltipText?: string | React.ReactNode;
  isInvalid: boolean;
  onBlur?: () => void;
  errorMessage?: string;
}

const SetCatalogConfigLink: React.FC<ISetCatalogConfigLink> = ({
  value = '',
  onChange,
  title,
  placeholder,
  tooltipText,
  listItems,
  className,
  withIcon,
  titleTooltipText,
  isInvalid,
  onBlur,
  errorMessage,
}) => (
  <div className={cx(styles.сonfigLink, className)}>
    {title && (
      <h1 className={styles.title}>
        {title}
        {withIcon && (
          <IconWithTooltip
            tooltipProps={{
              placement: 'bottomLeft',
              title: titleTooltipText,
              width: '298px',
              overlayClassName: styles.tooltip,
            }}
            iconProps={{
              name: 'Question',
            }}
          />
        )}
      </h1>
    )}
    {listItems?.length && (
      <ul className={styles.instructions}>
        {listItems.map((listItem, index) => (
          <li key={listItem.id}>
            {listItem.dontAddSequenceNumber ? '' : `${index + 1}. `}
            {listItem.text}
          </li>
        ))}
      </ul>
    )}
    <div className={styles.inputContainer}>
      <Input
        value={value}
        onChangeValue={onChange}
        className={styles.input}
        size="large"
        placeholder={placeholder}
        failure={isInvalid}
        onBlur={onBlur}
        elemAfter={
          tooltipText && (
            <IconWithTooltip
              tooltipProps={{
                placement: 'bottomLeft',
                title: tooltipText,
                width: '298px',
                overlayClassName: styles.tooltip,
              }}
              iconProps={{
                name: 'Question',
              }}
            />
          )
        }
      />
      
    </div>
    {isInvalid && (
      <div className={styles.errorMessage}>
        {errorMessage}
      </div>
    )}
  </div>
);

export default SetCatalogConfigLink;
