import { IAction } from '~/components/generic/Promo/Actions';

/**
 * Возвращает массив кнопок в формате IAction
 * оба аргумента должны совпадать по длине
 * @param conditions - массив boolean - определяет какую кнопку стоит вернуть по индексу
 * @param buttons - полный массив кнопок в формате IAction
 * @returns IAction[]
 */
export const getActionButton = (conditions: boolean[], buttons: IAction[]): IAction[] =>
  buttons.filter((button, index) => conditions[index]);