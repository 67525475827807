import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';

export const GetActions = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };
  const {
    mailingInstance,
    constructorStore,
    isYmlValid,
    isIntervalInValid,
    setIsPreviewShown,
    saveMailing,
    isSavedMailing,
  } = mailingStore;
  const {
    currentStep,
    changeCurrentStepNextOrBack,
  } = constructorStore;
  
  const authorizationEmailObj = mailingInstance.get('authorizationEmail');
  const saveButtonDisabled = !authorizationEmailObj?.address?.length;

  const isSenderName = authorizationEmailObj?.senderName?.length;
  const isEmailTheme = authorizationEmailObj?.emailTopic?.length;

  const isDisabledOnStep1 =
    currentStep === 'TabSelectType' && !mailingInstance.get('type');
  const isDisabledOnStep2 =
    currentStep === 'TabCatalog' && !mailingInstance.get('ymlCatalog');
  const isDisabledOnStep3 =
    currentStep === 'TabParameters' &&
    (saveButtonDisabled || !isSenderName || !isEmailTheme || isIntervalInValid);

  const isButtonDisabled =
    isDisabledOnStep1 || isDisabledOnStep2 || isDisabledOnStep3 || !isYmlValid;

  return (
    <div className="btn-group">
      {currentStep !== 'TabSelectType' && (
        <Button
          onClick={async () => changeCurrentStepNextOrBack(-1)}
          type="grey"
        >
          Назад
        </Button>
      )}
      {currentStep !== 'TabConstructor' && (
        <Button
          disabled={isButtonDisabled}
          onClick={async () => changeCurrentStepNextOrBack(1)}
        >
          Далее
        </Button>
      )}
      {currentStep === 'TabConstructor' && (
        <>
          <Button loading={isSavedMailing} disabled={saveButtonDisabled} onClick={saveMailing}>
            Сохранить
          </Button>
          <Button
            type="grey"
            icon="Eye"
            onClick={() => setIsPreviewShown(true)}
          >
            Предпросмотр
          </Button>
        </>
      )}
    </div>
  );
});
