import React from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

const InfoBlockInMessage: React.FC<{
  messageBeforeStopFeed?: string[];
}> = ({ messageBeforeStopFeed = [] }) => (
  <>
    {messageBeforeStopFeed.length > 0 && (
      <div className={styles.wrapper}>
        <div>
          <Icon name={'TriangleWarning2'} className={styles.icon} />
        </div>
        <div>
          {messageBeforeStopFeed.map((line, index) => {
            return (
              <div key={index} className={styles.line}>
                {line}
              </div>
            );
          })}
        </div>
      </div>
    )}
  </>
);

export default InfoBlockInMessage;
