import React, { useEffect, useRef, useState } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import { getHint } from '~/components/ScenarioEditor/ScenarioEditorSteps';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import InputWrapper from '~/components/ui/InputWrapper';
import './style.css';

interface Props {
  step: IScenarioTempalateStepURL;
  type: string;
  group: IScenarioTemplateStepGroupBase;
  onChangeSeparator: (operation: IScenarioStepURLOperation) => void;
  onChangeUrl: (url: string) => void;
  groupIndex: number;
  stepIndex: number;
  addCurrentValidationStatus: (groupIndex: number, stepIndex: number, status: boolean) => void;
}

const UrlStep: React.FC<Props> = React.memo(({
  step,
  type,
  group,
  onChangeSeparator,
  onChangeUrl,
  groupIndex,
  stepIndex,
  addCurrentValidationStatus,
}) => {

  const inputRef = useRef(null);
  const [failure, toggleFailure] = useState(false);

  const validation = (str: string): boolean => {
    // TODO - можно добавить более сложную валидацию

    return !!str;
  };

  const handleChangeValue = (url: string) => {
    const resultValidation = validation(url);
    toggleFailure(!resultValidation);
    addCurrentValidationStatus(groupIndex, stepIndex, resultValidation);
    onChangeUrl(url);
  };

  useEffect(() => {
    handleChangeValue(inputRef.current.value);
  }, []);

  return (
    <>
      <InputWrapper
        className="scenario-editor-step__input"
        elemAfter={getHint(step.ui.caption, 'disabled-input', step.operation, type, group.ui.caption) ? (
          <IconWithTooltip
            tooltipProps={{
              overlayClassName: 'scenario-editor-step__tooltip-overlay',
              placement: 'topRight',
              title:
                getHint(step.ui.caption, 'disabled-input', step.operation, type, group.ui.caption),
            }}
            iconProps={{
              name: 'Question',
              className: 'scenario-editor-step__tooltip-icon',
            }}
          />
        ) : undefined}
        disabled
      >
        {step.ui.caption}
      </InputWrapper>
      <div className="scenario-editor-step__separator" />
      <InputDropdown<IScenarioStepURLOperation>
        className="scenario-editor-step__input _dropdown"
        size="large"
        theme="light"
        overlayPlacement="bottom"
        selectedKey={step.operation}
        items={[
          { key: 'EQUAL', value: 'Соответствует' },
          { key: 'CONTAINS', value: 'Содержит' },
          { key: 'NOT_CONTAINS', value: 'Не содержит' },
          { key: 'START_WITH', value: 'Начинается с' },
        ]}
        onChange={onChangeSeparator}
        hint={(value: string) => getHint(step.ui.caption, 'dropdown', value, type, group.ui.caption)}
      />
      <div className="scenario-editor-step__separator" />
      <FormItem
        failure={failure}
        message={failure ? 'Обязательное' : ''}
        noReservedPlaceForMessage
        className={'scenario-editor-steps-url-step__input-wrapper'}
      >
        <Input
          ref={inputRef}
          failure={failure}
          className="scenario-editor-step-url__input"
          value={step.url}
          size="large"
          onBlur={handleChangeValue}
          onChangeValue={handleChangeValue}
          placeholder={
            step.operation === 'EQUAL'
              ? 'https://example.ru/promo-offer?id=1'
              : step.operation === 'CONTAINS'
                ? 'promo-offer'
                : step.operation === 'NOT_CONTAINS'
                  ? '/admin'
                  : step.operation === 'START_WITH'
                    ? 'https://example.ru/cart'
                    : ''
          }
          elemAfter={getHint(step.ui.caption, 'input', step.operation, type, group.ui.caption) ? (
            <IconWithTooltip
              tooltipProps={{
                placement: 'topRight',
                title: getHint(step.ui.caption, 'input', step.operation, type, group.ui.caption),
                overlayClassName: 'scenario-editor-step__tooltip-overlay',
              }}
              iconProps={{
                name: 'Question',
                className: 'scenario-editor-step__tooltip-icon',
              }}
            />
          ) : undefined}
        />
      </FormItem>
    </>
  );
});

export default UrlStep;