import React, { useEffect, useRef, useState } from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface INoticeProps {
  visible: boolean;
  transitionDuration?: number;
}

const Notice: React.FC<INoticeProps> = ({
  visible,
  transitionDuration = 0.5,
}) => {
  const noticeRef = useRef(null);
  const [top, setTop] = useState<string>('-50px');
  const [left, setLeft] = useState<number>(0);

  const hideNotice = () => {
    setTop('-50px');
  };

  const showNotice = () => {
    setLeft((document.body.offsetWidth - noticeRef.current?.offsetWidth) / 2);
    setTop('0');
  };

  useEffect(() => {
    if (visible) {
      showNotice();

      return;
    }
    hideNotice();
  }, [visible]);

  return (
    <div
      ref={noticeRef}
      className="component-ui-notice__box"
      style={{
        left,
        transitionDuration: `${transitionDuration}s`,
        top,
      }}
    >
      <div>
        <Icon name={'CheckedWhiteCircle'} />
      </div>
      <div>Успешно сохранено</div>
    </div>
  );
};

export default Notice;
