import { ResponsivePie } from '@nivo/pie';
import { Typography } from 'antd';
import React from 'react';
import Icon from '~/components/Icon';
import Tooltip from '~/components/ui/Tooltip';
import './style.css';

const { Text } = Typography;

interface Props {
  percent: number;
  tooltipTitle?: string;
}

const ChartGauge: React.FC<Props> = (props) => {
  const data = [
    // логичнее было бы указать сначала заполненный участок, а потом уже пустой, но так не работает
    {
      id: 'empty',
      value: 100 - props.percent,
    },
    {
      id: 'data',
      value: props.percent,
    },
  ];

  const colors = (value: number) => {
    if (value < 33) {
      return '#00BD7E';
    } else if (value >= 33 && value < 66) {
      return '#FAD76E';
    } else {
      return '#EF546B';
    }
  };

  const formatPercent = (percent: number) =>
    Number.isInteger(percent) ? `${percent}%` : `${percent.toFixed(2)}%`;

  return (
    <div className="chart-gauge">
      <div className="chart-gauge__caption">
        <Text
          className="chart-gauge__title"
          children={formatPercent(props.percent)}
        />
        <div className="chart-gauge__tooltip-wrapper">
          {props.tooltipTitle && (
            <Tooltip
              placement="rightTop"
              title={(
                <div className="dead-zone-screen__tooltip-text-wrapper">
                  {props.tooltipTitle}
                </div>
              )}
            >
              <Icon name="Question" className="dead-zone__hint-icon" />
            </Tooltip>
          )}
        </div>
      </div>
      <ResponsivePie
        data={data}
        startAngle={110}
        endAngle={-110}
        innerRadius={0.85}
        // логичнее было бы указать сначала заполненный участок, а потом уже пустой, но так не работает
        colors={['#EBEBEB', `${colors(data[1].value)}`]}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        isInteractive={false}
      />
    </div>
  );
};
export default ChartGauge;
