import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentDescriptionCssParam = (configuration: IFeedConfiguration): string => {
  const {
    cardDescriptionFontFamily,
    cardDescriptionColor,
    cardDescriptionOpacity,
    cardDescriptionFontWeight,
    cardDescriptionFontSize,
    cardDescriptionFontItalic,
    cardDescriptionTextUnderline,
    cardDescriptionTextLineThrough,
  } = configuration;
  
  return `
    font-family: ${cardDescriptionFontFamily};
    color: ${hexToRgb(
      cardDescriptionColor,
      cardDescriptionOpacity
    )};
    font-weight: ${fontWeightValues[cardDescriptionFontWeight]};
    font-size: ${cardDescriptionFontSize}px;
    font-style: ${cardDescriptionFontItalic
      ? 'italic'
      : 'normal'};
    text-decoration: ${getTextDecoration(
      cardDescriptionTextUnderline,
      cardDescriptionTextLineThrough
    )};`
    .replace(/(\r\n|\n|\r|↵)/gm, '')
    .trim();
};