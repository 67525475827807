import './style.css';

import cx from 'classnames';
import React, { Key } from 'react';

export interface RadioGroupWrapperItem {
  elemBefore?: React.ReactNode;
  item: React.ReactElement;
  elemAfter?: React.ReactNode;
}

interface RadioGroupWrapperProps {
  className?: string;
  items?: RadioGroupWrapperItem[];
  onChange?: (key: Key) => void;
}

const RadioGroupWrapper: React.FC<RadioGroupWrapperProps> = ({ items = [], onChange, className }) => {
  return (
    <div className={cx('radiogroup-wrapper', className)}>
      {
        items.map(item => renderItem(item, onChange))
      }
    </div>
  );
};

const renderItem = ({ elemBefore, item, elemAfter }: RadioGroupWrapperItem, onChange?: (key: Key) => void) => (
  <div className="radiogroup-wrapper__item-container" key={item.key} onClick={() => onChange?.(item.key)}>
    <div className="radiogroup-wrapper__element-before">
      {elemBefore && elemBefore}
    </div>
    <div className="radiogroup-wrapper__item">
      {item}
    </div>
    {
      elemAfter && (
        <div className="radiogroup-wrapper__element-after">
          {elemAfter}
        </div>
      )
    }
  </div>
);

export default RadioGroupWrapper;
