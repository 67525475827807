import { IPromo } from '~/components/generic/Promo';

export const items = [
  {
    iconSrc: 'DemoPro',
    text: 'Повышение продаж до +20% к текущему объему*',
  },
  {
    iconSrc: 'DemoPro',
    text: 'Алгоритм на основе ИИ предложит подходящие товары каждому посетителю',
  },
  {
    iconSrc: 'DemoPro',
    text: 'Сокращение клиентского пути от входа на сайт до заказа',
  },
  {
    iconSrc: 'DemoPro',
    text: 'Возможность отслеживать статистику эффективности настроенных лент',
  },
];

export const promoMaterials: Partial<IPromo> = {
  subheader:
    'Ваш каталог товаров больше 2 тысяч наименований или посещаемость сайта более 50 тысяч в месяц? Тогда это для вас!',
  img: '/assets/images/promo/recommendationsPro.png',
  items,
  text1: 'Система подбирает алгоритм с учетом популярности и предпочтений товаров для посетителей. Создание автоматизированной ленты рекомендаций за 5 минут',
};
