import React from 'react';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';

export const BitrixInstructions = () => {
  return (
    <SettingsItem
      Content={(
        <ol>
          <li>
            Нажмите в меню: Приложения {'>'} Разработчикам {'>'} Готовые
            сценарии {'>'} Другое {'>'} Входящий вебхук.
          </li>
          <li>Выберите метод «crm.lead.add».</li>
          <li>Введите название вебхука и нажмите «Сохранить».</li>
        </ol>
      )}
      title={
        'Создайте входящий вебхук для работы с данными в личном кабинете Битрикс24'
      }
      number={1}
    />
  );
};
