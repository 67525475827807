import React from 'react';
import Icon from '~/components/Icon';

export const statusItems = {
  INSTALL_CODE: (
    <div className="settings-status-bar__status-msg">
      <div className="settings-status-bar__icon _gray-circle">
        <Icon name="CheckedGrayCircle" />
      </div>
      <span className="settings-status-bar__text">Установите код на ваш сайт</span>
    </div>
  ),
  READY: (
    <div className="settings-status-bar__status-msg">
      <div className="settings-status-bar__icon _green-circle">
        <Icon name="CheckedGreenCircle" />
      </div>
      <div>
        <span className="settings-status-bar__text">Код установлен <br />и работает корректно</span>
        <span className="settings-status-bar__additional-text">Можете переходить к использованию инструментов!</span>
      </div>
    </div>
  ),
};
