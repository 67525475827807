import React from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

export type ContextButtonColor = 'grey' | 'red';
export type CallbackArgument = any;

const ContextButton: React.FC<{
  callbackArgument: CallbackArgument;
  name: string;
  callback: (index: CallbackArgument) => void;
  icon?: string;
  color?: ContextButtonColor;
  visible?: boolean;
  disabled?: boolean;
}> = ({
  callbackArgument,
  name,
  callback,
  icon = null,
  color = 'grey',
  visible = true,
  disabled = false,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    !disabled && callback(callbackArgument);
  };


  return (
    <>
      {visible && (
        <div className={styles.button} onClick={handleClick}>
          {icon && (
            <div className={`${styles.icon} ${styles[disabled ? 'disable' : color]}`}>
              <Icon name={icon} />
            </div>
          )}
          <div className={`${styles.name} ${styles[disabled ? 'disable' : color]}`}>
            {name}
          </div>
        </div>
      )}
    </>
  );
};

export default ContextButton;
