import cx from 'classnames';
import React from 'react';
import { Arrows } from '~/components/generic/Wheel/components/Arrows';
import { WinArrow } from '~/components/generic/Wheel/components/WinArrow';
import { IWheel } from '~/components/generic/Wheel/types';
import { WheelSectors } from './components/WheelSectors';
import {
  getDecorativeElementsStyles,
  getNoRotationElementsStyles,
  getOuterShadowElementsStyles,
  getRotatedElementsStyles, getWheelBorderElementStyles
} from './helpers';
import styles from './styles.module.css';

export const Wheel = ({
  sectors,
  radius = 200,
  animationProps,
  isMobile = false,
  cssStyles = {},
  arrowColor,
  className = '',
}: IWheel) => {
  const degree = 360 / sectors.length;

  const { winSectorNumber, onAnimationEnd } = animationProps;

  const diameter = radius * 2;

  const rotatedElementsStyles = getRotatedElementsStyles({
    diameter,
    degree,
    winSectorNumber,
  });

  return (
    <div
      style={cssStyles}
      className={cx(styles.wheel, isMobile && styles.isMobile, className)}
    >
      <div
        className={styles.noRotationElements}
        style={getNoRotationElementsStyles(diameter)}
      >
        <div
          className={styles.outerShadowElementsClass}
          style={getOuterShadowElementsStyles(diameter)}
        />
        <div
          className={styles.decorativeElementsClass}
          style={getDecorativeElementsStyles(diameter)}
        />
        <div
          className={styles.wheelBorderClass}
          style={getWheelBorderElementStyles(diameter)}
        />
        <WinArrow arrowColor={arrowColor} wheelSize={diameter} />
        <Arrows sectors={sectors} radius={radius} />
      </div>

      <div
        style={rotatedElementsStyles}
        className={cx(styles.rotatedStyles, '')}
        onAnimationEnd={onAnimationEnd}
      >
        <WheelSectors sectors={sectors} radius={radius} />
      </div>
    </div>
  );
};
