import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import SwitchButton, { ISwitchButton } from '~/components/ui/SwitchButton';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import authStore, { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import { PreviewSample } from '../SelectSample/PreviewSample';
import styles from './style.module.css';

const SELECT_TIME_SWITCH_BUTTONS: ISwitchButton[] = [
  {
    key: '3',
    name: '3 секунды',
  },
  {
    key: '5',
    name: '5 секунд',
  },
  {
    key: '10',
    name: '10 секунд',
  },
  {
    key: 'custom',
    name: 'Свое время',
  },
];

const SELECT_PAGES_SWITCH_BUTTONS: ISwitchButton[] = [
  {
    key: 'all',
    name: 'На всем сайте',
  },
  {
    key: 'custom',
    name: 'На определенных страницах',
  },
];

export const ConstructorSettings = observer(() => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
    authStore: AuthStore;
  };

  const domain = authStore?.currentOrg?.get('domains')[0];

  const { quizExample, templateData } = quizStore;
  const { getTriggerData, setTriggerData } = quizExample;

  const [newUrl, setNewUrl] = useState<string>('');
  // const [isUrlFailure, setIsUrlFailure] = useState<boolean>(false);
  const [isPreviewShown, setIsPreviewShown] = useState<boolean>(false);

  const onKeyDown = (e: any) => {
    e.persist();
    // setIsUrlFailure(false);

    if (e.key === 'Enter' && newUrl.length > 0) {
      // if (!isValidUrl(newUrl) || !checkOnHttp(newUrl)) {
      //   setIsUrlFailure(true);
      //
      //   return;
      // }

      newUrl.length > 0 &&
        setTriggerData('urls', [...getTriggerData('urls'), newUrl]);
      setNewUrl('');
    }
  };

  const onUrlDelete = (key: number) => {
    const pages = [...getTriggerData('urls')];
    pages.splice(key, 1);
    setTriggerData('urls', pages);
  };

  const defaultPagesKey = getTriggerData('urls')?.length > 0 ? 'custom' : 'all';

  const [activePagesKey, setActivePagesKey] = useState(defaultPagesKey);

  const isUrlsFormsShown = activePagesKey === 'custom';

  const [activeTimeKey, setActiveTimeKey] = useState(
    [3, 5, 10].includes(getTriggerData('timeoutSecond'))
      ? getTriggerData('timeoutSecond').toString()
      : 'custom'
  );

  const isActiveTimeFieldShown = activeTimeKey === 'custom';

  const setPagesKey = (key: string) => {
    if (key === 'all') {
      setTriggerData('urls', []);
      setActivePagesKey(key);

      return;
    }

    setActivePagesKey(key);
  };

  const setTimeout = (key: string) => {
    setActiveTimeKey(key);

    if (key === 'custom') {
      return;
    }

    setTriggerData('timeoutSecond', parseInt(key, 10));
  };

  useEffect(() => {
    return () => {

      if (quizStore.quizConstructorStep === 3 || quizStore.isSaving) {
        const isAlreadyHas = getTriggerData('urls').includes(newUrl);
        newUrl.length > 0 &&
          !isAlreadyHas &&
          setTriggerData('urls', [...getTriggerData('urls'), newUrl]);
        setNewUrl('');
      }
    };
  });

  // @ts-ignore
  return (
    <div className={styles.settings}>
      <div className={styles.buttonWrap}>
        <Button
          className={styles.button}
          icon={'Eye'}
          type={'green-outline'}
          onClick={() => setIsPreviewShown(true)}
          disabled={templateData.errors.length > 0}
        >
          Посмотреть ваш собранный квиз
        </Button>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Когда запускать квиз</div>
        <div className={styles.subtitle}>
          Настройте время через которое появляется квиз.{' '}
        </div>
        <SwitchButton
          className={styles.timeSwitch}
          activeKey={activeTimeKey}
          onClick={setTimeout}
          buttons={SELECT_TIME_SWITCH_BUTTONS}
        />
        {isActiveTimeFieldShown && (
          <div className={styles.customTime}>
            <Input
              className={styles.customInputTime}
              value={getTriggerData('timeoutSecond')}
              onChangeValue={(v: string) => {
                const newValue = parseInt(v, 10);

                if (newValue > 999) {
                  setTriggerData('timeoutSecond', 999);

                  return;
                }
                setTriggerData('timeoutSecond', parseInt(v, 10));
              }}
            />
            <div>секунд(ы)</div>
          </div>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Где показывать клиенту квиз</div>
        <div className={styles.subtitle}>
          Настройте на каких страницах будет появляться квиз.
        </div>
        <SwitchButton
          className={styles.pagesSwitch}
          activeKey={activePagesKey}
          onClick={setPagesKey}
          buttons={SELECT_PAGES_SWITCH_BUTTONS}
        />
        {isUrlsFormsShown && (
          <>
            <div className={styles.links}>
              {getTriggerData('urls').map((page: string, key: number) => (
                <div className={styles.link} key={key}>
                  {page}{' '}
                  <Icon
                    onClick={() => onUrlDelete(key)}
                    className={styles.closeIcon}
                    name={'Close'}
                  />
                </div>
              ))}
              <Input
                className={styles.urlInput}
                value={newUrl}
                onChangeValue={setNewUrl}
                onKeyDown={onKeyDown}
                withoutBorder
              />
              {/*{isUrlFailure && (*/}
              {/*  <span className={styles.error}>Неправильный формат url</span>*/}
              {/*)}*/}
            </div>
            <div className={styles.description}>
              Вы можете указать как отдельные страницы для показа, так и часть
              ссылки, общую для страниц, где хотите видеть квиз. Например, если
              хотете показать квиз на всех страницах товаров, то укажите ссылку,
              содержащую &lt;catalog&gt;
            </div>
          </>
        )}

        {!isUrlsFormsShown && (
          <div className={styles.pagesDescription}>
            Квиз откроется на любой странице сайта <span>{domain}</span> при
            посещении
          </div>
        )}
      </div>

      {isPreviewShown && (
        <PreviewSample
          onClose={() => setIsPreviewShown(false)}
          template={{ ...templateData, body: quizExample.copy() }}
          isEditButtonShown={false}
        />
      )}
    </div>
  );
});
