import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Profile, { IAgreementsTot, TConsentToMarketing } from '~/api/profile';
import Icon from '~/components/Icon';
import PageHeader from '~/components/PageHeader';
import ConsentToMarketing from '~/components/generic/ConsentToMarketing';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import { useIsSBSHook } from '~/customHooks/useIsSbsHook';
import ServiceShutdown from '~/screens/ProfileScreen/ServiceDown';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { ProfileStore } from '~/stores/profileStore';
import useStores from '~/utils/useStores';
import './style.css';

const  ProfileScreen: React.FC = observer(() => {

  const { authStore } = useStores() as { authStore: AuthStore };
  const { profileStore } = useStores() as { profileStore: ProfileStore };

  const hasAdminRights = authStore.currentUser.hasRightTo('ADMIN');

  const [showPopUp, toggleShowPopUp] = useState(false);
  const initialConsentState: TConsentToMarketing = useIsSBSHook() ? { sber: true, sbs: true } :
    { sber: true, tot: true };

  const [consentToMarketing, setConsentToMarketing] = useState<TConsentToMarketing>(initialConsentState);

  useEffect(() => {
    if (showPopUp) {
      (async () => {
        try {
          const response = await Profile.getUserAgreements();
          const data = response.data?.payload[0].data;

          setConsentToMarketing(data as IAgreementsTot);
        } catch (e) {
          throw new Error(e);
        }
      })();
    }
  }, [showPopUp]);

  const onSendUserAgreements = async () => {

    await profileStore.sendUserAgreements(
      consentToMarketing
    );
  };

  return (
    <Screen className="profile-screen">
      <PageHeader
        title="Профиль"
        aside={(
          <Button
            type="outline"
            loading={authStore.isLoggingOut}
            onClick={authStore.logout}
            icon="Logout"
          >
            Выйти из аккаунта
          </Button>
        )}
      />
      <div className="profile-screen__action-bar">
        {hasAdminRights && (
          <div className="profile-screen_footer">
            <div className="_terminate-button" onClick={() => profileStore.changeStep(1)}>
              Отключить сервис
            </div>
            <div>
                <Icon
                  className="_showPopUp"
                  name="NavSettings"
                  onClick={() => toggleShowPopUp(true)}
                />
              </div>
          </div>
        )}
      </div>

      <Drawer
        visible={showPopUp}
        title="Настройки информирования"
        onClose={async () => (
          await onSendUserAgreements(),
          toggleShowPopUp(false)
        )}
        size="x-small"
        className="connection-tab__drawer"
      >
        <div className="_wrapper">
          <ConsentToMarketing
            consentToMarketing={consentToMarketing}
            setConsentToMarketing={setConsentToMarketing}
          />
        </div>
      </Drawer>

      <ServiceShutdown />

    </Screen>
  );
});

export default ProfileScreen;

