import React from 'react';
import { Promo } from '~/components/generic/Promo';
import { promoMaterials } from '~/screens/FeedProScreen/components/EmptyScreen/consts';
import { emptyScreenButtonsCreator } from '~/screens/FeedProScreen/components/EmptyScreen/emptyScreenButtonsCreator';
import styles from './style.module.css';

interface IEmptyScreenProps {
  hasRight: boolean;
}

const EmptyScreen: React.FC<IEmptyScreenProps> = ({ hasRight }) => {
  const { img, items, subheader, text1 } = promoMaterials;

  return (
    <Promo
      actions={emptyScreenButtonsCreator(hasRight)}
      header={(
        <>
          Добро пожаловать в конструктор лент рекомендаций c <span className={styles.header}>ИИ</span>
        </>
      )}
      img={img}
      items={items}
      subheader={subheader}
      text1={text1}
    />
  );
};

export default EmptyScreen;