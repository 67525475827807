import { observer } from 'mobx-react';
import React from 'react';
import Caption from '~/components/generic/Caption';
import Button from '~/components/ui/Button';
import ButtonLike from '~/components/ui/ButtonLike';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Dropdown from '~/components/ui/Dropdown';
import RecommendationFor from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/SettingRule/RecommendationFor';
import RecommendationOfWhat from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/SettingRule/RecommendationOfWhat';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRulesByFeed,
  BusinessRuleEntity,
  BusinessRuleMeta,
  FilterBusinessRuleEntityRequest,
} from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const SettingRule: React.FC<{
  isOpenSettingRule: boolean;
  closeEditor: () => void;
  metadata: BusinessRuleMeta;
  businessRule: BusinessRulesModel;
  getBusinessRuleEntity: (
    parameters: FilterBusinessRuleEntityRequest
  ) => Promise<BusinessRuleEntity[]>;
  businessRulesByFeed: BusinessRulesByFeed;
  settingRuleIsDone: boolean;
}> = observer(
  ({
    isOpenSettingRule,
    closeEditor,
    metadata,
    businessRule,
    getBusinessRuleEntity,
    businessRulesByFeed,
    settingRuleIsDone,
  }) => {
    const allowedBalanceEntities =
      metadata.maxRecommendationTermsInRule -
      businessRule.get('recommendationOfWhat').entities.length;
    const allowedBalanceEntityCount =
      metadata.maxItemsInRule -
      businessRule
        .get('recommendationOfWhat')
        .entities.reduce((previousValue, item) => {
          return previousValue + item.itemCount;
        }, 0);

    return (
      <Drawer
        className="business_rule_editor__drawer"
        title={'Настройте правило'}
        visible={isOpenSettingRule}
        onClose={closeEditor}
        size="x-small"
      >
        <div className={styles.body_wrapper}>
          <div className={styles.content_wrapper}>
            <div className={styles.subtitle}>
              Укажите, какие товары и категории вы хотите рекомендовать для
              других товаров и категорий.
            </div>
            <Dropdown
              className={styles.dropdown}
              items={metadata.recommendationForTypeDict.map(
                (recommendationForType) => {
                  return {
                    key: recommendationForType.key,
                    value: recommendationForType.uiText,
                  };
                }
              )}
              size={'large'}
              selectedItemKey={businessRule.get('recommendationFor').type}
              onSelect={businessRule.setRecommendationForType}
            />
            <RecommendationFor
              businessRule={businessRule}
              metadata={metadata}
              getBusinessRuleEntity={getBusinessRuleEntity}
              businessRulesByFeed={businessRulesByFeed}
            />
            <Dropdown
              className={styles.dropdown}
              items={metadata.recommendationTypeDict.map(
                (recommendationType) => {
                  return {
                    key: recommendationType.key,
                    value: recommendationType.uiText,
                  };
                }
              )}
              size={'large'}
              selectedItemKey={businessRule.get('recommendationType')}
              onSelect={businessRule.setRecommendationType}
            />
            <Dropdown
              className={styles.dropdown}
              items={metadata.recommendationOfWhatTypeDict.map(
                (recommendationOfWhatType) => {
                  return {
                    key: recommendationOfWhatType.key,
                    value: recommendationOfWhatType.uiText,
                  };
                }
              )}
              size={'large'}
              selectedItemKey={businessRule.get('recommendationOfWhat').type}
              onSelect={businessRule.setRecommendationOfWhatKey}
            />
            {businessRule
              .get('recommendationOfWhat')
              .entities.map((entities, index) => {
                return (
                  <RecommendationOfWhat
                    key={index}
                    businessRule={businessRule}
                    metadata={metadata}
                    entities={entities}
                    index={index}
                    getBusinessRuleEntity={getBusinessRuleEntity}
                  />
                );
              })}
            {allowedBalanceEntities !== 0 && allowedBalanceEntityCount !== 0 && (
              <Button
                icon={'Plus'}
                type={'grey'}
                block
                onClick={businessRule.addRecommendationOfWhatEntity}
              >
                Добавить категорию
              </Button>
            )}
            <div className={styles.info_block}>
              <div className={styles.info_text}>
                Можно добавить категорий:{' '}
                <span
                  className={`${styles.info_number} ${
                    allowedBalanceEntities === 0 ? styles.red : styles.green
                  }`}
                >
                  {allowedBalanceEntities}
                </span>
              </div>
              <div className={styles.info_text}>
                Можно добавить товаров:{' '}
                <span
                  className={`${styles.info_number} ${
                    allowedBalanceEntityCount === 0 ? styles.red : styles.green
                  }`}
                >
                  {allowedBalanceEntityCount}
                </span>
              </div>
            </div>
            <Caption className={styles.caption} backgroundColor={'blue'}>
              Для остальных категорий и товаров, не включенных в правила,
              рекомендации будут рассчитаны в соответствии с алгоритмами
              выбранной ленты рекомендации.
              <br />
              <br />
              Если не найдено достаточное количество товаров, подходящих под
              критерии бизнес-правила, то мы отображаем рекомендации в
              соответствии с выбранным типом ленты.
            </Caption>
            <Caption className={styles.caption} backgroundColor={'yellow'}>
              Бизнес-правило перестанет использоваться в ленте, если с
              обновлением каталога товаров поменяется название товара или ID
              товара. Для возобновления работы бизнес-правила нужно будет заново
              указать все категории товаров.
            </Caption>
          </div>
          <DrawerFooter
            actions={(
              <>
                <ButtonLike
                  onClick={closeEditor}
                  disabled={!settingRuleIsDone}
                >
                  Готово
                </ButtonLike>
                {!!businessRule.get('ymlUrl') && (
                  <a
                    href={businessRule.get('ymlUrl')}
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                  >
                    Посмотреть каталог↗
                  </a>
                )}
              </>
            )}
          />
        </div>
      </Drawer>
    );
  }
);

export default SettingRule;
