const darkTextColors = ['#fad669', '#ffffff'];

const getTextColorByBackground = (rgb: string): string => {
  let hex = rgb;

  if (rgb.startsWith('rgb')) {
    hex = '#' + rgb
      .match(/\d+/g)
      .map((comp, idx) => idx < 3
        ? parseInt(comp, 10).toString(16).padStart(2, '0')
        : ''
      )
      .join('')
    ;
  }

  if (darkTextColors.includes(hex)) return '#000';

  return '#fff';
};

export default getTextColorByBackground;
