import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icons } from '~/components/Icon';
import { Promo } from '~/components/generic/Promo';
import { IAction } from '~/components/generic/Promo/Actions';
import { TButton } from '~/components/ui/ButtonLike';
import { promoMaterials } from '~/screens/FeedLiteScreen/components/EmptyScreen/consts';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

export const EmptyScreen = observer(
  ({
    setIsCreateFeedVideoShown,
  }: {
    setIsCreateFeedVideoShown: (v: boolean) => void;
  }) => {
    const { feedLiteStore, authStore } = useStores() as {
      feedLiteStore: FeedLiteStore;
      authStore: AuthStore;
    };

    const { settingsStore } = useStores() as { settingsStore: SettingsStore };

    const hasWrites = authStore.currentUser.hasRightTo('VIEW_FEEDS_LITE');

    const [isNonIndividual, setIsNonIndividual] = useState<boolean>(false);
    const [isActionButtonLoading, setIsActionButtonLoading] =
      useState<boolean>(!hasWrites);

    useEffect(() => {

      if (hasWrites) return;

      (async () => {
        setIsActionButtonLoading(true);
        await settingsStore.getServices();

        const isNonIndividualCheck = !!settingsStore.services.filter((v, k) => {

          return v.price !== 0 && !v.isActive && v.code === 'FEED_LITE_SRV';
        })?.length;

        setIsNonIndividual(isNonIndividualCheck);
        setIsActionButtonLoading(false);

      })();
    }, []);

    const { reset, setIsConstructorLiteOpen } = feedLiteStore;

    const handleCreate = () => {
      reset();
      setIsConstructorLiteOpen(true);
    };

    const history = useHistory();

    const getActionButton = () => {

      if (isActionButtonLoading) {
        return {
          type: 'default' as TButton,
          name: '',
          loading: isActionButtonLoading,
        };
      }

      if (hasWrites) {
        return {
          onClick: handleCreate,
          icon: 'Plus',
          type: 'default',
          name: 'Создать ленту рекомендаций Lite',
        };
      }

      if (isNonIndividual) {
        return {
          onClick: () => history.push('/settings'),
          icon: 'Plus',
          type: 'default',
          name: 'Подключить',
        };
      }

      return {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default',
        name: 'Оставить заявку',
      };
    };

    const videoShownButton = {
      icon: 'PlayOutline' as Icons,
      type: 'green-outline' as TButton,
      onClick: () => setIsCreateFeedVideoShown(true),
      name: 'Как создать ленту рекомендаций Lite?',
    };

    const actions = [getActionButton(), videoShownButton] as IAction[];
    const { header, img, items, subheader } = promoMaterials;

    return (
      <Promo
        actions={actions}
        header={header}
        img={img}
        items={items}
        subheader={subheader}
      />
    );
  }
);
