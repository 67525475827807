import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentfeedTitleCssParam = (configuration: IFeedConfiguration): string => {
  const {
    feedTitleFontFamily,
    feedTitleColor,
    feedTitleOpacity,
    feedTitleFontWeight,
    feedTitleFontSize,
    feedTitleFontItalic,
    feedTitleTextUnderline,
    feedTitleTextLineThrough,
  } = configuration;
  
  return `
    font-family: ${feedTitleFontFamily};
    color: ${hexToRgb(feedTitleColor, feedTitleOpacity)};
    font-weight: ${fontWeightValues[feedTitleFontWeight]};
    font-size: ${feedTitleFontSize}px;
    font-style: ${feedTitleFontItalic ? 'italic' : 'normal'};
    text-decoration: ${getTextDecoration(
      feedTitleTextUnderline,
      feedTitleTextLineThrough
    )};
  `.replace(/(\r\n|\n|\r|↵)/gm, '').trim();
};