import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentCategoryButtonTextCssParam = (configuration: IFeedConfiguration): string => {
  const {
    categoryButtonBorderRadius,
    categoryButtonBorderWidth,
    categoryButtonBorderColor,
    categoryButtonBorderOpacity,
    categoryButtonColor,
    categoryButtonOpacity,
    categoryButtonFontFamily,
    categoryButtonTextColor,
    categoryButtonTextOpacity,
    categoryButtonFontWeight,
    categoryButtonFontSize,
    categoryButtonFontItalic,
    categoryButtonTextLineThrough,
    categoryButtonTextUnderline,
  } = configuration;
  
  return `
    border: ${categoryButtonBorderWidth}px solid  ${hexToRgb(categoryButtonBorderColor, categoryButtonBorderOpacity)};
    border-radius: ${categoryButtonBorderRadius}px;
    background-color: ${hexToRgb(categoryButtonColor, categoryButtonOpacity)};
    font-family: ${categoryButtonFontFamily};
    color: ${hexToRgb(categoryButtonTextColor, categoryButtonTextOpacity)};
    font-weight: ${fontWeightValues[categoryButtonFontWeight]};
    font-size: ${categoryButtonFontSize}px;
    font-style: ${categoryButtonFontItalic ? 'italic' : 'normal'};
    text-decoration: ${getTextDecoration(
      categoryButtonTextLineThrough,
      categoryButtonTextUnderline
    )};
  `.replace(/(\r\n|\n|\r|↵)/gm, '').trim();
};