import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import './style.css';

enum CheckStateOfHint {
  InitialState = 0,
  ButtonCheckCodeClicked = 1,
  LinkToWebsiteClicked = 2,
}

enum ErrorText {
  InitialState = null,
  ErrorOfNotFoundCode = 1,
  AnyAtherError = 2,
}

const ERROR_TEXT = 'Произошел сбой при проверке подключения. Обновите страницу или повторите попытку позже.';

const CheckCodeButton: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const [installingError, setInstallingError] = useState(ErrorText.InitialState);
  const [stepOfHint, setStepOfHint] = useState(CheckStateOfHint.InitialState);
  const domain = authStore.currentOrg.domain[0];
  const history = useHistory();

  const checkCode = async () => {
    try {
      await authStore.fetchOrgData();
      await authStore.checkCode();
      await authStore.fetchProfile();
      await authStore.fetchClientState();
      history.push('/widgets');
    } catch (e) {
      if (e.response.data.errorData.errorType === 'CheckScriptException') {
        setInstallingError(ErrorText.ErrorOfNotFoundCode);
      } else {
        setInstallingError(ErrorText.AnyAtherError);
      }
      if (stepOfHint === CheckStateOfHint.ButtonCheckCodeClicked) {
        setStepOfHint(CheckStateOfHint.LinkToWebsiteClicked);
      }
      throw e;
    }
  };

  const handleLinkClick = () => {
    setStepOfHint(CheckStateOfHint.ButtonCheckCodeClicked);
  };

  const codeNotFoundError = (
    <>
      Код СберЛид не обнаружен. Перед тем, как проверить корректность установки кода
      еще раз, зайдите на свой
      <Link to={domain} target="_blank" onClick={handleLinkClick} className="check-code-link">
        сайт
      </Link>
      и повторно нажмите "Проверить подключение".
    </>
  );

  return (
      <div className="check-code">
        <Button
          size="large"
          onClick={checkCode}
          className="check-code__btn"
          loading={authStore.isCodeChecking}
        >
          Проверить подключение
        </Button>
        {stepOfHint !== CheckStateOfHint.LinkToWebsiteClicked && installingError && (
          <p className="check-code__hint-1 hint">
            {installingError === ErrorText.ErrorOfNotFoundCode && codeNotFoundError}
            {installingError === ErrorText.AnyAtherError && ERROR_TEXT}
          </p>
        )}
        {stepOfHint === CheckStateOfHint.LinkToWebsiteClicked && (
          <p className="check-code__hint-2 hint">
            Код СберЛид не обнаружен. Пожалуйста, проверьте корректность установки кода
             на вашем сайте. После чего, обновите страницу и проверьте подключение еще раз.
            <br /><br />
            Также вы можете ознакомиться с
            <Link to="/guide/cms" className="check-code-link">
              подробной инструкцией
            </Link>
          </p>
        )}
      </div>
  );
});

export default React.memo(CheckCodeButton);
