import { Alert, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { AuthTechSuccessScreen } from '~/screens/Auth/AuthTechScreen/AuthTechSuccessScreen';
import { sbbLoginDataCollection } from '~/screens/SbbIDLoginScreen/sbbLoginDataCollection';
import { AuthStore } from '~/stores/authStore';

type Props = {
  authStore?: AuthStore;
} & RouteComponentProps<{
  redirect?: string;
}>;

@inject('authStore')
@observer
class SbbIDLoginTechScreen extends Component<Props> {
  state = {
    errorMessage: 'Упс, что-то пошло не так :(',
  };

  componentDidMount() {
    this.handleSbbIDOauth();
  }

  handleSbbIDOauth = async () => {
    const { authStore } = this.props;
    const sbbIDLoginData = sbbLoginDataCollection();
    try {
      if (sbbIDLoginData.state && sbbIDLoginData.nonce && sbbIDLoginData.code) {
        authStore.storeSbbIDData(sbbIDLoginData);
        await authStore.loginTechWithSberID();
      } else {
        throw new Error('');
      }
    } catch (e) {
      this.setState({
        errorMessage: e?.response?.data?.errorData?.errorText.split(':')[0],
      });
    }
  }

  render() {
    const { authStore } = this.props;
    const { isLoggingIn, isTechnicalLogin } = authStore;

    return (
      <>
        {isLoggingIn ? (
          <div className="login-screen__spinner-container">
            <Spin size="large" spinning={isLoggingIn} />
          </div>
        ) : !isTechnicalLogin ? (
          <Alert
            message={this.state.errorMessage}
            type="error"
            showIcon
            style={{ marginBottom: 24 }}
          />
        ) : (
          <AuthTechSuccessScreen />
        )}
      </>
    );
  }
}

export default SbbIDLoginTechScreen;
