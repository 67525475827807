import { IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import HandImageParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Image/HandImageParams';
import { currentImageCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Image/currentImageCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import FormItem from '~/components/ui/FormItem';
import SwitchButton from '~/components/ui/SwitchButton';

interface IImageProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
}

export const Image: React.FC<IImageProps> = ({ configuration, onChange }) => {
  const [switchButtonState, setSwitchButtonState] = useState<string>(
    configuration.css?.image ? 'CSS' : 'HAND'
  );
  const onChangeCssParam = (value: string) => {
    const css = configuration.css ?? {};
    css.image = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Настроить стиль изображения"
          overlayClassName="feed-editor-appearance__tooltip"
          className="feed-editor-appearance__font"
        >
          <SwitchButton
            buttons={cssSwitchButtons}
            activeKey={switchButtonState}
            onClick={setSwitchButtonState}
          />
        </FormItem>
        {switchButtonState === 'CSS' && (
          <ParamCss
            onChangeCssParam={onChangeCssParam}
            value={configuration.css?.image ?? currentImageCssParam(configuration)}
          />
        )}
      </section>
      {switchButtonState === 'HAND' && (
        <HandImageParams
          isActive={switchButtonState === 'HAND'}
          configuration={configuration}
          onChange={onChange}
          onChangeCssParam={onChangeCssParam}
        />
      )}
    </>
  );
};
