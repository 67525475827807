import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { AddDomain } from '~/screens/Auth/DomainScreen/AddDomain';
import { ConnectedTariffs } from '~/screens/Auth/DomainScreen/ConnectedTariffs';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { ServiceStore } from '~/stores/serviceStore';
import { TariffStore } from '~/stores/tariffStore';
import { getFormattedDate } from '~/utils/date/getFormattedDate';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const DomainScreen = observer(() => {

  const { serviceStore, authStore } = useStores() as {
    serviceStore: ServiceStore;
    authStore: AuthStore;
    tariffStore: TariffStore;
  };

  const availableServices = serviceStore.availableServices;
  
  const formattedDate = getFormattedDate(authStore.currentOrg.get('tarplans')[0].dateTo);

  useEffect(() => {
    (async () => {
      await serviceStore.getCurrentServices();
    })();
  }, []);

  return (
    <Screen className={styles.page}>
      <h1 className={styles.header}>Вам доступны четыре инструмента</h1>
      <div className={styles.label}>
        Бесплатный тестовый период до {formattedDate}
      </div>
      <ConnectedTariffs tariffs={availableServices} />
      <AddDomain />
    </Screen>
  );
});
