import React from 'react';
import Icon, { Icons } from '~/components/Icon';
import Button from '~/components/ui/Button';
import { TButton } from '~/components/ui/ButtonLike';
import styles from './style.module.css';

export interface IAction {
  type: TButton;
  name: string;
  onClick?: () => void;
  icon?: Icons;
  loading?: boolean;
}

export const Actions = ({ actions }: { actions: IAction[] }) => {
  return (
    <div className={styles.actions}>
      {actions?.map((action, key) => {
        const { type, onClick, loading, icon, name } = action;

        return (
          <Button
            key={key}
            className={styles.action}
            type={type !== 'default' ? type : undefined}
            onClick={onClick}
            loading={loading}
          >
            {icon && <Icon name={icon} className={styles.icon} />} {name}
          </Button>
        );
      })}
    </div>
  );
};
