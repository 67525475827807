import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

interface IHiddenText {
  children: string | JSX.Element;
  onClick?: () => void;
  className?: string;
}

export const HiddenText = ({
  children,
  onClick = () => { return; },
  className,
}: IHiddenText) => {
  const [isHiddenTextDisplayed, setIsHiddenTextDisplayed] = useState(false);
  const [isHiddenTextCanBeShown, setIsHiddenTextCanBeShown] = useState(false);

  const onMouseEnter = () => setIsHiddenTextDisplayed(true);

  const onMouseLeave = () => setIsHiddenTextDisplayed(false);

  const textRef = useRef(null);
  const hiddenTextRef = useRef(null);

  useEffect(() => {
    const textWidth = textRef?.current.getBoundingClientRect().width || 0;
    const hiddenTextWidth =
      hiddenTextRef?.current.getBoundingClientRect().width || 0;

    if (hiddenTextWidth > textWidth) {
      setIsHiddenTextCanBeShown(true);
    }

  }, []);

  return (
    <div
      onClick={onClick}
      className={cx(styles.hiddenTextWrap, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.textToHide} ref={textRef}>
        {children}
      </div>
      <div
        className={styles.hiddenText}
        ref={hiddenTextRef}
        style={{
          opacity: isHiddenTextDisplayed && isHiddenTextCanBeShown ? 1 : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};
