import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface KeyName {
  name: string;
  key: IPricePosition;
}

interface IPricesPricePositionProps {
  onClick: (key: IPricePosition) => void;
  activePosition?: IPricePosition;
  className?: string;
}

const PricePosition: React.FC<IPricesPricePositionProps> = ({
  onClick,
  activePosition = '',
  className = null,
}) => {
  const prices: KeyName[] = [
    { name: 'PriceColumnPosition', key: 'column-reverse' },
    { name: 'PriceRowPosition', key: 'row' },
  ];

  return (
    <div
      className={`ui-notification-editor-price-position__block ${
        className !== null ? className : ''
      }`}
    >
      {prices.map((price) => {
        return (
          <div
            key={`${price.name}_${price.key}`}
            className="ui-notification-editor-price-position__price-wrapper"
            onClick={() => onClick(price.key)}
          >
            <Icon
              name={price.name}
              className={cx('ui-notification-editor-price-position__price', {
                _not_selected: price.key !== activePosition,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PricePosition;
