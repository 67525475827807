import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { listItems } from '~/components/generic/SignOfUnificationDropdown';
import CrossSellEmbedding from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/CrossSellEmbedding';
import NewProductsEmbedding from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/NewProductsEmbedding';
import PersonalEmbedding from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/PersonalEmbedding';
import PopularEmbedding from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/PopularEmbedding';
import SettingById from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/SettingById';
import StandardEmbedding from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/StandardEmbedding';
import { CheckedCountPage, FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import { checkOnHttp } from '~/utils/validationUtils/checkOnHttp';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';

export interface IPlacementPageParams {
  checkedCountPage: CheckedCountPage;
  urlPattern?: string;
  url?: string;
  operation?: Operation;
}

interface IFeedProEditorPlacementPages {
  isOpenDialogSettingsById: boolean;
  setIsOpenDialogSettingsById: (status: boolean) => void;
}

const StepPlacementPages: React.FC<IFeedProEditorPlacementPages> = observer(
  ({ isOpenDialogSettingsById, setIsOpenDialogSettingsById }) => {
    const { feedProStore, authStore } = useStores() as {
      feedProStore: FeedProStore;
      authStore: AuthStore;
    };
    const {
      isPlacementPageInvalid,
      setIsPlacementPageInvalid,
      hasClickedOnInput,
      setHasClickedOnInput,
      isRechoosingSelector,
      setIsRechoosingSelector,
      isRechoosingSelectorMobile,
      setIsRechoosingSelectorMobile,
      feed,
      checkedCountPage,
      setCheckedCountPage,
    } = feedProStore;

    const [
      errorMessageOnPlacementPage,
      setErrorMessageOnPlacementPage,
    ] = useState<string>('');

    const recType = feed.get('recType');

    const checkOnDomain = (domain: string): boolean => {
      const domains = authStore?.currentOrg?.domain || [];
      let isValid = false;
      domains.map((baseDomain) => {
        if (!isValid && domain.includes(baseDomain)) {
          isValid = true;
        }
      });

      return isValid;
    };

    const isErrorWhenCheckingForConditions = (
      urlPattern: string,
      url: string,
      operation: Operation
    ): boolean => {
      let regExp: RegExp;
      switch (operation) {
        case 'EQUAL':
          regExp = new RegExp(`^${url}$`);
          break;
        case 'START_WITH':
          regExp = new RegExp(`^${url}`);
          break;
        case 'CONTAINS':
          regExp = new RegExp(`.*${url}.*`);
          break;
        case 'NOT_CONTAINS':
          regExp = new RegExp(`^((?!${url}).)*$`);
          break;
      }
      if (!regExp.test(urlPattern)) {
        setErrorMessageOnPlacementPage(`Ссылка не соответствует условию «${
          listItems.find(item => item.key === operation)?.value
        }». Исправьте ссылку или вставьте другую`);

        return true;
      }

      return false;
    };

    const validateStepPlacementPages = () => {
      const recType = feed.get('recType');
      const urlPattern = feed.get('urlPattern');
      const url = feed.get('displayConditions')?.url;
      const operation = feed.get('displayConditions')?.operation;
      if (!checkOnHttp(urlPattern)) {
        setIsPlacementPageInvalid(true);
        setErrorMessageOnPlacementPage('Ссылка на страницу должна быть полной - включая «http://» или «https://»');

        return;
      }
      if (!isValidUrl(urlPattern) || urlPattern.includes(' ')) {
        setIsPlacementPageInvalid(true);
        setErrorMessageOnPlacementPage('Указанная ссылка некорректна. Исправьте ссылку или вставьте другую');

        return;
      }
      if (!checkOnDomain(urlPattern)) {
        setIsPlacementPageInvalid(true);
        setErrorMessageOnPlacementPage('Ссылка не содержит указанный в профиле домен. Исправьте ссылку или вставьте другую');

        return;
      }
      if (
        (recType === 'PERSONAL' || recType === 'POPULAR') &&
        checkedCountPage === 'ONE' &&
        isErrorWhenCheckingForConditions(urlPattern, url, operation)
      ) {
        setIsPlacementPageInvalid(true);
        
        return;
      }
      setIsPlacementPageInvalid(false);
    };

    const onChangeUrl = ({
      checkedCountPage,
      urlPattern = '',
      url = '',
      operation = 'CONTAINS',
    }: IPlacementPageParams) => {
      setCheckedCountPage(checkedCountPage);

      if (checkedCountPage === 'MULTIPLE') {
        feed.update({ displayConditions: null });
      } else {
        feed.setDisplayConditions({
          operation,
          url,
        });
      }

      if ((recType === 'CROSS_SELL' || recType === 'NEW_PRODUCTS') && checkedCountPage === 'ONE') {
        feed.setDisplayConditions({
          operation: 'EQUAL',
          url: urlPattern,
        });
      }

      feed.setUrlPattern(urlPattern);
      isRechoosingSelector && setIsRechoosingSelector(false);
      isRechoosingSelectorMobile && setIsRechoosingSelectorMobile(false);
      hasClickedOnInput && validateStepPlacementPages();
    };

    const onBlur = () => {
      !hasClickedOnInput && setHasClickedOnInput(true);
      validateStepPlacementPages();
    };

    return (
      <>
        {(recType === 'SIMILAR' || recType === 'ALTERNATIVE') && (
          <StandardEmbedding
            urlPattern={feed.get('urlPattern')}
            onChange={onChangeUrl}
            isInvalid={isPlacementPageInvalid}
            onBlur={onBlur}
            errorMessage={errorMessageOnPlacementPage}
          />
        )}
        {recType === 'NEW_PRODUCTS' && (
          <NewProductsEmbedding
            urlPattern={feed.get('urlPattern')}
            onChange={onChangeUrl}
            isInvalid={isPlacementPageInvalid}
            onBlur={onBlur}
            errorMessage={errorMessageOnPlacementPage}
            checkedCountPage={checkedCountPage}
          />
        )}
        {recType === 'CROSS_SELL' && (
          <CrossSellEmbedding
            urlPattern={feed.get('urlPattern')}
            onChange={onChangeUrl}
            isInvalid={isPlacementPageInvalid}
            onBlur={onBlur}
            errorMessage={errorMessageOnPlacementPage}
            checkedCountPage={checkedCountPage}
            externalKey={feed.get('externalKey')}
          />
        )}
        {recType === 'POPULAR' && (
          <PopularEmbedding
            urlPattern={feed.get('urlPattern')}
            onChange={onChangeUrl}
            isInvalid={isPlacementPageInvalid}
            onBlur={onBlur}
            errorMessage={errorMessageOnPlacementPage}
            checkedCountPage={checkedCountPage}
            externalKey={feed.get('externalKey')}
            url={feed.get('displayConditions')?.url}
            operation={feed.get('displayConditions')?.operation}
            recommendationGenerationType={feed.get('recommendationGenerationType')}
          />
        )}
        {recType === 'PERSONAL' && (
          <PersonalEmbedding
            urlPattern={feed.get('urlPattern')}
            onChange={onChangeUrl}
            isInvalid={isPlacementPageInvalid}
            onBlur={onBlur}
            errorMessage={errorMessageOnPlacementPage}
            checkedCountPage={checkedCountPage}
            operation={feed.get('displayConditions')?.operation}
            url={feed.get('displayConditions')?.url}
          />
        )}
        <SettingById
          isOpenDialogSettingsById={isOpenDialogSettingsById}
          setIsOpenDialogSettingsById={setIsOpenDialogSettingsById}
          onChange={onChangeUrl}
          feed={feed}
        />
      </>
    );
  }
);

export default StepPlacementPages;
