import { observer } from 'mobx-react';
import React from 'react';
import Input from '~/components/ui/Input';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import { getMaxLengthMessage } from '~/screens/MassMailingScreen/utils';
import styles from '../style.module.css';

const SEND_NAME_LIMIT_LENGTH = 55;

const SenderName: React.FC = observer(() => {
  const { mailingInstance } = massMailingStore;
  const authorizationEmailObj = mailingInstance?.get('authorizationEmail');
  const senderName = authorizationEmailObj.senderName;
  const isErrorShown = senderName?.length >= SEND_NAME_LIMIT_LENGTH;

  const setName = (senderName: string) => {
    mailingInstance.update({
      authorizationEmail: { ...authorizationEmailObj, senderName },
    });
  };

  return (
    <div className={styles.input_block}>
      <h3>Введите имя отправителя</h3>
      <p className={styles.description}>
        Это то название, которое отображается вместо адреса почты у получателя
        письма.
      </p>
      <Input
        placeholder="MyCompanyName"
        className={styles.input}
        value={senderName}
        onChangeValue={setName}
        maxLength={SEND_NAME_LIMIT_LENGTH}
        failure={isErrorShown}
      />
      {isErrorShown && (
        <div className={styles.error}>
          {getMaxLengthMessage(SEND_NAME_LIMIT_LENGTH)}
        </div>
      )}
    </div>
  );
});

export default SenderName;
