import React from 'react';
import { ISwitchUI } from '~/components/SwitchUI/types';
import { hasAccess } from '~/components/SwitchUI/utils';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

/**
 * Рубильник. Если элемент отключен, то вместо него выводим кастомную заглушку
 * либо null.
 *
 * @props {ISwitchUI}
 * @return
 */
export const SwitchUI: React.FC<ISwitchUI> = ({
  children,
  elementCode,
  rights,
  plug,
  customConditionFn,
}: ISwitchUI): JSX.Element => {

  const { uiSwitchStore, authStore } = useStores() as {
    uiSwitchStore: UiSwitchStore;
    authStore: AuthStore;
  };

  const codesToHide = uiSwitchStore.getCodesToHide();
  const isElementShown = hasAccess({ codesToHide, elementCode });

  const user = authStore.currentUser;
  const hasRights =
    !rights || (rights && rights.some(code => user.hasRightTo(code)));

  const isCustomFnCheck =  !(customConditionFn && !customConditionFn());

  if (isElementShown && hasRights && isCustomFnCheck) return <>{children}</>;

  return plug ? plug : null;
};
