import { configure } from 'mobx';
import { Provider as MobXProvider } from 'mobx-react';
import 'mobx-react/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from '~/App';
import stores from './stores';

if (typeof window !== 'undefined') {
  // @ts-ignore
  window._____APP_STATE_____ = stores;
}

configure({ enforceActions: 'never' });

const render = (Component: any) => ReactDOM.render(
  (
    <MobXProvider {...stores}>
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    </MobXProvider>
  ),
  document.getElementById('root')
);

render(App);

// Hot Module Replacement
declare let module: { hot: any };

if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
