import { Dropdown } from 'antd';
import { IAnswerList, IQuestionStep } from 'feed';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import ContextButton from '~/components/generic/SupportingButton/ContextButton';
import ContextMenu from '~/components/generic/SupportingButton/ContextMenu';
import styles from './style.module.css';

interface IQuestionItemProps {
  question: IQuestionStep;
  onClick: (question: IQuestionStep, isNewQuestion: boolean) => void;
}

export const QuestionItem: React.FC<IQuestionItemProps> = ({
  question,
  onClick,
}) => {
  const [isVisibleContext, setIsVisibleContext] = useState<boolean>(false);

  return (
    <Dropdown
      overlay={(
        <ContextMenu
          buttons={(
            <>
              <ContextButton
                callbackArgument={question}
                name={'Добавить новый вопрос'}
                callback={(question) => {
                  onClick(question, true);
                  setIsVisibleContext(false);
                }}
              />
              <ContextButton
                callbackArgument={question}
                name="Заменить текущий вопрос"
                callback={(question) => {
                  onClick(question, false);
                  setIsVisibleContext(false);
                }}
              />
            </>
          )}
          onClickOutside={() => setIsVisibleContext(false)}
        />
      )}
      placement="bottomLeft"
      visible={isVisibleContext}
    >
      <div className={styles.question} onClick={() => setIsVisibleContext(true)}>
        <div className={styles.questionIconWrapper}>
          <div className={styles.questionIcon}>
            <Icon name={'Plus'} />
          </div>
        </div>
        <div className={styles.questionBody}>
          <div className={styles.questionTitle}>
            {question.title}
          </div>
          <div className={styles.questionDesc}>
            {(question.answers as IAnswerList[])[0]?.image ? 'Ответы с изображением' : 'Ответы без изображения'}
          </div>
          <div className={styles.questionItemsBlock}>
            {question.answers?.map((answer, index) => {
              return (
                <div key={index.toString()} className={styles.questionItemWrapper}>
                  <div className={styles.questionItemIcon}>
                    <Icon name={'EmptyCircle'} />
                  </div>
                  <div className={styles.questionItemDesc}>
                    {answer.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dropdown>
  );
};