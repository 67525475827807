import { IFeedConfiguration, TFeedCartLabel } from 'feed';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LabelColorEditor, {
  ILabelColorEditorData,
} from '~/components/notification-editor/LabelColorEditor';
import ButtonLike from '~/components/ui/ButtonLike';
import FormItem from '~/components/ui/FormItem';
import Message from '~/components/ui/Message';
import TitleLabelsBlock from '~/screens/FeedLiteScreen/components/FeedConstructor/StepFeedProduct/FeedConstuctorAddProduct/LabelsBlock/TitleLabelsBlock';
import { LABELS_LIMIT } from '~/screens/FeedLiteScreen/constants';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import useStores from '~/utils/useStores';
import './style.css';

const LabelsBlock: React.FC<{
  currentLabelsInProduct: string[];
  setCurrentLabelsInProduct: (labelsInProduct: string[]) => void;
}> = observer(({ currentLabelsInProduct, setCurrentLabelsInProduct }) => {
  const { feedLiteStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
  };
  const { feedConfiguration, currentFeed, getProducts } = feedLiteStore;

  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [deleteLabel, setDeleteLabel] = useState<CartLabel>(null);

  const onChange = (configuration: IFeedConfiguration) => {
    currentFeed.setConfiguration(configuration);
  };

  const onChecked = (status: boolean, labelUuid: string) => {
    const setLabels = new Set(currentLabelsInProduct);
    status ? setLabels.add(labelUuid) : setLabels.delete(labelUuid);
    setCurrentLabelsInProduct([...setLabels.keys()]);
  };

  const onDeleteLabel = () => {
    onChecked(false, deleteLabel.uuid);
    setShowWarningMessage(false);
    getProducts.forEach((product) => {
      const setLabels = new Set(product.get('labels'));
      setLabels.delete(deleteLabel.uuid);
      product.update({ labels: [...setLabels.keys()] });
    });
    const tempLabelsArray: CartLabel[] = feedConfiguration.labels;
    tempLabelsArray.splice(
      feedConfiguration.labels.findIndex(
        (labelInConfiguration: TFeedCartLabel) => labelInConfiguration.uuid === deleteLabel.uuid
      ),
      1
    );
    onChange({
      ...feedConfiguration,
      labels: [...tempLabelsArray],
    });
  };

  const warningBeforeDeleteLabel = (deleteLabel: CartLabel) => {
    setDeleteLabel(deleteLabel);
    setShowWarningMessage(true);
  };

  return (
    <>
      <FormItem
        title={(
          <TitleLabelsBlock currentLabelsLength={currentLabelsInProduct.length} />
        )}
        message={'Карточка товара может уместить до 3-х тегов'}
      />
      <div className="add-product-labels__wrapper">
        {feedConfiguration.labels?.map((label: TFeedCartLabel, index: number) => {
          const isCheckedLabelColorEditor = currentLabelsInProduct.includes(
            label.uuid
          );

          return (
            <LabelColorEditor
              key={`${label.uuid}_${index}`}
              text={label.text}
              labelBackgroundColor={label.labelBackgroundColor}
              labelBackgroundOpacity={label.labelBackgroundOpacity}
              feedConfiguration={feedConfiguration}
              onChangeColor={(data: ILabelColorEditorData) => {
                const labels = feedConfiguration.labels;
                labels.find(
                  (labelInConfiguration: TFeedCartLabel) =>
                    labelInConfiguration.uuid === label.uuid
                ).labelBackgroundColor = data.labelColor;
                labels.find(
                  (labelInConfiguration: TFeedCartLabel) =>
                    labelInConfiguration.uuid === label.uuid
                ).labelBackgroundOpacity = data.labelOpacity;
                onChange({
                  ...feedConfiguration,
                  labels,
                });
              }}
              isPossibleTextEditing
              onChangeName={(text: string) => {
                const labels = feedConfiguration.labels;
                labels.find(
                  (labelInConfiguration: TFeedCartLabel) =>
                    labelInConfiguration.uuid === label.uuid
                ).text = text;
                onChange({
                  ...feedConfiguration,
                  labels,
                });
              }}
              step={10}
              isShowCheckBox
              checked={isCheckedLabelColorEditor}
              onCheck={status => onChecked(status, label.uuid)}
              disabledCheckBox={
                !isCheckedLabelColorEditor &&
                currentLabelsInProduct.length === LABELS_LIMIT
              }
              isShowTrashButton
              onDelete={() => warningBeforeDeleteLabel(label)}
            />
          );
        })}
        <ButtonLike
          type="text"
          icon={'Plus'}
          onClick={() => {
            const labels = feedConfiguration.labels;
            labels.push({
              uuid: uuidv4(),
              text: 'Ваш тег',
              labelBackgroundColor: '#007AFF',
              labelBackgroundOpacity: 100,
            });
            onChange({
              ...feedConfiguration,
              labels,
            });
          }}
        >
          Добавить тег
        </ButtonLike>
      </div>
      {showWarningMessage && (
        <Message
          className="feed-constructor__message-box"
          visible={showWarningMessage}
          title={`Удалить тег «${deleteLabel?.text}» на всех карточках товара?`}
          textTop={'При продолжении операции тег удалится на всех карточках'}
          showIcon={false}
          isErrorBlock={false}
          applyText={'Удалить'}
          cancelText={'Отмена'}
          onApply={onDeleteLabel}
          onExit={() => setShowWarningMessage(false)}
          onClose={() => setShowWarningMessage(false)}
        />
      )}
    </>
  );
});

export default LabelsBlock;
