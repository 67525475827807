import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const PlugSberCRM = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;
  const [sberCRMUrl, setSberCRMUrl] = useState<string>();
  const sberCRMUrlError = leadSettings.get('sberCRMUrlError');

  useEffect(() => {
    leadSettings.update({ sberCRMUrlError: '' });
  }, []);

  const onChangeValue = (sberCRMUrl: string) => {
    setSberCRMUrl(sberCRMUrl);
    leadSettings.update({ sberCRMUrlError: '' });
  };

  const enableSberCRM = async () => {
    await leadSettings.enableSberCRM(sberCRMUrl);
  };

  return (
    <SettingsItem
      title={
        'Скопируйте полученный WebHook, вставьте его в это поле и нажмите «Подключить»'
      }
      number={3}
      Content={(
        <>
          <Input
            className={'leads-settings__item-input'}
            onChangeValue={onChangeValue}
            failure={!!sberCRMUrlError}
            placeholder={'https://app.sbercrm.com/react-gateway/api/webhook/63190d07'}
          />
          {<div className={'leads-settings__item-error'}>{sberCRMUrlError}</div>}
          <Button
            className="leads-settings__item-button"
            onClick={enableSberCRM}
          >
            Подключить
          </Button>
        </>
      )}
    />
  );
});
