import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from './client';

export type MessageResponse = IResponse<IMessage>;
export type TariffResponse = IResponse<Array<ITariff>>;

enum ETariffsType {
  available = 'available',
  current = 'current',
}

export enum ETariffsCode {
  PROMO = 'PROMO',
}

const Tariffs = {
  getAvailableTariffs: (currentTariffCode: string): AxiosPromise<TariffResponse> =>
    actionGet('/admin/totTarplan', {
      type: ETariffsType.available,
      code: currentTariffCode,
    }),

  setTariff: (tariff: ITariffPayload): AxiosPromise<[]> =>
    actionSet('/admin/totTarplan', tariff, undefined),

  getCurrentTariff: (): AxiosPromise<TariffResponse> =>
    actionGet('/admin/totTarplan', { type: ETariffsType.current }),

};

export default Tariffs;
