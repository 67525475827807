import React, { useState } from 'react';
import { hasAccess } from '~/components/SwitchUI/utils';
import { SettingsSwitcher } from '~/screens/LeadsSettingsScreen/SettingsSwitcher';
import {
  leadsSettingsMenuItems,
  leadSettingsMenuContent,
} from '~/screens/LeadsSettingsScreen/consts';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import './style.css';

export const LeadsSettings = () => {
  const { uiSwitchStore, authStore } = useStores() as {
    uiSwitchStore: UiSwitchStore;
    authStore: AuthStore;
  };
  const codesToHide = uiSwitchStore.getCodesToHide();


  const initialMenuItems = leadsSettingsMenuItems.filter(v =>
    hasAccess({ codesToHide, elementCode: v?.uiSwitch as TSwitchCode })
  ).filter((v) => {

    if (v.right) {
      return authStore.currentUser.hasRightTo(v.right as IRightCode);
    }

    return true;
  });

  const [rememberedItems, setRememberedItems] = useState(initialMenuItems);

  const changeActiveItem = (activeItemKey: number) => {
    setRememberedItems(
      rememberedItems.map(item => ({
        ...item,
        active: activeItemKey === item.key,
      }))
    );
  };

  const activeKey = rememberedItems.filter(v => v.active)[0].key;

  return (
    <div className="leads-settings">
      <SettingsSwitcher
        settingsList={rememberedItems}
        changeActiveItem={changeActiveItem}
      />
      {leadSettingsMenuContent[activeKey]}
    </div>
  );
};
