import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Promo } from '~/components/generic/Promo';
import { IItem } from '~/components/generic/Promo/Items';
import { TButton } from '~/components/ui/ButtonLike';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import style from '~/screens/WheelOfFortune/style.module.css';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { TariffStore } from '~/stores/tariffStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

const items: IItem[] = [
  {
    iconSrc: 'DemoWheel',
    text: 'Продвигайте продажу любых товаров',
  },
  {
    iconSrc: 'DemoWheel',
    text: 'Мотивируйте покупателя',
  },
  {
    iconSrc: 'DemoWheel',
    text: 'Повышайте конверсию и возвращаемость',
  },
];

export const EmptyWheels = observer(
  () => {
    const { wheelOfFortuneStore, settingsStore, authStore } = useStores() as {
      tariffStore: TariffStore;
      wheelOfFortuneStore: WheelOfFortuneStore;
      settingsStore: SettingsStore;
      authStore: AuthStore;
    };

    const handleCreate = async () => {
      wheelOfFortuneStore.setSelectedId(null);
      wheelOfFortuneStore.setIsConstructorOpen(true);
    };

    const [isNonIndividual, setIsNonIndividual] = useState<boolean>(false);
    const [isActionButtonLoading, setIsActionButtonLoading] =
      useState<boolean>(false);

    const hasWrites = authStore.currentUser.hasRightTo(
      'LIST_FORTUNE_WHEEL_SCENARIOS'
    );

    useEffect(() => {
      if (hasWrites) return;

      (async () => {
        setIsActionButtonLoading(true);
        await settingsStore.getServices();

        const isNonIndividualCheck = !!settingsStore.services.filter((v, k) => {
          return v.price !== 0 && !v.isActive && v.code === 'FORTUNE_WHEEL_SRV';
        })?.length;

        setIsNonIndividual(isNonIndividualCheck);
        setIsActionButtonLoading(false);
      })();
    }, []);

    const history = useHistory();

    const getActionButton = () => {
      if (isActionButtonLoading) {
        return {
          type: 'default' as TButton,
          name: '',
          loading: isActionButtonLoading,
        };
      }

      if (hasWrites) {
        return {
          onClick: handleCreate,
          icon: 'Plus',
          type: 'default' as TButton,
          name: 'Создать колесо фортуны',
        };
      }

      if (isNonIndividual) {
        return {
          onClick: () => history.push('/settings'),
          icon: 'Plus',
          type: 'default' as TButton,
          name: 'Подключить',
        };
      }

      return {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default' as TButton,
        name: 'Оставить заявку',
      };
    };

    const actions = [getActionButton()];

    const image = (
      <div className={style.wheel_gif}>
        <img src="assets/wheel/wheel.gif" alt={''} />
        <div className={style.wheel_gif_wrapper_support}>
          <p>А еще, мы можем сделать настройку инструмента за вас</p>
          <a
            href="mailto:helpsberlead@tot.technology"
            target="_blank"
            rel="noopener noreferrer"
          >
            Напишите нам
          </a>
        </div>
      </div>
    );

    return (
      <Promo
        actions={actions}
        header={'Добро пожаловать в конструктор колеса фортуны'}
        img={image}
        items={items}
        subheader={
          'Увеличивайте продажи и привлекайте больше посетителей на ваш сайт,  мотивируя их к покупке в игровой форме'
        }
        text1="Колесо фортуны появляется на любой странице вашего сайта для каждого посетителя один раз"
      />
    );
  }
);
