import React, { useState } from 'react';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import styles from './style.module.css';

interface IRenameQuiz {
  selectedQuizId: string;
  onClose: () => void;
  saveQuizName: (v: string) => void;
  initialQuizName: string;
}

export const RenameQuiz = ({ onClose, initialQuizName, saveQuizName }: IRenameQuiz) => {

  const [quizName, setQuizName] = useState<string>(initialQuizName);

  const saveNewQuizName = () => saveQuizName(quizName);

  return (
    <Drawer
      visible
      onClose={onClose}
      size={'x-small'}
      title={'Название квиза'}
    >
      <div className={styles.wrapper}>
        <FormItem message="Не больше 90 символов">
          <Input
            value={quizName}
            onChangeValue={setQuizName}
            maxLength={90}
            block
          />
        </FormItem>
      </div>
      <div className={styles.wrapper}>
        Это название отображается только в общем списке квизов и будет видно только вам
      </div>
      <DrawerFooter
        className={styles.footer}
        actions={(
          <>
            <Button onClick={saveNewQuizName}>Сохранить</Button>
            <Button
              type="text"
              onClick={onClose}
              style={{ marginLeft: 16 }}
            >
              Отмена
            </Button>
          </>
        )}
      />
    </Drawer>
  );
};
