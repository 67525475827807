import React from 'react';
import Icon from '~/components/Icon';
import Tooltip from '~/components/ui/Tooltip';
import './style.css';

const hintsForTheTable = (column: string) => {

  return (
    <div className="scenarios-list-question-description">
        <span className="scenarios-list-question-title">{column}</span>
        <Tooltip
          placement="topLeft"
          title={(
            <div className="scenarios-list__tooltip-text-wrapper">
              {column === 'Показы' && (
                  <>
                    <span>Показы — Общий % показов виджета с момента запуска.</span><br />
                    <p>
                      Рассчитывается как отношение показов виджета к общему количеству посещений сайта.
                      При этом 1 посетитель может провести несколько сессий на сайте.
                    </p>
                  </>
                )
              }
              {column === 'Взаимодействия' && (
                <>
                  <span>Взаимодействия — Общий % взаимодействия, конверсия.</span><br />
                  <p>Рассчитывается как отношение клика по кнопке на виджете к общему количеству показов.</p>
                </>
                )
              }
            </div>
          )}
        >
          <div><Icon name="Question" className="scenarios-list__hint-icon" /></div>
        </Tooltip>
      </div>
  );
};

export default hintsForTheTable;