import React from 'react';
import './style.css';

const AdditionalHints: React.FC = () => {
  return (
    <div className="screens-auth-setpassword-additional-hints__text">
      Пароль должен содержать
      <br /> <br />
       - Минимум 12 символов
      <br />
      - Заглавную букву
      <br />
      - Цифру
    </div>
  );
};

export default AdditionalHints;
