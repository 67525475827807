import { observer } from 'mobx-react';
import React from 'react';
import ButtonActionFeed from '~/components/generic/FeedStatisticTable/columns/StatusCell/ButtonActionFeed';
import styles from './style.module.css';

interface IActionButtonProps {
  feedStats: FeedProStatistics | FeedApiStatistics | FeedStatistics;
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics | FeedStatistics
  ) => void;
  setIsInfoDrawerOpen: (isOpen: boolean) => void;
}

export const ActionButton: React.FC<IActionButtonProps> = observer(
  ({ feedStats, onClickActionButton, setIsInfoDrawerOpen }) => {

    let icon: string;
    let title: string;
    let onAction: () => void;

    if (feedStats?.status === 'WORK') {
      title = 'Остановить';
      icon = 'PauseS';
      onAction = () => {
        onClickActionButton('stop', feedStats);
        setIsInfoDrawerOpen(false);
      };
    }

    if (feedStats?.status === 'STOP') {
      title = 'Запустить';
      icon = 'PlayS';
      onAction = () => {
        onClickActionButton('start', feedStats);
        setIsInfoDrawerOpen(false);
      };
    }

    return (
      (feedStats?.status === 'WORK' || feedStats?.status === 'STOP') && (
        <div className={styles.wrapper}>
          <ButtonActionFeed
            icon={icon}
            onAction={onAction}
            name={title}
          />
          <div className={styles.desc}>
            {feedStats?.status === 'WORK' && 'Сейчас Лента запущена'}
            {feedStats?.status === 'STOP' && 'Сейчас Лента не запущена'}
          </div>
        </div>
      )
    );
  }
);
