import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CounterCode } from '~/api';
import Icon from '~/components/Icon';
import { Video } from '~/components/generic/Video';
import Drawer from '~/components/ui/Drawer';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { CODE_TO_COPY_FIELD_TEXT } from '~/constants';
import { CMSConstructor } from '~/screens/SettingsScreen/Connection/CmsСonstructor';
import { GuideGTM } from '~/screens/SettingsScreen/Connection/GuideGTM';
import { GuideHTML } from '~/screens/SettingsScreen/Connection/GuideHTML';
import { GuideForHimselfSettings } from '~/screens/SettingsScreen/Connection/GuideScreenForHimselfSettings';
import { InstructionsToSpecialist } from '~/screens/SettingsScreen/Connection/InstructionsToSpecialist';
import SettingsStatusBar from '~/screens/SettingsScreen/Connection/SettingsStatusBar';
import { SiteUrlEditor } from '~/screens/SettingsScreen/Connection/SiteUrlEditor';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { urlTruncateProtocol } from '~/utils/urlTruncateProtocol';
import useStores from '~/utils/useStores';
import './style.css';

export const Connection: React.FC = observer(() => {
  const { settingsStore } = useStores() as { settingsStore: SettingsStore };
  const { authStore } = useStores() as { authStore: AuthStore };

  const [isScriptInstalled, setScriptInstalled] = useState<boolean>(
    authStore.clientState === 'READY'
  );

  const checkScriptInClient = async () => {
    try {
      await authStore.checkCode();
      setScriptInstalled(authStore.isInstalledSuccessfully);
    } catch (e) {
      setScriptInstalled(false);
    }
  };

  const [clientCode, setClientCode] = useState('');
  const [copyLink, setCopyLink] = useState('');

  useEffect(() => {
    checkScriptInClient();
    (async () => {

      try {
        const response = await CounterCode.codeResponse();
        setCopyLink(`${CODE_TO_COPY_FIELD_TEXT}?analyticsId=${response.data.payload[0].analyticsId}`);
        setClientCode(response.data.payload[0].counter);
      } catch (e) {
        throw new Error(e);
      }
    })();
  }, []);

  const handleClick = (index: number) => {
    settingsStore.setDrawerComponent(index);
    settingsStore.setToggleDrawer(true);
  };

  const onCloseDrawer = () => {
    settingsStore.setToggleDrawer(false);
  };

  const onCheckAndCloseDrawer = async () => {
    await checkScriptInClient();
    onCloseDrawer();
  };

  const draweSize = settingsStore.drawerComponent === 0 ? 'x-small' : 'small';
  const filledDomain = urlTruncateProtocol(authStore.currentOrg?.domain[0]);

  const components = [
    {
      title: 'Редактирование адреса сайта',
      component: <SiteUrlEditor checkCode={checkScriptInClient} />,
    },
    {
      title: 'Конструкторы сайтов и CMS',
      component: <CMSConstructor onCloseDrawer={onCheckAndCloseDrawer} clientCode={clientCode} />,
    },
    {
      title: 'HTML',
      component: <GuideHTML onCloseDrawer={onCheckAndCloseDrawer} clientCode={clientCode} />,
    },
    {
      title: 'Google Tag Manager',
      component: <GuideGTM onCloseDrawer={onCheckAndCloseDrawer} clientCode={clientCode} />,
    },
    {
      title: 'Отправка инструкции специалисту',
      component: <InstructionsToSpecialist onCopy={onCloseDrawer} link={copyLink} />,
    },
  ];

  return (
    <div className="connection-tab__wrapper">
      <button
        onClick={() => handleClick(0)}
        className="connection-tab__site-badge"
      >
        {filledDomain}
        <Icon name="Pencil" className="settings-status-bar__icon" />
      </button>
      <SettingsStatusBar isScriptInstalled={isScriptInstalled} />

      <div className="guide_for_himself_with_video">
        <GuideForHimselfSettings />
        <Video
          title="Как установить код?"
          content="Мы подготовили подробную видеоинструкцию, с помощью которой вы легко установите код"
          link="https://www.youtube.com/embed/zR7Hlsz-nNE"
          className="guide_for_himself_video"
          previewImage="/assets/images/videoPreview/InstallCode.png"
        />
      </div>


      <div className="connection-tab__separator">
        <span>или</span>
      </div>

      <div className="connection-tab-code-to-copy__wrapper">
        <ToCopyArea text={clientCode} />
      </div>

      <Drawer
        visible={settingsStore.toggleDrawer}
        title={components[settingsStore.drawerComponent]?.title}
        onClose={onCloseDrawer}
        size={draweSize}
        className="connection-tab__drawer"
      >
        <div className="_wrapper">
          {components[settingsStore.drawerComponent]?.component}
        </div>
      </Drawer>
    </div>
  );
});
