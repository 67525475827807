import ButtonEditElement from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/settingsElements/ButtonEditElement';
import ImageEditElement from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/settingsElements/ImageEditElement';
import LetterEditElement from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/settingsElements/LetterEditElement';
import MailingPlug from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/settingsElements/MailingPlug';
import TextEditElement from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/settingsElements/TextEditElement';

export const getSettingsElement = (name: TMassMailItemName) => {
  return {
    letter: LetterEditElement,
    logo: ImageEditElement,
    head: TextEditElement,
    subhead: TextEditElement,
    goToCart: ButtonEditElement,
    unsubscribeText: TextEditElement,
    unsubscribeLink: MailingPlug,
  }[name];
};
