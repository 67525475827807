import React from 'react';
import Checkbox from '~/components/ui/CheckBox';
import Input from '~/components/ui/Input';
import RadioButton from '~/components/ui/RadioButton';
import { IStepEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/EditDrawer';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { Info } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/Info';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import localStyles from './style.module.css';

export const FinalEditor = ({ step, onDelete }: IStepEditor) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample } = quizStore;

  const { getValue, setValue } = quizExample;

  const hasBonuses = getValue(step, 'bonuses')?.length > 0;
  const hasPromoCode = !(typeof getValue(step, 'promoCode') === 'undefined');

  const switchBonuses = () => {

    if (!hasBonuses) {
      setValue(step, 'bonuses', getValue(0, 'bonuses'));

      return;
    }

    setValue(step, 'bonuses', undefined);
  };

  return (
    <>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingBody}>
          <Info
            text={
              'Не забудьте поблагодарить клиента за прохождение квиза, и по необходимости подарите промокод на бонусы '
            }
          />
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст заголовка</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'title')}
            placeholder={'Введите заголовок'}
            onChangeValue={(v: string) => setValue(step, 'title', v)}
            maxLength={120}
          />
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст описания</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'description')}
            placeholder={'Введите описание'}
            onChangeValue={(v: string) => setValue(step, 'description', v)}
          />
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>
          Какой результат получит посетитель?
        </div>
        <div className={styles.changeSettingsSubtitle}>
          Добавьте до 2х бонусов в подарок
        </div>

        <div className={styles.changeSettingBody}>
          <div
            className={localStyles.chooseField}
            onClick={(e) => {
              e.preventDefault();
              setValue(step, 'promoCode', '');
            }}
          >
            <RadioButton
              checked={hasPromoCode}
              label={(
                <div className={localStyles.labelWrap}>
                  <div>Промокод</div>
                  <div className={localStyles.label}>
                    Укажите код для скидки, подарка, который можно ввести на
                    вашем сайте
                  </div>
                </div>
                )}
              onCheck={() => { return; }}
            />
          </div>
          {hasPromoCode && (
            <>
              <div className={styles.changeSettingRow}>
                <Input
                  className={styles.settingsInput}
                  value={getValue(step, 'promoCode')}
                  placeholder={'Введите промокод'}
                  onChangeValue={(v: string) => setValue(step, 'promoCode', v)}
                  maxLength={12}
                />
              </div>
              <div className={styles.changeSettingRow}>
                <div className={styles.changeSettingTitle}>Текст кнопки</div>
                <Input
                  className={styles.settingsInput}
                  value={getValue(step, 'buttonText')}
                  placeholder={'Текст кнопки'}
                  onChangeValue={(v: string) => setValue(step, 'buttonText', v)}
                  maxLength={30}
                />
              </div>
            </>
          )}
          <div
            className={localStyles.chooseField}
            onClick={(e) => {
              e.preventDefault();
              setValue(step, 'promoCode', undefined);
            }}
          >
            <RadioButton
              checked={!hasPromoCode}
              label={(
                <div className={localStyles.labelWrap}>
                  <div>Ничего</div>
                  <div className={localStyles.label}>
                    В таком случае, не забудьте поблагодарить за прохождение в
                    заголовке
                  </div>
                </div>
                )}
              onCheck={() => { return; }}
            />
          </div>
        </div>
        <div
          className={localStyles.chooseField}
          onClick={(e) => {
            e.preventDefault();
            switchBonuses();
          }}
        >
          <Checkbox
            checked={hasBonuses}
            label={'Показать бонусы'}
            onCheck={() => { return; }}
          />
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.settingsControls} onClick={onDelete}>
          Удалить шаг
        </div>
      </div>
    </>
  );
};
