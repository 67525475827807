export const isValidUrl = (url: string) => {
  try {
    // tslint:disable-next-line:no-unused-expression
    new URL(url);
  } catch (e) {
    
    return false;
  }

  return true;
};
