import { IPromoMaterials } from '~/screens/ScenariosScreen/EmptyScreen/types';

export const widgetItems = [
  {
    iconSrc: 'DemoWidgets',
    text: 'Увеличить средний чек',
  },
  {
    iconSrc: 'DemoWidgets',
    text: 'Привлечь внимание к акциям, скидкам, бонусам',
  },
  {
    iconSrc: 'DemoWidgets',
    text: 'Продвигать нужные товары или услуги',
  },
  {
    iconSrc: 'DemoWidgets',
    text: 'Направить пользователей в новый раздел',
  },
];

export const leadsItems = [
  {
    iconSrc: 'DemoLead',
    text: 'Собирать контакты клиентов с помощью всплывающих поп-ап окон',
  },
  {
    iconSrc: 'DemoLead',
    text: 'Работать с заявками в СберЛид',
  },
  {
    iconSrc: 'DemoLead',
    text: 'Отправлять заявки напрямую в Битрикс, SberCRM или Телеграм',
  },
  {
    iconSrc: 'DemoLead',
    text: 'Записывать клиентов на консультации или обслуживание',
  },
];

export const promoMaterials: IPromoMaterials = {
  widgets: {
    header: 'Добро пожаловать в конструктор виджетов',
    subheader:
      'Виджеты — это всплывающие поп-ап окна, которые помогут решить различные бизнес-задачи:',
    img: '/assets/images/promo/widgets.png',
    items: widgetItems,
    text1:
      'Виджеты запускаются на сайте в несколько кликов, без помощи программистов и поддерживаются на компьютерах и мобильных устройствах.',
    text2: 'Доступны готовые шаблоны по наиболее популярным темам',
  },
  leads: {
    header: 'Добро пожаловать в конструктор сбора заявок',
    subheader:
      'Инструмент «Лиды» позволит собирать контакты с сайта и решить различные бизнес-задачи:',
    img: '/assets/images/promo/leads.png',
    items: leadsItems,
    text1:
      'Инструмент запускается на сайте в несколько кликов, без помощи программистов и поддерживаются на компьютерах и мобильных устройствах.',
    text2: undefined,
  },
};
