const colors: Record<string, string> = {
  'API Error': '#ff3b55',
  'Warning': '#f2dd10',
  'API': '#51a5ff',
  'Router': '#4bb080',
  'Timer': '#888',
  'Common': '#ddd',
};

const getTime = (): string => {
  return typeof window === 'undefined'
    ? new Date().toISOString()
    : (performance.now() / 1000).toFixed(3)
  ;
};

export class Logger {
  static LOGS_COUNT_LIMIT = 100;
  static COLORS_ENABLED = true;

  static log(timestamp: string, channel: string, ...messages: any[]) {
    let fn: keyof Console = 'log';

    if (channel === 'Warning')
      fn = 'warn';

    if (channel === 'Error')
      fn = 'error';

    if (Logger.COLORS_ENABLED) {
      console[fn](
        `%c${timestamp} %c[${channel}]`,
        `color: #888`,
        `color: ${colors[channel]}`,
        ...messages
      );
    } else {
      console[fn](
        `${timestamp} [${channel}]`,
        ...messages
      );
    }
  }

  logs: any[] = [];
  level: number;
  channels: Record<string, number> = {};
  timers: Record<string, number> = {};

  warn: (...messages: any[]) => void;

  constructor(level = 0) {
    this.level = level;
    this.warn = this.register('Warning', 10);
  }

  register(channel: string, level: number = 0) {
    this.channels[channel] = level;

    return (...messages: any[]) => this.log(channel, ...messages);
  }

  addLogToLogs(timestamp: string, channel: string, ...messages: any[]) {
    this.logs.push({
      timestamp,
      channel,
      messages,
    });

    if (this.logs.length > Logger.LOGS_COUNT_LIMIT) {
      this.logs.shift();
    }
  }

  log(channel: string, ...messages: any[]) {
    const channelLevel = this.channels[channel];
    if (channelLevel === undefined) {
      this.warn(`Channel ${channel} is not registered`);
    }

    const timestamp = getTime();

    if (channelLevel >= this.level) {
      Logger.log(timestamp, channel, ...messages);
    }

    this.addLogToLogs(timestamp, channel, ...messages);
  }

  getFullLog() {
    return JSON.stringify(this.logs);
  }

  getTextLog(...channel: string[]) {
    let text = '';

    this.logs.forEach((log) => {
      const messages = log.messages.map((message: object) => {
        if (typeof message === 'number' || typeof message === 'string') return message;
        if (typeof message === 'object') {
          const json = JSON.stringify(message);

          return json.length > 500 ? json.slice(0, 500) + '...' : json;
        }

        return '';
      });

      if (!channel.length || (channel && channel.includes(log.channel))) {
        text += `${log.timestamp} [${log.channel}] ${messages.join(' ')}\n`;
      }
    });

    return text;
  }

  exec(text: string) {
    const logs: {
      timestamp: string;
      channel: string;
      messages: any[];
    }[] = JSON.parse(text);

    logs.forEach(({ timestamp, channel, messages }) => {
      Logger.log(timestamp, channel, messages);
    });
  }

  showLog(...channel: string[]) {
    this.logs.forEach((log) => {
      if (!channel.length || (channel && channel.includes(log.channel))) {
        Logger.log(log.timestamp, log.channel, ...log.messages);
      }
    });
  }

  showTextLog(...channel: string[]) {
    const text = this.getTextLog(...channel);

    console.log(text);
  }

  time(label: string) {
    this.timers[label] = performance.now();
  }

  timeEnd(label: string) {
    if (Logger.COLORS_ENABLED) {
      console.log(
        `%c————— %c${(performance.now() - this.timers[label]).toFixed(0)}ms%c —— %c${label}`,
        `color: ${colors.Timer};`,
        `color: #bbb;`,
        `color: ${colors.Timer};`,
        `color: #bbb;`
      );
    } else {
      console.log(`————— ${(performance.now() - this.timers[label]).toFixed(0)}ms —— ${label}`);
    }

    delete this.timers[label];
  }

  get size() {
    return JSON.stringify(this.logs).length;
  }
}

const logger = new Logger();

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.logger = logger;
}

export const log = logger.log;
export const warn = logger.warn;

export default logger;
