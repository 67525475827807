import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import DateRangePicker from '~/components/ui/DateRangePicker';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import styles from '../style.module.css';

const SendStartTime: React.FC = observer(() => {
  const { mailingInstance } = massMailingStore;
  const [currentDate, setCurrentDate] = useState<string>(
    mailingInstance?.get('authorizationEmail')?.startSendDateTime
  );

  const onChangeDate = (date: moment.Moment) => {
    const validDate = date < moment().add(15, 'm') ? moment().add(15, 'm') : date;
    mailingInstance.update({
      authorizationEmail: {
        ...mailingInstance?.get('authorizationEmail'),
        startSendDateTime: validDate.toISOString(true),
      },
    });
  };

  useEffect(() => {
    setCurrentDate(mailingInstance?.get('authorizationEmail')?.startSendDateTime);

    return;
  }, [mailingInstance?.get('authorizationEmail')?.startSendDateTime]);

  return (
    <div className={styles.tabSendSection}>
      <div className={styles.title_block}>
        <h3>Задайте дату и время начала рассылки</h3>
        <IconWithTooltip
          iconProps={{ name: 'Question' }}
          tooltipProps={{
            title:
              'Если не задать дату и время отправки, рассылка начнется сразу после ее создания',
            placement: 'bottomLeft',
          }}
        />
      </div>
      <p>Время Московское (GMT+3)</p>
      <DateRangePicker
        value={moment(currentDate)}
        onChange={onChangeDate}
        showTime
        singleDate
        noPast
      />
    </div>
  );
});

export default SendStartTime;
