import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentCardTitleCssParam = (
  configuration: IFeedConfiguration
): string => {
  const {
    cardTitleFontFamily,
    cardTitleColor,
    cardTitleOpacity,
    cardTitleFontWeight,
    cardTitleFontSize,
    cardTitleFontItalic,
    cardTitleTextUnderline,
    cardTitleTextLineThrough,
  } = configuration;

  return `
    font-family: ${cardTitleFontFamily};
    color: ${hexToRgb(cardTitleColor, cardTitleOpacity)};
    font-weight: ${fontWeightValues[cardTitleFontWeight]};
    font-size: ${cardTitleFontSize}px;
    font-style: ${cardTitleFontItalic ? 'italic' : 'normal'};
    text-decoration: ${getTextDecoration(
      cardTitleTextUnderline,
      cardTitleTextLineThrough
    )};
  `
    .replace(/(\r\n|\n|\r|↵)/gm, '')
    .trim();
};
