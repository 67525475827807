import { action, observable, IObservableArray } from 'mobx';
import { Roles } from '~/api';
import Rights from '~/api/rights';
import RightModel from '~/models/RightModel';
import RoleModel from '~/models/RoleModel';
import Store from '~/stores/store';

export class RolesStore extends Store {
  @observable roles: IObservableArray<RoleModel> = [] as IObservableArray<RoleModel>;
  @observable rights: IObservableArray<RightModel> = [] as IObservableArray<RightModel>;
  @observable isRolesLoading = false;
  @observable isRoleDeleting = false;
  @observable isRightsLoading = false;

  getRoleById = (id: number) => {
    return this.roles.find(r => r.get('id') === id);
  }

  getRightById = (id: number) => {
    return this.rights.find(r => r.get('id') === id);
  }

  getRightsByIds = (ids: number[]) => {
    return this.rights.filter(r => ids.includes(r.get('id')));
  }

  @action
  fetchRoles = async () => {
    this.isRolesLoading = true;

    try {
      const response = await Roles.list();
      const roles = response.data.payload.map(r => new RoleModel(this, r));
      this.roles.replace(roles);
    } catch (e) {
      throw e;
    } finally {
      this.isRolesLoading = false;
    }
  }

  @action
  fetchRights = async () => {
    this.isRightsLoading = true;

    try {
      const response = await Rights.list();
      const rights = response.data.payload.map(r => new RightModel(this, r));
      this.rights.replace(rights);
    } catch (e) {
      throw e;
    } finally {
      this.isRightsLoading = false;
    }
  }

  @action
  deleteRole = async (id: number) => {
    const role = this.roles.find(r => r.get('id') === id);

    this.isRoleDeleting = true;

    try {
      role.update({ state: 'INACTIVE' });
      await Roles.update(role.get('id'), role.copy());
      this.roles.remove(role);
    } catch (e) {
      throw e;
    } finally {
      this.isRoleDeleting = false;
    }
  }
}

export default new RolesStore();
