import moment from 'moment';
import { DATE_FORMAT } from '~/constants';

const getDetailByDates = (dateFrom: string, dateTo: string): ReportDetails => {
  const from = moment(dateFrom, DATE_FORMAT);
  const to = moment(dateTo, DATE_FORMAT);
  const diffDays = to.diff(from, 'days');

  if (diffDays < 1) {
    return 'HOURLY';
  }

  if (diffDays <= 31) {
    return 'DAILY';
  }

  if (diffDays > 31) {
    return 'MONTHLY';
  }

  return 'DAILY';
};

export default getDetailByDates;
