import { action, computed, observable } from 'mobx';
import { Quiz } from '~/api';
import QuizModel from '~/screens/QuizScreen/stores/QuizModel';
import { IQuiz } from '~/screens/QuizScreen/types';
import Store from '~/stores/store';
import { getError } from '~/utils/getError';
import Image from '../../../api/image';

export enum ESampleType {
  quiz = 'QUIZ',
  survey = 'SURVEY',
  custom = 'CUSTOM',
}

export type TQuizConstructorStep = 1 | 2 | 3 | 4;

export type TQuizStatus = 'STARTED' | 'STOPPED' | 'ERROR';

export interface IStatistic {
  name: string;
  percent: number;
  count: number;
  hint?: string;
}

// TODO typings
interface IQuizItemTemplate {
  id: string;
  errors: any[];
  name: string;
  body: {
    steps: any[];
    configuration: any;
  };
}

export interface IQuizItem {
  quizId: string;
  creationDate: string;
  status: TQuizStatus;
  quizName: string;
  quizConfiguration: IQuiz;
  steps: any[];
  configuration: any;
  statistics: IStatistic[];

  template: IQuizItemTemplate;
  statistic: any;
}

export interface IAnswer {
  text: string;
  count?: string;
  percent?: number;
  isRight?: boolean;
  checked?: boolean;
  id: string;
}

export interface IQuestion {
  id: string;
  text: string;
  hasNoAnswers?: boolean;
  answerStatistics: IAnswer[];
  answers?: IAnswer[];
}

interface ICommonStatistic {
  text: string;
  percent: number;
  count: number;
  hint?: string;
}

interface IQuestionStatistic {
  id: string;
  text: string;
  answerStatistics: IAnswer[];
}

// interface IStatisticByContacts {
//   id: string;
//   date: string;
//   time: string;
//   email: string;
//   name: string;
//   answersCount: number;
//   questions: IQuestion[];
// }

interface IDetailStatistic {
  common: ICommonStatistic[];
  questionsStatistic: IQuestionStatistic[];
  contactsStatistic: any; // IStatisticByContacts[];
}

interface IQuizLead {
  dateTime: string;
  quizName: string;
  email: string;
  uuid: string;
  quizId: string;
}

export interface IQuizTemplate extends IQuizItem {
  quizMetaId: string;
  type: ESampleType;
  labels: string[];
  templateId: string;
  head: {
    image: string;
    caption: string;
    description: string;
  };
  body: {
    steps: any[];
  };
}

export class QuizStore extends Store {
  @observable quizConstructorStep: TQuizConstructorStep = 1;
  @observable sampleType: ESampleType;
  @observable isConstructorOpen: boolean = false;
  @observable quizList: IQuizItem[] = [];
  @observable quizLeads: IQuizLead[] = [];
  @observable quizTemplates: IQuizTemplate[] = [];
  @observable isListLoading: boolean = false;
  @observable isTemplatesLoading: boolean = false;
  @observable isStatisticLoading: boolean = false;
  @observable isLeadsLoading: boolean = false;
  @observable isSaving: boolean = false;
  @observable selectedId: string;
  @observable detailStatistic: IDetailStatistic;
  @observable quizExample: QuizModel;
  @observable templateData: any;
  @observable error: string;
  @observable currentStep: number;
  @observable paginator: any;

  constructor() {
    super();
  }

  resetOnClose = () => {
    this.setIsConstructorOpen(false);
    this.setCurrentStep(undefined);
    this.setTemplateData(undefined);
    this.setQuizConstructorStep(1);
    this.setQuizExample(undefined);
    this.setSampleType(undefined);
    this.setSelectedId(undefined);
  }

  setIsSaving = (isSaving: boolean) => {
    this.isSaving = isSaving;
  }

  setQuizExample = (quizExample: QuizModel) => {
    this.quizExample = quizExample;
  }

  setTemplateData = (templateData: any) => {
    this.templateData = templateData;
  }

  setCurrentStep = (currentStep: number) => {
    this.currentStep = currentStep;
  }

  @action
  processImage = async (imageBase64: string, errorCallback: Function) => {
    try {
      const result = await Image.set(imageBase64);
      const payload = result?.data?.payload;

      if (payload?.length > 0) {
        return payload[0];
      }

      errorCallback({
        isShown: true,
        title: 'Ошибка загрузки изображения',
        text: 'Неизвестная ошибка',
      });

      return false;
    } catch (e) {
      const error = getError({ error: e });

      errorCallback({
        isShown: true,
        title: 'Ошибка загрузки изображения',
        text: error,
      });

      return false;
    }
  }

  @action
  setQuizList = async () => {
    try {
      const result = await Quiz.fetchQuizList();
      // @ts-ignore
      this.quizList = result.data.payload.sort((a, b) => {
        // TODO typings
        return b?.template?.id - a?.template.id;
      });
    } catch (e) {
      console.log(e);
    }
  }

  @action
  fetchQuizList = async () => {
    this.isListLoading = true;
    try {
      await this.setQuizList();
    } catch (e) {
      console.log(e);
    } finally {
      this.isListLoading = false;
    }
  }

  @action
  fetchQuizTemplates = async () => {
    this.isTemplatesLoading = true;

    try {
      const result = await Quiz.fetchQuizTemplates();

      this.quizTemplates = result.data.payload;
    } catch (e) {
      console.log(e);
    } finally {
      this.isTemplatesLoading = false;
    }
  }

  @action
  fetchQuizLeads = async ({ quizId, paginator: { pageNum, pageSize } }: {
    quizId?: string;
    paginator: { pageNum: number; pageSize: number };
  }) => {
    this.isLeadsLoading = true;
    try {
      const result = await Quiz.fetchQuizLeads({
        quizId,
        paginator: { pageNum, pageSize },
      });

      const quizLeadsObj = result.data.payload[0];
      this.quizLeads = quizLeadsObj.items;


      this.paginator = {
        current: quizLeadsObj.pageNum,
        pageSize: quizLeadsObj.pageSize,
        totalPages: quizLeadsObj.totalPages,
        total: quizLeadsObj.totalItems,
      };

    } catch (e) {
      console.log(e);
    } finally {
      this.isLeadsLoading = false;
    }
  }

  @action
  updateStatus = async (quizId: string, status: string) => {
    try {
      // @ts-ignore
      await Quiz.updateQuiz({ quizId, action: status }); // TODO typings
      await this.setQuizList();

      return true;
    } catch (error) {
      const errorShown = getError({ error });
      console.log({ error, errorShown });
      this.setError(errorShown as string);

      return false;
    }
  }

  @action
  setError = (error: string) => {
    this.error = error;
  }

  @action
  updateQuizName = async (quizId: string, quizName: string) => {
    const quizObject = this.quizList.filter((v, k) => {
      return v.template.id === quizId;
    })[0];

    const quizTemplate = quizObject.template;

    const quizData = {
      ...quizTemplate,
      'body': { ...quizObject.template.body },
      quizName,
      'id': quizId,
      '@type': 'QuizTemplate',
    };

    try {
      await Quiz.updateQuiz({
        quizId,
        quizObject: quizData,
        action: 'save',
      });
      await this.fetchQuizList();

      return true;
    } catch (error) {
      const errorShown = getError({ error });
      console.log({ error, errorShown });
      this.setError(errorShown as string);

      return false;
    }
  }

  save = async (action: string) => {

    try {
      const quizObject = {
        ...this.templateData,
        'body': this.quizExample.copy(),
        'quizName': this.templateData.name || 'Новый квиз',
        '@type': 'QuizTemplate',
      };

      // @ts-ignore TODO typings
      await Quiz.updateQuiz({ quizObject, action });

      await this.fetchQuizList();

      return true;
    } catch (error) {
      const errorShown = getError({ error });

      this.setError(errorShown as string);

      return false;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  deleteQuizById = async (quizId: string) => {
    try {
      await Quiz.deleteQuiz({ quizId });
      await this.fetchQuizList();

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  copyQuizById = async (quizId: string) => {
    try {
      await Quiz.updateQuiz({ quizId, action: 'clone' });
      await this.fetchQuizList();

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  fetchStatisticById = async (quizId: string) => {
    try {
      this.isStatisticLoading = true;
      const commonStatisticResult = await Quiz.fetchQuizList();
      const questionsStatisticResult = await Quiz.fetchStatistic({ quizId });
      const contacts = await Quiz.fetchQuizLeads({ quizId, paginator: { pageNum: 1, pageSize: 1000 } });

      const contactsStatistic = contacts.data.payload[0].items;

      const quizExample = commonStatisticResult.data.payload.filter(
        (v: { template: { id: number } }) =>
          v.template.id === parseInt(quizId, 10)
      )[0];

      const common = quizExample?.statistic || {};

      const questions = quizExample?.template?.body.steps.filter(
        (v: { type: string }) => v.type === 'question'
      ) || [];

      const questionsStatistic =
        questionsStatisticResult.data.payload[0].versionStatistics
          .filter((v: { endDateTime: any }) => !v.endDateTime)[0]
          .questionsStatistic.map(
            (v: { answerStatistics: any[] }, k: string | number) => {
              return {
                ...v,
                answerStatistics: v.answerStatistics.map(
                  (answer: any, answerK: string | number) => {
                    return {
                      ...answer,
                      isRight: questions[k]?.answers[answerK].isRight,
                    };
                  }
                ),
              };
            }
          );

      this.detailStatistic = {
        common,
        questionsStatistic,
        contactsStatistic,
      };

    } catch (e) {
      console.log(e);
    } finally {
      this.isStatisticLoading = false;
    }
  }

  @action
  setIsConstructorOpen = (isConstructorOpen: boolean) => {
    this.isConstructorOpen = isConstructorOpen;
  }

  getDetailStatistic = () => {
    return this.detailStatistic;
  }

  @action
  setSampleType = (type: ESampleType) => {
    this.sampleType = type;
  }

  @action
  setQuizConstructorStep = (step: TQuizConstructorStep) => {
    this.quizConstructorStep = step;
  }

  @action
  setSelectedId = (selectedId: string) => {
    this.selectedId = selectedId;
  }

  getSampleLabels = () => {
    let result: string[] = ['Все шаблоны'];

    // @ts-ignore
    this.getSampleList()
      .filter((v) => {
        return !(v.hasCustomType || v.hasCustomAction);
      })
      .map((v: any) => {
        if (result.length > 0) {
          result = [...result, ...v.labels];

          return;
        }

        result = v.labels;
      });

    return Array.from(new Set(result));
  }

  getSampleList = (): {
    templateId: string;
    image: string;
    name: string;
    labels: string[];
    hasCustomType?: boolean;
    hasCustomAction?: boolean;
  }[] => {
    const quizTemplates =
      this.quizTemplates
        .filter((v) => {
          if (v.type === 'CUSTOM') return true;

          return v.type === this.sampleType;
        })
        .sort((v: IQuizTemplate) => (v.type === 'CUSTOM' ? -1 : 1))
        .map((v) => {
          return {
            templateId: v.quizMetaId,
            image:
              v.type === 'CUSTOM'
                ? '/assets/images/quiz/quizSamples/newSample.png'
                : v.head.image,
            name: v.head.caption,
            labels:
              v.type === 'CUSTOM'
                ? ['Начните создание с пустого листа']
                : v.labels,
            hasCustomType: v.type === 'CUSTOM',
            hasCustomAction: false,
          };
        }) || [];

    quizTemplates.push({
      templateId: '',
      image: '/assets/images/quiz/quizSamples/custom.png',
      name: 'Квиз на заказ',
      labels: ['Мы все сделаем за вас!'],
      hasCustomType: false,
      hasCustomAction: true,
    });

    return quizTemplates;
  }

  @computed
  get isRequiredStepsCreated() {
    const createdSteps = [...new Set(
      this.quizExample?.get('steps').map(step => step.type)
    ).values()];
    
    return createdSteps.includes('cover')
      && createdSteps.includes('question')
      && createdSteps.includes('final');
  }
}

export default new QuizStore();
