import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import {
  loginUrl,
  mainLoginUrl,
  mainRegisterUrl,
  mobileScreenWasShownConst,
  registerUrl, yandexMetrikaId
} from '~/constants';
import Screen from '~/screens/Screen';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import { AuthStore } from '~/stores/authStore';
import { checkPathname } from '~/utils/checkPathname';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import { saveToLocaleStorage } from '~/utils/localStorageUtils/saveToLocaleStorage';
import { parseGetParams } from '~/utils/parseGetParams';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';
import { saveGTM } from '~/utils/utm/saveGTM';
import './style.css';

const clearMobileScreenWasShown = () => {
  localStorage.removeItem(mobileScreenWasShownConst);
  localStorage.removeItem('isSbbolMobileScreen');
};

const SwitchLoginScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const { uiSwitchStore } = useStores() as { uiSwitchStore: UiSwitchStore };
  const { handleSubmit } = useForm();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const gtmDisabled = uiSwitchStore.hasAccess('GtmDisabled');
  const sbbolParams = parseGetParams(location?.search, ['loginDCB', 'isMobile']);
  const isSbbolMobile = sbbolParams && sbbolParams.loginDCB === 'true' && sbbolParams.isMobile;

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      const googleScript = document.createElement('script');

      if (!gtmDisabled) {
        googleScript.setAttribute('src', '/assets/files/js/googleMetrika.js');
      }

      document.head.appendChild(googleScript);
    }
  });

  useEffect(() => {
    isSbbolMobile && clearMobileScreenWasShown();

    if (authStore.isAuthorized) {
      history.push(
        firstPageAfterAuthorization(
          authStore.clientState,
          authStore.currentUser.rightsModel
        )
      );
    } else {
      saveGTM();
      clearMobileScreenWasShown();
    }
  }, [authStore.isAuthorized]);

  const onSubmit = (data: { login?: string; password?: string }) => {
    authStore.login(data.login, data.password);
  };

  const loginWithSberbankBusinessID = async (
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e?.preventDefault();
    try {
      // @ts-ignore
      await ym(yandexMetrikaId, 'reachGoal', 'lksbi');
      await authStore.getFintechRedirectData();
    } catch (e) {
      throw e;
    }
  };

  const redirectWithSberbankBusinessID = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    try {
      // @ts-ignore
      await await ym(yandexMetrikaId, 'reachGoal', 'lksbi');
      window.location.href = 'https://sberclickstream.ru/register';
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    const sbbolParams = parseGetParams(location?.search, [
      'loginDCB',
      'isMobile',
    ]);

    if (sbbolParams && sbbolParams.loginDCB === 'true') {
      if (sbbolParams.isMobile) {
        saveToLocaleStorage('isSbbolMobileScreen', 'true');
      }
      loginWithSberbankBusinessID();
    }
  }, []);

  const isRegistrationScreen = checkPathname('/register');
  const link = isRegistrationScreen ? registerUrl : loginUrl;
  const header = isRegistrationScreen ? 'Регистрация' : 'Вход в личный кабинет';
  const text = 'По номеру телефона';
  const authLinkDescription = isRegistrationScreen
    ? 'Есть учетная запись?'
    : 'Нет учетной записи?';
  const authLinkUrl = isRegistrationScreen ? mainLoginUrl : mainRegisterUrl;
  const authLinkText = isRegistrationScreen ? 'Войдите' : 'Зарегистрируйтесь';

  const hasAccessLoginWithSberbankBusinessID = uiSwitchStore.hasAccess(
    'LoginWithSberbankBusinessID'
  );

  return (
    <Screen className="login-screen">
      {!gtmDisabled && (
        <>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-TQX7VQ"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-TLLMLP"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}
      <form className="login-screen-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-screen-form__title">{header}</div>
        <div className="login-screen-form__row">
          <Button type="main" to={link} size="large" block>
            {text}
          </Button>
        </div>
        <div className="login-screen-form__row">
          <div className="login-screen-form__separator">
            <span>или</span>
          </div>
        </div>

        {!isRegistrationScreen && (
          <>
            <Button
              type="sber"
              icon="LogoWhite"
              loading={authStore.isLoggingIn}
              onClick={loginWithSberbankBusinessID}
              block
            >
              Войти по СберБизнес ID
            </Button>
            <div className="screens-auth-switchlogin__information-label_offer">
              Продолжая, я соглашаюсь с условиями{' '}
              <a href="https://sberlead.ru/offer">оферты</a>
            </div>
          </>
        )}

        {isRegistrationScreen && (
          <>
            <div className="login-screen-form__row">
              {hasAccessLoginWithSberbankBusinessID ? (
                <Button
                  type="sber"
                  icon="LogoWhite"
                  loading={authStore.isLoggingIn}
                  onClick={loginWithSberbankBusinessID}
                  block
                >
                  Войти по СберБизнес ID
                </Button>
              ) : (
                <Button
                  type="sber"
                  icon="LogoWhite"
                  loading={authStore.isLoggingIn}
                  onClick={redirectWithSberbankBusinessID}
                  block
                >
                  Войти по СберБизнес ID
                </Button>
              )}
            </div>
            <div className="login-screen-form__row">
              <div className="login-screen-form__separator">
                <span>или</span>
              </div>
            </div>
          </>
        )}
        {isRegistrationScreen && (
          <div className="login-screen-form__row">
            <Button
              className="login-screen__button"
              type="grey"
              tag="a"
              onClick={redirectToClickform}
            >
              Подключить с менеджером
            </Button>
          </div>
        )}
        <div className="auth-screen-form__row">
          <span className="auth-screen-form__text">
            {authLinkDescription}&nbsp;
            <Link to={authLinkUrl}>
              <span className="auth-screen-form__link">{authLinkText}</span>
            </Link>
          </span>
        </div>
      </form>
    </Screen>
  );
});

export default React.memo(SwitchLoginScreen);
