import { IFeedConfiguration } from 'feed';
import React, { useEffect } from 'react';

interface IParamHandProps {
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam?: (params: string) => void;
}
const ParamHand: React.FC<IParamHandProps> = ({
  children,
  isActive,
  configuration,
  onChange,
  onChangeCssParam,
}) => {
  useEffect(() => {
    if (isActive) {
      onChangeCssParam && onChangeCssParam(null);
      onChange(configuration, true);
    }
  }, [isActive]);

  return <>{children}</>;
};

export default ParamHand;
