import { observer } from 'mobx-react';
import React from 'react';
import { Promo } from '~/components/generic/Promo';
import { promoMaterials } from '~/screens/MailingScreen/components/EmptyScreen/consts';
import { emptyScreenButtonsCreator } from '~/screens/MailingScreen/components/EmptyScreen/emptyScreenButtonsCreator';

interface IEmptyScreenProps {
  hasRight: boolean;
}

const EmptyScreen: React.FC<IEmptyScreenProps> = observer(({ hasRight }) => {
  const { header, img, items, subheader, text1 } = promoMaterials;

  return (
    <Promo
      actions={emptyScreenButtonsCreator(hasRight)}
      header={header}
      img={img}
      items={items}
      subheader={subheader}
      text1={text1}
    />
  );
});

export default EmptyScreen;