import { action, get, observable } from 'mobx';
import uiSwitch from '~/api/uiSwitch';
import { hasAccess } from '~/components/SwitchUI/utils';
import Store from '~/stores/store';

export enum ESwitchCodes {
  'RegisterScreen#Title' = 'RegisterScreen#Title',
  'AuthScreen#AuthForm' = 'AuthScreen#AuthForm',
  FeedScreen = 'FeedScreen',
  'WidgetsScreen#PreClosePopup' = 'WidgetsScreen#PreClosePopup',
}

export class UiSwitchStore extends Store {

  @observable private codesToHide: TSwitchCode[] = [];
  @observable private isFetching: boolean;
  @observable private error: string;

  @action
  fetchCodes = async () => {
    this.isFetching = true;

    try {
      const response = await uiSwitch.fetchSwitcher();
      this.codesToHide = response.data?.payload[0]?.data?.map((v: TSwitchCode) => v);
    } catch (e) {
      this.error = e.response?.data?.errorData?.errorText.split(':')[0];
      console.log({ error: this.error });
    } finally {
      this.isFetching = false;
    }
  }

  @get
  getIsFetching = () => {
    return this.isFetching;
  }

  @get
  getError = () => {
    return this.error;
  }

  @get
  getCodesToHide = () => {
    return this.codesToHide;
  }

  hasAccess = (elementCode: TSwitchCode) => {
    const codesToHide = this.getCodesToHide();

    return hasAccess({ codesToHide, elementCode });
  }

}

export default new UiSwitchStore();
