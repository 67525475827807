import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Profile, { TConsentToMarketing } from '~/api/profile';
import ConsentToMarketing from '~/components/generic/ConsentToMarketing';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { regexpDomain } from '~/constants';
import { AuthStore } from '~/stores/authStore';
import { getError } from '~/utils/getError';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const AddDomain = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const history = useHistory();

  const [domain, setDomain] = useState<string>(authStore?.currentOrg?.get('domains')[0] || '');
  const [consentToMarketing, setConsentToMarketing] = useState<
    TConsentToMarketing
  >({
    sber: true,
    sbs: true,
  });
  const [error, setError] = useState<string>('');

  const isButtonDisabled = !domain.length;

  const onChangeDomain = (domain: string) => {
    setError('');
    setDomain(domain);
  };

  const onDomainAdd = async () => {
    setError('');

    if (!regexpDomain.test(domain)) {
      setError('Некорректное значение поля ввода домена');

      return;
    }

    try {
      await authStore.addDomain(domain);

      if ('sbs' in consentToMarketing) {
        await Profile.setUserAgreements({
          '@type': 'UserAgreements',
          'data': {
            sber: consentToMarketing.sber,
            sbs: consentToMarketing.sbs,
          },
        });
      }

      await authStore.fetchClientState();
      await authStore.fetchOrgData();

      history.push('/settings?tab=2');
    } catch (error) {
      setError(
        getError({
          error,
          defaultError: 'Ошибка сети, попробуйте позже',
        }) as string
      );
    }
  };

  return (
    <div className={styles.addDomain}>
      <div className={styles.title}>Укажите адрес вашего сайта</div>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          value={domain}
          onChangeValue={onChangeDomain}
          failure={error.length > 0}
        />
        {error.length > 0 && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          disabled={isButtonDisabled}
          className={styles.button}
          onClick={onDomainAdd}
        >
          Попробовать бесплатно
        </Button>
      </div>
      <div className={styles.description}>
        С вами свяжется менеджер для консультации по подключенным инструментам в
        течение бесплатного периода
      </div>
      <div className={styles.description}>
        <ConsentToMarketing
          consentToMarketing={consentToMarketing}
          setConsentToMarketing={setConsentToMarketing}
        />
      </div>

    </div>
  );
});
