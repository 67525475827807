import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import BlankScreenMessage from '~/components/generic/BlankScreenMessage';
import Caption from '~/components/generic/Caption';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import BusinessRuleEditor from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor';
import BusinessRuleList from '~/screens/BusinessRulesScreen/components/BusinessRuleList';
import DialogRenameRule from '~/screens/BusinessRulesScreen/components/DialogRenameRule';
import { BusinessRuleStore } from '~/screens/BusinessRulesScreen/store/businessRuleStore';
import LoadingScreen from '~/screens/LoadingScreen';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const BusinessRulesScreen: React.FC = observer(() => {
  const { businessRuleStore } = useStores() as {
    businessRuleStore: BusinessRuleStore;
  };
  const {
    recommendationForTypeDict,
    recommendationOfWhatTypeDict,
    recommendationTypeDict,
    similarityTypeDict,
    isNotUploadedBusinessRuleList,
    fetchBusinessRuleList,
    businessRuleList,
    metadata,
    businessRule,
    isOpenBusinessRuleEditor,
    createNewBusinessRule,
    setIsOpenBusinessRuleEditor,
    editBusinessRule,
    feedForBusinessRule,
    saveBusinessRule,
    getBusinessRuleEntity,
    businessRulesByFeed,
    editBusinessRuleName,
    isOpenRuleRenameDialog,
    setIsOpenRuleRenameDialog,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    startProcessDeleteRule,
    deleteRule,
    isOpenCreationSuccessInfoDialog,
    setIsOpenCreationSuccessInfoDialog,
  } = businessRuleStore;

  useEffect(() => {
    fetchBusinessRuleList();

    return () => businessRuleStore.destroy();
  }, []);

  const createNewBusinessRuleButton = (
    <Button icon="Plus" onClick={createNewBusinessRule}>
      Создать правило
    </Button>
  );

  if (isNotUploadedBusinessRuleList) {
    return <LoadingScreen />;
  }

  const isUploadedBusinessRuleList =
    !isNotUploadedBusinessRuleList &&
    businessRuleList &&
    businessRuleList.length > 0;

  return (
    <Screen
      className={styles.screen}
      documentTitle={'Бизнес-правила'}
      header={'Бизнес-правила'}
      footer={isUploadedBusinessRuleList ? createNewBusinessRuleButton : null}
    >
      <div className={styles.block}>
        <Caption className={styles.caption}>
          Бизнес-правила помогут сделать более детальную настройку ленты
          рекомендаций под ваши нужды. Вы можете явно указать, какие товары и
          категории вы хотите рекомендовать для других товаров и категорий.
        </Caption>
        <div className={styles.content}>
          {!isUploadedBusinessRuleList && (
            <BlankScreenMessage
              title={'Пока не создано ни одного бизнес-правила.'}
              action={createNewBusinessRuleButton}
            />
          )}
          {isUploadedBusinessRuleList && (
            <BusinessRuleList
              recommendationForTypeDict={recommendationForTypeDict}
              recommendationOfWhatTypeDict={recommendationOfWhatTypeDict}
              recommendationTypeDict={recommendationTypeDict}
              similarityTypeDict={similarityTypeDict}
              businessRuleList={businessRuleList}
              editBusinessRule={editBusinessRule}
              editBusinessRuleName={editBusinessRuleName}
              startProcessDeleteRule={startProcessDeleteRule}
            />
          )}
        </div>
      </div>
      <BusinessRuleEditor
        isOpenEditor={isOpenBusinessRuleEditor}
        closeEditor={() => setIsOpenBusinessRuleEditor(false)}
        metadata={metadata}
        businessRule={businessRule}
        feedForBusinessRule={feedForBusinessRule}
        saveBusinessRule={saveBusinessRule}
        getBusinessRuleEntity={getBusinessRuleEntity}
        businessRulesByFeed={businessRulesByFeed}
        recommendationForTypeDict={recommendationForTypeDict}
        recommendationTypeDict={recommendationTypeDict}
        recommendationOfWhatTypeDict={recommendationOfWhatTypeDict}
        similarityTypeDict={similarityTypeDict}
      />
      <DialogRenameRule
        visible={isOpenRuleRenameDialog}
        closeRenameDialog={() => setIsOpenRuleRenameDialog(false)}
      />
      {isOpenDeleteDialog && (
        <Message
          visible={isOpenDeleteDialog}
          title={'Удалить бизнес-правило?'}
          applyText={'Удалить'}
          onApply={deleteRule}
          cancelText={'Отмена'}
          onClose={() => setIsOpenDeleteDialog(false)}
          onExit={() => setIsOpenDeleteDialog(false)}
          showIcon={false}
          isErrorBlock={false}
        />
      )}
      {isOpenCreationSuccessInfoDialog && (
        <Message
          visible={isOpenCreationSuccessInfoDialog}
          title={(
            <>
              <Icon name={'DoneInCircle'} style={{ marginBottom: '8px' }} />
              Бизнес-правило создано!
            </>
          )}
          textTop={'Имейте в виду, что бизнес-правило вступит в силу не сразу, а через какое-то время.'}
          applyText={'Понятно'}
          onApply={() => setIsOpenCreationSuccessInfoDialog(false)}
          onClose={() => setIsOpenCreationSuccessInfoDialog(false)}
          showIcon={false}
          isErrorBlock={false}
        />
      )}
    </Screen>
  );
});

export default BusinessRulesScreen;
