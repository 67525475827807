import React from 'react';
import SetCatalog from '~/components/generic/SetCatalog';
import BlockCategoryPro from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockCategoryPro';
import BlockCrossedPricePro from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockCrossedPricePro';
import BlockLabelsPro from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockLabelsPro';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';
import './style.css';

export interface IStepCatalogProps {
  feed: FeedProModel;
  value: string;
  errorDesc: string[];
  onChange: (text: string) => void;
  isInvalid: boolean;
  onBlur: () => void;
  configLinkError: string;
  labelMaxCountLimit: number;
  isConfigInvalid?: boolean;
  hasClickedOnInput?: boolean;
  notification?: string;
}

const StepCatalog: React.FC<IStepCatalogProps> = ({
  feed,
  value,
  errorDesc,
  onChange,
  isInvalid,
  onBlur,
  isConfigInvalid,
  hasClickedOnInput,
  configLinkError,
  labelMaxCountLimit,
}) => (
  <SetCatalog
    value={value}
    onChange={onChange}
    errorDesc={errorDesc}
    isInvalid={isInvalid || (isConfigInvalid && !hasClickedOnInput)}
    isConfigInvalid={isConfigInvalid}
    onBlur={onBlur}
    hasClickedOnInput={null}
    configLinkError={configLinkError}
    notification={
      'Рекомендации всегда будут актуальны, так как мы автоматически проверяем каталог'
    }
  >
    <div className={'feed-pro-editor-step-catalog__instruments-title'}>
      Улучшите ваши рекомендации с помощью дополнительных инструментов
    </div>
    <BlockCrossedPricePro feed={feed} />
    <BlockCategoryPro feed={feed} />
    <BlockLabelsPro feed={feed} labelMaxCountLimit={labelMaxCountLimit} />
  </SetCatalog>
);

export default StepCatalog;
