import './style.css';

import cx from 'classnames';
import React, { Key, ReactElement } from 'react';

interface RadioGridProps {
  items?: ReactElement[];
  itemsPerRow?: number;
  style?: React.CSSProperties;
  className?: string;
  onSelect?: (item: Key | null) => void;
}

const RadioGrid: React.FC<RadioGridProps> = ({ style, className, onSelect, itemsPerRow = 3, items = [] }) => {
  return (
    <div
      className={cx('radiogrid', className)}
      style={{ ...style, gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)` }} >
      {items.map(item =>
        (
          <div
            key={item.key}
            className="radiogrid__item"
            onClick={() => onSelect?.(item.key)}
          >
            {item}
          </div>
        ))
      }
    </div>
  );
};

export default RadioGrid;
