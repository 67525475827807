import React, { Component } from 'react';

interface Props {
  children: string;
}

class Title extends Component<Props> {
  static DEFAULT_TITLE = 'СберЛид';
  static DEFAULT_POSTFIX = ' - СберЛид';

  static updateTitle(title?: string) {
    document.title = title
      ? `${title}${Title.DEFAULT_POSTFIX}`
      : Title.DEFAULT_TITLE
    ;
  }

  componentDidMount() {
    Title.updateTitle(this.props.children);
  }

  componentDidUpdate() {
    Title.updateTitle(this.props.children);
  }

  componentWillUnmount() {
    Title.updateTitle();
  }

  render(): React.ReactNode {
    return null;
  }
}

export default Title;
