import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

export interface InputWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  active?: boolean;
  size?: FormElementSize;
  block?: boolean;
  compact?: boolean;
  disabled?: boolean;
  success?: boolean;
  failure?: boolean;
  withLabel?: boolean;
  withoutBottomRadius?: boolean;
  highlighted?: boolean;
  withoutPadding?: boolean;
  withInteraction?: boolean;
  withoutBorder?: boolean;
  elemBefore?: React.ReactNode;
  elemAfter?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?: React.Ref<any>;
}

const InputWrapper: React.FC<InputWrapperProps> = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    style,
    placeholder,
    active,
    size,
    block,
    compact,
    disabled,
    success,
    failure,
    withLabel,
    withoutBottomRadius,
    highlighted,
    withoutPadding,
    withInteraction,
    withoutBorder,
    elemBefore,
    elemAfter,
    onClick,
  } = props;

  const classNames = cx('input-wrapper', {
    [`_size-${size}`]: size,
    '_success': success,
    '_failure': failure,
    '_active': active,
    '_block': block,
    '_compact': compact,
    '_disabled': disabled,
    '_without-bottom-radius': withoutBottomRadius,
    '_highlighted': highlighted,
    '_without-padding': withoutPadding,
    '_without-border': withoutBorder,
    '_with-interaction': withInteraction,
    '_elem-before': elemBefore,
    '_elem-after': elemAfter || success,
  }, className);

  const renderSuccess = () => (
    <Icon name="CheckmarkM" className="input-wrapper__success-icon" />
  );

  const body = (
    <>
      <div className="input-wrapper__placeholder">
        {placeholder}
      </div>
      {elemBefore && (
        <div className="input-wrapper__before">
          {elemBefore}
        </div>
      )}
      <div className="input-wrapper__body">
        {children}
      </div>
      {(elemAfter || success) && (
        <div className="input-wrapper__after">
          {elemAfter ? elemAfter : renderSuccess()}
        </div>
      )}
    </>
  );

  if (withLabel) {
    return (
      <label className={classNames} style={style} onClick={onClick} ref={ref}>
        {body}
      </label>
    );
  }

  return (
    <div className={classNames} style={style} onClick={onClick} ref={ref}>
      {body}
    </div>
  );
});

export default InputWrapper;
