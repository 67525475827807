import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { IMaskInput } from 'react-imask';
import Icon from '~/components/Icon';
import InputWrapper, { InputWrapperProps } from '~/components/ui/InputWrapper';
import './style.css';

export type Props = InputWrapperProps & {
  autoFocus?: boolean;
  type?: 'text' | 'tel' | 'password' | 'url' | 'search' | 'number' | 'email' | 'regTel';
  name?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange?: (ev: React.FormEvent<HTMLInputElement>) => void | PromiseLike<any>;
  onKeyDown?: (ev: React.FormEvent<HTMLInputElement>) => void | PromiseLike<any>;
  onChangeValue?: (value: string) => void | PromiseLike<any>;
  onBlur?: (value: string) => void | PromiseLike<any>;
  onFocus?: () => void | PromiseLike<any>;
  onChangePhone?: (value: string) => void;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  readonly?: boolean;
  elemBefore?: React.ReactNode;
  elemAfter?: React.ReactNode;
  maxLength?: number;
  withoutBorder?: boolean;
  withEye?: boolean;
};

const Input: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    autoFocus,
    type = 'text',
    name,
    value,
    defaultValue,
    maxLength,
    className,
    style,
    onChange,
    onKeyDown,
    onChangeValue,
    onBlur,
    onFocus,
    onChangePhone,
    size = 'default',
    block,
    placeholder,
    disabled,
    readonly = false,
    failure,
    success,
    elemBefore,
    elemAfter,
    withoutBorder,
    withEye,
  } = props;

  const [active, setActive] = useState(false);
  const [withEyeType, setWithEyeType] = useState('password');
  const eyeType = withEyeType === 'text' ? 'Eye' : 'EyeCrossed';

  const handleFocus = useCallback(() => {
    onFocus?.();
    setActive(true);
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    onBlur?.(value);
    setActive(false);
  }, [onBlur]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChange && onChange(e);

    const newValue = e.currentTarget.value;

    if (value !== newValue) {
      onChangeValue && onChangeValue(newValue);
    }
  };

  const onClickEye = () => {
    if (withEye) {
      withEyeType !== 'text' ? setWithEyeType('text') : setWithEyeType(type);
    }
  };

  if (type === 'regTel') {
    return (
      <InputWrapper
        className={cx('input', className)}
        style={style}
        size={size}
        block={block}
        active={active}
        disabled={disabled}
        failure={failure}
        success={success}
        withoutBorder={withoutBorder}
        elemBefore={elemBefore}
        elemAfter={elemAfter}
      >
        <IMaskInput
          mask={'+{7} (000) 000-00-00'}
          radix="."
          value={value}
          unmask
          onAccept={onChangePhone}
          inputRef={ref}
          name={name}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          className="input__element"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </InputWrapper>
    );
  }

  return (
    <InputWrapper
      className={cx('input', className)}
      style={style}
      size={size}
      block={block}
      active={active}
      disabled={disabled}
      failure={failure}
      success={success}
      withoutBorder={withoutBorder}
      elemBefore={elemBefore}
      elemAfter={elemAfter}
    >
      <input
        ref={ref}
        name={name}
        autoFocus={autoFocus}
        type={withEye ? withEyeType : type}
        readOnly={readonly}
        maxLength={maxLength}
        className="input__element"
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {withEye && <Icon name={eyeType} className="eye" onClick={onClickEye} />}
    </InputWrapper>
  );
});

export default React.memo(Input);
