import './style.css';

import React, { useRef } from 'react';
import FormItem from '~/components/ui/FormItem';
import TextArea, { TextAreaProps } from '~/components/ui/TextArea';
import pluralize from '~/utils/pluralize';

export interface TextAreaWithNoteProps {
  textAreaProps: TextAreaProps;
  showSizeLimitationNote?: boolean;
  customNote?: string;
  title?: string;
  hint?: string;
  message?: string;
  failure?: boolean;
}

const TextAreaWithNote: React.FC<TextAreaWithNoteProps> = ({
  textAreaProps,
  title,
  showSizeLimitationNote = false,
  customNote = '',
  hint,
  message = '',
  failure = false,
}) => {

  const ref = useRef();

  return (
    <FormItem
      noHTMLLabel
      title={title}
      hint={hint}
      noReservedPlaceForMessage
      failure={failure}
      message={message}
    >
      <div className="textarea-with-note">
        <TextArea autoSize ref={ref} {...textAreaProps} />
      </div>
      <div className="textarea-with-note__custom-note">{customNote}</div>
      {showSizeLimitationNote &&
        (
          <div className="textarea-with-note__size-limitation-message">
            Не больше {textAreaProps.maxLength} {pluralize(textAreaProps.maxLength, 'символа', 'символов', 'символов')}
          </div>
        )
      }
    </FormItem>
  );
};

export default TextAreaWithNote;
