import React from 'react';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import styles from './style.module.css';

interface IWrapperForTableCellProps {
  iconName?: string;
  iconColor?: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  tooltipName?: React.ReactNode;
}

const WrapperForTableCell: React.FC<IWrapperForTableCellProps> = ({
  iconName,
  iconColor,
  title,
  subtitle,
  tooltipName,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          {iconName && (
            <div className={styles.icon}>
              <Icon
                style={{ color: iconColor, pointerEvents: 'none' }}
                name={iconName}
              />
            </div>
          )}
          <div>
            <div className={styles.title_wrapper}>
              {title}
              {tooltipName && (
                <div className={styles.tooltip}>
                  <IconWithTooltip
                    iconProps={{
                      name: 'Question',
                    }}
                    tooltipProps={{
                      title: tooltipName,
                      placement: 'bottomRight',
                    }}
                  />
                </div>
              )}
            </div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WrapperForTableCell;
