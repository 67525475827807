export const parseGetParam = (queryString: string, param: string) => {
  try {
    const params =  new URLSearchParams(queryString);

    return params?.get(param);
  } catch (e) {
    console.log({ e });

    return false;
  }
};
