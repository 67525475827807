const parentWithTheClass = (
  element: Node,
  classname: string
): HTMLElement => {
  if (classname === 'table-ant__row' || classname === 'ant-table-row') {
    if ((element as HTMLElement).classList === undefined) return null;
    if ((element as HTMLElement).classList.contains(classname))
      return element as HTMLElement;

    if (!element.parentNode) return null;

    return parentWithTheClass(element.parentNode, classname);
  } else {
    return null;
  }
};

export default parentWithTheClass;