import { action, computed, observable, IObservableArray } from 'mobx';
import Scenarios, { TScenarioEditingStatus } from '~/api/scenarios';
import ScenarioModel from '~/models/ScenarioModel';
import ScenarioTemplateModel from '~/models/ScenarioTemplateModel';
import Store from '~/stores/store';

export default class ScenariosStore extends Store {
  @observable templateGroups: IObservableArray<IScenarioTemplateGroup> = [] as IObservableArray<IScenarioTemplateGroup>;
  @observable feedbackTemplateGroups: IObservableArray<IScenarioTemplateGroup> =
    [] as IObservableArray<IScenarioTemplateGroup>;
  @observable templates: IObservableArray<ScenarioTemplateModel> = [] as IObservableArray<ScenarioTemplateModel>;
  @observable feedbackTemplates: IObservableArray<ScenarioTemplateModel> =
    [] as IObservableArray<ScenarioTemplateModel>;
  @observable scenarios: IObservableArray<ScenarioModel> = [] as IObservableArray<ScenarioModel>;
  @observable feedbackScenarios: IObservableArray<ScenarioModel> = [] as IObservableArray<ScenarioModel>;
  @observable statistics: IObservableArray<IWidgetStatistics> = [] as IObservableArray<IWidgetStatistics>;
  @observable isTemplatesLoading = false;
  @observable isScenariosLoading = false;
  @observable isMessageShown = false;
  @observable isFetchingScenariosCount = false;
  @observable scenariosCount: GetScenariosCountPayload;
  @observable isFetchingPrivacyPolicy = false;
  @observable scenarioEditingStatus: TScenarioEditingStatus = 'edited';

  @action
  setScenarioEditingStatus = (scenarioEditingStatus: TScenarioEditingStatus) => {
    this.scenarioEditingStatus = scenarioEditingStatus;
  }

  getTemplatesByGroupIndex(groupIndex: number, isFeedback?: boolean) {
    const widgetTemplates = isFeedback ? this.feedbackTemplates : this.templates;

    return widgetTemplates.filter(template => template.groupIndex === groupIndex);
  }

  getTemplateByScenarioCode(code: string, isFeedback?: boolean) {
    const widgetTemplates = isFeedback ? this.feedbackTemplates : this.templates;

    return widgetTemplates.find(template => template.get('scenarioCode') === code);
  }

  @action
  fetchTemplates = async () => {
    this.isTemplatesLoading = true;

    try {
      const feedbackTemplateListResponse = await Scenarios.feedbackTemplateList();
      const templateListResponse = await Scenarios.templateList();

      const feedbackGroups = feedbackTemplateListResponse.data.payload;
      const groups = templateListResponse.data.payload;

      const feedbackTemplates: ScenarioTemplateModel[] = [];
      const templates: ScenarioTemplateModel[] = [];

      this.feedbackTemplateGroups.replace(feedbackGroups);
      this.templateGroups.replace(groups);

      groups.forEach((group, groupIndex) => {
        group.scenarios.forEach((template) => {
          const model = new ScenarioTemplateModel(this, template);
          model.setGroup(groupIndex);
          templates.push(model);
        });
      });

      feedbackGroups.forEach((feedbackGroup, groupIndex) => {
        feedbackGroup.scenarios.forEach((template) => {
          const model = new ScenarioTemplateModel(this, template);
          model.setGroup(groupIndex);
          feedbackTemplates.push(model);
        });
      });

      this.feedbackTemplates.replace(feedbackTemplates);
      this.templates.replace(templates);
    } catch (e) { } finally {
      this.isTemplatesLoading = false;
    }
  }

  @action
  fetchScenarios = async () => {
    this.isScenariosLoading = true;

    try {
      const scenariosListResponse = await Scenarios.list();
      const scenariosStatsResponse = await Scenarios.statisticsList();
      const stats = scenariosStatsResponse.data.payload;

      const feedbackWidgetsKeyword = 'Заявка от клиента';
      const wheelFoFortuneKeyword = 'Колесо фортуны';

      this.statistics.replace(stats);
      this.scenarios.replace(scenariosListResponse.data.payload
        .filter(scenario => (scenario.description !== feedbackWidgetsKeyword &&
          scenario.description !== wheelFoFortuneKeyword)).map(d => new ScenarioModel(this, d)));
      this.feedbackScenarios.replace(scenariosListResponse.data.payload
        .filter(scenario => scenario.description === feedbackWidgetsKeyword).map(d => new ScenarioModel(this, d)));
    } catch (e) { } finally {
      this.isScenariosLoading = false;
    }
  }

  @action
  showMessage = () => {
    this.isMessageShown = true;
  }

  @action
  hideMessage = () => {
    this.isMessageShown = false;
  }

  @action
  getTemplateGroups = (isFeedback?: boolean) => {
    return isFeedback ? this.feedbackTemplateGroups : this.templateGroups;
  }

  @action getScenariosCount = async () => {
    this.isFetchingScenariosCount = true;

    try {
      const response = await Scenarios.getScenariosCount();
      this.scenariosCount = response.data.payload[0];
    } catch (e) {
      throw e;
    } finally {
      this.isFetchingScenariosCount = false;
    }
  }

  @computed
  get availableScenarios() {
    return this.scenarios.filter(scenario => scenario.get('deleted') !== true);
  }

  @computed
  get availableFeedbackScenarios() {
    return this.feedbackScenarios.filter(scenario => scenario.get('deleted') !== true);
  }

  @computed
  get activeScenariosCount() {
    const allScenarios = [...this.availableScenarios, ...this.availableFeedbackScenarios];
    const active = allScenarios.filter(scenario => scenario.isActive).length;

    return active;
  }
}
