import React, { useEffect, useState } from 'react';
import Mailing from '~/api/mailing';
import Icon from '~/components/Icon';
import Logo from '~/components/Logo';
import Button from '~/components/ui/Button';
import LoadingScreen from '~/screens/LoadingScreen';
import { getError } from '~/utils/getError';
import { isMobile } from '~/utils/isMobile';
import { parseGetParam } from '~/utils/parseGetParam';
import styles from './style.module.css';

const MailingUnsubscribe: React.FC = () => {
  const [text, setText] = useState<string>('Вы успешно отписались от рассылки');
  const [smallText, setSmallText] = useState<string>('Вы больше не будете получать письмо от данного отправителя');
  const [icon, setIcon] = useState<string>('CheckmarkXxl');
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const utmContent = parseGetParam(location?.search, 'utm_content');
        await Mailing.unsubscribe(utmContent || '');
      } catch (error) {
        const resultError = getError({ error, unknownError: 'Сервер временно недоступен' });
        setText('Не удалось отписаться из-за ошибки');
        setSmallText(`Произошла ошибка${!!resultError ? `: «${resultError}»` : ''}.  Попробуйте отписаться позже.`);
        setIcon('CrossInCircleXxl');
      } finally {
        setIsFetching(false);
      }

    })();
  }, []);

  return (
    <>
      <Logo style={{
        position: 'absolute',
        left: 'calc(50% - 97px)',
      }} />
      {isFetching && (
        <LoadingScreen />
      )}
      {!isFetching && (
        <div className={`${styles.screen} ${isMobile() ? styles.mobile : ''}`}>
          <div className={styles.mailing_unsubscribe}>
            <Icon name={icon} />
            <div className={`${styles.text} ${isMobile() ? styles.mobile_text : ''}`}>
              {text}
            </div>
            <div className={`${styles.small_text} ${!isMobile() ? styles.desktop_small_text : ''}`}>
              {smallText}
            </div>
            <Button onClick={() => history.back()}>Назад</Button>
          </div>
        </div>
      )}
    </>

  );
};

export default MailingUnsubscribe;