import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { TScenarioEditingStatus } from '~/api/scenarios';
import ScenarioEditorSegments from '~/components/ScenarioEditor/ScenarioEditorSegments';
import ScenarioEditorSteps from '~/components/ScenarioEditor/ScenarioEditorSteps';
import ScenarioEditorWidget from '~/components/ScenarioEditor/ScenarioEditorWidget';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Tabs from '~/components/ui/Menu/Tabs';
import Message from '~/components/ui/Message';
import ScenarioTemplateModel from '~/models/ScenarioTemplateModel';
import { ESwitchCodes, UiSwitchStore } from '~/stores/UiSwitchStore';
import { setGtm } from '~/utils/setGtm';
import VideoMessage from '../ui/VideoMessage';
import './style.css';

interface Props {
  preset: IScenarioTemplate;
  scenarioEditingStatus?: TScenarioEditingStatus;
  setScenarioEditingStatus?: (v: TScenarioEditingStatus) => void;
  onClose?: () => void;
  onSave?: () => void;
  privacyPolicyText?: string;
  uiSwitchStore?: UiSwitchStore;
}

type K = string;
type V = boolean;

interface State {
  isSigned: boolean;
  currentStep: number;
  isHowItWorksVisible: boolean;
  errorText: string;
  isHowToConfigureOpen: boolean;
  configureText: string | JSX.Element;
  isWidgetStepDrawerOpen: boolean;
  isCloseWindowShown: boolean;
  isEnabledStep2: boolean;
  validation: Map<K, V>;
  oldState: string;
}

enum ESaveScenarioErrors {
  isSignedError = 'Перед сохранением нужно отметить галку "Я ознакомлен"',
}

@inject('uiSwitchStore')
@observer
class ScenarioEditor extends Component<Props, State> {
  preset: ScenarioTemplateModel = new ScenarioTemplateModel(this);

  state: State = {
    isSigned: false,
    currentStep: 1,
    isHowItWorksVisible: false,
    errorText: '',
    isHowToConfigureOpen: false,
    configureText: undefined,
    isWidgetStepDrawerOpen: false,
    isCloseWindowShown: true,
    isEnabledStep2: false,
    validation: new Map(),
    oldState: '',
  };

  getCircularReplacer = (key: string, value: any) => key === 'parent' ? undefined : value;

  componentDidMount() {

    const hasAccessToPreClosePopup = this.props.uiSwitchStore.hasAccess(ESwitchCodes['WidgetsScreen#PreClosePopup']);

    if (hasAccessToPreClosePopup) {
      this.props.setScenarioEditingStatus('pre-saved');
    }

    this.updatePreset({ preset: null }, this.props);

    const oldState = JSON.stringify(this.preset, this.getCircularReplacer);

    this.setState({ oldState });

    if (this.preset.get('id')) {
      this.setEnabledStep2(true);
    }
  }

  componentDidUpdate(nextProps: Props) {
    if (this.state.oldState === JSON.stringify(this.preset, this.getCircularReplacer) &&
      this.props.scenarioEditingStatus === 'editing') {
      this.props.setScenarioEditingStatus('edited');
      this.props.onClose();
    }
    this.updatePreset(this.props, nextProps);
  }

  updatePreset = (props: Props, nextProps?: Props) => {
    if (!nextProps.preset) {
      this.preset.clean();
    } else if (!props.preset && nextProps.preset) {
      this.preset.replace(nextProps.preset);
    } else if (props.preset['@type'] !== nextProps.preset['@type']) {
      this.preset.replace(props.preset);
    }
  }

  handleChangeSign = (isSigned: boolean) => {
    isSigned && this.state.errorText === ESaveScenarioErrors.isSignedError && this.setState({ errorText: '' });
    this.setState({ isSigned });
  }

  /**
   * Проверка общей валидации
   * валидация пройдена - true
   * @returns boolean
   */
  checkGeneralValidation = (): boolean => {
    return !Array.from(this.state.validation.values()).some(element => element === false);
  }

  /**
   * Добавляет новый элемент для наблюдения за валидацией
   * и проверяет общее состояние валидации по всем наблюдаемым элементам
   * если общая валидация пройдена делает доступными следуюшие шаги
   * @param groupIndex
   * @param stepIndex
   * @param status
   */
  addCurrentValidationStatus = (
    groupIndex: number,
    stepIndex: number,
    status: boolean
  ): void => {
    this.state.validation.set(
      `${groupIndex}${stepIndex}`, status
    );
    this.setEnabledStep2(this.checkGeneralValidation());
  }

  /**
   * Изменение доступности следующих вкладок
   * @param status
   */
  setEnabledStep2 = (status: boolean): void => {
    this.state.isEnabledStep2 = status;
  }

  handleChangeStep = (step: string) => {
    this.setState({ currentStep: parseInt(step, 10) });
  }

  handleClickNext = () => {
    const { currentStep } = this.state;
    this.handleChangeStep(String(currentStep + 1));
  }

  handleClickHowItWorks = () => {
    this.setState({ isHowItWorksVisible: true });
  }

  handleCloseHowItWorks = () => {
    this.setState({ isHowItWorksVisible: false });
  }

  handleChangeTriggerParams = (params: INotification) => {
    this.preset.replaceTriggerParams(params);
  }

  handleChangeSteps = (stepGroups: IScenarioTemplateStepGroupBase[]) => {
    this.preset.replaceStepGroups(stepGroups);
  }

  handleSaveScenario = async () => {
    if (!this.state.isSigned) {
      this.setState({ errorText: ESaveScenarioErrors.isSignedError });

      return;
    }

    try {
      await this.preset.save();
      this.props.onSave();
      this.setState({ errorText: '' });
    } catch (e) {
      this.setState({
        errorText: e.response?.data?.errorData.errorText.split(':')[0] || '',
      });
    }
  }

  showHowToConfigure = () => {
    this.setState({ isHowToConfigureOpen: true });
  }

  hideHowToConfigure = () => {
    this.setState({ isHowToConfigureOpen: false });
  }

  setConfigureText = (text: string) => {
    this.setState({ configureText: text });
  }

  showIsWidgetStepDrawerOpen = () => {
    setGtm('video_design');
    this.setState({ isWidgetStepDrawerOpen: true });
  }

  hideIsWidgetStepDrawerOpen = () => {
    this.setState({ isWidgetStepDrawerOpen: false });
  }

  renderFooter = () => {
    const { currentStep, isHowItWorksVisible, isSigned } = this.state;
    const { preset } = this;

    return (
      <div className="scenario-editor__footer">
        <DrawerFooter
          actions={
            currentStep === 1 || currentStep === 2 ? (
              currentStep === 1 ? (
                <Button onClick={this.handleClickNext} disabled={!this.state.isEnabledStep2}>Далее</Button>
              ) : (
                <Button onClick={this.handleClickNext} disabled={!this.state.isEnabledStep2}>Далее</Button>
              )
            ) : (
              <>
                <Button
                  onClick={this.handleSaveScenario}
                  loading={preset.isSaving}
                  disabled={!isSigned}
                >
                  Сохранить
                </Button>
                <div className="scenario-editor__error">
                  {this.state.errorText}
                </div>
              </>
            )
          }
          withSidebar={[3].includes(currentStep)}
        />

        <Drawer
          visible={isHowItWorksVisible}
          onClose={this.handleCloseHowItWorks}
          title={preset.get('ui')?.shortDescription}
          description={preset.get('ui')?.longDescription}
          size="x-small"
        />
      </div>
    );
  }

  renderSteps = () => {
    const { preset } = this;

    return (
      <ScenarioEditorSteps
        steps={preset.get('stepGroups')}
        onChange={this.handleChangeSteps}
        showHowToConfigure={this.showHowToConfigure}
        type={preset.get('@type')}
        setConfigureText={this.setConfigureText}
        addCurrentValidationStatus={this.addCurrentValidationStatus}
      />
    );
  }

  renderSegments = () => {
    return <ScenarioEditorSegments />;
  }

  renderWidget = () => {
    const { preset } = this;

    if (!preset.triggerParams) return null;

    return (
      <ScenarioEditorWidget
        triggerParams={preset.triggerParams}
        onChangeTriggerParams={this.handleChangeTriggerParams}
        showIsWidgetStepDrawerOpen={this.showIsWidgetStepDrawerOpen}
      />
    );
  }

  renderPreview = () => {
    const { preset } = this;
    const { isSigned } = this.state;

    if (!preset.triggerParams) return null;

    return (
      <>
        <ScenarioEditorWidget
          triggerParams={preset.triggerParams}
          onChangeTriggerParams={this.handleChangeTriggerParams}
          isPreview
          title={preset.get('name')}
          onChangeTitle={title => preset.update({ name: title })}
          isSigned={isSigned}
          onChangeSign={this.handleChangeSign}
          tabStep={this.state.currentStep}
        />
      </>
    );
  }

  render() {
    const { preset } = this;

    if (!preset) return null;

    const { scenarioEditingStatus } = this.props;

    const { currentStep, isHowToConfigureOpen, configureText, isWidgetStepDrawerOpen } = this.state;

    const closeWarningWindow = () => {
      this.props.setScenarioEditingStatus('pre-saved');
    };

    const ExitEditingMode = () => {
      this.props.setScenarioEditingStatus('edited');
      this.props.onClose();
    };

    const hasAccessToPreClosePopup =
      this.props.uiSwitchStore.hasAccess(ESwitchCodes['WidgetsScreen#PreClosePopup']);

    return (
      <div className="scenario-editor">
        {scenarioEditingStatus === 'editing' && hasAccessToPreClosePopup && (
          <Message
            title={'У вас остались несохраненные изменения'}
            textTop={
              this.checkGeneralValidation()
              ? 'Чтобы сохранить виджет перейдите на шаг "Предпросмотр", убедитесь в наличии галки на пункте "Я ознакомлен" и нажмите кнопку "Сохранить".'
              : 'Чтобы сохранить виджет убедитесь, что на шаге "Условия" заполнены обязательные поля. Затем перейдите на шаг "Предпросмотр", убедитесь в наличии галки на пункте "Я ознакомлен" и нажмите кнопку "Сохранить".'
            }
            visible
            onExit={closeWarningWindow}
            onApply={ExitEditingMode}
            onClose={closeWarningWindow}
            applyText={'Не сохранять'}
            cancelText={'Отмена'}
            showIcon={false}
          />
        )}
        <div className="scenario-editor__tabs">
          <Tabs
            items={[
              { key: '1', title: '1. Условия' },
              { key: '2', title: '2. Виджет', disabled: !this.state.isEnabledStep2 },
              { key: '3', title: '3. Предпросмотр', disabled: !this.state.isEnabledStep2 },
            ]}
            value={String(currentStep)}
            onChange={this.handleChangeStep}
          />
        </div>

        <div
          className={cx('scenario-editor__container', {
            [`_step-${currentStep}`]: currentStep,
          })}
        >
          {currentStep === 1 && this.renderSteps()}
          {currentStep === 2 && this.renderWidget()}
          {currentStep === 3 && this.renderPreview()}
        </div>

        {this.renderFooter()}
        {isHowToConfigureOpen && (
          typeof configureText === 'string'
          ?
          (
            <VideoMessage
              visible={isHowToConfigureOpen}
              videoTitle={preset.get('description')}
              onClose={() => this.hideHowToConfigure()}
              onExit={() => this.hideHowToConfigure()}
              videoSrc={configureText}
            />
          )
          :
          (
            <Drawer
              visible={isHowToConfigureOpen}
              onClose={() => {
                this.setConfigureText(undefined);
                this.hideHowToConfigure();
              }}
              size="x-small"
            >
              <h1 className="scenario-editor__configure-title">
                Настройка условий показа для виджета «{preset.get('description')}»
              </h1>
              <p className="scenario-editor__configure-text">{configureText}</p>
            </Drawer>
          )
        )}

        {isWidgetStepDrawerOpen && (
          <VideoMessage
            visible={isWidgetStepDrawerOpen}
            title="Как настроить внешний вид виджета"
            onClose={() => this.hideIsWidgetStepDrawerOpen()}
            onExit={() => this.hideIsWidgetStepDrawerOpen()}
            videoSrc="https://www.youtube.com/embed/7EiA58H3zE8"
          />
        )}
      </div>
    );
  }
}

export default ScenarioEditor;
