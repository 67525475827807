import { AxiosPromise } from 'axios';
import { actionGet } from '~/api/client';

export interface Legend {
  '@type': 'Legend';
  name: string;
  value: number;
  description: string;
}

export interface AnalyticsData {
  '@type': 'AnalyticsData';
  value: string;
  count: number;
}

export interface AnalyticsInfo {
  '@type': 'AnalyticsInfo';
  segment: number;
  count: number;
  percentageComparison?: number;
  name: string;
  data: AnalyticsData[];
}

export interface Detail {
  '@type': 'Detail';
  detailInfo: DetailInfo;
  details: AnalyticsInfo[];
}

export interface DetailInfo {
  '@type': 'DetailInfo';
  count: number;
  percentageComparison: number;
}

export interface ReportPayload {
  '@type': 'ReportPayload';
  detail: Detail;
  legend: Legend[];
  isEnabled: boolean;
}

export interface ReportTypesPayload {
  '@type': string;
  type: string;
  description: string;
  reports: Report[];
}

interface Segment {
  '@type': 'Segment';
  id: number;
  value: string;
}

interface SegmentPayload {
  '@type': 'SegmentPayload';
  segment: Segment[];
}

interface CheckDashboardPayload {
  '@type': 'PrimitiveJsonParameter';
  dashboardsAllowed: boolean;
}

export type ReportTypesResponse = IResponse<ReportTypesConfig[]>;
export type ReportResponse = IResponse<[ReportPayload]>;
export type SegmentResponse = IResponse<[SegmentPayload]>;
export type CheckDashboardResponse = IResponse<[CheckDashboardPayload]>;

const Dashboard = {
  segments: (): AxiosPromise<SegmentResponse> =>
    actionGet('/admin/segments', {}),

  checkDashboards: (): AxiosPromise<CheckDashboardResponse> =>
    actionGet('/admin/checkDashboards', {}),

  reportTypes: (): AxiosPromise<ReportTypesResponse> =>
    actionGet('/admin/reportTypes', {}),

  report: (
    type: ReportType,
    from?: string,
    to?: string,
    detail: ReportDetails = 'DAILY',
    showDetail: boolean = true,
    showLegend: boolean = true,
    legendLimit: number = 10
  ): AxiosPromise<ReportResponse> =>
    actionGet('/admin/report', {
      showDetail,
      showLegend,
      percentageComparison: true,
      legendLimit,
      type,
      from,
      to,
      detail,
    }),
};

export default Dashboard;
