import { IFeedConfiguration } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FontSettings from '~/components/notification-editor/FontSettings';

const HandDescriptionParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({ isActive, configuration, onChange, onChangeCssParam }) => {

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FontSettings
          className="feed-editor-appearance__font"
          type="title"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          data={{
            fontFamily: configuration.cardDescriptionFontFamily,
            color: configuration.cardDescriptionColor,
            alpha: configuration.cardDescriptionOpacity,
            fontWeight: configuration.cardDescriptionFontWeight,
            fontSize: configuration.cardDescriptionFontSize,
            isItalic: configuration.cardDescriptionFontItalic,
            isLineThrough: configuration.cardDescriptionTextLineThrough,
            isUnderline: configuration.cardDescriptionTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                cardDescriptionFontFamily: data.fontFamily,
                cardDescriptionColor: data.color,
                cardDescriptionOpacity: data.alpha,
                cardDescriptionFontWeight: data.fontWeight,
                cardDescriptionFontSize: data.fontSize,
                cardDescriptionFontItalic: data.isItalic,
                cardDescriptionTextLineThrough: data.isLineThrough,
                cardDescriptionTextUnderline: data.isUnderline,
              } as IFeedConfiguration,
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandDescriptionParams;
