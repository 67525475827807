import React, { useEffect, useState } from 'react';
import Icon from '~/components/Icon';
import Button from '../Button';
import './style.css';

interface INoticeInfoProps {
  visible: boolean;
  title?: string;
  text?: string;
  actionText?: string;
  afterClose?: () => void;
  onActionClick?: () => void;
}

const NoticeInfo: React.FC<INoticeInfoProps> = ({
  visible,
  title = '',
  text = '',
  actionText = 'Хорошо',
  afterClose = null,
  onActionClick = null,
}) => {

  const [show, setShow] = useState<boolean>(false);
  let timeOut: NodeJS.Timeout;
  
  const onClose = () => {
    setShow(false);
    afterClose && afterClose();
  };

  useEffect(() => {
    if (visible) {
      timeOut = setTimeout(setShow, 1000, true);
    }

    return () => clearTimeout(timeOut);
  }, [visible]);
  
  return (
    <div className={`component-ui-notice-info__box ${show ? '' : 'hidden'}`}>
      <div className="component-ui-notice-info__header">
        <div className="component-ui-notice-info__title">
          {title}
        </div>
        <div className="component-ui-notice-info__close-icon" onClick={onClose}>
          <Icon name="Close" style={{
            color: 'var(--White)',
            width: 24,
            height: 24,
          }} />
        </div>
      </div>
      <div className="component-ui-notice-info__text">
        {text}
      </div>
      <div  className="component-ui-notice-info__action">
        <Button size={'small'} type={'grey'} onClick={() => {
          onActionClick && onActionClick();
        }}>{actionText}</Button>
      </div>
    </div>
  );
};

export default NoticeInfo;