import cx from 'classnames';
import React, { useEffect } from 'react';
import Icon, { Icons } from '../../Icon';
import Button from '../Button';
import Link from '../Link';
import './style.css';

interface IText {
  text: string;
  icon: Icons;
}

interface Props {
  title?: string | JSX.Element;
  textTop?: string | JSX.Element;
  textBottom?: string | JSX.Element;
  applyText?: string | IText;
  cancelText?: string;
  visible: boolean;
  onExit?: () => void;
  onClose?: () => void;
  onApply?: () => void;
  showIcon?: boolean;
  showBackgroundCloseIcon?: boolean;
  swapActions?: boolean;
  className?: string;
  showLine?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  emailInput?: JSX.Element;
  emailInputError?: string;
  isErrorBlock?: boolean;
  isFooter?: boolean;
  turnOffClickBackground?: boolean;
  applyLoading?: boolean;
  isCenterContent?: boolean;
}

const Message: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    title,
    textTop,
    textBottom,
    applyText,
    cancelText,
    visible,
    onClose = () => {
      return;
    },
    onApply = () => {
      return;
    },
    onExit = () => {
      return;
    },
    showIcon = true,
    showBackgroundCloseIcon = false,
    swapActions = false,
    className = '',
    showLine = false,
    emailInput,
    emailInputError,
    isErrorBlock = false,
    isFooter = true,
    turnOffClickBackground = false,
    applyLoading = false,
    isCenterContent = false,
  } = props;

  const ENTER_CODE = 13;
  const ESCAPE_CODE = 27;

  useEffect(() => {
    const handleEsc = (event: any) => {
      switch (event.keyCode) {
        case ENTER_CODE:
          swapActions ? onClose() : onApply();
          break;
        case ESCAPE_CODE:
          swapActions ? onApply() : onClose();
          break;
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleClose = () => !turnOffClickBackground && onClose();

  return (
    <div className={cx('message_wrapper', className, { _hidden: !visible })}>
      {showBackgroundCloseIcon && (
        <Icon
          className="message-background-close__icon"
          name="Close"
          onClick={onClose}
        />
      )}
      <div className="message__background" onClick={handleClose} />
      <div className="message__box" ref={ref}>
        <div className="message__header">
          <div className={cx('message__title', { __center: isCenterContent })}>
            {title}
          </div>
          {showIcon && (
            <Icon
              className="message__icon"
              name="Close"
              onClick={onExit || onClose}
            />
          )}
        </div>
        <div className="message__body">
          <div
            className={cx('message__text _top', { __center: isCenterContent })}
          >
            {textTop}
          </div>
          {textBottom && (
            <div
              className={cx('message__text _bottom', {
                __center: isCenterContent,
              })}
            >
              {textBottom}
            </div>
          )}
        </div>
        {showLine && <div className="message__line" />}
        {isErrorBlock && (
          <div className="message__input">
            {emailInput || null}
            <div className="message_input__error">
              {emailInputError || null}
            </div>
          </div>
        )}
        {isFooter && (
          <div className={cx('message__footer', { __center: isCenterContent })}>
            {applyText && (
              <Button
                size={'large'}
                icon={
                  typeof applyText === 'object' ? applyText.icon : undefined
                }
                onClick={swapActions ? onClose : onApply}
                loading={applyLoading}
              >
                {typeof applyText === 'string' ? applyText : applyText.text}
              </Button>
            )}
            <Link
              className="message__link"
              to="#"
              onClick={swapActions ? onApply : onClose}
            >
              {cancelText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});

export default Message;
