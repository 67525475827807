import React, { useEffect, useState } from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SignOfUnificationDropdown from '~/components/generic/SignOfUnificationDropdown';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';
import styles from './style.module.css';

interface ISettingByIdProps {
  isOpenDialogSettingsById: boolean;
  setIsOpenDialogSettingsById: (status: boolean) => void;
  onChange: ({}: IPlacementPageParams) => void;
  feed: FeedProModel;
}

const SettingById: React.FC<ISettingByIdProps> = ({
  isOpenDialogSettingsById,
  setIsOpenDialogSettingsById,
  onChange,
  feed,
}) => {
  const [setting, setSetting] = useState<IPlacementPageParams>({
    checkedCountPage: feed.get('displayConditions') ? 'ONE' : 'MULTIPLE',
    urlPattern: feed.get('urlPattern'),
    url: feed.get('displayConditions')?.url,
    operation: feed.get('displayConditions')?.operation,
  });

  useEffect(() => {
    setSetting({
      checkedCountPage: feed.get('displayConditions') ? 'ONE' : 'MULTIPLE',
      urlPattern: feed.get('urlPattern'),
      url: feed.get('displayConditions')?.url,
      operation: feed.get('displayConditions')?.operation,
    });
  }, [isOpenDialogSettingsById]);

  const onChangeSeparator = (operation: Operation) => {
    setSetting({ ...setting, operation });
  };

  const onChangeDisplayConditions = (url: string) => {
    setSetting({ ...setting, url });
  };

  return (
    <Drawer
      title={'Настройка по ID'}
      visible={isOpenDialogSettingsById}
      size="small"
      onClose={() => {
        setIsOpenDialogSettingsById(false);
      }}
      actions={(
        <Button
          onClick={() => {
            setIsOpenDialogSettingsById(false);
            onChange({
              url: setting.url,
              checkedCountPage: feed.get('displayConditions') ? 'ONE' : 'MULTIPLE',
              operation: setting.operation,
              urlPattern: setting.urlPattern,
            });
          }}
        >
          Сохранить
        </Button>
      )}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <NumberedBlock>
            <SignOfUnificationDropdown
              title="Признак объединения страниц"
              operation={setting.operation}
              onChangeSeparator={onChangeSeparator}
              url={setting.url}
              onChangeDisplayConditions={onChangeDisplayConditions}
              withFlexDirectionColumn
            />
          </NumberedBlock>
          <NumberedBlock>
            <div className={styles.title}>
              Скрипт для товаров по идентификатору
            </div>
            <div className={styles.info}>
              Встраивание скрипта для подбора товаров по идентификатору.
            </div>
            <ToCopyArea
              text={
                '<script type="text/javascript">' +
                '(window["sberLeadApiQueue"] = window["sberLeadApiQueue"] || []).push(' +
                '{' +
                'method: "setProductsInPages",' +
                'items: [' +
                '{ productId: <product_id> }' +
                '],' +
                `externalKey: "${feed.get('externalKey')}"` +
                '}' +
                ');' +
                '</script>'
              }
              height={'155px'}
              className={styles.code_copy}
            />
          </NumberedBlock>
        </div>
      </div>
    </Drawer>
  );
};

export default SettingById;