import { observer } from 'mobx-react';
import React from 'react';
import Input from '~/components/ui/Input';
import commonStyles from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/style.module.css';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { getMaxLengthMessage } from '~/screens/MailingScreen/utils';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const SenderName = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const { mailingInstance } = mailingStore;
  const authorizationEmailObj = mailingInstance.get('authorizationEmail');
  const senderName = authorizationEmailObj.senderName;

  const maxTextLength = 55;
  const isErrorShown = senderName.length >= maxTextLength;

  const setName = (senderName: string) => {
    mailingInstance.update({
      authorizationEmail: { ...authorizationEmailObj, senderName },
    });
  };

  return (
    <div className={styles.inputBlock}>
      <h3>Введите имя отправителя</h3>
      <p className={styles.description}>
        Это то название, которое отображается вместо адреса почты у получателя
        письма.
      </p>
      <Input
        placeholder="MyCompanyName"
        className={styles.input}
        value={senderName}
        onChangeValue={setName}
        maxLength={maxTextLength}
        failure={isErrorShown}
      />
      {isErrorShown && (
        <div className={commonStyles.error}>
          {getMaxLengthMessage(maxTextLength)}
        </div>
      )}
    </div>
  );
});
