import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FloatTableContextButton } from '~/components/generic/FloatTable/FloatTableContextButton';
import { FloatTableData } from '~/components/generic/FloatTable/FloatTableData';
import { FloatTableLabel } from '~/components/generic/FloatTable/FloatTableLabel';
import { FloatTableSwapButtons } from '~/components/generic/FloatTable/FloatTableSwapButtons';
import styles from './style.module.css';

export const FloatTableRow = ({
  title,
  labels,
  data,
  xPosition,
  setXPosition,
  maxShift,
  setMaxShift,
  contextMenu,
  onRowClick,
}: IFloatTableRowComponent) => {
  const floatTableRow = useRef(null);
  const floatTableScrollable = useRef(null);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [mounted, setMounted] = useState(false);
  const [touchX, setTouchX] = useState<number | undefined>(undefined);

  const className = showButtons ? '_showButtons' : '_hideButtons';

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      // @ts-ignore
      const rowWidth = floatTableRow.current?.offsetWidth;
      // @ts-ignore
      const scrollableWidth = floatTableScrollable.current?.offsetWidth;

      !maxShift && setMaxShift(scrollableWidth);

      if (rowWidth < scrollableWidth) {
        setShowButtons(true);
      }
    }
  }, [showButtons]);

  const onTouchMove = (e: any) => {
    try {
      const ontouchmove = { ...e };
      const x = ontouchmove.changedTouches[0].clientX;
      const shiftX = x - touchX;
      setTouchX(x);
      setXPosition(xPosition + shiftX);
    } catch (e) {
      console.log('ontouchmove error', e);
    }

  };

  const onTouchStart = (e: any) => {
    try {
      const ontouchstart = { ...e };
      const x = ontouchstart.changedTouches[0].clientX;
      setTouchX(x);
    } catch (e) {
      console.log('ontouchstart error', e);
    }

  };

  const onTouchEnd = (e: any) => {
    setTouchX(undefined);
  };

  const onRowClickWrap = (e: any) => {
    if (showButtons) {
      return;
    }
    onRowClick(e);
  };

  return (
    <div className={cx(styles.floatTableRow, className)} ref={floatTableRow} onClick={onRowClickWrap}>
      <div
        className={styles.scrollable}
        ref={floatTableScrollable}
        style={{ left: xPosition || 8 + 'px' }}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.labels}>
          {labels?.map((v: IFloatTableRowLabel, key: number) => (
            <FloatTableLabel key={key} {...v} />
          ))}
        </div>
        <div className={styles.data}>
          {data?.map((v: IFloatTableData, key: number) => (
            <FloatTableData key={key} {...v} />
          ))}
        </div>
      </div>
      <div className={styles.shadow} />
      <FloatTableSwapButtons className={className} setXPosition={setXPosition} xPosition={xPosition} />
      <FloatTableContextButton config={contextMenu} />
    </div>
  );
};
