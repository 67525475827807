import React from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import './style.css';

const SuccessfulChoice: React.FC<ISuccessfulChoiceProps> = ({ rechoosing }) => {
  return (
    <>
      <div className="feed-editor-step-selector-device-successful-choice__container">
        <div className="feed-editor-step-selector-device-successful-choice__block">
          <Icon
            className="feed-editor-step-selector-device-successful-choice__icon"
            name="CheckmarkXxl"
          />
          <div className="feed-editor-step-selector-device-successful-choice__info">
            Место расположения
            <br />
            ленты выбрано
          </div>
          <Button
            onClick={rechoosing}
            type="grey"
            size="large"
            className="feed-editor-choose-selector__button"
          >
            Изменить расположение ленты
          </Button>
        </div>
      </div>
    </>
  );
};

export default SuccessfulChoice;
