import { IFeedConfiguration } from 'feed';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import DubleInfoBlock from '~/components/generic/DubleInfoBlock';
import RecommendationsApiBlock from '~/components/generic/RecommendationsApiBlock';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import ProductCard from '~/screens/FeedLiteScreen/components/ProductCard';
import { EFeedType } from '../types';

import './style.css';

const InfoBlock: React.FC<{
  operation: string;
  urlPattern: string;
  feedProYmlUrl: string;
  feedProSelector: string;
  feedProSelectorMobile: string;
  feedConfiguration: IFeedConfiguration;
  getProducts: FeedConstructorModel[];
  feedType: EFeedType;
  errorDesc: string[];
}> = ({
  operation,
  urlPattern,
  feedProYmlUrl,
  feedProSelector,
  feedProSelectorMobile,
  feedConfiguration,
  getProducts,
  feedType,
  errorDesc = [],
}) => {
  const containerRef = useRef<HTMLDivElement>();

  const [display, setDisplay] = useState<'flex' | 'none'>('none');
  const [contentHeight, setContentHeight] = useState('0');

  const handleResize = () => {
    setDisplay('none');
    const height = containerRef.current.getBoundingClientRect().height;
    setDisplay('flex');
    setContentHeight(`${height}px`);
  };

  useEffect(() => {
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 400);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <div ref={containerRef} className={'info-block__container'}>
      <div
        style={{
          display,
          height: contentHeight,
        }}
        className={'info-block__wrapper'}
      >
        {feedType !== EFeedType.API && (
          <div className={'info-block__title_info'}>
            Страницы размещения и условия
          </div>
        )}
        {urlPattern && (
          <DubleInfoBlock
            className={'info-block__duble_info_block'}
            topContent={(
              <div className={'info-block__top_info_block'}>
                <div className={'info-block__info_desc'}>{operation}</div>
              </div>
            )}
            bottomContent={(
              <div className={'info-block__top_info_block'}>
                <div className={'info-block__info_desc'}>
                  {urlPattern}
                  <IconWithTooltip
                    iconProps={{ name: 'Question' }}
                    tooltipProps={{
                      title:
                        'Ссылка на страницу должна быть полной - включая «http://» или «https://»',
                      placement: 'bottomLeft',
                    }}
                  />
                </div>
              </div>
            )}
          />
        )}

        {(feedType === EFeedType.PRO || feedType === EFeedType.API) &&
          feedProYmlUrl && (
            <>
              <div className={'info-block__title_info'}>
                Ссылка на каталог товаров в формате YML
              </div>
              <DubleInfoBlock
                className={'info-block__duble_info_block'}
                bottomContent={(
                  <div className={'info-block__top_info_block'}>
                    <div className={'info-block__info_desc'}>
                      {feedProYmlUrl}
                      <IconWithTooltip
                        iconProps={{ name: 'Question' }}
                        tooltipProps={{
                          title:
                            'Ссылка на страницу должна быть полной - включая «http://» или «https://»',
                          placement: 'bottomLeft',
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            </>
          )
        }

        {feedType === EFeedType.PRO && feedProSelector && (
          <>
            <div className={'info-block__title_info'}>
              Селектор (на компьютерах)
            </div>
            <DubleInfoBlock
              className={'info-block__duble_info_block'}
              bottomContent={(
                <div className={'info-block__top_info_block'}>
                  <div className={'info-block__info_desc'}>
                    {feedProSelector}
                  </div>
                </div>
              )}
            />
          </>
        )}

        {feedType === EFeedType.PRO && feedProSelectorMobile && (
          <>
            <div className={'info-block__title_info'}>
              Селектор (на мобильных телефонах)
            </div>
            <DubleInfoBlock
              className={'info-block__duble_info_block'}
              bottomContent={(
                <div className={'info-block__top_info_block'}>
                  <div className={'info-block__info_desc'}>
                    {feedProSelectorMobile}
                  </div>
                </div>
              )}
            />
          </>
        )}

        {(feedType === EFeedType.PRO || feedType === EFeedType.API) && errorDesc?.length > 0 && (
          <>
            
            <DubleInfoBlock
              className={'info-block-info__error-block'}
              bottomContent={(
                <>
                  <div className={'info-block__title_info-error'}>
                    Каталог в формате YML содержит ошибки
                  </div>
                  {errorDesc.map((errorLine: string, key: number) => {
                    return (
                      <p key={key} className="info-block-info__error-desc">
                        {errorLine}
                      </p>
                    );
                  })}
                </>
              )}
            />
          </>
        )}

        {feedType === EFeedType.LITE && getProducts.length ? (
          <div className={'info-block__title_info'}>Товары</div>
        ) : null}

        {feedType === EFeedType.LITE &&
          getProducts.map(
            (productModel: FeedConstructorModel, index: number) => {
              return (
                <div key={index} className={'info-block__table_card'}>
                  <ProductCard
                    productModel={productModel}
                    feedConfiguration={feedConfiguration}
                    witNothVisibleLink
                  />
                </div>
              );
            }
          )}
        {feedType === EFeedType.API && (
          <div className={'info-block__recommendation_wrapper'}>
            <RecommendationsApiBlock />
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBlock;
