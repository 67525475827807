import cx from 'classnames';
import React, { CSSProperties } from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface Props {
  title: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  id?: string;
  mobile?: boolean;
  onClick?: (id: string) => void;
  tag?: string;
  tagColor?: string;
}

const PresetCard: React.FC<Props> = ({
  title,
  className,
  style,
  id,
  mobile,
  onClick,
  children,
  tag,
  tagColor,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <div
      className={cx('preset-card', { _mobile: mobile }, className)}
      style={style}
      onClick={handleClick}
    >
      <div className="preset-card__container">
        <div className="preset-card__cover">
          <div
            className="preset-card__cover-bg"
          >
            <Icon name="PresetBg" />
          </div>
          <div className="preset-card__cover-item">
            {children}
          </div>
        </div>
        <div className="preset-card__body">
          <div className="preset-card__title">
            {title}
          </div>
          {tag ? (
            <div className="preset-card__tag" style={{ backgroundColor: tagColor }}>
              {tag}
            </div>
          ) : (
            <div className="preset-card__aside">
              <Icon name="Eye" className="preset-card__icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresetCard;
