import cx from 'classnames';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import './style.css';

export interface LinkProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void | PromiseLike<any>;
  to?: string ;
  target?: '_blank';
}

const Link: React.FC<LinkProps> = ({
  children,
  style,
  className,
  onClick,
  target,
  to,
}) => {

  const classNames = cx('link', className);

  let link;

  if (to && typeof to !== 'object' && to.startsWith('http')) {
    link = (
      <a
        className={classNames}
        onClick={onClick}
        style={style}
        href={to}
        target={target}
      >
        {children}
      </a>
    );
  } else {
    link = (
      <ReactRouterLink
        className={classNames}
        onClick={onClick}
        style={style}
        to={to}
      >
        {children}
      </ReactRouterLink>
    );
  }

  return link;
};

export default Link;
