import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import Logo from '~/components/Logo';
import Navigation from '~/components/Navigation';
import SidebarUser from '~/components/SidebarUser';
import { AuthStore } from '~/stores/authStore';
import { formatPhoneNumber } from '~/utils/formatPhoneNumber';
import { urlTruncateProtocol } from '~/utils/urlTruncateProtocol';
import './style.css';

interface Props {
  authStore?: AuthStore;
}

interface State {
  collapsed: boolean;
}

@inject('authStore')
@observer
class Sidebar extends Component<Props, State> {
  state = {
    collapsed: false,
  };

  render() {
    const { collapsed } = this.state;
    const { authStore } = this.props;
    const lastLoginDate = moment(authStore.currentUser.lastLoginDate).format('DD.MM.YYYY HH:mm');
    const domain = urlTruncateProtocol(authStore.currentOrg.domain[0]);
    const { abbreviation, fullName, login } = authStore.currentUser;
    const { isLoggingOut, logout } = authStore;
    const userName = fullName || login || '-';

    return (
      <>
        <div className={cx('sidebar', { _collapsed: collapsed })}>
          <div className="sidebar__header">
            <Logo />
          </div>
          <div className="sidebar__body">
            <Navigation />
          </div>
          <div className="sidebar__footer">
            <SidebarUser
              abbreviation={abbreviation}
              domain={domain}
              userName={formatPhoneNumber(userName) ?? userName}
              lastLoginDate={lastLoginDate}
              isLoggingOut={isLoggingOut}
              logout={logout}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
