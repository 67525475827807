import React from 'react';
import TypeSelectionCard from '~/components/generic/TypeSelectionCard';
import { FEED_TYPE_INFO } from '~/screens/FeedProScreen/components/FeedProEditor/StepFeedType/constants';
import './style.css';

interface IFeedEditorFeedType {
  chooseCard: (recType: string, recTitle: string) => void;
  chosenCard: string;
  recommendationTypes: FeedRecType[];
}

const StepFeedType: React.FC<IFeedEditorFeedType> = ({
  chooseCard,
  chosenCard,
  recommendationTypes,
}) => {
  return (
    <div className="feed-editor-feed-type">
      <h1 className="feed-editor-feed-type__title">
        Выберите тип ленты, который хотите разместить на сайте
      </h1>
      <h2 className="feed-editor-feed-type__sub-title">
        Товары в ленту из вашего каталога будут подбираться автоматически на
        основе поведения пользователя.
      </h2>
      {FEED_TYPE_INFO.map((recommendationType) => {
        return (
          recommendationTypes.find(
            ({ type }) => type === recommendationType.type
          ) && (
            <TypeSelectionCard
              key={recommendationType.type}
              id={recommendationType.type}
              title={recommendationType.title}
              description={recommendationType.description}
              exampleText={recommendationType.exampleText}
              messageText={recommendationType.messageText}
              image={`assets/feed/${recommendationType.type}.png`}
              onClick={chooseCard}
              isChosen={chosenCard === recommendationType.type}
            />
          )
        );
      })}
    </div>
  );
};

export default StepFeedType;
