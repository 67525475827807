import React from 'react';
import TabCatalog from '~/screens/MailingScreen/components/MailingConstructor/TabCatalog';
import { TabConstructor } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor';
import { TabParameters } from '~/screens/MailingScreen/components/MailingConstructor/TabParameters';
import TabSelectType from '~/screens/MailingScreen/components/MailingConstructor/TabSelectType';

interface IGetMailConstructorTabsItems {
  tab2Disabled: boolean;
  tab3Disabled: boolean;
}
export const getMailConstructorTabsItems = ({
  tab2Disabled,
  tab3Disabled,
}: IGetMailConstructorTabsItems) => {
  return [
    {
      key: 'TabSelectType',
      title: '1. Тип рассылки',
    },
    {
      key: 'TabCatalog',
      title: '2. Каталог товаров',
      disabled: tab2Disabled,
    },
    {
      key: 'TabParameters',
      title: '3. Параметры',
      disabled: tab3Disabled || tab2Disabled,
    },
    {
      key: 'TabConstructor',
      title: '4. Дизайн и отправка',
      disabled: tab3Disabled || tab2Disabled,
    },
  ];
};

export const mailingConstructorTabContent = (
  key: TMailingConstructorSteps
): JSX.Element => {
  switch (key) {
    case 'TabSelectType':
      return <TabSelectType key={'TabSelectType'} />;
    case 'TabCatalog':
      return <TabCatalog key={'TabCatalog'} />;
    case 'TabParameters':
      return <TabParameters key={'TabParameters'} />;
    case 'TabConstructor':
      return <TabConstructor key={'TabConstructor'} />;
    default:
      return <></>;
  }
};
