import { ESampleType } from '~/screens/QuizScreen/stores/quizStore';

export const header = 'Конструктор квизов';

export const subheader =
  'Квиз – это викторина или опрос, который поможет определить потребности клиента и предоставит вам горячий контакт';

export const contentTitles = [
  {
    title: 'Какой квиз вы хотите создать?',
    subtitle: 'Выберите подходящий тип квиза для вашей цели',
  },
  {
    title: 'Выберите шаблон',
    subtitle: 'Подберите подходящую тему шаблона или создайте свой опрос с нуля',
  },
  {
    title: 'Соберите квиз',
    subtitle: 'Для создания и редактирования квиза вам нужно настроить обложку, вопросы, сбор контактов и результат',
  },
  {
    title: ' Как вы хотите видеть квиз',
    subtitle: 'Квиз отображается посетителю во всплывающем окне. Выберите страницы сайта для отображения',
  },
];

export const quizTypes = [
  {
    img: '/assets/images/quiz/quizTypes/quiz.png',
    name: 'Викторина',
    description: 'Шаблоны квизов с получением приза за правильные ответы',
    type: ESampleType.quiz,
  },
  {
    img: '/assets/images/quiz/quizTypes/survey.png',
    name: 'Квиз-опрос',
    description: 'Шаблоны квизов с получением приза за правильные ответы',
    type: ESampleType.survey,
  },
  {
    img: '/assets/images/quiz/quizTypes/custom.png',
    name: 'Квиз на заказ',
    description: 'Оставьте заявку и мы все сделаем за вас!',
    type: ESampleType.custom,
  },
];
