import React from 'react';
import { ErrorsList } from '~/components/ui/ErrorsList';
import { IGetError, TErrorResult } from '~/utils/getError/types';

export const getError = ({ error, defaultError = 'Что-то пошло не так', unknownError }: IGetError): TErrorResult => {

  const errorText = error?.response?.data?.errorData?.errorText;

  if (!errorText) {
    return unknownError || 'Неизвестная ошибка';
  }

  if (errorText?.includes('Ошибка сервера')) {
    return errorText;
  }

  const uuidErrorRegexp = /: [a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/i;

  const checkOnUuidError = errorText.match(uuidErrorRegexp);

  const errors = checkOnUuidError ? errorText?.replace(uuidErrorRegexp, '') : errorText;

  const replace = /\{(.*)\}/i;

  const checkOnReplace = errors.match(replace);

  if (checkOnReplace && checkOnReplace.length > 0) {
    return errors;
  }

  const errorsArr = errors.split(';');

  return errorsArr?.length > 1 ? <ErrorsList errors={errorsArr} /> : errors || defaultError || '';

};

export const getErrorType = (error: any) => error?.response?.data?.errorData?.errorType || '';
