import { observer } from 'mobx-react';
import React from 'react';
import { AuthorizationEmail } from './AuthorizationEmail';
import { EmailTopic } from './EmailTopic';
import { SendInterval } from './SendInterval';
import { SenderName } from './SenderName';
import styles from './style.module.css';

export const TabParameters = observer(() => {

  return (
    <div className={styles.tabSend}>
      <AuthorizationEmail />
      <SenderName />
      <EmailTopic />
      <SendInterval />
    </div>
  );
});
