import React from 'react';
import { AmoCRMSettings } from '~/screens/LeadsSettingsScreen/amo';
import { BitrixSettings } from '~/screens/LeadsSettingsScreen/bitrix';
import { MacroCRMSettings } from '~/screens/LeadsSettingsScreen/macroCRM';
import { SberCRMSettings } from '~/screens/LeadsSettingsScreen/sberCRM';
import { TelegramSettings } from '~/screens/LeadsSettingsScreen/telegram/TelegramSettings';

export const leadsInstructionlist = [
  'Откройте настройки интеграции со Сберлид',
  'В личном кабинете amoCRM на панели слева нажмите amoМаркет',
  'После перехода в amoМаркет нажмите Установленные',
  'Нажмите на интеграцию со Сберлид',
  'Нажмите «Редактировать»',
  'Нажмите «Удалить интеграцию»',
];

export const leadsSettingsMenuItems = [
  {
    key: 0,
    name: 'Telegram',
    icon: '/assets/images/leads/settings/telegram.jpg',
    active: true,
  },
  {
    key: 1,
    icon: '/assets/images/leads/settings/bitrix24.jpg',
    active: false,
    uiSwitch: 'LeadSettings#Bitrix',
  },
  {
    key: 2,
    icon: '/assets/images/leads/settings/sberCRM.jpg',
    active: false,
    uiSwitch: 'LeadSettings#SberCRM',
  },
  {
    key: 3,
    icon: '/assets/images/leads/settings/macroCRM.jpg',
    active: false,
    uiSwitch: 'LeadSettings#MacroCRM',
    right: 'USE_MACROCRM',
  },
  {
    key: 4,
    icon: '/assets/images/leads/settings/amoCRM.svg',
    active: false,
    uiSwitch: 'LeadSettings#AmoCRM',
  },
];

export const leadSettingsMenuContent = [
  <TelegramSettings key={leadsSettingsMenuItems[0].key} />,
  <BitrixSettings key={leadsSettingsMenuItems[1].key} />,
  <SberCRMSettings key={leadsSettingsMenuItems[2].key} />,
  <MacroCRMSettings key={leadsSettingsMenuItems[3].key} />,
  <AmoCRMSettings key={leadsSettingsMenuItems[4].key} />,
];
