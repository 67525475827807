import React from 'react';
import { Link } from 'react-router-dom';
import { TConsentToMarketing } from '~/api/profile';
import Checkbox from '~/components/ui/CheckBox';
import { useIsSBSHook } from '~/customHooks/useIsSbsHook';
import './style.css';

interface IConsentToMarketingProps {
  consentToMarketing: TConsentToMarketing;
  setConsentToMarketing: (checked: TConsentToMarketing) => void;
}

const ConsentToMarketing: React.FC<IConsentToMarketingProps> = ({
  consentToMarketing,
  setConsentToMarketing,
}) => {
  const hasTotField = 'tot' in consentToMarketing;
  const hasSbsField = 'sbs' in consentToMarketing;

  const isSBS = useIsSBSHook();

  const marketingInfoLink = isSBS ? '/assets/doc/Согласие на получение рекламы ПАО Сбербанк.pdf' : '/assets/doc/sber_marketing.pdf';

  return (
    <div className="consent-to-marketing-wrap">
      <Checkbox
        size="small"
        checked={consentToMarketing.sber}
        color="grey"
        onCheck={checked =>
          setConsentToMarketing({ ...consentToMarketing, sber: checked })
        }
        className=""
        label={(
          <div className="screens-auth-setpassword-consentmarketing__label">
            Согласен получать маркетинговую информацию о продуктах&nbsp;
            <Link
              target="_blank"
              to={marketingInfoLink}
              className="screens-auth-setpassword-consentmarketing__link"
            >
              ПАО Сбербанк
            </Link>
          </div>
        )}
      />
      {hasTotField && (
        <Checkbox
        size="small"
        checked={'tot' in consentToMarketing ? consentToMarketing.tot : false}
        color="grey"
        onCheck={checked =>
          setConsentToMarketing({ ...consentToMarketing, tot: checked })
        }
        label={(
          <div className="screens-auth-setpassword-consentmarketing__label">
            Согласен получать маркетинговую информацию о продуктах&nbsp;
            <Link
              target="_blank"
              to="/assets/doc/tot_marketing.pdf"
              className="screens-auth-setpassword-consentmarketing__link"
            >
              ООО ТОТ
            </Link>
          </div>
        )}
      />
      )}
      {hasSbsField && (
        <Checkbox
          size="small"
          checked={'sbs' in consentToMarketing ? consentToMarketing.sbs : false}
          color="grey"
          onCheck={checked =>
            setConsentToMarketing({ ...consentToMarketing, sbs: checked })
          }
          label={(
            <div className="screens-auth-setpassword-consentmarketing__label">
              Согласен получать маркетинговую информацию о продуктах
              <Link
                target="_blank"
                to="/assets/doc/Согласие на получение рекламы ООО «Сбер Бизнес Софт».pdf"
                className="screens-auth-setpassword-consentmarketing__link"
              >
                 ООО Сбер Бизнес Софт
              </Link>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default ConsentToMarketing;
