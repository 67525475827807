import { observer } from 'mobx-react';
import React from 'react';
import { ChangeStatus } from '~/components/generic/FeedStatisticTable/TableMessages';
import InfoBlockInMessage from '~/components/generic/FeedStatisticTable/TableMessages/BeforeEditMessage/InfoBlockInMessage';
import Message from '~/components/ui/Message';

interface IBeforeEditMessageProps {
  feedStatisticsActiveRow: FeedProStatistics | FeedApiStatistics;
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics
  ) => void;
  isBeforeEditOpen: boolean;
  setIsBeforeEditOpen: ChangeStatus;
}

const BeforeEditMessage: React.FC<IBeforeEditMessageProps> = observer(
  ({
    feedStatisticsActiveRow,
    isBeforeEditOpen,
    setIsBeforeEditOpen,
    onClickActionButton,
  }) => {
    const textBottom = feedStatisticsActiveRow.messageBeforeStopFeed ? (
      <InfoBlockInMessage
        messageBeforeStopFeed={feedStatisticsActiveRow.messageBeforeStopFeed}
      />
    ) : (
      ''
    );

    return (
      <Message
        showIcon={false}
        visible={isBeforeEditOpen}
        title="Хотите отредактировать ленту?"
        textTop="При редактировании запущенной ленты она будет остановлена и не будет показываться на сайте."
        textBottom={textBottom}
        applyText="Остановить и редактировать"
        cancelText="Отмена"
        onClose={() => {
          setIsBeforeEditOpen(false);
        }}
        onApply={async () => {
          onClickActionButton('edit', feedStatisticsActiveRow);
        }}
        isErrorBlock={false}
      />
    );
  }
);

export default BeforeEditMessage;
