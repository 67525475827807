import { observer } from 'mobx-react';
import React from 'react';
import MailingAuthorizationEmailBlock from '~/components/generic/MailingAuthorizationEmailBlock';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';

export const AuthorizationEmail = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };
  const { mailingInstance } = mailingStore;

  const setEmail = (address: string, registered: boolean) => {
    mailingInstance.update({
      authorizationEmail: {
        ...mailingInstance?.get('authorizationEmail'),
        address,
        registered,
      },
    });
  };

  return (
    <MailingAuthorizationEmailBlock
      currentEmailAddress={mailingInstance?.get('authorizationEmail')?.address}
      currentEmailRegistered={
        mailingInstance?.get('authorizationEmail')?.registered
      }
      onSetEmail={setEmail}
    />
  );
});
