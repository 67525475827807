import cx from 'classnames';
import React from 'react';
import './style.css';

interface BadgeProps {
  value?: string;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({
  value = '0',
  className,
}) => {
  const isVisible: boolean = !!Number(value);

  return (
    <div className={cx('badge', {
      _visible: isVisible,
      _point: value === '1',
    }, className)}>
      <span className="badge-value">{Number(value) > 1 && value}</span>
    </div>
  );
};

export default Badge;
