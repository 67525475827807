import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading';
import { Constructor } from '~/components/ui/Constructor';
import Message from '~/components/ui/Message';
import { QuizList } from '~/screens/QuizScreen/components/QuizList';
import { QuizStartScreen } from '~/screens/QuizScreen/components/QuizStartScreen';
import { QuizStatistic } from '~/screens/QuizScreen/components/QuizStatistic';
import { ConstructQuiz } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz';
import { ConstructorSettings } from '~/screens/QuizScreen/components/constructorSteps/ConstructorSettings';
import { SelectQuizType } from '~/screens/QuizScreen/components/constructorSteps/SelectQuizType';
import { SelectSample } from '~/screens/QuizScreen/components/constructorSteps/SelectSample';
import { contentTitles } from '~/screens/QuizScreen/consts';
import {
  QuizStore,
  TQuizConstructorStep,
} from '~/screens/QuizScreen/stores/quizStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

const content = [
  <SelectQuizType key={1} />,
  <SelectSample key={2} />,
  <ConstructQuiz key={3} />,
  <ConstructorSettings key={4} />,
];

export const QuizScreen = observer(() => {
  // @ts-ignore
  const { quizId } = useParams();
  const { authStore, quizStore } = useStores() as {
    authStore: AuthStore;
    quizStore: QuizStore;
  };

  const [isBeforeCloseMessage, setIsBeforeCloseMessage] = useState(false);

  const rightsForQuiz = authStore.currentUser.hasRightTo('LIST_QUIZ');

  const { isListLoading, quizList, error, isRequiredStepsCreated } = quizStore;
  const showNoRightsScreen = !rightsForQuiz && !quizId;
  const showEmptyScreen = quizList.length === 0 && rightsForQuiz && !quizId;
  const showList = quizList.length > 0 && !quizId && rightsForQuiz;

  const setTabValue = (v: string) => {
    quizStore.setQuizConstructorStep(Number(v) as TQuizConstructorStep);
  };

  const onClose = () => {
    quizStore.resetOnClose();
    setIsBeforeCloseMessage(false);
  };

  const onPreClose = () => {
    setIsBeforeCloseMessage(true);
  };

  const quizConstructorStep = quizStore.quizConstructorStep - 1;

  const showStartScreen = showNoRightsScreen || showEmptyScreen;

  useEffect(() => {
    (async () => {
      await quizStore.fetchQuizList();
      await quizStore.fetchQuizTemplates();
    })();
  }, []);

  if (isListLoading) {
    return <Loading status={'Loading'} onClick={() => null} />;
  }

  if (quizId) {
    return <QuizStatistic quizId={quizId} />;
  }

  const isNextButtonDisabled = !isRequiredStepsCreated;

  const tabs = [
    {
      key: '1',
      title: '1. Определите тип',
      disabled: !!quizStore.selectedId,
    },
    {
      key: '2',
      title: '2. Выберите шаблон',
      disabled: !!quizStore.selectedId || (!quizStore.selectedId && quizStore.quizConstructorStep === 1),
    },
    {
      key: '3',
      title: '3. Соберите квиз',
      disabled: !quizStore.selectedId && [1, 2].includes(quizStore.quizConstructorStep),
    },
    {
      key: '4',
      title: '4. Разместите на сайте',
      disabled: isNextButtonDisabled,
    },
  ];

  return (
    <>
      {showStartScreen && <QuizStartScreen />}
      {quizStore.isConstructorOpen && (
        <Constructor
          onClose={onPreClose}
          tabs={tabs}
          onTabsChange={setTabValue}
          tabValue={quizStore.quizConstructorStep.toString()}
          subtitle={contentTitles[quizConstructorStep].subtitle}
          title={contentTitles[quizConstructorStep].title}
          content={content[quizConstructorStep]}
        />
      )}
      {showList && <QuizList />}
      {!!error && (
        <Message
          visible={!!error}
          title={'Ошибка!'}
          textBottom={error}
          onClose={() => quizStore.setError(undefined)}
          onExit={() => quizStore.setError(undefined)}
        />
      )}
      {!!isBeforeCloseMessage && (
        <Message
          visible={!!isBeforeCloseMessage}
          title={'У вас остались несохраненные изменения'}
          textTop={'При продолжении операции изменения и данные не сохранятся.'}
          cancelText={'Отмена'}
          applyText={'Не сохранять'}
          onClose={() => setIsBeforeCloseMessage(false)}
          onExit={() => setIsBeforeCloseMessage(false)}
          onApply={onClose}
        />
      )}
    </>
  );
});
