export const FEED_TYPE_INFO: TFeedInfo[] = [
  {
    type: 'SIMILAR',
    title: 'Похожие товары',
    description:
      'Сократите время до совершения покупки, предложив Вашему клиенту широкий выбор позиций со схожими характеристиками.',
    exampleText:
      'посетитель заинтересовался ноутбуком А, в ленте будет предложено до 30 похожих по характеристикам ноутбуков B, C, D...',
    messageText: null,
  },
  {
    type: 'ALTERNATIVE',
    title: 'Альтернативные товары',
    description:
      'Превратите неуверенного в выборе посетителя в покупателя, предложив товары, которые подойдут для его целей.',
    exampleText:
      'посетитель просматривает ноутбук, в ленте будет предложено до 30 товаров других категорий, но со схожими характеристиками: моноблоки, планшеты, стационарные компьютеры...',
    messageText: null,
  },
  {
    type: 'NEW_PRODUCTS',
    title: 'Новые товары',
    description:
      'Заинтересуйте посетителя товарами, которые недавно появились на сайте. Такая лента рекомендаций даст до +10% к текущей конверсии.',
    exampleText:
      'посетитель просматривает ноутбук, в ленте будет предложено до 30 новых товаров, появившихся в каталоге за последнее время',
    messageText:
      'Лента появится на сайте после появления в каталоге новых товаров. Для определения новинок сравнятся две версии каталога. Создавать отдельный каталог только с новыми товарами не нужно.',
  },
  {
    type: 'CROSS_SELL',
    title: 'Кросс-сейл',
    description:
      'Предложите посетителю товары, напрямую или косвенно дополняющие основную покупку. Это улучшит его клиентский опыт и увеличит средний чек до +35%.',
    exampleText:
      'посетитель просматривает ноутбук, в ленте будет предложено до 30 комплектующих товаров: мышь, коврик для мыши, подставка для ноутбука и др.',
    messageText: null,
  },
  {
    type: 'POPULAR',
    title: 'Популярные товары',
    description:
      'Покажите популярные товары интернет-магазина, рассчитанные на основе статистики поведения посетителей, увеличивайте средний чек.',
    exampleText:
      'посетитель заинтересовался ноутбуком, в ленте будут предложены до 30 популярных товаров интернет-магазина за последнее время.',
    messageText:
      'Лента будет обучаться и улучшаться постепенно, настраиваясь под вашу статистику поведения пользователей',
  },
  {
    type: 'PERSONAL',
    title: 'Персональные товары',
    description:
      'Познакомьте посетителя с подборками товаров, составленных на основе анализа его поведения. Это повысит клиентскую лояльность и сократит время принятия решения о покупке.',
    exampleText:
      'В ленте пользователю будет предложено до 30 товаров, которые могут его заинтересовать. Система анализирует историю поведения пользователей сайта, предыдущие покупки, просмотры и другие данные.',
    messageText:
      'Лента будет обучаться и улучшаться постепенно, настраиваясь под вашу статистику поведения пользователей',
  },
];
