import { ColumnProps } from 'antd/lib/table';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import React from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import Table from '~/components/ui/Table';
import TableCell from '~/components/ui/Table/TableCell';
import ScenarioModel from '~/models/ScenarioModel';
import styles from '~/screens/WheelOfFortune/components/EmptyWheels/style.module.css';
import { getHint } from '~/screens/WheelOfFortune/const';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import style from '~/screens/WheelOfFortune/style.module.css';
import useStores from '~/utils/useStores';

interface IWheelList {
  setIsErrorMessageVisible: (v: boolean) => void;
  setIsScriptErrorMessageVisible: (v: boolean) => void;
}

export const WheelList = observer(
  ({
    setIsErrorMessageVisible,
    setIsScriptErrorMessageVisible,
  }: IWheelList) => {
    const { wheelOfFortuneStore } = useStores() as {
      wheelOfFortuneStore: WheelOfFortuneStore;
    };

    const { statistics, scenarios } = wheelOfFortuneStore || {};

    const rowData = Array.from(scenarios);

    const sortedRowData = rowData.sort(
      (a, b) => Number(b.get('created_date')) - Number(a.get('created_date'))
    );

    const handleClickRow = (data: any) => {
      wheelOfFortuneStore.setSelectedId(data.get('id'));
      wheelOfFortuneStore.setIsConstructorOpen(true);
    };

    const filterStatsByScenarioId = (scenarioId: number) => {
      return statistics.filter(
        statsElement => statsElement.scenarioId === scenarioId
      )[0];
    };

    const getScenarioListColums = (): ColumnProps<ScenarioModel>[] => {
      return [
        {
          className: 'scenarios-screen-list-table__status',
          key: 'status',
          title: 'Статус',
          render: (_, scenario) => {
            let icon: React.ReactNode;
            let title: string;
            let hoverContent: React.ReactNode;

            if (scenario.isActive) {
              title = 'Запущен';
              icon = <Icon name="PlayS" style={{ color: '#00BF6D' }} />;
              hoverContent = (
                <Button
                  icon="PauseS"
                  type="black-outline"
                  loading={scenario.isPausing}
                  disabled={scenario.isPausing}
                  onClick={(e) => {
                    e.stopPropagation();

                    scenario.pause().then(() => {
                      if (scenario.errorWhenChangingStatus) {
                        setIsErrorMessageVisible(true);
                      }
                      wheelOfFortuneStore.fetchScenarios();
                    });
                  }}
                >
                  Остановить
                </Button>
              );
            }

            if (scenario.isPaused) {
              title = 'Остановлен';
              icon = <Icon name="PauseS" style={{ color: '#808080' }} />;
              hoverContent = (
                <Button
                  icon="PlayS"
                  type="black-outline"
                  loading={scenario.isActivating}
                  disabled={scenario.isActivating}
                  onClick={(e) => {
                    e.stopPropagation();

                    scenario.activate().then(() => {
                      wheelOfFortuneStore.fetchScenarios();
                      if (scenario.errorWhenChangingStatus) {
                        setIsErrorMessageVisible(true);
                      }
                      if (scenario.errorWhenCheckScriptException) {
                        setIsScriptErrorMessageVisible(true);
                      }
                    });
                  }}
                >
                  Запустить
                </Button>
              );
            }

            return (
              <TableCell
                icon={icon}
                title={title}
                hoverContent={hoverContent}
              />
            );
          },
        },

        {
          key: 'date',
          title: 'Дата создания',
          render: (_, scenario) => {

            if (!scenario.get('created_date')) return null;

            const date = moment(scenario.get('created_date'), 'x');

            return <TableCell title={date.format('DD.MM.YYYY')} />;
          },
        },
        {
          key: 'name',
          title: 'Название сценария',
          render: (_, scenario) => {
            // @ts-ignore
            const name = scenario.data.trigger.configuration.title;

            return <TableCell title={name} />;
          },
        },

        {
          key: 'shown',
          title: getHint('shown'),
          render: (_, scenario) => {
            const statsElement = filterStatsByScenarioId(scenario.get('id'));

            return <TableCell title={statsElement.widgetShowCount} />;
          },
        },
        {
          key: 'interactions',
          title: getHint('interactions'),
          render: (_, scenario) => {
            const statsElement = filterStatsByScenarioId(scenario.get('id'));

            return <TableCell title={statsElement.widgetIteractionCount} />;
          },
        },
        {
          className: 'scenarios-screen-list-table__action',
          key: 'action',
          title: '',
          render: (_, scenario) => {
            const removeScenario = async (e: any) => {
              e.stopPropagation();
              await scenario.remove();
              await wheelOfFortuneStore.fetchScenarios();
            };

            return (
              <Button
                tag="a"
                type="text"
                loading={scenario.isRemoving}
                onClick={removeScenario}
              >
                Удалить
              </Button>
            );
          },
        },
      ];
    };

    const showWheelConstructor = () => {
      wheelOfFortuneStore.setSelectedId(null);
      wheelOfFortuneStore.setIsConstructorOpen(true);
    };

    return (
      <div className={styles.wheelList}>
        <div className={style.wheel_content_title}>Колесо фортуны</div>
        <Table
          className={style.wheel_list_table}
          columns={getScenarioListColums()}
          rows={sortedRowData}
          rowKey={scenario => scenario.key}
          rowClassName={scenario =>
            `scenarios-screen-list-table__row _id-${scenario.key}`
          }
          rowHover
          onRowClick={handleClickRow}
        />
        <div className={styles.footer}>
          <Button
            onClick={showWheelConstructor}
            icon={'Plus'}
            style={{
              height: 50,
              marginBottom: 16,
            }}
            block
          >
            Создать колесо фортуны
          </Button>
        </div>
      </div>
    );
  }
);
