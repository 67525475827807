import { observer } from 'mobx-react';
import React from 'react';
import Icon from '~/components/Icon';
import { Wheel } from '~/components/generic/Wheel';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import Tooltip from '~/components/ui/Tooltip';
import { giftExample } from '~/screens/WheelOfFortune/data';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import style from './style.module.css';

export const Gifts: React.FC = observer(() => {
  const { wheelOfFortuneStore } = useStores() as {
    wheelOfFortuneStore: WheelOfFortuneStore;
  };
  const {
    wheelOfFortune,
    inputGifts,
    addEmptySector,
    changeSectorName,
    changePromoCode,
    closeEmptySector,
    getSectors,
  } = wheelOfFortuneStore;

  const start = false;
  const winSectorNumber = 4;
  const isMobile = false;
  const radius = 150;

  const wheelProps = {
    radius,
    start,
    winSectorNumber,
    onAnimationEnd: () => console.log('animation End'),
  };

  const arrowColor = wheelOfFortune.get('arrowColor');

  const staticPlaceholder = (
    <div className={style.staticPlaceholder}>
      <Tooltip
        placement="bottom"
        title={(
          <div className={style.placeHolderText}>
            Укажите ваш код на подарок, это может быть промокод, купон, кодовое слово.{' '}
            Если приз без кода, то оставляйте поле пустым
          </div>
        )}
      >
        <div>
          <Icon name="Question" className={style.hintIcon} />
        </div>
      </Tooltip>
    </div>
  );

  return (
    <div className={style.wrapper}>
      <div className={style.wrapper_title}>
        Добавьте подарки для секторов колеса
      </div>
      <div className={style.wrapper_subtitle}>
        Наполните секторы колеса фортуны вашими подарками, необязательно все
        делать призовыми
      </div>
      <div className={style.giftsBodyWrapper}>
        <div className={style.frameWrapper}>
          <div className={style.frame}>
            <div className={style.box_container}>
              <div className={style.box}>
                <Wheel
                  sectors={getSectors()}
                  animationProps={wheelProps}
                  radius={radius}
                  isMobile={isMobile}
                  arrowColor={arrowColor}
                />
              </div>
            </div>
          </div>
          <div className={style.gift_fields_wrapper}>
            <div className={style.gift_fields_wrapper_title}>
              Заполните поля подарков и они появятся в вашем колесе фортуны
            </div>
            <div className={style.gift_fields_wrapper_subtitle}>
              Минимальное количество подарков: 2, максимум: 8
            </div>
            <div className={style.gift_fields_wrapper_forms}>
              {inputGifts.map((sector, index) => {
                return (
                  <div className={style.gift_fields_wrapper_forms_row} key={index}>
                    <Input
                      style={{ marginRight: 8, flex: 2 }}
                      onChange={event => changeSectorName(event, index)}
                      value={sector.name}
                      placeholder="Название подарка"
                      maxLength={30}
                    />
                    <Input
                      style={{ marginRight: 8, flex: 1 }}
                      onChange={event => changePromoCode(event, index)}
                      value={sector.coupon}
                      placeholder="Код на подарок"
                      elemAfter={staticPlaceholder}
                      maxLength={12}
                    />
                    <div className={style.close_sector}>
                      {index >= 2 && (
                        <Icon
                          name="Trash"
                          style={{
                            color: '#999',
                          }}
                          onClick={() => {
                            closeEmptySector(index);
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              {inputGifts.length <= 7 && (
                <Button
                  type="default"
                  icon={'Plus'}
                  onClick={addEmptySector}
                  disabled={inputGifts.length <= 2 && inputGifts.length >= 7}
                >
                  Добавить подарок
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={style.exampleBox}>
          <div className={style.wrapper_example_box}>
            <div className={style.wrapper_example_image}>
              <Icon name={'Bow'} style={{ width: 112, height: 26 }} />
            </div>
            <div className={style.wrapper_example_content}>
              <div className={style.wrapper_example_content_title}>
                Популярные примеры подарков
              </div>
              <div className={style.wrapper_example_content_items}>
                {giftExample.map((item, index) => {
                  return (
                    <div className={style.wrapper_example_content_item} key={index}>{item}</div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
