import React, { useState } from 'react';
import { FloatTableRow } from '~/components/generic/FloatTable/FloatTableRow';

export const FloatTable = ({ rows, contextMenu, onRowClick }: IFloatTable) => {
  const [xPosition, setXPosition] = useState<number | undefined>(0);
  const [maxShift, setMaxShift] = useState<number | undefined>(undefined);

  const setPosition = (position: number): void => {
    if (position > 0) {
      setXPosition(50);
      setTimeout(() => setXPosition(0), 500);

      return;
    }

    if (Math.abs(position) > Math.abs(maxShift)) {
      setXPosition(position - 50);
      setTimeout(() => setXPosition(-maxShift + 150), 500);

      return;
    }

    setXPosition(position);
  };

  return (
    <>
      {rows.map((v: IFloatTableRow, key: number) => {
        const finalContextMenu =
          typeof contextMenu === 'function' ? contextMenu(v) : contextMenu;

        return (
          <FloatTableRow
            key={key}
            {...v}
            xPosition={xPosition}
            setXPosition={setPosition}
            maxShift={maxShift}
            setMaxShift={setMaxShift}
            contextMenu={finalContextMenu}
            onRowClick={() => onRowClick(v.id)}
          />
        );
      })}
    </>
  );
};
