import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from './client';

export type UsersListResponse = IResponse<IUser[]>;
export type UserResponse = IResponse<[IUser]>;
export type MessageResponse = IResponse<[IMessage]>;

const Users = {
  list: ({ pagingCount, pagingFrom }: IListUsers): AxiosPromise<UsersListResponse> =>
    actionGet('/admin/users', {
      pagingCount,
      pagingFrom,
    }),

  profile: (): AxiosPromise<UserResponse> =>
    actionGet('/auth/profile', null),

  get: (id: number): AxiosPromise<UserResponse> =>
    actionGet('/admin/users', { id }),

  create: (user: IUser): AxiosPromise<UserResponse> =>
    actionSet('/admin/users', {}, user),

  update: (id: number, user: IUser): AxiosPromise<UserResponse> =>
    actionSet('/admin/users', { id }, user),

  lookup: (login: string): AxiosPromise<UserResponse> =>
    actionGet('/auth/users/lookup', { login }),
};

export default Users;
