import cx from 'classnames';
import React, { useEffect, useState, MutableRefObject } from 'react';
import InputWrapper, { InputWrapperProps } from '~/components/ui/InputWrapper';
import './style.css';

export type TextAreaProps = InputWrapperProps & {
  autoFocus?: boolean;
  name?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void | PromiseLike<any>;
  onChangeValue?: (value: string) => void | PromiseLike<any>;
  disabled?: boolean;
  ref?: React.Ref<HTMLTextAreaElement>;
  maxLength?: number;
  rows?: number;
  autoSize?: boolean;
};

const TextArea: React.FC<TextAreaProps> = React.forwardRef((props, ref) => {
  const {
    autoFocus,
    name,
    value,
    defaultValue,
    className,
    style,
    onChange,
    onChangeValue,
    block,
    placeholder,
    disabled,
    failure,
    success,
    maxLength,
    rows,
    autoSize,
  } = props;

  const [active, setActive] = useState(false);

  const handleFocus = () => setActive(true);

  const handleBlur = () => setActive(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const el = e.target;

    if (autoSize) {
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    }

    onChange && onChange(e);

    const newValue = e.currentTarget.value;

    if (value !== newValue) {
      onChangeValue && onChangeValue(newValue);
    }
  };

  useEffect(() => {
    if (autoSize && ref) {
      const el = (ref as MutableRefObject<HTMLTextAreaElement>).current;

      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    }
  }, []);

  return (
    <InputWrapper
      className={cx('textarea', className)}
      withoutPadding
      style={style}
      block={block}
      active={active}
      disabled={disabled}
      failure={failure}
      success={success}
    >
      <textarea
        ref={ref}
        name={name}
        autoFocus={autoFocus}
        maxLength={maxLength}
        className="textarea__element"
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={handleFocus}
        rows={rows}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    </InputWrapper>
  );
});

export default React.memo(TextArea);
