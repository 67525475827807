import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface KeyName {
  name: string;
  key: ILabelPosition;
}

interface ItemsLabelPositionProps {
  onClick: (key: ILabelPosition) => void;
  activePosition?: ILabelPosition;
  className?: string;
}

const ItemLabelPosition: React.FC<ItemsLabelPositionProps> = ({
  onClick,
  activePosition = '',
  className = null,
}) => {
  const items: KeyName[] = [
    { name: 'LabelPositionTopLeft', key: 'top-left' },
    { name: 'LabelPositionBottomLeft', key: 'bottom-left' },
    { name: 'LabelPositionTopRight', key: 'top-right' },
    { name: 'LabelPositionBottomRight', key: 'bottom-right' },
  ];

  return (
    <div
      className={`ui-notification-editor-item-position__block ${
        className !== null ? className : ''
      }`}
    >
      {items.map((item) => {
        return (
          <div
            key={`${item.name}_${item.key}`}
            className="ui-notification-editor-item-position__item-wrapper"
            onClick={() => onClick(item.key)}
          >
            <Icon
              name={item.name}
              className={cx('ui-notification-editor-item-position__item', {
                _not_selected: item.key !== activePosition,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ItemLabelPosition;
