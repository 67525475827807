import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

type onClickArgument = any;

const ListItemButton: React.FC<{
  onClickArgument?: onClickArgument;
  onClick?: (argument: onClickArgument) => void;
  className?: string;
  title?: string;
  subtitle?: string;
  icon?: string;
  isDisabled?: boolean;
}> = ({
  onClickArgument = '',
  onClick = null,
  className = '',
  title = '',
  subtitle = '',
  icon = '',
  isDisabled = false,
}) => {
  const handleClick = () => {
    !isDisabled && onClick && onClick(onClickArgument);
  };

  return (
    <div
      className={`list_item_button__container ${className} ${
        isDisabled ? 'list_item_button__isDisabled' : ''
      }`}
      onClick={handleClick}
    >
      <div className="list_item_button__text_wrapper">
        {!!title && <div className="list_item_button__title">{title}</div>}
        {!!subtitle && (
          <div className="list_item_button__subtitle">{subtitle}</div>
        )}
      </div>
      {!!icon && (
        <div className="list_item_button__icon">
          <Icon name={icon} />
        </div>
      )}
    </div>
  );
};

export default ListItemButton;
