import { action, computed, observable } from 'mobx';
import Store from '~/stores/store';

export interface ISbbIDLoginData {
  state: string;
  nonce: string;
  code: string;
  location: string;
}

export class FeedStore extends Store {
  @observable isConstructorOpen = false; // Состояние конструктора лент
  @observable isShowExceedingLimitMessage: boolean = false; // Статус сообщения об превышении лимита
  @observable recommendationTypes: FeedRecType[] = [] as FeedRecType[]; // Список доступных типов
  @observable isMessageBeforeExitingOpen = false; // Статус сообщения при выходе из конструктора
  @observable feedLimit: number = 0; // Максимальное количество лент
  @observable fetchingStatisticsError: boolean = false; // Статус ошибки при запросе статистики лент
  @observable isFetchingStatistics = true; // Статус запроса статистики лент
  @observable isBeforeEditOpen = false; // Статус сообщения об остановке перед редактированием
  @observable isShowWaitMessage: boolean = false; // Статус сообщения об ожидании проверки каталога
  @observable isFetchingRequest = false; // Статус обработки запроса
  @observable isStoppingDialogOpen = false; // Состояние диалога про остановку ленты
  @observable isRemoveDialogOpen = false; // Статус диалога удаления ленты
  @observable isOpenRenameDialog: boolean = false; // Статус диалога переименования ленты
  @observable isInfoOpen: boolean = false; // Статус блока с информацией о ленте
  @observable feedAmount: number = 0; // Количество созданных лент
  @observable isOpenFinalDialog: boolean = false; // Статус финального диалога
  @observable isShowTabooTypeChangesMessage: boolean = false; // Статус сообщения изменения типа

  /**
   * Управляет состоянием конструктора
   */
  @action
  setIsConstructorOpen = (status: boolean) => {
    this.isConstructorOpen = status;
  }

  /**
   * Управляет состоянием сообщения о превышении лимита
   * @param status
   */
  @action
  setIsShowExceedingLimitMessage = (status: boolean) => {
    this.isShowExceedingLimitMessage = status;
  }

  /**
   * Наполняет рекомендации
   * @param types
   */
  @action
  setRecommendationTypes = (types: FeedRecType[]) => {
    this.recommendationTypes = types;
  }

  /**
   * Выход из режима редактирования
   */
  @action
  setIsMessageBeforeExitingOpen = (status: boolean) => {
    this.isMessageBeforeExitingOpen = status;
  }

  /**
   * Устанавливает лимит
   * @param feedLimit
   */
  @action
  setFeedLimit = (limit: number) => {
    this.feedLimit = limit;
  }

  /**
   * Управляет состоянием ошибки загрузки статистики
   * @param status
   */
  @action
  setFetchingStatisticsError = (status: boolean) => {
    this.fetchingStatisticsError = status;
  }

  /**
   * Управляет состоянием загрузки статистики
   * @param status
   */
  @action
  setFetchingStatistics = (status: boolean) => {
    this.isFetchingStatistics = status;
  }

  @action
  setIsBeforeEditOpen = (isOpen: boolean) => {
    this.isBeforeEditOpen = isOpen;
  }

  @action
  setIsShowWaitMessage = (status: boolean) => {
    this.isShowWaitMessage = status;
  }

  @action
  setIsFetchingRequest = (status: boolean) => {
    this.isFetchingRequest = status;
  }

  @action
  setIsStoppingDialogOpen = (status: boolean) => {
    this.isStoppingDialogOpen = status;
  }

  @action
  setIsRemoveDialogOpen = (status: boolean) => {
    this.isRemoveDialogOpen = status;
  }

  @action
  setIsOpenRenameDialog = (status: boolean) => {
    this.isOpenRenameDialog = status;
  }

  /**
   * Управляет состоянием инфо блока
   */
  @action
  setIsInfoOpen = (isOpen: boolean) => {
    this.isInfoOpen = isOpen;
  }

  /**
   * Управляет количеством созданных лент
   */
  @action
  setFeedAmount = (amount: number) => {
    this.feedAmount = amount;
  }

  /**
   * Управляет состоянием диалога о сохранении
   */
  @action
  setIsOpenFinalDialog = (isOpen: boolean) => {
    this.isOpenFinalDialog = isOpen;
  }

  /**
   * Управляет всплытием диалога о сохранении
   */
  @action
  blinkFinalDialog = (delayTimeMs: number = 3000) => {
    this.setIsOpenFinalDialog(true);
    setTimeout(() => this.setIsOpenFinalDialog(false), delayTimeMs);
  }

  /**
   * Управляет всплытием сообщения при изменении типа
   */
  @action
  setIsShowTabooTypeChangesMessage = (status: boolean) => {
    this.isShowTabooTypeChangesMessage = status;
  }


  // Вычисления
  
  @computed
  get hasPermissionToCreateFeed() {
    return this.feedAmount < this.feedLimit;
  }
}

export default new FeedStore();
