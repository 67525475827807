import { action, observable } from 'mobx';
import Leads, { SortOrder } from '~/api/leads';
import LeadSettingsModel from '~/models/LeadSettingsModel';
import Store from '~/stores/store';

const leadSettingsInitialState: ILeadSettingsModel = {
  isTelegramLoading: false,
  isTelegramSet: false,
  telegramError: '',
  telegramUrl: '',

  isBitrixLoading: false,
  isBitrixSet: false,
  bitrixError: '',
  bitrixUrl: '',
  bitrixUrlError: '',

  isSberCRMLoading: false,
  isSberCRMSet: false,
  sberCRMError: '',
  sberCRMUrl: '',
  sberCRMUrlError: '',

  isMacroCRMLoading: false,
  isMacroCRMSet: false,
  macroCRMError: '',
  macroCRMUrl: '',
  macroCRMUrlError: '',

  isAmoCRMLoading: false,
  isAmoCRMSet: false,
  amoCRMError: '',
  amoCRMUrl: '',
  amoCRMToken: '',
  amoCRMUrlError: '',
  amoCRMTokenError: '',
};

export class LeadsStore extends Store {
  @observable isFetching = false;
  @observable leadsData: LeadsPayload;
  @observable sortDirection: SortOrder = SortOrder.DESC;
  @observable leadSettings: LeadSettingsModel = new LeadSettingsModel(this, leadSettingsInitialState);

  @action
  fetchLeads = async (page: number = 1, sort?: SortOrder) => {
    this.isFetching = true;

    if (sort) {
      this.sortDirection = sort;
    }

    try {
      const response = await Leads.fetchLeads(page, this.sortDirection);
      this.leadsData = response.data.payload[0];
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @action
  removeLead = async (id: string) => {
    try {
      await Leads.removeLead(id);
      await this.fetchLeads(1);
    } catch (e) {
      throw e;
    }
  }
}

export default new LeadsStore();
