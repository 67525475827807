import { Alert, Divider, Result, Spin, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Button from '~/components/ui/Button';
import { sbbLoginDataCollection } from '~/screens/SbbIDLoginScreen/sbbLoginDataCollection';
import { AuthStore } from '~/stores/authStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import './style.css';

type Props = {
  authStore?: AuthStore;
} & RouteComponentProps<{
  redirect?: string;
}>;

@inject('authStore')
@observer
class SbbIDLoginScreen extends Component<Props> {
  componentDidMount() {
    this.checkAuth();
    if (window.location.pathname !== '/auth/sbbid/ios') {
      this.handleSbbIDOauth();
    }
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  getHrefAttributes = () => {
    const hrefAttrsArr = window.location.href.split('?')[1].split('&');
    const hrefAttributes: any = {};

    hrefAttrsArr.forEach((attrStr: string) => {
      const attrArr = attrStr.split('=');
      hrefAttributes[attrArr[0]] = attrArr[1];
    });

    return hrefAttributes;
  }

  checkAuth = async () => {
    const { authStore, history, location } = this.props;
    const { pathname, search } = location;

    if (pathname === '/auth/sbbid/ios') {
      const iosDeeplink = `sberclickstream://${qs.parse(search)}`;
      window.location.href = iosDeeplink;
    } else {
      if (authStore.isAuthorized) {
        history.push(firstPageAfterAuthorization(authStore.clientState, authStore.currentUser.rightsModel));
      }
    }
  }

  handleSbbIDOauth = async () => {
    const { authStore } = this.props;
    const sbbIDLoginData = sbbLoginDataCollection();
    try {
      if (sbbIDLoginData.state && sbbIDLoginData.nonce && sbbIDLoginData.code) {
        authStore.storeSbbIDData(sbbIDLoginData);
        await authStore.loginWithSberID();
      } else {
        throw new Error('');
      }
    } catch (e) {
      console.error(`${e.name}: ${e.message}`);
    }
  }

  renderError = () => {
    const { authStore } = this.props;
    const { loggingErrorResponse } = authStore;

    if (!loggingErrorResponse) return null;

    const { errorData } = loggingErrorResponse;

    let message: React.ReactNode = loggingErrorResponse.errorData.errorText;

    if (errorData) {
      message = errorData.errorText;
    }

    return (
      <Alert
        message={message}
        type="error"
        showIcon
        style={{ marginBottom: 24 }}
      />
    );
  }

  render() {
    const { authStore } = this.props;
    const { isLoggingIn } = authStore;

    return (
      <div className="sbbid-login-screen">
        <Typography.Title
          className="sbbid-login-screen__title"
          level={3}
        >
          Вход по Sberbank Business ID
        </Typography.Title>

        <Divider />

        {this.renderError()}

        {isLoggingIn ? (
          <div className="login-screen__spinner-container">
            <Spin
              size="large"
              spinning={isLoggingIn}
            />
          </div>
        ) : (
            <Result
              status="error" />
          )
        }

        <Button
          tag="button"
          loading={isLoggingIn}
          block
          onClick={this.handleSbbIDOauth}
          type="sber"
          icon={!isLoggingIn && 'LogoWhite'}
        >
          {isLoggingIn ? 'Загрузка...' : 'Попробовать ещё'}
        </Button>
      </div>
    );
  }
}

export default SbbIDLoginScreen;
