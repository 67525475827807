import cx from 'classnames';
import React, { Component } from 'react';
import './style.css';

interface Props {
  visible?: boolean;
  aside?: React.ReactNode;
}

class Warning extends Component<Props> {
  static Demo: React.FC<{ visible: boolean }> = ({ visible }) => (
    <Warning visible={visible}>
      Вы находитесь в демо-режиме. Для того, чтобы начать собирать данные со своего сайта, необходимо добавить сайт.
    </Warning>
  )

  render() {
    const { aside, children, visible } = this.props;

    return (
      <div className={cx('warning', { _visible: visible })}>
        <div className="warning__message">{children}</div>
        <div className="warning__aside">{aside}</div>
      </div>
    );
  }
}

export default Warning;
