import cx from 'classnames';
import React from 'react';
import styles from './style.module.css';

export const FloatTableLabel = ({ name, status, className, onClick }: IFloatTableRowLabel) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <span
      className={cx(styles.label, styles[status], className)}
      onClick={handleClick}
    >
      {name}
    </span>
  );
};
