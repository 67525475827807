import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { loginUrl } from '~/constants';
import '~/screens/Auth/style.css';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import useStores from '~/utils/useStores';
import { validatePassword } from '~/utils/validatePassword';
import AdditionalHints from './AdditionalHints';

const SetPasswordScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const history = useHistory();

  const { handleSubmit, register } = useForm();

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  useEffect(() => {
    if (authStore.isAuthorized) {
      history.push(
        firstPageAfterAuthorization(
          authStore.clientState,
          authStore.currentUser.rightsModel
        )
      );
    } else {
      if (authStore.registrationStep !== 2) {
        history.push(loginUrl);
      }
    }
  }, [authStore.isAuthorized, authStore.registrationStep]);

  const onSubmit = async (data: any) => {
    const validationResult = validatePassword(data);

    !validationResult.result &&
      authStore.setPasswordError(validationResult.error);

    authStore.isRegistration
      ? await authStore.sendPassword({
        password: data.newPassword,
      })
      : await authStore.sendForgotPassword(data.newPassword);
  };

  const isButtonLoading = authStore?.isFetchingOnRegistration;
  const validationResult = validatePassword({ newPassword, newPasswordRepeat });
  const isButtonDisabled = isButtonLoading || !validationResult.result;
  const title = authStore?.isRecovery
    ? 'Установите новый пароль'
    : 'Установите пароль';

  return (
    <Screen className="auth-screen">
      <div className="auth-screen-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={cx('auth-screen-form__title', '_isRegistration')}>
            {title}
          </div>

          <div className="auth-screen-form__row">
            <Input
              name="newPassword"
              onChangeValue={(v) => {
                setNewPassword(v);
                authStore.setPasswordError('');
              }}
              style={{ height: 60 }}
              placeholder="Введите пароль"
              ref={register()}
              size="large"
              type="password"
              failure={!!authStore.passwordError}
              block
              withEye
            />
          </div>

          <div className="auth-screen-form__row">
            <Input
              name="newPasswordRepeat"
              onChangeValue={(v) => {
                setNewPasswordRepeat(v);
                authStore.setPasswordError('');
              }}
              style={{ height: 60 }}
              ref={register()}
              placeholder="Повторите пароль"
              size="large"
              type="password"
              failure={!!authStore.passwordError}
              block
              withEye
            />
          </div>

          <div className="auth-screen-form__row">
            <Button
              loading={isButtonLoading}
              size="x-large"
              type="main"
              tag="button"
              htmlType="submit"
              disabled={isButtonDisabled}
              block
            >
              Установить пароль
            </Button>
          </div>

          <div className="auth-screen-form__error">
            {validationResult.error || authStore?.passwordError}
          </div>
        </form>

        <div className="auth-screen-form__row">
          <AdditionalHints />
        </div>
      </div>
    </Screen>
  );
});

export default React.memo(SetPasswordScreen);
