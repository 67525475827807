import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Tabs from '~/components/ui/Menu/Tabs';
import Screen from '~/screens/Screen';
import { scrollReaction } from '~/utils/scrollReaction';
import './style.css';

const items = [
  { key: '1', title: 'Как вставить код, если сайт на Tilda', scrollToId: 'tilda-install' },
  { key: '2', title: '1. Пройдите по ссылке', scrollToId: 'link' },
  { key: '3', title: '2. Зайдите в административную панель', scrollToId: 'admin' },
  { key: '4', title: '3. Пройдите в раздел “Еще”', scrollToId: 'else' },
  { key: '5', title: '4. Скопируйте код', scrollToId: 'copy-code' },
  { key: '6', title: '5. Вставьте код', scrollToId: 'put-code' },
  { key: '7', title: '6. Переопубликуйте страницы сайта', scrollToId: 'public' },
  { key: '8', title: '7. Проверьте подключение', scrollToId: 'check-install' },
  { key: '9', title: '8. «Счётчик успешно установлен!»', scrollToId: 'install-success' },
  { key: '10', title: '«Счётчик не установлен»', scrollToId: 'install-failed' },
  { key: '11', title: '9. Поддержка вашей CMS', scrollToId: 'support' },
];

const InstallGuideCMSScreen: React.FC = observer(() => {
  const [copied, setCopied] = useState<boolean>(false);
  const [value, changeValue] = useState<string>('1');

  const reaction = () => {
    scrollReaction(items, changeValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(reaction, 50));
    
    return () => {
      window.removeEventListener('scroll', reaction);
    };
  }, [value]);

  return (
    <Screen className="guide-html-screen cms">
      <div className="guide-html__container">
        <Button type="text" icon="ArrowMLeft" size="x-small" to="/guide/himself/cms">
          Назад
        </Button>
        <h1 className="guide-html__title">Установка кода через конструктор сайта или CMS</h1>
        <p className="guide-html-list__item-txt">
          Инструкция по установке кода счетчика «СберЛид» на ваш сайт, если он создан на одной из CMS
          (Content Management System) — программа для управления содержимым сайта. С ее помощью можно загружать
          на сайт и удалять с него тексты, изображения или видео, не имея навыков программирования.
        </p>
        <h2 className="guide-html__title" id={items[0].scrollToId}>Как вставить код, если сайт на Tilda</h2>
        <h3 className="guide-html-list__item-title" id={items[1].scrollToId}>
          1. Пройдите по ссылке
          <Link
            className="guide-link"
            to="https://tilda.cc/ru/">https://tilda.cc/ru/
          </Link> и войдите в свою систему.
        </h3>
        <div className="install-cms__image-1 image" />
        <h2 className="guide-html__subtitle" id={items[2].scrollToId}>2. Зайдите в административную панель Tilda </h2>
        <p className="guide-html__txt">Перейдите в раздел «Мои сайты» и выберите нужный проект (если их несколько).
          На странице проекта нажмите кнопку “Настройки сайта”.</p>
        <div className="install-cms__image-2 image" />
        <h2 className="guide-html__subtitle" id={items[3].scrollToId}>3. Пройдите в раздел «Еще» </h2>
        <p className="guide-html__txt">
          Найдите в разделе заголовок “HTML-код для вставки внутрь HEAD” и нажмите «Редактировать код».
        </p>
        <div className="install-cms__image-3 image" />
        <h2 className="guide-html__subtitle" id={items[4].scrollToId}>4. Скопируйте код</h2>
        <p className="guide-html__txt">Скопируйте код из личного кабинета «СберЛид». </p>
        <div className="guide-html-code-area">
          <CodeToCopy onCopy={() => setCopied(copied)} />
        </div>
        <h2 className="guide-html__subtitle" id={items[5].scrollToId}>5. Вставьте код</h2>
        <p className="guide-html__txt">
          Вставьте код сразу после открытия тега &lt;body&gt;. Желательно расположить код как можно ближе к
          началу страницы (после тега &lt;head&gt;), нажмите сохранить.
        </p>
        <div className="install-cms__image-4 image" />
        <h2 className="guide-html__subtitle" id={items[6].scrollToId}>6. Переопубликуйте все страницы сайта</h2>
        <div className="install-cms__image-5 image" />
        <h2 className="guide-html__subtitle" id={items[7].scrollToId}>7. Проверьте подключение</h2>
        <p className="guide-html__txt">
          После установки кода счетчика вернитесь на начальный экран «СберЛид» и кликните на
          кнопку «Проверить код»
        </p>
        <CheckCodeButton />
        <h2 className="guide-html__subtitle" id={items[8].scrollToId}>8. «Счётчик успешно установлен!»</h2>
        <p className="guide-html__txt">
          Если код счетчика установлен корректно, то через некоторое время Вы заметите появление данных с вашего
          сайта и сможете начать с ними работать.
        </p>
        <div className="success-install__image image" />
        <h2 className="guide-html__subtitle" id={items[9].scrollToId}>«Счётчик не установлен»</h2>
        <p className="guide-html__txt">
          Если код счетчика установлен некорректно, то при нажатии кнопки «Проверить код», Вы не сможете перейти на
          Главную страницу Личного кабинета. В этом случае, проверьте корректность установки кода счетчика на своем
          сайте еще раз.
        </p>
        <h2 className="guide-html__subtitle" id={items[10].scrollToId}>9. Обратитесь в Поддержку вашей CMS</h2>
        <p className="guide-html__txt">
          Проверьте корректность установки кода счетчика на всех пройденных этапах установки, при необходимости,
          обратитесь в «Справочный центр» сайта конструктора или напишите в их «Поддержку»
        </p>
        <div className="install-cms__image-6 image" />
        <div className="guide-html__separator" />
      </div>
      <Tabs
        items={items}
        value={value}
        onChange={changeValue}
        direction="vertical"
        className="guide-html__tabs"
        withScroll
      />
    </Screen>
  );
});

export default React.memo(InstallGuideCMSScreen);
