import cx from 'classnames';
import React from 'react';
import styles from './style.module.css';

interface ISettingsSwitcherItem {
  key: number;
  name?: string;
  icon: string;
  active: boolean;
  onClick?: () => void;
}

interface ISettingsSwitcher {
  settingsList: ISettingsSwitcherItem[];
  changeActiveItem: (k: number) => void;
}

const SettingsSwitcherItem = ({
  name,
  icon,
  onClick,
  active,
}: ISettingsSwitcherItem) => {
  return (
    <div
      className={cx(styles.item, active && styles.active)}
      onClick={onClick}
    >
      <div className={styles.itemInner}>
        <img src={icon} alt={icon} />
        {name && <div className={styles.name}>{name}</div>}
      </div>
    </div>
  );
};

export const SettingsSwitcher = ({
  settingsList,
  changeActiveItem,
}: ISettingsSwitcher) => {
  return (
    <div className={styles.switcher}>
      {settingsList.map((v) => {
        const onClick = () => changeActiveItem(v.key);

        return <SettingsSwitcherItem {...v} key={v.key} onClick={onClick} />;
      })}
    </div>
  );
};
