import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import { getLoggingErrorWithTimer } from '~/utils/getLoggingErrorWithTimer';
import useStores from '~/utils/useStores';
import './style.css';


const VipLoginScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  useEffect(() => {
    if (authStore.isAuthorized) {
      history.push(firstPageAfterAuthorization(authStore.clientState, authStore.currentUser.rightsModel));
    }
  }, [authStore.isAuthorized]);

  const onSubmit = (data: { login?: string; password?: string }) => {
    authStore.login(data.login, data.password);
  };

  return (
    <Screen className="login-screen">
      <form className="login-screen-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-screen-form__title">
          Добро пожаловать в СберЛид
        </div>

        <div className="login-screen-form__row">
          <Input
            name="login"
            ref={register({ required: true })}
            placeholder="Логин"
            size="large"
            failure={!!errors.login}
            autoFocus
            block
          />
        </div>

        <div className="login-screen-form__row">
          <Input
            name="password"
            ref={register({ required: true })}
            placeholder="Пароль"
            type="password"
            size="large"
            failure={!!errors.password}
            block
          />
        </div>

        <div className="login-screen-form__error">
          {getLoggingErrorWithTimer(
            authStore?.loggingErrorResponse?.errorData?.errorText.split(':')[
            authStore.loggingErrorResponse.errorData.errorText.split(':')
              .length - 2
              ] || ''
          )}
        </div>

        <div className="login-screen-form__row">
          <Button
            size="x-large"
            type="main"
            tag="button"
            htmlType="submit"
            loading={authStore.isLoggingIn}
            block
          >
            Войти
          </Button>
        </div>
      </form>
    </Screen>
  );
});

export default React.memo(VipLoginScreen);
