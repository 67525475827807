import { AxiosPromise } from 'axios';
import { ISbbIDLoginData } from '~/stores/authStore';
import { actionGet, actionSet, get, post } from './client';

interface ISbbIDRedirectUrl {
  message: string;
}

export type ISbbIDRedirectParameters = IResponse<ISbbIDRedirectUrl[]>;

interface ILoginData {
  token: string;
}

export type RolesListResponse = IResponse<IKey[]>;

export type DomainInfoResponse = IResponse<IDomainInfo[]>;

interface ISendPhoneNumber {
  phone: string;
  reason: TSendPhoneNumberReason;
  acceptedOffers?: Partial<IOffer>[];
}

interface ISendCode {
  code: string;
}

export interface ISendPassword {
  password: string;
}

export type PreAuthResponse = IResponse<[IPreAuthInfo]>;

type ICheckClientStateResponse = IResponse<[IClientState]>;

type IContractOwnerCodeResponse = IResponse<[IContractOwnerCode]>;

const Auth = {
  login: (
    login: string,
    password: string,
    isEncrypted: boolean = false,
    loginIsEncrypted: boolean = false
  ): AxiosPromise<ILoginData> =>
    post('/auth/login', { login, password, isEncrypted, loginIsEncrypted }),

  logout: (): AxiosPromise<void> => post('/auth/logout'),

  check: (): AxiosPromise<void> => post('/auth/check'),

  mandatoryChangePassword: (
    login: string,
    oldPassword: string,
    newPassword: string,
    isEncrypted: boolean = false,
    loginIsEncrypted: boolean = false
  ): AxiosPromise<void> =>
    post('/auth/mandatory_change_password', {
      login,
      oldPassword,
      newPassword,
      isEncrypted,
      loginIsEncrypted,
    }),

  changePassword: (
    password: string,
    isEncrypted: boolean = false
  ): AxiosPromise<void> =>
    post('/auth/change_password', { password, isEncrypted }),

  getFintechRedirectData: (): AxiosPromise<ISbbIDRedirectParameters> =>
    get(`/fintech/login2${window.location.search}`),

  getAuthTechFintechRedirectData: (): AxiosPromise<ISbbIDRedirectParameters> =>
    get(`/fintech/login_tech${window.location.search}`),

  loginWithSberID: (data: ISbbIDLoginData): AxiosPromise<ILoginData> =>
    post('/fintech/login', { ...data }),

  loginTechWithSberID: (data: ISbbIDLoginData): AxiosPromise<IResponse> =>
    post('/fintech/login_tech', { ...data }),

  getPasswordEncryptionKey: (): AxiosPromise<RolesListResponse> =>
    post('/device/key', {}),

  checkCodeInstalled: (): AxiosPromise<void> =>
    actionGet('/admin/checkScript', null),

  addDomain: (domainInfo: IDomainInfo): AxiosPromise<DomainInfoResponse> =>
    actionSet('/admin/addDomain', {}, domainInfo),

  sendPhoneNumber: ({
    phone,
    reason,
    acceptedOffers,
  }: ISendPhoneNumber): AxiosPromise<any> =>
    post('/auth/sms/send', { phoneNumber: phone, reason, acceptedOffers }),

  sendCode: ({ code }: ISendCode): AxiosPromise<any> =>
    post('/auth/sms/confirm', { code }),

  sendPassword: (data: ISendPassword): AxiosPromise<any> =>
    post('/auth/registration', { ...data }),

  sendForgotPassword: (newPassword: string): AxiosPromise<any> =>
    post('/auth/password/forgot', { password: newPassword }),

  contractOwnerCode: (): AxiosPromise<IContractOwnerCodeResponse> =>
    actionGet('/auth/contractOwnerCode', {}),

  getPaymentUrl: (): AxiosPromise<PreAuthResponse> =>
    actionGet('/admin/card', {}),

  checkPaymentUrl: (): AxiosPromise<PreAuthResponse> =>
    actionSet('/admin/card', {}, undefined),

  checkClientState: (): AxiosPromise<ICheckClientStateResponse> =>
    actionGet('/admin/clientState', {}),

  setUTM: (payload: ISetUTM): AxiosPromise =>
    actionSet('/admin/Utm', {}, payload),
};

export default Auth;
