import { Layout } from 'antd';
import cx from 'classnames';
import React from 'react';

const OldFooter = ({ className }: { className: string }) => (
  <Layout.Footer
    className={cx('footer', className)}
  >
    &copy; Copyright, 2019
  </Layout.Footer>
);

export default OldFooter;
