import cx from 'classnames';
import React, { useState, CSSProperties } from 'react';
import { FieldError } from 'react-hook-form';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import './style.css';

interface Props {
  title?: React.ReactNode;
  message?: React.ReactNode;
  hint?: React.ReactNode;
  failure?: boolean | FieldError;
  success?: boolean;
  noHTMLLabel?: boolean;
  style?: CSSProperties;
  className?: string;
  noReservedPlaceForMessage?: boolean;
  dropdown?: boolean;
  overlayClassName?: string;
}

const FormItem: React.FC<Props> = ({
  title,
  hint,
  message,
  failure,
  success,
  noHTMLLabel,
  noReservedPlaceForMessage,
  style,
  className,
  children,
  dropdown,
  overlayClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classNames = cx(
    'form-item',
    { _failure: failure, _success: success },
    className
  );

  const toggle = () => setIsOpen(!isOpen);

  const TooltipMemo = React.useMemo(
    () => (
      <div className="form-item__hint">
        <IconWithTooltip
          tooltipProps={{
            placement: 'bottomRight',
            title: hint,
            overlayClassName,
            width: '340px',
          }}
          iconProps={{
            name: 'Question',
            className: 'form-item__hint-icon',
          }}
        />
      </div>
    ),
    [hint]
  );

  const body = (
    <>
      {title && (
        <div className="form-item__header">
          <div className="form-item__title">{title}</div>
          {dropdown && (
            <div className="form-item__dropdown">
              <Icon
                name={isOpen ? 'ArrowSUp' : 'ArrowSDown'}
                className="form-item__dropdown-icon"
                onClick={toggle}
              />
            </div>
          )}
          {hint && TooltipMemo}
        </div>
      )}
      {((dropdown && isOpen) || !dropdown) && (
        <>
          <div className="form-item__component">{children}</div>
          {(message || !noReservedPlaceForMessage) && (
            <div className="form-item__footer">
              <div className="form-item__message">{message}</div>
            </div>
          )}
        </>
      )}
    </>
  );

  return noHTMLLabel ? (
    <div className={classNames} style={style}>
      {body}
    </div>
  ) : (
    <label className={classNames} style={style}>
      {body}
    </label>
  );
};

export default FormItem;
