import { IFeedConfiguration } from 'feed';
import React from 'react';
import Icon from '~/components/Icon';
import Card from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/General/Card';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import { getItems } from '~/utils/getItems';

interface IGeneralProps {
  configuration: IFeedConfiguration;
  onChange: (configuration: IFeedConfiguration, isDesignUpdate?: boolean) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
  device: Device;
}

export const General: React.FC<IGeneralProps> = ({
  configuration,
  onChange,
  onBlur,
  device,
}) => {
  const {
    feedWidth,
    hasCardShadow,
    feedProductsAmount,
    feedBackgroundColor,
    feedOpacity,
  } = configuration;

  const onChangeFeedWidth = (value: string | number) => {
    let finalValue = 0;

    if (!isNaN(+value) && +value <= 100) {
      finalValue = Math.abs(+value);
    } else if (!isNaN(+value) && +value > 100) {
      finalValue = 100;
    } else {
      finalValue = feedWidth;
    }
    onChange({ ...configuration, feedWidth: finalValue }, true);

  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Ширина ленты"
          overlayClassName="feed-editor-appearance__tooltip"
          hint="Вы можете выбрать размер ленты. Мы не советуем делать ее слишком маленькой. Макет слева отображает, как будет выглядеть лента на вашем сайте."
          noReservedPlaceForMessage
        >
          <Input
            className="feed-editor-appearance__width-input mini"
            elemAfter={(
              <Icon
                className="feed-editor-appearance__width-input-icon"
                name="Percent"
              />
            )}
            value={feedWidth.toString()}
            onChangeValue={onChangeFeedWidth}
            maxLength={3}
            onBlur={() => onBlur(configuration)}
            block
          />
        </FormItem>
      </section>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Максимальное кол-во товаров"
          hint="Выберите, сколько товарных рекомендаций будет в ленте. Вы можете указать значения от 1 до 30, в зависимости от количества добавленных товаров."
          overlayClassName="feed-editor-filling__tooltip"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <InputDropdown
            selectedKey={feedProductsAmount?.toString()}
            className="feed-editor-appearance__width-input mini"
            onChange={value => onChange({ ...configuration, feedProductsAmount: +value }, true)}
            items={getItems(30)}
            theme={'light'}
          />
        </FormItem>
      </section>
      {device !== 'mobile' && (
        <section className="feed-editor-appearance__section">
          <FormItem
            title="Тень при наведении на товар"
            overlayClassName="feed-editor-appearance__tooltip"
            noHTMLLabel
          >
            <Checkbox
              label="Добавить тень"
              className="feed-editor-appearance__checkbox"
              checked={hasCardShadow}
              onCheck={checked =>
                onChange({ ...configuration, hasCardShadow: checked }, true)
              }
            />
          </FormItem>
        </section>
      )}
      <section className="feed-editor-appearance__section">
        <FormItem
          overlayClassName="feed-editor-appearance__tooltip"
          title="Цвет фона ленты"
 
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={feedBackgroundColor}
            alpha={feedOpacity}
            onChange={data =>
              onChange(
                {
                  ...configuration,
                  feedBackgroundColor: data.color,
                  feedOpacity: data.alpha,
                },
                true
              )
            }
            className="feed-editor-appearance__color-picker"
          />
        </FormItem>
      </section>

      <Card
        configuration={configuration}
        onChange={onChange}
        isDesctop={device !== 'mobile'}
      />
    </>
  );
};
