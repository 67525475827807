import cx from 'classnames';
import React, { useEffect } from 'react';
import Icon from '../../Icon';
import './style.css';

interface Props {
  title?: string | JSX.Element;
  visible: boolean;
  onExit?: () => void;
  onClose?: () => void;
  onApply?: () => void;
  showIcon?: boolean;
  showBackgroundCloseIcon?: boolean;
  swapActions?: boolean;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
  videoTitle?: string;
  videoSrc?: string;
}

const VideoMessage: React.FC<Props> = React.forwardRef((props, ref) => {

  const {
    title,
    visible,
    onExit = () => { return; },
    onClose = () => { return; },
    onApply = () => { return; },
    showIcon = true,
    showBackgroundCloseIcon = false,
    swapActions = false,
    className = '',
    videoTitle,
    videoSrc,
  } = props;


  const ENTER_CODE = 13;
  const ESCAPE_CODE = 27;

  useEffect(() => {
    const handleEsc = (event: any) => {
      switch (event.keyCode) {
        case ENTER_CODE:
          swapActions ? onClose() : onApply();
          break;
        case ESCAPE_CODE:
          swapActions ? onApply() : onClose();
          break;
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className={cx('message_wrapper', className, { _hidden: !visible })}>
      {showBackgroundCloseIcon && (
        <Icon className="message-background-close__icon" name="Close" onClick={onClose} />
      )}
      <div className="message__background" onClick={onClose} />
      <div className="message-video__box" ref={ref}>
        <div className="message-video__header">
          <div className="message__title">
            {title || ''}
            {videoTitle ? `Как настроить условия показа виджета «${videoTitle}»` : ''}
          </div>
          {showIcon && <Icon className="message-video__icon" name="Close" onClick={onExit || onClose} />}
        </div>
          <iframe
            className="message-video__iframe"
            width="800"
            height="450"
            frameBorder="none"
            src={videoSrc}
            allowFullScreen 
          />
      </div>
    </div>
  );
});

export default VideoMessage;
