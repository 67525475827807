const icons: { [key: string]: Promise<any> } = {
  Alert: import(
    /* webpackChunkName: "AlignBottomCenter" */
    '~/components/icons/Alert.tsx'
    ),
  AlignBottomCenter: import(
    /* webpackChunkName: "AlignBottomCenter" */
    '~/components/icons/AlignBottomCenter.tsx'
  ),
  AlignBottomLeft: import(
    /* webpackChunkName: "AlignBottomLeft" */
    '~/components/icons/AlignBottomLeft.tsx'
  ),
  AlignBottomRight: import(
    /* webpackChunkName: "AlignBottomRight" */
    '~/components/icons/AlignBottomRight.tsx'
  ),
  AlignCenter: import(
    /* webpackChunkName: "AlignCenter" */
    '~/components/icons/AlignCenter.tsx'
  ),
  AlignCenterLeft: import(
    /* webpackChunkName: "AlignCenterLeft" */
    '~/components/icons/AlignCenterLeft.tsx'
  ),
  AlignCenterRight: import(
    /* webpackChunkName: "AlignCenterRight" */
    '~/components/icons/AlignCenterRight.tsx'
  ),
  AlignTopCenter: import(
    /* webpackChunkName: "AlignTopCenter" */
    '~/components/icons/AlignTopCenter.tsx'
  ),
  AlignTopLeft: import(
    /* webpackChunkName: "AlignTopLeft" */
    '~/components/icons/AlignTopLeft.tsx'
  ),
  AlignTopRight: import(
    /* webpackChunkName: "AlignTopRight" */
    '~/components/icons/AlignTopRight.tsx'
  ),
  ArrowLeft: import(
    /* webpackChunkName: "ArrowLeft" */
    '~/components/icons/ArrowLeft.tsx'
  ),
  ArrowMDown: import(
    /* webpackChunkName: "ArrowMDown" */
    '~/components/icons/ArrowMDown.tsx'
  ),
  ArrowMLeft: import(
    /* webpackChunkName: "ArrowMLeft" */
    '~/components/icons/ArrowMLeft.tsx'
  ),
  ArrowMRight: import(
    /* webpackChunkName: "ArrowMRight" */
    '~/components/icons/ArrowMRight.tsx'
  ),
  ArrowMUp: import(
    /* webpackChunkName: "ArrowMUp" */
    '~/components/icons/ArrowMUp.tsx'
  ),
  ArrowSDown: import(
    /* webpackChunkName: "ArrowSDown" */
    '~/components/icons/ArrowSDown.tsx'
  ),
  ArrowSLeft: import(
    /* webpackChunkName: "ArrowSLeft" */
    '~/components/icons/ArrowSLeft.tsx'
  ),
  ArrowSRight: import(
    /* webpackChunkName: "ArrowSRight" */
    '~/components/icons/ArrowSRight.tsx'
  ),
  ArrowSUp: import(
    /* webpackChunkName: "ArrowSUp" */
    '~/components/icons/ArrowSUp.tsx'
  ),
  Bell: import(
    /* webpackChunkName: "Bell" */
    '~/components/icons/Bell.tsx'
  ),
  BorderRadius: import(
    /* webpackChunkName: "BorderRadius" */
    '~/components/icons/BorderRadius.tsx'
  ),
  Bow: import(
    /* webpackChunkName: "Bow" */
    '~/components/icons/Bow.tsx'
  ),
  Bulb: import(
    /* webpackChunkName: "Bulb" */
    '~/components/icons/Bulb.tsx'
  ),
  BurgerMenu: import(
    /* webpackChunkName: "BurgerMenu" */
    '~/components/icons/BurgerMenu.tsx'
  ),
  Calendar: import(
    /* webpackChunkName: "Calendar" */
    '~/components/icons/Calendar.tsx'
  ),
  ChartBar: import(
    /* webpackChunkName: "ChartBar" */
    '~/components/icons/ChartBar.tsx'
  ),
  ChartDonut: import(
    /* webpackChunkName: "ChartDonut" */
    '~/components/icons/ChartDonut.tsx'
  ),
  ChartLine: import(
    /* webpackChunkName: "ChartLine" */
    '~/components/icons/ChartLine.tsx'
  ),
  ChartMap: import(
    /* webpackChunkName: "ChartMap" */
    '~/components/icons/ChartMap.tsx'
  ),
  ChartTable: import(
    /* webpackChunkName: "ChartTable" */
    '~/components/icons/ChartTable.tsx'
  ),
  Check: import(
    /* webpackChunkName: "Check" */
    '~/components/icons/Check.tsx'
  ),
  CheckedCircle: import(
    /* webpackChunkName: "CheckedCircle" */
    '~/components/icons/CheckedCircle.tsx'
  ),
  CheckedDouble: import(
    /* webpackChunkName: "CheckedCircle" */
    '~/components/icons/CheckDouble.tsx'
    ),
  CheckedGreenCircle: import(
    /* webpackChunkName: "CheckedGreenCircle" */
    '~/components/icons/CheckedGreenCircle.tsx'
  ),
  CheckedGrayCircle: import(
    /* webpackChunkName: "CheckedGrayCircle" */
    '~/components/icons/CheckedGrayCircle.tsx'
  ),
  CheckedWhiteCircle: import(
    /* webpackChunkName: "CheckedWhiteCircle" */
    '~/components/icons/CheckedWhiteCircle.tsx'
  ),
  CheckmarkM: import(
    /* webpackChunkName: "CheckmarkM" */
    '~/components/icons/CheckmarkM.tsx'
  ),
  CheckmarkS: import(
    /* webpackChunkName: "CheckmarkS" */
    '~/components/icons/CheckmarkS.tsx'
  ),
  CheckmarkXxl: import(
    /* webpackChunkName: "CheckmarkXxl" */
    '~/components/icons/CheckmarkXxl.tsx'
  ),
  CrossInCircleXxl: import(
    /* webpackChunkName: "CrossInCircleXxl" */
    '~/components/icons/CrossInCircleXxl.tsx'
    ),
  Clip: import(
    /* webpackChunkName: "Clip" */
    '~/components/icons/Clip.tsx'
  ),
  Clock: import(
    /* webpackChunkName: "Clock" */
    '~/components/icons/Clock.tsx'
  ),
  Close: import(
    /* webpackChunkName: "Close" */
    '~/components/icons/Close.tsx'
  ),
  CloseCircle: import(
    /* webpackChunkName: "CloseCircle" */
    '~/components/icons/CloseCircle.tsx'
  ),
  Code: import(
    /* webpackChunkName: "Code" */
    '~/components/icons/Code.tsx'
  ),
  Constructor: import(
    /* webpackChunkName: "Constructor" */
    '~/components/icons/Constructor.tsx'
  ),
  Copy: import(
    /* webpackChunkName: "Copy" */
    '~/components/icons/Copy.tsx'
  ),
  DemoBlindZones: import(
    /* webpackChunkName: "DemoBlindZones" */
    '~/components/icons/DemoBlindZones.tsx'
    ),
  DemoLead: import(
    /* webpackChunkName: "DemoLead" */
    '~/components/icons/DemoLead.tsx'
    ),
  DemoLite: import(
    /* webpackChunkName: "DemoLite" */
    '~/components/icons/DemoLite.tsx'
    ),
  DemoMailing: import(
    /* webpackChunkName: "DemoMailing" */
    '~/components/icons/DemoMailing.tsx'
    ),
  DemoPro: import(
    /* webpackChunkName: "DemoPro" */
    '~/components/icons/DemoPro.tsx'
    ),
  DemoWheel: import(
    /* webpackChunkName: "DemoWheel" */
    '~/components/icons/DemoWheel.tsx'
    ),
  DemoWidgets: import(
    /* webpackChunkName: "DemoWidgets" */
    '~/components/icons/DemoWidgets.tsx'
    ),
  DeviceDesktop: import(
    /* webpackChunkName: "DeviceDesktop" */
    '~/components/icons/DeviceDesktop.tsx'
  ),
  DevicePhone: import(
    /* webpackChunkName: "DevicePhone" */
    '~/components/icons/DevicePhone.tsx'
  ),
  DeviceTablet: import(
    /* webpackChunkName: "DeviceTablet" */
    '~/components/icons/DeviceTablet.tsx'
  ),
  Doc: import(
    /* webpackChunkName: "Doc" */
    '~/components/icons/Doc.tsx'
  ),
  Doc2: import(
    /* webpackChunkName: "Doc2" */
    '~/components/icons/Doc2.tsx'
  ),
  Done: import(
    /* webpackChunkName: "Done" */
    '~/components/icons/Done.tsx'
  ),
  DoneInCircle: import(
    /* webpackChunkName: "DoneInCircle" */
    '~/components/icons/DoneInCircle.tsx'
  ),
  Download: import(
    /* webpackChunkName: "Download" */
    '~/components/icons/Download.tsx'
  ),
  EditCircle: import(
    /* webpackChunkName: "EditCircle" */
    '~/components/icons/EditCircle.tsx'
  ),
  EmptyCircle: import(
    /* webpackChunkName: "EmptyCircle" */
    '~/components/icons/EmptyCircle.tsx'
  ),
  Eye: import(
    /* webpackChunkName: "Eye" */
    '~/components/icons/Eye.tsx'
  ),
  EyeCrossed: import(
    /* webpackChunkName: "EyeCrossed" */
    '~/components/icons/EyeCrossed.tsx'
  ),
  FeedPlacement: import(
    /* webpackChunkName: "FeedPlacement" */
    '~/components/icons/FeedPlacement.tsx'
  ),
  FeedPlacementMobile: import(
    /* webpackChunkName: "FeedPlacementMobile" */
    '~/components/icons/FeedPlacementMobile.tsx'
  ),
  FeedPlacementMobile2: import(
    /* webpackChunkName: "FeedPlacementMobile2" */
    '~/components/icons/FeedPlacementMobile2.tsx'
  ),
  FeedPlacementSmall: import(
    /* webpackChunkName: "FeedPlacementSmall" */
    '~/components/icons/FeedPlacementSmall.tsx'
  ),
  Gear: import(
    /* webpackChunkName: "Gear" */
    '~/components/icons/Gear.tsx'
  ),
  General: import(
    /* webpackChunkName: "General" */
    '~/components/icons/General.tsx'
  ),
  GeneralActive: import(
    /* webpackChunkName: "GeneralActive" */
    '~/components/icons/GeneralActive.tsx'
  ),
  GeneralChartActive: import(
    /* webpackChunkName: "GeneralChartActive" */
    '~/components/icons/GeneralChartActive.tsx'
  ),
  GeneralChartLock: import(
    /* webpackChunkName: "GeneralChartLock" */
    '~/components/icons/GeneralChartLock.tsx'
  ),
  GeneralChartLockCurrent: import(
    /* webpackChunkName: "GeneralChartLockCurrent" */
    '~/components/icons/GeneralChartLockCurrent.tsx'
  ),
  GeneralChartPassed: import(
    /* webpackChunkName: "GeneralChartPassed" */
    '~/components/icons/GeneralChartPassed.tsx'
  ),
  GeneralChartPassedCurrent: import(
    /* webpackChunkName: "GeneralChartPassedCurrent" */
    '~/components/icons/GeneralChartPassedCurrent.tsx'
  ),
  GeneralGreeting: import(
    /* webpackChunkName: "GeneralGreeting" */
    '~/components/icons/GeneralGreeting.tsx'
  ),
  GeneralNetActive: import(
    /* webpackChunkName: "GeneralNetActive" */
    '~/components/icons/GeneralNetActive.tsx'
  ),
  GeneralNetGreeting: import(
    /* webpackChunkName: "GeneralNetGreeting" */
    '~/components/icons/GeneralNetGreeting.tsx'
  ),
  GeneralNetLock: import(
    /* webpackChunkName: "GeneralNetLock" */
    '~/components/icons/GeneralNetLock.tsx'
  ),
  GeneralNetLockCurrent: import(
    /* webpackChunkName: "GeneralNetLockCurrent" */
    '~/components/icons/GeneralNetLockCurrent.tsx'
  ),
  GeneralNetPassed: import(
    /* webpackChunkName: "GeneralNetPassed" */
    '~/components/icons/GeneralNetPassed.tsx'
  ),
  GeneralNetPassedCurrent: import(
    /* webpackChunkName: "GeneralNetPassedCurrent" */
    '~/components/icons/GeneralNetPassedCurrent.tsx'
  ),
  GeneralSquareActive: import(
    /* webpackChunkName: "GeneralSquareActive" */
    '~/components/icons/GeneralSquareActive.tsx'
  ),
  GeneralSquareGreeting: import(
    /* webpackChunkName: "GeneralSquareGreeting" */
    '~/components/icons/GeneralSquareGreeting.tsx'
  ),
  GeneralSquareLock: import(
    /* webpackChunkName: "GeneralSquareLock" */
    '~/components/icons/GeneralSquareLock.tsx'
  ),
  GeneralSquareLockCurrent: import(
    /* webpackChunkName: "GeneralSquareLockCurrent" */
    '~/components/icons/GeneralSquareLockCurrent.tsx'
  ),
  GeneralSquarePassed: import(
    /* webpackChunkName: "GeneralSquarePassed" */
    '~/components/icons/GeneralSquarePassed.tsx'
  ),
  GeneralSquarePassedCurrent: import(
    /* webpackChunkName: "GeneralSquarePassedCurrent" */
    '~/components/icons/GeneralSquarePassedCurrent.tsx'
  ),
  GeneralTargetActive: import(
    /* webpackChunkName: "GeneralTargetActive" */
    '~/components/icons/GeneralTargetActive.tsx'
  ),
  GeneralTargetLock: import(
    /* webpackChunkName: "GeneralTargetLock" */
    '~/components/icons/GeneralTargetLock.tsx'
  ),
  GeneralTargetLockCurrent: import(
    /* webpackChunkName: "GeneralTargetLockCurrent" */
    '~/components/icons/GeneralTargetLockCurrent.tsx'
  ),
  GeneralTargetPassed: import(
    /* webpackChunkName: "GeneralTargetPassed" */
    '~/components/icons/GeneralTargetPassed.tsx'
  ),
  GeneralTargetPassedCurrent: import(
    /* webpackChunkName: "GeneralTargetPassedCurrent" */
    '~/components/icons/GeneralTargetPassedCurrent.tsx'
  ),
  FitBlock: import(
    /* webpackChunkName: "FitBlock" */
    '~/components/icons/FitBlock.tsx'
  ),
  FitContent: import(
    /* webpackChunkName: "FitContent" */
    '~/components/icons/FitContent.tsx'
  ),
  Heartbeat: import(
    /* webpackChunkName: "Heartbeat" */
    '~/components/icons/Heartbeat.tsx'
  ),
  Help: import(
    /* webpackChunkName: "Help" */
    '~/components/icons/Help.tsx'
  ),
  InfoDonut: import(
    /* webpackChunkName: "InfoDonut" */
    '~/components/icons/InfoDonut.tsx'
  ),
  IconFrame: import(
    /* webpackChunkName: "IconFrame" */
    '~/components/icons/IconFrame.tsx'
  ),
  IconNoFrame: import(
    /* webpackChunkName: "IconNoFrame" */
    '~/components/icons/IconNoFrame.tsx'
  ),
  ImagePositionBottom: import(
    /* webpackChunkName: "ImagePositionBottom" */
    '~/components/icons/ImagePositionBottom.tsx'
  ),
  ImagePositionFillHorizontal: import(
    /* webpackChunkName: "ImagePositionFillHorizontal" */
    '~/components/icons/ImagePositionFillHorizontal.tsx'
  ),
  ImagePositionFillVertical: import(
    /* webpackChunkName: "ImagePositionFillVertical" */
    '~/components/icons/ImagePositionFillVertical.tsx'
  ),
  ImagePositionLeft: import(
    /* webpackChunkName: "ImagePositionLeft" */
    '~/components/icons/ImagePositionLeft.tsx'
  ),
  ImagePositionRight: import(
    /* webpackChunkName: "ImagePositionRight" */
    '~/components/icons/ImagePositionRight.tsx'
  ),
  ImagePositionTop: import(
    /* webpackChunkName: "ImagePositionTop" */
    '~/components/icons/ImagePositionTop.tsx'
  ),
  index: import(
    /* webpackChunkName: "index" */
    '~/components/icons/index.tsx'
  ),
  InfoCircle: import(
      /* webpackChunkName: "InfoCircle" */
      '~/components/icons/InfoCircle.tsx'
      ),
  Instructor: import(
    /* webpackChunkName: "Instructor" */
    '~/components/icons/Instructor.tsx'
  ),
  Italic: import(
    /* webpackChunkName: "Italic" */
    '~/components/icons/Italic.tsx'
  ),
  LabelPositionTopLeft: import(
    /* webpackChunkName: "LabelPositionTopLeft" */
    '~/components/icons/LabelPositionTopLeft.tsx'
  ),
  LabelPositionBottomLeft: import(
    /* webpackChunkName: "LabelPositionBottomLeft" */
    '~/components/icons/LabelPositionBottomLeft.tsx'
  ),
  LabelPositionTopRight: import(
    /* webpackChunkName: "LabelPositionTopRight" */
    '~/components/icons/LabelPositionTopRight.tsx'
  ),
  LabelPositionBottomRight: import(
    /* webpackChunkName: "LabelPositionBottomRight" */
    '~/components/icons/LabelPositionBottomRight.tsx'
  ),
  Lamp: import(
    /* webpackChunkName: "Lamp" */
    '~/components/icons/Lamp.tsx'
  ),
  Leads: import(
    /* webpackChunkName: "Leads" */
    '~/components/icons/Leads.tsx'
  ),
  LineThrough: import(
    /* webpackChunkName: "LineThrough" */
    '~/components/icons/LineThrough.tsx'
  ),
  Logo1: import(
    /* webpackChunkName: "Logo1" */
    '~/components/icons/Logo1.tsx'
  ),
  Logo2: import(
    /* webpackChunkName: "Logo2" */
    '~/components/icons/Logo2.tsx'
  ),
  LogoWhite: import(
    /* webpackChunkName: "LogoWhite" */
    '~/components/icons/LogoWhite.tsx'
  ),
  Logout: import(
    /* webpackChunkName: "Logout" */
    '~/components/icons/Logout.tsx'
  ),
  MobileTrafficActive: import(
    /* webpackChunkName: "MobileTrafficActive" */
    '~/components/icons/MobileTrafficActive.tsx'
  ),
  MobileTrafficChartActive: import(
    /* webpackChunkName: "MobileTrafficChartActive" */
    '~/components/icons/MobileTrafficChartActive.tsx'
  ),
  MobileTrafficChartLock: import(
    /* webpackChunkName: "MobileTrafficChartLock" */
    '~/components/icons/MobileTrafficChartLock.tsx'
  ),
  MobileTrafficChartLockCurrent: import(
    /* webpackChunkName: "MobileTrafficChartLockCurrent" */
    '~/components/icons/MobileTrafficChartLockCurrent.tsx'
  ),
  MobileTrafficChartPassed: import(
    /* webpackChunkName: "MobileTrafficChartPassed" */
    '~/components/icons/MobileTrafficChartPassed.tsx'
  ),
  MobileTrafficChartPassedCurrent: import(
    /* webpackChunkName: "MobileTrafficChartPassedCurrent" */
    '~/components/icons/MobileTrafficChartPassedCurrent.tsx'
  ),
  MobileTrafficSquareActive: import(
    /* webpackChunkName: "MobileTrafficSquareActive" */
    '~/components/icons/MobileTrafficSquareActive.tsx'
  ),
  MobileTrafficSquareLock: import(
    /* webpackChunkName: "MobileTrafficSquareLock" */
    '~/components/icons/MobileTrafficSquareLock.tsx'
  ),
  MobileTrafficSquareLockCurrent: import(
    /* webpackChunkName: "MobileTrafficSquareLockCurrent" */
    '~/components/icons/MobileTrafficSquareLockCurrent.tsx'
  ),
  MobileTrafficSquarePassed: import(
    /* webpackChunkName: "MobileTrafficSquarePassed" */
    '~/components/icons/MobileTrafficSquarePassed.tsx'
  ),
  MobileTrafficSquarePassedCurrent: import(
    /* webpackChunkName: "MobileTrafficSquarePassedCurrent" */
    '~/components/icons/MobileTrafficSquarePassedCurrent.tsx'
  ),
  MobileTrafficStopActive: import(
    /* webpackChunkName: "MobileTrafficStopActive" */
    '~/components/icons/MobileTrafficStopActive.tsx'
  ),
  MobileTrafficStopLock: import(
    /* webpackChunkName: "MobileTrafficStopLock" */
    '~/components/icons/MobileTrafficStopLock.tsx'
  ),
  MobileTrafficStopLockCurrent: import(
    /* webpackChunkName: "MobileTrafficStopLockCurrent" */
    '~/components/icons/MobileTrafficStopLockCurrent.tsx'
  ),
  MobileTrafficStopPassed: import(
    /* webpackChunkName: "MobileTrafficStopPassed" */
    '~/components/icons/MobileTrafficStopPassed.tsx'
  ),
  MobileTrafficStopPassedCurrent: import(
    /* webpackChunkName: "MobileTrafficStopPassedCurrent" */
    '~/components/icons/MobileTrafficStopPassedCurrent.tsx'
  ),
  MobileTrafficTargetActive: import(
    /* webpackChunkName: "MobileTrafficTargetActive" */
    '~/components/icons/MobileTrafficTargetActive.tsx'
  ),
  MobileTrafficTargetLock: import(
    /* webpackChunkName: "MobileTrafficTargetLock" */
    '~/components/icons/MobileTrafficTargetLock.tsx'
  ),
  MobileTrafficTargetLockCurrent: import(
    /* webpackChunkName: "MobileTrafficTargetLockCurrent" */
    '~/components/icons/MobileTrafficTargetLockCurrent.tsx'
  ),
  MobileTrafficTargetPassed: import(
    /* webpackChunkName: "MobileTrafficTargetPassed" */
    '~/components/icons/MobileTrafficTargetPassed.tsx'
  ),
  MobileTrafficTargetPassedCurrent: import(
    /* webpackChunkName: "MobileTrafficTargetPassedCurrent" */
    '~/components/icons/MobileTrafficTargetPassedCurrent.tsx'
  ),
  Mail: import(
    /* webpackChunkName: "Mail" */
    '~/components/icons/Mail.tsx'
  ),
  Message: import(
    /* webpackChunkName: "Message" */
    '~/components/icons/Message.tsx'
  ),
  MobileSubstrate: import(
    /* webpackChunkName: "MobileSubstrate" */
    '~/components/icons/MobileSubstrate.tsx'
  ),
  MouseArrow: import(
    /* webpackChunkName: "MouseArrow" */
    '~/components/icons/MouseArrow.tsx'
  ),
  MouseArrowWithLoader: import(
    /* webpackChunkName: "MouseArrow" */
    '~/components/icons/MouseArrowWithLoader.tsx'
  ),
  Move: import(
    /* webpackChunkName: "Move" */
    '~/components/icons/Move.tsx'
  ),
  NavDeadZone: import(
    /* webpackChunkName: "NavDeadZone" */
    '~/components/icons/NavDeadZone.tsx'
  ),
  NavHelp: import(
    /* webpackChunkName: "NavHelp" */
    '~/components/icons/NavHelp.tsx'
  ),
  NavHome: import(
    /* webpackChunkName: "NavHome" */
    '~/components/icons/NavHome.tsx'
  ),
  NavReports: import(
    /* webpackChunkName: "NavReports" */
    '~/components/icons/NavReports.tsx'
  ),
  NavSettings: import(
    /* webpackChunkName: "NavSettings" */
    '~/components/icons/NavSettings.tsx'
  ),
  NavWidgets: import(
    /* webpackChunkName: "NavWidgets" */
    '~/components/icons/NavWidgets.tsx'
  ),
  NoDecoration: import(
    /* webpackChunkName: "NoDecoration" */
    '~/components/icons/NoDecoration.tsx'
  ),
  NoItalic: import(
    /* webpackChunkName: "NoItalic" */
    '~/components/icons/NoItalic.tsx'
  ),
  Partners: import (
    /* webpackChunkName: "PauseS" */
    '~/components/icons/Partners.tsx'
  ),
  PauseS: import(
    /* webpackChunkName: "PauseS" */
    '~/components/icons/PauseS.tsx'
  ),
  PauseCircle: import(
    /* webpackChunkName: "PauseCircle" */
    '~/components/icons/PauseCircle.tsx'
    ),
  Pencil: import(
    /* webpackChunkName: "Pencil" */
    '~/components/icons/Pencil.tsx'
  ),
  Percent: import(
    /* webpackChunkName: "Percent" */
    '~/components/icons/Percent.tsx'
  ),
  Phone: import(
    /* webpackChunkName: "Phone" */
    '~/components/icons/Phone.tsx'
  ),
  PhoneCheck: import(
    /* webpackChunkName: "PhoneCheck" */
    '~/components/icons/PhoneCheck.tsx'
    ),
  Play: import(
    /* webpackChunkName: "Play" */
    '~/components/icons/Play.tsx'
  ),
  PlayCircle: import(
    /* webpackChunkName: "PlayCircle" */
    '~/components/icons/PlayCircle.tsx'
  ),
  PlayS: import(
    /* webpackChunkName: "PlayS" */
    '~/components/icons/PlayS.tsx'
  ),
  PlayOutline: import(
    /* webpackChunkName: "PlayOutline" */
    '~/components/icons/PlayOutline.tsx'
    ),
  Plus: import(
    /* webpackChunkName: "Plus" */
    '~/components/icons/Plus.tsx'
  ),
  PresetBg: import(
    /* webpackChunkName: "PresetBg" */
    '~/components/icons/PresetBg.tsx'
  ),
  PriceRowPosition: import(
    /* webpackChunkName: "PriceRowPosition" */
    '~/components/icons/PriceRowPosition.tsx'
  ),
  PriceColumnPosition: import(
    /* webpackChunkName: "PriceColumnPosition" */
    '~/components/icons/PriceColumnPosition.tsx'
  ),
  Programmer: import(
    /* webpackChunkName: "Programmer" */
    '~/components/icons/Programmer.tsx'
  ),
  Question: import(
    /* webpackChunkName: "Question" */
    '~/components/icons/Question.tsx'
  ),
  Quiz: import(
    /* webpackChunkName: "QuizScreen" */
    '~/components/icons/Quiz.tsx'
    ),
  RectHorizontal: import(
    /* webpackChunkName: "RectHorizontal" */
    '~/components/icons/RectHorizontal.tsx'
  ),
  RectVertical: import(
    /* webpackChunkName: "RectVertical" */
    '~/components/icons/RectVertical.tsx'
  ),
  Refresh: import(
    /* webpackChunkName: "RectVertical" */
    '~/components/icons/Refresh.tsx'
    ),
  Rename: import(
    /* webpackChunkName: "Rename" */
    '~/components/icons/Rename.tsx'
  ),
  Ruble: import(
    /* webpackChunkName: "Ruble" */
    '~/components/icons/Ruble.tsx'
  ),
  SadSmiley: import(
    /* webpackChunkName: "SadSmiley" */
    '~/components/icons/SadSmiley.tsx'
  ),
  SberbankLogo: import(
    /* webpackChunkName: "SberbankLogo" */
    '~/components/icons/SberbankLogo.tsx'
  ),
  Screw: import(
    /* webpackChunkName: "Screw" */
    '~/components/icons/Screw.tsx'
  ),
  ScrollLeft: import(
    /* webpackChunkName: "ScrollLeft" */
    '~/components/icons/ScrollLeft.tsx'
  ),
  ScrollRight: import(
    /* webpackChunkName: "ScrollRight" */
    '~/components/icons/ScrollRight.tsx'
  ),
  Search: import(
    /* webpackChunkName: "Search" */
    '~/components/icons/Search.tsx'
  ),
  ShoppingCart: import(
    /* webpackChunkName: "ShoppingCart" */
    '~/components/icons/ShoppingCart.tsx'
  ),
  SiteWorkingActive: import(
    /* webpackChunkName: "SiteWorkingActive" */
    '~/components/icons/SiteWorkingActive.tsx'
  ),
  SiteWorkingChartActive: import(
    /* webpackChunkName: "SiteWorkingChartActive" */
    '~/components/icons/SiteWorkingChartActive.tsx'
  ),
  SiteWorkingChartLock: import(
    /* webpackChunkName: "SiteWorkingChartLock" */
    '~/components/icons/SiteWorkingChartLock.tsx'
  ),
  SiteWorkingChartLockCurrent: import(
    /* webpackChunkName: "SiteWorkingChartLockCurrent" */
    '~/components/icons/SiteWorkingChartLockCurrent.tsx'
  ),
  SiteWorkingChartPassed: import(
    /* webpackChunkName: "SiteWorkingChartPassed" */
    '~/components/icons/SiteWorkingChartPassed.tsx'
  ),
  SiteWorkingChartPassedCurrent: import(
    /* webpackChunkName: "SiteWorkingChartPassedCurrent" */
    '~/components/icons/SiteWorkingChartPassedCurrent.tsx'
  ),
  SiteWorkingNetActive: import(
    /* webpackChunkName: "SiteWorkingNetActive" */
    '~/components/icons/SiteWorkingNetActive.tsx'
  ),
  SiteWorkingNetLock: import(
    /* webpackChunkName: "SiteWorkingNetLock" */
    '~/components/icons/SiteWorkingNetLock.tsx'
  ),
  SiteWorkingNetLockCurrent: import(
    /* webpackChunkName: "SiteWorkingNetLockCurrent" */
    '~/components/icons/SiteWorkingNetLockCurrent.tsx'
  ),
  SiteWorkingNetPassed: import(
    /* webpackChunkName: "SiteWorkingNetPassed" */
    '~/components/icons/SiteWorkingNetPassed.tsx'
  ),
  SiteWorkingNetPassedCurrent: import(
    /* webpackChunkName: "SiteWorkingNetPassedCurrent" */
    '~/components/icons/SiteWorkingNetPassedCurrent.tsx'
  ),
  SiteWorkingSafetyActive: import(
    /* webpackChunkName: "SiteWorkingSafetyActive" */
    '~/components/icons/SiteWorkingSafetyActive.tsx'
  ),
  SiteWorkingSafetyLock: import(
    /* webpackChunkName: "SiteWorkingSafetyLock" */
    '~/components/icons/SiteWorkingSafetyLock.tsx'
  ),
  SiteWorkingSafetyLockCurrent: import(
    /* webpackChunkName: "SiteWorkingSafetyLockCurrent" */
    '~/components/icons/SiteWorkingSafetyLockCurrent.tsx'
  ),
  SiteWorkingSafetyPassed: import(
    /* webpackChunkName: "SiteWorkingSafetyPassed" */
    '~/components/icons/SiteWorkingSafetyPassed.tsx'
  ),
  SiteWorkingSafetyPassedCurrent: import(
    /* webpackChunkName: "SiteWorkingSafetyPassedCurrent" */
    '~/components/icons/SiteWorkingSafetyPassedCurrent.tsx'
  ),
  SiteWorkingTargetActive: import(
    /* webpackChunkName: "SiteWorkingTargetActive" */
    '~/components/icons/SiteWorkingTargetActive.tsx'
  ),
  SiteWorkingTargetLock: import(
    /* webpackChunkName: "SiteWorkingTargetLock" */
    '~/components/icons/SiteWorkingTargetLock.tsx'
  ),
  SiteWorkingTargetLockCurrent: import(
    /* webpackChunkName: "SiteWorkingTargetLockCurrent" */
    '~/components/icons/SiteWorkingTargetLockCurrent.tsx'
  ),
  SiteWorkingTargetPassed: import(
    /* webpackChunkName: "SiteWorkingTargetPassed" */
    '~/components/icons/SiteWorkingTargetPassed.tsx'
  ),
  SiteWorkingTargetPassedCurrent: import(
    /* webpackChunkName: "SiteWorkingTargetPassedCurrent" */
    '~/components/icons/SiteWorkingTargetPassedCurrent.tsx'
  ),
  Soon: import(
    /* webpackChunkName: "Soon" */
    '~/components/icons/Soon.tsx'
  ),
  SortZa: import(
    /* webpackChunkName: "SortZa" */
    '~/components/icons/SortZa.tsx'
  ),
  Square: import(
    /* webpackChunkName: "Square" */
    '~/components/icons/Square.tsx'
  ),
  Stroke: import(
    /* webpackChunkName: "Stroke" */
    '~/components/icons/Stroke.tsx'
  ),
  Subtract: import(
    /* webpackChunkName: "Subtract" */
    '~/components/icons/Subtract.tsx'
  ),
  Stop: import(
    /* webpackChunkName: "Stop" */
    '~/components/icons/Stop.tsx'
  ),

  Table: import(
    /* webpackChunkName: "Table" */
    '~/components/icons/Table.tsx'
  ),
  Target: import(
    /* webpackChunkName: "Target" */
    '~/components/icons/Target.tsx'
  ),
  Telegram: import(
    /* webpackChunkName: "Telegram" */
    '~/components/icons/Telegram.tsx'
    ),
  Telescope: import(
    /* webpackChunkName: "Telescope" */
    '~/components/icons/Telescope.tsx'
  ),
  TelescopeScreen: import(
    /* webpackChunkName: "TelescopeScreen" */
    '~/components/icons/TelescopeScreen.tsx'
  ),
  ThumbsUp: import(
    /* webpackChunkName: "ThumbsUp" */
    '~/components/icons/ThumbsUp.tsx'
  ),
  Trash: import(
    /* webpackChunkName: "Trash" */
    '~/components/icons/Trash.tsx'
  ),
  TriangleWarning: import(
    /* webpackChunkName: "TriangleWarning" */
    '~/components/icons/TriangleWarning.tsx'
  ),
  TriangleWarning2: import(
    /* webpackChunkName: "TriangleWarning2" */
    '~/components/icons/TriangleWarning2.tsx'
  ),
  Underline: import(
    /* webpackChunkName: "Underline" */
    '~/components/icons/Underline.tsx'
  ),
  User: import(
    /* webpackChunkName: "User" */
    '~/components/icons/User.tsx'
    ),
  WidgetLine: import(
    /* webpackChunkName: "WidgetLine" */
    '~/components/icons/WidgetLine.tsx'
  ),
  WidgetSimpleLine: import(
    /* webpackChunkName: "WidgetSimpleLine" */
    '~/components/icons/WidgetSimpleLine.tsx'
  ),
  Wheel: import(
    /* webpackChunkName: "Wheel" */
    '~/components/icons/Wheel.tsx'
  ),
  Wrench: import(
    /* webpackChunkName: "Wrench" */
    '~/components/icons/Wrench.tsx'
  ),
};
export default icons;
