import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

const AuthTechScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };

  const loginWithSberbankBusinessID = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      await authStore.getAuthTechFintechRedirectData();
    } catch (e) {
      throw e;
    }
  };

  return (
    <Screen className="login-screen">
      <form className="login-screen-form">
        <div className="login-screen-form__title">Вход в личный кабинет</div>
        <div className="login-screen-form__row">
          <Button
            type="sber"
            icon="LogoWhite"
            loading={authStore.isLoggingIn}
            onClick={loginWithSberbankBusinessID}
            block
          >
            Войти по СберБизнес ID
          </Button>
        </div>
      </form>
    </Screen>
  );
});

export default React.memo(AuthTechScreen);
