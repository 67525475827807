import { IFeedConfiguration } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FormItem from '~/components/ui/FormItem';
import InputDropdown from '~/components/ui/InputDropdown';

interface IHandCardParamsProps {
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}

const HandCardParams: React.FC<IHandCardParamsProps> = ({
  isActive,
  configuration,
  onChange,
  onChangeCssParam,
}) => {
  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Размеры карточек товаров"
          overlayClassName="feed-editor-filling__tooltip"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <InputDropdown
            selectedKey={
              configuration.countCardsInDisplay
                ? configuration.countCardsInDisplay?.toString()
                : '4'
            }
            className="feed-editor-appearance__width-input"
            onChange={value =>
              onChange(
                {
                  ...configuration,
                  countCardsInDisplay: +value === 4 ? null : +value,
                },
                true
              )
            }
            items={[
              { key: '1', value: 'Большая' },
              { key: '2', value: 'Средняя' },
              { key: '3', value: 'Малая' },
              { key: '4', value: 'Стандартная' },
            ]}
            theme={'light'}
          />
        </FormItem>
      </section>
    </ParamHand>
  );
};

export default HandCardParams;
