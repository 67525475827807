import { randomIntFromInterval } from '~/utils/randomIntFromInterval';

export const getNoRotationElementsStyles = (diameter: number) => ({
  width: `${diameter}px`,
  height: `${diameter}px`,
});

export const getOuterShadowElementsStyles = (diameter: number) => ({
  border: `${diameter * 0.01}px #b3b3b3 solid`,
  top: `-${0.03 * diameter}px`,
  left: `-${0.03 * diameter}px`,
  height: `${diameter + 0.06 * diameter}px`,
  width: `${diameter + 0.06 * diameter}px`,
  filter: `blur(${diameter * 0.01}px)`,
});

export const getDecorativeElementsStyles = (diameter: number) => ({
  border: `${.025 * diameter}px #fff solid`,
  height: `${diameter + 0.05 * diameter}px`,
  width: `${diameter + 0.05 * diameter}px`,
  top: `-${.025 * diameter}px`,
  left: `-${.025 * diameter}px`,
});

export const getWheelBorderElementStyles = (diameter: number) => ({
  boxShadow: `inset 0 0 ${.015}px ${.005}px rgba(0, 0, 0, .25)`,
  height: `${diameter}px`,
  width: `${diameter}px`,
});

export const getWinArrowStyles = (
  wheelSize: number,
  arrowColor: string = 'white'
) => {
  const arrowSize = wheelSize / 40;

  return {
    width: `${arrowSize}px`,
    border: `${arrowSize * 2}px solid transparent`,
    borderTop: `${arrowSize * 2.5}px solid ${arrowColor}`,
    left: `${wheelSize / 2 - arrowSize * 2}px`,
  };
};

interface IGetRotatedElementsStyles {
  diameter: number;
  degree: number;
  winSectorNumber: number;
}

export const getRotatedElementsStyles = ({
  diameter,
  degree,
  winSectorNumber,
}: IGetRotatedElementsStyles) => {
  const randomSpinNumber = randomIntFromInterval(3, 5);

  const rotationEnd =
    360 * randomSpinNumber - degree * (winSectorNumber - 1) + degree / 2 + 90;

  const startValue = degree;

  const getAnimationDuration = (randomSpinNumber: number) => {
    return randomSpinNumber <= 4 ? 2 : 3;
  };

  return {
    width: `${diameter}px`,
    height: `${diameter}px`,
    animationDuration: `${getAnimationDuration(randomSpinNumber)}s`,
    transform: `rotate(${startValue}deg)`,
    ['--min-rotate-value']: startValue + 'deg',
    ['--max-rotate-value']: rotationEnd + 'deg',
  };
};
