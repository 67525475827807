const textTruncate = (
  text: string,
  maxWidth: number = 24,
  tailPlace: 'middle' | 'end' = 'middle'
): string => {
  if (!text) return text;

  const length = text.length;

  if (length <= maxWidth) return text;

  if (tailPlace === 'end') return text.substr(0, maxWidth) + '...';

  const firstPart = Math.ceil(maxWidth / 2);
  const lastPart = Math.floor(maxWidth / 2);

  return `${text.slice(0, firstPart)}…${text.slice(length - lastPart)}`;
};

export default textTruncate;
