import { action, computed, observable } from 'mobx';
import Settings, { TCodeServices } from '~/api/settings';
import DocumentsModel from '~/models/DocumentsModel';
import Store from '~/stores/store';
import { getError } from '~/utils/getError';

export interface IServices {
  '@type': 'ServiceStatus';
  code: TCodeServices;
  isActive: boolean;
  price: number;
  endPromoDate: string;
  isPromoAvilable: boolean;
  isPlannedOn: boolean;
}

export class SettingsStore extends Store {
  @observable attendance: IAttendance;
  @observable isFetching: boolean;
  @observable isPopupVisible: boolean;
  @observable email: string = '';
  @observable inn: string = '';
  @observable showPopUp: boolean = true;

  /*
      TODO
      Переменные и методы, связанные с ролевой моделью должны быть перенесены
      в отдельную стору. Возможно во вложенную по иерархии.
   */
  @observable toggleDrawer: boolean = false;
  @observable userWasCreated: boolean = false;
  @observable loginPasswordCopied: boolean = false;
  @observable drawerComponent: number;
  @observable documents: DocumentsModel = new DocumentsModel(this);
  @observable toggleUserDrawer: boolean = false;
  @observable userId: number;
  @observable userPhone: string = '';
  @observable userName: string = '';
  @observable userPassword: string = '';
  @observable deleteUserPopUp: boolean = false;
  @observable operationStatus: TOperationStatus = 'createUser';
  @observable loadingUsers: boolean;
  @observable createUserError: string = '';
  @observable services: IServices[] = [];
  @observable activeServices: boolean[] = [];
  @observable isFetchingServices: boolean;
  @observable isRedirect: boolean;
  @observable inProcessing: boolean;

  @action
  resetProcessing = () => {
    this.inProcessing = false;
  }

  @action
  enabledServices = () => {
    this.activeServices = [];

    if (this.services.length) {
      this.services.map(service => service.isActive && this.activeServices.push(service.isActive));
    }

    return this.activeServices;
  }

  @action
  getServices = async () => {
    this.isFetchingServices  = true;
    
    try {
      const response = await Settings.getServices();
      this.services = response.data.payload;
    } catch (e) {
      throw e;
    } finally {
      this.isFetchingServices = false;
    }
  }

  @action
  remoteService = async (service: string, active: boolean) => {

    try {
      const response = await Settings.remoteService(service, active);
      this.services = response.data.payload;
      this.isRedirect = this.isLastActiveService;
    } catch (e) {
      this.inProcessing = e.response.data.errorData.errorType === 'AdminTotServiceException';
      throw e;
    }
  }

  @action
  setIsPopupVisible = (isVisible: boolean) => {
    this.isPopupVisible = isVisible;
  }

  @action
  fetchAttendance = async () => {
    this.isFetching = true;

    try {
      const response = await Settings.fetchAttendance();
      this.attendance = response.data.payload[0];
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @action
  setEmail = (userEmail: string) => {
    this.email = userEmail;
  }

  @action
  setInn = (inn: string) => {
    this.inn = inn;
  }

  @action
  sendEmail = async () => {
    await Settings.sendEmail(this.email, this.inn);
  }

  @action
  toggleVisiblePopUp = (togglePopUp: boolean) => {
    this.showPopUp = togglePopUp;
  }

  @action
  setToggleDrawer = (toggleDrawer: boolean) => {
    this.toggleDrawer = toggleDrawer;
  }

  @action
  setDrawerComponent = (index: number) => {
    this.drawerComponent = index;
  }

  @action
  setToggleUserDrawer = (toggleUserDrawer: boolean) => {
    this.toggleUserDrawer = toggleUserDrawer;
  }

  @action
  setUserId = (id: number) => {
    this.userId = id;
  }

  @action
  setUserPhone = (userPhone: string) => {
    this.userPhone = userPhone;
  }

  @action
  setUserName = (userName: string) => {
    this.userName = userName;
  }

  @action
  setUserPassword = (userPassword: string) => {
    this.userPassword = userPassword;
  }

  @action
  setDeleteUserPopUp = (togglePopUp: boolean) => {
    this.deleteUserPopUp = togglePopUp;
  }

  @action
  setOperationStatus = (status: TOperationStatus) => {
    this.operationStatus = status;
  }

  @action
  setLoadingUsers = (loadingStatus: boolean) => {
    this.loadingUsers = loadingStatus;
  }

  @action
  setCreateUserError = (error: string) => {
    this.createUserError = error;
  }

  @action
  setUserWasCreated = (userWasCreated: boolean) => {
    this.userWasCreated = userWasCreated;
  }


  @action
  setLoginPasswordCopied = (copied: boolean) => {
    this.loginPasswordCopied = copied;
  }

  getUsers = async () => {
    try {
      this.setLoadingUsers(true);
      const result = await Settings.getUsers();
      this.setLoadingUsers(false);

      return result.data.payload;
    } catch (e) {
      this.setLoadingUsers(false);
      const error = getError({
        error: e,
        defaultError: 'Ошибка создания пользователя',
      });
      console.log({ e: error });
    }
  }

  addUser = async () => {

    this.setCreateUserError('');
    try {
      const result = await Settings.setUser({
        '@type': 'User',
        'id': this.operationStatus === 'editUser' ? this.userId : undefined,
        'accountName': this.userName,
        'login': this.userPhone,
        'password': this.userPassword,
        'status': 'ACTIVE',
      });
      this.setUserWasCreated(true);
      this.setToggleUserDrawer(false);

      return result.data.payload;
    } catch (e) {
      this.setUserWasCreated(false);
      this.setToggleUserDrawer(true);
      const error = getError({
        error: e,
        defaultError: 'Ошибка создания пользователя',
      });
      this.setCreateUserError(error as string);
    }
  }

  deleteUser = async () => {
    try {
      const result = await Settings.setUser({
        '@type': 'User',
        'id': this.userId,
        'accountName': this.userName,
        'login': this.userPhone,
        'status': 'INACTIVE',
      });

      this.clearUserData();
      this.setDeleteUserPopUp(false);

      return result.data.payload;
    } catch (e) {
      const error = getError({
        error: e,
        defaultError: 'Ошибка создания пользователя',
      });
      console.log({ e: error });
    }
  }

  @action
  clearUserData = () => {
    this.userPhone = '';
    this.userName = '';
    this.userPassword = '';
    this.createUserError = '';
    this.operationStatus = 'createUser';
    this.loginPasswordCopied = false;
  }

  @computed
  get isLastActiveService() {
    const arr = this.services.filter((item) => {
      return item.isActive;
    });
    
    return arr.length >= 1 ? false : true;
  }

  @computed
  get userEditTitle() {
    return this.operationStatus === 'editUser'
      ? 'Редактирование пользователя'
      : 'Добавление пользователя';
  }

  @computed
  get userEditButtonText() {
    return this.operationStatus === 'editUser'
      ? 'Сохранить'
      : 'Добавить пользователя';
  }

  @computed
  get withCopiedLoginPwdText() {
    return this.loginPasswordCopied ? 'Скопировано' : 'Скопировать логин и пароль';
  }

  @computed
  get withCopiedLoginPwdIcon() {
    return this.loginPasswordCopied ? 'CheckmarkM' : 'Copy';
  }

  @computed
  get getCompletedOperationStatusHeader() {
    return this.operationStatus === 'editUser' 
    ? 'Пользователь успешно отредактирован!'
    : 'Пользователь успешно создан!';
  }

}

export default new SettingsStore();
