import React from 'react';
import TextAreaWithNote from '~/components/ui/TextArea/TextAreaWithNote';
import {
  getMaxLengthMessage,
} from '~/screens/MassMailingScreen/utils';
import commonStyles from '../style.module.css';
import styles from './styles.module.css';

const TextEditElement: React.FC<IEditElementProps> = ({ configuration, onEdit }: IEditElement) => {
  const { editParams = {} } = configuration;
  const maxTextLength = editParams?.maxTextLength;
  const value = configuration.params.text;
  const isErrorShown = value.length >= maxTextLength;

  return (
    <>
      <div className={commonStyles.head}>{configuration.uiName}</div>
      <section className={commonStyles.section}>
        <TextAreaWithNote
          message={getMaxLengthMessage(maxTextLength)}
          failure={isErrorShown}
          textAreaProps={{
            className: `${styles.textarea} ${isErrorShown ? styles.red : ''}`,
            maxLength: maxTextLength,
            rows: 7,
            value,
            onChangeValue: value => onEdit(configuration.name, 'text', value),
            placeholder: 'Ваш текст здесь...',
          }}
        />
      </section>
    </>
  );
};

export default TextEditElement;