import { debounce } from 'lodash';
import React, { useEffect, useRef, useState, ReactNode } from 'react';
import './style.css';

interface IWrapperContentDrawerProps {
  children: ReactNode;
  displayParam?: 'flex' | 'block';
}

export const WrapperContentDrawer: React.FC<IWrapperContentDrawerProps> = ({
  children,
  displayParam = 'flex',
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const [display, setDisplay] = useState<'flex' | 'block' | 'none'>('none');
  const [contentHeight, setContentHeight] = useState('0');

  const handleResize = () => {
    setDisplay('none');
    const height = containerRef?.current.getBoundingClientRect().height;
    setDisplay(displayParam);
    setContentHeight(`${height}px`);
  };

  useEffect(() => {
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 400);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <div
      className={'component-generic-wrappeer-content-drawer__container'}
      ref={containerRef}
    >
      <div
        className={'component-generic-wrappeer-content-drawer__content'}
        style={{
          display,
          height: contentHeight,
        }}
      >
        {children}
      </div>
    </div>
  );
};
