import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRule,
  BusinessRulesByFeed,
  BusinessRuleEntity,
  BusinessRuleMeta,
  FeedForBusinessRule,
  FilterBusinessRuleEntityRequest,
} from '~/screens/BusinessRulesScreen/types';

type GetRuleListResponse = IResponse<BusinessRule[]>;
type GetMetadataResponse = IResponse<BusinessRuleMeta[]>;
type GetFeedForBusinessRuleResponse = IResponse<FeedForBusinessRule[]>;
type GetBusinessRuleEntityResponse = IResponse<BusinessRuleEntity[]>;
type GetBusinessRulesByFeedResponse = IResponse<BusinessRulesByFeed[]>;
type SaveBusinessRulesResponse = IResponse<[]>;

const BusinessRuleApi = {
  getRuleList: (): AxiosPromise<GetRuleListResponse> =>
    actionGet('/admin/businessrule', null),

  getMetedata: (): AxiosPromise<GetMetadataResponse> =>
    actionGet('/admin/businessrule', { type: 'meta' }),

  getFeedForBusinessRule: (): AxiosPromise<GetFeedForBusinessRuleResponse> =>
    actionGet('/admin/businessrule', { type: 'feedList' }),

  getBusinessRuleEntity: (
    parameters: FilterBusinessRuleEntityRequest
  ): AxiosPromise<GetBusinessRuleEntityResponse> =>
    actionGet('/admin/businessrule', {
      type: 'filter',
      ...parameters,
    }),

  getBusinessRulesByFeed: (
    feedId: string
  ): AxiosPromise<GetBusinessRulesByFeedResponse> =>
    actionGet('/admin/businessrule', { type: 'rulesByFeed', feedId }),

  saveBusinessRules: (
    businessRules: BusinessRulesModel
  ): AxiosPromise<SaveBusinessRulesResponse> =>
    actionSet('/admin/businessrule', null, {
      '@type': 'BusinessRule',
      'ruleName': businessRules.get('ruleName'),
      'ruleId': businessRules.get('ruleId'),
      'feedId': businessRules.get('feedId'),
      'recommendationFor': businessRules.get('recommendationFor'),
      'recommendationType': businessRules.get('recommendationType'),
      'recommendationOfWhat': {
        type: businessRules.get('recommendationOfWhat').type,
        entities: businessRules
          .get('recommendationOfWhat')
          .entities.filter(entity => !!entity.id),
      },
      'similarityType': Array.from(
        new Set(businessRules.get('similarityType')).values()
      ),
    }),

  renameBusinessRules: (
    businessRules: BusinessRulesModel
  ): AxiosPromise<SaveBusinessRulesResponse> =>
    actionSet(
      '/admin/businessrule',
      {
        type: 'rename',
        ruleId: businessRules.get('ruleId'),
        feedId: businessRules.get('feedId'),
        name: businessRules.get('ruleName'),
      },
      null
    ),

  deleteBusinessRules: (
    businessRules: BusinessRulesModel
  ): AxiosPromise<SaveBusinessRulesResponse> =>
    actionSet(
      '/admin/businessrule',
      {
        type: 'delete',
        ruleId: businessRules.get('ruleId'),
        feedId: businessRules.get('feedId'),
      },
      null
    ),
};

export default BusinessRuleApi;
