import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import './style.css';

export interface ISwitchButton {
  key: string;
  name: string;
}

interface ISwitchButtonProps {
  activeKey: string;
  onClick: (key: string) => void;
  buttons?: ISwitchButton[];
  className?: string;
  isEqualWidth?: boolean;
}

const SWITCH_BUTTONS: ISwitchButton[] = [
  {
    key: 'ON',
    name: 'On',
  },
  {
    key: 'OFF',
    name: 'Off',
  },
];

const SwitchButton: React.FC<ISwitchButtonProps> = ({
  activeKey,
  onClick,
  buttons = SWITCH_BUTTONS,
  className= '',
  isEqualWidth = true,
}) => {
  const switchRef = useRef(null);
  const [positionLeft, setPositionLeft] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const calculatePosition = () => {
    setPositionLeft(
      ((switchRef.current.getBoundingClientRect().width - 4) / buttons.length) *
        buttons.findIndex(button => button.key === activeKey)
    );
  };

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    const calculatedWidth = document.getElementById(`components-ui-switch-button__button-${activeKey}`)?.offsetWidth;
    setWidth(calculatedWidth);

    return window.removeEventListener('resize', calculatePosition);
  }, []);

  useEffect(() => {
    calculatePosition();
  }, [activeKey]);

  const defaultWidth = 100 / buttons.length;

  return (
    <div ref={switchRef} className={cx('components-ui-switch-button__box', className)}>
      {buttons.map((button) => {
        return (
          <div
            key={button.key}
            style={{
              width: isEqualWidth ? `${defaultWidth}%` : 'inherit',
            }}
            id={`components-ui-switch-button__button-${button.key}`}
            className={'components-ui-switch-button__button'}
            onClick={() => onClick(button.key)}
          >
            {button.name}
          </div>
        );
      })}
      <div
        style={{
          width: isEqualWidth ? `${defaultWidth}%` : `${width + 4}px`,
          left: positionLeft,
        }}
        className={'components-ui-switch-button__button-active'}
      >
        {buttons.find(button => button.key === activeKey)?.name}
      </div>
    </div>
  );
};

export default SwitchButton;
