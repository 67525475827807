import { ColumnProps } from 'antd/lib/table';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { StatusCell } from '~/components/generic/FeedStatisticTable/columns/StatusCell';
import SupportingButtonsCell from '~/components/generic/FeedStatisticTable/columns/SupportingButtonsCell';
import WrapperForTableCell from '~/components/generic/FeedStatisticTable/columns/WrapperForTableCell';
import TableCell from '~/components/ui/Table/TableCell';
import { FEED_TYPE_INFO } from '~/screens/FeedProScreen/components/FeedProEditor/StepFeedType/constants';
import { getYmlIconColor } from '~/screens/FeedProScreen/utils/getYmlIconColor';
import { getYmlIconName } from '~/screens/FeedProScreen/utils/getYmlIconName';
import { getYmlStatusText } from '~/screens/FeedProScreen/utils/getYmlStatusText';

export type ColumnKey =
  | 'status'
  | 'feedName'
  | 'createdDate'
  | 'feedTitle'
  | 'feedDevice'
  | 'ymlCatalogStatus'
  | 'displays'
  | 'interactions'
  | 'addToBasket'
  | 'buyCount'
  | 'buySum'
  | 'support';

interface IGetColumns {
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics | FeedStatistics
  ) => void;
  isFetchingRequest?: boolean;
}

export const getColumns: (
  params: IGetColumns
) => ColumnProps<FeedProStatistics | FeedApiStatistics>[] = ({
  onClickActionButton,
  isFetchingRequest = false,
}: IGetColumns) => {
  return [
    {
      key: 'status' as ColumnKey,
      fixed: 'left',
      title: 'Статус',
      width: 170,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) => {
        return (
          <StatusCell
            feedStats={feedStats}
            onClickActionButton={onClickActionButton}
            isFetchingRequest={isFetchingRequest}
          />
        );
      },
    },
    {
      key: 'feedName' as ColumnKey,
      fixed: 'left',
      title: 'Название ленты',
      width: 200,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) => (
        <TableCell
          title={(
            <>
              <div
                className={cx('feed-screen-table-name-cell__title', {
                  _noTitle: !feedStats.feedName,
                })}
              >
                {feedStats.feedName ? feedStats.feedName : 'Без названия'}
              </div>
              <div className="feed-screen-table-name-cell__title-type">
                {
                  FEED_TYPE_INFO.find(
                    typeFeed => typeFeed.type === feedStats.recType
                  )?.title
                }
              </div>
            </>
          )}
        />
      ),
    },
    {
      key: 'createdDate' as ColumnKey,
      title: 'Дата создания',
      width: 180,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        moment(feedStats.createdDate).format('LL'),
    },
    {
      key: 'feedTitle' as ColumnKey,
      title: 'Заголовок ленты',
      width: 200,
      render: feedStats => (
        <TableCell
          title={(
            <>
              <div
                className={cx('feed-screen-table-name-cell__title', {
                  _noTitle: !feedStats.feedTittle,
                })}
              >
                {feedStats.feedTittle
                  ? feedStats.feedTittle
                  : 'Нет заголовка ленты'}
              </div>
            </>
          )}
        />
      ),
    },
    {
      key: 'feedDevice' as ColumnKey,
      title: 'Устройства показа',
      width: 160,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) => {
        const isDevice: boolean = !!feedStats?.device;
        const isMobile: boolean =
          isDevice && feedStats?.device === 'mobile' ? true : false;

        return (
          <WrapperForTableCell
            iconName={isMobile ? 'DevicePhone' : 'DeviceDesktop'}
            title={
              isMobile ? (
                <>
                  Мобильные
                  <br />
                  телефоны
                </>
              ) : (
                'Компьютеры'
              )
            }
          />
        );
      },
    },
    {
      key: 'ymlCatalogStatus' as ColumnKey,
      title: 'Статус каталога',
      width: 240,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) => {
        const statusText = getYmlStatusText(feedStats.ymlCatalogStatus);
        const iconName = getYmlIconName(feedStats.ymlCatalogStatus);
        const iconColor = getYmlIconColor(feedStats.ymlCatalogStatus);

        return (
          <WrapperForTableCell
            iconName={iconName}
            iconColor={iconColor}
            title={statusText}
            subtitle={
              feedStats.ymlCatalogStatus === 'UPLOAD_CACHED'
                ? feedStats.lastUploadDate
                : null
            }
            tooltipName={feedStats.tooltip}
          />
        );
      },
    },
    {
      key: 'displays' as ColumnKey,
      title: () => (
        <WrapperForTableCell
          title={'Показы ленты'}
          tooltipName={'Столько раз пользователи видели ленту рекомендаций'}
        />
      ),
      width: 175,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        feedStats.displaysWithPercent &&
        feedStats.displaysWithPercent.absolute > 0
          ? `${feedStats.displaysWithPercent.absolute.toLocaleString()}`
          : '–',
    },
    {
      key: 'interactions' as ColumnKey,
      title: () => (
        <WrapperForTableCell
          title={'Переходы по товарам'}
          tooltipName={
            'Столько раз пользователи нажали на товары в ленте рекомендаций'
          }
        />
      ),
      width: 225,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        feedStats.interactionsWithPercent &&
        feedStats.interactionsWithPercent.absolute > 0
          ? `${feedStats.interactionsWithPercent.absolute.toLocaleString()} (${Math.round(
              feedStats.interactionsWithPercent.percent * 100
            )}%)`
          : '–',
    },
    {
      key: 'addToBasket' as ColumnKey,
      title: () => (
        <WrapperForTableCell
          title={'Положили в корзину, шт'}
          tooltipName={
            'Столько раз пользователи положили товар в корзину после нажатия на него в ленте рекомендаций'
          }
        />
      ),
      width: 240,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        feedStats.addToBasketWithPercent &&
        feedStats.addToBasketWithPercent.absolute > 0
          ? `${feedStats.addToBasketWithPercent.absolute.toLocaleString()} (${Math.round(
              feedStats.addToBasketWithPercent.percent * 100
            )}%)`
          : '–',
    },
    {
      key: 'buyCount' as ColumnKey,
      title: () => (
        <WrapperForTableCell
          title={'Куплено товаров из ленты, шт'}
          tooltipName={
            'Количество товаров, которые купили переходя через ленту рекомендаций'
          }
        />
      ),
      width: 279,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        feedStats.buyCountWithPercent &&
        feedStats.buyCountWithPercent.absolute > 0
          ? `${feedStats.buyCountWithPercent.absolute.toLocaleString()} (${Math.round(
              feedStats.buyCountWithPercent.percent * 100
            )}%)`
          : '–',
    },
    {
      key: 'buySum' as ColumnKey,
      title: () => (
        <WrapperForTableCell
          title={'Продажи ленты'}
          tooltipName={
            'Сумма покупок, совершенных пользователями при переходе через ленту рекомендаций'
          }
        />
      ),
      width: 211,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) =>
        feedStats.buySum !== undefined && feedStats.buySum > 0
          ? `${feedStats.buySum.toLocaleString()} ₽`
          : '–',
    },
    {
      key: 'support' as ColumnKey,
      className: 'ant-table-cell__buttons',
      width: 42,
      render: (feedStats: FeedProStatistics | FeedApiStatistics) => {
        return (
          <SupportingButtonsCell
            feedStatistics={feedStats}
            onClickActionButton={onClickActionButton}
            className={'support'}
          />
        );
      },
    },
  ];
};
