import cx from 'classnames';
import copy from 'fast-copy';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import ScenarioEditorConfigure from '~/components/ScenarioEditor/ScenarioEditorConfigure';
import TimeStep from '~/components/ScenarioEditor/ScenarioEditorSteps/TimeStep';
import TimestampIntervalStep from '~/components/ScenarioEditor/ScenarioEditorSteps/TimestampIntervalStep';
import UrlStep from '~/components/ScenarioEditor/ScenarioEditorSteps/UrlStep';
import InputWrapper from '~/components/ui/InputWrapper';
import { DATE_FORMAT } from '~/constants';
import { setGtm } from '~/utils/setGtm';
import './style.css';

interface Props {
  steps: IScenarioTemplateStepGroupBase[];
  onChange: (steps: IScenarioTemplateStepGroupBase[]) => void;
  showHowToConfigure: () => void;
  setConfigureText: (text: string | JSX.Element) => void;
  type?: string;
  addCurrentValidationStatus: (
    groupIndex: number,
    stepIndex: number,
    status: boolean
  ) => void;
}

type Item = {
  id: string;
  text: string;
  src?: string;
  children?: Item[];
};

export const getHint = (
  groupName: string,
  inputType: 'dropdown' | 'input' | 'disabled-input',
  operation: string,
  type: string,
  groupCaption: string
) => {
  if (groupName === 'Достигнута целевая страница') {
    if (inputType === 'disabled-input') {
      if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
        return 'Укажите здесь страницу или страницы, на которых должен показываться виджет. Виджет будет показан, если ранее посетитель зашел на страницу, указанную в "посещение страницы" и перешел на страницу, которую вы указали здесь.';

      if (
        type === 'ScenarioTemplate_ImportantNewsImpl' ||
        type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
        type === 'ScenarioTemplate_HolidaysImpl'
      )
        return 'Виджет будет показываться посетителю, когда он зайдет на страницу, которая будет соответствовать условию, которое вы здесь укажете';

      if (type === 'ScenarioTemplate_FeedbackImpl')
        return 'Виджет будет показываться посетителю, когда он зайдет на страницу, которая будет соответствовать условию, которое вы здесь укажете';
    }

    if (operation === 'EQUAL') {
      if (inputType === 'dropdown') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'Вставьте url адрес страницы, на которой не хотите показывать виджет. Он не отобразится только на этой странице';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'Вставьте url адрес страницы, на которой хотите показывать виджет. Он отобразится только на этой странице';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'Вставьте полный url адрес страницы, включая http и https. Виджет будет показываться, если посетитель до этого заходил на указанную страницу.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'Вставьте url адрес страницы, на которой хотите показывать виджет. Он отобразится только на этой странице';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'Вставьте url адрес страницы, на которой хотите показывать виджет. Он отобразится только на этой странице';
      }

      if (inputType === 'input') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет не будет показываться только на этой странице. Если вы хотите, чтобы виджет показывался везде, то просто впишите в поле прочерк или пробел и перейдите к следующему шагу.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет будет показываться только на этой странице.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет будет показываться только на этой странице. Виджет будет показываться на указанной странице, если посетитель до этого заходил на страницу из прошлого условия.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет будет показываться только на этой странице.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет будет показываться только на этой странице.';
      }
    }

    if (operation === 'CONTAINS') {
      if (inputType === 'dropdown') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет не будет показываться на страницах, где в адресе есть "basket"';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться на страницах, где в адресе есть "basket"';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться на страницах, где в адресе есть "basket".';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться на страницах, где в адресе есть "basket"';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться на страницах, где в адресе есть "basket"';
      }

      if (inputType === 'input') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц, на которых вы не хотели бы, чтобы показывался виджет. Если вы хотите, чтобы виджет показывался везде, то просто впишите в поле прочерк или пробел и перейдите к следующему шагу.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц, на которых вы хотели бы, чтобы показывался виджет.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц. Тогда виджет будет показываться, если посетитель на странице, где в адресе есть указанное значение, например, basket, а раннее был на странице из прошлого условия';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц, на которых вы хотели бы, чтобы показывался виджет.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц, на которых вы хотели бы, чтобы показывался виджет.';
      }
    }

    if (operation === 'NOT_CONTAINS') {
      if (inputType === 'dropdown') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться только на тех страницах, где в адресе есть "basket", а на всех остальных не будет.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться только на тех страницах, где в адресе нет "basket", а на всех остальных не будет.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться только на тех страницах, где в адресе нет "basket", а на всех остальных не будет.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться только на тех страницах, где в адресе нет "basket", а на всех остальных не будет.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться только на тех страницах, где в адресе нет "basket", а на всех остальных не будет.';
      }

      if (inputType === 'input') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Виджет не будет показываться на тех страницах, где нет в адресе указанного значения. Если вы хотите, чтобы виджет показывался везде, то просто впишите в поле прочерк или пробел и перейдите к следующему шагу.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Виджет будет показываться на тех страницах, где нет в адресе указанного значения.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Тогда виджет будет показываться, если посетитель на странице, где в адресе нет указанных значений, например, basket, а раннее был на странице из прошлого условия.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Виджет будет показываться на тех страницах, где нет в адресе указанного значения.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Виджет будет показываться на тех страницах, где нет в адресе указанного значения.';
      }
    }

    if (operation === 'START_WITH') {
      if (inputType === 'dropdown') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Тогда виджет не будет показываться на всех страницах, которые так начинаются.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Тогда виджет будет показываться на всех страницах, которые так начинаются.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Тогда виджет будет показываться на всех страницах, которые так начинаются.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Тогда виджет будет показываться на всех страницах, которые так начинаются.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Тогда виджет будет показываться на всех страницах, которые так начинаются.';
      }

      if (inputType === 'input') {
        if (
          (type === 'ScenarioTemplate_KeepOnTheSiteImpl' ||
            type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Виджет не показываем'
        )
          return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы не хотели бы, чтобы показывался виджет. Если вы хотите, чтобы виджет показывался везде, то просто впишите в поле прочерк или пробел и перейдите к следующему шагу.';

        if (
          (type === 'ScenarioTemplate_NewOrders' ||
            type === 'ScenarioTemplate_FirstOrderImpl') &&
          groupCaption === 'Показ виджета'
        )
          return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы хотели бы, чтобы показывался виджет.';

        if (type === 'ScenarioTemplate_MotivateAtNextOrderImpl')
          return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы хотели бы, чтобы показывался виджет. Виджет будет показываться на страницах которые начинаются указанным образом и если посетитель до этого заходил на страницы из прошлого условия.';

        if (
          type === 'ScenarioTemplate_ImportantNewsImpl' ||
          type === 'ScenarioTemplate_Bonus4RegistrationImpl' ||
          type === 'ScenarioTemplate_HolidaysImpl'
        )
          return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы хотели бы, чтобы показывался виджет.';

        if (type === 'ScenarioTemplate_FeedbackImpl')
          return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы хотели бы, чтобы показывался виджет.';
      }
    }
  }

  if (groupName === 'После истечения времени') {
    if (
      inputType === 'disabled-input' &&
      type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
    )
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';

    if (
      inputType === 'disabled-input' &&
      type === 'ScenarioTemplate_Bonus4RegistrationImpl'
    )
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';

    if (
      (type === 'ScenarioTemplate_NewOrders' ||
        type === 'ScenarioTemplate_FirstOrderImpl') &&
      inputType === 'input'
    )
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';

    if (
      type === 'ScenarioTemplate_MotivateAtNextOrderImpl' &&
      inputType === 'input'
    )
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';

    if (
      type === 'ScenarioTemplate_Bonus4RegistrationImpl' &&
      inputType === 'input'
    )
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';

    if (type === 'ScenarioTemplate_FeedbackImpl' && inputType === 'input')
      return 'Вы можете установить время, через которое виджет появится на странице. Например, если вы настроили 5 секунд, то посетитель, зайдя на страницу, где должен быть виджет, увидит его через 5 секунд.';
  }

  if (groupName === 'Посещение страниц(ы)') {
    if (
      inputType === 'disabled-input' &&
      type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
    )
      return 'Укажите здесь страницу или страницы, на которые посетитель должен зайти, чтобы начать. Виджет будет показан, если посетитель заходил на эту страницу.';

    if (operation === 'EQUAL') {
      if (
        inputType === 'dropdown' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'Вставьте полный url адрес страницы, включая http и https. Виджет будет показываться, если посетитель до этого заходил на указанную страницу.';

      if (
        inputType === 'input' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'В поле необходимо вписать одну полную ссылку, включающую http или https. Виджет будет показываться только на этой странице. Виджет будет показываться, если посетитель до этого заходил на указанную страницу.';
    }

    if (operation === 'CONTAINS') {
      if (
        inputType === 'dropdown' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'Вставьте одинаковую часть url адреса, которая содержится на каждой из страниц. Данный вариант подходит, если у вас все страницы, например, корзины имеют немного разные адреса. Если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться, если посетитель до этого заходил на страницы, где в адресе есть указанное значение, например, basket.';

      if (
        inputType === 'input' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'В поле необходимо вписать неизменяемую часть, содержащуюся в адресе страниц. Виджет будет показываться, если посетитель до этого заходил на страницы, где в адресе есть указанное значение.';
    }

    if (operation === 'NOT_CONTAINS') {
      if (
        inputType === 'dropdown' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'Вставьте одинаковую часть url адреса, которая не должна содержаться на каждой из страниц. Данный вариант подходит, если у вас все страницы корзины имеют немного разные адреса. Например, если адреса выглядят следующим образом "https://www.example.ru/categories1/basket/1234", "https://www.example.ru/categories2/basket/345", то в поле необходимо вписать "basket". Тогда виджет будет показываться, если посетитель до этого заходил на страницы, где в адресе нет указанных значений, например, basket.';

      if (
        inputType === 'input' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'В поле необходимо вписать неизменную часть, содержащуюся в адресе страниц. Виджет будет показываться, если посетитель до этого заходил на страницы, где в адресе нет указанного значения.';
    }

    if (operation === 'START_WITH') {
      if (
        inputType === 'dropdown' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'Вам необходимо вставить одинаковую часть ссылки, с которой начинается каждая страница. Данный вариант подходит, если у вас все страницы, например, корзины имеют одинаковое начало адреса. Если адреса выглядят следующим образом "https://www.example.ru/basket/1234", "https://www.example.ru/basket/345", то в поле необходимо вписать "https://www.example.ru/basket/. Виджет будет показываться, если посетитель до этого заходил на страницы, которые начинались указанным образом.';

      if (
        inputType === 'input' &&
        type === 'ScenarioTemplate_MotivateAtNextOrderImpl'
      )
        return 'В поле необходимо вписать начало ссылки, начиная с http или https, на страницах которых вы хотели бы, чтобы показывался виджет. Виджет будет показываться, если посетитель до этого заходил на страницы, которые начинались указанным образом.';
    }
  }

  if (groupName === 'Посещение любой страницы сайта') {
    if (inputType === 'disabled-input') {
      if (
        type === 'ScenarioTemplate_ImportantNewsImpl' ||
        type === 'ScenarioTemplate_Bonus4RegistrationImpl'
      )
        return 'Виджет будет показываться вне зависимости от того, с какой страницы посетитель начал свой путь на вашем сайте';

      if (type === 'ScenarioTemplate_FeedbackImpl')
        return 'Виджет будет показываться вне зависимости от того, с какой страницы посетитель начал свой путь на вашем сайте';
    }
  }

  if (groupName === 'Период показа виджета') {
    if (type === 'ScenarioTemplate_HolidaysImpl') {
      if (inputType === 'disabled-input')
        return 'Укажите период показа виджета для вашего клиента';

      if (inputType === 'input')
        return 'Нажмите на поле ввода дат и введите даты вручную через точку или при помощи календаря. Виджет будет показываться по последнее число включительно.';
    }
  }

  return undefined;
};

const allItems: Item[][] = [
  [
    {
      id: '1',
      text: 'Виджет будет показываться на всех страницах вашего сайта, поэтому дополнительные условия в блоке "Показ виджета" вам устанавливать не нужно.',
      src: 'https://www.youtube.com/embed/N3FpqLC-_tc',
    },
    {
      id: '2',
      text: 'Укажите в блоке "Виджет не показываем" одну или несколько страниц, на которых не должен появиться виджет.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и время, через которое должен появиться этот виджет на экране.',
      src: 'https://www.youtube.com/embed/IdVq3VUSG0A',
    },
    {
      id: '2',
      text: 'Укажите в блоке "Виджет не показываем" одну или несколько страниц, на которых не должен появиться виджет.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и время, через которое должен появиться этот виджет на экране.',
      src: 'https://www.youtube.com/embed/bDo6k6kNrkY',
    },
    {
      id: '2',
      text: 'Укажите в блоке "Виджет не показываем" одну или несколько страниц, на которых не должен появиться виджет.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и время, через которое должен появиться этот виджет на экране. Алгоритм работы следующий:',
      src: 'https://www.youtube.com/embed/rgOH-rZ8P0c',
    },
    {
      id: '1',
      text: '',
      children: [
        {
          id: '1',
          text: 'проверяется факт перехода на первую из указанных страниц,',
        },
        {
          id: '2',
          text: 'проверяется факт перехода на вторую из указанных страниц и Виджет появляется на второй странице.',
        },
      ],
    },
    {
      id: '4',
      text: 'Если вы хотите, чтобы Виджет показывался в любом случае на странице и условие проверки предыдущего шага не срабатывало, укажите одну и ту же страницу в обоих полях (ту, на которой должен появиться виджет).',
    },
    {
      id: '5',
      text: 'Укажите в блоке "Виджет не показываем" одну или несколько страниц, на которых не должен появиться виджет.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет.',
      src: 'https://www.youtube.com/embed/h3jcgrbiKwg',
    },
    {
      id: '2',
      text: 'Виджет будет показываться всегда на тех страницах, которые вы указали, поэтому дополнительные условия "не показа" вам устанавливать не нужно.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и время, через которое должен появиться этот виджет на экране.',
      src: 'https://www.youtube.com/embed/uhqPvlLmtso',
    },
    {
      id: '2',
      text: 'Виджет будет показываться всегда на тех страницах, которые вы указали, поэтому дополнительные условия "не показа" вам устанавливать не нужно.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и даты, в которые он должен появляться на сайте. В другие дни виджет появляться на сайте не будет, даже если посетитель зашел на указанную вами страницу.',
      src: 'https://www.youtube.com/embed/SLQDlzaqQmM',
    },
    {
      id: '2',
      text: 'Виджет будет показываться всегда на тех страницах, которые вы указали, поэтому дополнительные условия "не показа" вам устанавливать не нужно.',
    },
  ],
  [
    {
      id: '1',
      text: 'Укажите в блоке "Показ виджета" одну или несколько страниц, на которых должен появиться виджет и время, через которое должен появиться этот виджет на экране.',
    },
    {
      id: '2',
      text: 'Виджет будет показываться всегда на тех страницах, которые вы указали, поэтому дополнительные условия "не показа" вам устанавливать не нужно.',
    },
  ],
];

const createTextElement = (items: Item[]) => (
  <>
    На данном шаге вы можете настроить условия, когда виджет отобразится на
    сайте.
    <br />
    <br />
    <ol className="scenario-editor-steps__drawer-list">
      {items.map((item) => {
        if (item.children) {
          return (
            <ol key={item.id} className="scenario-editor-steps__drawer-list">
              {item.children.map(child => (
                <li
                  className="scenario-editor-steps__drawer-list-item"
                  key={child.id}
                >
                  {child.text}
                </li>
              ))}
            </ol>
          );
        }

        return (
          <li key={item.id} className="scenario-editor-steps__drawer-list-item">
            {item.text}
          </li>
        );
      })}
    </ol>
  </>
);

const getDrawerText = (type: string | JSX.Element) => {
  switch (type) {
    case 'ScenarioTemplate_KeepOnTheSiteImpl':
      return allItems[0][0].src;
    case 'ScenarioTemplate_NewOrders':
      return allItems[1][0].src;
    case 'ScenarioTemplate_FirstOrderImpl':
      return allItems[2][0].src;
    case 'ScenarioTemplate_MotivateAtNextOrderImpl':
      return allItems[3][0].src;
    case 'ScenarioTemplate_ImportantNewsImpl':
      return allItems[4][0].src;
    case 'ScenarioTemplate_Bonus4RegistrationImpl':
      return allItems[5][0].src;
    case 'ScenarioTemplate_HolidaysImpl':
      return allItems[6][0].src;
    case 'ScenarioTemplate_FeedbackImpl':
      return createTextElement(allItems[7]);
    default:
      return undefined;
  }
};

const ScenarioEditorSteps: React.FC<Props> = observer((props) => {
  const {
    steps,
    onChange,
    showHowToConfigure,
    setConfigureText,
    type,
    addCurrentValidationStatus,
  } = props;

  const updateSteps = useCallback(
    (
      groupIndex: number,
      stepIndex: number,
      value: Partial<IScenarioTemplateStepBase>
    ) => {
      const newSteps: IScenarioTemplateStepGroupBase[] = copy(steps);
      newSteps[groupIndex].steps[stepIndex] = Object.assign(
        newSteps[groupIndex].steps[stepIndex],
        value
      );

      onChange(newSteps);
    },
    [onChange, steps]
  );

  if (!steps) return null;

  const handleClick = () => {
    setConfigureText(getDrawerText(type));
    showHowToConfigure();
    if (type !== 'ScenarioTemplate_FeedbackImpl') {
      setGtm('video_conditions');
    }
  };

  return (
    <div className="scenario-editor-steps">
      <ScenarioEditorConfigure
        onClick={handleClick}
        text="На данном шаге вы можете настроить условия, когда виджет отобразится на сайте"
        hasPlayButton={typeof getDrawerText(type) === 'string'}
      />
      <div className="scenario-editor-steps__title">Начало сценария</div>

      {steps.map((group, groupIndex) => (
        <div className="scenario-editor-steps__group" key={groupIndex}>
          {group.steps.map((step, stepIndex) => (
            <div className="scenario-editor-step" key={stepIndex}>
              <div className="scenario-editor-step__wrapper">
                {step['@type'] === 'TimestampIntervalStep' && (
                  <TimestampIntervalStep
                    step={step}
                    type={type}
                    group={group}
                    onChangeValue={(dates: [Moment, Moment]) => {
                      const [momentDateFrom, momentDateTo] = dates;
                      const from = momentDateFrom.format(DATE_FORMAT);
                      const to = momentDateTo.format(DATE_FORMAT);
                      updateSteps(groupIndex, stepIndex, { from, to });
                    }}
                    groupIndex={groupIndex}
                    stepIndex={stepIndex}
                    addCurrentValidationStatus={addCurrentValidationStatus}
                  />
                )}

                {step['@type'] === 'AnyPageStep' && (
                  <InputWrapper
                    className="scenario-editor-step__input"
                    elemAfter={
                      getHint(
                        'Посещение любой страницы сайта',
                        'disabled-input',
                        '',
                        type,
                        group.ui.caption
                      ) ? (
                        <IconWithTooltip
                          tooltipProps={{
                            overlayClassName:
                              'scenario-editor-step__tooltip-overlay',
                            placement: 'topRight',
                            title: getHint(
                              'Посещение любой страницы сайта',
                              'disabled-input',
                              '',
                              type,
                              group.ui.caption
                            ),
                          }}
                          iconProps={{
                            name: 'Question',
                            className: 'scenario-editor-step__tooltip-icon',
                          }}
                        />
                      ) : undefined
                    }
                    disabled
                  >
                    Посещение любой страницы сайта
                  </InputWrapper>
                )}

                {step['@type'] === 'ExitStep' && (
                  <InputWrapper
                    className="scenario-editor-step__input"
                    disabled
                  >
                    Попытка ухода с сайта
                  </InputWrapper>
                )}

                {step['@type'] === 'UrlStep' && (
                  <UrlStep
                    step={step}
                    type={type}
                    group={group}
                    onChangeSeparator={operation =>
                      updateSteps(groupIndex, stepIndex, { operation })
                    }
                    onChangeUrl={url =>
                      updateSteps(groupIndex, stepIndex, { url })
                    }
                    groupIndex={groupIndex}
                    stepIndex={stepIndex}
                    addCurrentValidationStatus={addCurrentValidationStatus}
                  />
                )}

                {(step['@type'] === 'IdleStep' ||
                  step['@type'] === 'TimeStep') && (
                  <TimeStep
                    step={step}
                    type={type}
                    group={group}
                    updateSteps={(unit) => {
                      updateSteps(groupIndex, stepIndex, {
                        timeUnitType: unit,
                      });
                    }}
                    onChangeValue={(timeout) => {
                      updateSteps(groupIndex, stepIndex, {
                        timeout:
                          timeout === ''
                            ? undefined
                            : Math.round(Number(timeout)),
                      });
                    }}
                    groupIndex={groupIndex}
                    stepIndex={stepIndex}
                    addCurrentValidationStatus={addCurrentValidationStatus}
                  />
                )}
              </div>
            </div>
          ))}

          <div
            className={cx('scenario-editor-step', {
              '_steps-to-show': groupIndex === 0,
              '_steps-to-hide': groupIndex === 1,
            })}
          >
            <Icon
              className="scenario-editor-step__icon"
              name={groupIndex === 0 ? 'Check' : 'Close'}
            />

            {group.ui.caption}
          </div>
        </div>
      ))}
    </div>
  );
});

export default ScenarioEditorSteps;
