import { observer } from 'mobx-react';
import React from 'react';
import MailingAuthorizationEmailBlock from '~/components/generic/MailingAuthorizationEmailBlock';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';

const AuthorizationEmail: React.FC = observer(() => {
  const {
    mailingInstance,
  } = massMailingStore;

  const setEmail = (address: string, registered: boolean) => {
    mailingInstance.update({
      authorizationEmail: {
        ...mailingInstance?.get('authorizationEmail'),
        address,
        registered,
      },
    });
  };

  return (
    <MailingAuthorizationEmailBlock
      currentEmailAddress={mailingInstance?.get('authorizationEmail')?.address}
      currentEmailRegistered={mailingInstance?.get('authorizationEmail')?.registered}
      onSetEmail={setEmail}
    />
  );
});

export default AuthorizationEmail;
