import cx from 'classnames';
import * as React from 'react';
import { IOption, ISelect } from '~/components/Select/index';
import './style.css';

export const Select: React.FC<ISelect> = ({
  options,
  selected,
  handleClick,
  className,
}) => {
  const sortedOptions = options.sort(
    (a, b) => Number(a.value) - Number(b.value)
  );

  return (
    <div className={cx('select', className)}>
      <select className="select__input" value={selected} onChange={handleClick}>
        {sortedOptions.map((option: IOption) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      <img
        src="../../assets/landing/dropdown.svg"
        alt=""
        width="24"
        height="24"
      />
    </div>
  );
};
