import { observer } from 'mobx-react';
import React from 'react';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IRecTypeComponentProps {
  recType: RecType;
  title: string;
  description: string;
}

const RecTypeComponent: React.FC<IRecTypeComponentProps> = observer(
  ({ recType, title, description }) => {
    const { feedApiStore } = useStores() as {
      feedApiStore: FeedApiStore;
    };
    const { feedApi, setIsShowTabooTypeChangesMessage } = feedApiStore;

    const updateRecType = (recType: RecType) => {
      if (!feedApi.get('id')) {
        feedApi.update({ 
          recType,
          recommendationGenerationType: recType === 'POPULAR' ? 'OVER_ALL' : 'BY_OFFER',
        });

        return;
      }
      setIsShowTabooTypeChangesMessage(true);
    };

    const isCheckedType = feedApi.get('recType') === recType;

    return (
      <div onClick={() => updateRecType(recType)}>
        <NumberedBlock
          className={`${styles.numbered_block} ${
            isCheckedType ? styles.checked_block : ''
          }`}
        >
          <div className={styles.type_block}>
            <div className={styles.icon_wrapper}>
              <Icon name={isCheckedType ? 'CheckedCircle' : 'EmptyCircle'} />
            </div>
            <div className={styles.description_wrapper}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
          </div>
        </NumberedBlock>
      </div>
    );
  }
);

export default RecTypeComponent;
