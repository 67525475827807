import { IAction } from '~/components/generic/Promo/Actions';
import { getActionButton } from '~/components/generic/Promo/utilities';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

export const emptyScreenButtonsCreator = (
  hasRight: boolean
): IAction[] => {
  const { feedProStore } = useStores() as {
    feedProStore: FeedProStore;
  };

  return getActionButton(
    [hasRight, !hasRight, true],
    [
      {
        onClick: feedProStore.createFeedPro,
        icon: 'Plus',
        type: 'default',
        name: 'Создать ленту рекомендаций с ИИ',
      }, {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default',
        name: 'Оставить заявку на подключение',
      },
    ]
  );
};