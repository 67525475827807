import { hexToRgb } from '~/utils/hexUtils';

export const htmlLogoImage = ({
  imagePath,
  imageSize,
  backgroundColor,
  backgroundOpacity,
}: IMailingImage) => {

  return `
  <mj-wrapper
    padding="20px 20px 0 20px"
    border-radius="12px 12px 0 0"
    background-color="${hexToRgb(backgroundColor, backgroundOpacity)}"
  >
    <mj-section
    background-position="top-left"
    background-url="${imagePath}"
    background-size="${imageSize}"
    background-repeat="no-repeat"
    padding="0px 0px"
    >
    <mj-column>
      <mj-text
      height='60px'
      padding="0px"></mj-text>
    </mj-column>
  </mj-section>
</mj-wrapper>
`;
};
