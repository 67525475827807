import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentPriceCssParam = (configuration: IFeedConfiguration): string => {
  const {
    cardPriceFontFamily,
    cardPriceColor,
    cardPriceOpacity,
    cardPriceFontWeight,
    cardPriceFontSize,
    cardPriceFontItalic,
    cardPriceTextLineThrough,
    cardPriceTextUnderline,
  } = configuration;
  
  return `
    font-family: ${cardPriceFontFamily};
    color: ${hexToRgb(
      cardPriceColor,
      cardPriceOpacity
    )};
    font-weight: ${fontWeightValues[cardPriceFontWeight]};
    font-size: ${cardPriceFontSize}px;
    font-style: ${cardPriceFontItalic
      ? 'italic'
      : 'normal'
    };
    text-decoration: ${getTextDecoration(
      cardPriceTextUnderline,
      cardPriceTextLineThrough
    )};
    margin-right: 9px;`
    .replace(/(\r\n|\n|\r|↵)/gm, '')
    .trim();
};