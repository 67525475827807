import { IPromo } from '~/components/generic/Promo';

export const items = [
  {
    iconSrc: 'DemoLite',
    text: 'Добавляйте товары или услуги из вашего каталога',
  },
  {
    iconSrc: 'DemoLite',
    text: 'Выбирайте страницы и место отображения ленты',
  },
  {
    iconSrc: 'DemoLite',
    text: 'Настраивайте дизайн',
  },
  {
    iconSrc: 'DemoLite',
    text: 'Отслеживайте встроенную статистику эффективности работы',
  },
];

export const promoMaterials: Partial<IPromo> = {
  header: 'Добро пожаловать в конструктор лент рекомендаций lite',
  subheader:
    'Всего в несколько кликов и без помощи ИТ-специалиста вы можете создать ленту подборок товаров или услуги для увеличения продаж и среднего чека. Настройка ленты займет не более пяти минут:',
  img: '/assets/images/promo/recommendationsLite.png',
  items,
};
