import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';

interface IHorizontalScroll {
  children: JSX.Element;
  className?: string;
  outerStyles?: { [key: string]: string };
  ref?: any;
}

function addHorizontalScroll(
  blockId: string,
  shadow1Ref: React.Ref<any>,
  shadow2Ref: React.Ref<any>,
  className1: string,
  className2: string
) {
  const block = document.getElementById(blockId);

  block.addEventListener('wheel', (evt) => {
    const maxScroll = block.scrollWidth - block.offsetWidth;
    const currentScroll = block.scrollLeft + evt.deltaY;

    // @ts-ignore
    shadow1Ref.current.classList.add(className1);
    // @ts-ignore
    shadow2Ref.current.classList.add(className2);

    if (currentScroll > 0 && currentScroll < maxScroll) {
      evt.preventDefault();
      block.scrollLeft = currentScroll;
    } else if (currentScroll <= 0) {
      block.scrollLeft = 0;
      // @ts-ignore
      shadow1Ref.current.classList.remove(className1);
    } else {
      block.scrollLeft = maxScroll;
      // @ts-ignore
      shadow2Ref.current.classList.remove(className2);
    }
  });
}

export const HorizontalScroll = ({
  children,
  className,
  outerStyles,
  ref,
}: IHorizontalScroll) => {
  const id = 'scrollId' + Math.ceil(Math.random() * 1000);
  const shadow1Ref = useRef(null);
  const shadow2Ref = useRef(null);

  useEffect(() => {
    addHorizontalScroll(
      id,
      shadow1Ref,
      shadow2Ref,
      styles.shadow1,
      styles.shadow2
    );
  }, []);

  return (
    <div
      className={cx(styles.horizontalScrollWrapper, className)}
      style={{ ...outerStyles }}
      ref={ref}
    >
      <div className={styles.horizontalScroll} id={id}>
        {children}
      </div>
      <div ref={shadow1Ref} />
      <div className={styles.shadow2} ref={shadow2Ref} />
    </div>
  );
};
