import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import { getHint } from '~/components/ScenarioEditor/ScenarioEditorSteps';
import DateRangePicker from '~/components/ui/DateRangePicker';
import FormItem from '~/components/ui/FormItem';
import InputWrapper from '~/components/ui/InputWrapper';
import './style.css';

interface Props {
  step: IScenarioTemplateStepTimestampInterval;
  type: string;
  group: IScenarioTemplateStepGroupBase;
  onChangeValue: (dates: [Moment, Moment]) => void;
  groupIndex: number;
  stepIndex: number;
  addCurrentValidationStatus: (groupIndex: number, stepIndex: number, status: boolean) => void;
}

const TimestampIntervalStep: React.FC<Props> = React.memo(({
  step,
  type,
  group,
  onChangeValue,
  groupIndex,
  stepIndex,
  addCurrentValidationStatus,
}) => {
  const [failure, toggleFailure] = useState(false);

  const validation = (dates: [Moment, Moment]): boolean => {
    // TODO - можно добавить более сложную валидацию
    if (dates[0] !== null && dates[1] !== null) return true;

    return false;
  };

  const handleChangeValue = (dates: [Moment, Moment]) => {
    const resultValidation = validation(dates);
    toggleFailure(!resultValidation);
    addCurrentValidationStatus(groupIndex, stepIndex, resultValidation);
    if (dates[0] !== null && dates[1] !== null) onChangeValue(dates);
  };

  useEffect(() => {
    handleChangeValue([
      step.from ? moment(step.from) : null,
      step.to ? moment(step.to) : null,
    ]);
  }, []);

  return (
    <>
      <InputWrapper
        className="scenario-editor-step__input"
        elemAfter={
          getHint('Период показа виджета', 'disabled-input', '', type, group.ui.caption) ? (
            <IconWithTooltip
              tooltipProps={{
                overlayClassName: 'scenario-editor-step__tooltip-overlay',
                placement: 'topRight',
                title: getHint('Период показа виджета', 'disabled-input', '', type, group.ui.caption),
              }}
              iconProps={{
                name: 'Question',
                className: 'scenario-editor-step__tooltip-icon',
              }}
            />
          ) : undefined}
        disabled
      >
        {step.ui.caption}
      </InputWrapper>
      <div className="scenario-editor-step__separator" />
      <FormItem
        className="scenario-editor-step-timestampinterval__form-item"
        failure={failure}
        message={failure ? 'Обязательное' : ''}
        noReservedPlaceForMessage
      >
        <DateRangePicker
          className="scenario-editor-step-timestampinterval__date-interval"
          failure={failure}
          filled={false}
          key="dates"
          value={[
            step.from ? moment(step.from) : null,
            step.to ? moment(step.to) : null,
          ]}
          onChange={handleChangeValue}
        />
      </FormItem>
      {getHint('Период показа виджета', 'input', '', type, group.ui.caption) && (
        <IconWithTooltip
          tooltipProps={{
            overlayClassName: 'scenario-editor-step__tooltip-overlay',
            placement: 'topRight',
            title: getHint('Период показа виджета', 'input', '', type, group.ui.caption),
          }}
          iconProps={{
            name: 'Question',
            className: 'scenario-editor-step__tooltip-icon',
          }}
          iconWrapperClassName="scenario-editor-step__tooltip-icon-wrapper"
        />
      )}
      <div className="scenario-editor-step__spacer" />
    </>
  );
});

export default TimestampIntervalStep;