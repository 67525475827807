import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '~/components/Icon';
import Caption from '~/components/generic/Caption';
import FeedStatisticTable from '~/components/generic/FeedStatisticTable';
import Footer from '~/screens/FeedProScreen/components/FeedProStatistics/Footer';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import LoadingScreen from '~/screens/LoadingScreen';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import './style.css';
import styles from './style.module.css';

const FeedTable: React.FC = observer(() => {
  const { feedProStore } = useStores() as { feedProStore: FeedProStore };
  const {
    feedAmount,
    feedLimit,
    createFeedPro,
    feedStatistics,
    onClickActionButton,
    isFetchingRequest,
  } = feedProStore;

  const tableRef = useRef<HTMLDivElement>();
  const [heightTableBlock, setHeightTableBlock] = useState<number>(0);
  const [wightTableBlock, setWightTableBlock] = useState<number>(0);

  useEffect(() => {
    setHeightTableBlock(tableRef.current?.getBoundingClientRect().height);
    setWightTableBlock(tableRef.current?.getBoundingClientRect().width);
  }, []);

  return (
    <Screen
      header={'Ленты рекомендаций Pro'}
      footer={(
        <Footer
          amount={feedAmount}
          limit={feedLimit}
          create={createFeedPro}
        />
      )}
      noPadding
    >
      <Caption
        className="feed-pro-screen-table__caption"
        backgroundColor={'grey'}
      >
        <div className={styles.information}>
          <div className={styles.icon}>
            <Icon name={'ChartLine'} />
          </div>
          <div className={styles.text}>
            Расчет статистики ведется с момента первого запуска ленты по
            настоящее время
          </div>
        </div>
      </Caption>
      <div ref={tableRef} className="feed-screen-table__wrapper">
        {heightTableBlock !== 0 ? (
          <FeedStatisticTable
            heightTableBlock={heightTableBlock}
            wightTableBlock={wightTableBlock}
            statistics={feedStatistics}
            onClickActionButton={onClickActionButton}
            isFetchingRequest={isFetchingRequest}
          />
        ) : (
          <LoadingScreen />
        )}
      </div>
    </Screen>
  );
});

export default FeedTable;
