import { observer } from 'mobx-react';
import React from 'react';
import Icon from '~/components/Icon';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';
import Message from '~/components/ui/Message';
import styles from './style.module.css';

const SendSayMessages: React.FC = observer(
  () => {
    const {
      isErrorMessageShown,
      setIsErrorMessageShown,
      errorMessage,
      isSuccessWindowShow,
      setIsSuccessWindowShow,
    } = SendSayAccountElementsStore;

    return (
      <>
        {isErrorMessageShown && (
          <Message
            visible
            showIcon={false}
            onApply={() => {
              setIsErrorMessageShown(false);
            }}
            applyText={'Хорошо'}
            title={errorMessage.title}
            textTop={errorMessage.body as string}
          />
        )}
        {isSuccessWindowShow && (
          <Message
            visible
            showIcon={false}
            onApply={() => {
              setIsSuccessWindowShow(false);
            }}
            applyText={'Хорошо'}
            title={(
              <div>
                <Icon name={'CheckmarkXxl'} className={styles.popupIcon} />
                Учетная запись Sendsay успешно прикреплена.
              </div>
            )}
          />
        )}
      </>
    );
  }
);

export default SendSayMessages;
