import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import styles from './styles.module.css';

interface IFloatTableSwapButtons {
  className: string;
  setXPosition: (xPosition: number) => void;
  xPosition: number;
}

export const FloatTableSwapButtons = ({
  className,
  setXPosition,
  xPosition = 0,
}: IFloatTableSwapButtons) => {
  const onArrowLeft = (e: any) => {
    e.stopPropagation();
    setXPosition(xPosition + 200);
  };

  const onArrowRight = (e: any) => {
    e.stopPropagation();
    setXPosition(xPosition - 200);
  };

  return (
    <div className={cx(styles.swapButtons, className)}>
      <div className={cx(styles.swapButton, className)} onClick={onArrowLeft}>
        <Icon className={styles.arrow} name={'ArrowMLeft'} />
      </div>
      <div className={cx(styles.swapButton, className)} onClick={onArrowRight}>
        <Icon className={styles.arrow} name={'ArrowMRight'} />
      </div>
    </div>
  );
};
