const PHONE_HEIGHT = 666;
const PHONE_WIDTH = 344;

export const computeDesctopFrameSize = (
  frameContainer: React.MutableRefObject<HTMLDivElement>,
  frameRef: React.MutableRefObject<HTMLIFrameElement> | React.MutableRefObject<HTMLDivElement>
) => {
  const frameContainerWidth =
    frameContainer.current?.getBoundingClientRect().width || 0;
  const scale = frameContainerWidth / 1200;
  const heightForFrame =
    frameContainer.current?.getBoundingClientRect().height || 0;
  if (frameRef.current) {
    frameRef.current.style.transform = `scale(${
      Math.floor(scale * 1000) / 1000
    })`;
    frameRef.current.style.minHeight = `${
      Math.floor((heightForFrame / scale) * 1000) / 1000
    }px`;
  }
};

export const computeMobileFrameSize = (
  frameContainer: React.MutableRefObject<HTMLDivElement>,
  mobileContainerRef: React.MutableRefObject<HTMLDivElement>,
  mobileFrameRef: React.MutableRefObject<HTMLIFrameElement> | React.MutableRefObject<HTMLDivElement>
) => {
  const heightForFrame =
    frameContainer.current?.getBoundingClientRect().height || 0;
  if (mobileContainerRef.current) {
    mobileContainerRef.current.style.height = `${heightForFrame}px`;
    if (mobileFrameRef.current) {
      mobileFrameRef.current.style.height = `${
        (PHONE_HEIGHT - 24) / ((PHONE_WIDTH - 24) / 375)
      }px`;
      mobileFrameRef.current.style.top = `calc(50% - ${
        (PHONE_HEIGHT - 24) / 2
      }px)`;
      mobileFrameRef.current.style.borderRadius = `${
        32 / (PHONE_WIDTH / 375)
      }px`;
      mobileFrameRef.current.style.left = `calc(50% - ${
        PHONE_WIDTH / 2 - 12
      }px)`;
      mobileFrameRef.current.style.transform = `scale(calc(${
        PHONE_WIDTH - 24
      } / 375))`;
    }

    if (mobileContainerRef.current.getBoundingClientRect().height < 708) {
      mobileContainerRef.current.style.alignItems = 'flex-start';
      mobileContainerRef.current.style.padding = '54px 0';
      mobileContainerRef.current.style.overflow = 'auto';

      if (mobileFrameRef.current) {
        mobileFrameRef.current.style.top = '20px';
      }
    } else {
      mobileContainerRef.current.style.alignItems = null;
      mobileContainerRef.current.style.padding = null;
      mobileContainerRef.current.style.overflow = null;

      if (mobileFrameRef.current) {
        mobileFrameRef.current.style.top = `calc(50% - ${
          (PHONE_HEIGHT - 24) / 2
        }px)`;
      }
    }
  }
};
