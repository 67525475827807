import React from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import SignOfUnificationDropdown from '~/components/generic/SignOfUnificationDropdown';
import FormItem from '~/components/ui/FormItem';
import RadioGroup from '~/components/ui/RadioButton/RadioGroup';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import { DROPDOWN_ITEMS } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/constants';
import { CheckedCountPage } from '~/screens/FeedProScreen/store/feedProStore';
import './../style.css';

interface IPersonalEmbeddingProps {
  urlPattern: string;
  onChange: ({}: IPlacementPageParams) => void;
  isInvalid: boolean;
  onBlur: () => void;
  errorMessage: string;
  checkedCountPage: CheckedCountPage;
  operation: Operation;
  url: string;
}

const PersonalEmbedding: React.FC<IPersonalEmbeddingProps> = ({
  urlPattern,
  onChange,
  isInvalid,
  onBlur,
  errorMessage,
  checkedCountPage,
  operation,
  url,
}) => {

  return (
    <div className="feed-editor-placement-pages">
      <img 
        className="feed-editor-placement-pages__img"
        src={'/assets/feed/placement_personal_example.png'}
      />
      <NumberedBlock>
        <FormItem
          title="Где вы хотите разместить ленту?"
          noHTMLLabel
          noReservedPlaceForMessage
          className="feed-editor-placement-pages__form-item"
        />
        <RadioGroup
          className="feed-editor-placement-pages__radio-group"
          size="medium"
          onCheck={(checkedCountPage) => {
            onChange({ checkedCountPage: checkedCountPage as CheckedCountPage });
          }}
          items={[
            { label: 'На всех карточках товара', value: 'MULTIPLE' },
            { label: 'На страницах, указанных вручную', value: 'ONE' },
          ]}
          checkedItem={checkedCountPage}
        />
      </NumberedBlock>

      {checkedCountPage === 'ONE' && (
        <NumberedBlock>
          <SignOfUnificationDropdown
            title="Укажите условия, на каких страницах показывать ленту"
            operation={operation}
            onChangeSeparator={(operation) => {
              onChange({
                checkedCountPage: checkedCountPage as CheckedCountPage,
                urlPattern,
                url,
                operation: operation as Operation,
              });
            }}
            url={url}
            onChangeDisplayConditions={(url) => {
              onChange({
                checkedCountPage: checkedCountPage as CheckedCountPage,
                urlPattern,
                url,
                operation: operation as Operation,
              });
            }}
            withFlexDirectionColumn={false}
          />
        </NumberedBlock>
      )}

      <NumberedBlock>
        <SetCatalogConfigLink
          title={
            checkedCountPage === 'ONE'
              ? `Вставьте ссылку на страницу, которая ${DROPDOWN_ITEMS.find(
                item => item.key === operation
              )?.value
              } ${url}`
              : 'Введите ссылку на любую карточку товара'
            }
          onChange={(urlPattern) => {
            onChange({
              checkedCountPage: checkedCountPage as CheckedCountPage,
              urlPattern,
              url,
              operation: operation as Operation,
            });
          }}
          value={urlPattern}
          placeholder="https://example.ru/product-1"
          tooltipText={`Ссылка на страницу должна быть полной - включая «http://» или «https://»`}
          isInvalid={isInvalid}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
        <div className="feed-editor-placement-pages__info">
          На основании этой ссылки мы автоматически распознаем остальные страницы
          всех товаров вашего сайта.&nbsp; На каждой странице товара будет своя
          лента рекомендаций.
        </div>
      </NumberedBlock>
    </div>
  );
};

export default PersonalEmbedding;
