import { observer } from 'mobx-react';
import React from 'react';
import PageHeader from '~/components/PageHeader';
import { WrapperContentDrawer } from '~/components/generic/WrapperContentDrawer';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Tabs from '~/components/ui/Menu/Tabs';
import { GetActions } from '~/screens/MailingScreen/components/MailingConstructor/GetActions';
import { GetAside } from '~/screens/MailingScreen/components/MailingConstructor/GetAside';
import {
  getMailConstructorTabsItems,
  mailingConstructorTabContent,
} from '~/screens/MailingScreen/components/MailingConstructor/utils';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';
import './style.css';

interface IMailingConstructor {
  isConstructorOpen: boolean;
}

export const MailingConstructor: React.FC<IMailingConstructor> = observer(
  ({ isConstructorOpen }) => {
    const { mailingStore } = useStores() as {
      mailingStore: MailingStore;
    };
    const { constructorStore, mailingInstance, isYmlValid, setIsBeforeClosePopup } = mailingStore;

    const onClose = () => {
      setIsBeforeClosePopup(true);
    };

    const tab2Disabled = !mailingInstance?.get('type');
    const tab3Disabled =
      !mailingInstance?.get('ymlCatalog') ||
      !isYmlValid;
    const mailingConstructorTabsItems = getMailConstructorTabsItems({
      tab2Disabled,
      tab3Disabled,
    });

    const isNewMailing = !mailingInstance?.get('id');

    return (
      <Drawer visible={isConstructorOpen} onClose={onClose} size="large">
        <div className={'mailing-constructor__body-wrapper'}>
          <PageHeader
            title={`${isNewMailing ? 'Создание' : 'Редактирование'} Email рекомендаций`}
            className="mailing-constructor__page-header"
          />
          <Tabs
            items={mailingConstructorTabsItems}
            value={constructorStore.currentStep}
            onChange={value =>
              constructorStore.changeCurrentStep(
                value as TMailingConstructorSteps
              )
            }
          />
          <WrapperContentDrawer>
            {mailingConstructorTabContent(constructorStore.currentStep)}
          </WrapperContentDrawer>
          <DrawerFooter
            className="mailing-constructor__footer"
            actions={<GetActions />}
            aside={<GetAside />}
          />
        </div>
      </Drawer>
    );
  }
);
