import React, { useEffect, useState, ReactNode } from 'react';
import Button from '~/components/ui/Button';
import styles from './style.module.css';

interface IPopupElementProps {
  children: ReactNode;
  content: IPopupContent;
  isVisible: boolean;
  onClick: () => void;
}

const PopupElement: React.FC<IPopupElementProps> = ({
  children,
  content,
  isVisible,
  onClick,
}) => {
  const [numberDisplays, setNumberDisplays] = useState<number>(0);
  const [isActivePopup, setIsActivePopup] = useState<boolean>(
    isVisible && numberDisplays === 1
  );

  useEffect(() => {
    if (isVisible && numberDisplays === 1) {
      setIsActivePopup(true);
    } else {
      setIsActivePopup(false);
    }
    setNumberDisplays(numberDisplays + 1);
  }, [isVisible]);

  const handleClick = () => {
    setIsActivePopup(false);
    onClick();
  };

  return (
    <>
      {content && isActivePopup && (
        <div className={styles.shadow} onClick={handleClick}>
          <div className={styles.box}>
            <div className={styles.wrapper}>
              <div className={styles.title}>{content.title}</div>
              <div className={styles.desc}>{content.desc}</div>
              <Button
                onClick={handleClick}
              >
                Хорошо
              </Button>
            </div>
            <div className={styles.child}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupElement;
