import cx from 'classnames';
import React from 'react';
import './style.css';

interface Props {
  size?: FormElementSize;
  className?: string;
  children?: React.ReactElement[];
  block?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

const InputGroup: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    size,
    block,
  } = props;
  const items = Array.isArray(children) ? children : [children];

  return (
    <div className={cx('input-group', { _block: block }, className)} ref={ref}>
      {items.map((child, key) => React.cloneElement(child, { size, key }))}
    </div>
  );
});

export default InputGroup;
