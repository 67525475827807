import { DatePicker } from 'antd';
import cx from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';
import './style.css';

export interface IDateRangePickerProps {
  singleDate?: boolean;
  style?: React.CSSProperties;
  className?: string;
  filled?: boolean;
  value?: Moment | [Moment, Moment];
  onChange?: (dates: Moment | [Moment, Moment], dateStrings: string | [string, string]) => void;
  noFuture?: boolean;
  noPast?: boolean;
  failure?: boolean;
  showTime?: boolean;
}

const locale: any = {
  lang: {
    placeholder: 'Выберите дату',
    rangePlaceholder: ['Начало', 'Конец'],
    locale: 'ru_RU',
    today: 'Сегодня',
    now: 'Сегодня',
    backToToday: 'Текущая дата',
    ok: 'Готово',
    clear: 'Очистить',
    month: 'Месяц',
    year: 'Год',
    timeSelect: 'Выбрать время',
    dateSelect: 'Выбрать дату',
    monthSelect: 'Выбрать месяц',
    yearSelect: 'Выбрать год',
    decadeSelect: 'Выбрать десятилетие',
    yearFormat: 'YYYY',
    dateFormat: 'D.MM.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D-M-YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Предыдущий месяц (PageUp)',
    nextMonth: 'Следующий месяц (PageDown)',
    previousYear: 'Предыдущий год (Control + left)',
    nextYear: 'Следующий год (Control + right)',
    previousDecade: 'Предыдущее десятилетие',
    nextDecade: 'Следущее десятилетие',
    previousCentury: 'Предыдущий век',
    nextCentury: 'Следующий век',
  },
  timePickerLocale: {
    placeholder: 'Выберите время',
  },
};

const disabledFuture = (current: Moment) =>
  current && current > moment().subtract(1, 'day').endOf('day');

const disabledPast = (current: Moment) =>
  current && current < moment().subtract(1, 'day').endOf('day');

const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  filled,
  value,
  onChange,
  noFuture,
  noPast,
  singleDate,
  failure,
  className,
  showTime = false,
}) => {
  const AntPicker = singleDate ? DatePicker : DatePicker.RangePicker;

  const disabledDate = noFuture ? disabledFuture : (noPast ? disabledPast : undefined);

  return (
    <div
      className={cx('date-range', className, {
        _filled: filled,
      })}
    >
      <AntPicker
        className={cx('date-range-ant', {
          _failure: failure,
        })}
        dropdownClassName="date-range-dropdown-ant"
        value={value as any}
        separator="—"
        format={showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
        locale={locale}
        allowClear={false}
        disabledDate={disabledDate}
        onChange={onChange}
        showToday={false}
        showTime={showTime ? { format: 'HH:mm' } : false}
      />
    </div>
  );
};

export default DateRangePicker;
