import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import './style.css';

interface Props {
  icon?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  hoverContent?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  iconAfter?: React.ReactNode;
  isHovered?: boolean;
}

const TableCell: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  hoverContent,
  className,
  style,
  iconAfter,
  isHovered,
}) => {
  const [hoverVisible, setHoverVisible] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHoverVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoverVisible(false);
  }, []);

  return (
    <div
      className={cx('table-cell', { _hover: (isHovered || hoverVisible) && hoverContent }, className)}
      style={style}
      onMouseEnter={isHovered ? undefined : handleMouseEnter}
      onMouseLeave={isHovered ? undefined : handleMouseLeave}
    >
      <div className="table-cell__main-content">
        {icon && (
          <div className="table-cell__icon">
            {icon}
          </div>
        )}
        <div className="table-cell__body">
          <div className="table-cell__title">
            {title}
          </div>
          {subtitle && (
            <div className="table-cell__subtitle">
              {subtitle}
            </div>
          )}
        </div>
        {iconAfter && (
          <div className="table-cell__icon-after">
            {iconAfter}
          </div>
        )}
      </div>
      {hoverContent && (
        <div className="table-cell__hover-content">
          {hoverContent}
        </div>
      )}
    </div>
  );
};

export default TableCell;
