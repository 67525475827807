import React from 'react';
import './style.css';

const Desktop: React.FC<IDesktop> = ({ frameRef, isResizing, urlPattern }) => {
  return (
    <div className="feed-editor-step-selector-device-workspace-frame-desktop__container">
      <iframe
        ref={frameRef}
        frameBorder="0"
        className="feed-editor-step-selector-device-workspace-frame-desktop__frame"
        src={urlPattern}
        style={{ display: isResizing ? 'none' : null }}
      />
    </div>
  );
};

export default Desktop;
