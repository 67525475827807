import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import { Select } from '~/components/Select/Select';
import Button from '~/components/ui/Button';
import Checkbox from '~/components/ui/CheckBox';
import Input from '~/components/ui/Input';
import RadioButton from '~/components/ui/RadioButton';
import { ImageEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/QuestionEditor/ImageEditor';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { Info } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/Info';
import { defaultImage } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/utils';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';

const selectImageSizeOptions = [
  { value: '1', text: 'Выбор верно/неверно' },
  { value: '2', text: 'Выбор одного варианта ответа' },
  { value: '3', text: 'Выбор нескольких варианта ответа' },
  { value: '4', text: 'Свободный ответ' },
];

interface ISelectAnswerType {
  oldAnswers: any[];
  currentType: string;
  setValue: (field: string, value: string | any[] | boolean) => void;
  answerTypeValue?: string;
}

const getAnswerTypeValue = (
  answerType: string,
  isRadio: boolean,
  answers?: any[]
): string => {
  if (answerType === 'INPUT') return '4';

  if (isRadio && answers && 'isRight' in answers[0] && typeof answers[0].isRight !== 'undefined') {
    return '1';
  }

  if (isRadio && answers && (!('isRight' in answers[0]) || typeof answers[0].isRight === 'undefined')) {
    return '2';
  }

  if (!isRadio) {
    return '3';
  }

  return undefined;
};

const SelectAnswerType = ({
  oldAnswers,
  currentType,
  setValue,
  answerTypeValue = '1',
}: ISelectAnswerType) => {
  const onSelect = (e: any) => {
    const type = e?.target?.value;

    if (type === '1') {
      // Верно-неверно
      setValue('answerType', 'LIST');
      setValue('radio', true);

      if (currentType === 'LIST') {
        setValue(
          'answers',
          oldAnswers.map((v, k) => ({ ...v, 'isRight': k === 0, '@type': 'RadioQuizAnswer' }))
        );
      } else {
        setValue('answers', [
          {
            '@type': 'RadioQuizAnswer',
            'uuid': '',
            'text': '',
            'checked': false,
            'isRight': true,
          },
        ]);
      }
    }

    if (type === '2') {
      setValue('answerType', 'LIST');
      setValue('radio', true);
      if (currentType === 'LIST') {
        setValue(
          'answers',
          oldAnswers.map((v, k) => {

            const answer = { ...v, '@type': 'RadioQuizAnswer' };

            delete answer.isRight;

            return ({ ...answer });
          })
        );
      } else {
        setValue('answers', [
          {
            '@type': 'RadioQuizAnswer',
            'uuid': '',
            'text': '',
            'checked': false,
          },
        ]);
      }
    }

    if (type === '3') {
      setValue('answerType', 'LIST');
      setValue('radio', false);

      if (currentType === 'LIST') {
        setValue(
          'answers',
          oldAnswers.map((v, k) => {

            const answer = { ...v, '@type': 'CheckboxQuizAnswer' };

            delete answer.isRight;

            return ({ ...answer });
          })
        );
      } else {
        setValue('answers', [
          {
            '@type': 'CheckboxQuizAnswer',
            'uuid': '',
            'text': '',
            'checked': false,
          },
        ]);
      }
    }

    if (type === '4') {
      setValue('answerType', 'INPUT');
      setValue('radio', undefined);
      setValue('answers', [
        {
          '@type': 'InputQuizAnswer',
          'uuid': 'sdfsdf',
          'text': '',
          'placeholder': 'Введите текст',
        },
      ]);
    }
  };

  return (
    <>
      <Select
        handleClick={onSelect}
        options={selectImageSizeOptions}
        selected={answerTypeValue}
        className={styles.input}
      />
    </>
  );
};

interface IQuestionEditorProps {
  onDelete: () => void;
  step: number;
  setIsShowLibraryOfQuestions: (status: boolean) => void;
}

export const QuestionEditor: React.FC<IQuestionEditorProps> = ({
  step,
  onDelete,
  setIsShowLibraryOfQuestions,
}) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample } = quizStore;

  const { getValue, setValue } = quizExample;

  const answerType = getValue(step, 'answerType');

  const answerTypeValue = getAnswerTypeValue(
    answerType,
    getValue(step, 'radio'),
    getValue(step, 'answers')
  );

  const changePlaceholder = (v: string, k: number) => {

    const answers = [...getValue(step, 'answers')];
    answers[k].placeholder = v;

    setValue(step, 'answers', answers);
  };

  const changeText = (v: string, k: number) => {
    console.log({ v, k });

    const answers = [...getValue(step, 'answers')];
    answers[k].text = v;

    setValue(step, 'answers', answers);
  };

  // TODO typings
  const changeImage = (v: { code: string; image: string }, k: number) => {
    const answers = [...getValue(step, 'answers')];

    answers[k].image = v;

    setValue(step, 'answers', answers);
  };

  const onChangeRight = (key: number) => {
    const answers = getValue(step, 'answers').map((v: any, k: number) => {
      return { ...v, isRight: key === k };
    });

    setValue(step, 'answers', answers);
  };

  const onDeleteAnswer = (key: number) => {
    const answers = getValue(step, 'answers').filter((v: any, k: number) => {

      return key !== k;
    });

    setValue(step, 'answers', answers);
  };

  const duplicateAnswer = () => {
    const answers = getValue(step, 'answers');
    const lastAnswer = { ...answers[answers.length - 1] };
    lastAnswer.text = '';
    if ('isRight' in lastAnswer && typeof lastAnswer.isRight !== 'undefined') {
      lastAnswer.isRight = false;
    }

    setValue(step, 'answers', [...answers, lastAnswer]);
  };

  const switchImage = (hasImage: boolean) => {
    const answers = getValue(step, 'answers').map((v: any) => {
      return { ...v, image: hasImage ? defaultImage : undefined };
    });

    setValue(step, 'answers', answers);
  };

  const hasImages =
    getValue(step, 'answers').filter((v: { image: any }) => v.image).length > 0;

  const answers = getValue(step, 'answers');

  return (
    <>
      <div className={styles.changeSetting}>
        <Button
          type={'green-outline'}
          block
          onClick={() => {
            setIsShowLibraryOfQuestions(true);
          }}
        >
          Библиотека вопросов
        </Button>
      </div>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Наименование вопроса</div>
        <Input
          className={styles.input}
          placeholder={'Введите текст заголовка'}
          value={getValue(step, 'title')}
          onChangeValue={(v: string) => setValue(step, 'title', v)}
          maxLength={120}
        />
      </div>
      <Info
        text={
          'Добавьте к вопросу не больше 6 простых вариантов ответа, чтобы не наскучить пользователю'
        }
      />
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Тип ответа</div>
        <SelectAnswerType
          oldAnswers={getValue(step, 'answers')}
          currentType={getValue(step, 'answerType')}
          setValue={(v, k) => setValue(step, v, k)}
          answerTypeValue={answerTypeValue}
        />
        {answerType === 'LIST' && (
          <Checkbox
            checked={hasImages}
            label={'Показать изображение'}
            onCheck={() => {
              switchImage(!hasImages);
            }}
          />
        )}
      </div>
      <div className={styles.changeSetting}>
        {answers.map((v: { [key: string]: any }, k: number) => {
          const isRightShown =
            'isRight' in v && typeof v.isRight !== 'undefined';

          return (
            <div key={k}>
              {answerType === 'INPUT' && (
                <>
                  Плейсхолдер: <br />
                  <Input
                    className={styles.input}
                    value={v?.placeholder}
                    onChangeValue={(v: string) => changePlaceholder(v, k)}
                  />
                </>
              )}

              {answerType === 'LIST' && (
                <div
                  className={styles.settingsFlex}
                  style={{ alignItems: 'start' }}
                >
                  {/*TODO typings*/}
                  {v.image && (
                    <ImageEditor
                      src={v.image.url}
                      changeImage={(v: { url: string; code: string }) =>
                        //  @ts-ignore
                        changeImage(v, k) // TODO typings
                      }
                    />
                  )}
                  <div
                    style={{
                      width: v.image ? 'calc(100% - 90px)' : '100%',
                    }}
                  >
                    <div
                      style={{ width: '100%', marginBottom: '10px' }}
                      className={styles.settingsFlex}
                    >
                      <Input
                        className={styles.input}
                        value={v?.text}
                        onChangeValue={(v: string) => changeText(v, k)}
                        style={{ width: 'calc(100% - 30px)' }}
                        placeholder={'Введите ответ'}
                      />

                      {answers.length > 1 && (
                        <Icon
                          className={styles.deleteIcon}
                          name={'Close'}
                          onClick={() => onDeleteAnswer(k)}
                        />
                      )}
                    </div>
                    {isRightShown && (
                      <RadioButton
                        checked={v.isRight}
                        onCheck={() => onChangeRight(k)}
                        label={'Правильный ответ'}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {answerType === 'LIST' && answers.length < 6 && (
        <div className={cx(styles.changeSetting, styles.addAnswer)}>
          <div
            className={styles.settingsControls}
            onClick={duplicateAnswer}
          >
            + <span>Добавить ответ</span>
          </div>
        </div>
      )}
      <div className={cx(styles.changeSetting, styles.settingsFlex)}>
        <div className={styles.settingsControls} onClick={onDelete}>
          Удалить вопрос
        </div>
        {/*<div*/}
        {/*  className={styles.settingsControls}*/}
        {/*  onClick={() => {*/}
        {/*    duplicateStep(step);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Дублировать вопрос*/}
        {/*</div>*/}
      </div>
    </>
  );
};
