import { observer } from 'mobx-react';
import React from 'react';
import Caption from '~/components/generic/Caption';
import FeedSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import ButtonLike from '~/components/ui/ButtonLike';
import Drawer from '~/components/ui/Drawer';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import { FeedForBusinessRule } from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const FeedSelection: React.FC<{
  isOpenFeedSelection: boolean;
  closeEditor: () => void;
  feedForBusinessRule: FeedForBusinessRule[];
  businessRule: BusinessRulesModel;
  feedSelectionIsDone: boolean;
}> = observer(
  ({
    isOpenFeedSelection,
    closeEditor,
    feedForBusinessRule,
    businessRule,
    feedSelectionIsDone,
  }) => {
    return (
      <Drawer
        className="business_rule_editor__drawer"
        title={'Выберите ленту рекомендаций'}
        visible={isOpenFeedSelection}
        onClose={closeEditor}
        size="x-small"
        actions={(
          <ButtonLike onClick={closeEditor} disabled={!feedSelectionIsDone}>
            Готово
          </ButtonLike>
        )}
      >
        <div className={styles.content_wrapper}>
          Выберите, для какой созданной ленты рекомендаций применить эти
          бизнес-правила.
          <FeedSelectorRadioButton
            currentDevice={businessRule.get('feedId')}
            onChange={businessRule.setFeedParameters}
            listOptions={feedForBusinessRule.map((feed) => {
              return {
                device: feed.feedId,
                label: <>{!!feed.feedName ? feed.feedName : 'Без названия'}</>,
              };
            })}
            disabled={feedForBusinessRule.map((feed) => {
              return !!feed.restrictions ? (feed.feedId as string) : '';
            })}
            infoLabelForItems={feedForBusinessRule.map(feed =>
              feed.restrictions ? {
                text: feed.restrictions,
              } : null
            )}
          />
          <Caption className={styles.caption}>
            Здесь отображены ленты рекомендации следующих типов: «Похожие
            товары», «Альтернативные товары», «Кросс-сейл». Создавать
            бизнес-правила можно только для этих типов лент.
          </Caption>
        </div>
      </Drawer>
    );
  }
);

export default FeedSelection;
