import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FontSettings from '~/components/notification-editor/FontSettings';

const HandCardTitleParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({ isActive, configuration, onChange, onChangeCssParam }) => {

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
     <section className="feed-editor-appearance__section">
        <FontSettings
          type="title"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          className="feed-editor-appearance__font"
          data={{
            fontFamily: configuration.cardTitleFontFamily,
            color: configuration.cardTitleColor,
            alpha: configuration.cardTitleOpacity,
            fontWeight: configuration.cardTitleFontWeight,
            fontSize: configuration.cardTitleFontSize,
            isItalic: configuration.cardTitleFontItalic,
            isLineThrough: configuration.cardTitleTextLineThrough,
            isUnderline: configuration.cardTitleTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                cardTitleFontFamily: data.fontFamily,
                cardTitleColor: data.color,
                cardTitleOpacity: data.alpha,
                cardTitleFontWeight: data.fontWeight,
                cardTitleFontSize: data.fontSize as TFontSize,
                cardTitleFontItalic: data.isItalic,
                cardTitleTextLineThrough: data.isLineThrough,
                cardTitleTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandCardTitleParams;
