import React, { useState } from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

interface IFileUploadProps {
  isFileUploaded: boolean;
  fileName: string;
  uploadFile: () => void;
  deleteFile: () => void;
  handleChangeFile: (file: File) => void;
  fileUploadErrorMessage: string;
}

const FileUpload: React.FC<IFileUploadProps> = ({
  isFileUploaded,
  fileName,
  uploadFile,
  deleteFile,
  handleChangeFile,
  fileUploadErrorMessage,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  
  return (
    <>
      {isFileUploaded && (
        <div className={styles.button_wrapper}>
          <Icon name={'Doc2'} />
          <div className={styles.file_name}>{fileName}</div>
          <Icon
            name={'Close'}
            style={{ width: 24, color: '#B3B3B3', cursor: 'pointer' }}
            onClick={deleteFile}
          />
        </div>
      )}
      {!isFileUploaded && (
        <div
          className={`${styles.button_wrapper} ${
            isDragOver ? styles.active : ''
          }`}
          onDragOver={(event) => {
            event.preventDefault();
            !isDragOver && setIsDragOver(true);
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            setIsDragOver(false);
          }}
          onDrop={(event) => {
            event.preventDefault();
            setIsDragOver(false);
            handleChangeFile(event.dataTransfer.files[0]);
          }}
        >
          <div className={styles.button}>
            Загрузите контакты из файла в эту область
          </div>
          <div className={styles.clip} onClick={uploadFile}>
            <Icon name={'Clip'} />
          </div>
        </div>
      )}
      {!!fileUploadErrorMessage && (
        <div className={styles.error}>{fileUploadErrorMessage}</div>
      )}
    </>
  );
};

export default FileUpload;
