import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactChild } from 'react';
import Navigation from '~/components/Navigation';
import NavProfile from '~/components/__deprecated/NavProfile';
import OldFooter from '~/components/__deprecated/OldFooter';
import { AuthStore } from '~/stores/authStore';
import './style.css';

const { Header, Sider, Content } = Layout;

interface Props {
  children: ReactChild;
  authStore?: AuthStore;
}

@inject('authStore')
@observer
class AppLayout extends Component<Props> {
  state = {
    collapsed: false,
  };

  handleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <Layout className="app-layout">
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.handleCollapse}
        >
          <div className="app-layout__logo" />
          <Navigation />
        </Sider>

        <Layout>
          <Header className="app-layout__header">
            <NavProfile />
          </Header>
          <Content className="app-layout__content">
            <div className="app-layout__content-wrapper">{children}</div>
          </Content>
          <OldFooter className="app-layout__footer" />
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
