import { IFeedConfiguration, TFeedCartLabel } from 'feed';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DeviceSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import ButtonLike from '~/components/ui/ButtonLike';
import Dash from '~/components/ui/Dash';
import AdditionalParams from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockLabelsPro/AdditionalParams';
import LabelsList from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockLabelsPro/LabelsList';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';

const OPTIONS = [
  {
    device: 'off',
    label: <>Не использовать</>,
  },
  {
    device: 'on',
    label: <>Использовать теги</>,
  },
];

interface IBlockLabelsProProps {
  feed: FeedProModel;
  labelMaxCountLimit: number;
}

const BlockLabelsPro: React.FC<IBlockLabelsProProps> = observer(({ feed, labelMaxCountLimit }) => {
  const checkStatusBlockLabels = () =>
    feed.get('configuration')?.labelMaxCount > 0 &&
      feed.get('configuration')?.labels
        ?.filter(
          (label: TFeedCartLabel) => label.isActive && label.aliasName
        )?.length > 0
          ? 'on'
          : 'off';
  const [statusBlockLabels, setStatusBlockLabels] = useState<string>(checkStatusBlockLabels());

  const onChangeConfigurationParam = (params: Partial<IFeedConfiguration>) => {
    feed.setConfiguration({
      ...feed.get('configuration'),
      ...params,
    });
  };

  const onChangeSelector = (optionDevice: string) => {
    if (optionDevice === 'off') {
      const labels = feed.get('configuration').labels;
      labels.forEach((label: TFeedCartLabel) => label.isActive = false);
      onChangeConfigurationParam({ labels });
    }
    setStatusBlockLabels(optionDevice);
  };

  useEffect(() => {
    if (!feed.get('configuration').labels) {
      onChangeConfigurationParam({
        labels: [
          {
            isActive: true,
            aliasName: '',
            uuid: 'hit',
            text: 'Хит',
            labelBackgroundColor: '#D54242',
            labelBackgroundOpacity: 100,
          },
          {
            isActive: true,
            aliasName: '',
            uuid: 'new',
            text: 'Новинка',
            labelBackgroundColor: '#099768',
            labelBackgroundOpacity: 100,
          },
          {
            isActive: true,
            aliasName: '',
            uuid: 'pop',
            text: 'Популярное',
            labelBackgroundColor: '#007AFF',
            labelBackgroundOpacity: 100,
          },
        ],
        labelMaxCount: labelMaxCountLimit,
      });
    }
    setStatusBlockLabels(checkStatusBlockLabels());
  }, []);

  return (
    <div
      className="feed-pro-editor-step-catalog__device-selector-radio-button"
      style={{
        maxHeight: statusBlockLabels === 'off' ? 250 : 'none',
      }}
    >
      <NumberedBlock
        className={'feed-pro-editor-step-catalog__block-radio-button'}
      >
        <DeviceSelectorRadioButton
          title={'Теги'}
          tooltipForTitle={'Теги — это отличительные свойства товара.'}
          message={
            'Создайте теги, настройте их порядок и количество на карточке продукта. Они привлекают внимание, повышают конверсию и позволяют показать преимущества товара'
          }
          currentDevice={statusBlockLabels}
          onChange={onChangeSelector}
          listOptions={OPTIONS}
          infoLabelForItems={[
            { text: 'На рекомендованных товарах не будут отображаться теги' },
            {
              text: 'Рекомендованные товары будут отображаться с тегами',
              actionReaction: true,
            },
          ]}
          className={'feed-pro-editor-step-catalog__radio-button'}
        />
        {statusBlockLabels === 'on' && (
          <div>
            <Dash />
            <AdditionalParams
              labelMaxCount={feed.get('configuration')?.labelMaxCount || labelMaxCountLimit}
              labelPriorityOrderYml={
                feed.get('configuration')?.labelPriorityOrderYml
              }
              onChangeConfigurationParam={onChangeConfigurationParam}
              labelMaxCountLimit={labelMaxCountLimit}
            />
            <LabelsList
              feedConfiguration={feed.get('configuration')}
              onChangeConfigurationParam={onChangeConfigurationParam}
            />
            <ButtonLike
              type="text"
              icon={'Plus'}
              onClick={() => {
                const labels = feed.get('configuration').labels;
                labels.push({
                  isActive: true,
                  aliasName: '',
                  uuid: uuidv4(),
                  text: 'Ваш тег',
                  labelBackgroundColor: '#007AFF',
                  labelBackgroundOpacity: 100,
                });
                onChangeConfigurationParam({ labels });
              }}
            >
              Добавить тег
            </ButtonLike>
          </div>
        )}
      </NumberedBlock>
      <div>
        <img src="/assets/feed/labels-sample.png" />
      </div>
    </div>
  );
});

export default BlockLabelsPro;
