import { IFeedConfiguration } from 'feed';
import { action, observable } from 'mobx';
import Model from '~/models/Model';
import FeedProApi from '~/screens/FeedProScreen/api/feedProApi';

export default class FeedProModel extends Model<FeedPro> {
  @observable isFetchingRequest = false; // Статус обработки запроса

  @action
  setIsFetchingRequest = (status: boolean) => {
    this.isFetchingRequest = status;
  }

  @action
  saveFeedPro = async () => {
    try {
      this.setIsFetchingRequest(true);
      await FeedProApi.saveFeedPro(this);
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  @action
  fixYml = async () => {
    try {
      this.setIsFetchingRequest(true);
      await FeedProApi.fixYml(this.get('id'));
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  @action
  setDisplayConditions = ({ url, operation }: DisplayConditions) => {
    this.update({
      displayConditions: {
        operation: operation ?? this.get('displayConditions').operation,
        url: url ?? this.get('displayConditions').url,
      },
    });
  }

  @action
  setUrlPattern = (urlPattern: string) => this.update({ urlPattern })

  @action
  setYmlUrl = (ymlUrl: string) => this.update({ ymlUrl })

  @action
  setSelector = (selector: string) => this.update({ selector })

  @action
  setSelectorMobile = (selectorMobile: string) =>
    this.update({ selectorMobile })

  @action
  setRecType = (recType: RecType) => this.update({ recType })

  @action
  setConfiguration = (configuration: IFeedConfiguration) =>
    this.update({ configuration })

  @action
  setRecommendationGenerationType = (
    recommendationGenerationType: RecommendationGenerationType
  ) => {
    this.update({
      recommendationGenerationType,
    });
  }
}
