import React from 'react';
import TabConstructor from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor';
import TabParameters from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters';

interface IMassMailingConstructorContentProps {
  step: TMassMailingConstructorSteps;
}

const MassMailingConstructorContent: React.FC<IMassMailingConstructorContentProps> = ({
  step,
}) => {
  switch (step) {
    case 'TabParameters':
      return <TabParameters key={'TabParameters'} />;
    case 'TabConstructor':
      return <TabConstructor key={'TabConstructor'} />;
    default:
      return <>{step}</>;
  }
};

export default MassMailingConstructorContent;
