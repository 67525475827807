import React from 'react';
import Button from '~/components/ui/Button';
import { mobileScreenWasShownConst, widgetsUrl } from '~/constants';
import { getFromLocaleStorage } from '~/utils/localStorageUtils/getFromLocaleStorage';
import { saveObjectToLocaleStorage } from '~/utils/localStorageUtils/saveObjectToLocaleStorage';
import { parseGetParam } from '~/utils/parseGetParam';
import styles from './style.module.css';

export const MobileScreen = () => {
  const redirectParam =
    parseGetParam(location?.search, 'redirect') || widgetsUrl;

  const onContinue = () => {
    saveObjectToLocaleStorage(mobileScreenWasShownConst, 'true');

    if (
      location.pathname === '/register_mobile' &&
      getFromLocaleStorage('isSbbolMobileScreen') === 'true'
    ) {
      location.href = '/tariff';

      return;
    }

    location.href = redirectParam;
  };

  return (
    <div className={styles.mobileScreen}>
      <div>
        <img
          className={styles.img}
          src="/assets/images/mobileScreen/mobileAlert.png"
          alt="mobileAlert"
        />
        <div className={styles.title}>
          Пожалуйста, продолжите работу на компьютере
        </div>
        <div className={styles.description}>
          Пользоваться сервисом удобнее на большом экране
        </div>
        <Button className={styles.button} onClick={onContinue}>
          Продолжить здесь
        </Button>
      </div>
    </div>
  );
};
