import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const Logo: React.FC<Props> = ({ className, style }) => {

  return (
    <div className={cx('logo', className)} style={style}>
      <a href="https://sberlead.ru/">
        <Icon name="Logo2" className="logo__svg" />
      </a>
    </div>
  );
};

export default Logo;
