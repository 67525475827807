import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

export const SuccessPaymentScreen = () => {

  return (
    <div className="payment--success">
      <Icon name="CheckmarkXxl" className="payment--success-mark" />
      <div className="payment-success-title">
        Автоплатеж подключен
      </div>
      <div className="payment-success-text">
        Не закрывайте страницу и пройдите процесс регистрации до конца. <br /><br />
        В течение нескольких минут произойдет переход на следующий шаг для продолжения регистрации.
      </div>
    </div>
  );
};
