import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatTable } from '~/components/generic/FloatTable';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import Message from '~/components/ui/Message';
import NoticeInfo from '~/components/ui/NoticeInfo';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import { convertMailingStatisticToFloatTableData } from '~/screens/MassMailingScreen/utils';
import styles from './style.module.css';

interface IMassMailingStatisticsProps {
  hasRightToModify?: boolean;
}

const MassMailingStatistics: React.FC<IMassMailingStatisticsProps> = observer(({
  hasRightToModify = false,
}) => {
  const {
    editMassMailing,
    mailingStatistics,
    setIsErrorMessageShown,
    setSelectedId,
    getMailingInstanceById,
    editTitle,
    delete: deleteMailingStore,
    start: startMailingStore,
    stop,
    isShowEmailNotRegisterMessage,
    setIsShowEmailNotRegisterMessage,
    openConstructorForApproveEmail,
  } = massMailingStore;
  const history = useHistory();
  const [isDeletePopupShown, setIsDeletePopupShown] = useState<boolean>(false);
  const [mailingDeletedId, setMailingDeletedId] = useState<
    string | number | undefined
  >(undefined);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [mailingEditedTitle, setMailingEditedTitle] = useState<string>('');
  const [mailingEditedId, setMailingEditedId] = useState<
    string | number | undefined
  >(undefined);

  const showMessageEmailNotRegisterMessage = (id: number) => {
    setSelectedId(id);
    setIsShowEmailNotRegisterMessage(true);
  };

  const tableData = convertMailingStatisticToFloatTableData(
    mailingStatistics,
    showMessageEmailNotRegisterMessage
  );

  const deleteMailing = () => {
    const result = deleteMailingStore(mailingDeletedId);
    setMailingDeletedId(undefined);
    setIsDeletePopupShown(false);

    !result && setIsErrorMessageShown(true);
  };

  const start = async (id: any) => {
    const result = await startMailingStore(id);
    !result && setIsErrorMessageShown(true);
  };

  const edit = async (id: number) => {
    if (
      getMailingInstanceById(id)?.status === 'STOPPED' ||
      getMailingInstanceById(id)?.status === 'WAITING'
    ) {
      setSelectedId(id);
      editMassMailing(id);
    }
  };

  const pause = (id: any) => {
    const result = stop(id);
    !result && setIsErrorMessageShown(true);
  };

  const rename = (row: any) => {
    setMailingEditedId(row.id);
    setMailingEditedTitle(row.title);
    setIsDrawerVisible(true);
  };

  const deleteEmail = (id: string) => {
    setMailingDeletedId(id);
    setIsDeletePopupShown(true);
  };

  const contextMenu: Function = (row: IFloatTableRow) => {
    return [
      {
        icon: 'PlayS',
        name: 'Запустить',
        callback: start,
        callbackArgument: row.id,
        visible: getMailingInstanceById(row.id)?.status === 'STOPPED',
        disabled: !hasRightToModify,
      },
      {
        icon: 'PauseS',
        name: 'Остановить',
        callback: pause,
        callbackArgument: row.id,
        visible:
          getMailingInstanceById(row.id)?.status === 'STARTED' ||
          getMailingInstanceById(row.id)?.status === 'WAITING',
        disabled: !hasRightToModify,
      },
      {
        icon: 'Pencil',
        name: 'Редактировать',
        callback: edit,
        callbackArgument: row.id,
        visible:
          getMailingInstanceById(row.id)?.status === 'STOPPED' ||
          getMailingInstanceById(row.id)?.status === 'WAITING',
        disabled: !hasRightToModify,
      },
      {
        icon: 'Rename',
        name: 'Переименовать',
        callback: rename,
        callbackArgument: row,
        visible: true,
        disabled: !hasRightToModify,
      },
      {
        icon: 'Trash',
        name: 'Удалить',
        callback: deleteEmail,
        callbackArgument: row.id,
        color: 'red',
        visible:
          getMailingInstanceById(row.id)?.status === 'STOPPED' ||
          getMailingInstanceById(row.id)?.status === 'WAITING',
        disabled: !hasRightToModify,
      },
    ];
  };

  const save = () => {
    setIsDrawerVisible(false);
    const result = editTitle(mailingEditedTitle, mailingEditedId);
    !result && setIsErrorMessageShown(true);
    setIsDrawerVisible(false);
    setMailingEditedTitle('');
    setMailingEditedId(undefined);
  };

  const cancelSave = () => {
    setIsDrawerVisible(false);
    setMailingEditedTitle('');
    setMailingEditedId(undefined);
  };

  return (
    <>
      <div className={styles.mailing_list}>
        <div className={styles.mass_mailing_list__content}>
          <FloatTable
            rows={tableData}
            contextMenu={contextMenu}
            onRowClick={id => hasRightToModify && edit(id)}
          />
        </div>
      </div>
      <Drawer
        title={'Название рассылки'}
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        size={'x-small'}
        actions={(
          <>
            <Button onClick={save}>Сохранить</Button>
            <Button type="grey" onClick={cancelSave}>
              Отмена
            </Button>
          </>
        )}
      >
        <div className={styles.mailing_list__drawer_inner}>
          <FormItem title={''} message={'Не больше 35 символов'}>
            <Input
              name="name"
              type="text"
              placeholder={'Название рассылки'}
              value={mailingEditedTitle}
              block
              maxLength={35}
              onChange={event =>
                setMailingEditedTitle(event.currentTarget.value)
              }
            />
          </FormItem>
        </div>
      </Drawer>
      {isDeletePopupShown && (
        <Message
          visible={isDeletePopupShown}
          applyText={'Удалить'}
          cancelText={'Отмена'}
          onApply={deleteMailing}
          onClose={() => setIsDeletePopupShown(false)}
          title={'Удалить рассылку?'}
          textTop={'При продолжении рассылка прекратится и будет удалена.'}
          showIcon={false}
        />
      )}
      {!hasRightToModify && (
        <NoticeInfo
          visible={!hasRightToModify}
          title={'Услуга неактивна'}
          text={'Закончился период оплаты. Для управления рассылками необходимо подключить услугу.'}
          actionText={'Перейти в тарифы'}
          onActionClick={() => {
            history.push('/settings?tab=1');
          }}
        />
      )}
      {isShowEmailNotRegisterMessage && (
        <Message
          visible={isShowEmailNotRegisterMessage}
          applyText={'Подтвердить email'}
          cancelText={'Отмена'}
          onApply={openConstructorForApproveEmail}
          onClose={() => setIsShowEmailNotRegisterMessage(false)}
          onExit={() => setIsShowEmailNotRegisterMessage(false)}
          title={'Email не подтвержден'}
          textTop={'Для запуска рассылки подтвердите email, с которого будет осуществляться отправка'}
          showIcon={false}
        />
      )}
    </>
  );
});

export default MassMailingStatistics;
