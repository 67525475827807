import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';
import FeedApiModel from '~/screens/ApiScreen/FeedApi/model/FeedApiModel';

type FreeResponse = IResponse<[]>;
type GetFeedApiStatisticsResponse = IResponse<any[]>;
type GetFeedApiByIdResponse = IResponse<[FeedApi]>;
type GetRecommendationTypesResponse = IResponse<FeedRecType[]>;

const FeedApiApi = {
  saveFeedApi: (feedApi: FeedApiModel): AxiosPromise<FreeResponse> =>
    actionSet(
      '/admin/feedApi',
      feedApi.get('id') ? { id: feedApi.get('id') } : null,
      { ...feedApi.copy() }
    ),

  fixYml: (id: number): AxiosPromise<FreeResponse> =>
    actionSet('/admin/feedApi', { operation: 'fixYml', id }, null),

  getFeedApiById: (id: number): AxiosPromise<GetFeedApiByIdResponse> =>
    actionGet('/admin/feedApi', { id }),

  getFeedApiStatistics: (): AxiosPromise<GetFeedApiStatisticsResponse> =>
    actionGet('/admin/feedApi', null),

  getRecommendationTypes: (): AxiosPromise<GetRecommendationTypesResponse> =>
    actionGet('/admin/feedApi', { type: 'recTypes' }),
};

export default FeedApiApi;
