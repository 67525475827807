import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { ProductItems } from '~/components/Mailing/ui/ProductItems';
import { SwitchUI } from '~/components/SwitchUI';
import TestMail from '~/components/generic/MailingPreview/TestMail';
import { exapleProductList } from '~/components/generic/MailingPreview/exapleProductList';
import Button from '~/components/ui/Button';
import { SegmentedPicker } from '~/components/ui/SegmentedPicker';
import { hexToRgb } from '~/utils/hexUtils';
import styles from './style.module.css';
const mjml2html = require('mjml-browser');

interface IMailingPreviewProps {
  configuration: IMassMailConfiguration[] | MailItem[];
  isPreviewShown: boolean;
  setIsPreviewShown: (status: boolean) => void;
  email: string;
  sendTestLetter: (email: string) => Promise<boolean>;
  errorMessage: string;
  unsubscribeLinkHref: string;
}

const MailingPreview: React.FC<IMailingPreviewProps> = ({
  configuration,
  isPreviewShown,
  setIsPreviewShown,
  email,
  sendTestLetter,
  errorMessage,
  unsubscribeLinkHref,
}) => {
  if (!configuration) {
    return null;
  }

  let mails = '';

  configuration.forEach((mailItem: MailItem) => {
    if (mailItem?.name === 'unsubscribeLink') {
      const params = {
        ...mailItem?.params,
        editMode: false,
        href: unsubscribeLinkHref,
      };
      mails += mailItem?.configuration(params);
    } else if (mailItem.name === 'goToCart') {
      const params = { ...mailItem?.params, editMode: false };
      mails += mailItem?.configuration(params);
    } else if (mailItem.name === 'productItem') {
      mails += ProductItems(mailItem?.params, exapleProductList);
    } else {
      mails += mailItem?.configuration(mailItem?.params);
    }
  });

  const letterProps = configuration.filter(
    (mailItem: MailItem) => mailItem.type === 'letter'
  )[0];

  const { backgroundColor, backgroundOpacity } = letterProps?.params || {};

  const htmlOutput = mjml2html(
    `<mjml>
        <mj-head>
          <mj-html-attributes>
            <mj-selector path=".bodyPadding">
              <mj-html-attribute name="style">padding: 32px 5px;
            </mj-html-attribute>
            </mj-selector>
          </mj-html-attributes>
        </mj-head>
        <mj-body background-color="${hexToRgb(
          backgroundColor,
          backgroundOpacity
        )}" css-class='bodyPadding' width='600px'>
          ${mails}
        </mj-body>
    </mjml>
  `
  );

  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (isPreviewShown) setKey(0);
  }, [isPreviewShown]);

  const isDesktop = key === 1;

  const backToEditing = () => setIsPreviewShown(false);

  const backgroundStyles = { backgroundColor, opacity: backgroundOpacity };

  const backgroundMobileStyles = {
    ...backgroundStyles,
  };

  return (
    <>
      {isPreviewShown && (
        <div className={styles.preview}>
          <Button
            type="grey"
            icon="ArrowMLeft"
            onClick={backToEditing}
            className={styles.backButton}
          >
            Вернуться к редактированию
          </Button>
          <div className={styles.letterContainer}>
            <SwitchUI elementCode="SendTestEmail">
              <TestMail
                email={email}
                isDesktop={isDesktop}
                sendTestLetter={sendTestLetter}
                errorMessage={errorMessage}
              />
            </SwitchUI>
            <div className={styles.pickerWrap}>
              <SegmentedPicker
                onSwitch={setKey}
                items={[
                  { key: 0, name: 'Телефон' },
                  { key: 1, name: 'Компьютер' },
                ]}
              />
            </div>
            <div
              className={cx(
                styles.wrapperStyles,
                isDesktop ? styles.desktop : styles.mobile
              )}
              style={isDesktop ? backgroundStyles : {}}
            >
              <div
                style={isDesktop ? {} : backgroundMobileStyles}
                className={!isDesktop ? styles.mobileInner : ''}
              >
                <div dangerouslySetInnerHTML={{ __html: htmlOutput.html }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MailingPreview;
