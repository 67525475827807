import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactChild } from 'react';
import Logo from '~/components/Logo';
import { ServiceStore } from '~/stores/serviceStore';
import useStores from '~/utils/useStores';
import './style.css';

interface Props {
  children: ReactChild;
  headerChild?: React.ReactNode;
}

const AuthLayout: React.FC<Props> = observer(({ children, headerChild }) => {
  const { serviceStore } = useStores() as {
    serviceStore: ServiceStore;
  };

  const isHeaderDisabled = location.href.includes('unsubscribe');

  return (
    <div className="auth-layout">
      {!isHeaderDisabled && (
        <div className="auth-layout__header">
          <Logo />
          {headerChild}
        </div>
      )}
      <main
        className={cx(
          'auth-layout__content',
          serviceStore.currentStep === 1 ? 'services' : ''
        )}
      >
        {children}
      </main>
    </div>
  );
});

export default AuthLayout;
