import cx from 'classnames';
import React from 'react';
import Button from '~/components/ui/Button';
import './style.css';

interface IErrorPaymentScreen {
  link: string;
  error?: string | Element;
}

const regularError = () => (
  <>
    <div className="error--screen-title">Некорректные данные карты</div>
    <div className="error--screen-text">
      Убедитесь, что указали верные реквизиты банковской карты, у нее не истек
      срок действия и на счету есть денежные средства.
      <br />
      <br />
      При нажатии на кнопку “Повторить попытку” вы будете перенаправлены на
      страницу банка-эквайринга для повторной попытки
    </div>
  </>
);

const manualError = (error: string | Element) => (
  <>
    <div className="error--screen-title">Ошибка</div>
    <div className="error--screen-text">{error}</div>
  </>
);

export const ErrorPaymentScreen = ({ link, error }: IErrorPaymentScreen) => {
  const onRetry = () => {
    location.href = link;
  };

  return (
    <div className="error--screen">
      {!error && regularError()}
      {error && manualError(error)}
      <div className="error--screen-link">
        <Button onClick={onRetry}>Повторить попытку</Button>
      </div>
      <div className={cx('error--screen-phone', 'error--screen-text')}>
        При возникновении сложностей - звоните <br />
        <a href="tel:88002008997">8 (800) 200-89-97</a>
      </div>
    </div>
  );
};
