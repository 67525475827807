import cx from 'classnames';
import React, { ReactNode } from 'react';
import Title from '~/components/Title';
import styles from './style.module.css';

interface IScreenProps {
  className?: string;
  documentTitle?: string;
  noPadding?: boolean;
  header?: string;
  toolbar?: ReactNode;
  seporator?: ReactNode | boolean;
  footer?: ReactNode;
}

const Screen: React.FC<IScreenProps> = ({
  children,
  className = '',
  documentTitle = 'СберЛид',
  noPadding = false,
  header = null,
  toolbar = null,
  seporator = null,
  footer = null,
}) => {
  return (
    <>
      <Title>{documentTitle}</Title>
      <div className={`${styles.screen} ${className}`}>

        {!!header && <div className={styles.header}>{header}</div>}

        {!!toolbar && (
          <div
            className={cx(styles.toolbar, noPadding ? styles.no_padding : '')}
          >
            {toolbar}
          </div>
        )}

        {!!seporator && <div className={styles.seporator}>{seporator}</div>}

        <div
          className={cx(styles.body, noPadding ? styles.no_padding : '')}
        >
          {children}
        </div>

        {!!footer && <div className={styles.footer}>{footer}</div>}

      </div>
    </>
  );
};

export default Screen;
