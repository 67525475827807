import React from 'react';
import Button from '~/components/ui/Button';
import './style.css';

interface IButtonActionFeedProps {
  icon: string;
  name: string;
  className?: string;
  loading?: boolean;
  onAction?: () => void;
  disabled?: boolean;
}

const ButtonActionFeed: React.FC<IButtonActionFeedProps> = ({
  icon,
  name,
  className = '',
  loading = false,
  onAction = null,
  disabled = false,
}) => {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onAction && onAction();
  };
  
  return (
    <Button
      icon={icon}
      type="black-outline"
      className={className}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      size={'small'}
    >
      {name}
    </Button>
  );
};

export default ButtonActionFeed;
