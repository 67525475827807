import React from 'react';
import styles from './style.module.css';

interface IHeader {
  header: string | JSX.Element;
  subheader: string | JSX.Element;
}

export const Header = ({ header, subheader }: IHeader) => {
  return (
    <div className={styles.headerWrap}>
      <div className={styles.header}>{header}</div>
      <div className={styles.subtitle}>{subheader}</div>
    </div>
  );
};
