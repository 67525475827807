import React, { useState } from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

export const Info = ({ text }: { text: string }) => {
  const [isInfoClosed, setIsInfoClosed] = useState(false);
  if (isInfoClosed) return null;

  return (
    <div className={styles.info}>
      <Icon name={'InfoCircle'} className={styles.icon} />
      {text}
      <Icon
        name={'Close'}
        className={styles.closeIcon}
        onClick={() => setIsInfoClosed(true)}
      />
    </div>
  );
};
