import { AxiosPromise } from 'axios';
import { actionGet } from './client';

export interface IBlindZonesInfo {
  '@type': 'BlindZonesInfo';
  blindPagesNumber: number;
  allPagesNumber: number;
  percentageComparison: number;
  isEnabled: boolean;
  isSuccessful: boolean;
  blindPages: string[];
}

export type ReportDeadZone = IResponse<[IBlindZonesInfo]>;

const DeadZone = {
  report: (
    from?: string,
    to?: string
  ): AxiosPromise<ReportDeadZone> =>
    actionGet('/admin/blindzone', {
      from,
      to,
    }),

};

export default DeadZone;
