import { IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import HandDescriptionParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Description/HandDescriptionParams';
import { currentDescriptionCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Description/currentDescriptionCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import SwitchButton from '~/components/ui/SwitchButton';

interface IDescriptionProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
}

export const Description: React.FC<IDescriptionProps> = ({ configuration, onChange }) => {
  const [switchButtonState, setSwitchButtonState] = useState<string>(
    configuration.css?.cardDescription ? 'CSS' : 'HAND'
  );
  const onChangeCssParam = (value: string) => {
    const css = configuration.css ?? {};
    css.cardDescription = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Отображение"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <Checkbox
            label="Показать описание"
            className="feed-editor-appearance__checkbox"
            checked={configuration.hasCardDescription}
            onCheck={checked =>
              onChange({ ...configuration, hasCardDescription: checked }, true)
            }
          />
        </FormItem>
      </section>
      {configuration.hasCardDescription && (
        <>
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Как настраивать стили"
              overlayClassName="feed-editor-appearance__tooltip"
              className="feed-editor-appearance__font"
            >
              <SwitchButton
                buttons={cssSwitchButtons}
                activeKey={switchButtonState}
                onClick={setSwitchButtonState}
              />
            </FormItem>
            {switchButtonState === 'CSS' && (
              <ParamCss
                onChangeCssParam={onChangeCssParam}
                value={
                  configuration.css?.cardDescription ??
                  currentDescriptionCssParam(configuration)
                }
              />
            )}
          </section>
          {switchButtonState === 'HAND' && (
            <HandDescriptionParams
              isActive={switchButtonState === 'HAND'}
              configuration={configuration}
              onChange={onChange}
              onChangeCssParam={onChangeCssParam}
            />
          )}
        </>
      )}
    </>
  );
};
