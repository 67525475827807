import { observer } from 'mobx-react';
import React, { Component } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Steps from '~/components/ui/Steps';
import Screen from '~/screens/Screen';
import { setGtm } from '~/utils/setGtm';
import './style.css';

interface State {
  current: number;
  copied: boolean;
}

@observer
class GuideScreenHTML extends Component {
  state: State = {
    current: 0,
    copied: false,
  };

  handleNextStepClick = () => {
    this.setState({ current: this.state.current + 1 });
  }

  handleCopyCode = () => {
    this.setState({ copied: true, current: this.state.current + 1 });
  }

  render() {
    /**
     * Отправка события site_on_html для GA
     */
    setGtm('site_on_html');

    const items = [{}, {}, {}, {}, {}];

    return (
      <Screen className="guide-cms">
        <div className="guide-navigation">
          <Button className="guide-back-button" type="text" icon="ArrowMLeft" size="x-small" to="/guide">
            К выбору способа подключения
          </Button>
          <h2 className="guide-name">
            Установка кода через HTML
          </h2>
        </div>
        <div className="guide-for-dev__container">
          {this.state.current === 0 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Перейдите к файлам вашего сайта</h1>
              <p className="guide-cms__text">
                Это можно сделать через сайт вашего<br />хостинг-провайдера или через FTP-клиент.
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 1 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Откройте файл index сайта в редакторе кода</h1>
              <p className="guide-cms__text">
                Начните редактирование сайта с файла index - это основная страница.
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 2 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-for-dev__title">Скопируйте код</h1>
              <CodeToCopy onCopy={this.handleCopyCode} />
            </div>
          )}
          {this.state.current === 3 && (
            <div className="guide-cms__wrapper">
              <h1 className="guide-cms__title">Вставьте скопированный код</h1>
              <p className="guide-cms__text">
                Вернитесь в редактор кода. Если все страницы вашего
                сайта - отдельные файлы, написаннные вручную,
                добавьте скопированный код в HTML-код всех страниц
                сайта. Рекомендуем разместить его внутри элементов
                head или body как можно ближе к началу страницы
              </p>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 4 && (
            <div className="guide-cms-message__wrapper">
              <h1 className="guide-for-dev__title">Проверьте подключение</h1>
              <CheckCodeButton />
            </div>
          )}
        </div>
        <Steps items={items} current={this.state.current} />
        <div className="guide-for-dev__link-wrapper">
          <Link className="guide-for-dev__link" to="/guide/html">
            Подробная инструкция
          </Link>
        </div>
      </Screen>
    );
  }
}

export default React.memo(GuideScreenHTML);
