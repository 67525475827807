import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import Table from '~/components/ui/Table';
import {
  columns,
  rowKey,
  Plug,
} from '~/screens/SettingsScreen/Documents/utils';
import { SettingsStore } from '~/stores/settingsStore';
import useStores from '~/utils/useStores';

export const Documents = observer(() => {
  const {
    settingsStore: { documents },
  } = useStores() as { settingsStore: SettingsStore };

  useEffect(() => {
    (async () => {
      await documents.fetch();
    })();
  }, []);

  const isLoading = documents.isFetching;
  const error = documents.error;
  const isDocumentsShown =
    documents.get('documents')?.length > 0 && !isLoading && !error;

  const rowData = documents.get('documents');

  const sortedRowData = rowData?.sort(
    (a, b) => +moment(b.startDate) - +moment(a.startDate)
  );

  return (
    <div className="documents">
      <Plug isLoading={isLoading} error={error} />
      {isDocumentsShown && (
        <Table rows={sortedRowData} columns={columns} rowKey={rowKey} />
      )}
    </div>
  );
});
