import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

const getListOptions = (currentDevice: Device | string, tooltips: string[]): IListOptions[]  => {

  return [
    {
      device: 'mobile',
      label: (
        <>
          На мобильных телефонах
        </>
      ),
      icon: (
        <Icon
          name="DevicePhone"
          className={cx('device-selector-radio-button__item-icon', {
            _active: currentDevice === 'mobile',
          })}
        />
      ),
      tooltip: tooltips ? tooltips[0] : null,
    },
    {
      device: 'desktop',
      label: (
        <>
          На компьютерах и планшетах
        </>
      ),
      icon: (
        <>
          <Icon
            name="DeviceDesktop"
            className={cx('device-selector-radio-button__item-icon', {
              _active: currentDevice === 'desktop',
            })}
          />
          <Icon
            name="DeviceTablet"
            className={cx('device-selector-radio-button__item-icon', {
              _active: currentDevice === 'desktop',
            })}
          />
        </>
      ),
      tooltip: tooltips ? tooltips[1] : null,
    },
  ];
};

export default getListOptions;
