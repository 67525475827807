import React, { useState } from 'react';
import GroupListItems from '~/components/generic/GroupListItems';
import ListItemButton from '~/components/generic/GroupListItems/ListItemButton';
import SupportingButton from '~/components/generic/SupportingButton';
import ContextButton from '~/components/generic/SupportingButton/ContextButton';
import ContextMenu from '~/components/generic/SupportingButton/ContextMenu';
import { BusinessRule } from '~/screens/BusinessRulesScreen/types';
import './style.css';
import styles from './style.module.css';

const BusinessRuleCard: React.FC<{
  businessRule: BusinessRule;
  getRecommendationForTypeDictDisplayText: (key: string) => string;
  getRecommendationOfWhatTypeDictDisplayText: (key: string) => string;
  getRecommendationTypeDictDisplayText: (key: string) => string;
  getSimilarityTypeDictDisplayText: (key: string) => string;
  editBusinessRule: (ruleId: number) => void;
  editBusinessRuleName: (ruleId: number) => void;
  startProcessDeleteRule: (ruleId: number) => void;
}> = ({
  businessRule,
  getRecommendationForTypeDictDisplayText,
  getRecommendationOfWhatTypeDictDisplayText,
  getRecommendationTypeDictDisplayText,
  getSimilarityTypeDictDisplayText,
  editBusinessRule,
  editBusinessRuleName,
  startProcessDeleteRule,
}) => {
  const [isVisibleContext, setIsVisibleContext] = useState<boolean>(false);

  return (
    <GroupListItems className={styles.card} onClick={() => editBusinessRule(businessRule.ruleId)}>
      <div className={styles.name_wrapper}>
        <div className={`${styles.name} ${styles.gelatin}`}>
          {businessRule.ruleName}
        </div>
        <div className={styles.supporting_button_wrapper}>
          <SupportingButton
            className={styles.supporting_button}
            isVisibleContext={isVisibleContext}
            onClick={() => setIsVisibleContext(true)}
            overlay={(
              <ContextMenu
                buttons={(
                  <>
                    <ContextButton
                      callbackArgument={businessRule.ruleId}
                      icon="Pencil"
                      name="Редактировать"
                      callback={(ruleId) => {
                        setIsVisibleContext(false);
                        editBusinessRule(ruleId as number);
                      }}
                    />
                    <ContextButton
                      callbackArgument={businessRule.ruleId}
                      icon="Rename"
                      name="Переименовать"
                      callback={(ruleId) => {
                        setIsVisibleContext(false);
                        editBusinessRuleName(ruleId as number);
                      }}
                    />
                    <ContextButton
                      callbackArgument={businessRule.ruleId}
                      icon="Trash"
                      name="Удалить"
                      callback={(ruleId) => {
                        setIsVisibleContext(false);
                        startProcessDeleteRule(ruleId as number);
                      }}
                      color="red"
                    />
                  </>
                )}
                onClickOutside={() => setIsVisibleContext(false)}
              />
            )}
            noShadow
          />
        </div>
      </div>
      <div className={`${styles.gelatin} ${styles.condition_block}`}>
        {getRecommendationForTypeDictDisplayText(
          businessRule.recommendationFor.type
        )}{' '}
        «{businessRule.recommendationFor.entities.name}»{' '}
        {getRecommendationTypeDictDisplayText(
          businessRule.recommendationType
        ).toLocaleLowerCase()}{' '}
        {getRecommendationOfWhatTypeDictDisplayText(
          businessRule.recommendationOfWhat.type
        ).toLocaleLowerCase()}
        {' ' +
          businessRule.recommendationOfWhat.entities
            .map(entities => `«${entities.name}»`)
            .join(', ')}
      </div>

      <div className={styles.seporator} />
      <ListItemButton
        className="technical_block"
        title={'подбирать товары, схожие по'}
        subtitle={businessRule.similarityType
          .map((key: string) => {
            return getSimilarityTypeDictDisplayText(key);
          })
          .join(', ').toLocaleLowerCase() || '—'}
      />
      <div className={styles.seporator} />
      <ListItemButton
        className="technical_block"
        title={'лента рекомендаций'}
        subtitle={businessRule.feedName ? businessRule.feedName : 'Без названия'}
      />
    </GroupListItems>
  );
};

export default BusinessRuleCard;
