import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactChild } from 'react';
import Sidebar from '~/components/Sidebar';
import WarningDemo from '~/components/ui/Warning/WarningDemo';
import { AppStore } from '~/stores/appStore';
import './style.css';

interface Props {
  children: ReactChild;
  appStore?: AppStore;
}

@inject('appStore')
@observer
class MainLayout extends Component<Props> {
/*
  constructor(props: Props) {
    super(props);

    setInterval(() => {
      if (props.appStore.isDemo) {
        props.appStore.unsetDemo();
      } else {
        props.appStore.setDemo();
      }
    }, 2000);
  }
*/

  render() {
    const { children, appStore } = this.props;

    return (
      <>
        <div className={cx('main-layout', { _warning: appStore.isDemo })}>
          <div className="main-layout__sidebar">
            <Sidebar />
          </div>
          <div className="main-layout__body">
            {children}
          </div>
        </div>
        <WarningDemo visible={appStore.isDemo} />
      </>
    );
  }
}

export default MainLayout;
