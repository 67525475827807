import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import ButtonActionFeed from '~/components/generic/FeedStatisticTable/columns/StatusCell/ButtonActionFeed';
import TableCell from '~/components/ui/Table/TableCell';

interface IStatusCellProps {
  feedStats: FeedProStatistics | FeedApiStatistics;
  onClickActionButton: (
    actionType: FeedActionButtonType, feedStatistics: FeedProStatistics | FeedApiStatistics
  ) => void;
  isFetchingRequest?: boolean;
}

export const StatusCell: React.FC<IStatusCellProps> = ({
  feedStats,
  onClickActionButton,
  isFetchingRequest = false,
}) => {

  let icon: React.ReactNode;
  let title: string | React.ReactNode;
  let hoverContent: React.ReactNode;
  const ymlCatalogError = feedStats.ymlCatalogStatus === 'YML_ERROR';

  if (feedStats.status === 'WORK') {
    title = 'Запущена';
    icon = <Icon name="PlayS" style={{ color: '#00BF6D' }} />;
    hoverContent = (
      <div className="status-cell-buttons__block">
        <ButtonActionFeed
          icon={ymlCatalogError ? 'Wrench' : 'PauseS'}
          className={ymlCatalogError ? 'feed-screen__table-button' : ''}
          loading={isFetchingRequest}
          onAction={() => {
            if (ymlCatalogError) {
              onClickActionButton('edit', feedStats);
            } else {
              onClickActionButton('stop', feedStats);
            }
          }}
          name={ymlCatalogError ? 'Исправить' : 'Остановить'}
        />
      </div>
    );
  }

  if (feedStats.status === 'VERIFY') {
    title = 'Проверка';
    icon = (
      <Icon
        name="Clock"
        style={{ fill: '#FAD155', color: 'var(--White)', pointerEvents: 'none' }}
      />
    );
  }

  if (feedStats.status === 'STOP') {
    const showRefresh = feedStats.ymlCatalogStatus === 'NOT_UPLOAD';
    title = 'Не запущена';
    icon = <Icon name="PauseS" style={{ color: '#808080' }} />;
    const hoverIcon = ymlCatalogError
      ? 'Wrench'
      : showRefresh
      ? 'Refresh'
      : 'PlayS';
    const hoverTitle = ymlCatalogError
      ? 'Исправить'
      : showRefresh
      ? 'Обновить'
      : 'Запустить';
    hoverContent = (
      <div className="status-cell-buttons__block">
        <ButtonActionFeed
          icon={hoverIcon}
          className={ymlCatalogError ? 'feed-screen__table-button' : ''}
          loading={isFetchingRequest}
          onAction={() => {
            if (ymlCatalogError) {
              onClickActionButton('edit', feedStats);
            } else {
              onClickActionButton('start', feedStats);
            }
          }}
          name={hoverTitle}
        />
      </div>
    );
  }

  if (feedStats.status === 'DRAFT') {
    title = (<div style={{ color: '#EF546B' }}>Черновик</div>);
    icon = <Icon name="Pencil" style={{ color: '#EF546B' }} />;
    hoverContent = (
      <div className="status-cell-buttons__block">
        <ButtonActionFeed
          icon={'Pencil'}
          onAction={() => {
            onClickActionButton('edit', feedStats);
          }}
          name={'Редактировать'}
        />
      </div>
    );
  }

  return (
    <TableCell
      className={cx('feed-table__table-cell', 'id=status')}
      icon={icon}
      title={title}
      hoverContent={hoverContent}
    />
  );
};
