import React from 'react';
import Icon from '~/components/Icon';
import Checkbox from '~/components/ui/CheckBox';
import Input from '~/components/ui/Input';
import RadioButton from '~/components/ui/RadioButton';
import Tooltip from '~/components/ui/Tooltip';
import { IStepEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/EditDrawer';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { Info } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/Info';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import localStyles from './style.module.css';

export const LeadEditor = ({ step, onDelete }: IStepEditor) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample } = quizStore;

  const { getValue, setValue } = quizExample;

  const showBonusesSwitcher = getValue(0, 'bonuses').length;

  const hasPhone =
    getValue(step, 'contactType').filter((v: string) => v === 'PHONE').length >
    0;
  const hasEmail =
    getValue(step, 'contactType').filter((v: string) => v === 'EMAIL').length >
    0;
  const hasName =
    getValue(step, 'contactType').filter((v: string) => v === 'NAME').length >
    0;

  const hasBonuses = getValue(step, 'bonuses')?.length > 0;

  const onCheck = (v: any) => console.log({ v });

  const switchContactType = (contactType: string) => {
    const oldContacts = getValue(step, 'contactType');
    const contacts = [...oldContacts];

    if (contactType === 'PHONE') {
      !hasPhone && contacts.push('PHONE');
      const index = contacts.indexOf('EMAIL');
      index !== -1 && contacts.splice(index, 1);
    }

    if (contactType === 'EMAIL') {
      !hasEmail && contacts.push('EMAIL');
      const index = contacts.indexOf('PHONE');
      index !== -1 && contacts.splice(index, 1);
    }

    if (contactType === 'NAME' && !hasName) {
      contacts.push('NAME');
    }

    if (contactType === 'NAME' && hasName) {
      const index = contacts.indexOf('NAME');
      contacts.splice(index, 1);
    }

    const newContacts = contacts.sort((v, k) => {
      return v === 'NAME' ? 1 : -1;
    });

    setValue(step, 'contactType', newContacts);
  };

  const switchBonuses = () => {
    if (!hasBonuses) {
      setValue(step, 'bonuses', getValue(0, 'bonuses'));

      return;
    }

    setValue(step, 'bonuses', []);
  };

  return (
    <>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст заголовка</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'title')}
            placeholder={'Введите заголовок'}
            onChangeValue={(v: string) => setValue(step, 'title', v)}
            maxLength={120}
          />
        </div>
      </div>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст описания</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'description')}
            placeholder={'Введите описание'}
            onChangeValue={(v: string) => setValue(step, 'description', v)}
            maxLength={150}
          />
        </div>
      </div>
      <Info
        text={
          'Мотивируйте клиентов оставлять контакты, рассказывая какие бонусы их ждут по результату'
        }
      />
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>
          <Tooltip
            placement={'bottom'}
            title={
              'Для сбора контактов, выберите телефон или email и по необходимости добавьте имя '
            }
          >
            <div className={styles.titleWithHint}>Какие поля добавить в сбор контактов?
              <Icon name="Question" className={styles.tooltipIcon} />
            </div>
          </Tooltip>
        </div>
        <div className={styles.changeSettingBody}>
          <div
            className={localStyles.chooseField}
            onClick={(e) => {
              e.preventDefault();
              switchContactType('PHONE');
            }}
          >
            <RadioButton
              name={'PHONE'}
              value={'PHONE'}
              checked={hasPhone}
              label={'Номер телефона'}
              onCheck={onCheck}
            />
          </div>
          <div
            className={localStyles.chooseField}
            onClick={(e) => {
              e.preventDefault();
              switchContactType('EMAIL');
            }}
          >
            <RadioButton
              name={'EMAIL'}
              value={'EMAIL'}
              checked={hasEmail}
              onCheck={onCheck}
              label={'Email адрес'}
            />
          </div>
          <div
            className={localStyles.chooseField}
            onClick={(e) => {
              e.preventDefault();
              switchContactType('NAME');
            }}
          >
            <Checkbox
              checked={hasName}
              label={'Имя посетителя(необязательно)'}
              onCheck={onCheck}
            />
          </div>
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст кнопки</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'buttonText')}
            placeholder={'Текст кнопки'}
            onChangeValue={(v: string) => setValue(step, 'buttonText', v)}
            maxLength={30}
          />
        </div>
      </div>

      {!!showBonusesSwitcher && (
        <div className={styles.changeSetting}>
          <div className={styles.changeSettingTitle}>
            Бонусы после прохождения
          </div>
          <div className={styles.changeSettingBody}>
            <div
              className={localStyles.chooseField}
              onClick={(e) => {
                e.preventDefault();
                switchBonuses();
              }}
            >
              <Checkbox
                checked={hasBonuses}
                label={'Показать бонусы'}
                onCheck={onCheck}
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles.changeSetting}>
        <div className={styles.settingsControls} onClick={onDelete}>
          Удалить шаг
        </div>
      </div>
    </>
  );
};
