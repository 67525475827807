import { observer } from 'mobx-react';
import React from 'react';
import AddressBase from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/AddressBase';
import AuthorizationEmail from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/AuthorizationEmail';
import EmailTopic from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/EmailTopic';
import SendStartTime from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/SendStartTime';
import SenderName from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/SenderName';
import styles from './style.module.css';

const TabParameters: React.FC = observer(() => {

  return (
    <div className={styles.tabSend}>
      <AuthorizationEmail />
      <SenderName />
      <EmailTopic />
      <AddressBase />
      <SendStartTime />
    </div>
  );
});

export default TabParameters;
