import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';
import FeedModel from '~/screens/FeedLiteScreen/model/FeedLiteModel';
import { CheckedCountPage } from '~/screens/FeedLiteScreen/store/feedLiteStore';

type FeedRemoveResponse = IResponse<[]>;
type GetFeedLiteByIdResponse = IResponse<[FeedLite]>;
type GetStatisticsResponse = IResponse<FeedLiteStatistics[]>;

const FeedLiteApi = {
  saveLite: (
    feed: FeedModel,
    recommendationList: IProduct[],
    id: string | number,
    feedProcessId: string,
    checkedCountPage: CheckedCountPage,
    stage: Stage,
    status?: string
  ): AxiosPromise<IResponse<[Feed]>> => {

    return actionSet('/admin/feedLite', null, {
      '@type': 'FeedLiteTemplate',
      id,
      stage,
      feedProcessId,
      'urlPattern': feed.get('urlPattern'),
      'selector': feed.get('selector'),
      'selectorMobile': feed.get('selectorMobile'),
      'configuration': feed.get('configuration'),
      recommendationList,
      'displayConditions': {
        url: checkedCountPage === 'ONE' ? feed.get('urlPattern') : feed.get('displayConditions')?.url,
        operation: checkedCountPage === 'ONE' ? 'EQUAL' : feed.get('displayConditions')?.operation,
      },
      status,
    });
  },

  removeLite: (feedProcessId: string): AxiosPromise<FeedRemoveResponse> =>
    actionSet('/admin/feedLite', { operation: 'delete', feedProcessId }, null),

  getFeedLiteById: (id: number): AxiosPromise<GetFeedLiteByIdResponse> =>
    actionGet('/admin/feedLite', { id }),

  getStatisticsLite: (): AxiosPromise<GetStatisticsResponse> =>
    actionGet('/admin/feedLite', null), 

  deleteImage: (
    feedProcessId: string,
    imageUuid: string
  ): AxiosPromise<IResponse> =>
    actionSet('/admin/feedImage', null, {
      '@type': 'FeedImageRequest',
      'action': 'DELETE',
      'feedProcessId': feedProcessId,
      'imageUuid': imageUuid,
    }),

  uploadImage: (
    feedProcessId: string,
    imageB64: string | ArrayBuffer
  ): AxiosPromise<IResponse> =>
    actionSet('/admin/feedImage', null, {
      '@type': 'FeedImageRequest',
      'action': 'SAVE',
      'feedProcessId': feedProcessId,
      'imageBase64': imageB64,
    }),
};

export default FeedLiteApi;
