import React from 'react';
import styles from './style.module.css';

interface IToggleSwitch {
  isActive: boolean;
  setIsRemoteServiceModalOpen: (isOpen: boolean) => void;
  disabled: boolean;
}

const ToggleSwitch: React.FC<IToggleSwitch> = ({
  isActive,
  setIsRemoteServiceModalOpen,
  disabled,
}) => {
  const onToggle = () => {
    if (disabled) return;
    setIsRemoteServiceModalOpen(true);
  };

  return (
    <label className={styles.toggle_switch}>
      <input
        type="checkbox"
        checked={isActive}
        onChange={onToggle}
        disabled={disabled}
      />
      <span className={styles.switch} />
    </label>
  );
};

export default ToggleSwitch;
