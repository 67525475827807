import React from 'react';
import styles from './styles.module.css';

export const MailingPlug = () => {
  return (
    <div className={styles.wrap}>
      <div>
        <img src="assets/images/mailing/nonEditedItem.png" alt="Aa..." />
      </div>
      <div className={styles.text}>Этот элемент пока нельзя редактировать</div>
    </div>
  );
};
