import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';

type FreeResponse = IResponse<[]>;
type GetFeedProStatisticsResponse = IResponse<FeedProStatistics[]>;
type GetFeedProByIdResponse = IResponse<[FeedPro]>;
type GetRecommendationTypesResponse = IResponse<FeedRecType[]>;

const FeedProApi = {
  saveFeedPro: (feedPro: FeedProModel): AxiosPromise<FreeResponse> =>
    actionSet(
      '/admin/feedPro',
      feedPro.get('id') ? { id: feedPro.get('id') } : null,
      { ...feedPro.copy() }
    ),

  fixYml: (id: number): AxiosPromise<FreeResponse> =>
    actionSet('/admin/feedPro', { operation: 'fixYml', id }, null),

  getFeedProById: (id: number): AxiosPromise<GetFeedProByIdResponse> =>
    actionGet('/admin/feedPro', { id }),

  getFeedProStatistics: (): AxiosPromise<GetFeedProStatisticsResponse> =>
    actionGet('/admin/feedPro', null),

  getRecommendationTypes: (): AxiosPromise<GetRecommendationTypesResponse> =>
    actionGet('/admin/feedPro', { type: 'recTypes' }),
};

export default FeedProApi;
