import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CounterCode } from '~/api';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import './style.css';

interface Props {
  onCopy?: () => void;
  tag?: 'textarea' | 'input';
  fieldText?: string; 
  className?: string;
}

const CodeToCopy: React.FC<Props> = observer(({
  onCopy,
  tag = 'textarea',
  fieldText,
  className,
  }) => {

  const [codeToCopy, setCodeToCopy] = useState('');
  const [copyLink, setCopyLink] = useState('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {

    if (!mounted) {
      (async() => {
        setMounted(true);
        try {
          const response = await CounterCode.codeResponse();
          setCopyLink(`${fieldText}?${response?.data?.payload[0]?.analyticsId}`);
          setCodeToCopy(response?.data?.payload[0]?.counter);
        } catch (e) {
          throw e;
        }
      })();
    }
  });
  

  if (tag === 'input') {
    return (
      <>
        <Input
          className="code-to-copy__input-field-text"
          value={copyLink}
          readonly
        />
        <CopyToClipboard 
          text={copyLink}
          onCopy={onCopy}
        >
          <Button block icon="Copy" size="large">
            Скопировать ссылку
          </Button>
        </CopyToClipboard>
      </>
    );
  } else {
    return (
      <div className={`code-to-copy ${className}`}>
        <textarea
          readOnly
          value={codeToCopy}
          className="code-to-copy__input"
        />
        <CopyToClipboard
          text={codeToCopy}
          onCopy={onCopy}>
          <Button block size="large" icon="Copy" type="text">
            Скопировать код
          </Button>
        </CopyToClipboard>
      </div>
    );
  }
});

export default React.memo(CodeToCopy);
