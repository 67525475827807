import cx from 'classnames';
import React from 'react';
import { SwitchUI } from '~/components/SwitchUI';
import ServiceTypeCard from '~/screens/Auth/AttendanceScreen/ServiceSelection/ServiceTypeCard';
import { SERVICE_INFO } from '~/screens/Auth/AttendanceScreen/ServiceSelection/constants';
import { EAvailableServices } from '~/stores/serviceStore';
import { isMobile } from '~/utils/isMobile';
import './style.css';

interface IServiceSelectionProps {
  serviceSelection: any;
  setServiceSelection: (checked: EAvailableServices) => void;
  availableServices: EAvailableServices[];
}

const ServiceSelection: React.FC<IServiceSelectionProps> = ({
  serviceSelection,
  setServiceSelection,
  availableServices,
}) => {
  return (
    <div
      className={cx(
        'screens-auth-setpassword-service-selection',
        isMobile() ? '_mobile' : ''
      )}
    >
      {availableServices.map((availableService: EAvailableServices) => (
        <SwitchUI elementCode={SERVICE_INFO[availableService].uiSwitch} key={availableService}>
          <ServiceTypeCard
            title={SERVICE_INFO[availableService].title}
            description={SERVICE_INFO[availableService].description}
            image={SERVICE_INFO[availableService].image}
            checked={serviceSelection[availableService]}
            availableService={availableService}
            serviceSelection={serviceSelection}
            setServiceSelection={setServiceSelection}
          />
        </SwitchUI>
      ))}
    </div>
  );
};

export default ServiceSelection;
