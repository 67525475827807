import { IFeedConfiguration } from 'feed';
import React from 'react';
import { imageSizes } from '~/components/ScenarioEditor/ScenarioEditorSidebar/ImageEditor';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FormItem from '~/components/ui/FormItem';
import InputDropdown from '~/components/ui/InputDropdown';

const HandImageParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({ isActive, configuration, onChange, onChangeCssParam }) => {
  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Размер изображения"
          overlayClassName="feed-editor-appearance__tooltip"
          hint={(
            <>
              Здесь вы можете выбрать размер изображения в выпадающем списке:
              <ul>
                <li>Оригинал - размер изображения не меняется</li>
                <li>Заполнить - изображение растягивается на всю карточку</li>
                <li>
                  Уместить - изображение вмещается в размер окна с карточкой
                </li>
              </ul>
              Макет слева отображает, как будет выглядеть ваше изображение в
              выбранном размере.
            </>
          )}
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <InputDropdown
            selectedKey={configuration.imageSize}
            className="feed-editor-appearance__width-input"
            onChange={(imageSize: TNotificationImageSize) =>
              onChange({ ...configuration, imageSize }, true)
            }
            items={imageSizes}
            theme={'light'}
          />
        </FormItem>
      </section>
    </ParamHand>
  );
};

export default HandImageParams;
