import { AxiosPromise } from 'axios';
import { actionGet } from '~/api/client';

export type RightsResponse = IResponse<IRight[]>;

const Rights = {
  list: (): AxiosPromise<RightsResponse> =>
    actionGet('/admin/rights', {}),
};

export default Rights;
