import React from 'react';
import Loading from '~/components/Loading';
import {
  HeaderCell,
  IHeaderCell,
} from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/HeaderCell';
import { TableRow } from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/TableRow';
import {
  columns,
  getContactsStatistics,
} from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/consts';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IContactsStatisticsProps {
  activeId?: string;
}

export const ContactsStatistics: React.FC<IContactsStatisticsProps> = ({
  activeId,
}) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const { detailStatistic, isStatisticLoading } = quizStore;
  const { contactsStatistic } = detailStatistic || {};

  if (isStatisticLoading || !contactsStatistic) {
    return <Loading status={'Loading'} onClick={() => null} />;
  }

  const rows = getContactsStatistics(contactsStatistic || []);

  const contactsId = 'table' + Math.ceil(Math.random() * 1000);

  const finalActiveId = activeId || (rows.length > 0 ? rows[0].id : 0);

  return (
    <div className={styles.contacts} id={contactsId}>
      <div className={styles.tableHeader}>
        {columns.map((value: IHeaderCell, key) => {
          return <HeaderCell {...value} key={key} />;
        })}
      </div>
      {rows.map((value, key) => {
        return (
          <TableRow
            {...{
              ...value,
              isShown: value.id.toString() === finalActiveId,
            }}
            key={key}
          />
        );
      })}
    </div>
  );
};
