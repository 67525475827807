import { CartesianMarkerProps } from '@nivo/core';

export const HELP_MAIL = 'mail@sberlead.ru';

export const HELP_PHONE = '8-800-200-89-97';

export const HELP_PHONE_CLEAN = '88002008997';

export const CHART_PALETTE = [
  '#39bd91',
  '#e66767',
  '#fad669',
  '#5273c1',
  '#cb6e9c',
  '#56c5db',
  '#4d4d4d',
  '#b3b3b3',
];

export const HAS_BLACK_COLORS: string[] = ['#fad669'];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';

export const DASHBOARD_CHART_LINING = 2;

export const REPORT_CHART_LINING = 5;

export const NIVO_ZERO_LINE_MARKER: CartesianMarkerProps = {
  axis: 'y',
  value: 0,
  lineStyle: {
    stroke: '#ccc',
    strokeWidth: 1,
  },
};

export const TARIFF_TAB = '2';

export const NIVO_THEME_GRID = {
  grid: {
    line: {
      stroke: '#e6e6e6',
    },
  },
};

export const NIVO_THEME_RESET = {
  tooltip: {
    container: {
      background: 'none',
      padding: 0,
      boxShadow: 'none',
    },
  },
};

export const CODE_TO_COPY_FIELD_TEXT = `${window.location.origin}/guide/developer-instructions`;

export const MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET: number = 512000; // 500 kB

export const URL_FOR_UPLOAD_IMAGE_IN_WIDGET: string = 'image/';

export const regexpDomain =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i;

export const mainRegisterUrl = '/register';

export const mainLoginUrl = '/auth';

export const loginUrl = '/auth/login';

export const recoveryUrl = '/auth/recovery';

export const registerUrl = '/auth/register';

export const setPasswordUrl = '/auth/setPassword';

export const paymentSuccessUrl = '/payment/request';

export const vipAuthUrl = '/vipauth';

export const guideUrl = '/guide';

export const widgetsUrl = '/widgets';

export const tariffUrl = '/tariff';

export const mobileScreenWasShownConst = 'isMobileScreenWasShown';

export const registrationPaths = [
  mainRegisterUrl,
  mainLoginUrl,
  loginUrl,
  recoveryUrl,
  registerUrl,
  setPasswordUrl,
];

export const semiRegistrationPaths = [
  tariffUrl,
  paymentSuccessUrl,
  '/payment/success',
  '/payment/error',
];

export const toBitrix24 =
  'https://www.bitrix24.ru/apps/?app=Sberclickstream.sberclickstream';

export const toInsales =
  'https://www.insales.ru/collection/apps-push/product/sberlead';

export const toJoomla =
  'https://extensions.joomla.org/extension/marketing/landing-pages/sberlead/';

export const toWordpress = 'https://wordpress.org/plugins/sberlead/';

export const clickform = 'https://sberlead.ru/#form';

export const YANDEX_SUPPORT_PARTNERMARKET = 'https://yandex.ru/support/partnermarket/export/cms.html';

export const STORAGE_NAME_FOR_APP_SETTINGS: string = '_sberlead_app_settings_';

export const yandexMetrikaId = 93448909;
