import { PageHeader as AntPageHeader } from 'antd';
import React, { Component } from 'react';
import './style.css';

interface Props {
  title: React.ReactNode;
  extra?: React.ReactNode;
  subTitle?: React.ReactNode;
  onBack?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

class OldPageHeader extends Component<Props> {
  render() {
    return (
      <AntPageHeader
        className="old-page-header"
        {...this.props}
      />
    );
  }
}

export default OldPageHeader;
