import { observer } from 'mobx-react';
import React from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import ProductCard from '~/screens/FeedLiteScreen/components/ProductCard';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import useStores from '~/utils/useStores';

import './style.css';

const FeedConstructorTable: React.FC = observer(() => {
  const { feedLiteStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
  };
  const {
    feedConfiguration,
    setIsOpenDrawen,
    getProducts,
    deleteProduct,
    getLength,
  } = feedLiteStore;

  const [idUpdatedProduct, setIdUpdatedProduct] = React.useState<string>('');
  const [isMessageOpen, setIsMessageOpen] = React.useState<boolean>(false);
  const [
    isOpenMessageBeforeDeleting,
    setIsOpenMessageBeforeDeleting,
  ] = React.useState<boolean>(false);

  const handleApply = () => {
    setIsMessageOpen(false);
  };

  const handleCloseDeleteMessage = () => setIsOpenMessageBeforeDeleting(false);

  const handleDeleteProduct = (): void => {
    deleteProduct(idUpdatedProduct);
    handleCloseDeleteMessage();
  };

  return (
    <div className={'feed-lite-constructor-step-produkt-list__container'}>
      <Button
        type="main"
        onClick={() => {
          if (getLength >= 30) {
            setIsMessageOpen(true);
          } else {
            setIsOpenDrawen({
              create: true,
              update: false,
            });
          }
        }}
        icon={'Plus'}
        className="feed-constructor__add-product-button"
      >
        Добавить товар
        <div>{`Всего товаров: ${getLength} из 30`}</div>
      </Button>
      <div className="feed-constructor__wrapper">
        <div className="feed-constructor__table">
          {getProducts.map(
            (productModel: FeedConstructorModel, index: number) => {
              return (
                <NumberedBlock
                  number={index + 1}
                  key={productModel.get('imageUuid')}
                >
                  <div className="feed-constructor__table-card">
                    <ProductCard
                      productModel={productModel}
                      feedConfiguration={feedConfiguration}
                    />

                    <div className="feed-constructor__table-card-button">
                      <Button
                        className="feed-constructor__remove-button"
                        type="grey"
                        onClick={() => {
                          setIdUpdatedProduct(productModel.get('imageUuid'));
                          setIsOpenDrawen(
                            {
                              create: false,
                              update: true,
                            },
                            productModel.get('imageUuid')
                          );
                        }}
                      >
                        Редактировать
                      </Button>
                      <Button
                        className="feed-constructor__remove-button"
                        onClick={() => {
                          setIdUpdatedProduct(productModel.get('imageUuid'));
                          setIsOpenMessageBeforeDeleting(true);
                        }}
                        type="grey"
                      >
                        Удалить
                      </Button>
                    </div>
                  </div>
                </NumberedBlock>
              );
            }
          )}
        </div>
      </div>
      {isMessageOpen && (
        <Message
          className="feed-constructor__message-box"
          showIcon={false}
          onExit={() => setIsMessageOpen(false)}
          onClose={() => setIsMessageOpen(false)}
          onApply={handleApply}
          title={'Создано максимальное количество товаров'}
          textTop={
            'Вы можете удалить какие-то товары из списка или создать новую ленту.'
          }
          visible={isMessageOpen}
          applyText={'Понятно'}
        />
      )}
      {isOpenMessageBeforeDeleting && (
        <Message
          className="feed-constructor__message-box"
          showIcon={false}
          onExit={handleCloseDeleteMessage}
          onClose={handleCloseDeleteMessage}
          onApply={handleDeleteProduct}
          title={'Удалить товар?'}
          visible={isOpenMessageBeforeDeleting}
          applyText={'Удалить'}
          cancelText={'Отмена'}
        />
      )}
    </div>
  );
});

export default FeedConstructorTable;
