import React from 'react';
import Button from '~/components/ui/Button';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Link from '~/components/ui/Link';
import { YANDEX_SUPPORT_PARTNERMARKET } from '~/constants';
import './style.css';

interface IFooterProps {
  amount: number;
  limit: number;
  create: () => Promise<void>;
}

const Footer: React.FC<IFooterProps> = ({
  amount,
  limit,
  create,
}) => {

  return (
    <div className="feed-screen-footer__wrapper">
      <DrawerFooter
        className="feed-screen__footer"
        actions={(
          <Button
            icon="Plus"
            onClick={create}
          >
            Создать ленту
          </Button>
        )}
        aside={(
          <div className="feed-screen__footer-aside">
            <Link
              className="feed-screen__footer-aside-element"
              target="_blank"
              to={YANDEX_SUPPORT_PARTNERMARKET}
            >
              Требования к каталогу
            </Link>
            <div className="feed-screen__footer-aside-element">
              Созданных лент: {amount}/{limit}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default Footer;
