import { observer } from 'mobx-react';
import React from 'react';
import { getSettingsElement } from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings/utils';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import styles from './styles.module.css';

export const MailSettings = observer(() => {
  const { mailingInstance } = massMailingStore;

  const selectedMailSection = mailingInstance.getSelectedSection();
  const SettingsElement = getSettingsElement(selectedMailSection?.name);

  return (
    <div className={styles.wrapper}>
      {selectedMailSection && (
        <SettingsElement
          configuration={selectedMailSection}
          onEdit={mailingInstance.changeValue}
        />
      )}
    </div>
  );
});
