import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import BorderComponent from '~/components/notification-editor/BorderComponent';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FontSettings from '~/components/notification-editor/FontSettings';
import FormItem from '~/components/ui/FormItem';

interface IHandCategoryButtonParamsProps {
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}

const HandCategoryButtonParams: React.FC<IHandCategoryButtonParamsProps> = ({
  isActive,
  configuration,
  onChange,
  onChangeCssParam,
}) => {
  const {
    categoryButtonBorderRadius,
    categoryButtonBorderWidth,
    categoryButtonBorderColor,
    categoryButtonBorderOpacity,
    hasCategoryButtonBorder,
    categoryButtonColor,
    categoryButtonOpacity,
    categoryButtonFontFamily,
    categoryButtonTextColor,
    categoryButtonTextOpacity,
    categoryButtonFontWeight,
    categoryButtonFontSize,
    categoryButtonFontItalic,
    categoryButtonTextLineThrough,
    categoryButtonTextUnderline,
  } = configuration;

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FontSettings
          className="feed-editor-appearance__font"
          type="title"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          data={{
            fontFamily: categoryButtonFontFamily,
            color: categoryButtonTextColor,
            alpha: categoryButtonTextOpacity,
            fontWeight: categoryButtonFontWeight,
            fontSize: categoryButtonFontSize,
            isItalic: categoryButtonFontItalic,
            isLineThrough: categoryButtonTextLineThrough,
            isUnderline: categoryButtonTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                categoryButtonFontFamily: data.fontFamily,
                categoryButtonTextColor: data.color,
                categoryButtonTextOpacity: data.alpha,
                categoryButtonFontWeight: data.fontWeight,
                categoryButtonFontSize: data.fontSize as TFontSize,
                categoryButtonFontItalic: data.isItalic,
                categoryButtonTextLineThrough: data.isLineThrough,
                categoryButtonTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
      <section className="feed-editor-appearance__section">
        <FormItem
          className="feed-editor-appearance__font"
          overlayClassName="feed-editor-appearance__tooltip"
          title="Цвет кнопки категории"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={categoryButtonColor}
            alpha={categoryButtonOpacity}
            onChange={data =>
              onChange(
                {
                  ...configuration,
                  categoryButtonColor: data.color,
                  categoryButtonOpacity: data.alpha,
                },
                true
              )
            }
            className="feed-editor-appearance__color-picker"
          />
        </FormItem>
      </section>
      <section className="feed-editor-appearance__section">
        <BorderComponent
          className="feed-editor-appearance__font"
          limit={8}
          title="Обводка кнопки категории"
          overlayClassName="feed-editor-appearance__tooltip"
          withRadius
          borderRadius={categoryButtonBorderRadius}
          onBorderRadiusChange={(categoryButtonBorderRadius) => {
            onChange({
              ...configuration,
              categoryButtonBorderRadius,
            });
          }}
          borderWidth={categoryButtonBorderWidth}
          onBorderWidthChange={categoryButtonBorderWidth =>
            onChange(
              {
                ...configuration,
                categoryButtonBorderWidth,
              },
              true
            )
          }
          colorPickerProps={{
            alpha: categoryButtonBorderOpacity,
            color: categoryButtonBorderColor,
            onChange: ({ alpha, color }) =>
              onChange(
                {
                  ...configuration,
                  categoryButtonBorderColor: color,
                  categoryButtonBorderOpacity: alpha,
                },
                true
              ),
          }}
          enabled={hasCategoryButtonBorder}
          onChangeState={hasCategoryButtonBorder =>
            onChange(
              {
                ...configuration,
                hasCategoryButtonBorder,
              },
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandCategoryButtonParams;
