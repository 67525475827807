import { observer } from 'mobx-react';
import React, { useState } from 'react';
import DeviceSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';
import TooltipForBlockCategoryPro from './TooltipForBlockCategoryPro';

interface IBlockCategoryProProps {
  feed: FeedProModel;
}

const OPTIONS = [
  {
    device: 'off',
    label: <>Не группировать</>,
  },
  {
    device: 'on',
    label: <>Сгруппировать товары по категориям</>,
  },
];

const BlockCategoryPro: React.FC<IBlockCategoryProProps> = observer(({ feed }) => {
  const [statusBlockСategories, setStatusBlockstatusBlockСategories] = useState<
    string
  >(feed.get('configuration').isGroupByCategory ? 'on' : 'off');

  const onChangeSelector = (optionDevice: string) => {
    feed.update({configuration: {
      ...feed.get('configuration'),
      isGroupByCategory: optionDevice === 'on' ? true : false,
    }});
    setStatusBlockstatusBlockСategories(optionDevice);
  };

  return feed.get('recType') !== 'POPULAR' ? (
    <div className="feed-pro-editor-step-catalog__device-selector-radio-button">
      <NumberedBlock
        className={'feed-pro-editor-step-catalog__block-radio-button'}
      >
        <DeviceSelectorRadioButton
          title={'Категории'}
          tooltipForTitle={TooltipForBlockCategoryPro}
          message={
            'Мы автоматически сгруппируем рекомендованные товары по категориям для повышения продуктовой выдачи и увеличения продаж'
          }
          currentDevice={statusBlockСategories}
          onChange={onChangeSelector}
          listOptions={OPTIONS}
          infoLabelForItems={[
            { text: 'Рекомендованные товары не будут делиться на категории' },
            {
              text: 'Рекомендованные товары будут сгруппированы по категориям',
              actionReaction: true,
            },
          ]}
          className={'feed-pro-editor-step-catalog__radio-button'}
        />
      </NumberedBlock>
      <div>
        <img style={{ width: 280 }} src="/assets/feed/category-sample.png" />
      </div>
    </div>
  ) : null;
});

export default BlockCategoryPro;
