type TCard = {
  title: string;
  description: string;
  image: string;
  uiSwitch?: TSwitchCode;
};

type TServiceInfo = {
  SCENARIO_SRV: TCard;
  FEED_LITE_SRV: TCard;
  FORTUNE_WHEEL_SRV: TCard;
  QUIZ_SRV: TCard;
};

export const SERVICE_INFO: TServiceInfo = {
  SCENARIO_SRV: {
    title: 'Виджеты и лиды',
    description: 'Всплывающие окна для повышения продаж, акций, сбора лидов. До +20% новых заказов с сайта.',
    image: 'assets/images/serviceInfo/widget-service.png',
  },
  FEED_LITE_SRV: {
    title: 'Ленты рекомендаций Lite',
    description: 'Подборка с товарами или услугами, настраиваемая на страницах сайта. Эффективный кросс-сейл, повышение среднего чека до +30%',
    image: 'assets/images/serviceInfo/lite-service.png',
  },
  FORTUNE_WHEEL_SRV: {
    title: 'Колесо фортуны',
    description: 'Интерактивный инструмент для повышения продаж и конверсии сайта, который настраивается и подходит под любой тип бизнеса.',
    image: 'assets/images/serviceInfo/wheel-of-fortune-service.png',
    // uiSwitch: 'AuthScreen#AuthForm1',
  },
  QUIZ_SRV: {
    title: 'Квизы',
    description: 'Квиз - это викторина или опрос, который поможет определить потребности клиента и предоставить вам горячий контакт',
    image: 'assets/images/serviceInfo/quiz-service.png',
  },
};
