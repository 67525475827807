import React, { useState } from 'react';
import SupportingButton from '~/components/generic/SupportingButton';
import ContextButton from '~/components/generic/SupportingButton/ContextButton';
import ContextMenu from '~/components/generic/SupportingButton/ContextMenu';

const SupportingButtonsCell: React.FC<{
  feedStatistics: FeedProStatistics | FeedApiStatistics;
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics
  ) => void;
  className?: string;
}> = ({
  feedStatistics,
  onClickActionButton,
  className = null,
}) => {
  const [isVisibleContext, setIsVisibleContext] = useState<boolean>(false);
  const ymlCatalogError = feedStatistics.ymlCatalogStatus === 'YML_ERROR';
  const showRefresh = feedStatistics.ymlCatalogStatus === 'NOT_UPLOAD';

  const actionObject =
    feedStatistics.status === 'WORK'
      ? { icon: 'PauseS', name: 'Остановить', action: 'stop' }
      : ymlCatalogError
      ? { icon: 'Wrench', name: 'Исправить', action: 'edit' }
      : showRefresh
      ? { icon: 'Refresh', name: 'Обновить', action: 'edit' }
      : { icon: 'PlayS', name: 'Запустить', action: 'start' };

  return (
    <SupportingButton
      className={className}
      isVisibleContext={isVisibleContext}
      onClick={() => setIsVisibleContext(true)}
      overlay={(
        <ContextMenu
          buttons={(
            <>
              <ContextButton
                callbackArgument={feedStatistics}
                icon={actionObject.icon}
                name={actionObject.name}
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton(actionObject.action as FeedActionButtonType, feedStatistics);
                }}
                visible={feedStatistics.status !== 'VERIFY' && feedStatistics.status !== 'DRAFT'}
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="InfoDonut"
                name="Инфо о ленте"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('info', feedStatistics);
                }}
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="Pencil"
                name="Редактировать"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('edit', feedStatistics);
                }}
                visible={feedStatistics.status !== 'VERIFY'}
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="Rename"
                name="Переименовать"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('rename', feedStatistics);
                }}
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="Eye"
                name="Предпросмотр"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('preview', feedStatistics);
                }}
                visible={
                  feedStatistics.status === 'STOP' &&
                  feedStatistics.ymlCatalogStatus === 'UPLOAD' &&
                  feedStatistics.device !== 'api'
                }
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="Copy"
                name="Дублировать"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('copy', feedStatistics);
                }}
              />
              <ContextButton
                callbackArgument={feedStatistics}
                icon="Trash"
                name="Удалить"
                callback={(feedStatistics: FeedProStatistics) => {
                  setIsVisibleContext(false);
                  onClickActionButton('delete', feedStatistics);
                }}
                color="red"
                visible={feedStatistics.status !== 'VERIFY'}
              />
            </>
          )}
          onClickOutside={() => setIsVisibleContext(false)}
        />
      )}
    />
  );
};

export default SupportingButtonsCell;
