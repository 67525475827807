import { action, observable, reaction } from 'mobx';
import Dashboard from '~/api/dashboard';
import Store from '~/stores/store';
import getDatesByPreset from '~/utils/getDatesByPreset';
import getDetailByDates from '~/utils/getDetailByDates';

export class DateRangeStore extends Store {
  private timeout: NodeJS.Timeout;
  @observable preset: DatePreset;
  @observable detail: ReportDetails;
  @observable dateFrom: string;
  @observable dateTo: string;
  @observable hasDashboardData: boolean = false;
  @observable isDataRequested: boolean = false;

  constructor() {
    super();
    this.reactions.checkData = reaction(() => this.isDataRequested && !this.hasDashboardData, this.isCheckingData);
    this.setRangePreset('yesterday');
  }

  private clearInterval() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  @action
  setCustomRange = (dateFrom: string, dateTo: string) => {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;

    this.setRangePreset('custom');
  }

  @action
  setRangePreset = (preset: DatePreset) => {
    if (this.preset === preset && preset !== 'custom') return;

    const [dateFrom, dateTo] = getDatesByPreset(preset, [this.dateFrom, this.dateTo]);
    const detail = getDetailByDates(dateFrom, dateTo);

    this.preset = preset;
    this.detail = detail;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  @action
  checkData = async () => {
    const response = await Dashboard.checkDashboards();
    this.isDataRequested = true;

    return this.hasDashboardData = response.data.payload[0].dashboardsAllowed === true;
  }

  @action
  isCheckingData = async () => {
    if (this.isDataRequested && !this.hasDashboardData) {
      this.timeout = setInterval(this.checkData, 4000);
    }
    if (this.hasDashboardData) {
      this.clearInterval();
    }
  }

  destroy() {
    super.destroy();
    this.clearInterval();
  }
}

export default new DateRangeStore();
