import cx from 'classnames';
import React from 'react';
import Button from '~/components/ui/Button';
import { isMobile } from '~/utils/isMobile';
import styles from './style.module.css';

const MockEmailLinkResult: React.FC = () => {
  const onClose = () => {
    window.open('', '_parent', '');
    window.close();
  };

  const imagePath = isMobile()
    ? '/assets/images/mailing/examples/mobile.svg'
    : '/assets/images/mailing/examples/desktop.svg';

  return (
    <div className={styles.mockEmailResult}>
      <div className={styles.head}>Email рассылки для ваших клиентов</div>
      <div className={styles.subhead}>
        Желаете ли вы продолжить оформление заказа?
      </div>
      <div className={cx(styles.image, isMobile() && styles.imageMobile)}>
        <img src={imagePath} alt="example image" />
        <div className={styles.buttonContainer}>
          <Button onClick={onClose}>
            Продолжить настройку Email рекомендации
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MockEmailLinkResult;
