import React from 'react';
import { IArrow } from '~/components/generic/Wheel/components/Arrow/types';
import styles from './styles.module.css';

export const Arrow = ({
  radius,
  arrowNumber,
  degree,
}: IArrow) => {
  const arrowSize = radius / 60;
  const left = radius - arrowSize * 2;
  const top = - arrowSize * 4;
  const display = degree * arrowNumber === 90 ? 'none' : 'block';

  const arrowContainerStyles = {
    transform: `rotate(${degree * arrowNumber}deg)`,
  };

  const arrowStyles = {
    width: `${arrowSize}px`,
    border: `${arrowSize * 2}px solid transparent`,
    borderBottom: `${arrowSize * 2}px solid black`,
    top: `${top}px`,
    left: `${left}px`,
    display,
  };

  return (
    <div className={styles.arrowContainer} style={arrowContainerStyles}>
      <div className={styles.arrow} style={arrowStyles} />
    </div>
  );
};
