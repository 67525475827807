import React from 'react';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import { GetActions } from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorFooter/GetActions';
import { GetAside } from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorFooter/GetAside';

const MassMailingConstructorFooter: React.FC = () => {
  return (
    <DrawerFooter
      className="mailing-constructor__footer"
      actions={<GetActions />}
      aside={<GetAside />}
    />
  );
};

export default MassMailingConstructorFooter;
