import cx from 'classnames';
import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  selected: boolean;
  onSelect: (selected: boolean) => void;
}

const EyeToggle: React.FC<Props> = ({
  className,
  style,
  selected,
  onSelect,
  children,
}) => {
  const handleClick = useCallback(() => {
    onSelect(!selected);
  }, [onSelect, selected]);

  return (
    <div
      className={cx('eye-toggle', className)}
      style={style}
    >
      <div className="eye-toggle__body">{children}</div>
      <div className="eye-toggle__icon" onClick={handleClick}>
        <Icon name={selected ? 'Eye' : 'EyeCrossed'} className="eye-toggle__svg" />
      </div>
    </div>
  );
};

export default EyeToggle;
