import { AxiosPromise } from 'axios';
import { actionGet } from './client';

interface CounterCodePayload {
  '@type': string;
  counter: string;
  analyticsId: string;
}

type CounterCodeResponse = IResponse<CounterCodePayload[]>;

const CounterCode = {
  codeResponse: (): AxiosPromise<CounterCodeResponse> => 
    actionGet('/admin/CounterCode', { action: 'get' }),
};

export default CounterCode;