import { observer } from 'mobx-react';
import React from 'react';
import style from './style.module.css';

interface IWheelDesktopProps {
  wheelString: string;
}

const WheelMobile: React.FC<IWheelDesktopProps> = observer(
  ({
    wheelString,
  }) => {

    return (
      <>
        <img
          src="/assets/wheel/mobile-device.png"
          alt=""
          width={'250px'}
        />
        <div
          className={style.mobileWrap}
        >
          <div
            className={style.mobileSecondWrap}
            dangerouslySetInnerHTML={{ __html: wheelString }}
          />
        </div>
      </>
    );
  }
);

export default WheelMobile;
