import cx from 'classnames';
import React from 'react';
import textTruncate from '~/utils/textTruncate';
import './style.css';

interface ITopPanelProps {
  urlPattern: string;
  feedTypeName: string;
}

const TopPanel: React.FC<ITopPanelProps> = ({ urlPattern, feedTypeName }) => (
  <div className="feed-editor-site__domain">
    <div className={cx('feed-editor-site__url-pattern', '_fourthStep')}>
      <div>{textTruncate(urlPattern, 40, 'end')}</div>
    </div>
    <span className={cx('feed-editor-site__type', '_fourthStep')}>
      {feedTypeName}
    </span>
  </div>
);

export default TopPanel;
