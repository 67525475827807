import { IFeedConfiguration } from 'feed';

export const currentImageCssParam = (configuration: IFeedConfiguration): string => {
  const {
    imageSize,
  } = configuration;
  
  const backgroundSize = {
    original : 'none',
    fill : 'cover',
    fit : 'contain',
  };

  return `
    cursor: pointer;
    position: relative;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
    pointer-events: none;
    object-fit: ${backgroundSize[imageSize]};
    border-radius: 4px;
  `.replace(/(\r\n|\n|\r|↵)/gm, '').trim();
};