import { feed, IFeedConfiguration, TFeedCard } from 'feed';
import React, { useEffect, useRef } from 'react';
import DemonstrationInfoPopup from '~/components/generic/StepSelectorAndDesign/Workspace/DemonstrationInfoPopup';
import {
  computeMobileFrameSize,
} from '~/components/generic/StepSelectorAndDesign/Workspace/Frame/computeFrameSize';
import FeedDesctop from '~/components/generic/StepSelectorAndDesign/Workspace/NoFrameDemonstration/FeedDesctop';
import FeedMobile from '~/components/generic/StepSelectorAndDesign/Workspace/NoFrameDemonstration/FeedMobile';
import './style.css';

interface IFeedEditorNoIFrame {
  device: Device;
  feedConfiguration: IFeedConfiguration;
  cards: TFeedCard[];
  contentHeight: string;
  feedMode: FeedMode;
  setActiveMenu: (activeMenu: StepDesignMenuElements) => void;
}

const NoFrameDemonstration: React.FC<IFeedEditorNoIFrame> = ({
  device,
  feedConfiguration,
  cards,
  contentHeight,
  feedMode,
  setActiveMenu,
}) => {
  const frameContainer = useRef<HTMLDivElement>();
  const mobileContainerRef = useRef<HTMLDivElement>();
  const mobileFrameRef = useRef<HTMLDivElement>();
  const elements = [
    'CATEGORIES',
    'IMAGE',
    'FEED_TITLE',
    'CARD_TITLE',
    'DESCRIPTION',
    'PRICE',
    'BUTTON',
    'LABEL',
  ];

  const categories = [
    {
      categoryName: 'Категория выбрана',
      categoryId: 'sampleCategory1',
    },
    {
      categoryName: 'Категория не выбрана',
      categoryId: 'sampleCategory2',
    },
  ];

  const feedCreator = new feed.FeedCreator(
    device, // вместо feedId
    '.feed-editor-step-selector-device-workspace-noframe__feed', // selector
    feedConfiguration,
    cards,
    device === 'mobile', // isMobileDevice
    categories, // Массив категорий
    'sampleCategory1' // Выбранная категория
  );
  feedCreator.feedStyle.addStyles(true);

  useEffect(() => {
    if (device === 'mobile') {
      computeMobileFrameSize(
        frameContainer,
        mobileContainerRef,
        mobileFrameRef
      );
    }
  }, [contentHeight]);

  const addActionFeed = () => {
    feedCreator.feedCompute.changeScrollPosition(0);
    if (device === 'desktop') feedCreator.feedCompute.toggleVisibilityScrollButtons();
    feedCreator.activateScrollButtons();
    feedCreator.feedCompute.setCallbackForElements(
      elements,
      ({ key }: { key: StepDesignMenuElements }) => setActiveMenu(key)
    );
    feedCreator.feedCompute.startHighlighting();
  };

  return (
    <>
      {device === 'desktop' && (
        <div className="feed-editor-step-selector-device-workspace-noframe__container">
          <div className="feed-editor-step-selector-device-workspace-noframe__wrapper">
            <FeedDesctop
              feedString={feedCreator.compileFeedElements().outerHTML}
              addActionFeed={addActionFeed}
            />
          </div>
        </div>
      )}

      {device === 'mobile' && (
        <div
          ref={frameContainer}
          className="feed-editor-step-selector-device-workspace-noframe__container"
        >
          <div
            className="feed-editor-step-selector-device-workspace-noframe-mobile__container-demonstration"
            ref={mobileContainerRef}
          >
            <FeedMobile
              mobileFrameRef={mobileFrameRef}
              feedString={feedCreator.compileFeedElements().outerHTML}
              addActionFeed={addActionFeed}
            />
          </div>
        </div>
      )}

      {feedMode === 'pro' && <DemonstrationInfoPopup />}
    </>
  );
};

export default NoFrameDemonstration;
