import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Loading from '~/components/Loading';
import Chip from '~/components/generic/Chip';
import Button from '~/components/ui/Button';
import { PreviewSample } from '~/screens/QuizScreen/components/constructorSteps/SelectSample/PreviewSample';
import QuizModel from '~/screens/QuizScreen/stores/QuizModel';
import {
  IQuizTemplate,
  QuizStore,
} from '~/screens/QuizScreen/stores/quizStore';
import textTruncate from '~/utils/textTruncate';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const SelectSample = observer(() => {
  const [selectedName, setSelectedName] = useState<string | undefined>(
    undefined
  );
  const [quizMetaId, setQuizMetaId] = useState<string | undefined>();

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const chipsRef = useRef(null);
  const samplesRef = useRef(null);
  const samples = quizStore.getSampleList();
  const sampleLabels = quizStore.getSampleLabels();
  const isTemplatesLoading = quizStore.isTemplatesLoading;

  useEffect(() => {
    if (isTemplatesLoading) return;

    const startBlockHeight = document
      .getElementById('quiz-select-sample-block')
      .getBoundingClientRect().height;
    const headerHeight = chipsRef.current.getBoundingClientRect().height;
    const content = samplesRef.current;

    if (headerHeight && typeof headerHeight === 'number') {
      content.style['max-height'] = `calc(${
        startBlockHeight - headerHeight
      }px)`;
    }
  }, [isTemplatesLoading]);

  if (isTemplatesLoading) {
    return <Loading status={'Loading'} onClick={() => { return; }} />;
  }

  const closePreview = () => {
    setQuizMetaId(undefined);
  };

  const template = (quizStore.quizTemplates.filter(
    (v: IQuizTemplate) => v.quizMetaId === quizMetaId
  ) || [])[0];

  return (
    <div className={styles.selectSample} id="quiz-select-sample-block">
      {quizMetaId && (
        <PreviewSample onClose={closePreview} template={template} />
      )}
      <div className={styles.chips} ref={chipsRef}>
        {sampleLabels.map((value, key) => {
          return (
            <Chip
              key={key}
              name={value}
              id={value}
              onClick={setSelectedName}
              isActive={value === selectedName}
              className={styles.chip}
            />
          );
        })}
      </div>
      <div className={styles.samples} ref={samplesRef}>
        {samples
          .filter((value) => {
            if (value.hasCustomAction || value.hasCustomType) {
              return true;
            }

            if ('Все шаблоны' === selectedName) return true;

            if (typeof selectedName === 'undefined') return true;

            if (!sampleLabels.filter((x: string) => value.labels.includes(x)))
              return true;

            return value.labels.includes(selectedName);
          })
          .map((value) => {
            let backgroundColor = '#fff';
            let backgroundSize = 'cover';

            if (value.hasCustomType) {
              backgroundColor =
                'linear-gradient(243.15deg, rgb(227, 255, 149) -33.206%, rgb(210, 255, 209) 115.862%)';
              backgroundSize = 'contain';
            }

            if (value.hasCustomAction) {
              backgroundColor =
                'linear-gradient(to right, rgb(255, 167, 246), rgb(209, 214, 255))';
              backgroundSize = 'contain';
            }

            const onOpenHelp = () => {
              location.href = 'https://app.sbercrm.com/form-builder-web/form.html?organizationId=aca51112-3ef8-11ed-8d1b-a7ef008ec515&formId=38ad65f8-f64d-11ee-9f4a-a3cef9af639b';

            };

            const finalName = value.hasCustomType ? 'Создать свой' : value.name;

            return (
              <div
                className={styles.sample}
                key={value.templateId}
                style={{
                  background: `url(${value.image}), ${backgroundColor}`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize,
                  backgroundPosition: 'left',
                }}
              >
                <div className={styles.shadow}>
                  {!(value.hasCustomType || value.hasCustomAction) && (
                    <Button
                      className={styles.shadowButton}
                      icon={'Eye'}
                      type={'white'}
                      size={'small'}
                      onClick={() => setQuizMetaId(value.templateId)}
                    >
                      Посмотреть
                    </Button>
                  )}
                  {value.hasCustomAction && (
                    <Button
                      className={styles.shadowButton}
                      icon={'Eye'}
                      type={'white'}
                      size={'small'}
                      onClick={onOpenHelp}
                    >
                      Перейти
                    </Button>
                  )}
                  {!value.hasCustomAction && (
                    <Button
                      className={styles.shadowButton}
                      icon={'EditCircle'}
                      type="white"
                      size={'small'}
                      onClick={() => {
                        // @ts-ignore TODO
                        const quizData = quizStore.quizTemplates.filter(
                          (v: IQuizTemplate) =>
                            v.quizMetaId === value.templateId
                        )[0].body;
                        const quizTemplate = quizStore.quizTemplates.filter(
                          (v: IQuizTemplate) =>
                            v.quizMetaId === value.templateId
                        )[0];
                        // @ts-ignore TODO типизация
                        quizStore.quizExample = new QuizModel(null, quizData);
                        quizStore.templateData = quizTemplate;
                        quizStore.setQuizConstructorStep(3);
                      }}
                    >
                      {value.hasCustomType ? 'Создать' : 'Редактировать'}
                    </Button>
                  )}
                </div>
                <div className={styles.curtain}>
                  <div className={styles.sampleName}>
                    {textTruncate(finalName, 40, 'end')}
                  </div>
                  <div className={styles.sampleValue}>
                    {value.labels.map((v, i) => (
                      <div key={i}>
                        {v}
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});
