import cx from 'classnames';
import React, { useState } from 'react';
import {
  ISegmentedPicker,
  ISegmentedPickerItem,
} from '~/components/ui/SegmentedPicker/types';
import styles from './style.module.css';

export const SegmentedPicker = ({
  className,
  onSwitch,
  items,
  theme,
}: ISegmentedPicker) => {
  const [selected, setSelected] = useState<number>(0);
  const onClick = (selected: number) => {
    setSelected(selected);
    onSwitch(selected);
  };

  return (
    <div className={cx(styles.segmentedPicker, className, theme === 'dark' && styles.dark)}>
      {items.map((item: ISegmentedPickerItem, key: number) => {
        return (
          <div
            onClick={() => onClick(item.key)}
            className={cx(
              styles.button,
              selected === item.key && styles.selected
            )}
            key={key}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
