import { Button, Form, Input, Modal, Select, Spin, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Users } from '~/api';
import OldPageHeader from '~/components/__deprecated/OldPageHeader';
import UserModel from '~/models/UserModel';
import { AuthStore } from '~/stores/authStore';
import { RolesStore } from '~/stores/rolesStore';
import './style.css';

const { Paragraph, Text } = Typography;
const { Option } = Select;

interface FormValues {
  login?: string;
  lastName?: string;
  middleName?: string;
  firstName?: string;
  role?: number;
}

type Props = {
  rolesStore?: RolesStore;
  authStore?: AuthStore;
} & RouteComponentProps<{
  id?: string;
}>;

interface State {
  temporaryPasswordModal: boolean;
  isLookupLoading: boolean;
  errorMessage: string;
}

@inject('rolesStore', 'authStore')
@observer
class EditUserScreen extends Component<Props> {
  user = new UserModel(this);

  state: State = {
    temporaryPasswordModal: false,
    isLookupLoading: false,
    errorMessage: '',
  };

  constructor(props: Props) {
    super(props);

    const id = this.props.match.params.id;

    if (id) {
      this.user.update({ id: Number(id) });
      this.props.rolesStore.fetchRoles();
      this.user.fetch();
    }
  }

  getErrorMessage (error: string) {
    const errorArray = error.split(':');

    return errorArray[1];
  }

  handleFinish = async (values: FormValues) => {
    const { authStore, rolesStore } = this.props;

    const isNew = this.user.isNew === true;

    const prevData = this.user.copy();
    const user: Partial<IUser> = {
      ...values,
      'role': rolesStore.getRoleById(values.role).copy(),
      '@type': 'UserInfo',
    };

    this.user.update(user);

    try {
      await this.user.save();

      if (isNew) {
        this.setState({ temporaryPasswordModal: true });
      } else {
        this.goUsersList();
        this.setState({ errorMessage: '' });
      }

      if (authStore.currentUser.get('id') === this.user.get('id')) {
        authStore.currentUser.update(user);
      }
    } catch (e) {
      this.user.clean();
      this.user.update(prevData);
      if (e.response) {
        this.setState({ errorMessage: this.getErrorMessage(e.response.data.errorData.errorText) });
      }
      throw e;
    }
  }

  goUsersList = () => {
    this.props.history.push('/users');
  }

  lookupLogin = async (rule: any, value: string, callback: (err?: string) => void) => {
    if (value === this.user.get('login') || value === '') return callback();

    this.setState({ isLookupLoading: true });

    try {
      await Users.lookup(value);
      callback();
    } catch (e) {
      callback('Такой логин уже занят');
    } finally {
      this.setState({ isLookupLoading: false });
    }
  }

  renderForm = () => {
    if (this.user.isFetching) return <Spin />;

    const { rolesStore } = this.props;

    const formItemLayout = {
      colon: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
    };

    const buttonItemLayout = {
      wrapperCol: { span: 12, offset: 4 },
    };

    return (
      <Form
        name="edit-user"
        layout="horizontal"
        onFinish={this.handleFinish}
        initialValues={{
          login: this.user.get('login'),
          role: this.user.get('role') ? this.user.get('role').id : undefined,
          lastName: this.user.get('lastName'),
          firstName: this.user.get('firstName'),
          middleName: this.user.get('middleName'),
        }}
      >
        {this.state.errorMessage &&  <p className="edit-user-screen-error">{this.state.errorMessage}</p>}
        <Form.Item
          {...formItemLayout}
          label="Логин"
          name="login"
          rules={[{
            required: true,
            message: 'Укажите логин',
          }, {
            validator: this.lookupLogin,
          }]}
        >
          <Input
            autoFocus={this.user.isNew}
            suffix={(
              <div style={{ display: this.state.isLookupLoading ? 'block' : 'none' }}>
                loading
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Роль"
          name="role"
          rules={[{ required: true, message: 'Укажите роль' }]}
        >
          <Select>
            {rolesStore.roles.map(r => (
              <Option
                key={r.key}
                value={r.get('id')}
              >
                {r.get('name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Фамилия" {...formItemLayout} name="lastName">
          <Input />
        </Form.Item>

        <Form.Item label="Имя" {...formItemLayout} name="firstName">
          <Input />
        </Form.Item>

        <Form.Item label="Отчество" {...formItemLayout} name="middleName">
          <Input />
        </Form.Item>

        <Form.Item label="Платформа" {...formItemLayout}>
          <Select disabled />
        </Form.Item>

        <Form.Item label="Доступные экраны" {...formItemLayout}>
          <Select disabled />
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.user.isSaving}>
            {this.user.isNew ? 'Создать' : 'Сохранить'}
          </Button>
          {/*{!this.user.isNew && (
            <>
              {' '}
              <Button type="danger" icon="unlock">
                Сбросить пароль
              </Button>
            </>
          )}*/}
        </Form.Item>
      </Form>
    );
  }

  handlePasswordModalOK = () => {
    this.setState({
      temporaryPasswordModal: false,
    });

    this.goUsersList();
  }

  renderPasswordModal = () => {
    return (
      <Modal
        title="Временные данные для входа"
        visible={this.state.temporaryPasswordModal}
        footer={(
          <>
            <div style={{ float: 'left' }}>
              <CopyToClipboard text={this.user.get('password')}>
                <Button>Скопировать пароль</Button>
              </CopyToClipboard>
              {' '}
              <CopyToClipboard text={
                `Логин: ${this.user.get('login')}\nВременный пароль: ${this.user.get('password')}`
              }>
                <Button>Скопировать всё</Button>
              </CopyToClipboard>
            </div>
            <Button type="primary" onClick={this.handlePasswordModalOK}>OK</Button>
          </>
        )}
      >
        <Typography>
          <Paragraph>
            <Text strong>Логин:</Text><br />
            <Text code>{this.user.get('login')}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Временный пароль:</Text><br />
            <Text code>{this.user.get('password')}</Text>
          </Paragraph>
        </Typography>
      </Modal>
    );
  }

  render() {
    return (
      <div className="edit-user-screen">
        <OldPageHeader
          title={this.user.isNew
            ? 'Создание пользователя'
            : 'Редактирование пользователя'}
          onBack={this.goUsersList}
        />
        {this.renderForm()}
        {this.renderPasswordModal()}
      </div>
    );
  }
}

export default withRouter(EditUserScreen);
