import React from 'react';

export const getYmlStatusText = (ymlCatalogStatus: string) => {
  switch (ymlCatalogStatus) {
    case 'UPLOAD_CACHED':
    case 'UPLOAD':
      return 'Каталог подключен';
    case 'YML_ERROR':
      return <span style={{ color: '#EF546B' }}> Ошибка в каталоге</span>;
    case 'NOT_UPLOAD':
      return 'Каталог не подключен';
    case 'VERIFY':
      return 'Каталог проверяется';
    default:
      return 'Неизвестная ошибка';
  }
};
