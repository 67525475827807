import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Drawer from '~/components/ui/Drawer';
import Tabs from '~/components/ui/Menu/Tabs';
import Footer from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor/Footer';
import StepCatalog from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor/steps/StepCatalog';
import StepFeedType from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor/steps/StepFeedType';
import StepManual from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor/steps/StepManual';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import { FEED_TYPE_INFO } from '~/screens/FeedProScreen/components/FeedProEditor/StepFeedType/constants';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const FeedApiEditor: React.FC = observer(() => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };

  const {
    isConstructorOpen,
    setIsConstructorOpen,
    constructorStore,
    feedApi,
    setIsMessageBeforeExitingOpen,
    isInvalidYmlUrl,
    isConfigInvalid,
  } = feedApiStore;
  const {
    currentStep,
    changeCurrentStep,
  } = constructorStore;

  useEffect(() => {
    isConstructorOpen && isConfigInvalid && changeCurrentStep('StepCatalog');
  }, [isConstructorOpen]);

  return (
    <>
      <Drawer
        visible={isConstructorOpen}
        onClose={() => {
          feedApi.hasDataChange()
            ? setIsMessageBeforeExitingOpen(true)
            : setIsConstructorOpen(false);
        }}
        title={`${
          feedApi.get('id') ? 'Редактирование' : 'Создание'
        } инструкции для интеграции Ленты${
          feedApi.get('recType')
            ? `: ${
                FEED_TYPE_INFO.find(
                  recType => recType.type === feedApi.get('recType')
                ).title
              }`
            : ''
        }`}
        size={'large'}
      >
        <div className={styles.block}>
          <Tabs
            items={[
              {
                key: 'StepFeedType',
                title: '1. Выбор типа рекомендаций',
                disabled: isConfigInvalid,
              },
              {
                key: 'StepCatalog',
                title: '2. Ссылка на каталог',
                disabled: !feedApi.get('recType'),
                isInvalid: isConfigInvalid,
              },
              {
                key: 'StepManual',
                title: '3. Получить инструкцию',
                disabled:
                  !feedApi.get('recType') ||
                  isInvalidYmlUrl === null ||
                  isInvalidYmlUrl ||
                  isConfigInvalid,
              },
            ]}
            value={currentStep.toString()}
            onChange={activeKey => changeCurrentStep(activeKey as EditorSteps)}
          />
          <div className={styles.content}>
            {currentStep === 'StepFeedType' && <StepFeedType />}
            {currentStep === 'StepCatalog' && <StepCatalog />}
            {currentStep === 'StepManual' && <StepManual />}
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      </Drawer>
    </>
  );
});

export default FeedApiEditor;
