import { quiz } from 'feed';
import React, { useEffect, useRef } from 'react';
import QuizModel from '~/screens/QuizScreen/stores/QuizModel';
const { QuizCreator } = quiz;
import cx from 'classnames';
import Button from '~/components/ui/Button';
import { getQuizShownConfiguration, getQuizShownSteps } from '~/screens/QuizScreen/utils';
import styles from './style.module.css';

interface IStep {
  step: number;
  template: QuizModel;
  setEditWindow: (v: number) => void;
  stepTitle?: string;
  isActive: boolean;
  className?: string;
}

export const Step = ({ step, template, setEditWindow, stepTitle, isActive, className }: IStep) => {
  const mobileRef = useRef(null);
  const desktopRef = useRef(null);

  useEffect(() => {

    const configuration = getQuizShownConfiguration(
        // @ts-ignore
      template.get('configuration')
    );

    const steps = getQuizShownSteps(template.get('steps'));

    const quizMobile = new QuizCreator(
      Math.ceil(Math.random() * 1000) + '',
        steps,
      configuration,
      true
    );
    quizMobile.quizStyle.addStyles();
    // @ts-ignore
    mobileRef.current.innerHTML = null;

    mobileRef?.current?.append(quizMobile.compileQuizElements(step));

    const quizDesktop = new QuizCreator(
      Math.ceil(Math.random() * 1000) + '',
        steps,
      configuration,
      false
    );
    quizDesktop.quizStyle.addStyles();
    // @ts-ignore
    desktopRef.current.innerHTML = null;
    desktopRef?.current?.append(quizDesktop.compileQuizElements(step));
  });

  const onClick = () => setEditWindow(step);

  return (
    <>
      <div className={cx(styles.stepCover, isActive && styles.isActive, className)}>
        <div className={styles.stepCurtain} onClick={onClick}>
          <Button
            className={styles.editButton}
            type={'grey'}
            icon={'EditCircle'}
          >
            Редактировать
          </Button>
        </div>
        {stepTitle && <div className={styles.stepSectionName}>{stepTitle}</div>}
        <div className={cx(styles.stepWrap)}>
          <div className={styles.stepMobileWrap}>
            <div className={styles.stepMobile} ref={mobileRef} />
          </div>
          <div className={styles.stepDesktopWrap}>
            <div className={styles.stepDesktop} ref={desktopRef} />
          </div>
        </div>
      </div>
    </>
  );
};
