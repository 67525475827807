import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ListItemButton from '~/components/generic/GroupListItems/ListItemButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import ButtonLike from '~/components/ui/ButtonLike';
import Drawer from '~/components/ui/Drawer';
import CloseDialog from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/CloseDialog';
import FeedSelection from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/FeedSelection';
import Parameters from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/Parameters';
import SettingRule from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/SettingRule';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRulesByFeed,
  BusinessRuleEntity,
  BusinessRuleMeta,
  FeedForBusinessRule,
  FilterBusinessRuleEntityRequest,
} from '~/screens/BusinessRulesScreen/types';
import './style.css';
import styles from './style.module.css';

const BusinessRuleEditor: React.FC<{
  isOpenEditor: boolean;
  closeEditor: () => void;
  metadata: BusinessRuleMeta;
  businessRule: BusinessRulesModel;
  feedForBusinessRule: FeedForBusinessRule[];
  saveBusinessRule: () => void;
  getBusinessRuleEntity: (
    parameters: FilterBusinessRuleEntityRequest
  ) => Promise<BusinessRuleEntity[]>;
  businessRulesByFeed: BusinessRulesByFeed;
  recommendationForTypeDict: Map<string, string>;
  recommendationTypeDict: Map<string, string>;
  recommendationOfWhatTypeDict: Map<string, string>;
  similarityTypeDict: Map<string, string>;
}> = observer(
  ({
    isOpenEditor,
    closeEditor,
    metadata,
    businessRule,
    feedForBusinessRule,
    saveBusinessRule,
    getBusinessRuleEntity,
    businessRulesByFeed,
    recommendationForTypeDict,
    recommendationTypeDict,
    recommendationOfWhatTypeDict,
    similarityTypeDict,
  }) => {
    const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);
    const [isOpenFeedSelection, setIsOpenFeedSelection] = useState(false);
    const [isOpenSettingRule, setIsOpenSettingRule] = useState(false);
    const [isOpenParameters, setIsOpenParameters] = useState(false);

    const feedSelectionIsDone: boolean = !!businessRule.get('feedId');
    const settingRuleIsDone: boolean =
      businessRule.get('recommendationOfWhat')?.entities.length > 0 &&
      businessRule.get('recommendationOfWhat')?.entities.every(entity => entity.id > 0) &&
      businessRule.get('recommendationFor').entities.id !== 0;
    const parametersIsDone: boolean =
      businessRule.get('similarityType')?.length > 0;

    return (
      <>
        <Drawer
          className="business_rule_editor__drawer"
          title={businessRule.get('ruleName')}
          visible={isOpenEditor}
          onClose={() => {
            businessRule.hasDataChange()
              ? setIsOpenWarningDialog(true)
              : closeEditor();
          }}
          size="x-small"
          actions={(
            <ButtonLike
              onClick={saveBusinessRule}
              disabled={!settingRuleIsDone}
            >
              Сохранить
            </ButtonLike>
          )}
        >
          <div className={styles.content_wrapper}>
            <NumberedBlock
              number={1}
              className={styles.number_block}
              isDone={feedSelectionIsDone}
            >
              <ListItemButton
                title={'Выберите ленту рекомендаций'}
                subtitle={
                  feedSelectionIsDone
                    ? businessRule.get('feedName') || 'Без названия'
                    : 'Выберите, для какой созданной ленты рекомендаций применить эти бизнес-правила.'
                }
                icon={'ArrowSRight'}
                onClickArgument
                onClick={() => setIsOpenFeedSelection(true)}
              />
            </NumberedBlock>
            <NumberedBlock
              number={2}
              className={styles.number_block}
              isDisabled={!feedSelectionIsDone}
              isDone={settingRuleIsDone}
            >
              <ListItemButton
                isDisabled={!feedSelectionIsDone}
                title={'Настройте правило'}
                subtitle={
                  settingRuleIsDone
                    ? `${recommendationForTypeDict.get(
                        businessRule.get('recommendationFor').type
                      )} «${
                        businessRule.get('recommendationFor').entities.name
                      }» 
                      ${recommendationTypeDict
                        .get(businessRule.get('recommendationType'))
                        .toLocaleLowerCase()} 
                      ${recommendationOfWhatTypeDict
                        .get(businessRule.get('recommendationOfWhat').type)
                        .toLocaleLowerCase()}
                      ${
                        ' ' +
                        businessRule
                          .get('recommendationOfWhat')
                          .entities.map(
                            entities => `«${entities.name}» (${entities.itemCount} тов.)`
                          )
                          .join(', ')
                      }`
                    : 'Укажите, какие товары и категории вы хотите рекомендовать для других товаров и категорий.'
                }
                icon={'ArrowSRight'}
                onClick={() => setIsOpenSettingRule(true)}
              />
            </NumberedBlock>
            <NumberedBlock
              number={3}
              className={styles.number_block}
              isDisabled={!settingRuleIsDone}
              isDone={parametersIsDone}
            >
              <ListItemButton
                isDisabled={!settingRuleIsDone}
                title={'Выберите, по каким параметрам подбирать схожие товары'}
                subtitle={
                  parametersIsDone
                    ? `${businessRule.get('similarityType')
                      .map((key: string) => {
                        return similarityTypeDict.get(key);
                      })
                      .join(', ').toLocaleLowerCase() || '—'}`
                    : 'Можно пропустить этот шаг'}
                icon={'ArrowSRight'}
                onClick={() => setIsOpenParameters(true)}
              />
            </NumberedBlock>
          </div>
          <FeedSelection
            isOpenFeedSelection={isOpenFeedSelection}
            closeEditor={() => setIsOpenFeedSelection(false)}
            feedForBusinessRule={feedForBusinessRule}
            businessRule={businessRule}
            feedSelectionIsDone={feedSelectionIsDone}
          />
          <SettingRule
            isOpenSettingRule={isOpenSettingRule}
            closeEditor={() => setIsOpenSettingRule(false)}
            metadata={metadata}
            businessRule={businessRule}
            getBusinessRuleEntity={getBusinessRuleEntity}
            businessRulesByFeed={businessRulesByFeed}
            settingRuleIsDone={settingRuleIsDone}
          />
          <Parameters
            isOpenParameters={isOpenParameters}
            closeEditor={() => setIsOpenParameters(false)}
            metadata={metadata}
            businessRule={businessRule}
          />
        </Drawer>
        {isOpenWarningDialog && (
          <CloseDialog
            visible={isOpenWarningDialog}
            onApply={() => {
              setIsOpenWarningDialog(false);
              closeEditor();
            }}
            onClose={() => setIsOpenWarningDialog(false)}
            isChanging={!!businessRule.get('ruleId')}
          />
        )}
      </>
    );
  }
);

export default BusinessRuleEditor;
