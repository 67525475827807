import { action, observable } from 'mobx';
import Leads from '~/api/leads';
import Model from '~/models/Model';
import { getError } from '~/utils/getError';

export default class LeadSettingsModel extends Model<ILeadSettingsModel> {
  @observable isTelegramFetching: boolean;

  getTelegramIntegrationState = async () => {
    try {
      this.update({ isTelegramLoading: true });
      const result = await Leads.getTelegramInitialState();
      const data = result.data?.payload[0];
      const { isBinding, bindingUrl } = data;
      this.update({ isTelegramSet: isBinding, telegramUrl: bindingUrl });
    } catch (e) {
      this.update({ telegramError: 'Внутренняя ошибка' });
      console.log(getError({ error: e }));
    } finally {
      this.update({ isTelegramLoading: false });
    }
  }

  disableTelegram = async () => {
    try {
      await Leads.disableTelegram();
      this.update({ isTelegramSet: false });
    } catch (e) {
      throw e;
    }
  }

  @action
  sendTelegramCode = () => {
    this.update({ isTelegramSet: true });
  }

  get isTelegramSet() {
    return this.get('isTelegramSet');
  }

  getBitrixIntegrationState = async () => {
    try {
      this.update({ isBitrixLoading: true });
      const result = await Leads.getBitrixInitialState();
      const data = result.data?.payload[0];
      const { isEnabled } = data;
      this.update({ isBitrixSet: isEnabled });
    } catch (e) {
      this.update({ bitrixError: 'Внутренняя ошибка' });
      console.log(getError({ error: e }));
    } finally {
      this.update({ isBitrixLoading: false });
    }
  }

  disableBitrix = async () => {
    try {
      await Leads.switchBitrix(false);
      this.update({ isBitrixSet: false });
    } catch (e) {
      throw e;
    }
  }

  enableBitrix = async (bindingUrl: string) => {
    this.update({ bitrixUrlError: '' });
    try {
      const result = await Leads.switchBitrix(true, bindingUrl);
      const data = result.data.payload[0];
      const { isEnabled } = data;

      this.update({ isBitrixSet: isEnabled });

      if (!isEnabled) {
        this.update({ bitrixUrlError: 'Вебхук не был подключен. Проверьте правильность его создания. Обратите внимание на метод и права, которые имеет вебхук.' });
      }
    } catch (e) {
      this.update({ bitrixUrlError: getError({ error: e }) });
      console.log(getError({ error: e }));
      throw e;
    }
  }

  getSberCRMIntegrationState = async () => {
    try {
      this.update({ isSberCRMLoading: true });
      const result = await Leads.getSberCRMInitialState();
      const data = result.data?.payload[0];
      const { isEnabled } = data;
      this.update({ isSberCRMSet: isEnabled });
    } catch (e) {
      this.update({ sberCRMError: 'Внутренняя ошибка' });
      console.log(getError({ error: e }));
    } finally {
      this.update({ isSberCRMLoading: false });
    }
  }

  disableSberCRM = async () => {
    try {
      await Leads.switchSberCRM(false);
      this.update({ isSberCRMSet: false });
    } catch (e) {
      throw e;
    }
  }

  enableSberCRM = async (bindingUrl: string) => {
    this.update({ sberCRMUrlError: '' });
    try {
      const result = await Leads.switchSberCRM(true, bindingUrl);
      const data = result.data.payload[0];
      const { isEnabled } = data;

      this.update({ isSberCRMSet: isEnabled });

      if (!isEnabled) {
        this.update({ sberCRMUrlError: 'Вебхук не был подключен. Проверьте правильность его создания. Обратите внимание на метод и права, которые имеет вебхук.' });
      }
    } catch (e) {
      this.update({ sberCRMUrlError: getError({ error: e }) });
      console.log(getError({ error: e }));
      throw e;
    }
  }

  getMacroCRMIntegrationState = async () => {
    try {
      this.update({ isMacroCRMLoading: true });
      const result = await Leads.getMacroCRMInitialState();
      const data = result.data?.payload[0];
      const { isEnabled } = data;
      this.update({ isMacroCRMSet: isEnabled });
    } catch (e) {
      this.update({ macroCRMError: 'Внутренняя ошибка' });
      console.log(getError({ error: e }));
    } finally {
      this.update({ isMacroCRMLoading: false });
    }
  }

  disableMacroCRM = async () => {
    try {
      await Leads.switchMacroCRM(false);
      this.update({ isMacroCRMSet: false });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  enableMacroCRM = async (bindingUrl: string) => {
    this.update({ bitrixUrlError: '' });
    try {
      const result = await Leads.switchMacroCRM(true, bindingUrl);
      const data = result.data.payload[0];
      const { isEnabled } = data;

      this.update({ isMacroCRMSet: isEnabled });

      if (!isEnabled) {
        this.update({ sberCRMUrlError: 'Вебхук не был подключен. Проверьте правильность его создания. Обратите внимание на метод и права, которые имеет вебхук.' });
      }
    } catch (e) {
      this.update({ sberCRMUrlError: getError({ error: e }) });
      console.log(getError({ error: e }));
      throw e;
    }
  }

  getAmoCRMIntegrationState = async () => {
    try {
      this.update({ isAmoCRMLoading: true });
      const result = await Leads.getAmoCRMInitialState();
      const data = result.data?.payload[0];
      const { isBinding } = data;
      this.update({ isAmoCRMSet: isBinding });
    } catch (e) {
      this.update({ amoCRMError: getError({ error: e })  });
      console.log(getError({ error: e }));
    } finally {
      this.update({ isAmoCRMLoading: false });
    }
  }

  disableAmoCRM = async () => {
    try {
      await Leads.switchAmoCRM(false);
      this.update({ isAmoCRMSet: false });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  enableAmoCRM = async (subdomain: string, amoToken: string) => {
    this.update({ amoCRMError: '' });
    try {
      const result = await Leads.switchAmoCRM(true, subdomain, amoToken);
      const data = result.data.payload[0];
      const { isBinding } = data;
      this.update({ isAmoCRMSet: isBinding });
    } catch (e) {
      this.update({ amoCRMUrlError: getError({ error: e }) });
      this.update({ amoCRMTokenError: getError({ error: e }) });
      console.log(getError({ error: e }));
      throw e;
    }
  }
}
