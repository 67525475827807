import { Button, Checkbox, Col, Form, Input, Row, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Roles } from '~/api';
import RoleModel from '~/models/RoleModel';
import OldScreen from '~/screens/__deprecated/OldScreen';
import { RolesStore } from '~/stores/rolesStore';
import './style.css';

interface FormValues {
  name?: string;
  code?: string;
  rights?: number[];
}

type Props = {
  rolesStore?: RolesStore;
} & RouteComponentProps<{
  id?: string;
}>;

interface State {
  isNameLookupLoading: boolean;
  isCodeLookupLoading: boolean;
  errorMessage: string;
}

@inject('rolesStore')
@observer
class EditRoleScreen extends Component<Props> {
  role = new RoleModel(this, { rights: [] });

  state: State = {
    isNameLookupLoading: false,
    isCodeLookupLoading: false,
    errorMessage: '',
  };

  constructor(props: Props) {
    super(props);

    const id = props.match.params.id;

    if (id) {
      this.role = props.rolesStore.getRoleById(Number(id));
    }
  }

  getErrorMessage (error: string) {
    const errorArray = error.split(':');

    return errorArray[1];
  }

  handleFinish = async (values: FormValues) => {
    const { rolesStore } = this.props;

    const role: Partial<IRole> = {
      '@type': 'RoleInfo',
      'state': 'ACTIVE',
      'name': values.name,
      'code': values.code,
      'rights': this.role.get('rights'),
    };

    if (values.rights) {
      role.rights = rolesStore.getRightsByIds(values.rights).map(r => r.copy());
    }

    this.role.update(role);

    try {
      await this.role.save();
      await rolesStore.fetchRoles();
      this.setState({ errorMessage: '' });
      this.goRolesList();
    } catch (e) {
      if (e.response) {
        this.setState({ errorMessage: this.getErrorMessage(e.response.data.errorData.errorText) });
      }
      throw e;
    }
  }

  goRolesList = async () => {
    this.props.history.push('/roles');
    if (this.state.errorMessage) {
      try {
        const { rolesStore } = this.props;
        await rolesStore.fetchRoles();
      } catch (e) {
        throw e;
      }
    }
  }

  lookupCode = async (rule: any, value: string, callback: (err?: string) => void) => {
    if (value === this.role.get('code') || value === '') return callback();

    this.setState({ isCodeLookupLoading: true });

    try {
      const res = await Roles.codeLookup(value);

      if (res.data.payload.length) {
        callback('Роль с таким кодом уже существует');
      } else {
        callback();
      }
    } catch (e) {
      callback('Неудалось проверить уникальность кода из-за ошибки сети');
    } finally {
      this.setState({ isCodeLookupLoading: false });
    }
  }

  lookupName = async (rule: any, value: string, callback: (err?: string) => void) => {
    if (value === this.role.get('name') || value === '') return callback();

    this.setState({ isNameLookupLoading: true });

    try {
      const res = await Roles.nameLookup(value);

      if (res.data.payload.length) {
        callback('Роль с таким именем уже существует');
      } else {
        callback();
      }
    } catch (e) {
      callback('Неудалось проверить уникальность имени из-за ошибки сети');
    } finally {
      this.setState({ isNameLookupLoading: false });
    }
  }

  renderForm = () => {
    if (this.role.isFetching) return <Spin />;

    const { rolesStore } = this.props;

    const formItemLayout = {
      colon: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
    };
    const buttonItemLayout = {
      wrapperCol: { span: 12, offset: 4 },
    };

    return (
      <Form
        name="edit-role"
        layout="horizontal"
        onFinish={this.handleFinish}
        initialValues={{
          name: this.role.get('name'),
          code: this.role.get('code'),
          rights: this.role.get('rights').map(r => r.id),
        }}
      >
        {this.state.errorMessage &&  <p className="edit-role-screen-error">{this.state.errorMessage}</p>}
        <Form.Item
          {...formItemLayout}
          label="Имя роли"
          name="name"
          rules={[{
            required: true,
            message: 'Укажите имя роли',
          }, {
            validator: this.lookupName,
          }]}
        >
          <Input
            autoFocus={this.role.isNew}
            suffix={(
              <div style={{ display: this.state.isNameLookupLoading ? 'block' : 'none' }}>
                loading
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Код роли"
          name="code"
          rules={[{
            required: true,
            message: 'Укажите код роли',
          }]}
        >
          <Input
            suffix={(
              <div style={{ display: this.state.isCodeLookupLoading ? 'block' : 'none' }}>
                loading
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Права доступа"
          name="rights"
          rules={[{ required: true, message: 'Укажите хотя бы одно право доступа' }]}
        >
          <Checkbox.Group>
            <Row>
              {rolesStore.rights.map(right => (
                <Col key={right.key}>
                  <Checkbox value={right.get('id')}>
                    {right.get('name')}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button
            htmlType="submit"
            loading={this.role.isSaving}
          >
            {this.role.isNew
              ? 'Создать'
              : 'Сохранить'
            }
          </Button>
        </Form.Item>
      </Form>
    );
  }

  render() {
    return (
      <OldScreen
        className="edit-role-screen"
        availableWithRule={'ADMIN'}
        title={this.role.isNew
          ? 'Создание новой роли'
          : 'Редактирование роли'
        }
        titleOnBack={this.goRolesList}
      >
        {this.renderForm()}
      </OldScreen>
    );
  }
}

export default withRouter(EditRoleScreen);

