import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Loading from '~/components/Loading';
import FeedInfo from '~/components/generic/FeedInfo';
import { EFeedType } from '~/components/generic/FeedInfo/types';
import TableMessages from '~/components/generic/FeedStatisticTable/TableMessages';
import EmptyScreen from '~/screens/ApiScreen/FeedApi/components/EmptyScreen';
import FeedApiEditor from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor';
import FeedApiStatistics from '~/screens/ApiScreen/FeedApi/components/FeedApiStatistics';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import LoadingScreen from '~/screens/LoadingScreen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

const FeedApi: React.FC = observer(() => {
  const { feedApiStore, authStore } = useStores() as {
    feedApiStore: FeedApiStore;
    authStore: AuthStore;
  };
  const {
    feedApiStatistics,
    getFeedApiStatistics,
    isFetchingStatistics,
    fetchingStatisticsError,
    isStoppingDialogOpen,
    setIsStoppingDialogOpen,
    onClickActionButton,
    isShowWaitMessage,
    setIsShowWaitMessage,
    isRemoveDialogOpen,
    setIsRemoveDialogOpen,
    feedStatisticsActiveRow,
    isShowExceedingLimitMessage,
    setIsShowExceedingLimitMessage,
    isBeforeEditOpen,
    isFetchingRequest,
    isOpenRenameDialog,
    setIsOpenRenameDialog,
    renameFeed,
    feedApi,
    isMessageBeforeExitingOpen,
    setIsMessageBeforeExitingOpen,
    setIsConstructorOpen,
    setIsBeforeEditOpen,
    isInfoOpen,
    setIsInfoOpen,
    isOpenFinalDialog,
    isShowTabooTypeChangesMessage,
    setIsShowTabooTypeChangesMessage,
  } = feedApiStore;

  useEffect(() => {
    getFeedApiStatistics();

    return () => feedApiStore.destroy();
  }, []);

  if (isFetchingStatistics) {
    return <LoadingScreen />;
  }

  const loadingStatus = () => {
    if (isFetchingStatistics) return 'Loading';
    if (fetchingStatisticsError) return 'Error';

    return 'Success';
  };

  const loadedStatus = loadingStatus();
  const hasRight = authStore.currentUser.hasRightTo('VIEW_FEEDS');
  const showFeed =
    hasRight &&
    feedApiStatistics &&
    feedApiStatistics.length > 0 &&
    loadedStatus === 'Success';
  const showFirstEntry =
    hasRight && feedApiStatistics && feedApiStatistics.length === 0;

  return (
    <>
      {hasRight && (
        <Loading status={loadedStatus} onClick={getFeedApiStatistics} />
      )}
      {(!hasRight || showFirstEntry) && (
        <EmptyScreen hasRight={hasRight} />
      )}
      {showFeed && <FeedApiStatistics />}
      <FeedApiEditor />
      <FeedInfo
        isInfoDrawerOpen={isInfoOpen}
        setIsInfoDrawerOpen={setIsInfoOpen}
        onClickActionButton={onClickActionButton}
        feedStatistic={feedStatisticsActiveRow}
        feedType={EFeedType.API}
      />
      <TableMessages
        feedStatisticsActiveRow={feedStatisticsActiveRow}
        onClickActionButton={onClickActionButton}
        isChanging={!!feedApi?.get('id')}
        isMessageBeforeExitingOpen={isMessageBeforeExitingOpen}
        setIsMessageBeforeExitingOpen={setIsMessageBeforeExitingOpen}
        setIsConstructorOpen={setIsConstructorOpen}
        isStoppingDialogOpen={isStoppingDialogOpen}
        setIsStoppingDialogOpen={setIsStoppingDialogOpen}
        isShowWaitMessage={isShowWaitMessage}
        setIsShowWaitMessage={setIsShowWaitMessage}
        isRemoveDialogOpen={isRemoveDialogOpen}
        setIsRemoveDialogOpen={setIsRemoveDialogOpen}
        isShowExceedingLimitMessage={isShowExceedingLimitMessage}
        setIsShowExceedingLimitMessage={setIsShowExceedingLimitMessage}
        isBeforeEditOpen={isBeforeEditOpen}
        isFetchingRequest={isFetchingRequest}
        isOpenRenameDialog={isOpenRenameDialog}
        setIsOpenRenameDialog={setIsOpenRenameDialog}
        renameFeed={renameFeed}
        setIsBeforeEditOpen={setIsBeforeEditOpen}
        isOpenFinalDialog={isOpenFinalDialog}
        isShowTabooTypeChangesMessage={isShowTabooTypeChangesMessage}
        setIsShowTabooTypeChangesMessage={setIsShowTabooTypeChangesMessage}
      />
    </>
  );
});

export default FeedApi;
