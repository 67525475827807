import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import RadioGrid from '~/components/notification-editor/RadioGrid';
import FormItem from '~/components/ui/FormItem';
import './style.css';

interface ItemsAlignmentProps {
  align?: INotificationAlignment;
  showTopRow?: boolean;
  showCenterRow?: boolean;
  showBottomRow?: boolean;
  onSelect?: (align: INotificationAlignment) => void;
  hint?: string;
}
interface KeyName {
  name: string;
  key: INotificationAlignment;
}

const topItems: KeyName[] = [
  { name: 'AlignTopLeft', key: 'top-left' },
  { name: 'AlignTopCenter', key: 'top-center' },
  { name: 'AlignTopRight', key: 'top-right' },
];

const centerItems: KeyName[] = [
  { name: 'AlignCenterLeft', key: 'center-left' },
  { name: 'AlignCenter', key: 'center' },
  { name: 'AlignCenterRight', key: 'center-right' },
];

const bottomItems: KeyName[] = [
  { name: 'AlignBottomLeft', key: 'bottom-left' },
  { name: 'AlignBottomCenter', key: 'bottom-center' },
  { name: 'AlignBottomRight', key: 'bottom-right' },
];


const ItemAlignment: React.FC<ItemsAlignmentProps> = ({
  align,
  onSelect,
  showTopRow = true,
  showCenterRow = true,
  showBottomRow = true,
  hint,
}) => {
  let visibleItems: KeyName[] = [];
  showTopRow && (visibleItems = [...visibleItems, ...topItems]);
  showCenterRow && (visibleItems = [...visibleItems, ...centerItems]);
  showBottomRow && (visibleItems = [...visibleItems, ...bottomItems]);

  return (
    <div
      className="item-alignment">
      <FormItem
        title="Выравнивание"
        hint={hint}
        noReservedPlaceForMessage
      >
        <div className="item-alignment__radiogrid">
          <RadioGrid
            onSelect={onSelect}
            items={(visibleItems).map(({ key, name }) => (
              <div key={key} className={cx('item-alignment__icon', { _selected: align === key })}>
                <Icon name={name} />
              </div>
            )
            )}
          />
        </div>
      </FormItem>
    </div>
  );
};

export default ItemAlignment;
