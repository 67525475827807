import React, { useState } from 'react';
import DeviceSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import TooltipForBlockCrossedPricePro from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockCrossedPricePro/TooltipForBlockCrossedPricePro';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';

interface IBlockCrossedPriceProProps {
  feed: FeedProModel;
}

const OPTIONS = [
  {
    device: 'off',
    label: <>Не использовать</>,
  },
  {
    device: 'on',
    label: <>Показывать старую и новую цены</>,
  },
];

const BlockCrossedPricePro: React.FC<IBlockCrossedPriceProProps> = ({ feed }) => {

  const [statusBlockCrossedPrice, setStatusBlockstatusBlockCrossedPrice] = useState<
    string
  >(feed.get('configuration').hasCardCrossedPrice ? 'on' : 'off');

  const onChangeSelector = (option: string) => {
    feed.setConfiguration({
      ...feed.get('configuration'),
      hasCardCrossedPrice: option === 'on' ? true : false,
    });
    setStatusBlockstatusBlockCrossedPrice(option);
  };

  return (
    <div className="feed-pro-editor-step-catalog__device-selector-radio-button">
      <NumberedBlock
        className={'feed-pro-editor-step-catalog__block-radio-button'}
      >
        <DeviceSelectorRadioButton
          title={'Старая и новая цена'}
          tooltipForTitle={TooltipForBlockCrossedPricePro}
          message={
            'Цены на сайте могут отображаться со скидкой. Наличие старой цены в товаре повышает конверсию к покупке'
          }
          currentDevice={statusBlockCrossedPrice}
          onChange={onChangeSelector}
          listOptions={OPTIONS}
          infoLabelForItems={[
            { text: 'Рекомендованные товары будут отображаться только с основной ценой' },
            {
              text: 'Рекомендованные товары будут отображаться со старой и новой ценой',
              actionReaction: true,
            },
          ]}
          className={'feed-pro-editor-step-catalog__radio-button'}
        />
      </NumberedBlock>
      <div>
        <img style={{ width: 232 }} src="/assets/feed/crossed-price-sample.png" />
      </div>
    </div>
  );
};

export default BlockCrossedPricePro;