import { observer } from 'mobx-react';
import React from 'react';
import { getSettingsElement } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/utils';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';
import styles from './styles.module.css';

export const MailSettings = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const selectedMailSection = mailingStore.mailingInstance.getSelectedSection();
  const SettingsElement = getSettingsElement(selectedMailSection?.name);

  return (
    <div className={styles.wrapper}>
      {selectedMailSection && (
        <SettingsElement
          configuration={selectedMailSection}
          onEdit={mailingStore.mailingInstance.changeValue}
        />
      )}
      </div>
  );
});
