import cx from 'classnames';
import React, { useEffect, useState, CSSProperties } from 'react';
import { Link } from 'react-scroll';
import Icon from '~/components/Icon';
import MenuItem from '~/components/ui/Menu/MenuItem';
import Tooltip from '~/components/ui/Tooltip';
import './style.css';

export interface MenuRoute {
  key: string;
  title: React.ReactNode | string;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  children?: MenuRoute[];
  scrollToId?: string;
  isInvalid?: boolean;
}

export const locateKeyInMenu = (targetKey: string, routes: MenuRoute[]): string[] => {
  const find = (item: MenuRoute, search: string, route: string[] = []): string[] => {
    if (item.key === search) {
      return route;
    }

    if (item.children) {
      for (const child of item.children) {
        const res = find(child, search, [...route, child.key]);
        if (res) return res;
      }
    }

    return null;
  };

  for (const route of routes) {
    return find(route, targetKey, [route.key]);
  }

  return null;
};

const countMaxItems = (routes: MenuRoute[]): number => {
  const lengths: number[] = [];

  const getMax = (routes: MenuRoute[], firstPass: boolean) => {
    if (!firstPass) lengths.push(routes.length);

    routes.forEach((route) => {
      if (route.children) {
        getMax(route.children, false);
      }
    });
  };

  getMax(routes, true);

  return Math.max(...lengths);
};

interface Props {
  routes: MenuRoute[];
  selected: string[];
  onSelect: (key: string, route: string[]) => void;
  withOverlay?: boolean;
  pinToScreen?: boolean;
  style?: CSSProperties;
  overlayStyle?: CSSProperties;
  className?: string;
  overlayClassName?: string;
  noPadding?: boolean;
  withScroll?: boolean;
}

const Menu: React.FC<Props> = ({
  routes,
  selected,
  onSelect,
  withOverlay,
  pinToScreen,
  style,
  className,
  overlayStyle,
  overlayClassName,
  noPadding,
  withScroll,
}) => {
  const [firstRoute, setFirstRoute] = useState<string>(null);
  const [secondRoute, setSecondRoute] = useState<string>(null);
  const [dropdownLines, setDropdownLines] = useState<number>();

  useEffect(() => {
    setDropdownLines(countMaxItems(routes));
  }, [routes]);

  const handleItemClick = (route: MenuRoute, key: string, path: string[]) => {
    if (path.length === 1 && route.children) {
      if (firstRoute === key) {
        setFirstRoute(null);
        setSecondRoute(null);
      } else {
        setFirstRoute(key);
        setSecondRoute(null);
      }

      return;
    }

    setFirstRoute(null);
    setSecondRoute(null);

    onSelect(key, path);
  };

  const handleDropdownItemMouseEnter = (key: string) => {
    setSecondRoute(key);
  };

  const handleClickOverlay = () => {
    setFirstRoute(null);
    setSecondRoute(null);
  };

  const dropdownVisible = typeof firstRoute === 'string';
  const firstLevel = routes.find(route => route.key === firstRoute);
  const secondLevel = firstLevel && firstLevel.children
    ? firstLevel.children.find(route => route.key === secondRoute)
    : undefined
  ;

  return (
    <>
      <div
        className={cx('menu', {
          '_pin-to-screen': pinToScreen,
          '_no-padding': noPadding,
        }, className)}
        style={style}
      >
        <div className="menu__header menu-header">
          {!withScroll && routes.map(route => (
            <div
              key={route.key}
              className={cx('menu-header__item menu-header-item', {
                _selected: selected && selected.length > 0 && selected[0] === route.key,
                _active: firstRoute && firstRoute === route.key,
                _disabled: route.disabled,
                _invalid: route.isInvalid,
              })}
              onClick={route.disabled ? undefined : () => handleItemClick(route, route.key, [route.key])}
            >
              <div className="menu-header-item__title">
                {route.title}
              </div>
              {route.children && (
                <div className="menu-header-item__icon">
                  <Icon name="ArrowSDown" />
                </div>
              )}
              {route.tooltip && (
                <div className="menu-header-item__icon _question">
                  <Tooltip
                    placement="top"
                    title={route.tooltip}
                  >
                    <Icon name="Question" />
                  </Tooltip>
                </div>
              )}
            </div>
          ))}
          {withScroll && routes.map(route => (
            <Link
              to={route.scrollToId}
              smooth
              key={route.key}
              className={cx('menu-header__item menu-header-item', {
                _selected: selected && selected.length > 0 && selected[0] === route.key,
                _active: firstRoute && firstRoute === route.key,
              })}
              onClick={() => handleItemClick(route, route.key, [route.key])}
            >
              <div className="menu-header-item__title">
                {route.title}
              </div>
              {route.children && (
                <div className="menu-header-item__icon">
                  <Icon name="ArrowSDown" />
                </div>
              )}
            </Link>
          ))}
        </div>
        <div
          className={cx('menu__dropdown menu-dropdown', { _visible: dropdownVisible })}
          data-lines={dropdownLines}
        >
          {dropdownVisible && (
            <div className="menu-dropdown__column">
              {firstLevel && firstLevel.children && firstLevel.children.map((route) => {
                const withDot = selected && selected.length >= 2 &&
                  selected[0] === firstLevel.key &&
                  selected[1] === route.key
                ;

                return (
                  <MenuItem
                    key={route.key}
                    onMouseEnter={() => handleDropdownItemMouseEnter(route.key)}
                    active={secondRoute === route.key}
                    disabled={route.disabled}
                    withDot={withDot}
                    withArrow
                  >
                    {route.title}
                  </MenuItem>
                );
              })}
            </div>
          )}

          <div className="menu-dropdown__column">
            {secondLevel && secondLevel.children && secondLevel.children.map((route) => {
              const withCheckmark: boolean = selected && selected.length === 3 &&
                selected[0] === firstLevel.key &&
                selected[1] === secondLevel.key &&
                selected[2] === route.key
              ;

              const path = [firstLevel.key, secondLevel.key, route.key];

              return (
                <MenuItem
                  key={route.key}
                  onClick={() => handleItemClick(route, route.key, path)}
                  withCheckmark={withCheckmark}
                  disabled={route.disabled}
                >
                  {route.title}
                </MenuItem>
              );
            })}
          </div>
        </div>
      </div>

      {withOverlay && (
        <div
          className={cx('menu-overlay', { _visible: dropdownVisible }, overlayClassName)}
          style={overlayStyle}
          onClick={handleClickOverlay}
        />
      )}
    </>
  );
};

export default React.memo(Menu);
