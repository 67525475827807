import React from 'react';
import formatLongNumbers from '~/utils/formatLongNumbers';
import pluralize from '~/utils/pluralize';

const convertStatusToString = (v: TMailingStatus) =>
  ({
    STARTED: 'Запущена',
    STOPPED: 'Не запущена',
    LOADING_CATALOG: 'Не запущена',
    LOADING_CATALOG_ERROR: 'Не запущена',
    CATALOG_LOADED: 'Не запущена',
  }[v]);

const convertStatusToLabelStatus = (v: TMailingStatus) =>
  ({
    STARTED: 'success',
    STOPPED: 'error',
    LOADING_CATALOG: 'error',
    LOADING_CATALOG_ERROR: 'error',
    CATALOG_LOADED: 'error',
  }[v]);

const convertCatalogStatusToString = (v: TMailingStatus) =>
  ({
    STARTED: 'Каталог: проверен',
    STOPPED: 'Каталог: не проверен',
    LOADING_CATALOG: 'Каталог: на проверке',
    LOADING_CATALOG_ERROR: 'Каталог: ошибка',
    CATALOG_LOADED: 'Каталог: проверен',
  }[v]);

const convertCatalogStatusToLabelStatus = (v: TMailingStatus) =>
  ({
    STARTED: 'default',
    STOPPED: 'error',
    LOADING_CATALOG: 'warning',
    LOADING_CATALOG_ERROR: 'error',
    CATALOG_LOADED: 'default',
  }[v]);

const convertEmailStatusToString = (v: EmailStatus) =>
  ({
    approved: 'Email: подтвержден',
    unapproved: 'Email: не подтвержден',
  }[v]);

const convertEmailStatusToLabelStatus = (v: EmailStatus) =>
  ({
    approved: 'default',
    unapproved: 'error',
  }[v]);

const convertStatisticTypeToString = (v: MailingStatisticsType) =>
  ({
    EmailDistributionTemplateWithStatistic: 'Тип: брошенная корзина',
  }[v]);

const getLabels = (
  v: IMailingTemplateWithStatistic,
  showMessageEmailNotRegisterMessage: (id: number) => void
) => {
  const labels = [];
  if (v.status) {
    labels.push({
      name: convertStatusToString(v.status),
      status: convertStatusToLabelStatus(v.status) as TFloatTableRowLabelStatus,
    });

    labels.push({
      name: convertCatalogStatusToString(v.status),
      status: convertCatalogStatusToLabelStatus(
        v.status
      ) as TFloatTableRowLabelStatus,
    });
  }

  if (v.template?.senderEmailAddress) {
    const emailLabel = v.template?.senderEmailAddress?.registered
      ? 'approved'
      : 'unapproved';

    labels.push({
      name: convertEmailStatusToString(emailLabel),
      status: convertEmailStatusToLabelStatus(
        emailLabel
      ) as TFloatTableRowLabelStatus,
      onClick: () => {
        if (emailLabel === 'unapproved' && v?.template?.id) {
          showMessageEmailNotRegisterMessage(v.template.id);
        }
      },
    });
  }

  labels.push({
    name: convertStatisticTypeToString(v['@type']),
    status: 'default' as TFloatTableRowLabelStatus,
  });

  return labels;
};

const getData = (v: IMailingTemplateWithStatistic) => {
  const data = [];

  const formatValue = (
    percent: number = 0,
    count: number = 0
  ): {
    subHeadData: string;
    headData: string;
  } => {
    return count === 0 || count === null
      ? {
        subHeadData: '',
        headData: '—',
      }
      : {
        subHeadData: percent === null ? '' : `${percent}%`,
        headData: formatLongNumbers(count),
      };
  };

  if (v.statistic?.sent) {
    data.push({
      icon: 'Telegram',
      label: 'Отправлено',
      labelNotification:
        'Количество писем, отправленных посетителям вашего сайта',
      ...formatValue(v.statistic.sent.percent, v.statistic.sent.count),
    });
  }

  if (v.statistic?.delivered) {
    data.push({
      icon: 'Check',
      label: 'Доставлено',
      labelNotification: (
        <>
          Количество писем, которые были доставлены посетителям вашего сайта{' '}
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(
        v.statistic?.delivered.percent,
        v.statistic?.delivered.count
      ),
      backgroundColor: '#F5F7FF',
    });
  }

  if (v.statistic?.read) {
    data.push({
      icon: 'CheckedDouble',
      label: 'Прочитано',
      labelNotification: (
        <>
          Количество писем, которые были прочитаны посетителями вашего сайта
          <br />
          Процент считается от доставленных писем
        </>
      ),
      ...formatValue(v.statistic?.read.percent, v.statistic?.read.count),
    });
  }

  if (v.statistic?.followedLink) {
    data.push({
      icon: 'MouseArrowWithLoader',
      label: 'Переходы',
      labelNotification: (
        <>
          Количество переходов, которые посетители вашего сайта производили по
          ссылкам из писем
          <br />
          Процент считается от прочитанных писем
        </>
      ),
      ...formatValue(
        v.statistic?.followedLink.percent,
        v.statistic?.followedLink.count
      ),
      backgroundColor: '#E4F5E5',
    });
  }

  if (v.statistic?.buyCount) {
    data.push({
      icon: 'ShoppingCart',
      label: 'Выкуплено корзин',
      labelNotification: (
        <>
          Количество корзин, выкупленных при переходе из рассылки.
          Процент считается от числа переходов из письма в корзину.
        </>
      ),
      ...formatValue(
        v.statistic?.buyCount.percent,
        v.statistic?.buyCount.count
      ),
    });
  }

  if (v.statistic?.buySum) {
    data.push({
      icon: 'Ruble',
      label: 'Продажи, руб',
      labelNotification: (
        <>Сумма по всем выкупленным корзинам при переходе из рассылки</>
      ),
      ...formatValue(null, v.statistic?.buySum.count),
      backgroundColor: '#F5F7FF',
    });
  }

  if (v.statistic?.error) {
    data.push({
      icon: 'Alert',
      label: 'Ошибки',
      labelNotification: (
        <>
          Количество писем, по которым происходили ошибки в доставке писем
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(v.statistic?.error?.percent, v.statistic?.error?.count),
    });
  }

  if (v.statistic?.unsubscribed) {
    data.push({
      icon: 'Stop',
      label: 'Отписки',
      labelNotification: (
        <>
          Столько раз посетитель вашего сайта отписался от получения писем по
          ссылке из письма
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(
        v.statistic?.unsubscribed?.percent,
        v.statistic?.unsubscribed?.count
      ),
      backgroundColor: '#F5F7FF',
    });
  }

  return data;
};

export const convertMailingStatisticToFloatTableData = (
  data: IMailingTemplateWithStatistic[],
  showMessageEmailNotRegisterMessage: (id: number) => void
): IFloatTableRow[] => {
  return data
    .map((v: IMailingTemplateWithStatistic) => {
      const labels = getLabels(v, showMessageEmailNotRegisterMessage);
      const data = getData(v);

      return {
        title: v?.template?.name || '',
        id: v?.template?.id,
        labels,
        data,
      };
    })
    .sort((a, b) => a.id - b.id);
};

export const getMaxLengthMessage = (maxLength: number): string =>
  `Не больше ${maxLength} ${pluralize(maxLength, 'символа', 'символов', 'символов')}`;
