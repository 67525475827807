import { observer } from 'mobx-react';
import React from 'react';
import style from './style.module.css';

interface IWheelDesktopProps {
  wheelString: string;
}

const WheelDesktop: React.FC<IWheelDesktopProps> = observer(
  ({
    wheelString,
  }) => {

    return (
      <>
        <img src="/assets/wheel/desktop- device.png" alt="" />
        <div
          className={style.desktopWrap}
        >
          <div
            className={style.desktopSecondWrap}
            dangerouslySetInnerHTML={{ __html: wheelString }}
          />
        </div>
      </>
    );
  }
);

export default WheelDesktop;
