import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SwitchUI } from '~/components/SwitchUI';
import Button from '~/components/ui/Button';
import Checkbox from '~/components/ui/CheckBox';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import TextArea from '~/components/ui/TextArea';
import { MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET } from '~/constants';
import LabelsBlock from '~/screens/FeedLiteScreen/components/FeedConstructor/StepFeedProduct/FeedConstuctorAddProduct/LabelsBlock';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import { getItems } from '~/utils/getItems';
import useStores from '~/utils/useStores';
import { checkOnHttp } from '~/utils/validationUtils/checkOnHttp';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';
import './style.css';

const FeedConstuctorAddProduct: React.FC<{
  picture: string;
  setPicture: (picture: string) => void;
}> = observer(({ picture, setPicture }) => {
  const { feedLiteStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
  };
  const {
    getProduct,
    getLength,
    isOpenDrawen,
    updateProduct,
    createFeed,
    setIsOpenDrawen,
    computedPosition,
    feedProcessId,
    isCrossedPrice = false,
    setIsCrossedPrice,
    currentFeed,
    getProducts,
  } = feedLiteStore;

  const { register, handleSubmit, reset, errors, getValues, control } = useForm(
    {
      mode: 'onChange',
    }
  );

  const [currentLabelsInProduct, setCurrentLabelsInProduct] = useState<
    string[]
  >(getProduct?.get('labels') ?? []);

  useEffect(() => {
    if (isOpenDrawen.create || isOpenDrawen.update) {
      setCurrentLabelsInProduct(getProduct ? getProduct?.get('labels') : []);
    }
    reset({ ...(getProduct && getProduct.copy()), image: null });
    
    if (getProduct && getProduct.get('crossedPrice')) {
      setIsCrossedPrice(true);
    } else {
      setIsCrossedPrice(false);
    }
    
    if (isOpenDrawen.create) {
      reset({ order: computedPosition });
    }
  }, [isOpenDrawen]);

  const {
    order,
    offerId,
    name,
    description,
    urlLink,
    price,
    crossedPrice,
  } = getValues();

  const onSubmit = (data: IProduct) => {
    const updateProductDate: IProduct = {
      ...data,
      labels: currentLabelsInProduct ?? [],
    };
    if (isOpenDrawen.create) {
      createFeed(updateProductDate, feedProcessId, picture);
    } else if (isOpenDrawen.update) {
      updateProduct(updateProductDate, picture);
    }
    setPicture(null);
    reset();
    const hasCrossedPriceInProducts = getProducts?.find(
      product => product.get('crossedPrice')
    ) ? true : false;
    const currentConfig = currentFeed.get('configuration');
    currentFeed.update({ configuration: {
      ...currentConfig,
      hasCardCrossedPrice: hasCrossedPriceInProducts || !!data.crossedPrice,
    }});
    setIsOpenDrawen({ create: false, update: false });
  };

  const validateFileSize = (value: any) => {
    return value[0] && value[0].size <= MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET;
  };

  const validateFileType = (value: any) => {
    if (value?.length === 0 && hideImage()) return true; 
    const regex = new RegExp('(.*?).(jpg|png|jpeg)$');
    if (regex.test(value[0]?.name.toLowerCase())) return true;

    return false;
  };

  const handleChangeImage = (event: any) => {
    const files = event.target.files;
    if (files && validateFileSize(files) && validateFileType(files)) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onloadend = async () => {
        setPicture(reader.result as string);
      };
    }
  };

  const validateLink = (value: string) => {
    return !(
      !isValidUrl(value) ||
      !checkOnHttp(value) ||
      value.includes(' ')
    );
  };

  const validatePrice = (price: string) => {
    const regex = new RegExp('^([0-9]+([.][0-9]{1,2})?)$');

    return price === '' ? true : regex.test(price);
  };

  const hideImage = () => {
    if (isOpenDrawen.update && !picture && getProduct) {
      return getProduct.get('imgLink');
    }

    return picture;
  };

  return (
    <div className="add-product__wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-product__form-items">
          <FormItem
            title={'Позиция в ленте'}
            message={errors.order && 'Необходимо ввести позицию'}
            failure={errors?.order}
            className="add-product__position-field"
            hint={
              'Номер, каким по счету отображается этот товар в ленте (максимальное количество - 30)'
            }
          >
            {isOpenDrawen.create && (
              <Input
                ref={register({ required: true })}
                name="order"
                type="number"
                placeholder={'Позиция в ленте'}
                block
                failure={Boolean(errors.order)}
                success={order && !errors.order}
                onChange={() => {
                  return;
                }}
                disabled
              />
            )}
            {isOpenDrawen.update && (
              <Controller
                control={control}
                name="order"
                render={({ value, onChange }) => {
                  return (
                    <InputDropdown
                      selectedKey={String(value)}
                      className="feed-editor-filling__dropdown"
                      onChange={onChange}
                      items={getItems(getLength)}
                    />
                  );
                }}
              />
            )}
          </FormItem>

          <FormItem
            title={'Название товара'}
            message={
              (!errors.name && 'Не больше 40 символов') ||
              (errors.name.type === 'required' &&
                'Необходимо ввести название товара') ||
              (errors.name.type === 'maxLength' &&
                'Вы ввели больше 40 символов')
            }
            failure={errors.name}
            className="add-product__product-name-field"
          >
            <Input
              ref={register({ required: true, maxLength: 40 })}
              name="name"
              type="text"
              placeholder={'Название товара'}
              block
              failure={Boolean(errors.name)}
              success={name && !errors.name}
              onChange={() => {
                return;
              }}
            />
          </FormItem>

          <FormItem
            title={'Идентификатор товара'}
            message={
              (!errors.offerId &&
                `Идентификатор товара в вашей системе 
                (Он необходим на шаге встраивания скриптов)`) ||
              (errors.offerId.type === 'maxLength' &&
                'Вы ввели больше 75 символов')
            }
            failure={errors.offerId}
            className="add-product__product-name-field"
            hint={'Индивидуальный идентификатор товара'}
          >
            <Input
              ref={register({ maxLength: 75 })}
              name="offerId"
              type="text"
              placeholder={'Введите идентификатор'}
              block
              failure={Boolean(errors.offerId)}
              success={offerId && !errors.offerId}
              onChange={() => {
                return;
              }}
            />
          </FormItem>

          <FormItem
            title={'Описание товара'}
            message={
              (!errors.description && 'Не более 75 символов') ||
              (errors.description.type === 'maxLength' &&
                'Вы ввели больше 75 символов')
            }
            failure={errors.description}
          >
            <TextArea
              ref={register({ maxLength: 75 })}
              name="description"
              placeholder={'Описание товара'}
              failure={!!errors.description}
              success={description && !errors.description}
              block
              rows={5}
            />
          </FormItem>

          <FormItem
            title="Изображение"
            message={
              (!errors.image &&
                `Изображение должно быть формата PNG, JPG или JPEG.
                    Вес изображения – не больше 500 Кб.`) ||
              (errors.image?.type === 'required' && !picture &&
                'Необходимо добавить изображение') ||
              (errors.image?.type === 'validateFileSize' &&
                'К сожалению, мы не можем загрузить изображение, вес которого больше 500 Кб') ||
              (errors.image?.type === 'validateFileType' &&
                'Выбранный формат изображения не подходит. Подходящие форматы: png, jpg, jpeg')
            }
            failure={errors.image}
          >
            <div className="add-product__picture">
              <img src={hideImage()} />
            </div>
            <Button type="grey" block style={{ height: 32 }}>
              Выбрать изображение
              <label onChange={handleChangeImage} htmlFor="formId">
                <input
                  id="formId"
                  type="file"
                  name="image"
                  ref={register({
                    required: isOpenDrawen.update === true ? false : true,
                    validate: {
                      validateFileSize,
                      validateFileType,
                    },
                  })}
                  accept=".png, .jpg, .jpeg"
                  hidden
                />
              </label>
            </Button>
          </FormItem>

          <FormItem
            title={'Ссылка на товар'}
            message={
              (errors.urlLink?.type === 'required' &&
                'Необходимо ввести ссылку на товар') ||
              (errors.urlLink?.type === 'validateLink' && 'Неверный формат URL')
            }
            failure={errors.urlLink}
            hint={
              'Ссылка на товар должна быть полной - включая "http://" или "https://"'
            }
          >
            <Input
              ref={register({
                required: true,
                validate: {
                  validateLink,
                },
              })}
              name="urlLink"
              placeholder={'Ссылка на товар'}
              block
              failure={Boolean(errors.urlLink)}
              success={urlLink && !errors.urlLink}
            />
          </FormItem>
          <FormItem
            title={'Текущая цена в рублях'}
            message={
              (errors.price?.type === 'maxLength' &&
                'Можно ввести максимально 13 символов') ||
              (errors.price?.type === 'validatePrice' && 'Неверный формат цены')
            }
            failure={errors.price}
          >
            <Input
              ref={register({
                maxLength: 13,
                validate: {
                  validatePrice,
                },
              })}
              name="price"
              placeholder={`${'9 900.00'.toLocaleString()} ₽`}
              block
              failure={Boolean(errors.price)}
              success={price && !errors.price}
            />
          </FormItem>

          <Checkbox
            label="Старая цена при наличии"
            size="small"
            className="add-product__discount-price"
            checked={isCrossedPrice}
            onCheck={(check) => {
              setIsCrossedPrice(check);
            }}
            hint={'Укажите стоимость товара без скидки, если на него действует акция или цена по карте'}
          />
          {isCrossedPrice ? (
            <FormItem
              title={'Цена в рублях'}
              message={
                (!errors.crossedPrice &&
                  `Если у вас есть на этот товар скидка, укажите старую цену `) ||
                (errors.crossedPrice?.type === 'maxLength' &&
                  'Можно ввести максимально 13 символов') ||
                (errors.crossedPrice?.type === 'validatePrice' &&
                  'Неверный формат цены')
              }
              failure={errors.crossedPrice}
            >
              <Input
                ref={register({
                  maxLength: 13,
                  validate: {
                    validatePrice,
                  },
                })}
                name="crossedPrice"
                placeholder={`${'9 900.00'.toLocaleString()} ₽`}
                block
                failure={Boolean(errors.crossedPrice)}
                success={crossedPrice && !errors.crossedPrice}
              />
            </FormItem>
          ) : (
            ''
          )}

          <SwitchUI elementCode={'FeedLiteLabels'}>
            <LabelsBlock
              currentLabelsInProduct={currentLabelsInProduct}
              setCurrentLabelsInProduct={setCurrentLabelsInProduct}
            />
          </SwitchUI>
        </div>

        {/* TODO: Исправить вёрстку */}
        <div style={{ height: 70 }} />
        <DrawerFooter
          className="add-product-drawer-footer__container"
          actions={(
            <Button
              className="add-product__drawer-next-button"
              type="main"
              tag="button"
              htmlType="submit"
              disabled={!!errors.image && !picture}
            >
              {isOpenDrawen.create ? 'Добавить' : 'Сохранить'}
            </Button>
          )}
        />
      </form>
    </div>
  );
});

export default FeedConstuctorAddProduct;
