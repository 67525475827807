import { IFeedConfiguration } from 'feed';
import React, { useEffect, useRef, useState } from 'react';
import DemonstrationInfoPopup from '~/components/generic/StepSelectorAndDesign/Workspace/DemonstrationInfoPopup';
import Desktop from '~/components/generic/StepSelectorAndDesign/Workspace/Frame/Desktop';
import Mobile from '~/components/generic/StepSelectorAndDesign/Workspace/Frame/Mobile';
import {
  computeDesctopFrameSize,
  computeMobileFrameSize,
} from '~/components/generic/StepSelectorAndDesign/Workspace/Frame/computeFrameSize';
import './style.css';

interface IInitEmbeddingFeed {
  type: 'initEmbeddingFeed';
  platform: Device;
  feedId: string | number;
  uuid: string;
  configuration: IFeedConfiguration;
  reqRec?: 'off' | 'temp';
}

interface IFrame {
  device: Device;
  urlPattern: string;
  contentHeight: string;
  selector: string;
  selectorMobile: string;
  isRechoosingSelector: boolean;
  id: string | number;
  feedConfiguration: IFeedConfiguration;
  frameRef: React.MutableRefObject<HTMLIFrameElement>;
  mobileFrameRef: React.MutableRefObject<HTMLIFrameElement>;
  currentStep: EditorSteps;
  showPrototipFeedInFrame: (configiration: IFeedConfiguration) => void;
  uuid: string;
  feedMode: FeedMode;
  reqRec?: 'off' | 'temp';
}

const Frame: React.FC<IFrame> = ({
  device,
  urlPattern,
  contentHeight,
  selector,
  selectorMobile,
  isRechoosingSelector,
  id,
  feedConfiguration,
  frameRef,
  mobileFrameRef,
  currentStep,
  showPrototipFeedInFrame,
  uuid,
  feedMode,
  reqRec = 'off',
}) => {
  const frameContainer = useRef<HTMLDivElement>();
  const mobileContainerRef = useRef<HTMLDivElement>();

  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    setIsResizing(true);
    if (device === 'desktop') {
      computeDesctopFrameSize(frameContainer, frameRef);
    }
    if (device === 'mobile') {
      computeMobileFrameSize(
        frameContainer,
        mobileContainerRef,
        mobileFrameRef
      );
    }
    setIsResizing(false);
  }, [contentHeight, device]);

  useEffect(() => {
    const frame =
      device === 'desktop' ? frameRef.current : mobileFrameRef.current;

    const startEmbeddingAndShowFeed = () => {
      if (!selector && !selectorMobile) {
        const initEmbeddingFeed: IInitEmbeddingFeed = {
          type: 'initEmbeddingFeed',
          platform: device,
          feedId: id,
          uuid,
          configuration: JSON.parse(JSON.stringify(feedConfiguration)),
          reqRec,
        };
        frame.contentWindow.postMessage(initEmbeddingFeed, '*');
      }
      showPrototipFeedInFrame(feedConfiguration);
    };

    frame.onerror = () => {
      console.log('frame.onerror');
    };

    frame.onload = () => {
      startEmbeddingAndShowFeed();
    };

    if (isRechoosingSelector) {
      startEmbeddingAndShowFeed();
    }
  }, [isRechoosingSelector, device]);

  return (
    <div
      ref={frameContainer}
      className="feed-editor-step-selector-device-workspace-frame__container"
    >
      {device === 'desktop' && (
        <Desktop
          frameRef={frameRef}
          isResizing={isResizing}
          urlPattern={urlPattern}
        />
      )}
      {device === 'mobile' && (
        <Mobile
          mobileContainerRef={mobileContainerRef}
          mobileFrameRef={mobileFrameRef}
          isResizing={isResizing}
          urlPattern={urlPattern}
          currentStep={currentStep}
        />
      )}
      {currentStep === 'StepDesign' && feedMode === 'pro' && <DemonstrationInfoPopup />}
    </div>
  );
};

export default Frame;
