import 'antd/dist/antd.css';
import '~/styles/global.css';

import { inject, observer } from 'mobx-react';
import 'moment/locale/ru';
import React, { Component } from 'react';
import {
  withRouter,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch
} from 'react-router-dom';
import ErrorBoundary from '~/components/ErrorBoundary';
import { preloadAll } from '~/components/Icon';
import {
  loginUrl,
  mobileScreenWasShownConst,
  recoveryUrl,
  registerUrl,
  setPasswordUrl,
  vipAuthUrl, widgetsUrl,
  yandexMetrikaId
} from '~/constants';
import AuthLayout from '~/layouts/AuthLayout';
import MainLayout from '~/layouts/MainLayout';
import __AppLayout from '~/layouts/__deprecated/AppLayout';
import ApiScreen from '~/screens/ApiScreen';
import { AttendanceScreen } from '~/screens/Auth/AttendanceScreen';
import AuthTechScreen from '~/screens/Auth/AuthTechScreen';
import SbbIDLoginTechScreen from '~/screens/Auth/AuthTechScreen/SbbIDLoginTechScreen';
import { DomainScreen } from '~/screens/Auth/DomainScreen';
import LoginScreen from '~/screens/Auth/LoginScreen';
import { PaymentScreen } from '~/screens/Auth/PaymentScreen';
import RegisterScreen from '~/screens/Auth/RegisterScreen';
import SetPasswordScreen from '~/screens/Auth/SetPasswordScreen';
import SwitchLoginScreen from '~/screens/Auth/SwitchLoginScreen';
import BusinessRulesScreen from '~/screens/BusinessRulesScreen';
import DeadZoneScreen from '~/screens/DeadZoneScreen';
import FeedConstructorScreen from '~/screens/FeedLiteScreen';
import FeedScreen from '~/screens/FeedProScreen';
import GuideScreen from '~/screens/GuideScreen';
import GuideScreenCMS from '~/screens/GuideScreenCMS';
import GuideScreenForDeveloper from '~/screens/GuideScreenForDeveloper';
import GuideScreenForHimself from '~/screens/GuideScreenForHimself';
import GuideScreenGTM from '~/screens/GuideScreenGTM';
import GuideScreenHTML from '~/screens/GuideScreenHTML';
import HelpScreen from '~/screens/HelpScreen';
import InstallGuideCMSScreen from '~/screens/InstallGuideCMSScreen';
import InstallGuideDeveloperScreen from '~/screens/InstallGuideDeveloperScreen';
import InstallGuideGTMScreen from '~/screens/InstallGuideGTMScreen';
import InstallGuideHTMLScreen from '~/screens/InstallGuideHTMLScreen';
import LeadsScreen from '~/screens/LeadsScreen';
import LeadsSettingsScreen from '~/screens/LeadsSettingsScreen';
import LoadingScreen from '~/screens/LoadingScreen';
import MailingScreen from '~/screens/MailingScreen';
import MockEmailLinkResult from '~/screens/MailingScreen/components/MockEmailLinkResult';
import MailingUnsubscribe from '~/screens/MailingUnsubscribe';
import MassMailingScreen from '~/screens/MassMailingScreen';
import MockMassMailLinkResultScreen from '~/screens/MassMailingScreen/components/MockMassMailLinkResultScreen';
import { MobileScreen } from '~/screens/MobileScreen';
import ProfileScreen from '~/screens/ProfileScreen';
import { QuizLeadsScreen } from '~/screens/QuizLeadsScreen';
import { QuizScreen } from '~/screens/QuizScreen';
import RedirectToChangePasswordScreen from '~/screens/Redirect/RedirectToChangePasswordScreen';
import RedirectToDomainScreen from '~/screens/Redirect/RedirectToDomainScreen';
import RedirectToLoginScreen from '~/screens/Redirect/RedirectToLoginScreen';
import RedirectToMobileScreen from '~/screens/Redirect/RedirectToMobileScreen';
import RedirectToPaymentScreen from '~/screens/Redirect/RedirectToPaymentScreen';
import RedirectToTariffScreen from '~/screens/Redirect/RedirectToTariffScreen';
import SbbIDLoginScreen from '~/screens/SbbIDLoginScreen';
import ScenariosScreen from '~/screens/ScenariosScreen';
import SettingsScreen from '~/screens/SettingsScreen';
import VipLoginScreen from '~/screens/VipLoginScreen';
import { WheelOfFortune } from '~/screens/WheelOfFortune';
import __ChangePasswordScreen from '~/screens/__deprecated/ChangePasswordScreen';
import __EditRoleScreen from '~/screens/__deprecated/EditRoleScreen';
import __EditUserScreen from '~/screens/__deprecated/EditUserScreen';
import __InstallationManualScreen from '~/screens/__deprecated/InstallationManualScreen';
import __LoadingErrorScreen from '~/screens/__deprecated/LoadingErrorScreen';
import __RolesScreen from '~/screens/__deprecated/RolesScreen';
import __UsersScreen from '~/screens/__deprecated/UsersScreen';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import { AppStore } from '~/stores/appStore';
import { AuthStore } from '~/stores/authStore';
import { checkPathname } from '~/utils/checkPathname';
import { isMobile } from '~/utils/isMobile';
import { isSemiRegistrationPath } from '~/utils/isRegistrationPath';
import { getFromLocaleStorage } from '~/utils/localStorageUtils/getFromLocaleStorage';

interface ExpandedRouteProps extends RouteProps {
  headerChild?: React.ReactNode;
}

const AppRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={matchProps => (
      <ErrorBoundary>
        <__AppLayout>
          <Component {...matchProps} />
        </__AppLayout>
      </ErrorBoundary>
    )}
  />
);

const AuthRoute = ({ component: Component, headerChild, ...rest }: ExpandedRouteProps) => (
  <Route
    {...rest}
    render={matchProps => (
      <ErrorBoundary>
        <AuthLayout headerChild={headerChild}>
          <Component {...matchProps} />
        </AuthLayout>
      </ErrorBoundary>
    )}
  />
);

const EmptyRoute = ({ component: Component, headerChild, ...rest }: ExpandedRouteProps) => (
  <Route
    {...rest}
    render={matchProps => (
      <Component {...matchProps} />
    )}
  />
);

const MainRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={matchProps => (
      <ErrorBoundary>
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      </ErrorBoundary>
    )}
  />
);

type Props = RouteComponentProps & {
  appStore?: AppStore;
  authStore?: AuthStore;
  uiSwitchStore?: UiSwitchStore;
};


@inject('appStore', 'authStore', 'uiSwitchStore')
@observer
class App extends Component<Props> {

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {

    const prevPathname = prevProps.location.pathname;
    const currentPathname = location.pathname;

    if (prevPathname !== currentPathname) {
      // @ts-ignore
      if (ym) {
        const url = location.href;
        const options = {
          params: {},
          referer: location.origin + prevPathname,
          title: document.title,
        };
        // @ts-ignore
        ym(yandexMetrikaId, 'hit', url, options);
      }
    }
  }

  async componentDidMount() {

    if (this.withoutRequests()) return;

    const { authStore, uiSwitchStore } = this.props;

    preloadAll();

    await uiSwitchStore.fetchCodes();

    try {
      await authStore.check();
    } catch (e) {}
  }

  withoutRequests = () => location?.pathname?.includes('/unsubscribe') || location?.pathname.includes('/mockEmailLinkResult');

  checkStatus = (
    status: TClientState,
    component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>
  ): any => {
    const pathsCondition = !checkPathname('/domain')  &&
      !checkPathname('/tariff') &&
    !checkPathname('/payment');

    if (!pathsCondition) {

      return component;
    }

    if (status === 'TARIFF_NOT_SELECTED') {

      return RedirectToTariffScreen;
    }

    if (status === 'DOMAIN_NOT_ADDED') {
      return RedirectToDomainScreen;
    }

    if (status === 'CARD_NOT_ATTACHED') {

      return RedirectToPaymentScreen;
    }

    const isMobileScreenShow = isMobile() &&
      !getFromLocaleStorage(mobileScreenWasShownConst) &&
      !isSemiRegistrationPath();

    if (isMobileScreenShow) {
      return RedirectToMobileScreen;
    }

    return component;
  }

  checkAuth = (
    component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>
  ) => {
    const { authStore } = this.props;


    if (!authStore.isAuthorized) {
      return RedirectToLoginScreen;
    }

    if (authStore.clientState !== 'READY') {
      return this.checkStatus(authStore.clientState, component);
    }


    return component;
  }

  checkPassword = (
    component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>
  ) => {
    const { authStore } = this.props;

    if (authStore.shouldChangePassword) {
      return RedirectToChangePasswordScreen;
    }

    return component;
  }

  render() {
    const { authStore } = this.props;
    const auth = this.checkAuth;
    const passw = this.checkPassword;
    if (authStore.cantFetchStaticData) {
      return <__LoadingErrorScreen />;
    }

    if (!authStore.isLoaded && !this.withoutRequests()) {
      return <LoadingScreen />;
    }

    return (
      <Switch>
        <AuthRoute path={loginUrl} exact component={passw(LoginScreen)} />
        <AuthRoute path={registerUrl} exact component={passw(RegisterScreen)} />
        <AuthRoute path={recoveryUrl} exact component={passw(RegisterScreen)} />
        <AuthRoute path={setPasswordUrl} exact component={passw(SetPasswordScreen)} />
        <AuthRoute path={vipAuthUrl} exact component={passw(VipLoginScreen)} />
        <AuthRoute path="/auth" exact component={passw(SwitchLoginScreen)} />
        <AuthRoute path="/auth-tech" exact component={passw(AuthTechScreen)} />
        <AuthRoute path="/register" exact component={passw(SwitchLoginScreen)} />
        <AuthRoute path="/payment/:status" exact component={auth(PaymentScreen)} />
        <AuthRoute path="/auth/sbbid" exact component={SbbIDLoginScreen} />
        <AuthRoute path="/auth/sbbid/tech" exact component={SbbIDLoginTechScreen} />
        <AuthRoute path="/auth/sbbid/ios" exact component={SbbIDLoginScreen} />
        <AuthRoute path="/tariff" exact component={auth(AttendanceScreen)} />
        <AuthRoute path="/domain" exact component={auth(DomainScreen)} />
        <AuthRoute path="/mobile" exact component={passw(MobileScreen)} />
        <AuthRoute path="/register_mobile" exact component={passw(MobileScreen)} />

        <MainRoute path={widgetsUrl} exact component={auth(ScenariosScreen)} />
        <MainRoute path="/feedback-widgets" exact component={auth(ScenariosScreen)} />
        <MainRoute path="/wheel-of-fortune" exact component={auth(WheelOfFortune)} />
        <MainRoute path="/quiz" exact component={auth(QuizScreen)} />
        <MainRoute path="/quiz/statistic/:type/:quizId" exact component={auth(QuizScreen)} />
        <MainRoute path="/quiz/statistic/:type/:quizId/:id" exact component={auth(QuizScreen)} />
        <MainRoute path="/quiz-leads" exact component={auth(QuizLeadsScreen)} />
        <MainRoute path="/leads" exact component={auth(LeadsScreen)} />
        <MainRoute path="/leads-settings" exact component={auth(LeadsSettingsScreen)} />
        <MainRoute path="/feed" exact component={auth(FeedScreen)} />
        <MainRoute path="/business-rules" exact component={auth(BusinessRulesScreen)} />
        <MainRoute path="/mailing" exact component={auth(MailingScreen)} />
        <AuthRoute path="/mockEmailLinkResult" exact component={MockEmailLinkResult} />
        <MainRoute path="/mass-mailing" exact component={auth(MassMailingScreen)} />
        <AuthRoute path="/mockMassMailLinkResult" exact component={MockMassMailLinkResultScreen} />
        <MainRoute path="/feed-constructor" exact component={auth(FeedConstructorScreen)} />
        <MainRoute path="/api" exact component={auth(ApiScreen)} />
        <MainRoute path="/blindzone" exact component={auth(DeadZoneScreen)} />
        <MainRoute path="/profile" exact component={auth(ProfileScreen)} />
        <MainRoute path="/settings" exact component={auth(SettingsScreen)} />
        <MainRoute path="/help" exact component={auth(HelpScreen)} />

        <AuthRoute path="/guide" exact component={auth(GuideScreen)} />
        <AuthRoute path="/guide/developer" exact component={auth(GuideScreenForDeveloper)} />
        <AuthRoute path="/guide/html" exact component={auth(InstallGuideHTMLScreen)} />
        <AuthRoute path="/guide/cms" exact component={auth(InstallGuideCMSScreen)} />
        <AuthRoute path="/guide/gtm" exact component={auth(InstallGuideGTMScreen)} />
        <AuthRoute path="/guide/himself" exact component={auth(GuideScreenForHimself)} />
        <AuthRoute path="/guide/himself/cms" exact component={auth(GuideScreenCMS)} />
        <AuthRoute path="/guide/himself/html" exact component={auth(GuideScreenHTML)} />
        <AuthRoute path="/guide/himself/gtm" exact component={auth(GuideScreenGTM)} />
        <AuthRoute path="/guide/developer-instructions" exact component={InstallGuideDeveloperScreen} />

        <EmptyRoute path="/unsubscribe" exact component={MailingUnsubscribe} />

        {/* TODO: Природа настолько очистилась, что следующие экраны оказались ненужными */}
        {/* <MainRoute path="/instructor" exact component={auth(InstructorScreen)} /> */}
        {/* <MainRoute path="/partners" component={auth(PartnersScreen)} /> */}
        <AppRoute path="/setup" exact component={auth(__InstallationManualScreen)} />
        <AppRoute path="/users" exact component={auth(__UsersScreen)} />
        <AppRoute path="/users/create" exact component={auth(__EditUserScreen)} />
        <AppRoute path="/users/:id" exact component={auth(__EditUserScreen)} />
        <AppRoute path="/roles" exact component={auth(__RolesScreen)} />
        <AppRoute path="/roles/create" exact component={auth(__EditRoleScreen)} />
        <AppRoute path="/roles/:id" exact component={auth(__EditRoleScreen)} />
        <AuthRoute path="/auth/password" exact component={__ChangePasswordScreen} />
        {/* <MainRoute path="/telescope" exact component={auth(Telescope)} /> */}
      </Switch>
    );
  }
}

export default withRouter(App);
