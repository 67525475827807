import { action, observable } from 'mobx';
import Profile, { TConsentToMarketing } from '~/api/profile';
import Store from '~/stores/store';

export class ProfileStore extends Store {
  @observable step = 0;

  constructor() {
    super();
  }

  @action
  changeStep = (step: number) => {
    this.step = step;
  }

  sendUserAgreements = async (data: TConsentToMarketing) => {
    try {
      await Profile.setUserAgreements({
        '@type': 'UserAgreements',
        'data': data,
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  closeContract = async () => {
    try {
      await Profile.closeContract();

      this.step = 2;
    } catch (e) {
      throw e;
    }
  }

}

export default new ProfileStore();
