import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';

export const GetAside: React.FC = observer(() => {
  const { constructorStore, saveAndRun, mailingInstance } = massMailingStore;
  const { currentStep, isWaitingResponse } = constructorStore;

  const isShowButtonSaveAndRun = currentStep === 'TabConstructor';
  const isDisabledButtonSaveAndRun = !mailingInstance?.get('id') ||
    !mailingInstance?.get('authorizationEmail')?.registered;

  return (
    <>
      {isShowButtonSaveAndRun && (
        <Button
          disabled={isDisabledButtonSaveAndRun}
          onClick={saveAndRun}
          loading={isWaitingResponse}
        >
          Сохранить и запустить
        </Button>
      )}
    </>
  );
});
