import React from 'react';
import { NotificationProps } from '~/components/Notification';
import BorderComponent from '~/components/notification-editor/BorderComponent';
import BorderRadiusSelector from '~/components/notification-editor/BorderRadiusSelector';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FontSettings from '~/components/notification-editor/FontSettings';
import NotificationWidth from '~/components/notification-editor/RadioGroupWrapper/NotificationWidth';
import EyeToggle from '~/components/ui/EyeToggle';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import '../style.css';

interface IButtonEditor {
  notificationParams: NotificationProps;
  onChangeNotificationParams: (params: NotificationProps) => void;
}

/**
 *
 * @param {NotificationProps} notificationParams Параметры виджета
 * @param {function} onChangeNotificationParams Функция изменения параметров виджета.
 *
 * @return
 */
export const ButtonEditor: React.FC<IButtonEditor> = ({
  notificationParams,
  onChangeNotificationParams,
}: IButtonEditor): JSX.Element => {
  const buttonSizes: {
    key: INotificaationButtonSize;
    value: string;
  }[] = [
    {
      key: 'small',
      value: 'Маленькая',
    },
    {
      key: 'medium',
      value: 'Средняя',
    },
    {
      key: 'large',
      value: 'Большая',
    },
  ];

  return (
    <>
      <section className="scenario-editor-sidebar__section">
        <FormItem
          title="Текст на кнопке"
          hint="Здесь вы можете написать свой текст, который будет отображаться на кнопке виджета. Макет слева отображает, как будет выглядеть ваша надпись на кнопке."
          noReservedPlaceForMessage
        >
          <Input
            value={notificationParams.buttonText}
            onChangeValue={(buttonText) => {
              onChangeNotificationParams({ ...notificationParams, buttonText });
            }}
            placeholder="Показать промокод"
            maxLength={40}
            block
          />
        </FormItem>
      </section>
      {!notificationParams.hasPrivacyPolicy && (
        <section className="scenario-editor-sidebar__section">
          <FormItem
            title="Ссылка"
            noReservedPlaceForMessage
            hint="Здесь вы можете указать ссылку, на которую произойдет переход при нажатии на кнопку в виджете (ссылка на страницу должна быть полной, включая http в начале). Ссылку можно не указывать, тогда перехода не произойдет. Так как изменения не касаются внешнего вида, в макете слева ничего не поменяется."
          >
            <Input
              placeholder="https://example.ru/promo-offer"
              value={notificationParams.buttonHREF !== 'null' ? notificationParams.buttonHREF : ''}
              onChangeValue={(buttonHREF) => {
                onChangeNotificationParams({
                  ...notificationParams,
                  buttonHREF,
                });
              }}
              block
            />
          </FormItem>
        </section>
      )}
      <section className="scenario-editor-sidebar__section">
        <FontSettings
          isMobile={notificationParams.isMobile}
          type="button"
          data={{
            alpha: notificationParams.buttonTextOpacity * 100,
            color: notificationParams.buttonTextColor,
            fontFamily: notificationParams.buttonFontFamily,
            fontSize: notificationParams.buttonFontSize,
            fontWeight: notificationParams.buttonFontWeight,
            isItalic: notificationParams.buttonFontItalic,
            isLineThrough: notificationParams.buttonTextLineThrough,
            isUnderline: notificationParams.buttonTextUnderline,
          }}
          onChange={(data) => {
            const props: Partial<INotification> = {
              buttonTextOpacity: data.alpha / 100,
              buttonTextColor: data.color,
              buttonFontFamily: data.fontFamily,
              buttonFontSize: data.fontSize as INotificationButtonFontSize,
              buttonFontWeight: data.fontWeight,
              buttonFontItalic: data.isItalic,
              buttonTextLineThrough: data.isLineThrough,
              buttonTextUnderline: data.isUnderline,
            };

            onChangeNotificationParams({
              ...notificationParams,
              ...props,
            });
          }}
          hint={(
            <div>
              <p>
                Тут вы можете выбрать шрифт надписи из выпадающего окна. Макет
                слева отображает как будет выглядеть ваша надпись на кнопке.
              </p>
              <p>
                Здесь вы можете выбрать жирность надписи из выпадающего окна
                (слева) и поменять размер надписи(справа). Макет слева
                отображает как будет выглядеть ваша надпись на кнопке.
              </p>
              <p>
                Укажите HEX-кодировку цвета или выберите его на палитре. В
                процентах укажите непрозрачность цвета (значение должно быть
                кратно 10-ти). Макет слева отображает, как будет выглядеть виджет
                в зависимости от выбранных значений.
              </p>
              <p>
                Тут вы можете дополнительно обработать надпись на кнопке:
                оставить текст прямым, курсив, без зачеркивания и подчеркивания,
                надпись зачеркивается, надпись подчеркивается. Для этого нужно
                нажать на соответствующий значок. Макет слева отображает как
                будет выглядеть ваша надпись на кнопке.
              </p>
            </div>
          )}
        />
      </section>
      <section className="scenario-editor-sidebar__section">
        <FormItem
          title="Размер кнопки"
          hint="Здесь вы выбираете высоту кнопки. Макет слева отображает, как будет выглядеть кнопка на виджете в выбранном размере."
          noReservedPlaceForMessage
        >
          <InputDropdown
            size="default"
            items={buttonSizes}
            selectedKey={notificationParams.buttonSize}
            onChange={(buttonSize) => {
              onChangeNotificationParams({ ...notificationParams, buttonSize });
            }}
          />
        </FormItem>
      </section>
      {!notificationParams.isMobile && (
        <section className="scenario-editor-sidebar__section">
          <NotificationWidth
            fitContent={!notificationParams.isButtonBlockWidth}
            onChange={(fitContent) => {
              onChangeNotificationParams({
                ...notificationParams,
                isButtonBlockWidth: !fitContent,
              });
            }}
            hint="Здесь вы выбираете длину кнопки. По размеру текста - кнопка будет чуть длиннее, чем текст на ней. По ширине блока - длина кнопки будет чуть меньше длины виджета без учета размера текста на ней. Макет слева отображает, как будет выглядеть кнопка на виджете в выбранном размере."
          />
        </section>
      )}
      <section className="scenario-editor-sidebar__section">
        <FormItem
          title="Цвет кнопки"
          hint="Укажите HEX-кодировку цвета или выберите его на палитре. В процентах укажите непрозрачность цвета (значение должно быть кратно 10-ти). Макет слева отображает, как будет выглядеть виджет в зависимости от выбранных значений."
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <EyeToggle
            selected={notificationParams.buttonWithBackgroundColor}
            onSelect={(buttonWithBackgroundColor) => {
              onChangeNotificationParams({
                ...notificationParams,
                buttonWithBackgroundColor,
              });
            }}
          >
            <ColorPicker
              color={notificationParams.buttonBackgroundColor}
              alpha={Math.round(notificationParams.buttonOpacity * 100)}
              onChange={({ alpha, color }) => {
                onChangeNotificationParams({
                  ...notificationParams,
                  buttonBackgroundColor: color,
                  buttonOpacity: Math.round(alpha) / 100,
                });
              }}
            />
          </EyeToggle>
        </FormItem>
      </section>
      <section className="scenario-editor-sidebar__section">
        <BorderComponent
          limit={8}
          borderWidth={notificationParams.buttonBorderWidth}
          onBorderWidthChange={(buttonBorderWidth) => {
            onChangeNotificationParams({
              ...notificationParams,
              buttonBorderWidth,
            });
          }}
          colorPickerProps={{
            alpha: notificationParams.buttonBorderOpacity * 100,
            color: notificationParams.buttonBorderColor,
            onChange: ({ alpha, color }) => {
              onChangeNotificationParams({
                ...notificationParams,
                buttonBorderColor: color,
                buttonBorderOpacity: alpha / 100,
              });
            },
          }}
          enabled={notificationParams.buttonWithBorder}
          onChangeState={(buttonWithBorder) => {
            onChangeNotificationParams({
              ...notificationParams,
              buttonWithBorder,
            });
          }}
          hint={() => {
            return (
              <>
                В этом блоке вы можете:
                <ul>
                  <li>
                    поменять толщину линии вокруг кнопки (можно поставить
                    значения от 0 до 8)
                  </li>
                  <li>
                    выбрать цвет линии вокруг кнопки и ее НЕпрозрачность в
                    процентах. Если вы не знаете обозначение желаемого цвета,
                    нажмите на квадрат с цветом. В открывшемся окне первой
                    строкой выберите нужный вам цветовой спектр и выберите цвет
                    на палитре выше (палитра выше соответствует выбранному
                    спектру), второй строкой выберите нужный вам процент
                    непрозрачности линии вокруг кнопки (можно устанавливать
                    только значения кратные 10-ти).
                  </li>
                  <li>
                    Скрыть/показать линию вокруг кнопки нажав на значок глаза{' '}
                  </li>
                </ul>
                Макет слева отображает, как будет выглядеть кнопка на виджете.
              </>
            );
          }}
        />
      </section>

      <section className="scenario-editor-sidebar__section">
        <BorderRadiusSelector
          hint="Здесь вы можете поменять степень скругления углов кнопки. Значение вводится в окно ниже (не больше 32-ух, доступны только целые числа). Если скругление углов будет равно нулю, то углы у кнопки будут квадратные. Макет слева отображает, как будет выглядеть кнопка на виджете."
          borderRadius={notificationParams.buttonBorderRadius}
          onChangeValue={(buttonBorderRadius) => {
            onChangeNotificationParams({
              ...notificationParams,
              buttonBorderRadius,
            });
          }}
        />
      </section>
    </>
  );
};
