import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { WrapperContentDrawer } from '~/components/generic/WrapperContentDrawer';
import Button from '~/components/ui/Button';
import Tabs from '~/components/ui/Menu/Tabs';
import Message from '~/components/ui/Message';
import ScenarioModel from '~/models/ScenarioModel';
import { validateSectorsProbability } from '~/screens/WheelOfFortune/const';
import { getItems } from '~/screens/WheelOfFortune/data';
import {
  menuItems,
  WheelOfFortuneStore,
} from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import { ChanceToWin } from './ChanceToWin';
import { GeneralView } from './GeneralView';
import { Gifts } from './Gifts';
import { Pattern } from './Pattern';
import './style.css';

interface IWheelConstructor {
  setIsErrorMessageVisible: (v: boolean) => void;
  setIsScriptErrorMessageVisible: (v: boolean) => void;
  setIsSuccessMessageVisible: (v: boolean) => void;
}

export const WheelConstructor = observer(
  ({
    setIsErrorMessageVisible,
    setIsScriptErrorMessageVisible,
    setIsSuccessMessageVisible,
  }: IWheelConstructor) => {
    const { wheelOfFortuneStore } = useStores() as {
      wheelOfFortuneStore: WheelOfFortuneStore;
    };

    const { stepsStore, setIsConstructorOpen, hasAllGiftNameFilled } =
      wheelOfFortuneStore;

    const {
      currentStep,
      currentStepIndex,
      changeCurrentStep,
      changeCurrentStepNextOrBack,
    } = stepsStore;

    const hideWheelConstructor = () => setIsConstructorOpen(false);

    const handleChangeStep = (step: WheelEditorSteps) => {
      changeCurrentStep(step);
    };

    const selectedTemplate = wheelOfFortuneStore.selectedTemplate;
    const selectedWheel = selectedTemplate?.params;
    const extraHTMLConfiguration = selectedWheel?.extraHTMLConfiguration;
    wheelOfFortuneStore.inputGifts = extraHTMLConfiguration?.sectors;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [isProbabilityValid, setIsProbabilityValid] = useState<boolean>(true);
    const [isClosePopupShown, setIsClosePopupShown] = useState<boolean>(false);

    const isEditing = !!wheelOfFortuneStore.selectedId;

    useEffect(() => {
      const { selectedTemplate } = wheelOfFortuneStore;
      const title = selectedTemplate?.params.title.value;
      const message = selectedTemplate?.params.message.value;
      const buttonText = selectedTemplate?.params.buttonText.value;

      wheelOfFortune.setTitle(title);
      wheelOfFortune.setButtonText(buttonText);
      wheelOfFortune.setMessage(message);
    }, [selectedTemplate?.params]);

    const { wheelOfFortune } = wheelOfFortuneStore;

    const title = wheelOfFortune.get('title');
    const buttonText = wheelOfFortune.get('buttonText');
    const isStepGeneralViewValid = !!(title && buttonText);

    return (
      <div className={'wheel_constructor'}>
        <div className={'wheel_constructor__wrapper'}>
          <div className={'wheel_constructor__header'}>
            <Button
              onClick={() => setIsClosePopupShown(true)}
              type="default"
              icon={'Close'}
              style={{ position: 'absolute' }}
            >
              Закрыть
            </Button>
            <div className={'wheel_constructor__menu'}>
              <Tabs
                className={'wheel_constructor__tabs'}
                items={getItems({
                  currentStep,
                  isEditing,
                  hasAllGiftNameFilled,
                  isStepGeneralViewValid,
                })}
                value={currentStep.toString()}
                onChange={handleChangeStep}
              />
            </div>
          </div>
          <WrapperContentDrawer>
            <div className={'wheel_constructor__content'}>
              {currentStep === 'StepWheelPatterns' && <Pattern />}
              {currentStep === 'StepGifts' && <Gifts />}
              {currentStep === 'StepGeneralView' && <GeneralView />}
              {currentStep === 'StepChanceToWin' && (
                <ChanceToWin setIsButtonDisabled={setIsButtonDisabled} />
              )}
            </div>
          </WrapperContentDrawer>
          {currentStep !== 'StepWheelPatterns' && (
            <div className={'wheel_constructor__footer'}>
              <Button
                onClick={() => changeCurrentStepNextOrBack(-1)}
                type="grey"
              >
                Назад
              </Button>
              <Button
                onClick={async () => {
                  if (currentStep !== 'StepChanceToWin') {
                    changeCurrentStepNextOrBack(+1);
                  } else {
                    setIsButtonDisabled(true);
                    setIsButtonLoading(true);

                    const sectors =
                      wheelOfFortuneStore.wheelOfFortune.get('sectors');

                    if (!validateSectorsProbability(sectors)) {
                      setIsProbabilityValid(false);
                      setIsButtonDisabled(false);
                      setIsButtonLoading(false);

                      return;
                    }

                    const savedId = await wheelOfFortuneStore.save();

                    if (!savedId) {
                      setIsButtonDisabled(false);
                      setIsButtonLoading(false);

                      return;
                    }

                    let scenario: ScenarioModel;
                    await wheelOfFortuneStore.fetchScenarios();

                    scenario = wheelOfFortuneStore.scenarios.filter(
                      (v: ScenarioModel) => v.get('id') === savedId
                    )[0];

                    await scenario.activate().then(() => {
                      if (scenario.errorWhenChangingStatus) {
                        setIsErrorMessageVisible(true);
                      } else if (scenario.errorWhenCheckScriptException) {
                        setIsScriptErrorMessageVisible(true);
                      } else {
                        !wheelOfFortuneStore.selectedId &&
                          setIsSuccessMessageVisible(true);
                      }
                    });
                    await wheelOfFortuneStore.fetchScenarios();
                    setIsButtonDisabled(false);
                    setIsButtonLoading(false);
                    wheelOfFortuneStore.setIsConstructorOpen(false);
                  }
                }}
                style={{ width: 480 }}
                disabled={hasAllGiftNameFilled || isButtonDisabled}
                loading={isButtonLoading}
              >
                {currentStep !== 'StepChanceToWin'
                  ? `Продолжить настройку: Шаг ${
                      menuItems[currentStepIndex + 1].title
                    }`
                  : 'Сохранить и запустить'}
              </Button>
            </div>
          )}
        </div>
        {!isProbabilityValid && (
          <Message
            visible={!isProbabilityValid}
            title={'Ой! Процент выигрыша не совпадает'}
            textTop={
              'Необходимо настроить вероятности для каждого подарка. В сумме все подарки должны составлять 100%'
            }
            applyText="Все, понятно"
            showLine={false}
            onApply={() => setIsProbabilityValid(true)}
            onClose={() => setIsProbabilityValid(true)}
          />
        )}
        {isClosePopupShown && (
          <Message
            visible={isClosePopupShown}
            title={
              wheelOfFortuneStore.selectedId
                ? 'У вас остались несохраненные изменения'
                : 'Прекратить создание колеса фортуны?'
            }
            textTop={
              'При продолжении операции изменения и данные не сохранятся'
            }
            applyText={
              wheelOfFortuneStore.selectedId ? 'Не сохранять' : `Прекратить`
            }
            cancelText={'Отмена'}
            showLine={false}
            onClose={() => setIsClosePopupShown(false)}
            onApply={hideWheelConstructor}
          />
        )}
      </div>
    );
  }
);
