import { Table as AntTable } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import cx from 'classnames';
import React, { useCallback } from 'react';
import parentWithTheClass from '~/utils/parentWithTheClass';
import './style.css';

interface TableElementsProps {
  children: React.ReactChildren;
  key?: string;
  className?: string;
  colSpan?: number;
  onClick?: (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  secondColumnFixed?: boolean;
}

interface Props<T> {
  columns: ColumnProps<T>[];
  rows: T[];
  rowKey?: (record: T, index?: number) => string;
  rowClassName?: (record: T, index: number, indent: number) => string;
  rowHover?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onRowClick?: (record: T, el: HTMLElement) => void;
  tableLayout?: 'fixed' | 'auto';
  onMouseEnterRow?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key?: string) => void;
  onMouseLeaveRow?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key?: string) => void;
  secondColumnFixed?: boolean;
  idInsteadOfKey?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

const renderHeaderWrapper = ({ children }: TableElementsProps) => (
  <thead className="table-ant__thead">{children}</thead>
);

const renderBodyWrapper = ({ children }: TableElementsProps) => (
  <tbody className="table-ant__tbody">{children}</tbody>
);

const renderBodyRow = ({ onMouseEnter, onMouseLeave, className, key, children, ...rest }: TableElementsProps) => {

  return (
  <tr
    // @ts-ignore
    onMouseEnter={onMouseEnter ? event => onMouseEnter(event, rest['data-row-key']) : undefined}
    onMouseLeave={onMouseLeave}
    className={cx('table-ant__row', className)}
    key={key}
  >
    {children}
  </tr>
  );
};

const renderHeaderCell = ({ children, className = '', onClick, style, secondColumnFixed }: TableElementsProps) => {
  const isSticky = secondColumnFixed && style.position === 'sticky' && className.includes('feed-screen__table-fixed-cell');

  return (
    <th
      onClick={onClick}
      className={cx('table-ant__th', className)}
      style={isSticky ? {
        ...style,
        paddingLeft: '24px',
      } : style}
    >
      {children}
    </th>
  );
};

const renderBodyCell = ({ children, className = '', colSpan, style, secondColumnFixed }: TableElementsProps) => {
  const isSticky = secondColumnFixed && style.position === 'sticky' && className.includes('feed-screen__table-fixed-cell');

  return (
    <td
      className={cx('table-ant__td', className)}
      colSpan={colSpan}
      style={isSticky ? {
        ...style,
        paddingLeft: '24px',
      } : style}
    >
      <div className="table-ant__cell">{children}</div>
    </td>
  );
};

const Table = React.forwardRef(<T extends object>(props: Props<T>, ref: React.Ref<HTMLDivElement>) => {
  const {
    columns,
    rows,
    rowClassName,
    rowKey,
    rowHover,
    style,
    className,
    onRowClick,
    tableLayout,
    onMouseEnterRow,
    onMouseLeaveRow,
    secondColumnFixed,
    idInsteadOfKey,
  } = props;

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {

    if (!onRowClick) return;

    const defineElement = event.target as Element;
    const indexOfThClassName = defineElement.className.split(' ').indexOf('table-ant__th');

    if (indexOfThClassName === -1) {
      const el = parentWithTheClass(event.target as Node, 'table-ant__row');
      const classname = el.className.split(' ').find(name => name.startsWith('_id-'));
      const key = classname?.replace('_id-', '');

      // @ts-ignores
      const record = rows.find(r => (idInsteadOfKey ? r.id.toString() : r.key) === key);

      onRowClick(record, el);

    } else {

      return;
    }
  }, [rows, onRowClick]);

  return (
    <div
      className={cx('table', { '_row-hover': rowHover }, className)}
      style={style}
      onClick={handleClick}
      ref={ref}
    >
      <AntTable
        className="table-ant"
        components={{
          header: {
            wrapper: renderHeaderWrapper,
            cell: (props: TableElementsProps) => renderHeaderCell({ ...props, secondColumnFixed }),
          },
          body: {
            wrapper: renderBodyWrapper,
            row: (props: TableElementsProps) =>
              renderBodyRow({ ...props, onMouseEnter: onMouseEnterRow, onMouseLeave: onMouseLeaveRow }),
            cell: (props: TableElementsProps) => renderBodyCell({ ...props, secondColumnFixed }),
          },
        }}
        columns={columns}
        rowKey={rowKey}
        rowClassName={rowClassName}
        dataSource={rows}
        pagination={false}
        tableLayout={tableLayout}
      />
    </div>
  );
});

export default Table as <T extends object>(props: Props<T>) => JSX.Element;
