import { observer } from 'mobx-react';
import React, { useState } from 'react';
import SetCatalog from '~/components/generic/SetCatalog';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import useStores from '~/utils/useStores';
import { checkOnHttp } from '~/utils/validationUtils/checkOnHttp';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';

const StepCatalog: React.FC = observer(() => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };

  const { feedApi, isInvalidYmlUrl, setIsInvalidYmlUrl, isConfigInvalid } = feedApiStore;

  const [errorMessage, setErrorMessage] = useState<string>('');

  const hasInvalidYmlUrl = (ymlUrl: string): boolean =>
    !isValidUrl(ymlUrl) || !checkOnHttp(ymlUrl) || ymlUrl.includes(' ');

  const setError = () => {
    setIsInvalidYmlUrl(true);
    setErrorMessage('Указанная ссылка некорректна');
  };

  const setSuccess = () => {
    setIsInvalidYmlUrl(false);
    setErrorMessage('');
  };

  const onBlur = () =>
    hasInvalidYmlUrl(feedApi.get('ymlUrl')) ? setError() : setSuccess();

  const onChange = (ymlUrl: string) => {
    feedApi.update({ ymlUrl });
    onBlur();
  };

  return (
    <SetCatalog
      value={feedApi.get('ymlUrl')}
      errorDesc={feedApi.get('errorDesc')}
      onChange={onChange}
      isInvalid={!!errorMessage || isInvalidYmlUrl || isConfigInvalid}
      onBlur={onBlur}
      configLinkError={errorMessage}
      isConfigInvalid={isConfigInvalid}
      notification={'Рекомендации всегда будут актуальны, так как мы автоматически проверяем каталог'}
    />
  );
});

export default StepCatalog;
