import { IPromo } from '~/components/generic/Promo';

export const items = [
  {
    iconSrc: 'DemoMailing',
    text: 'Возможность замотивировать клиента к покупке, даже если он ушел с сайта',
  },
  {
    iconSrc: 'DemoMailing',
    text: 'Быстрая настройка списка получателей',
  },
  {
    iconSrc: 'DemoMailing',
    text: 'Ручной выбор времени отправки письма',
  },
];

export const promoMaterials: Partial<IPromo> = {
  header: 'Ручные Email рекомендации',
  subheader:
    'Вы самостоятельно настраиваете список получателей рассылки и выбираете время отправки письма',
  img: '/assets/images/promo/mass-mailing.png',
  items,
};
