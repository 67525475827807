import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Divider } from '~/components/Divider';
import Loading from '~/components/Loading';
import Button from '~/components/ui/Button';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { PlugByComputer } from '~/screens/LeadsSettingsScreen/telegram/PlugByComputer';
import { PlugByQrCode } from '~/screens/LeadsSettingsScreen/telegram/PlugByQrCode';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';
import './style.css';

export const TelegramSettings = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;
  const isTelegramSet = leadSettings.get('isTelegramSet');
  const isTelegramLoading = leadSettings.get('isTelegramLoading');
  const telegramError = leadSettings.get('telegramError');
  const telegramUrl = leadSettings.get('telegramUrl');

  useEffect(() => {

    let interval: NodeJS.Timeout;

    leadsStore.leadSettings.getTelegramIntegrationState();

    if (!isTelegramSet && !isTelegramLoading) {
      interval = setInterval(() => {
        leadsStore.leadSettings.getTelegramIntegrationState();
      }, 10000);
    }

    return () => interval && clearInterval(interval);

  }, [isTelegramSet]);

  const isSuccessShow = isTelegramSet && !isTelegramLoading && !telegramError;
  const isPlugFormShow = !isTelegramSet && !isTelegramLoading && !telegramError;

  return (
    <div className="leads-settings__telegram">
      {isTelegramLoading && <Loading status={'Loading'} onClick={() => null} />}
      {telegramError && (
        <Loading status={'Error'} onClick={() => window.location.reload()} />
      )}
      {isPlugFormShow && (
        <>
          <h3 className="leads-settings__description">
            Для того, чтобы получать заявки в мессенджер Telegram, выполните
            подключение удобным способом
          </h3>
          <PlugByComputer link={telegramUrl} />
          <Divider className="leads-settings__divider" />
          {telegramUrl && <PlugByQrCode link={telegramUrl} />}
        </>
      )}
      {isSuccessShow && (
        <SettingsItem
          title="Ура! Теперь вы можете получать заявки с виджетов прямо в Telegram!"
          Content={(
            <Button
              className="tgm-settings__content-btn"
              type="grey"
              onClick={() => leadSettings.disableTelegram()}
              block
            >
              Отвязать аккаунт Telegram от уведомлений
            </Button>
          )}
          isSuccess
        />
      )}
    </div>
  );
});
