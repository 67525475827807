import cx from 'classnames';
import React from 'react';
import './style.css';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  full?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const Toolbar: React.FC<Props> = ({
  style,
  className,
  left,
  right,
  full,
}) => (
  <div
    className={cx('toolbar', {
      _full: !!full,
      _left: !!left && !!right === false,
      _right: !!right && !!left === false,
      _both: !!right && !!left,
    }, className)}
    style={style}
  >
    {left && <div className="toolbar__col">{left}</div>}
    {right && <div className="toolbar__col">{right}</div>}
    {full && <div className="toolbar__col">{full}</div>}
  </div>
);

export default Toolbar;
