import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import FeedInfo from '~/components/generic/FeedInfo';
import { EFeedType } from '~/components/generic/FeedInfo/types';
import TableMessages from '~/components/generic/FeedStatisticTable/TableMessages';
import VideoMessage from '~/components/ui/VideoMessage';
import { EmptyScreen } from '~/screens/FeedLiteScreen/components/EmptyScreen';
import FeedConstructor from '~/screens/FeedLiteScreen/components/FeedConstructor';
import ListCreatedFeed from '~/screens/FeedLiteScreen/components/ListCreatedFeed';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import LoadingScreen from '~/screens/LoadingScreen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import './style.css';

const FeedConstructorScreen: React.FC = observer(() => {
  const { feedLiteStore, authStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
    authStore: AuthStore;
  };
  const [isCreateFeedVideoShown, setIsCreateFeedVideoShown] =
    useState<boolean>(false);

  const {
    isConstructorLiteOpen,
    setIsConstructorLiteOpen,
    fetchLiteStatistics,
    isFetchingLiteStatistics,
    fetchingLiteStatisticsError,
    feedLiteStatistics,
    isInfoOpen,
    setIsInfoOpen,
    onClickActionButton,
    feedLiteCurrentStatistic,
    isStoppingDialogOpen,
    setIsStoppingDialogOpen,
    isShowWaitMessage,
    setIsShowWaitMessage,
    isRemoveDialogOpen,
    setIsRemoveDialogOpen,
    isShowExceedingLimitMessage,
    setIsShowExceedingLimitMessage,
    isBeforeEditOpen,
    isFetchingRequest,
    feed,
    isMessageBeforeExitingOpen,
    setIsMessageBeforeExitingOpen,
    setIsBeforeEditOpen,
    isOpenRenameDialog,
    setIsOpenRenameDialog,
    renameFeed,
    isOpenFinalDialog,
    removeUnfinishedFeedLite,
  } = feedLiteStore;

  useEffect(() => {
    fetchLiteStatistics();

    return () => feedLiteStore.destroy();
  }, []);

  if (isFetchingLiteStatistics) {
    return <LoadingScreen />;
  }

  const loadingStatus = () => {
    if (isFetchingLiteStatistics) return 'Loading';
    if (fetchingLiteStatisticsError) return 'Error';

    return 'Success';
  };

  const loadedStatus = loadingStatus();
  const hasWrites = authStore.currentUser.hasRightTo('VIEW_FEEDS_LITE');
  const showFirstEntry =
    hasWrites && feedLiteStatistics && feedLiteStatistics.length === 0;
  const showListCreatedFeed =
    hasWrites &&
    feedLiteStatistics &&
    feedLiteStatistics.length > 0 &&
    loadedStatus === 'Success';

  return (
    <>
      {hasWrites && (
        <Loading status={loadedStatus} onClick={fetchLiteStatistics} />
      )}
      {(!hasWrites || showFirstEntry) && (
        <EmptyScreen setIsCreateFeedVideoShown={setIsCreateFeedVideoShown} />
      )}
      {isConstructorLiteOpen && (
        <FeedConstructor
          setIsCreateFeedVideoShown={setIsCreateFeedVideoShown}
        />
      )}
      {showListCreatedFeed && <ListCreatedFeed />}
      {isCreateFeedVideoShown && (
        <VideoMessage
          visible={isCreateFeedVideoShown}
          title={'Как создать ленту рекомендаций?'}
          onClose={() => setIsCreateFeedVideoShown(false)}
          onExit={() => setIsCreateFeedVideoShown(false)}
          videoSrc="https://www.youtube.com/embed/GbVIFtvbIU8"
        />
      )}
      <FeedInfo
        isInfoDrawerOpen={isInfoOpen}
        setIsInfoDrawerOpen={setIsInfoOpen}
        onClickActionButton={onClickActionButton}
        feedStatistic={feedLiteCurrentStatistic}
        feedType={EFeedType.LITE}
      />
      <TableMessages
        isStoppingDialogOpen={isStoppingDialogOpen}
        setIsStoppingDialogOpen={setIsStoppingDialogOpen}
        onClickActionButton={onClickActionButton}
        isChanging={!!feed?.get('id')}
        isMessageBeforeExitingOpen={isMessageBeforeExitingOpen}
        setIsMessageBeforeExitingOpen={setIsMessageBeforeExitingOpen}
        setIsConstructorOpen={setIsConstructorLiteOpen}
        isShowWaitMessage={isShowWaitMessage}
        setIsShowWaitMessage={setIsShowWaitMessage}
        isRemoveDialogOpen={isRemoveDialogOpen}
        setIsRemoveDialogOpen={setIsRemoveDialogOpen}
        feedStatisticsActiveRow={feedLiteCurrentStatistic as FeedProStatistics}
        isShowExceedingLimitMessage={isShowExceedingLimitMessage}
        setIsShowExceedingLimitMessage={setIsShowExceedingLimitMessage}
        isBeforeEditOpen={isBeforeEditOpen}
        isFetchingRequest={isFetchingRequest}
        setIsBeforeEditOpen={setIsBeforeEditOpen}
        isOpenRenameDialog={isOpenRenameDialog}
        setIsOpenRenameDialog={setIsOpenRenameDialog}
        renameFeed={renameFeed}
        isOpenFinalDialog={isOpenFinalDialog}
        setCloseFeedConstuctor={removeUnfinishedFeedLite}
      />
    </>
  );
});

export default FeedConstructorScreen;
