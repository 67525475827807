import { action, computed, observable } from 'mobx';
import Scenarios from '~/api/scenarios';
import Model from '~/models/Model';
import ScenarioTemplateModel from '~/models/ScenarioTemplateModel';

export default class ScenarioModel extends Model<IScenario> {
  @observable isRemoving = false;
  @observable template: ScenarioTemplateModel;
  @observable errorWhenChangingStatus: boolean = false;
  @observable errorWhenCheckScriptException: boolean = false;

  get isPausing() {
    return this.get('isPausing') || false;
  }

  get isActivating() {
    return this.get('isActivating') || false;
  }

  @action
  getTemplate = async () => {
    this.template?.fetch();
  }

  @action
  remove = async () => {
    this.isRemoving = true;

    try {
      await Scenarios.remove(this.get('id'));
      this.update({ deleted: true });
    } catch (e) {
    } finally {
      this.isRemoving = false;
    }
  }

  @action
  pause = async () => {
    if (this.isPausing) return;
    this.update({ isPausing: true });
    try {
      await Scenarios.pause(this.get('id')).then((response) => {
        if (response && response?.data?.errorData?.errorFlag === true) {
          this.setErrorWhenChangingStatus(true);
          
          return;
        }
        this.update({ active: false });
        this.setErrorWhenChangingStatus(false);   
      });
    } catch (e) {
      this.setErrorWhenChangingStatus(true);
    } finally {
      this.update({ isPausing: false });
    }
  }

  @action
  activate = async () => {
    if (this.isActivating) return;
    this.update({ isActivating: true });
    try {
      await Scenarios.play(this.get('id')).then(() => {
        this.update({ active: true }); 
        this.setErrorWhenChangingStatus(false);
        this.setErrorWhenCheckScriptException(false);
      });  
    } catch (e) {
      e.response?.data?.errorData?.errorType === 'CheckScriptException' 
        ? this.setErrorWhenCheckScriptException(true)
        : this.setErrorWhenChangingStatus(true);
    } finally {
      this.update({ isActivating: false });
    }
  }

  @action
  setErrorWhenChangingStatus = (status: boolean): void => {
    this.errorWhenChangingStatus = status;
  }

  @action
  setErrorWhenCheckScriptException = (status: boolean): void => {
    this.errorWhenCheckScriptException = status;
  }

  @computed
  get isPaused() {
    return !this.get('active');
  }

  @computed
  get isActive() {
    return this.get('active');
  }

  @computed
  get key() {
    return this.get('id').toString();
  }
}
