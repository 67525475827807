export const getYmlIconColor = (ymlCatalogStatus: string) => {
  switch (ymlCatalogStatus) {
    case 'UPLOAD':
      return '#00BF6D';
    case 'YML_ERROR':
    case 'NOT_UPLOAD':
      return '#EF546B';
    case 'VERIFY':
      return '#C89909';
    default:
      return '#000000';
  }
};
