import React from 'react';
import './style.css';

const DemonstrationInfoPopup: React.FC = () => {
  return (
    <div className="feed-editor-noiframe__info-popup">
      Это пример ленты. В ленте на вашем сайте будут товары из вашего каталога
    </div>
  );
};

export default DemonstrationInfoPopup;
