interface IValidatePassword {
  newPassword: string;
  newPasswordRepeat: string;
}

interface IValidatePasswordResult {
  result: boolean;
  error?: string;
}

export const validatePassword = ({ newPassword, newPasswordRepeat }: IValidatePassword): IValidatePasswordResult => {
  if (!newPassword?.length && !newPasswordRepeat.length) {
    return {
      result: false,
      error: '',
    };
  }

  if (!newPassword?.length) {
    return {
      result: false,
      error: 'Пароль должен быть заполнен.',
    };
  }

  if (newPassword?.length < 12) {
    return {
      result: false,
      error: 'Пароль должен содержать не менее 12 символов',
    };
  }

  if (newPassword?.length > 50) {
    return {
      result: false,
      error: 'Пароль должен содержать не более 50 символов ',
    };
  }

  const errorTextRegexp = 'Пароль должен содержать не менее одной цифры и одной заглавной буквы';

  if (!/\d/.test(newPassword)) {
    return {
      result: false,
      error: errorTextRegexp,
    };
  }

  if (!/[ЁA-ZА-Я]/.test(newPassword)) {
    return {
      result: false,
      error: errorTextRegexp,
    };
  }

  if (!newPasswordRepeat?.length) {
    return {
      result: false,
      error: 'Повторите пароль.',
    };
  }

  if (newPassword !== newPasswordRepeat) {
    return {
      result: false,
      error: 'Пароли должны совпадать.',
    };
  }

  return {
    result: true,
  };
};
