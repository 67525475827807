import { action } from 'mobx';
import Model from '~/models/Model';
import FeedApiApi from '~/screens/ApiScreen/FeedApi/api/FeedApiApi';

export default class FeedApiModel extends Model<FeedApi> {
  @action
  saveFeedApi = async () => {
    try {
      await FeedApiApi.saveFeedApi(this);
    } catch (e) {
      throw e;
    }
  }

  @action
  fixYml = async () => {
    try {
      await FeedApiApi.fixYml(this.get('id'));
    } catch (e) {
      throw e;
    }
  }
}
