import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics/style.module.css';
import { IAnswer } from '~/screens/QuizScreen/stores/quizStore';

export const Answer = ({
  text,
  percent,
  count,
  id,
  isRight,
  checked,
}: IAnswer) => {
  const [answerWidth, setAnswerWidth] = useState(0);
  const [display, setDisplay] = useState('none');

  useEffect(() => {

    if (!percent) return;

    const answerWidthCalculated = percent > 10 ? percent : percent + 10;
    setAnswerWidth(answerWidthCalculated);
    setDisplay('inline');
  }, [percent]);

  const shownPercent = () => {
    return (
      <>
        {percent ? (
          <div
            className={cx(styles.percentScale, styles[`percentScale${id}`])}
            style={{ width: answerWidth / 100 + '%' }}
          >
            <span style={{ display }}>
              {(percent / 100).toFixed(2)} % ({count}){' '}
            </span>
          </div>
        ) : (
          '-'
        )}{' '}
      </>
    );
  };

  return (
    <div className={styles.answer}>
      {typeof percent !== 'undefined' && shownPercent()}
      <div
        className={cx(
          styles.answerText,
          (isRight || checked) && styles.isRight,
          !percent && styles.contactsAnswer
        )}
      >
        {id}. {text || 'Свободный ответ'}
      </div>
    </div>
  );
};
