import { Icons } from '~/components/Icon';

export type Routes =
  | '/widgets'
  | 'group-feedback'
  | '/feedback-widgets'
  | '/leads'
  | '/leads-settings'
  | '/feed-constructor'
  | '/wheel-of-fortune'
  | 'group-feed-pro'
  | '/feed'
  | '/business-rules'
  | 'group-mailing'
  | '/mailing'
  | '/mass-mailing'
  | '/api'
  | '/blindzone'
  | '/instructor'
  | '/partners'
  | '/settings'
  | '/help'
  | 'group-quiz-item'
  | '/quiz'
  | '/quiz-leads';

export interface INavigationRoute {
  type: 'parent' | 'child' | 'separator';
  parent?: Routes;
  route?: Routes;
  icon?: Icons;
  title?: string;
  disabled?: boolean;
  rights?: IRightCode[];
  uiSwitch?: TSwitchCode;
  children?: INavigationRoute[];
}

export const navigationRoutes: INavigationRoute[] = [
  {
    type: 'parent',
    route: '/widgets',
    icon: 'NavWidgets',
    title: 'Виджеты',
  },
  {
    type: 'parent',
    icon: 'Leads',
    title: 'Лиды',
    route: 'group-feedback',
    children: [
      {
        type: 'child',
        route: '/feedback-widgets',
        parent: 'group-feedback',
        title: 'Конструктор',
      },
      {
        type: 'child',
        route: '/leads',
        parent: 'group-feedback',
        title: 'Заявки',
        rights: ['FEEDBACK_WIDGET'],
      },
      {
        type: 'child',
        route: '/leads-settings',
        parent: 'group-feedback',
        title: 'Настройки заявок',
        uiSwitch: 'LeadSettings',
        rights: ['FEEDBACK_WIDGET'],
      },
    ],
  },
  {
    type: 'parent',
    route: '/feed-constructor',
    icon: 'Constructor',
    title: 'Рекомендации Lite',
    uiSwitch: 'FeedLiteScreen',
  },
  {
    type: 'parent',
    route: '/wheel-of-fortune',
    icon: 'Wheel',
    title: 'Колесо фортуны',
    uiSwitch: 'WheelOfFortuneScreen',
  },
  {
    type: 'parent',
    route: 'group-quiz-item',
    icon: 'Quiz',
    title: 'Квизы',
    uiSwitch: 'QuizScreen',
    children: [
      {
        type: 'child',
        parent: 'group-quiz-item',
        route: '/quiz',
        title: 'Конструктор',
      },
      {
        type: 'child',
        parent: 'group-quiz-item',
        route: '/quiz-leads',
        title: 'Заявки',
      },
    ],
  },
  {
    type: 'parent',
    route: 'group-feed-pro',
    icon: 'Target',
    title: 'Рекомендации PRO',
    children: [
      {
        type: 'child',
        route: '/feed',
        parent: 'group-feed-pro',
        title: 'Конструктор',
      },
      {
        type: 'child',
        route: '/business-rules',
        parent: 'group-feed-pro',
        title: 'Бизнес-правила',
        uiSwitch: 'BusinessRules',
        rights: ['VIEW_FEEDS'],
      },
    ],
  },
  {
    type: 'parent',
    route: 'group-mailing',
    icon: 'Subtract',
    title: 'Email рекомендации',
    uiSwitch: 'Mailing',
    children: [
      {
        type: 'child',
        title: 'Автоматические',
        route: '/mailing',
        uiSwitch: 'TriggerMailing',
      },
      {
        type: 'child',
        title: 'Ручные',
        route: '/mass-mailing',
        uiSwitch: 'MassMailing',
      },
    ],
  },
  {
    type: 'parent',
    route: '/api',
    icon: 'Code',
    title: 'API',
    uiSwitch: 'ApiScreen',
  },
  {
    type: 'parent',
    route: '/blindzone',
    icon: 'NavDeadZone',
    title: 'Слепые зоны',
    rights: ['VIEW_BLIND_ZONES'],
  },
  {
    type: 'separator',
  },
 /*  {
    type: 'parent',
    route: '/instructor',
    icon: 'Instructor',
    title: 'Инструктор',
    disabled: false,
    rights: ['VIEW_INSTRUCTOR'],
  },
  {
    type: 'parent',
    route: '/partners',
    icon: 'Partners',
    title: 'Партнеры',
  }, */
  {
    type: 'parent',
    route: '/settings',
    icon: 'NavSettings',
    title: 'Настройки',
    disabled: false,
  },
  {
    type: 'parent',
    route: '/help',
    icon: 'NavHelp',
    title: 'Помощь',
  },
];
