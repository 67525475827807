import { ColumnProps } from 'antd/lib/table';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { SortOrder } from '~/api/leads';
import Icon from '~/components/Icon';
// import CsvDownloader from 'react-csv-downloader';
import PageHeader from '~/components/PageHeader';
import Badge from '~/components/ui/Badge';
import Button from '~/components/ui/Button';
import Pagination from '~/components/ui/Pagination';
import Table from '~/components/ui/Table';
import Tooltip from '~/components/ui/Tooltip';
import Screen from '~/screens/Screen';
import { LeadsStore } from '~/stores/leadsStore';
import { setGtm } from '~/utils/setGtm';
import useStores from '~/utils/useStores';
import LoadingScreen from '../LoadingScreen';
import './style.css';

// type Data = (string[] | {
//   [key: string]: string;
// })[];

const twoLinesValue = (firstLineValue: string, secondLineValue: string) => (
  <div className="two-lines-value" key={firstLineValue + secondLineValue}>
    <span className="first-line-value">{firstLineValue}</span>
    <span className="second-line-value">{secondLineValue}</span>
  </div>
);

const LeadsScreen: React.FC = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };

  const {
    fetchLeads,
    isFetching,
    leadsData,
    removeLead,
    sortDirection,
  } = leadsStore;

  useEffect(() => {
    /**
     * Отправка события enter_to_leads_request для GA
     */
    setGtm('enter_to_leads_request');

    fetchLeads();
  }, []);

  // const generateCSVColumnsFromData = (data: ColumnProps<object>[]) => {
  //   const resultColumnsArray: {
  //     id: string;
  //     displayName: string;
  //   }[] = [];
  //   const columnsNames = ['badge', 'name', 'phone_number', 'email', 'widget', 'date', 'comment', 'delete'];

  //   for (const i in data) {
  //     if (data[i].title) {
  //       resultColumnsArray.push({
  //         id: columnsNames[i],
  //         displayName: data[i].title as string,
  //       });
  //     }
  //   }

  //   return resultColumnsArray;
  // };

  // const generateCSVRowsFromData = () => {
  //   const resultRowsArray: Data = [];

  //   leads.forEach(({ email, name, phoneNumber, comment }) => {
  //     resultRowsArray.push({
  //       name,
  //       phoneNumber,
  //       email,
  //       widget: 'Синяя кнопка',
  //       date: '28.04.2020',
  //       comment,
  //     });
  //   });

  //   return resultRowsArray;
  // };

  if (isFetching) {
    return <LoadingScreen />;
  }

  const columnsData: ColumnProps<ILead>[] = [
    {
      key: 'badge',
      title: '',
      dataIndex: 'isShown',
      render: (value, currentLead) => {
        const renderBadge = () => <Badge value={value ? '0' : currentLead.repeateCount.toString()} />;

        return currentLead.repeateCount > 1 ? (
          <Tooltip
            title="Повторное обращение от этого клиента"
            width="176px"
            placement="bottomLeft"
          >
            <div>
              {renderBadge()}
            </div>
          </Tooltip>
        ) : renderBadge();
      },
    },
    {
      key: 'phoneNumber',
      title: 'Номер телефона',
      dataIndex: 'phone',
      render: phoneNumber => phoneNumber || '—',
    },
    {
      key: 'email',
      title: 'Электронная почта',
      dataIndex: 'email',
      render: email => email || '—',
    },
    {
      key: 'scenario',
      title: 'Виджет',
      dataIndex: 'scenario',
      render: (scenario: ILeadScenario) => twoLinesValue(scenario.name, scenario.description),
    },
    {
      key: 'date',
      dataIndex: 'datetime',
      title: () => (
        <div
          onClick={() => {
            if (Number.isInteger(leadsData?.pageNum)) {
              fetchLeads(leadsData.pageNum, sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
            }
          }}
          className="leads-screen__date-column"
        >
          Дата/время
          <Icon className="leads-screen__date-column-icon" name={sortDirection === SortOrder.ASC ? 'ArrowSUp' : 'ArrowSDown'} />
        </div>
      ),
      render: date => twoLinesValue(moment(date).format('DD.MM.YYYY'), moment(date).format('HH:mm')),
    },
    // {
    //   key: 'comment',
    //   title: 'Комментарий',
    //   dataIndex: 'comment',
    //   render: (comment, row) => (
    //     <Input
    //       onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateComment(row.id, e.target.value)}
    //       onBlur={(value: string) => {
    //         console.log('value', value);
    //         updateComment(row.id, value);
    //       }}
    //       value={comment}
    //     />
    //   ),
    // },
    {
      key: 'delete',
      title: '',
      dataIndex: 'delete',
      render: (_, lead) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            removeLead(lead?.id.toString());
          }}
          type="text"
          tag="button"
        >
          Удалить
        </Button>
      ),
    },
  ];

  return (
    <Screen
      className="leads-screen"
      documentTitle="Заявки"
    >
      <PageHeader className="leads-screen__title" title="Заявки" />
      {/* <div style={{ marginLeft: '40px' }}>
        <Input value={text} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)} />
        <Button onClick={() => filter(text)}>Фильтровать</Button>
        <Button onClick={resetFilter}>Сбросить фильтр</Button>
      </div> */}
      <Table
        className={cx('leads-screen__table', {})}
        columns={columnsData}
        rows={leadsData?.items}
        rowKey={leads => leads.id.toString()}
        rowClassName={lead => lead.isShown ? '' : 'leads-screen__table-row'}
        rowHover
      />
      <footer className="leads-screen__footer">
        <div className="leads-screen__footer-block">
          <Pagination
            current={leadsData?.pageNum}
            total={leadsData?.totalItems}
            pageSize={leadsData?.pageSize}
            totalPages={Math.ceil(leadsData?.totalItems / leadsData?.pageSize)}
            onChange={(page: number) => fetchLeads(page, sortDirection)}
            handleBlur={fetchLeads}
          />
        </div>
        {/* <div className="leads-screen__footer-block">
          <CsvDownloader
            filename="Заявки"
            separator=";"
            wrapColumnChar=""
            columns={generateCSVColumnsFromData(columnsData)}
            datas={generateCSVRowsFromData()}
          >
            <Button type="outline" icon="Download">Скачать все в CSV</Button>
          </CsvDownloader>
        </div> */}
      </footer>
    </Screen>
  );
});

export default LeadsScreen;
