export const ProductItem = () =>
  `<mj-wrapper
    padding="4px 20px"
    background-color="#FFFFFF"
    >
    <mj-section padding="0" border-radius="12px" background-color='#e5e5e5'>
      <mj-group>
        <mj-column padding="12px" width='25%' css-class='mobileItemPhoto'>
          <mj-image width="100px" padding="0" align="left" border-radius='8px' width="100px" src='assets/images/mailing/item.png'></mj-image>
        </mj-column>
        <mj-column padding="12px 0px" width='75%' css-class='mobileItemText'>
        <mj-text
          color="#4d4d4d"
          align='left'
          padding='0px'
          font-family='Inter'
          font-weight='600'
          font-size='16px'
          padding-bottom='4px'
          line-height='24px'
          >
          \{\{item.name\}\}
        </mj-text>
        <mj-text
          color="#808080"
          align='left'
          padding='0px'
          font-family='Inter'
          font-weight='500'
          font-size='16px'
          line-height='24px'
          padding-bottom='12px'
        >
          Количество \{\{item.count\}\}
        </mj-text>
         <mj-text
           color="#4d4d4d"
           align='left'
           padding='0px'
           font-family='Inter'
           font-weight='700'
           font-size='26px'
           line-height='24px'
         >
          \{\{item.price\}\} ₽
        </mj-text>
      </mj-column>
      </mj-group>

     </mj-section>
    </mj-wrapper>`;
