import cx from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import Icon from '~/components/Icon';
import Input from '~/components/ui/Input';
import './style.css';

export interface DropdownItemProps<T> {
  key: T;
  value: React.ReactNode;
}

export interface DropdownProps<T> {
  className?: string;
  items?: DropdownItemProps<T>[];
  size?: FormElementSize;
  onSelect?: (key: T) => void;
  selectedItemKey?: T;
  withoutBorder?: boolean;
}

const Dropdown = <T extends string>({
  className,
  selectedItemKey,
  onSelect,
  withoutBorder,
  size = 'default',
  items = [],
}: DropdownProps<T>) => {
  const overlayRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const showOverlay = () => {
    setVisible(!visible);
  };

  const hideOverlay = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSelect = useCallback((key: T) => {
    onSelect?.(key as T);
    hideOverlay();
  }, [onSelect]);

  useOnClickOutside(overlayRef, hideOverlay);

  const selectedItem = selectedItemKey
    ? items.find(e => e.key === selectedItemKey) || items[0]
    : items[0];

  return (
    <div className={cx('dropdown', className)} ref={overlayRef}>
      <div onClick={showOverlay}>
        <Input
          className="dropdown__preview"
          withoutBorder={withoutBorder}
          size={size}
          block
          elemAfter={<Icon name={visible ? 'ArrowMUp' : 'ArrowMDown'} className="dropdown__preview-icon" />}
          value={selectedItem.value as T}
        />
      </div>
      <div className={
        cx('dropdown__overlay',
        { _hidden: !visible },
        { [`_size-${size}`]: size })
      }>
        {items.map(item => (
          <div
            key={item.key}
            data-key={item.key}
            onClick={() => handleSelect(item.key as T)}
            className={cx('dropdown__overlay-item', { _selected: selectedItemKey === item.key })}
          >
            <div className={'dropdown-overlay-item__name'}>
              {item.value}
            </div>
            <div className={'dropdown__icon-wrapper'}>
              {selectedItemKey === item.key && <Icon name="Check" />}
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default Dropdown;
