import { action, observable } from 'mobx';
import MailingAuthorizationEmailBlockApi from '~/components/generic/MailingAuthorizationEmailBlock/api';
import Store from '~/stores/store';

interface ISendingMessageData {
  isShow: boolean;
  title: string;
  message: string;
}

export class MailingAuthorizationEmailBlockStore extends Store {
  @observable emailsList: IEmailList[] = [];
  @observable sendingMessageData: ISendingMessageData = {
    isShow: false,
    title: '',
    message: '',
  };

  @action
  setEmailsList = (emailSender: IEmailSender[] = []) => {
    this.emailsList = emailSender.map((mailSenderItem: IEmailSender, key: number) => {
      return {
        key,
        value: mailSenderItem.address,
        registered: mailSenderItem.registered,
        status: mailSenderItem.registered ? 'Подтвержден' : 'Не подтвержден',
      };
    });
  }

  @action
  setSendingMessageData = (sendingMessageData: ISendingMessageData) => {
    this.sendingMessageData = sendingMessageData;
  }

  @action
  fetchEmailSenders = async () => {
    try {
      const responseEmailSenders = await MailingAuthorizationEmailBlockApi.getEmailSenders();
      this.setEmailsList(responseEmailSenders.data.payload);
    } catch (error) {
      this.setEmailsList();
    }
  }

  @action
  approveEmail = async (email: string) => {
    try {
      await MailingAuthorizationEmailBlockApi.approveEmail(email);
      this.setSendingMessageData({
        isShow: true,
        title: 'Письмо успешно отправлено',
        message: 'Для подтверждения почты перейдите по ссылке из письма, отправленного на указанный адрес',
      });

      return true;
    } catch (e) {
      this.setSendingMessageData({
        isShow: true,
        title: 'Письмо не отправлено',
        message: e.response?.data?.errorData?.errorText,
      });

      return false;
    }
  }

  @action
  checkEmail = async (email: string) => {
    try {
      const registered = !!(await MailingAuthorizationEmailBlockApi.checkEmail(email))?.data?.payload[0]
        ?.registered;

      return registered;
    } catch (e) {

      return false;
    }
  }

  @action
  isValidateEmail = (email: string = '') => {
    if (
      email.includes('@yandex.ru') ||
      email.includes('@gmail.com') ||
      email.includes('@mail.ru') ||
      !email.includes('@') ||
      !/.+@.+\..+/i.test(email)
    ) {
      return false;
    }
    
    return true;
  }
}

export default new MailingAuthorizationEmailBlockStore();
