import React from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

interface IInstructions {
  type: 'bitrix' | 'sberSRM' | 'macroCRM' | 'amoCRM';
}

const links = {
  bitrix: '/assets/files/leads/PDF_Bitrix24.pdf',
  sberSRM: '/assets/files/leads/PDF_SberCRM.pdf',
  macroCRM: '/assets/files/leads/PDF_MacroCRM.pdf',
  amoCRM: '/assets/files/leads/PDF_AmoCRM.pdf',
};

export const Instructions = ({ type }: IInstructions) => {
  return (
    <div className={styles.instructions}>
      <div>Вы можете скачать подробную инструкцию ниже по кнопке</div>
      <div className={styles.plug}>
        <Icon name={'Doc'} className={styles.icon} />
        <a className={styles.link} href={links[type]} target="_blank" download>Скачать инструкцию по настройке .PDF</a>
      </div>
    </div>
  );
};
