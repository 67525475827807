import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import SendSayMessages from '~/components/generic/SendSayAccountElements/components/SendSayDrawer/SendSayMessages';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import Input from '~/components/ui/Input';
import styles from './style.module.css';

interface ISendSayDrawerProps {
  onSave?: () => void;
}

const SendSayDrawer: React.FC<ISendSayDrawerProps> = observer(
  ({ onSave = null }) => {
    const {
      isSendSayWindowOpen,
      sendSayAccounts,
      saveSendSayAccount,
      setIsSendSayWindowOpen,
      setIsSuccessWindowShow,
    } = SendSayAccountElementsStore;

    const activeAccounts = sendSayAccounts.filter(
      (sendSayAccount: ISendSayAccount) => sendSayAccount.active === true
    );
    const hasWebhook = activeAccounts?.length > 0;

    const initApiLogin = hasWebhook ? activeAccounts[0].apiLogin : '';
    const initApiKey = hasWebhook ? activeAccounts[0].apiKey : '';

    const [apiLogin, setApiLogin] = useState<string>(initApiLogin);
    const [apiKey, setApiKey] = useState<string>(initApiKey);


    useEffect(() => {
      if (isSendSayWindowOpen) {
        setApiLogin(initApiLogin);
        setApiKey(initApiKey);
      }
    }, [isSendSayWindowOpen]);

    const handleClose = () => {
      setIsSendSayWindowOpen(false);
    };

    const save = async () => {
      const isSave = await saveSendSayAccount(apiLogin, apiKey);
      if (isSave) {
        handleClose();
        setIsSuccessWindowShow(true);
        if (onSave) onSave();

        return;
      }
    };

    return (
      <>
        <Drawer
          visible={isSendSayWindowOpen}
          title={'Учетная запись Sendsay'}
          size="x-small"
          onClose={handleClose}
          actions={(
            <div className={styles.actions}>
              <Button
                className={styles.cancel}
                type="text"
                onClick={handleClose}
              >
                Отмена
              </Button>
              <Button className={styles.save} onClick={save} block disabled={!apiLogin || !apiKey}>
                Сохранить
              </Button>
            </div>
          )}
        >
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div className={styles.blockTitle}>
                <span>Введите логин</span>
                <IconWithTooltip
                  tooltipProps={{
                    placement: 'bottomLeft',
                    title:
                      'В качестве названия вы можете использовать любое сочетание букв и цифр, которое поможет вам идентифицировать этого пользователя в общей таблице. Это название видите только вы.',
                    overlayClassName: styles.tooltip,
                  }}
                  iconProps={{
                    name: 'Question',
                  }}
                />
              </div>
              <div className={styles.blockSubtitle}>
                Нужно указать корпоративный логин Sendsay
              </div>
              <Input
                onChangeValue={setApiLogin}
                className={styles.input}
                placeholder={'Ваш логин'}
                value={apiLogin}
              />
            </div>
            <div className={styles.block}>
              <div className={styles.blockTitle}>
                <span>Ключ API</span>
                <IconWithTooltip
                  tooltipProps={{
                    placement: 'bottomLeft',
                    title:
                      'В качестве названия вы можете использовать любое сочетание букв и цифр, которое поможет вам идентифицировать этого пользователя в общей таблице. Это название видите только вы.',
                    overlayClassName: styles.tooltip,
                  }}
                  iconProps={{
                    name: 'Question',
                  }}
                />
              </div>
              <div className={styles.blockSubtitle}>
                Можно найти в разделе настроек пользователя
              </div>
              <Input
                onChangeValue={setApiKey}
                className={styles.input}
                placeholder={'Введите значение'}
                value={apiKey}
              />
            </div>
            <div
              className={cx(
                styles.block,
                styles.infoBlock,
                hasWebhook && styles.attention
              )}
            >
              <div className={styles.infoBlockTitle}>Важно!</div>
              {hasWebhook ? (
                <div className={styles.infoBlockText}>
                  Если изменить учетную запись, то все созданные рассылки
                  останутся в старой учетной записи
                </div>
              ) : (
                <div className={styles.infoBlockText}>
                  Все созданные рассылки будут закреплены за этой учетной
                  записью Sendsay. При изменении учетной записи — рассылки
                  пропадут из личного кабинета СберЛид, а все имейл отправителя
                  станут неподтвержденными
                </div>
              )}
              <div className={styles.downloadInstruction}>
                <Icon name={'Doc'} className={styles.icon} />
                <a
                  className={styles.link}
                  href="/assets/files/mailing/PDF_Sendsay.pdf"
                  target="_blank"
                  download
                >
                  Скачать инструкцию по настройке .PDF
                </a>
              </div>
            </div>
          </div>
        </Drawer>
        <SendSayMessages />
      </>
    );
  }
);

export default SendSayDrawer;
