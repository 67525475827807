import cx from 'classnames';
import React, { CSSProperties, Suspense } from 'react';
import icons from '../icons';
import './style.css';

export type Icons = keyof typeof icons;

export type IconProps = {
  name: Icons;
  className?: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLOrSVGElement>) => void;
};

const Icon = ({ name, className, style, ...props }: IconProps) => {
  const SVGIcon = React.lazy(() => icons[name]);

  return (
    <Suspense fallback={null}>
      <SVGIcon
        className={cx('icon', className)}
        style={style}
        {...props}
      />
    </Suspense>
  );
};

export const preloadAll = () => {
  Object.keys(icons).forEach(icon => icon);
};

export default React.memo(Icon, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps));
