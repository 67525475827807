import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import Icon from '~/components/Icon';
import { navigationRoutes, INavigationRoute, Routes } from '~/components/Navigation/routes';
import { SwitchUI } from '~/components/SwitchUI';
import { useHideOnSBSHook } from '~/customHooks/useHideOnSBSHook';
import './style.css';

interface WrapperProps {
  route: INavigationRoute;
  handleDropdown: (route: INavigationRoute) => void;
}

const Wrapper: React.FC<WrapperProps> = ({ route, handleDropdown, children }) =>
  !route.children?.length ? (
    <Link className="navigation-item__link" to={route.route} onClick={() => handleDropdown(route)}>
      {children}
    </Link>
  ) : (
    <div
      className="navigation-item__link"
      onClick={() => handleDropdown(route)}
    >
      {children}
    </div>
  );

const renderLink = (
  route: INavigationRoute,
  handleDropdown: (route: INavigationRoute) => void,
  rootPath: string,
  isDropdownOpen?: boolean
) => (
  <SwitchUI
    elementCode={route.uiSwitch}
    rights={route.rights}
    key={route.route}
    customConditionFn={() => useHideOnSBSHook(route.route)}
  >
    <div
      className={cx('navigation-item', {
        _child: route.type === 'child',
        _active:
          (route.route === rootPath)
          || route?.children?.find(route => route.route === rootPath),
        _open: (route.children?.length > 0 && isDropdownOpen),
        _disabled: route.disabled,
      })}
    >
      <Wrapper route={route} handleDropdown={handleDropdown}>
        <div className="navigation-item__icon">
          {route.icon && (
            <Icon name={route.icon} className="navigation-item__svg" />
          )}
        </div>
        <div className="navigation-item__label">{route.title}</div>
        {route.children?.length > 0 && (
          <Icon
            name={isDropdownOpen ? 'ArrowSUp' : 'ArrowSDown'}
            className="navigation-item__svg"
          />
        )}
      </Wrapper>
    </div>
  </SwitchUI>
);

const Navigation: React.FC<RouteComponentProps> = observer(({ location }) => {
  const [openDropdowns, setOpenDropdowns] = useState<Routes>(null);

  const handleDropdown = (navigationRoute: INavigationRoute) => {
    setOpenDropdowns(
      openDropdowns === navigationRoute.route
        ? null
        : navigationRoute?.parent ?? navigationRoute.route
    );
  };

  const rootPath = `/${location.pathname.split('/')[1]}`;

  useEffect(() => {
    navigationRoutes.map((item) => {
      if (item.children?.length > 0) {
        item.children.map((child) => {
          if (child.route === location.pathname) {
            setOpenDropdowns(item.route);
          }
        });
      }

      if (item.route === location.pathname) {
        setOpenDropdowns(item.route);
      }
    });
  }, []);

  return (
    <div className="navigation">
      {navigationRoutes.map((route: INavigationRoute, idx: number) => {
        if (route.type === 'separator') {
          return <div className="navigation__separator" key={idx} />;
        }

        const isDropdownOpen =
          openDropdowns === route.route ||
          route.children?.filter(child => openDropdowns === child.route)
            .length > 0;

        return (
          <div className="navigation__item" key={route.route}>
            {renderLink(route, handleDropdown, rootPath, isDropdownOpen)}
            {isDropdownOpen &&
              route.children?.map(child =>
                renderLink(child, handleDropdown, rootPath)
              )}
          </div>
        );
      })}
    </div>
  );
});

export default withRouter(Navigation);
