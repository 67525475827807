import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './style.css';

interface Props {
  className?: string;
  style?: CSSProperties;
  type?: 'default' | 'button';
  color?: 'default' | 'black' | 'white' | 'green';
}

const Spinner: React.FC<Props> = ({
  className,
  style,
  type= 'default',
  color = 'default',
}) => {
  let strokeWidth = '.5';

  if (type === 'button') {
    strokeWidth = '1.5';
  }

  return (
    <div
      className={cx('spinner', {
        [`_type-${type}`]: type,
        [`_color-${color}`]: color,
      }, className)}
      style={style}
    >
      <svg className="spinner__svg" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.25 10A9.25 9.25 0 1110 .75"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </svg>
    </div>
  );
};

export default Spinner;
