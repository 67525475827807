import React from 'react';
import styles from '~/screens/QuizScreen/components/QuizStartScreen/style.module.css';

export const QuizTypes = () => {
  return (
    <div className={styles.sector}>
      <div className={styles.left}>
        <div className={styles.smallHeader}>Какие квизы можно создать</div>
        <div className={styles.quizType}>
          <img
            src="/assets/images/quiz/quizTypes/quiz.png"
            alt=""
            className={styles.quizTypeImage}
          />
          <div className={styles.quizDescription}>
            <div className={styles.quizTypeTitle}>Викторина</div>
            <div className={styles.quizTypeText}>
              Шаблоны квизов с получением приза
              <br /> за правильные ответы
            </div>
          </div>
        </div>
        <div className={styles.quizType}>
          <img
            src="/assets/images/quiz/quizTypes/survey.png"
            alt=""
            className={styles.quizTypeImage}
          />
          <div className={styles.quizDescription}>
            <div className={styles.quizTypeTitle}>Квиз-опрос</div>
            <div className={styles.quizTypeText}>
              Шаблоны квизов, собирающие пожелания ваших клиентов
            </div>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.quizTypeCustom}>
          <img
            src="/assets/images/quiz/quizTypes/custom.png"
            alt="Квиз на заказ"
            className={styles.quizCustomImage}
          />
          <div className={styles.quizCustomTitle}>Квиз на заказ</div>
          <div className={styles.quizCustomText}>
            Оставьте заявку и мы все сделаем за вас!
          </div>
        </div>
      </div>
    </div>
  );
};
