import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import { BusinessRuleStore } from '~/screens/BusinessRulesScreen/store/businessRuleStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const DialogRenameRule: React.FC<{
  visible: boolean;
  closeRenameDialog: () => void;
}> = observer(({ visible, closeRenameDialog }) => {
  const { businessRuleStore } = useStores() as {
    businessRuleStore: BusinessRuleStore;
  };
  const {
    businessRule,
    renameBusinessRules,
  } = businessRuleStore;

  return (
    <Drawer
      size="x-small"
      visible={visible}
      title={'Название бизнес-правила'}
      onClose={closeRenameDialog}
      actions={(
        <>
          <Button onClick={renameBusinessRules}>Сохранить</Button>
          <Button
            type="grey"
            onClick={closeRenameDialog}
          >
            Отмена
          </Button>
        </>
      )}
    >
      <div className={styles.wrapper}>
        <FormItem message="Не больше 35 символов">
          <Input
            value={businessRule.get('ruleName')}
            onChangeValue={businessRule.setRuleName}
            maxLength={35}
            block
          />
        </FormItem>
      </div>
    </Drawer>
  );
});

export default DialogRenameRule;
