import cx from 'classnames';
import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import ColorPicker, { ColorPickerProps } from '~/components/notification-editor/ColorPicker';
import EyeToggle from '~/components/ui/EyeToggle';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import './style.css';

const MAX_THICKNESS_DEFAULT = 50; // максимальная толщина обводки по умолчанию
interface BorderComponentProps {
  limit?: number;
  enabled?: boolean;
  borderRadius?: number;
  borderWidth?: number;
  colorPickerProps?: ColorPickerProps;
  onChangeState?: (hidden: boolean) => void;
  onBorderRadiusChange?: (borderRadius: number) => void;
  onBorderWidthChange?: (borderWidth: number) => void;
  className?: string;
  hint?: string | React.ReactNode;
  overlayClassName?: string;
  title?: string;
  withRadius?: boolean;
}

const BorderComponent: React.FC<BorderComponentProps> = ({
  limit = MAX_THICKNESS_DEFAULT,
  borderRadius,
  borderWidth,
  enabled,
  colorPickerProps,
  onChangeState,
  onBorderRadiusChange,
  onBorderWidthChange,
  hint,
  className,
  overlayClassName,
  title = 'Обводка',
  withRadius = false,
}) => {
  const handleBorderWidthChange = useCallback((borderWidth: string) => {
    const parseValue = Math.abs(parseInt(borderWidth, 10) || 0);
    const value = parseValue > limit ? limit : parseValue;
    onBorderWidthChange?.(value);
  }, [borderWidth, onBorderWidthChange]);

  const handleBorderRadiusChange = useCallback((borderRadius: string) => {
    const parseValue = Math.abs(parseInt(borderRadius, 10) || 0);
    onBorderRadiusChange?.(parseValue);
  }, [borderRadius, onBorderRadiusChange]);

  const handleChangeState = useCallback(() => {
    onChangeState(!enabled);
  }, [enabled, onChangeState]);

  return (
    <div className={cx('border-component', className)}>
      <FormItem
        noReservedPlaceForMessage
        noHTMLLabel
        title={title}
        hint={hint}
        overlayClassName={overlayClassName}
      >
        <Input
          type="tel"
          className="border-component__input"
          elemBefore={<Icon name="Stroke" className="border-component__border-icon" />}
          value={borderWidth?.toString() || ''}
          onChangeValue={handleBorderWidthChange}
        />
        {withRadius && (
          <Input
            className="border-radius-component__input"
            elemBefore={<Icon name="BorderRadius" className="border-radius-component__icon" />}
            value={borderRadius?.toString() || ''}
            onChangeValue={handleBorderRadiusChange}
          />
        )}
        <EyeToggle selected={enabled} onSelect={handleChangeState}>
          <ColorPicker {...colorPickerProps} />
        </EyeToggle>
      </FormItem>
    </div>
  );
};

export default BorderComponent;
