import React from 'react';
import { ESampleType, QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from '../style.module.css';

interface IQuizType {
  img: string;
  name: string;
  description: string;
  type: ESampleType;
}

export const QuizType = ({ img, name, description, type }: IQuizType) => {

  const { quizStore } = useStores() as {quizStore: QuizStore};
  const onChangeQuizType = () => {
    if (type === 'CUSTOM') {

      location.href = 'https://app.sbercrm.com/form-builder-web/form.html?organizationId=aca51112-3ef8-11ed-8d1b-a7ef008ec515&formId=38ad65f8-f64d-11ee-9f4a-a3cef9af639b';

      return;
    }
    quizStore.setSampleType(type);
    quizStore.setQuizConstructorStep(2);
  };

  return (
    <div className={styles.quizType} onClick={onChangeQuizType}>
      <img src={img} alt={name} className={styles.quizImage} />
      <div className={styles.quizTitle}>{name}</div>
      <div className={styles.quizDescription}>{description}</div>
    </div>
  );
};
