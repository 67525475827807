import React, { useState } from 'react';
import SupportingButton from '~/components/generic/SupportingButton';
import ContextButton, { ContextButtonColor } from '~/components/generic/SupportingButton/ContextButton';
import ContextMenu from '~/components/generic/SupportingButton/ContextMenu';
import styles from './style.module.css';

interface IFloatTableContextButton {
  config: IContextMenu[];
}

export const FloatTableContextButton = ({
  config,
}: IFloatTableContextButton) => {
  const [isVisibleContext, setIsVisibleContext] = useState<boolean>(false);

  const getButtons = (config: IContextMenu[]) => {

    return (
      <ContextMenu
        onClickOutside={() => setIsVisibleContext(false)}
        buttons={(
          <>
            {config.map((v: IContextMenu, key: number) => {

              const callback = (callbackArgument: string | number) => {
                setIsVisibleContext(false);
                v.callback(callbackArgument);
              };

              return (
                <ContextButton
                  key={key}
                  callbackArgument={v.callbackArgument}
                  icon={v.icon}
                  name={v.name}
                  callback={callback}
                  color={v.color as ContextButtonColor}
                  visible={v.visible}
                  disabled={v.disabled}
                />
              );
            })}
          </>
        )}
      />
    );
  };

  return (
    <div className={styles.contextButton}>
      <SupportingButton
        isVisibleContext={isVisibleContext}
        overlay={getButtons(config)}
        onClick={() => setIsVisibleContext(!isVisibleContext)}
      />
    </div>
  );
};
