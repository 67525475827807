import { action, computed, observable } from 'mobx';
import SendSayAccountElementsApi from '~/components/generic/SendSayAccountElements/api/SendSayAccountElementsApi';
import Store from '~/stores/store';
import { getError } from '~/utils/getError';

export class SendSayAccountElementsStore extends Store {
  @observable sendSayAccounts: ISendSayAccount[] = [];
  @observable isSendSayWindowOpen: boolean = false;
  @observable errorMessage: ISendSayAccountErrorMessage = {
    title: 'Что-то пошло не так',
    body: 'Попробуйте еще раз',
  };
  @observable isErrorMessageShown: boolean = false;
  @observable isSuccessWindowShow: boolean = false;

  @action
  setSendSayAccounts = (sendSayAccounts: ISendSayAccount[]) =>
    (this.sendSayAccounts = sendSayAccounts)

  @action
  getSendSayAccounts = async () => {
    try {
      const result = await SendSayAccountElementsApi.getSendSayAccounts();
      this.setSendSayAccounts(result?.data?.payload);
    } catch (e) {
      this.setSendSayAccounts([]);
    }
  }

  @action
  setIsSendSayWindowOpen = (status: boolean) => {
    this.isSendSayWindowOpen = status;
  }

  setErrorMessage = (errorMessage: ISendSayAccountErrorMessage) => {
    this.errorMessage = errorMessage;
  }

  @action
  setIsErrorMessageShown = (status: boolean) => {
    this.isErrorMessageShown = status;
  }

  @action
  setIsSuccessWindowShow = (status: boolean) => {
    this.isSuccessWindowShow = status;
  }

  @action
  saveSendSayAccount = async (apiLogin: string, apiKey: string): Promise<boolean> => {
    try {
      const result = await SendSayAccountElementsApi.saveSendSayAccount(
        apiLogin,
        apiKey
      );
      this.setSendSayAccounts(result?.data?.payload);

      return true;
    } catch (e) {
      this.setErrorMessage({
        title: 'Что-то пошло не так',
        body: getError({ error: e }),
      });
      this.setIsErrorMessageShown(true);

      return false;
    }
  }

  @computed
  get activeAccount() {
    return this.sendSayAccounts.find(
      (sendSayAccount: ISendSayAccount) => sendSayAccount.active
    );
  }

  @computed
  get hasAccounts() {
    return this.sendSayAccounts.filter(
      (sendSayAccount: ISendSayAccount) => sendSayAccount.active === true
    )?.length > 0;
  }
}

export default new SendSayAccountElementsStore();
