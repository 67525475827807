import React, { useEffect } from 'react';
import SendSayDrawer from '~/components/generic/SendSayAccountElements/components/SendSayDrawer';
import SendSayLabel from '~/components/generic/SendSayAccountElements/components/SendSayLabel';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';

interface ISendSayAccountElementsProps {
  classNameSendSayLabel?: string;
  onSave?: () => void;
}

const SendSayAccountElements: React.FC<ISendSayAccountElementsProps> = ({
  classNameSendSayLabel = '',
  onSave = null,
}) => {
  const { getSendSayAccounts } = SendSayAccountElementsStore;

  useEffect(() => {
    getSendSayAccounts();
  }, []);

  return (
    <>
      <SendSayLabel className={classNameSendSayLabel} />
      <SendSayDrawer onSave={onSave} />
    </>
  );
};

export default SendSayAccountElements;
