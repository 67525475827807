import { isEmpty } from 'lodash';
import { getObjectFromLocaleStorage } from '~/utils/localStorageUtils/getObjectFromLocaleStorage';
import { saveObjectToLocaleStorage } from '~/utils/localStorageUtils/saveObjectToLocaleStorage';
import { parseGetParams } from '~/utils/parseGetParams';
import { utmParameters, IUTMData } from '~/utils/utm/types';

export const saveGTM = (): void => {
  const utmObject = getObjectFromLocaleStorage<IUTMData>('utm');

  const isUTMDataSaved = utmObject ? !isEmpty(utmObject?.data) : false;

  if (isUTMDataSaved) return;

  const utmData = parseGetParams(location?.search, utmParameters);

  if (utmData) {
    let utm: IUTMData;
    if (utmData?.loginDCB) {
      utm = {
        data: { utm_source: 'market-sbbol' },
        sent: false,
      };
    } else {
      utm = {
        data: utmData,
        sent: false,
      };
    }
    saveObjectToLocaleStorage('utm', utm);
  }
};
