import React from 'react';
import styles from './style.module.css';

interface IAddressBaseFooterProps {
  isManualMethod: boolean;
  setIsManualMethod: (status: boolean) => void;
}

const AddressBaseFooter: React.FC<IAddressBaseFooterProps> = ({
  isManualMethod,
  setIsManualMethod,
}) => {
  return (
    <div className={styles.block}>
      <div onClick={() => setIsManualMethod(!isManualMethod)}>
        или{' '}
        <span className={styles.switch}>
          {!isManualMethod ? 'укажите вручную' : 'загрузите контакты из файла'}
        </span>
      </div>
      <div className={styles.link_wrapper}>
        {!isManualMethod && (
          <a
            className={styles.link}
            href="/assets/files/mailing/template.xlsx"
            target="_blank"
            download
          >
            Скачать файл шаблона
          </a>
        )}
      </div>
    </div>
  );
};

export default AddressBaseFooter;
