import { hexToRgb } from '~/utils/hexUtils';

export const mjmlButton = ({
  backgroundSectionColor = '#ffffff',
  sectionBorderRadius = '0',
  backgroundColor = '#484848',
  backgroundOpacity = 1,
  borderRadius = '10px',
  fontFamily,
  fontSize,
  height,
  text,
  color,
  colorOpacity = 1,
  padding,
  align = 'center',
  textDecoration = 'none',
  lineHeight = '100%',
  verticalAlign = 'top',
  href = '',
  innerPadding,
  editMode = true,
}: IMailingButton) => {
  const finalInnerPadding = innerPadding
    ? `inner-padding="${innerPadding}"`
    : '';

  return `<mj-section
    padding="0"
    background-color="${backgroundSectionColor}"
    border-radius="${sectionBorderRadius}"
    >
    <mj-column padding="0" vertical-align="top">
      <mj-button
      background-color="${hexToRgb(backgroundColor, backgroundOpacity)}"
      font-family=${fontFamily}
      font-size=${fontSize}
      height="${height}"
      padding="${padding}"
      ${finalInnerPadding}
      color="${hexToRgb(color, colorOpacity)}"
      border-radius="${borderRadius}"
      align="${align}"
      text-decoration="${textDecoration}"
      line-height="${lineHeight}"
      href="${editMode ? '' : href}"
      vertical-align="${verticalAlign}"
      >
        ${text}
      </mj-button>
    </mj-column>
  </mj-section>`;
};
