import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import Link from '~/components/ui/Link';
import { HELP_MAIL, HELP_PHONE } from '~/constants';
import styles from './style.module.css';

interface IHelpBlockProps {
  title?: string;
  mail?: string;
  phone?: string;
  className?: string;
}

const HelpBlock: React.FC<IHelpBlockProps> = ({
  title = 'Обратитесь к нам за помощью или отправьте инструкцию своему специалисту',
  mail = HELP_MAIL,
  phone = HELP_PHONE,
  className= '',
}) => (
  <div className={cx(styles.info, className)}>
    {title !== null && (
      <div className={styles.info_title}>{title}</div>
    )}
    <NumberedBlock>
      <div className={styles.info_item}>
        <Icon name={'Phone'} className={styles.info_icon} />
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `tel:${phone.match(/\d+/g)}`;
            e.preventDefault();
          }}
        >
          {phone}
        </Link>
      </div>
      <div className={styles.line} />
      <div className={styles.info_item}>
        <Icon name={'Subtract'} className={styles.info_icon} />
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${mail}`;
            e.preventDefault();
          }}
        >
          {mail}
        </Link>
      </div>
    </NumberedBlock>
  </div>
);

export default HelpBlock;
