import React from 'react';
import ToCopyInput from '~/components/ui/ToCopyInput';

interface InstructionsToSpecialistProps {
  link: string;
  onCopy: () => void;
}

export const InstructionsToSpecialist: React.FC<InstructionsToSpecialistProps> = ({ 
  link,
  onCopy,
}) => {
  return <ToCopyInput text={link} onCopy={onCopy} />;
};
