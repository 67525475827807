import { IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import HandTitleParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/FeedTitle/HandTitleParams';
import { currentfeedTitleCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/FeedTitle/currentfeedTitleCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import SwitchButton from '~/components/ui/SwitchButton';

interface IFeedTitleProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
}

export const FeedTitle: React.FC<IFeedTitleProps> = ({ configuration, onChange, onBlur }) => {
  const [switchButtonState, setSwitchButtonState] = useState<string>(
    configuration.css?.feedTitle ? 'CSS' : 'HAND'
  );
  const onChangeCssParam = (value: string) => {
    const css = configuration.css ?? {};
    css.feedTitle = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Отображение"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <Checkbox
            label="Показать заголовок"
            className="feed-editor-appearance__checkbox"
            checked={configuration.hasFeedTitle}
            onCheck={checked =>
              onChange({ ...configuration, hasFeedTitle: checked }, true)
            }
          />
        </FormItem>
      </section>
      {configuration.hasFeedTitle && (
        <>
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Заголовок ленты"
              message="Не больше 35 символов"
              overlayClassName="feed-editor-appearance__tooltip"
              hint="Введите название вашей ленты. Рекомендуем сделать его узнаваемым."
            >
              <Input
                value={configuration.feedTitle || ''}
                onChangeValue={value =>
                  onChange({ ...configuration, feedTitle: value })
                }
                maxLength={35}
                className="feed-editor-appearance__width-input"
                onBlur={() => onBlur(configuration)}
                block
              />
            </FormItem>
          </section>
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Как настраивать стили"
              overlayClassName="feed-editor-appearance__tooltip"
              className="feed-editor-appearance__font"
            >
              <SwitchButton
                buttons={cssSwitchButtons}
                activeKey={switchButtonState}
                onClick={setSwitchButtonState}
              />
            </FormItem>
            {switchButtonState === 'CSS' && (
              <ParamCss
                onChangeCssParam={onChangeCssParam}
                value={configuration.css?.feedTitle ?? currentfeedTitleCssParam(configuration)}
              />
            )}
          </section>
          {switchButtonState === 'HAND' && (
            <HandTitleParams
              isActive={switchButtonState === 'HAND'}
              configuration={configuration}
              onChange={onChange}
              onChangeCssParam={onChangeCssParam}
            />
          )}
        </>
      )}
    </>
  );
};
