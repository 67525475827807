import cx from 'classnames';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import { colorToView, hexToRgb } from '~/utils/hexUtils';
import Checkbox from '../ui/CheckBox';
import Input from '../ui/Input';
import './style.css';

export enum Months {
  'января' = 1,
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
}

export const fontWeights: INotificationFontWeight[] = ['Thin', 'Extra Light', 'Light', 'Normal', 'Medium', 'Semi-Bold', 'Bold', 'Extra Bold', 'Black'];

export const titleFontSizes: INotificationTitleFontSize[] =
  [10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40];

export const titleMobileFontSizes: INotificationTitleMobileFontSize[] = [18, 20, 22, 24];

export const messageFontSizes: INotificationMessageFontSize[] = [13, 14, 16, 18];

export const messageMobileFontSizes: INotificationMessageMobileFontSize[] = [13, 14, 16];

export const couponFontSizes: INotificationCouponFontSize[] = [18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40];

export const couponMobileFontSizes: INotificationCouponMobileFontSize[] = [16, 18, 20, 22, 24, 26, 28, 30];

export const buttonFontSizes: INotificationButtonFontSize[] = [13, 14, 16, 17, 18];

export const labelFontSizes: INotificationLabelFontSize[] = [10, 11, 12, 13, 14];

export const fontWeightValues: { [key in INotificationFontWeight]: number } = {
  'Thin': 100,
  'Extra Light': 200,
  'Light': 300,
  'Normal': 400,
  'Medium': 500,
  'Semi-Bold': 600,
  'Bold': 700,
  'Extra Bold': 800,
  'Black': 900,
};

export const fonts: INotificationFont[] = [
  'Inter',
  'Open Sans',
  'PT Sans',
  'PT Serif',
  'Roboto',
  'Roboto Slab',
  'Roboto Condensed',
  'Oswald',
  'Lato',
  'Ubuntu',
  'Montserrat',
  'Exo 2',
  'Playfair Display',
  'Pacifico',
  'Raleway',
  'Fira Sans',
  'Philosopher',
  'Neucha',
  'Yeseva One',
];
export const getTextDecoration = (textUnderline: boolean, textLineThrough: boolean) =>
  textUnderline || textLineThrough
    ? `${textUnderline ? 'underline' : ''} ${textLineThrough ? 'line-through' : ''}`
    : 'none';

export const place: INotificationAlignment[] = [
  'bottom-center',
  'bottom-left',
  'bottom-right',
  'center',
  'center-left',
  'center-right',
  'top-center',
  'top-left',
  'top-right',
];

export interface IExtraHTML {
  position: 'before' | 'after';
  html: React.ReactNode;
}

export type NotificationProps = INotification & {
  className?: string;
  style?: React.CSSProperties;
  isPreview?: boolean;
  showPrivacyPolicy?: () => void;
  tabStep?: number;
  extraHTML?: IExtraHTML;
};

const Notification: React.FC<NotificationProps> = React.memo(({
  backgroundColor = '#ffffff',
  borderRadius = 12,
  opacity,
  alignment = 'bottom-center',

  closeButtonColor = '#ffffff',
  closeIconColor = '#4d4d4d',

  image,
  imageSize = 'original',
  imagePosition = 'center',
  imageBase64,
  inputPlaceholder,

  buttonBackgroundColor = '#5886f5',
  buttonHREF,
  buttonFontFamily = 'Inter',
  buttonOpacity,
  buttonFontSize = 16,
  buttonFontItalic = false,
  buttonFontWeight,
  buttonSize = 'large',
  buttonText,
  buttonTextColor = '#ffffff',
  buttonTextOpacity = 1,
  buttonTextUnderline = false,
  buttonTextLineThrough = false,
  isButtonBlockWidth = true,
  buttonWithBorder = false,
  buttonBorderColor = '#ffffff',
  buttonBorderWidth = 1,
  buttonBorderOpacity = 1,
  buttonBorderRadius = 6,
  buttonWithBackgroundColor = true,

  checkboxColor,

  coupon,
  couponBackgroundColor = 'transparent',
  couponWithBorder = true,
  couponBorderColor = '#808080',
  couponBorderOpacity = 1,
  couponBorderWidth = 1,
  couponFontFamily = 'Inter',
  couponOpacity = 1,
  couponFontSize = 36,
  couponMobileFontSize = 24,
  couponFontItalic = false,
  couponFontWeight = 'Medium',
  couponTextColor = '#000000',
  couponTextUnderline = false,
  couponTextLineThrough = false,

  expirationDate,
  expirationDateColor = '#000000',
  expirationDateFontFamily = 'Inter',
  expirationDateOpacity,
  expirationDateFontSize = 16,
  expirationMobileDateFontSize = 14,
  expirationDateFontItalic = false,
  expirationDateFontWeight = 'Normal',
  expirationDateTextUnderline = false,
  expirationDateTextLineThrough = false,

  hasCoupon,
  hasExpirationDate,
  hasImage,
  hasInput,
  hasMessage,
  hasPrivacyPolicy,

  isHorizontal,
  isMobile,

  message,
  messageColor = '#808080',
  messageFontFamily = 'Inter',
  messageOpacity,
  messageFontSize = 18,
  messageMobileFontSize = 14,
  messageFontItalic = false,
  messageFontWeight = 'Normal',
  messageTextUnderline = false,
  messageTextLineThrough = false,

  privacyPolicyLinkText,
  privacyPolicyText,
  privacyPolicyOpacity,

  title,
  titleColor = '#000000',
  titleFontFamily = 'Inter',
  titleOpacity,
  titleFontSize = 24,
  titleMobileFontSize = 20,
  titleFontItalic = false,
  titleFontWeight = 'Medium',
  titleTextUnderline = false,
  titleTextLineThrough = false,

  isPreview,
  className,
  style,
  tabStep,

  extraHTML,
}) => {
  const [couponHidden, setCouponHidden] = useState(true);
  const removeCouponOverlay = () => {
    setCouponHidden(false);
  };

  const getFormattedExpirationDate = (): string => {
    const dateArr = expirationDate.split('-');

    return `До ${dateArr[2]} ${Months[Number(dateArr[1])]} ${dateArr[0]}`;
  };

  const onClickProp = isPreview ? {} : (buttonHREF ? { href: buttonHREF } : { onClick: removeCouponOverlay });

  const path = (path: string) => `${process.env.API_PATH || ''}/${path}`;
  const commonPath = (path: string) => `/${path}`;

  let imageCustomOrDefault: string = '/assets/widgets/empty.png';

  if (image) {
    imageCustomOrDefault = image.startsWith('http')
      ? image
      : image.startsWith('assets') ? commonPath(`${image}`) : path(image);
  }
  if (imageBase64) {
    imageCustomOrDefault = imageBase64;
  }
  const bgImage = hasImage ? `url(${imageCustomOrDefault})` : null;
  const buttonStyle = {
    backgroundColor: buttonWithBackgroundColor ? hexToRgb(buttonBackgroundColor, buttonOpacity) : 'transparent',
    color: buttonTextColor,
    fontFamily: `"${buttonFontFamily}"`,
    fontSize: `${buttonFontSize}px`,
    fontStyle: buttonFontItalic ? 'italic' : 'normal',
    fontWeight: fontWeightValues[buttonFontWeight],
    textDecoration: getTextDecoration(buttonTextUnderline, buttonTextLineThrough),
    border: buttonWithBorder
      ? `${buttonBorderWidth}px solid ${hexToRgb(buttonBorderColor, buttonBorderOpacity)}`
      : null
    ,
    borderRadius: `${buttonBorderRadius}px`,
  };

  const renderMessage = () => {
    return (
      <div className="notification__message"
        style={{
          color: hexToRgb(messageColor, messageOpacity),
          fontFamily: `"${messageFontFamily}"`,
          fontSize: isMobile ? `${messageMobileFontSize}px` : `${messageFontSize}px`,
          fontStyle: messageFontItalic ? 'italic' : 'normal',
          fontWeight: fontWeightValues[messageFontWeight],
          textDecoration: getTextDecoration(messageTextUnderline, messageTextLineThrough),
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: message.replaceAll('\n', '<br />') }} />
        {isPreview && message === '' && 'Введите подзаголовок'}
      </div>
    );
  };

  const renderCoupon = () => {
    return (
      <div className="notification__coupon" style={{
        border: couponWithBorder
          ? `${couponBorderWidth}px dashed ${hexToRgb(couponBorderColor, couponBorderOpacity)}`
          : 'none',
        backgroundColor: couponBackgroundColor ? hexToRgb(couponBackgroundColor, couponOpacity || 0) : null,
        opacity: couponOpacity || 0,
      }}>
        <div className={cx('notification__coupon-text', { _hidden: couponHidden })}
          style={{
            color: couponTextColor,
            fontFamily: `"${couponFontFamily}"`,
            fontSize: isMobile ? `${couponMobileFontSize}px` : `${couponFontSize}px`,
            fontStyle: couponFontItalic ? 'italic' : 'normal',
            fontWeight: fontWeightValues[couponFontWeight],
            textDecoration: getTextDecoration(couponTextUnderline, couponTextLineThrough),
          }}
        >
          {coupon}
          {isPreview && coupon === '' && 'Код купона'}
        </div>
      </div>
    );
  };

  const renderExpirationDate = () => {
    return (
      <div className="notification__expiration"
        style={{
          color: hexToRgb(expirationDateColor, expirationDateOpacity),
          fontFamily: `"${expirationDateFontFamily}"`,
          fontSize: isMobile ? `${expirationMobileDateFontSize}px` : `${expirationDateFontSize}px`,
          fontStyle: expirationDateFontItalic ? 'italic' : 'normal',
          fontWeight: fontWeightValues[expirationDateFontWeight],
          textDecoration: getTextDecoration(expirationDateTextUnderline, expirationDateTextLineThrough),
        }}
      >
        <Icon className="notification__expiration-icon" name="Clock" />
        <div className="notification__expiration-date">
          {expirationDate && getFormattedExpirationDate()}
          {isPreview && expirationDate === '' && 'Дата истечения'}
        </div>
      </div>
    );
  };

  const renderButtonTag = () => {
    const btnClassName = cx('notification__button', `_size-${buttonSize}`, {
      _block: isButtonBlockWidth,
      _preview: isPreview,
    });

    return (
      <button
        className={btnClassName}
        style={buttonStyle}
        {...onClickProp}
      >
        <span className="notification__button-text" style={{ opacity: buttonTextOpacity || 0 }}>
          {buttonText}
          {isPreview && buttonText === '' && 'Текст кнопки'}
        </span>
      </button>
    );
  };

  const renderATag = () => {
    const btnClassName = cx('notification__button', `_size-${buttonSize}`, {
      _block: isButtonBlockWidth,
      _preview: isPreview,
    });

    return (
      <a
        className={btnClassName}
        style={buttonStyle}
        {...onClickProp}
      >
        <span className="notification__button-text" style={{ opacity: buttonTextOpacity || 0 }}>
          {buttonText}
          {isPreview && buttonText === '' && 'Текст кнопки'}
        </span>
      </a>
    );
  };

  const renderInput = () => (
    <Input className="notification__input" size="large" placeholder={inputPlaceholder} disabled />
  );

  const renderPrivacyPolicy = () => (
    <Checkbox
      className={cx('notification__checkbox', { _withPointerEvents: isPreview === false })}
      color={checkboxColor}
      checked
      disabled
      onCheck={() => undefined}
      size="small"
      label={(
        <span className="notification__privacy-policy" style={{ opacity: privacyPolicyOpacity }}>
          {privacyPolicyText.slice(0, privacyPolicyText.indexOf(privacyPolicyLinkText))}
          <a href={'/assets/files/Consent_to_the_processing_of_personal_data.pdf'}
            target="_blank"
          >
            <span
                className={cx('notification__privacy-policy-underlined-text',
                  { _withPointerEvents: isPreview === false })}
              >
                {privacyPolicyLinkText}
          </span>
          </a>
          {privacyPolicyText.slice(privacyPolicyText.indexOf(privacyPolicyLinkText) + privacyPolicyLinkText.length)}
        </span>
      )}
    />
  );

  return (
    <div
      className={cx(
        'notification',
        `_image-size-${imageSize}`,
        `_image-position-${imagePosition}`,
        {
          '_horizontal': isHorizontal,
          '_widget-edit': (tabStep || (tabStep && tabStep === 3)) ? isHorizontal : !isHorizontal,
          '_mobile': isMobile,
          '_has-image': hasImage,
        },
        className
      )}
      style={{
        borderRadius,
        backgroundImage: bgImage,
        backgroundColor: hexToRgb(colorToView(backgroundColor), opacity),
        ...style,
      }}
    >
      {extraHTML && extraHTML.position === 'before' && extraHTML.html}
      <div className="notification-close" style={{
        backgroundColor: closeButtonColor,
      }}>
        <Icon className="notification-close__icon" name="Close" style={{
          color: closeIconColor,
        }} />
      </div>
      <div className={cx(
        'notification__body',
        `_alignment-${alignment}`)}
      >
        <div className="notification__title"
          style={{
            color: hexToRgb(titleColor, titleOpacity),
            fontFamily: `"${titleFontFamily}"`,
            fontSize: isMobile ? `${titleMobileFontSize}px` : `${titleFontSize}px`,
            fontStyle: titleFontItalic ? 'italic' : 'normal',
            fontWeight: fontWeightValues[titleFontWeight],
            textDecoration: getTextDecoration(titleTextUnderline, titleTextLineThrough),
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: title.replaceAll('\n', '<br />') }} />
          {isPreview && title === '' && 'Введите заголовок'}
        </div>
        {hasMessage && renderMessage()}
        {hasCoupon && renderCoupon()}
        {hasExpirationDate && renderExpirationDate()}
        {hasInput && renderInput()}
        {!buttonHREF && renderButtonTag()}
        {buttonHREF && renderATag()}
        {hasPrivacyPolicy && privacyPolicyText && privacyPolicyLinkText && renderPrivacyPolicy()}
      </div>
      {extraHTML && extraHTML.position === 'after' && extraHTML.html}
    </div>
  );
});

export default Notification;
