import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import Input from '~/components/ui/Input';
import './style.css';

export type TItemClassName = 'confirmed' | 'not-confirmed' | 'default';

export interface IEmailListInputItem {
  key: string;
  value: string;
  status: string;
  itemClassName: TItemClassName;
  registered?: boolean;
}

const EmailListInput: React.FC<{
  onChange: (value: string) => void;
  items?: IEmailListInputItem[];
  onClickSearchItem?: (item: IEmailListInputItem) => void;
  value?: string;
  className?: string;
  placeholder?: string;
  size?: FormElementSize;
  isOpenDropdown?: boolean;
  clickOutside?: () => void;
  failure?: boolean;
  onBlur?: (value: string) => void | PromiseLike<any>;
  elemAfter?: React.ReactNode;
  setIsDropdownVisible?: (status: boolean) => void;
}> = ({
  onChange,
  items = [],
  onClickSearchItem = null,
  value = '',
  className = '',
  placeholder = '',
  size = 'large',
  isOpenDropdown = false,
  clickOutside = null,
  failure = false,
  onBlur = null,
  elemAfter = null,
  setIsDropdownVisible = null,
}) => {
  const dropdownRef = useRef(null);
  const handleClickOutside = () => {
    if (clickOutside) clickOutside();
  };
  useOnClickOutside(dropdownRef, handleClickOutside);

  const handleClick = () => {
    !isOpenDropdown && setIsDropdownVisible?.(true);
  };

  return (
    <div ref={dropdownRef} className={`generic-email-list-input__container ${className}`}>
      <Input
        value={value}
        onChangeValue={onChange}
        className="generic-email-list-input__input"
        placeholder={placeholder}
        size={size}
        block
        failure={failure}
        onBlur={onBlur}
        elemAfter={elemAfter}
        onFocus={handleClick}
      />
      <div
        className={`generic-email-list-input__dropdown ${
          isOpenDropdown && items.length > 0 ? '__is_visible' : ''
        }`}
      >
        {items.map((item) => {
          return (
            <div
              key={item.key}
              className={`generic-email-list-input-dropdown__item`}
              onClick={() => {
                if (onClickSearchItem) onClickSearchItem(item);
              }}
            >
              <div>{item.value}</div> 
              <div
                className={item.itemClassName
                  ? `generic-email-list-input-dropdown-item__${item.itemClassName}`
                  : null
                }
              >
                {item.status}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmailListInput;
