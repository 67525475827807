import React from 'react';
import { useHistory } from 'react-router-dom';
import Message from '~/components/ui/Message';
import { TARIFF_TAB } from '~/constants';

interface ScriptIsNotWorkingMessageProps {
  isVisible: boolean;
  setIsVisible: (status: boolean) => void;
}

const ScriptIsNotWorkingMessage: React.FC<ScriptIsNotWorkingMessageProps> = ({
  isVisible,
  setIsVisible,
}) => {

  const history = useHistory();
  const redirectToSettings = () => history.push(`/settings?tab=${TARIFF_TAB}`);

  return (
    <>
      {isVisible && (
        <Message
          visible={isVisible}
          className="scenarios-screen-list__msg-script-exception"
          title={(
            <span>
              Если не подключить сайт, этот <br /> раздел не сможет
              работать 🙄
            </span>
          )}
          textTop="Подключайте сайт и пользуйтесь на здоровье!"
          applyText="Подключить сайт"
          cancelText="Закрыть"
          showLine={false}
          onApply={redirectToSettings}
          onClose={() => setIsVisible(false)}
          onExit={() => setIsVisible(false)}
        />
      )}
    </>
  );
};

export default ScriptIsNotWorkingMessage;