export const getYmlIconName = (ymlCatalogStatus: string) => {
  switch (ymlCatalogStatus) {
    case 'UPLOAD':
      return 'CheckmarkM';
    case 'YML_ERROR':
    case 'NOT_UPLOAD':
      return 'Close';
    case 'VERIFY':
      return 'Clock';
    case 'UPLOAD_CACHED':
      return 'TriangleWarning2';
    default:
      return 'Close';
  }
};
