import { IFinalStep, TStepType } from 'feed';
import { v4 as uuidv4 } from 'uuid';

export const generateEmptyStep = (type: TStepType): IFinalStep => {
  let step = {
    type,
    buttonText: '',
    title: '',
    uuid: uuidv4(),
  };

  if (type === 'cover') {
    step = {
      ...step,
      ...{
        title: 'Заголовок квиза или викторины',
        description: 'Дополнительный текст или описание',
        buttonText: 'Начать',
        bonuses: [],
      },
    };
  }

  if (type === 'question') {
    const answerExample = {
      '@type': 'RadioQuizAnswer',
      'text': 'Текст ответа',
      'checked': false,
      'image': {
        url: 'https://quiz.arh.net.ru/clickstream-analytics-bh/image/485a2ff405b3b1c2be5c13b444d8336f798e2c8bf1',
        code: '485a2ff405b3b1c2be5c13b444d8336f798e2c8bf1',
      },
      'isRight': true,
    };

    const mockedAnswers = [answerExample];

    step = {
      ...step,
      ...{
        answerType: 'LIST',
        title: 'Новый вопрос',
        description: undefined,
        radio: true,
        buttonText: 'Далее',
        answers: mockedAnswers,
      },
    };
  }

  if (type === 'lead') {
    step = {
      ...step,
      ...{
        title: 'Дарим вам скидку 15%',
        buttonText: 'Получить скидку',
        description:
          'Оставьте ваш телефон и мы закрепим скидку за вашим номером!',
        contactType: ['PHONE', 'NAME'],
        bonuses: [],
      },
    };
  }

  if (type === 'final') {
    step = {
      ...step,
      ...{
        title: 'Дарим вам скидку 15%',
        description:
          'Оставьте ваш телефон и мы закрепим скидку за вашим номером!',
        buttonText: 'Копировать',
        promoCode: 'GHY5646UYTB',
      },
    };
  }

  return step;
};

export const defaultImage = {
  code: '485a2ff405b3b1c2be5c13b444d8336f798e2c8bf1',
  url: 'https://quiz.arh.net.ru/clickstream-analytics-bh/image/485a2ff405b3b1c2be5c13b444d8336f798e2c8bf1',
};
