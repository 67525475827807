export const hexToRgb = (hex: string = '#000', alpha: number = 1): string => {
  if (hex === 'transparent' || hex === null) return 'rgba(0, 0, 0, 0)';

  const short = hex?.length === 4;

  const r = parseInt(
    short ? `${hex.slice(1, 2)}${hex.slice(1, 2)}` : hex.slice(1, 3),
    16
  );
  const g = parseInt(
    short ? `${hex.slice(2, 3)}${hex.slice(2, 3)}` : hex.slice(3, 5),
    16
  );
  const b = parseInt(
    short ? `${hex.slice(3, 4)}${hex.slice(3, 4)}` : hex.slice(5, 7),
    16
  );
  const a = alpha > 1 ? `${alpha}%` : alpha;

  const rgba = `rgba(${r}, ${g}, ${b}${
    alpha ? `, ${a}` : ', 0'
  })`;

  return rgba;
};

export const isWhite = (color: string) => {
  if (color === null) return false;

  return color
    .replace('#', '')
    .split('')
    .every(char => char.toLowerCase() === 'f');
};

export const formatAlpha = (alpha: number, addSign = false) =>
  `${alpha}${addSign ? '%' : ''}`;

export const formatColor = (color: string, addSign = false) => {
  if (color === null) return '#000000';

  return `${addSign ? '#' : ''}${color.replace('#', '')}`;
};


/**
 * Метод преобразует невалидные значения цвета в полные 6-значные.
 */
export const completeHexCode = (value: string) => {
  const color = value.replace('#', '');
  switch (color.length) {
    case 0:
      return '';
    case 1:
      return `#${color.repeat(6)}`;
    case 2:
      return `#${color[0].repeat(3)}${color[1].repeat(3)}`;
    case 3:
      return `#${color[0].repeat(2)}${color[1].repeat(2)}${color[2].repeat(2)}`;
    case 4:
      return `#${color}${color[2]}${color[3]}`;
    case 5:
      return `#${color}${color[4]}`;
    default:
      return formatColor(color, true);
  }
};

/**
 * Валидация hex цвета.
 *
 * @param color Hex цвет
 *
 * @return boolean результат валидации
 */
export const isValidHexColor = (color: string) => {
  if (color.length !== 7) {
    return false;
  }

  if (!/^#[0-9A-F]+$/i.test(color)) {
    return false;
  }

  return true;
};

/**
 * Проверка строки цвета на наличие символа #.
 *
 * @param color
 *
 * @return boolean
 */
export const hasSharp = (color: string) => {
  return color?.indexOf('#') !== -1;
};

/**
 * Корректировка вывода цвета на макет в режиме редактирования цвета.
 *
 * @param color
 *
 * @return string
 */
export const colorToView = (color: string): string =>
  !hasSharp(color) ? formatColor(color, true) : color;

export const isRgb = (rgb: string) => {

  return rgb.includes('rgb');
};

export const getOpacity = (rgba: string) => {
  return isRgb(rgba) ?
    parseInt(rgba.replace(/^.*,(.+)\)/, '$1').replace('%', ''), 10)
    : 100;
};

/**
 * Переводит 'rgba(221,38,38,1)' в hex '#xxxxxx' вид
 * @param rgba string
 * @returns
 */
export const rgbaToHex = (rgba: string): string => {
  return `#${rgba
    .match(/[0-9|.]+/g)
    .map((x, i) =>
      i === 3
        ? // @ts-ignore
          parseInt(255 * parseFloat(x), 10).toString(16)
        : parseInt(x, 10).toString(16)
    )
    .join('')}`;
};

/**
 * Выравнивает цифру по шагу
 * Используется в нормировании прозрачности цвета
 * @param alpha
 * @param step
 * @returns
 */
export const alphaStepVerification = (alpha: number, step: number = 1): number => {
  if (alpha === 0) return alpha;
  if (alpha < step) return step;
  if (step > 1) {
    if (alpha % step !== 0) {
      return Math.round(alpha / step) * step;
    }
  }

  return alpha;
};
