import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { MailingElementWrapper } from '~/components/Mailing/ui/MailingElementWrapper';
import MailElement from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/Mail/MailElement';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { hexToRgb } from '~/utils/hexUtils';
import useStores from '~/utils/useStores';
import styles from './style.module.css';
const mjml2html = require('mjml-browser');

export const Mail = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };
  const { mailingInstance, firstRender, setFirstRender } = mailingStore;

  const ref = useRef(null);

  const onClick = (name: TMailItemName = 'letter') => {
    setFirstRender(false);
    mailingInstance.selectSection(name);
  };

  if (mailingInstance.get('configuration')) {
    let mails = '';

    mailingInstance
      .get('configuration')
      .forEach((v: MailItem) => (mails += v?.configuration(v?.params)));

    const letterProps = mailingInstance
      .get('configuration')
      .filter((v: any) => v.type === 'letter')[0];

    const { backgroundColor, backgroundOpacity } = letterProps?.params || {};

    const htmlOutput = mjml2html(
      `<mjml>
        <mj-head>
          <mj-html-attributes>
            <mj-selector path=".bodyPadding">
              <mj-html-attribute name="style">padding: 40px 0;
            </mj-html-attribute>
            </mj-selector>
          </mj-html-attributes>
          <mj-style>
            .mobileItemPhoto {
              max-width: 120px;
            }
          </mj-style>
        </mj-head>
        <mj-body background-color="${hexToRgb(
          backgroundColor,
          backgroundOpacity
        )}" css-class='bodyPadding' width='600px'>
          ${mails}
        </mj-body>
    </mjml>
  `
    );

    return (
      <div
        className={styles.wrapper} 
        style={{
          backgroundColor,
          opacity: backgroundOpacity,
          border: letterProps.selected
            ? '2px #47a5ff solid'
            : `2px ${backgroundColor} solid`,
        }}
        onClick={() => onClick('letter')}
      >
        <div ref={ref} className={styles.box} >
          {firstRender && (
            <div className={styles.info}>
              Кликните на любую область письма, чтобы ее редактировать
            </div>
          )}
          {mailingInstance
            .get('configuration')
            .filter((v: any) => v.type !== 'letter')
            .map((v: MailItem, key: number) =>
              v.params ? (
                <MailElement
                  key={key}
                  name={v.name}
                  onClick={() => onClick(v.name)}
                  selected={v.selected}
                >
                  <MailingElementWrapper
                    sectionFunction={v.configuration}
                    sectionProps={v.params}
                  />
                </MailElement>
              ) : (
                <div>Неверно переданы параметры, нельзя отрисовать блок</div>
              )
            )}
        </div>
        <div
          style={{ border: '1px black solid', display: 'none' }}
          dangerouslySetInnerHTML={{ __html: htmlOutput.html }}
        />
      </div>
    );
  }

  return null;
});
