import React from 'react';
import DatePicker, { DatePickerProps } from '~/components/ui/DatePicker';
import FormItem from '~/components/ui/FormItem';

interface ValidityOfTheActionProps {
  validityOfTheActionProps?: DatePickerProps;
  hint?: string;
}

const ValidityOfTheAction: React.FC<ValidityOfTheActionProps> = ({ validityOfTheActionProps, hint }) => {
  return (
    <div className="validity-of-the-action" >
      <FormItem
        title="Срок действия акции"
        hint={hint}
        noReservedPlaceForMessage
      >
        <DatePicker {...validityOfTheActionProps} noPast />
      </FormItem>
    </div>
  );
};

export default ValidityOfTheAction;
