import { action } from 'mobx';
import Model from '~/models/Model';

export default class WheelOfFortuneModel extends Model<IWheelOfFortune> {
  @action
  setIsMobile = (device: Device) => this.update({ isMobile: device === 'mobile' })

  @action
  setTitle = (title: string) => this.update({ title })

  @action
  setMessage = (message: string) => this.update({ message })

  @action
  setButtonText = (buttonText: string) => this.update({ buttonText })

  @action
  setSectors = (sectors: ISectorB[]) => {
    this.update({
      sectors,
    });
  }

  @action
  setSectorParams = (sectorParams: ISectorParams[]) => {
    this.update({
      sectorUIParams: sectorParams,
    });
  }
}
