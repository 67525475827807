import cx from 'classnames';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import Spinner from '~/components/ui/Spinner';
import { className } from '~/screens/QuizScreen/components/QuizList';
import { QuizStore, TQuizStatus } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IStatusButton {
  status: TQuizStatus;
  quizId: string;
}

const statusName = {
  STARTED: 'Запущен',
  STOPPED: 'На паузе',
  ERROR: 'Ошибка',
};

const iconName = {
  STARTED: 'PlayCircle',
  STOPPED: 'PauseCircle',
  ERROR: 'CloseCircle',
};

export const StatusButton = ({ status, quizId }: IStatusButton) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const onClick = async () => {
    setIsLoading(true);

    try {
      const newStatusAction = status === 'STARTED' ? 'stop' : 'start';

      await quizStore.updateStatus(quizId, newStatusAction);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cx(styles.statusButton, styles[className[status]])} onClick={onClick}>
      <Icon name={iconName[status]} />
      {isLoading ? <div  className={styles.spinner}><Spinner /></div> : statusName[status]}
    </div>
  );
};
