import { IPromo } from '~/components/generic/Promo';

export const items = [
  {
    iconSrc: 'DemoMailing',
    text: 'Возможность замотивировать клиента к покупке, даже если он ушел с сайта',
  },
  {
    iconSrc: 'DemoMailing',
    text: 'Различные механики рассылки (брошенная корзина, циклические покупки, акции, распродажи)',
  },
  {
    iconSrc: 'DemoMailing',
    text: 'Единоразовая настройка сценария рассылки для регулярной коммуникации с клиентами',
  },
];

export const promoMaterials: Partial<IPromo> = {
  header: 'Автоматические Email рекомендации',
  subheader:
    'Автоматические письма отправляются в реальном времени исходя из поведения каждого посетителя на вашем сайте.',
  img: '/assets/images/promo/mailing.png',
  items,
  text1: 'Настройка займет не более пяти минут',
};
