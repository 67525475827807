import cx from 'classnames';
import React from 'react';
import Checkbox from '~/components/ui/CheckBox';

export interface CheckGroupItem {
  key: string;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  label: React.ReactNode;
}

interface CheckGroupProps {
  items: CheckGroupItem[];
  size?: 'medium' | 'small';
  onCheck: (key: string, checked: boolean) => void;
}

const CheckGroup: React.FC<CheckGroupProps> = ({
  size = 'medium',
  items,
  onCheck,
}) => {
  return (
    <div className={cx('checkgroup', `_size-${size}`)}>
      {
        items.map(item => (
          <Checkbox
            key={item.key}
            {...item}
            checked={item.checked || false}
            size={size}
            onCheck={checked => onCheck(item.key, checked)}
          />
        ))
      }
    </div>
  );
};

export default CheckGroup;

