import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import { SwitchUI } from '~/components/SwitchUI';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import { Instructions } from '~/screens/LeadsSettingsScreen/Instructions/Instructions';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { MacroCRMInstructions } from '~/screens/LeadsSettingsScreen/macroCRM/MacroCRMInstructions';
import { PlugMacroCRM } from '~/screens/LeadsSettingsScreen/macroCRM/PlugMacroCRM';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const MacroCRMSettings = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;

  const isMacroCRMLoading = leadSettings.get('isMacroCRMLoading');
  const isMacroCRMSet = leadSettings.get('isMacroCRMSet');
  const macroCRMError = leadSettings.get('macroCRMError');

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isDisablePopupOpen, setIsDisablePopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    !isMounted && leadsStore.leadSettings.getMacroCRMIntegrationState();
  }, [isMounted]);

  const isSuccessShow = isMacroCRMSet && !isMacroCRMLoading && !macroCRMError;
  const isPlugFormShow = !isMacroCRMSet && !isMacroCRMLoading && !macroCRMError;

  const disableMacroCRM = async () => {
    await leadSettings.disableMacroCRM();
    setIsDisablePopupOpen(false);
  };

  return (
    <SwitchUI elementCode={'LeadSettings#MacroCRM'} rights={['USE_MACROCRM']}>
      <div className="leads-settings__inner-container">
        {isMacroCRMLoading && (
          <Loading status={'Loading'} onClick={() => null} />
        )}
        {macroCRMError && (
          <Loading status={'Error'} onClick={() => window.location.reload()} />
        )}
        {isPlugFormShow && (
          <>
            <h3 className="leads-settings__description">
              Для того, чтобы получать заявки в «MacroCRM», вам нужно:
            </h3>
            <MacroCRMInstructions />
            <PlugMacroCRM />
            <Instructions type={'macroCRM'} />
          </>
        )}
        {isSuccessShow && (
          <SettingsItem
            title="Ура! Теперь вы можете получать заявки с виджетов прямо в MacroCRM! "
            Content={(
              <Button
                className="tgm-settings__content-btn"
                type="grey"
                onClick={() => setIsDisablePopupOpen(true)}
                block
              >
                Отключить сбор заявок в MacroCRM
              </Button>
            )}
            isSuccess
          />
        )}
        {isDisablePopupOpen && (
          <Message
            onApply={disableMacroCRM}
            visible={isDisablePopupOpen}
            title="Отключить сбор заявок в SberCRM?"
            textTop={
              'При продолжении операции, заявки больше не будут приходить в личный кабинет MacroCRM, пока Вы снова их не подключите.'
            }
            applyText={'Отключить'}
            cancelText={'Отмена'}
            onClose={() => setIsDisablePopupOpen(false)}
            showIcon={false}
          />
        )}
      </div>
    </SwitchUI>
  );
});
