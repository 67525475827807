import { IFeedConfiguration } from 'feed';
import { action } from 'mobx';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import Model from '~/models/Model';
import FeedLiteApi from '~/screens/FeedLiteScreen/api/feedLiteApi';
import { CheckedCountPage } from '~/screens/FeedLiteScreen/store/feedLiteStore';

export default class FeedLiteModel extends Model<FeedLite> {
  @action
  setUrlPattern = (urlPattern: string) => this.update({ urlPattern })

  @action
  setDisplayOperation = (
    operation: 'CONTAINS' | 'EQUAL' | 'NOT_CONTAINS' | 'START_WITH'
  ) =>
    this.update({
      displayConditions: {
        operation,
        url: this.get('displayConditions')?.url,
      },
    })

  @action
  setDisplayUrl = (url: string) =>
    this.update({
      displayConditions: {
        operation: this.get('displayConditions')?.operation,
        url,
      },
    })

  @action
  setSelector = (selector: string) => this.update({ selector })

  @action
  setSelectorMobile = (selectorMobile: string) =>
    this.update({ selectorMobile })

  @action
  setConfiguration = (configuration: IFeedConfiguration) => {
    this.update({ configuration });
  }

  @action
  saveLite = async (
    stage: Stage,
    recommendationList?: FeedConstructorModel[],
    id?: string | number,
    feedProcessId?: string,
    checkedCountPage?: CheckedCountPage,
    status?: string
  ) => {
    const recommendations: IProduct[] = [];
    recommendationList.forEach((item) => {
      recommendations.push({
        imageUuid: item.get('imageUuid'),
        order: item.get('order'),
        offerId: item.get('offerId'),
        name: item.get('name'),
        description: item.get('description'),
        urlLink: item.get('urlLink'),
        price: item.get('price'),
        crossedPrice: item.get('crossedPrice') ?? null,
        image: item.get('image'),
        imgLink: item.get('imgLink'),
        labels: item.get('labels'),
      });
    });
    try {
      return await FeedLiteApi.saveLite(
        this,
        recommendations,
        id,
        feedProcessId,
        checkedCountPage,
        stage,
        status
      );
    } catch (e) {
      throw e;
    }
  }
}
