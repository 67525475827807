import React from 'react';
import { Mail } from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/Mail';
import { MailSettings } from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabConstructor/MailSettings';
import styles from './style.module.css';

const TabConstructor: React.FC = () => (
    <div className={styles.constructor}>
      <div className={styles.result} id="letter-constructor-container">
        <Mail />
      </div>
      <div className={styles.letterSettings} id="mail_settings">
        <MailSettings />
      </div>
    </div>
  );

export default TabConstructor;
