import { observer } from 'mobx-react';
import React from 'react';
import Icon from '~/components/Icon';
import SendSayAccountElementsStore from '~/components/generic/SendSayAccountElements/store/SendSayAccountElementsStore';
import './style.css';

interface ISendSayLabelProps {
  className?: string;
}

const SendSayLabel: React.FC<ISendSayLabelProps> = observer(({ className = '' }) => {
  const {
    activeAccount,
    setIsSendSayWindowOpen,
  } = SendSayAccountElementsStore;

  const apiLogin = activeAccount?.apiLogin;

  return (
    <>
      {apiLogin && (
        <div
          className={`components-generic-sendsayaccountelements-sendsay-label__button ${
            className ? className : ''
          }`}
          onClick={() => {
            setIsSendSayWindowOpen(true);
          }}
        >
          Прикреплена уч.запись Sendsay: {apiLogin}
          <span>
            <Icon
              name={'CheckedGreenCircle'}
              className={
                'components-generic-sendsayaccountelements-sendsay-label__icon'
              }
            />
          </span>
        </div>
      )}
    </>
  );
});

export default SendSayLabel;
