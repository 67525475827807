import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const AmoCRMInstructions = observer(() => {

  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;
  const [amoCRMUrl, setCRMUrl] = useState<string>();
  const [amoToken, setAmoToken] = useState<string>();
  const amoCRMUrlError = leadSettings.get('amoCRMUrlError');
  const amoCRMTokenError = leadSettings.get('amoCRMTokenError');

  const onChangeCRMUrl = (url: string) => {
    setCRMUrl(url);
    leadSettings.update({ amoCRMUrlError: '' });
  };

  const onChangeToken = (token: string) => {
    setAmoToken(token);
    leadSettings.update({ amoCRMTokenError: '' });
  };

  const enableAmoCRM = async () => {
    const subdomain = getSubdomain(amoCRMUrl);
    await leadSettings.enableAmoCRM(subdomain, amoToken);
  };

  const getSubdomain = (url: string) => {
    let domain = url;
    if (url?.includes('://')) {
      domain = url?.split('://')[1];
    }
    
    return domain?.split('.')[0];
  };

  return (
    <>
      <SettingsItem
        Content={(
          <div className={'simple-text'}>
            Пошаговая инструкция описана в документации amoCRM{<br />}
            <a
              target="_blank"
              href="https://www.amocrm.ru/developers/content/oauth/step-by-step"
              className="leads-settings__simple-link-text"
            >
              Инструкция amoCRM по созданию интеграции
            </a>
          </div>
        )}
        title={
          'Создайте внешнюю интеграцию в amoCRM'
        }
        number={1}
      />
      <SettingsItem
        Content={(
          <>
            <div className={'simple-text'} style={{
              marginBottom: '8px',
            }}>
              Вы можете его скопировать из адресной строки, находясь в личном кабинете amoCRM
            </div>
            <Input
              className={'leads-settings__item-input'}
              onChangeValue={onChangeCRMUrl}
              failure={!!amoCRMUrlError}
              placeholder={
                'https://example.amocrm.ru/leads/detail/47680201'
              }
            />
            {(
              <div className={'leads-settings__item-error'}>
                {amoCRMUrlError}
              </div>
            )}
          </>
        )}
        title={'Введите ваш поддомен amoCRM'}
        number={2}
      />
      <SettingsItem
        Content={(
          <div className={'simple-text'}>
            Пошаговая инструкция описана в документации amoCRM{<br />}
            <a
              target="_blank"
              href="https://www.amocrm.ru/developers/content/oauth/step-by-step"
              className="leads-settings__simple-link-text"
            >
              Инструкция amoCRM по созданию интеграции
            </a>
          </div>
        )}
        title={
          'Сгенерируйте долгосрочный токен в amoCRM'
        }
        number={3}
      />
      <SettingsItem
        title={
          'Введите токен'
        }
        number={4}
        Content={(
          <>
            <Input
              className={'leads-settings__item-input'}
              onChangeValue={onChangeToken}
              failure={!!amoCRMTokenError}
              placeholder={
                'Token'
              }
            />
            {(
              <div className={'leads-settings__item-error'}>
                {amoCRMTokenError}
              </div>
            )}
          </>
        )}
      />
      <SettingsItem
        Content={(
          <>
            <Button
              className="leads-settings__item-button"
              onClick={enableAmoCRM}
            >
              Подключить
            </Button>
          </>
        )}
      />
    </>
  );
});
