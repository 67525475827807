import { action, observable } from 'mobx';
import Store from '~/stores/store';

type IStatusFunction = (status: boolean) => void;

export interface IConstructorStore<StepIdentifier> {
  steps: StepIdentifier[];
  currentStepIndex: number;
  currentStep: StepIdentifier;
  isConstructorOpen: boolean;
  isWaitingResponse: boolean;
  changeCurrentStep: (activeKey: StepIdentifier) => void;
  changeCurrentStepNextOrBack: (direction: number) => void;
  setIsConstructorOpen: IStatusFunction;
  setIsWaitingResponse: IStatusFunction;
}

/**
 * Управляет шагами и открытием конструктора
 */
export class ConstructorStore<StepIdentifier> extends Store
  implements IConstructorStore<StepIdentifier> {
  @observable steps: StepIdentifier[]; // Массив всех шагов
  @observable currentStepIndex: number = 0; // Индекс текущего шага
  @observable currentStep: StepIdentifier; // Текущий шаг
  @observable isConstructorOpen: boolean = false; // Состояние конструктора
  @observable isWaitingResponse: boolean = false; // Ожидает ответа

  constructor(steps: StepIdentifier[] = []) {
    super();
    this.steps = steps;
    this.setCurrentStep(steps.length > 0 ? this.steps[0] : null);
  }

  /**
   * Изменяет индекс текущего шага
   * @param activeKey
   */
  @action
  setCurrentStepIndex = (currentIndex: number) => {
    this.currentStepIndex = currentIndex;
  }

  /**
   * Находит индекс шага по его наименованию
   * @param activeKey 
   * @returns 
   */
  @action
  findCurrentStepIndex = (activeKey: StepIdentifier) => {
    return this.steps.findIndex(
      (step: StepIdentifier) => activeKey === step
    );
  }

  @action
  setCurrentStep = (step: StepIdentifier) => {
    this.currentStep = step;
  }

  /**
   * Изменяет шаг на указанный
   * @param activeKey
   */
  @action
  changeCurrentStep = (activeKey: StepIdentifier) => {
    this.setCurrentStep(activeKey);
    this.setCurrentStepIndex(
      this.findCurrentStepIndex(activeKey)
    );
  }

  /**
   * Изменяе шаг на +n или -n шагов
   * @param n
   */
  @action
  changeCurrentStepNextOrBack = (n: number) => {
    let newStepIndex = this.findCurrentStepIndex(this.currentStep) + n;
    if (newStepIndex > this.steps.length - 1 || newStepIndex < 0)
      newStepIndex = 0;
    this.changeCurrentStep(this.steps[newStepIndex]);
  }

  /**
   * Изменяет состояние конструктора
   * @param status 
   */
  @action
  setIsConstructorOpen = (status: boolean) => {
    this.isConstructorOpen = status;
  }

  /**
   * Изменяет состояние ожидания
   * @param status 
   */
  @action
  setIsWaitingResponse = (status: boolean) => {
    this.isWaitingResponse = status;
  }
}

export default ConstructorStore;
