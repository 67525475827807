import React from 'react';
import { StatisticItem } from '~/screens/QuizScreen/components/CommonStatistic/StatisticItem';
import styles from './style.module.css';

import { IStatistic } from '~/screens/QuizScreen/stores/quizStore';

interface ICommonStatistic {
  statistics: IStatistic[];
}

export const CommonStatistic = ({ statistics }: ICommonStatistic) => {

  if (!statistics) return null;

  return (
    <div className={styles.statistic}>
      {statistics.map((v, key) => (
        <StatisticItem {...v} key={key} />
      ))}
    </div>
  );
};
