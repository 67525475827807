import { IFeedConfiguration, TFeedCard } from 'feed';

export const FEED_DEF_CONFIGURATION: IFeedConfiguration = {
  feedTitle: 'Рекомендованные товары',
  feedProductsAmount: 10,

  hasFeedTitle: true,
  feedTitleFontFamily: 'Inter',
  feedTitleColor: '#000000',
  feedTitleOpacity: 100,
  feedTitleFontWeight: 'Semi-Bold',
  feedTitleFontSize: 20,
  feedTitleFontItalic: false,
  feedTitleTextLineThrough: false,
  feedTitleTextUnderline: false,

  hasCardTitle: true,
  cardTitleFontFamily: 'Inter',
  cardTitleColor: '#000000',
  cardTitleOpacity: 100,
  cardTitleFontWeight: 'Medium',
  cardTitleFontSize: 16,
  cardTitleFontItalic: false,
  cardTitleTextLineThrough: false,
  cardTitleTextUnderline: false,

  hasCardDescription: false,
  cardDescriptionFontFamily: 'Inter',
  cardDescriptionColor: '#808080',
  cardDescriptionOpacity: 100,
  cardDescriptionFontWeight: 'Normal',
  cardDescriptionFontSize: 14,
  cardDescriptionFontItalic: false,
  cardDescriptionTextLineThrough: false,
  cardDescriptionTextUnderline: false,

  cardPriceFontFamily: 'Inter',
  cardPriceColor: '#000000',
  cardPriceOpacity: 100,
  cardPriceFontWeight: 'Medium',
  cardPriceFontSize: 22,
  cardPriceFontItalic: false,
  cardPriceTextLineThrough: false,
  cardPriceTextUnderline: false,

  cardCrossedPricePosition: 'row',

  cardCrossedPriceFontFamily: 'Inter',
  cardCrossedPriceColor: '#b3b3b3',
  cardCrossedPriceOpacity: 100,
  cardCrossedPriceFontWeight: 'Medium',
  cardCrossedPriceFontSize: 20,
  cardCrossedPriceFontItalic: false,
  cardCrossedPriceTextLineThrough: true,
  cardCrossedPriceTextUnderline: false,

  clickFunctionName: '',
  countCardsInDisplay: 4,

  hasButtonIcon: false,
  buttonIconName: 'bag',
  buttonIconColor: '#107f8c',
  buttonIconOpacity: 100,
  buttonBorderWidth: 1,
  buttonBorderColor: '#000000',
  buttonBorderOpacity: 100,
  hasButtonBorder: true,
  buttonColor: '#ffffff',
  buttonOpacity: 100,
  buttonTextColor: '#000000',
  buttonTextOpacity: 100,
  buttonFontFamily: 'Inter',
  buttonFontWeight: 'Medium',
  buttonFontSize: 16,
  buttonFontItalic: false,
  buttonTextLineThrough: false,
  buttonTextUnderline: false,

  feedBackgroundColor: '#ffffff',
  feedOpacity: 0,
  feedWidth: 100,
  hasCardButton: true,
  hasCardCrossedPrice: false,
  buttonName: 'Подробнее',
  hasCardPrice: true,
  imageSize: 'fill',
  hasCardShadow: true,

  labelsPosition: 'top-left', // 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  labelShadow: false,
  labelTextColor: '#ffffff',
  labelTextColorOpacity: 100,
  labelFontFamily: 'Inter',
  labelFontWeight: 'Medium',
  labelFontSize: 13,
  labelFontItalic: false,
  labelTextLineThrough: false,
  labelTextUnderline: false,
  labels: [
    {
      isActive: true,
      aliasName: '',
      uuid: 'hit',
      text: 'Хит',
      labelBackgroundColor: '#D54242',
      labelBackgroundOpacity: 100,
    },
    {
      isActive: true,
      aliasName: '',
      uuid: 'new',
      text: 'Новинка',
      labelBackgroundColor: '#099768',
      labelBackgroundOpacity: 100,
    },
    {
      isActive: true,
      aliasName: '',
      uuid: 'pop',
      text: 'Популярное',
      labelBackgroundColor: '#007AFF',
      labelBackgroundOpacity: 100,
    },
  ],

  isGroupByCategory: false,
  categoryButtonBorderRadius: 4,
  categoryButtonBorderWidth: 1,
  categoryButtonBorderColor: '#2F3236',
  categoryButtonBorderOpacity: 100,
  hasCategoryButtonBorder: true,
  categoryButtonColor: '#ffffff',
  categoryButtonOpacity: 100,
  categoryButtonFontFamily: 'Inter',
  categoryButtonTextColor: '#000000',
  categoryButtonTextOpacity: 100,
  categoryButtonFontWeight: 'Medium',
  categoryButtonFontSize: 14,
  categoryButtonFontItalic: false,
  categoryButtonTextLineThrough: false,
  categoryButtonTextUnderline: false,

  categoryActiveButtonBorderRadius: 4,
  categoryActiveButtonBorderWidth: 1,
  categoryActiveButtonBorderColor: '#0B1F36',
  categoryActiveButtonBorderOpacity: 100,
  hasCategoryActiveButtonBorder: true,
  categoryActiveButtonColor: '#2F3236',
  categoryActiveButtonOpacity: 100,
  categoryActiveButtonFontFamily: 'Inter',
  categoryActiveButtonTextColor: '#ffffff',
  categoryActiveButtonTextOpacity: 100,
  categoryActiveButtonFontWeight: 'Medium',
  categoryActiveButtonFontSize: 14,
  categoryActiveButtonFontItalic: false,
  categoryActiveButtonTextLineThrough: false,
  categoryActiveButtonTextUnderline: false,
  sortingElements: ['CARD_TITLE', 'DESCRIPTION', 'PRICE', 'BUTTON'],
};

export const FEED_DEF_MOBILE_CONFIGURATION: IFeedConfiguration = {
  ...FEED_DEF_CONFIGURATION,
  feedTitleFontSize: 16,
  cardTitleFontSize: 10,
  cardDescriptionFontSize: 10,
  cardPriceFontSize: 14,
  cardCrossedPriceFontSize: 10,
  buttonFontSize: 10,
  labelFontSize: 10,
};

export const feedSampleCards = (
  hasCrossedPrice: boolean = false,
  hasLabels = false
): TFeedCard[] => [
  {
    id: '1',
    urlLink: '',
    imgLink: 'assets/feed/product_1.png',
    name: 'Товар 1',
    description: 'Описание товара 1',
    price: 79.9,
    crossedPrice: hasCrossedPrice ? 275.5 : null,
    labels: hasLabels ? ['hit', 'new'] : [],
  },
  {
    id: '2',
    urlLink: '',
    imgLink: 'assets/feed/product_2.png',
    name: 'Товар 2',
    description: 'Описание товара 2',
    price: 269.9,
    crossedPrice: hasCrossedPrice ? 275.5 : null,
    labels: hasLabels ? ['pop', 'hit'] : [],
  },
  {
    id: '3',
    urlLink: '',
    imgLink: 'assets/feed/product_3.png',
    name: 'Товар 3',
    description: 'Описание товара 3',
    price: 229.9,
    crossedPrice: hasCrossedPrice ? 292.8 : null,
    labels: hasLabels ? ['hit'] : [],
  },
  {
    id: '4',
    urlLink: '',
    imgLink: 'assets/feed/product_4.png',
    name: 'Товар 4',
    description: 'Описание товара 4',
    price: 229.9,
    crossedPrice: hasCrossedPrice ? 258.6 : null,
    labels: hasLabels ? ['pop'] : [],
  },
];