import cx from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import styles from './style.module.css';

interface Props {
  title: ReactNode;
  aside?: ReactNode;
  menu?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const PageHeader: React.FC<Props> = ({
  title,
  aside,
  menu,
  className,
  style,
}) => {
  return (
    <>
      {menu}
      <div className={cx(styles['page-header'], { [styles['_with-menu']]: menu }, className)} style={style}>
        <div className={styles['page-header__title']}>{title}</div>
        <div className={styles['page-header__aside']}>{aside}</div>
      </div>
    </>
  );
};

export default React.memo(PageHeader);
