export const secondsToTime = (allSeconds: number) => {

  const d = Math.floor(allSeconds / (3600 * 24));
  const h = Math.floor(allSeconds % (3600 * 24) / 3600);
  const m = Math.floor(allSeconds % 3600 / 60);
  const s = Math.floor(allSeconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' день, ' : ' дней, ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' час, ' : ' часов, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' минута, ' : ' минут, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' секунда' : ' секунд') : '';

  return dDisplay + hDisplay + mDisplay + sDisplay;
};
