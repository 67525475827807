import React, { useEffect } from 'react';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import styles from './style.module.css';

interface IAdditionSelector {
  isOpenDialogManuallyAddingSelector: boolean;
  setIsOpenDialogManuallyAddingSelector: (isOpen: boolean) => void;
  setSelectorManual: (selector: string) => void;
}

const AdditionSelector: React.FC<IAdditionSelector> = ({
  isOpenDialogManuallyAddingSelector,
  setIsOpenDialogManuallyAddingSelector,
  setSelectorManual,
}) => {
  const [selector, setSelector] = React.useState('');

  const closeDialog = () => {
    setIsOpenDialogManuallyAddingSelector(false);
  };

  useEffect(() => {
    setSelector('');
  }, [isOpenDialogManuallyAddingSelector]);

  return (
    <Drawer
      visible={isOpenDialogManuallyAddingSelector}
      onClose={closeDialog}
      size={'x-small'}
      title={'Указать селектор вручную'}
    >
      <div className={styles.wrapper}>
        <FormItem>
          <Input
            value={selector}
            onChangeValue={setSelector}
            placeholder={'#root > div.main-layout'}
            block
          />
        </FormItem>
      </div>

      <DrawerFooter
        className={styles.footer}
        actions={(
          <Button
            disabled={!selector}
            onClick={async () => {
              await setSelectorManual(selector);
              closeDialog();
            }}
          >
            Сохранить
          </Button>
        )}
      />
    </Drawer>
  );
};

export default AdditionSelector;
