import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import Icon from '~/components/Icon';
import Input from '~/components/ui/Input';
import './style.css';

interface ISearchItem {
  key: number;
  value: string | React.ReactNode;
  disabled: boolean;
}

interface ISearchItemAttributes {
  key: number;
  index: number;
}

const SearchInput: React.FC<{
  onChange: (value: string) => void;
  resultSearchItem?: ISearchItem[];
  onClickSearchItem?: (itemAttributes: ISearchItemAttributes) => void;
  value?: string;
  className?: string;
  placeholder?: string;
  size?: FormElementSize;
  isOpenDropdown?: boolean;
  clickOutside?: () => void;
  scrolledToEnd?: () => void;
  failure?: boolean;
  onBlur?: (value: string) => void | PromiseLike<any>;
}> = ({
  onChange,
  resultSearchItem = [],
  onClickSearchItem = null,
  value = '',
  className = '',
  placeholder = '',
  size = 'large',
  isOpenDropdown = false,
  clickOutside = null,
  scrolledToEnd = null,
  failure = false,
  onBlur = null,
}) => {
  const dropdownRef = useRef(null);
  const handleClickOutside = () => {
    if (clickOutside) clickOutside();
  };
  useOnClickOutside(dropdownRef, handleClickOutside);

  const onScrollDropdown = () => {
    if (scrolledToEnd)
      if (
        dropdownRef.current.scrollTop ===
        dropdownRef.current.scrollHeight - dropdownRef.current.clientHeight
      )
        scrolledToEnd();
  };

  return (
    <div className={`generic-search-input__container ${className}`}>
      <Input
        value={value}
        onChangeValue={onChange}
        className="generic-search-input__input"
        placeholder={placeholder}
        elemAfter={<Icon name="Search" className="dropdown__preview-icon" />}
        size={size}
        block
        failure={failure}
        onBlur={onBlur}
      />
      <div
        ref={dropdownRef}
        className={`generic-search-input__dropdown ${
          isOpenDropdown ? '__is_visible' : ''
        }`}
        onScroll={onScrollDropdown}
      >
        {resultSearchItem.map((item, index) => {
          return (
            <div
              key={item.key}
              className={`generic-search-input-dropdown__item ${item.disabled ? '__disable' : ''}`}
              onClick={() => {
                if (onClickSearchItem && item.key !== 0 && !item.disabled)
                  onClickSearchItem({ key: item.key, index });
              }}
            >
              {item.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchInput;
