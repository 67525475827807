import React from 'react';
import { LeadsSettings } from '~/screens/LeadsSettingsScreen/LeadsSettings';
import Screen from '~/screens/Screen';

const LeadsSettingsScreen = () => {
  const title = 'Настройки заявок';

  return (
    <Screen className="leads-screen" documentTitle={title} header={title}>
      <LeadsSettings />
    </Screen>
  );
};
export default LeadsSettingsScreen;
