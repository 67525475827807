import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ApproveEmailButton from '~/components/generic/MailingAuthorizationEmailBlock/ApproveEmailButton';
import EmailListInput, {
  IEmailListInputItem,
} from '~/components/generic/MailingAuthorizationEmailBlock/EmailListInput';
import MailingAuthorizationEmailBlockStore from '~/components/generic/MailingAuthorizationEmailBlock/store';
import Message from '~/components/ui/Message';
import styles from './style.module.css';

interface IMailingAuthorizationEmailBlockProps {
  currentEmailAddress: string;
  currentEmailRegistered: boolean;
  onSetEmail: (addres: string, registered: boolean) => void;
}

const MailingAuthorizationEmailBlock: React.FC<IMailingAuthorizationEmailBlockProps> = observer(({
  currentEmailAddress,
  currentEmailRegistered,
  onSetEmail,
}) => {
  const {
    emailsList,
    isValidateEmail,
    checkEmail,
    approveEmail,
    fetchEmailSenders,
    sendingMessageData,
    setSendingMessageData,
  } = MailingAuthorizationEmailBlockStore;

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [isEmailValid, setEmailValid] = useState<boolean>(true);

  useEffect(() => {
    fetchEmailSenders();
  }, []);

  const validateEmail = (email: string) => {
    isValidateEmail(email) ? setEmailValid(true) : setEmailValid(false);
  };

  const setEmail = (address: string) => {
    onSetEmail(
      address,
      !!emailsList.find(emailData => emailData.value === address)?.registered
    );
    validateEmail(address);
  };

  const onCheckEmail = async (address: string) => {
    const registered = await checkEmail(address);
    onSetEmail(address, registered);
  };

  const onInputBlur = () => {
    validateEmail(currentEmailAddress);
  };

  const isNewEmail = () => {
    return (
      emailsList.filter(emailItem =>
        emailItem.value.startsWith(currentEmailAddress)
      ).length === 0
    );
  };

  return (
    <div className={styles.input_block}>
      <h3>Введите email, от которого будет осуществляться отправка писем</h3>
      <p className={styles.description}>
        На указанную почту придет письмо со ссылкой для подтверждения. Если
        письмо не пришло, попробуйте отправить ссылку повторно или проверьте
        папку «Спам».
      </p>
      <EmailListInput
        value={currentEmailAddress}
        onChange={(address) => {
          setEmail(address);
          setIsDropdownVisible(true);
        }}
        placeholder="hello@example.ru"
        failure={!isEmailValid}
        onBlur={onInputBlur}
        isOpenDropdown={isDropdownVisible}
        elemAfter={
          currentEmailAddress.length && (
            <span
              className={
                currentEmailRegistered
                  ? styles.authEmailListConfirmed
                  : styles.authEmailListNonConfirmed
              }
            >
              {currentEmailRegistered
                ? 'Подтвержден'
                : isNewEmail()
                ? ''
                : 'Не подтвержден'}
            </span>
          )
        }
        items={(() => {
          const items = emailsList
            .map(({ key, value, registered, status }) => {
              return {
                key: key.toString(),
                value,
                status,
                itemClassName: registered ? 'confirmed' : 'not-confirmed',
              } as IEmailListInputItem;
            })
            .filter(item => item.value.startsWith(currentEmailAddress));
          if (
            currentEmailAddress &&
            items.filter(item => item.value === currentEmailAddress)
              .length === 0
          )
            items.push({
              key: 'new',
              value: currentEmailAddress,
              status: 'Добавить',
              itemClassName: 'default',
            });

          return items;
        })()}
        setIsDropdownVisible={setIsDropdownVisible}
        onClickSearchItem={(item) => {
          setEmail(item.value);
          setIsDropdownVisible(false);
        }}
        clickOutside={() => setIsDropdownVisible(false)}
      />
      <p className={cx(styles.smallDescription, !isEmailValid && styles.error)}>
        Укажите почту на домене вашего сайта. Почты на доменах типа @gmail.com,
        @mail.ru, @yandex.ru работать не будут.
      </p>
      <div style={{ marginBottom: 20, width: '100%' }}>
        <ApproveEmailButton
          disabled={!isEmailValid || !currentEmailAddress.length}
          isEmailNew={isNewEmail()}
          authorizationEmailObj={{
            address: currentEmailAddress,
            registered: currentEmailRegistered,
          }}
          checkEmail={onCheckEmail}
          approveEmail={approveEmail}
        />
      </div>
      {sendingMessageData.isShow && (
        <Message
          visible
          applyText={'Хорошо'}
          onApply={() => setSendingMessageData({
            isShow: false,
            title: '',
            message: '',
          })}
          title={sendingMessageData.title}
          textTop={sendingMessageData.message}
          showIcon={false}
        />
      )}
    </div>
  );
});

export default MailingAuthorizationEmailBlock;
