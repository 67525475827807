import { action } from 'mobx';
import Model from '~/models/Model';
import {
  BusinessRule,
  BusinessRuleEntity,
  FeedForBusinessRule,
  MetaBlock,
} from '~/screens/BusinessRulesScreen/types';

export default class BusinessRulesModel extends Model<BusinessRule> {
  @action
  setFeedParameters = (feedId: string) => {
    const currentFeed: FeedForBusinessRule = this.parent.feedForBusinessRule.find(
      (feed: FeedForBusinessRule) => feed.feedId === feedId
    );
    this.update({
      feedId: currentFeed.feedId,
      ymlUrl: currentFeed.ymlUrl,
      feedName: currentFeed.feedName,
    });
    this.parent.getBusinessRulesByFeed(feedId);  
  }

  @action
  setRecommendationForType = (key: string) => {
    this.get('recommendationFor').type = key;
  }

  @action
  setRecommendationForEntities = (entities: BusinessRuleEntity) => {
    this.get('recommendationFor').entities = entities;
  }

  @action
  setRecommendationType = (key: string) => {
    this.update({
      recommendationType: key,
    });
  }

  @action
  setRecommendationOfWhatKey = (key: string) => {
    this.get('recommendationOfWhat').type = key;
  }

  @action
  setRecommendationOfWhat = (
    index: number,
    entities: BusinessRuleEntity
  ) => {
    this.get('recommendationOfWhat').entities[index] = entities;
  }

  @action
  setRuleName = (ruleName: string) => this.update({ ruleName })

  @action
  addRecommendationOfWhatEntity = () => {
    this.get('recommendationOfWhat').entities.push({
      '@type': 'FilterBusinessRuleEntity',
      'id': null,
      'name': null,
      'uiText': '',
      'itemCount': 1,
    });
  }

  @action
  addSimilarityType = () => {
    const freeSimilarityTypeList = this.parent.metadata.similarityTypeDict
      .map((similarityTypeDict: MetaBlock) => similarityTypeDict.key)
      .filter((similarityType: string) => !this.get('similarityType').includes(similarityType));
    this.get('similarityType').push(freeSimilarityTypeList[0]);
  }

  @action
  changeSimilarityType = (index: number, similarityType: string) => {
    this.get('similarityType')[index] = similarityType;
  }

  @action
  deleteEntity = (index: number) => {
    this.get('recommendationOfWhat').entities.splice(index, 1);
  }

  @action
  setEntityCount = (index: number, value: number) => {
    this.get('recommendationOfWhat').entities[index].itemCount = value;
  }

  @action
  deleteParameter = (index: number) => {
    this.get('similarityType').splice(index, 1);
  }
}
