import React from 'react';
import Icon, { Icons } from '~/components/Icon';
import Button from '~/components/ui/Button';
import styles from '~/screens/QuizScreen/components/QuizStartScreen/style.module.css';
import { header, subheader } from '~/screens/QuizScreen/consts';

interface IQuizPossibilities {
  name: string;
  onClick?: () => void;
  icon?: Icons;
  loading?: boolean;
}

export const QuizPossibilities = ({
  name,
  onClick = () => { return; },
  icon,
  loading,
}: IQuizPossibilities) => (
  <div className={styles.sector}>
    <div className={styles.left}>
      <div className={styles.header}>{header}</div>
      <div className={styles.subheader}>{subheader}</div>
      <img
        src="/assets/images/quiz/promoPage/smallRectangle.png"
        alt="smallRectangle"
        className={styles.smallRectangleImg}
      />
      <div className={styles.smallHeader}>На что способен квиз</div>
      <div className={styles.possibilities}>
        <div className={styles.leftPossibilities}>
          <img
            src="/assets/images/quiz/promoPage/5.png"
            alt="до 5 раз"
            className={styles.leftPossibilitiesImage}
          />
          Увеличит количество заявок и повысит ваши продажи
        </div>
        <div className={styles.rightPossibilities}>
          <div className={styles.rightPossibility}>
            <div className={styles.possibilityIconWrap}>
              <Icon name="User" className={styles.possibilityIcon} />
            </div>
            <div className={styles.possibilityText}>
              Шаблоны и библиотека заготовленных вопросов с готовыми ответами
            </div>
          </div>
          <div className={styles.rightPossibility}>
            <div className={styles.possibilityIconWrap}>
              <Icon name="PhoneCheck" className={styles.possibilityIcon} />
            </div>
            <div className={styles.possibilityText}>
              Позволит в игровой форме привлечь и удержать посетителей на сайте
            </div>
          </div>
        </div>
      </div>
      <Button
        icon={icon}
        loading={loading}
        className={styles.button}
        onClick={onClick}
      >
        {name}
      </Button>
    </div>
    <div className={styles.right}>
      <img
        className={styles.bigRectangle}
        src="/assets/images/quiz/promoPage/bigRectangle.png"
        alt="bigRectangle"
      />
      <img
        className={styles.promo}
        src="/assets/images/quiz/promoPage/promo.png"
        alt="promo"
      />
    </div>
  </div>
);
