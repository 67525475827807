import { AxiosPromise } from 'axios';
import { actionGet } from './client';

export type UiSwitchResponse = IResponse<[{data: TSwitchCode[]}]>;

const uiSwitch = {
  fetchSwitcher: (): AxiosPromise<UiSwitchResponse> => actionGet('/admin/uiSwitch', {}),
};

export default uiSwitch;
