import React from 'react';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';

export const SberCRMInstructions = () => {
  return (
    <>
      <SettingsItem
        Content={(
          <div className={'simple-text'}>
            Нажмите в меню: Настройки  Менеджер
            <br /> объектов
            {' >'} Новый объект.
          </div>
          )}
        title={
          'Создайте в SberCRM объект, который содержит поля для загрузки данных с виджета:'
        }
        number={1}
      />
      <SettingsItem
        Content={(
          <ol>
            <li>Нажмите в меню: Настройки {'>'} Настройка интеграции {'>'} Новая интеграция</li>
            <li>
              В поле Сущность выберите объект, который создали на первом шаге.</li>
          </ol>
        )}
        title={
          'Создайте интеграцию с объектом:'
        }
        number={2}
      />
    </>
  );
};
