import { IPromo } from '~/components/generic/Promo';

export const items = [
  {
    iconSrc: 'DemoPro',
    text: 'Создание рекомендаций займет не более пяти минут',
  },
  {
    iconSrc: 'DemoPro',
    text: 'Выберите тип рекомендаций',
  },
  {
    iconSrc: 'DemoPro',
    text: 'Укажите ссылку на ваш каталог товаров',
  },
];

export const promoMaterials: Partial<IPromo> = {
  header: 'Интегрируйте рекомендательную систему с помощью API',
  subheader:
    'Здесь вы можете создать рекомендации, получить индивидуальный ключ API и инструкцию по настройке',
  img: '/assets/images/promo/recommendationsApi.png',
  items,
  text1: 'Настройка ленты займет не более пяти минут',
};
