import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import './style.css';

export interface CheckboxProps {
  disabled?: boolean;
  size?: 'medium' | 'small';
  checked: boolean;
  onCheck: (checked: boolean) => void;
  name?: string;
  label?: React.ReactNode;
  className?: string;
  color?: string;
  hint?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  disabled,
  size = 'medium',
  checked,
  onCheck,
  name,
  label,
  className,
  color,
  hint = null,
}) => {
  const icon = size === 'medium' ? 'CheckmarkM' : 'CheckmarkS';
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => onCheck(e.target.checked);

  return (
    <div
      className={cx('checkbox', {
        _checked: checked,
        [`_size-${size}`]: size,
        _disabled: disabled,
      }, className)}
    >
      <label
        className="checkbox__label"
      >
        <input
          className="checkbox__input"
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleCheck}
        />
        <span className="checkbox__square" style={{ backgroundColor: color, borderColor: color }} />
        <span className="checkbox__icon">
          <Icon className="checkbox__svg" name={icon} />
        </span>
        {label}
        {!!hint && (
          <IconWithTooltip
            tooltipProps={{
              placement: 'bottomRight',
              title: hint,
              width: '340px',
            }}
            iconProps={{
              name: 'Question',
            }}
          />
        )}
      </label>
    </div>
  );
};

export default Checkbox;

