import React from 'react';
import { NotificationProps } from '~/components/Notification';
import BorderRadiusSelector from '~/components/notification-editor/BorderRadiusSelector';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import ItemAlignment from '~/components/notification-editor/RadioGrid/ItemAlignment';
import ItemPosition from '~/components/notification-editor/RadioGrid/ItemPosition';
import NotificationOrientation from '~/components/notification-editor/RadioGroupWrapper/NotificationOrientation';
import NotificationType from '~/components/notification-editor/RadioGroupWrapper/NotificationType';
import NotificationElements from '~/components/ui/CheckGroup/NotificationElements';
import FormItem from '~/components/ui/FormItem';
import '../style.css';

interface IGeneralEditor {
  notificationParams: NotificationProps;
  onChangeNotificationParams: (params: NotificationProps) => void;
}

/**
 * 
 * @param {NotificationProps} notificationParams Параметры виджета
 * @param {function} onChangeNotificationParams Функция изменения параметров виджета.
 *
 * @return
 */
export const GeneralEditor: React.FC<IGeneralEditor> = ({
  notificationParams,
  onChangeNotificationParams,
}: IGeneralEditor): JSX.Element => {
  return (
    <>
      <section className="scenario-editor-sidebar__section">
        <NotificationType
          onChange={(isMobile) => {
            onChangeNotificationParams({
              ...notificationParams,
              isMobile,
            });
          }}
          isMobile={notificationParams.isMobile}
          isMobileConst={notificationParams.isMobileConst}
        />
      </section>

      {!notificationParams.isMobile && (
        <section className="scenario-editor-sidebar__section">
          <NotificationOrientation
            onChange={(isHorizontal) => {
              onChangeNotificationParams({
                ...notificationParams,
                isHorizontal,
              });
            }}
            isHorizontal={notificationParams.isHorizontal}
            hint={(
              <>
                <p>Здесь вы можете выбрать ориентацию виджета, нажав на
                соответствующую надпись внизу.</p>
                <p>Вертикальная - книжная ориентация, когда виджет вытянут в
                высоту.</p>
                <p>Горизонтальная - альбомная ориентация, когда виджет вытянут в
                длину.</p>
                <p>Макет слева отображает, как будет выглядеть виджет в зависимости
                от выбранной ориентации.</p>
              </>
            )}
          />
        </section>
      )}

      <section className="scenario-editor-sidebar__section">
        <ItemAlignment
          align={notificationParams.alignment}
          onSelect={(alignment) => {
            onChangeNotificationParams({
              ...notificationParams,
              alignment,
            });
          }}
          showTopRow={!notificationParams.isHorizontal || notificationParams.isMobile}
          showCenterRow={notificationParams.isHorizontal && !notificationParams.isMobile}
          showBottomRow={!notificationParams.isHorizontal || notificationParams.isMobile}
          hint="Тут вы можете выбрать в какой части виджета будут располагаться все его элементы (заголовок, дополнительный текст, промокод, срок действия, кнопка), нажав на соответствующий квадрат ниже. Макет слева отображает, как будет выглядеть виджет в зависимости от выбранного выравнивания."
        />
      </section>

      {!notificationParams.isMobile && (
        <section className="scenario-editor-sidebar__section">
          <ItemPosition
            title="Расположение на экране"
            hint="Тут вы можете выбрать в какой части экрана на вашем сайте будет появляться виджет, нажав на соответствующий квадрат внизу. Так как изменения касаются расположения виджета на сайте, а не его внешнего вида, в макете слева ничего не поменяется."
            align={notificationParams.position}
            onSelect={(position: INotificationAlignment) => {
              onChangeNotificationParams({ ...notificationParams, position });
            }}
          />
        </section>
      )}

      <section className="scenario-editor-sidebar__section">
        <FormItem
          title="Цвет фона"
          hint="Укажите HEX-кодировку цвета или выберите его на палитре. В процентах укажите непрозрачность цвета (значение должно быть кратно 10-ти). Макет слева отображает, как будет выглядеть виджет в зависимости от выбранных значений."
          noReservedPlaceForMessage
          noHTMLLabel
        >
          <ColorPicker
            color={notificationParams.backgroundColor}
            alpha={notificationParams.opacity * 100}
            onChange={({ color, alpha }) => {
              onChangeNotificationParams({
                ...notificationParams,
                backgroundColor: color,
                opacity: alpha / 100,
              });
            }}
          />
        </FormItem>
      </section>

      <section className="scenario-editor-sidebar__section">
        <BorderRadiusSelector
          borderRadius={notificationParams.borderRadius}
          onChangeValue={(borderRadius) => {
            onChangeNotificationParams({ ...notificationParams, borderRadius });
          }}
          hint="Здесь вы можете поменять степень cкругления углов виджета. Значение вводится в окно ниже (не больше 32-ух, доступны только целые числа). Если скругление углов будет равно нулю, то углы у виджета будут квадратные. Макет слева отображает, как будет выглядеть виджет в зависимости от выбранного значения скругления углов."
        />
      </section>

      <section className="scenario-editor-sidebar__section">
        <NotificationElements
          checkedItems={{
            hasImage: notificationParams.hasImage,
            hasMessage: notificationParams.hasMessage,
            hasCoupon: notificationParams.hasCoupon,
            hasExpirationDate: notificationParams.hasExpirationDate,
          }}
          onCheck={(state) => {
            onChangeNotificationParams({ ...notificationParams, ...state });
          }}
          disabledItems={
            notificationParams.hasPrivacyPolicy
              ? ['hasCoupon', 'hasExpirationDate']
              : []
          }
          hint="В этом блоке вы можете добавить/убрать элементы виджета. Чтоб добавить или убрать элемент, нажмите на квадрат рядом с наименованием элемента. Макет слева отображает, как будет выглядеть виджет с текущим составом элементов."
        />
      </section>
    </>
  );
};