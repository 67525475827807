import { Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from '~/components/Loading';
import ChartGauge from '~/components/charts/ChartGauge/index';
import NoData from '~/components/charts/NoData';
import { Header } from '~/components/generic/Header';
import Button from '~/components/ui/Button';
import DateRangePicker from '~/components/ui/DateRangePicker';
import Link from '~/components/ui/Link';
import Message from '~/components/ui/Message';
import DatePresetSwitch from '~/components/ui/TabSwitch/DatePresetSwitch';
import Toolbar from '~/components/ui/Toolbar';
import { DATE_FORMAT, TARIFF_TAB } from '~/constants';
import { EmptyScreen } from '~/screens/DeadZoneScreen/EmptyScreen';
import { Instructions } from '~/screens/DeadZoneScreen/EmptyScreen/Instructions';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { MediaQueriesStore } from '~/stores/mediaQueriesStore';
import DeadZoneStore from './store';
import './style.css';

interface Props extends RouteComponentProps {
  mediaQueriesStore?: MediaQueriesStore;
  authStore?: AuthStore;
}

const { Text } = Typography;

@inject('mediaQueriesStore', 'authStore')
@observer
class DeadZoneScreen extends Component<
  Props,
  { isNotScriptWork: boolean; isInstructionsShown: boolean }
> {
  store: DeadZoneStore = new DeadZoneStore();

  constructor(props: Props) {
    super(props);
    this.state = { isNotScriptWork: false, isInstructionsShown: false };
    const clientState = this.props.authStore?.clientState;
    if (
      clientState === 'CODE_NOT_SET' ||
      clientState === 'USER_PROFILE_NOT_FILLED'
    ) {
      this.state = { isNotScriptWork: true, isInstructionsShown: false };
    }
  }

  componentWillUnmount() {
    this.store.destroy();
  }

  handleChangeDate = (dates: [Moment, Moment]) => {
    this.store.setCustomRange(
      dates[0].format(DATE_FORMAT),
      dates[1].format(DATE_FORMAT)
    );
  }

  renderToolbar = () => {
    const { setRangePreset, preset, dateFrom, dateTo } = this.store;

    const presetSwitch = (
      <DatePresetSwitch
        key="presets"
        onChange={setRangePreset}
        value={preset}
      />
    );

    const rangePicker = (
      <DateRangePicker
        filled={preset === 'custom'}
        key="dates"
        value={[moment(dateFrom, DATE_FORMAT), moment(dateTo, DATE_FORMAT)]}
        onChange={this.handleChangeDate}
      />
    );

    return <Toolbar left={[presetSwitch, rangePicker]} />;
  }

  renderWidgets = () => {
    const { deadZoneData } = this.store;

    return (
      <>
        <div className="general-indicators">
          <div className="chart-gauge__wrapper">
            <ChartGauge
              percent={deadZoneData.percentageComparison}
              tooltipTitle={
                'Процент слепых зон на сайте. ' +
                'Считается, как отношение непосещаемых страниц к общему количеству страниц сайта.'
              }
            />
          </div>
          <div className="general-indicators__wrapper">
            <Text className="general-indicators__digital-text">
              {deadZoneData.blindPagesNumber}
            </Text>
            <Text
              className="general-indicators__description-text"
              children={
                'количество страниц, которые не посещались за выбранный период'
              }
            />
          </div>
          <div className="general-indicators__wrapper">
            <Text className="general-indicators__digital-text">
              {deadZoneData.allPagesNumber}
            </Text>
            <Text
              className="general-indicators__description-text"
              children={
                'всего страниц сайта, которые были посчитаны через sitemap '
              }
            />
          </div>
        </div>
        {/* Новые страницы за этот период */}
        <div className="pages">
          <div className="pages__wrapper">
            <div className="pages__title-text-wrapper">
              <Text
                className="pages__title-text"
                children={'Новые страницы за этот период'}
              />
            </div>
            {/*FIXME по результатам уточнения у Елены Калашниковой количество дней пока не показываем*/}
            {/*<div className="pages__time">*/}
            {/*  <Text className="pages__time-text" children={'Дней без посещений'} />*/}
            {/*  <div className="pages__time__dead-zone-screen__hint">*/}
            {/*    <Tooltip*/}
            {/*      placement="topLeft"*/}
            {/*      title={(*/}
            {/*        <div className="dead-zone-screen__tooltip-text-wrapper">*/}
            {/*          Количество дней подряд, в течение которых ни один пользователь не посетил указанную страницу*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      <Icon name="Question" className="dead-zone__hint-icon" />*/}
            {/*    </Tooltip>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          {deadZoneData.blindPages?.length > 0 ? (
            deadZoneData.blindPages.map((value, index) => {
              return (
                <div
                  className="pages__block-wrapper"
                  key={`pages__block-wrapper - ${index} - ${value}`}
                >
                  <div className="pages__title-row-wrapper">
                    {/*FIXME это нужно удалить но это не точно*/}
                    {/*<Text className="pages__time-text" children={value.nameLink} />*/}
                    <Link
                      className="pages__link-text"
                      to={`${value}`}
                    >{`${value}`}</Link>
                  </div>
                  {/*FIXME по результатам уточнения у Елены Калашниковой количество дней пока не показываем*/}
                  {/*<div className="pages__time">*/}
                  {/*  <Text className="pages__time-text" children={value.time} />*/}
                  {/*  <Text className="pages__time-text" children={value.timeFormat} />*/}
                  {/*</div>*/}
                </div>
              );
            })
          ) : (
            <div>
              <Text
                className="empty-block__text-style"
                children={'Нет новых страниц'}
              />
            </div>
          )}
        </div>
        <Button
          className={'dead-zone-screen__button-show-instruction'}
          onClick={() => this.setState({ isInstructionsShown: true })}>
          Инструкция подключения Sitemap
        </Button>
      </>
    );
  }

  redirectToSettings = () => {
    const { history } = this.props;
    history.push(`/settings?tab=${TARIFF_TAB}`);
  }

  render() {
    const { deadZoneData, isDeadZoneData, requestStatus, getReport } =
      this.store;

    const hideErrorMessage = () => this.setState({ isNotScriptWork: false });

    return (
      <>
        {!deadZoneData?.isSuccessful && (
          <EmptyScreen
            showInstructions={() =>
              this.setState({ isInstructionsShown: true })
            }
          />
        )}
        {deadZoneData?.isSuccessful && (
          <Screen className="dead-zone-screen">
            <Header
              header={'Слепые зоны'}
              subheader={(
                <>
                  <p>
                    Инструмент определит и покажет страницы сайта, на которые
                    посетители не заходили за определённый промежуток времени.
                  </p>

                  <p>
                    Вы сможете повысить эффективность сайта с помощью наших
                    инструментов, направляя клиентов на нужные страницы. Также
                    можете улучшить навигацию сайта и удалить ненужные разделы
                    для ускорения загрузки и снижения затрат на поддержку.
                  </p>
                </>
                )}
            />
            {this.renderToolbar()}
            <Loading status={requestStatus} onClick={getReport} />
            {/* данные для отображения есть */}
            {isDeadZoneData &&
              requestStatus === 'Success' &&
              deadZoneData?.isSuccessful &&
              this.renderWidgets()}
            {/* данных для отображения нет */}
            {!isDeadZoneData && requestStatus !== 'Error' && (
              <div className="empty-block">
                <Text
                  className="empty-block__text-style"
                  children={'Нет слепых зон'}
                />
              </div>
            )}
            {/*{ нет данных для составления отчета }*/}
            {!deadZoneData?.isSuccessful && <NoData />}
            {this.state.isNotScriptWork && (
              <Message
                visible={this.state.isNotScriptWork}
                className="scenarios-screen-list__msg-script-exception"
                title={(
                  <span>
                    Если не подключить сайт, этот <br /> раздел не сможет
                    работать 🙄
                  </span>
                  )}
                textTop="Подключайте сайт и пользуйтесь на здоровье!"
                applyText="Подключить сайт"
                cancelText="Закрыть"
                showLine={false}
                onApply={this.redirectToSettings}
                onClose={hideErrorMessage}
                onExit={hideErrorMessage}
              />
            )}
          </Screen>
        )}
        {this.state.isInstructionsShown && (
          <Instructions
            isInstructionsShown={this.state.isInstructionsShown}
            hideInstructions={() =>
              this.setState({ isInstructionsShown: false })
            }
          />
        )}
      </>
    );
  }
}

export default DeadZoneScreen;
