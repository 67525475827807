import cx from 'classnames';
import { quiz } from 'feed';
import React, { useEffect, useRef, useState } from 'react';
import { HiddenText } from '~/components/generic/HiddenText';
import SupportingButton from '~/components/generic/SupportingButton';
import ContextButton from '~/components/generic/SupportingButton/ContextButton';
import ContextMenu from '~/components/generic/SupportingButton/ContextMenu';
import Link from '~/components/ui/Link';
import { CommonStatistic } from '~/screens/QuizScreen/components/CommonStatistic';
import { className } from '~/screens/QuizScreen/components/QuizList';
import { StatusButton } from '~/screens/QuizScreen/components/QuizList/QuizItem/StatusButton';
import { IQuizItem, IStatistic } from '~/screens/QuizScreen/stores/quizStore';
import { getQuizShownConfiguration, getQuizShownSteps } from '~/screens/QuizScreen/utils';
import styles from './style.module.css';

const { QuizCreator } = quiz;


// TODO вынести отдельно
type TKeyStatistic = 'shown' | 'iterations' | 'finished';

export type TStatisticFromBack = {
  [key in TKeyStatistic]: {
    count: number;
    percent: number;
  };
};

export const getStatistic = (statistics: TStatisticFromBack): IStatistic[] => {
  const keys = Object.keys(statistics);

  const names = {
    shown: 'Показы',
    iterations: 'Взаимодействия',
    finished: 'Пройдены',
  };

  const hints = {
    shown: 'Столько раз посетители вашего сайта видели квиз, % рассчитывается от общего посещения сайта',
    iterations: 'Столько раз посетители вашего сайта начинали квиз, % рассчитывается от показов квиза',
    finished: 'Столько раз посетители вашего сайта прошли квиз, % рассчитывается от показов квиза',
  };

  return keys.map((key: TKeyStatistic) => {
    return {
      name: names[key],
      percent: statistics[key].percent,
      count: statistics[key].count,
      hint: hints[key],
    };
  });
};

interface IQuizCallbacks {
  openDrawer: (v: string) => void;
  deleteQuiz: (v: string) => void;
  copyQuiz: (v: string) => void;
  openConstructor: (v: string) => void;
}

export const QuizItem = ({
  quizId,
  quizName,
  creationDate,
  status,
  steps,
  configuration,
  statistics,
  openDrawer,
  deleteQuiz,
  copyQuiz,
  openConstructor,
}: IQuizItem & IQuizCallbacks) => {
  const quizPreview = useRef();

  useEffect(() => {

    if (! steps.length) return;

    const quiz = new QuizCreator(
      quizId,
      getQuizShownSteps(steps),
      getQuizShownConfiguration(configuration),
      false
    );
    quiz.quizStyle.addStyles();

    if (quizPreview.current) {
      // @ts-ignore
      quizPreview?.current?.append(quiz.compileQuizElements());
    }
  }, [quizPreview]);

  const [isVisibleContext, setIsVisibleContext] = useState<boolean>();

  const convertedStatistic = getStatistic(
    statistics as unknown as TStatisticFromBack // TODO typings
  );

  return (
    <div className={styles.quizItemWrap}>
      <div className={cx(styles.quizItem, styles[className[status]])}>
        <div className={styles.statusBlock}>
          <div className={styles.date}>{creationDate}</div>
          <div className={styles.name}>
            <HiddenText>{quizName}</HiddenText>
          </div>
          <StatusButton status={status} quizId={quizId} />
        </div>
        <div className={styles.previewWrap}>
          <div className={styles.preview} ref={quizPreview} />
        </div>
        <CommonStatistic statistics={convertedStatistic} />
        <div className={styles.more}>
          <SupportingButton
            className={styles.moreButton}
            isVisibleContext={isVisibleContext}
            onClick={() => setIsVisibleContext(true)}
            overlay={(
              <ContextMenu
                buttons={(
                  <>
                    <ContextButton
                      callbackArgument={quizId}
                      icon="Pencil"
                      name="Редактировать"
                      callback={(quizId) => {
                        openConstructor(quizId as string);
                        setIsVisibleContext(false);
                      }}
                      visible
                    />
                    <ContextButton
                      callbackArgument={quizId}
                      icon="Rename"
                      name="Переименовать"
                      callback={(quizId) => {
                        openDrawer(quizId as string);
                        setIsVisibleContext(false);
                      }}
                      visible
                    />
                    <ContextButton
                      callbackArgument={quizId}
                      icon="Copy"
                      name="Дублировать"
                      callback={(quizId) => {
                        copyQuiz(quizId as string);
                        setIsVisibleContext(false);
                      }}
                      visible
                    />
                    <ContextButton
                      callbackArgument={quizId}
                      color="red"
                      icon="Trash"
                      name="Удалить"
                      callback={(quizId) => {
                        deleteQuiz(quizId as string);
                        setIsVisibleContext(false);
                      }}
                      visible
                    />
                  </>
                )}
                onClickOutside={() => setIsVisibleContext(false)}
              />
            )}
          />
          <Link to={`/quiz/statistic/common/${quizId}`} className={styles.moreText}>
            Подробная <br /> статистика
          </Link>
        </div>
      </div>
    </div>
  );
};
