import cx from 'classnames';
import { TFeedCartLabel } from 'feed';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { feedSampleCards, FEED_DEF_CONFIGURATION, FEED_DEF_MOBILE_CONFIGURATION } from '~/components/Feed/constants';
import StepManual from '~/components/generic/StepManual';
import StepSelectorAndDesign from '~/components/generic/StepSelectorAndDesign';
import FooterButton from '~/components/generic/StepSelectorAndDesign/FooterButton';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Tabs from '~/components/ui/Menu/Tabs';
import Spinner from '~/components/ui/Spinner';
import StepCatalog from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog';
import StepFeedType from '~/screens/FeedProScreen/components/FeedProEditor/StepFeedType';
import StepPlacementPages from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import { checkOnHttp } from '~/utils/validationUtils/checkOnHttp';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';
import './style.css';

const FeedProEditor: React.FC = observer(() => {
  const { feedProStore } = useStores() as { feedProStore: FeedProStore };
  const [display, setDisplay] = useState<'flex' | 'none'>('none');
  const [contentHeight, setContentHeight] = useState('0');
  const [errorMessageOnCatalogPage, setErrorMessageOnCatalogtPage] = useState('');
  const containerRef = useRef<HTMLDivElement>();
  const {
    isConstructorOpen,
    setIsConstructorOpen,
    getFeedProStatistics,
    setIsMessageBeforeExitingOpen,
    isIFrameAccessible,
    setIsIFrameAccessible,
    isPlacementPageInvalid,
    isConfigLinkInvalid,
    setIsConfigLinkInvalid,
    hasClickedOnInput,
    setHasClickedOnInput,
    isRechoosingSelector,
    setIsRechoosingSelector,
    isRechoosingSelectorMobile,
    setIsRechoosingSelectorMobile,
    device,
    setDevice,
    feedTitle,
    isConfigInvalid,
    feed,
    isFetchingFeed,
    recommendationTypes,
    fetchSelector,
    setSelectorManual,
    lastSavedConfiguration,
    setLastSavedConfiguration,
    mapFeedRecTypeName,
    constructorStore,
    isOpenDialogSettingsById,
    setIsOpenDialogSettingsById,
    blinkFinalDialog,
    labelMaxCountLimit,
    setIsShowTabooTypeChangesMessage,
  } = feedProStore;
  const {
    currentStep,
    changeCurrentStep,
    changeCurrentStepNextOrBack,
  } = constructorStore;

  const isChanging = !!feed.get('id');
  const [previousYml, setPreviousYml] = useState(feed?.get('ymlUrl'));
  const [mounted, setMounted] = useState(false);
  const [isSwitchFrameButton, setIsSwitchFrameButton] = useState(false);
  const [
    isOpenDialogManuallyAddingSelector,
    setIsOpenDialogManuallyAddingSelector,
  ] = useState<boolean>(false);

  const handleClickNext = () => changeCurrentStepNextOrBack(1);
  const handleBackClick = () => changeCurrentStepNextOrBack(-1);
  
  const handleResize = () => {
    setDisplay('none');
    const height = containerRef?.current.getBoundingClientRect().height;
    setDisplay('flex');
    setContentHeight(`${height}px`);
  };

  useEffect(() => {
    if (!mounted && feed?.get('ymlUrl').length > 0) {
      validateStep3();
      setMounted(true);
      setPreviousYml(feed?.get('ymlUrl'));
    }
  }, [feed?.get('ymlUrl')]);

  useEffect(() => {
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 400);
    window.addEventListener('resize', debouncedHandleResize);
    isConfigInvalid && changeCurrentStep('StepCatalog');

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    containerRef.current.scrollTop = 0;
  }, [currentStep]);

  const isButtonDisabled =
    currentStep === 'StepFeedType'
      ? !feed.get('recType')
      : currentStep === 'StepPlacementPages'
        ? !feed.get('urlPattern') || isPlacementPageInvalid
        : currentStep === 'StepCatalog'
          ? !feed.get('ymlUrl') || isConfigLinkInvalid || isConfigInvalid
          : currentStep === 'StepSelector'
            ? !(feed.get('selector') || feed.get('selectorMobile'))
            : false;

  const validateStep3 = () => {
    const ymlUrl = feed.get('ymlUrl');

    const isValidUrlPattern = !isValidUrl(ymlUrl) ||
      !checkOnHttp(ymlUrl) ||
      ymlUrl.includes(' ');

    if (isValidUrlPattern) {
      setErrorMessageOnCatalogtPage('Указанная ссылка некорректна');
    } else {
      setErrorMessageOnCatalogtPage(' ');
    }

    setIsConfigLinkInvalid(isValidUrlPattern);
  };

  const onBlur = () => {
    isConfigInvalid && !hasClickedOnInput && setHasClickedOnInput(true);
    if (currentStep === 'StepCatalog') {
      validateStep3();
    }
  };

  const handleSave = async () => {
    feed.setConfiguration({
      ...(
        device === 'mobile' 
        ? FEED_DEF_MOBILE_CONFIGURATION
        : FEED_DEF_CONFIGURATION
      ),
      ...feed.get('configuration'),
    });
    await feed.saveFeedPro();
    setIsConstructorOpen(false);
    blinkFinalDialog();
    await getFeedProStatistics();
  };

  const handleFixYml = async () => {
    try {
      const currentYml = feed.get('ymlUrl');
      previousYml !== currentYml
        ? await handleSave()
        : await feed.fixYml();
      setIsConstructorOpen(false);
      await getFeedProStatistics();
    } catch (e) {
      throw e;
    }
  };

  const chooseCard = (recType: RecType, recTitle: string): void => {
    if (!feed.get('id')) {
      feed.setRecType(recType);
      if (recType === 'POPULAR') {
        feed.setRecommendationGenerationType('OVER_ALL');
      } else {
        feed.setRecommendationGenerationType('BY_OFFER');
      }
      if (!isChanging && !feed.get('configuration')?.feedTitle) {
        feed.setConfiguration({
          ...feed.get('configuration'),
          feedTitle: recTitle,
        });
      }

      return;
    }
    setIsShowTabooTypeChangesMessage(true);
  };

  const shownTitle = feedTitle ? `«${feedTitle}»` : '';

  const onChangeYml = (ymlUrl: string) => {
    feed.setYmlUrl(ymlUrl);
    validateStep3();
  };

  return (
    <Drawer
      visible={isConstructorOpen}
      onClose={() => {
        feed?.hasDataChange()
          ? setIsMessageBeforeExitingOpen(true)
          : setIsConstructorOpen(false);
      }}
      title={
        isChanging
          ? `Редактирование ленты ${shownTitle}`
          : 'Создание ленты рекомендаций'
      }
      size="large"
      className={cx('feed-screen__drawer', {
        _center: isFetchingFeed,
      })}
    >
      {isFetchingFeed && <Spinner />}
      {!isFetchingFeed && (
        <>
          <Tabs
            items={[
              { key: 'StepFeedType', title: '1. Тип ленты', disabled: isConfigInvalid },
              {
                key: 'StepPlacementPages',
                title: '2. Страницы размещения',
                disabled: !feed.get('recType') || isConfigInvalid,
              },
              {
                key: 'StepCatalog',
                title: '3. Каталог',
                disabled:
                  (currentStep === 'StepPlacementPages' && isPlacementPageInvalid) ||
                  !feed.get('recType') ||
                  !feed.get('urlPattern') || isConfigInvalid,
                isInvalid: isConfigInvalid,
              },
              {
                key: 'StepManual',
                title: '4. Продажи',
                disabled:
                  isPlacementPageInvalid ||
                  isConfigLinkInvalid ||
                  !feed.get('recType') ||
                  !feed.get('urlPattern') ||
                  !feed.get('ymlUrl') ||
                  isConfigInvalid,
              },
              {
                key: 'StepSelector',
                title: '5. Расположение',
                disabled:
                  isPlacementPageInvalid ||
                  isConfigLinkInvalid ||
                  !feed.get('recType') ||
                  !feed.get('urlPattern') ||
                  !feed.get('ymlUrl') ||
                  isConfigInvalid,
              },
              {
                key: 'StepDesign',
                title: '6. Дизайн',
                disabled:
                  isPlacementPageInvalid ||
                  isConfigLinkInvalid ||
                  !feed.get('recType') ||
                  !feed.get('urlPattern') ||
                  !feed.get('ymlUrl') ||
                  !(feed.get('selector') || feed.get('selectorMobile')) ||
                  isConfigInvalid,
              },
            ]}
            value={currentStep.toString()}
            onChange={activeKey => changeCurrentStep(activeKey as EditorSteps)}
          />
          <div
            ref={containerRef}
            className={cx('feed-screen__container', {
              _iframe: currentStep === 'StepSelector' || currentStep === 'StepDesign',
              background_gray: currentStep === 'StepManual',
            })}
          >
            <div
              style={{
                display,
                height: contentHeight,
              }}
              className={cx('feed-screen__wrapper', {
                _iframe: currentStep === 'StepSelector' || currentStep === 'StepDesign',
              })}
            >
              {currentStep === 'StepFeedType' && recommendationTypes && (
                <StepFeedType
                  chosenCard={feed.get('recType')}
                  chooseCard={chooseCard}
                  recommendationTypes={recommendationTypes}
                />
              )}
              {currentStep === 'StepPlacementPages' && (
                <StepPlacementPages
                  isOpenDialogSettingsById={isOpenDialogSettingsById}
                  setIsOpenDialogSettingsById={setIsOpenDialogSettingsById}
                />
              )}
              {currentStep === 'StepCatalog' && (
                <StepCatalog
                  value={feed.get('ymlUrl')}
                  errorDesc={feed.get('errorDesc')}
                  onChange={onChangeYml}
                  isInvalid={isConfigLinkInvalid}
                  onBlur={onBlur}
                  isConfigInvalid={isConfigInvalid}
                  hasClickedOnInput={hasClickedOnInput}
                  configLinkError={errorMessageOnCatalogPage}
                  feed={feed}
                  labelMaxCountLimit={labelMaxCountLimit}
                />
              )}
              {currentStep === 'StepManual' && (
                <StepManual />
              )}
              {(currentStep === 'StepSelector' || currentStep === 'StepDesign') && (
                <StepSelectorAndDesign
                  id={feed.get('id')}
                  urlPattern={feed.get('urlPattern')}
                  feedTypeName={mapFeedRecTypeName.get(feed.get('recType') as RecType)}
                  selector={feed.get('selector')}
                  setSelector={feed.setSelector}
                  selectorMobile={feed.get('selectorMobile')}
                  setSelectorMobile={feed.setSelectorMobile}
                  device={device}
                  setDevice={setDevice}
                  currentStep={currentStep}
                  isIFrameAccessible={isIFrameAccessible}
                  setIsIFrameAccessible={setIsIFrameAccessible}
                  feedConfiguration={{
                    ...(
                      device === 'mobile'
                        ? FEED_DEF_MOBILE_CONFIGURATION
                        : FEED_DEF_CONFIGURATION
                    ),
                    ...feed.get('configuration'),
                  }}
                  contentHeight={contentHeight}
                  isRechoosingSelector={isRechoosingSelector}
                  setIsRechoosingSelector={setIsRechoosingSelector}
                  lastSavedConfiguration={lastSavedConfiguration}
                  setLastSavedConfiguration={setLastSavedConfiguration}
                  onChange={feed.setConfiguration}
                  fetchSelector={fetchSelector}
                  setSelectorManual={setSelectorManual}
                  setIsSwitchFrameButton={setIsSwitchFrameButton}
                  isOpenDialogManuallyAddingSelector={isOpenDialogManuallyAddingSelector}
                  setIsOpenDialogManuallyAddingSelector={setIsOpenDialogManuallyAddingSelector}
                  cards={feedSampleCards(
                    feed.get('configuration')?.hasCardCrossedPrice,
                    labelMaxCountLimit > 0
                      && feed.get('configuration')?.labels?.filter(
                        (label: TFeedCartLabel) => label.isActive && label.aliasName
                      )?.length > 0
                  )}
                  feedMode={'pro'}
                />
              )}
            </div>
          </div>

          <DrawerFooter
            className="feed-editor-drawer-footer__container"
            actions={(
              <>
                {(currentStep !== 'StepFeedType' && !isConfigInvalid) && (
                  <Button type="grey" onClick={handleBackClick} disabled={isConfigInvalid}>
                    Назад
                  </Button>
                )}
                {!isConfigInvalid && (
                  <Button
                    className="feed-screen__drawer-next-button"
                    onClick={currentStep !== 'StepDesign' ? handleClickNext : handleSave}
                    disabled={isButtonDisabled}
                    loading={feed.isFetchingRequest}
                  >
                    {currentStep !== 'StepDesign' ? 'Далее' : 'Сохранить'}
                  </Button>
                )}
                {currentStep === 'StepSelector' && (
                  <Button
                    className="feed-screen__drawer-addition-selector"
                    type="text"
                    onClick={() => setIsOpenDialogManuallyAddingSelector(true)}
                  >
                    Указать селектор вручную
                  </Button>
                )}
                {currentStep === 'StepPlacementPages' && (
                  <Button
                    className="feed-screen__drawer-addition-selector"
                    type="text"
                    onClick={() => {
                      setIsOpenDialogSettingsById(true);
                    }}
                  >
                    Настройка по ID
                  </Button>
                )}
                {isSwitchFrameButton && currentStep === 'StepSelector' && (
                  <FooterButton
                    isIFrameAccessible={isIFrameAccessible}
                    setIsIFrameAccessible={setIsIFrameAccessible}
                  />
                )}
                {!isIFrameAccessible &&
                  !isRechoosingSelector &&
                  !isRechoosingSelectorMobile &&
                  feed.get('selector') &&
                  feed.get('selectorMobile') &&
                  currentStep === 'StepSelector' && (
                    <Button
                      className="feed-screen__drawer-clear-button"
                      type="text"
                      onClick={() => {
                        setIsRechoosingSelector(true);
                        setIsRechoosingSelectorMobile(true);
                      }}
                    >
                      Изменить расположение
                    </Button>
                  )}
                {currentStep === 'StepCatalog' &&
                  isConfigInvalid && (
                    <Button
                      className="feed-screen__drawer-next-button"
                      onClick={handleFixYml}
                      disabled={isConfigLinkInvalid}
                      loading={feed.isFetchingRequest}
                    >
                      Исправить
                    </Button>
                  )}
              </>
            )}
            withSidebar={currentStep === 'StepDesign'}
            isFeed
          />
        </>
      )}
    </Drawer>
  );
});

export default FeedProEditor;
