import { action, computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Feeds } from '~/api';
import { useAppSettings } from '~/customHooks/useAppSettings';
import FeedApiApi from '~/screens/ApiScreen/FeedApi/api/FeedApiApi';
import FeedApiModel from '~/screens/ApiScreen/FeedApi/model/FeedApiModel';
import ConstructorStore, { IConstructorStore } from '~/stores/constructorStore';
import { FeedStore } from '~/stores/feedStore';

export class FeedApiStore extends FeedStore {
  @observable constructorStore: IConstructorStore<EditorSteps>;
  @observable feedApi: FeedApiModel = new FeedApiModel(this, {});
  @observable feedApiStatistics: FeedApiStatistics[]; // Статистика лент
  @observable isInvalidYmlUrl: boolean = null; // Максимальное количество лент
  @observable feedStatisticsActiveRow: FeedApiStatistics = null; // Объект активной ленты в таблице статистики
  @observable previousYml: string = ''; // Адрес каталога при открытии конструктора

  constructor() {
    super();
    this.constructorStore = new ConstructorStore([
      'StepFeedType',
      'StepCatalog',
      'StepManual',
    ]);
  }

  /**
   * Сброс некоторых состояний
   */
  @action
  resetToFactorySettings = () => {
    this.constructorStore.changeCurrentStep('StepFeedType');
    this.setIsInvalidYmlUrl(null);
  }

  /**
   * Наполняет FeedApi текущий
   * @param feedApi
   */
  @action
  setFeedApi = (feedApi: FeedApi) => {
    this.feedApi = new FeedApiModel(this, feedApi);
  }

  /**
   * Создает пустое АПИ
   */
  @action
  createEmptyFeedApi = () => {
    this.resetToFactorySettings();
    this.feedApi = null;
    this.feedApi = new FeedApiModel(this, {
      '@type': 'FeedApiTemplate',
      'externalKey': uuidv4(),
    });
    this.setIsConstructorOpen(true);
  }

  /**
   * Проверяет возможность перед попыткой создать АПИ
   */
  @action
  createFeedApi = async () => {
    this.hasPermissionToCreateFeed
      ? await this.createEmptyFeedApi()
      : this.setIsShowExceedingLimitMessage(true);
  }

  /**
   * Получает данные по id api
   * @param id
   * @param copy
   */
  @action
  getFeedApiById = async (id: number, copy: boolean = false) => {
    try {
      const response = await FeedApiApi.getFeedApiById(id);
      const payload = response.data.payload[0];
      if (copy) {
        delete payload.id;
        delete payload.status;
        delete payload.ymlCatalogStatus;
      }
      this.setFeedApi(payload);
      this.setPreviousYml(payload?.ymlUrl);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Получает рекомендации
   */
  @action
  fetchRecommendationTypes = async () => {
    if (this.recommendationTypes.length === 0) {
      try {
        const response = await FeedApiApi.getRecommendationTypes();
        this.setRecommendationTypes(response.data.payload);
      } catch (e) {
        throw e;
      }
    }
  }

  /**
   * Устанавливает флаг ошибки ссылки на каталог
   * @param status
   */
  @action
  setIsInvalidYmlUrl = (status: boolean) => {
    this.isInvalidYmlUrl = status;
  }

  @action
  setFeedApiStatistics = (statistics: FeedApiStatistics[]) => {
    this.feedApiStatistics = statistics;
    this.setFeedAmount(statistics?.length ?? 0);
  }

  @action
  updateFeedApiStatistics = async () => {
    try {
      const response = await FeedApiApi.getFeedApiStatistics();
      this.setFeedApiStatistics(response?.data?.payload);
    } catch (error) {
      throw error;
    }
  }

  @action
  getFeedApiStatistics = async () => {
    try {
      useAppSettings()
        .getAppSetting('MAX_FEED_COUNT_API')
        .then(settingsValue => 
          this.setFeedLimit(settingsValue as number)
        );
      this.setFetchingStatisticsError(false);
      this.setFetchingStatistics(true);
      this.fetchRecommendationTypes();
      this.updateFeedApiStatistics();
    } catch (e) {
      this.setFetchingStatisticsError(true);
      throw e;
    } finally {
      this.setFetchingStatistics(false);
    }
  }

  @action
  setFeedStatisticsActiveRow = (feedStatistics: FeedApiStatistics) => {
    this.feedStatisticsActiveRow = feedStatistics;
  }

  @action
  setPreviousYml = (previousYml: string) => {
    this.previousYml = previousYml;
  }

  /**
   * Старт ленты
   * @param id
   */
  @action
  startFeedApi = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.start(id);
      await this.updateFeedApiStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Остановка ленты
   */
  @action
  stopFeedApi = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.stop(id);
      await this.updateFeedApiStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Удаление ленты
   */
  @action
  deleteFeedApi = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.delete(id);
      await this.updateFeedApiStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Переименование ленты
   */
  @action
  renameFeed = async (id: number, feedName: string) => {
    try {
      await Feeds.rename(id, feedName);
      await this.updateFeedApiStatistics();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Обработка акшен-кнопок и суппорт-кнопок
   * @param actionType
   * @param feedStatistics
   */
  @action
  onClickActionButton = (
    actionType: FeedActionButtonType,
    feedStatistics: FeedApiStatistics
  ) => {
    this.setFeedStatisticsActiveRow(feedStatistics);
    switch (actionType) {
      // Запуск ленты
      case 'start': {
        this.startFeedApi(feedStatistics.id);
        break;
      }
      // Остановка ленты
      case 'stop': {
        const stopFeed = () => {
          this.setIsStoppingDialogOpen(false);
          this.stopFeedApi(feedStatistics.id);
        };
        this.isStoppingDialogOpen
          ? stopFeed()
          : this.setIsStoppingDialogOpen(true);
        break;
      }
      // Редактирование ленты
      case 'edit': {
        const editFeedApi = async (feedId: number) => {
          await this.getFeedApiById(feedId);
          this.constructorStore.changeCurrentStep('StepFeedType');
          this.setIsInvalidYmlUrl(false);
          this.setIsConstructorOpen(true);
        };
        const stopAndEditFeedApi = async (feedId: number) => {
          this.setIsBeforeEditOpen(false);
          await this.stopFeedApi(feedId);
          editFeedApi(feedId);
        };
        switch (feedStatistics.status) {
          case 'WORK':
            this.isBeforeEditOpen
              ? stopAndEditFeedApi(feedStatistics.id)
              : this.setIsBeforeEditOpen(true);
            break;
          case 'VERIFY':
            this.setIsShowWaitMessage(true);
            break;
          default:
            editFeedApi(feedStatistics.id);
            break;
        }
        break;
      }
      // Информация о ленте
      case 'info': {
        const infoFeed = async (feedId: number) => {
          await this.getFeedApiById(feedId);
          this.setIsInfoOpen(true);
        };
        infoFeed(feedStatistics.id);
        break;
      }
      // Переименование ленты
      case 'rename': {
        this.setIsOpenRenameDialog(true);
        break;
      }
      // Копирование ленты
      case 'copy': {
        const copyFeedApi = async (feedId: number) => {
          await this.getFeedApiById(feedId, true);
          this.constructorStore.changeCurrentStep('StepFeedType');
          this.setIsInvalidYmlUrl(false);
          this.feedApi.update({ externalKey: uuidv4() });
          this.setIsConstructorOpen(true);
        };
        this.hasPermissionToCreateFeed
          ? copyFeedApi(feedStatistics.id)
          : this.setIsShowExceedingLimitMessage(true);
        break;
      }
      // Удаление ленты
      case 'delete': {
        const deleteFeed = (feedId: number) => {
          this.deleteFeedApi(feedId);
          this.setIsRemoveDialogOpen(false);
        };
        this.isRemoveDialogOpen
          ? deleteFeed(feedStatistics.id)
          : this.setIsRemoveDialogOpen(true);
        break;
      }
      // Исправление каталога
      case 'fix': {
        const currentYml = this.feedApi?.get('ymlUrl');
        const saveFeedApi = async () => {
          await this.feedApi?.saveFeedApi();
          this.getFeedApiStatistics();
        };
        const fixFeedApiYml = async () => {
          await this.feedApi?.fixYml();
          this.getFeedApiStatistics();
        };
        this.previousYml !== currentYml ? saveFeedApi() : fixFeedApiYml();
        this.setIsConstructorOpen(false);
        break;
      }
    }
  }

  @computed
  get isConfigInvalid() {
    return (
      this.feedApi?.get('ymlCatalogStatus') === 'YML_ERROR' 
        || this.feedApi?.get('ymlCatalogStatus') === 'UPLOAD_CACHED'
    );
  }
}

export default new FeedApiStore();
