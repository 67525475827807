import React, { useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { validateEmail } from '~/utils/validationUtils/validateEmail';
import styles from './style.module.css';

const defaultError = 'Ошибка в адресе';

interface ITestMailProps {
  email: string;
  isDesktop: boolean;
  sendTestLetter: (email: string) => Promise<boolean>;
  errorMessage: string;
}

const TestMail: React.FC<ITestMailProps> = ({
  email,
  isDesktop,
  sendTestLetter,
  errorMessage,
}) => {
  const [testEmail, setTestEmail] = useState<string>(email);
  const [isValid, setIsValid] = useState<boolean>(validateEmail(email));
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const isInvalidResult = testEmail.length > 0 && !isValid;
  const [error, setError] = useState<boolean | string>('');

  const onChangeValue = (testEmail: string) => {
    setIsSuccess(false);
    setIsValid(validateEmail(testEmail));
    setTestEmail(testEmail);
  };

  const onBlur = (testEmail: any) => {
    setIsSuccess(false);
    setIsValid(validateEmail(testEmail));
  };

  const onSend = async () => {
    const result = await sendTestLetter(testEmail);
    if (result) {
      setIsSuccess(true);

      return;
    }

    setError(errorMessage);
    setIsValid(false);
  };

  const position = isDesktop
    ? { left: 'calc(100vw / 2 + 350px)' }
    : { left: 'calc(100vw / 2 + 250px)' };

  return (
    <div style={position} className={styles.testMail}>
      <div className={styles.head}>Отправьте это письмо себе для проверки</div>
      <Input
        failure={!isValid}
        onBlur={onBlur}
        className={styles.input}
        value={testEmail}
        placeholder="Тестовый Email"
        onChangeValue={onChangeValue}
      />
      {isInvalidResult && (
        <div className={styles.error}>{error || defaultError}</div>
      )}
      <Button
        type={isSuccess ? 'grey' : 'main'}
        icon={isSuccess ? 'CheckmarkM' : undefined}
        onClick={onSend}
        disabled={!isValid}
      >
        {isSuccess ? 'Отправлено' : 'Отправить'}
      </Button>
    </div>
  );
};

export default TestMail;
