import { IFeedConfiguration, TFeedKeyElement } from 'feed';
import React from 'react';
import ContentMenuItem from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Content/ContentMenuItem';
import DraggableElements from '~/components/ui/DraggableElements';
import './style.css';

interface IContentProps {
  handleChangeActiveMenu: (key: StepDesignMenuElements) => void;
  configuration: IFeedConfiguration;
  onChange: (configuration: IFeedConfiguration, isDesignUpdate?: boolean) => void;
}

export const Content: React.FC<IContentProps> = ({
  handleChangeActiveMenu,
  configuration,
  onChange,
}) => {
  const {
    hasFeedTitle,
    hasCardTitle,
    hasCardDescription,
    hasCardPrice,
    hasCardButton,
  } = configuration;

  const staticItems: StepDesignMenuContentItem[] = [
    {
      key: 'FEED_TITLE',
      value: 'Заголовок ленты',
      checked: hasFeedTitle,
      onCheck: (status: boolean) => onChange({ ...configuration, hasFeedTitle: status }, true),
      onEdit: handleChangeActiveMenu,
    },
  ];

  const DraggableItems: StepDesignMenuContentItem[] = [
    {
      key: 'CARD_TITLE',
      value: 'Наименование товара',
      checked: hasCardTitle,
      onCheck: (status: boolean) => onChange({ ...configuration, hasCardTitle: status }, true),
      onEdit: handleChangeActiveMenu,
      isShowMoveIcon: true,
    },
    {
      key: 'DESCRIPTION',
      value: 'Описание',
      checked: hasCardDescription,
      onCheck: (status: boolean) => onChange({ ...configuration, hasCardDescription: status }, true),
      onEdit: handleChangeActiveMenu,
      isShowMoveIcon: true,
    },
    {
      key: 'PRICE',
      value: 'Цена',
      checked: hasCardPrice,
      onCheck: (status: boolean) => onChange({ ...configuration, hasCardPrice: status }, true),
      onEdit: handleChangeActiveMenu,
      isShowMoveIcon: true,
    },
    {
      key: 'BUTTON',
      value: 'Кнопка',
      checked: hasCardButton,
      onCheck: (status: boolean) => onChange({ ...configuration, hasCardButton: status }, true),
      onEdit: handleChangeActiveMenu,
      isShowMoveIcon: true,
    },
  ];

  return (
    <div className="feed-editor-appearance-tools-content__wrapper">
      <div className="feed-editor-appearance-tools-content__header">
        Управляйте порядком и показом элементов
      </div>
      <div className="feed-editor-appearance-tools-content__body">
        {staticItems
          .map(item => (
            <ContentMenuItem
              key={item.key}
              menuKey={item.key}
              name={item.value}
              checked={item.checked}
              onCheck={item.onCheck}
              onEdit={handleChangeActiveMenu}
              isShowMoveIcon={item.isShowMoveIcon}
            />
          ))
        }
        <DraggableElements
          elements={DraggableItems
            .sort((a, b) => 
              configuration.sortingElements
                .indexOf(a.key as TFeedKeyElement) - configuration.sortingElements.indexOf(b.key as TFeedKeyElement))
            .map(item => (
              <ContentMenuItem
                key={item.key}
                menuKey={item.key}
                name={item.value}
                checked={item.checked}
                onCheck={item.onCheck}
                onEdit={handleChangeActiveMenu}
                isShowMoveIcon={item.isShowMoveIcon}
              />
            ))
          }
          onChange={anyArray => onChange({
            ...configuration,
            sortingElements: anyArray.map(element => element.key as TFeedKeyElement),
          }, true)}
        />
      </div>
      <div className="feed-editor-appearance-tools-content__desc">
        Выключенные объекты не будут отображаться в вашей ленте, но их можно будет включить в настройке
      </div>
    </div>
  );
};
