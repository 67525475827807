import { observer } from 'mobx-react';
import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import Input from '~/components/ui/Input';
import { SettingsStore } from '~/stores/settingsStore';
import useStores from '~/utils/useStores';
import './style.css';

const UserEditor: React.FC = observer(() => {

  const { settingsStore } = useStores() as { settingsStore: SettingsStore };

  // прикрутить валидаторы
  // нужна аналитика
  const phoneError = false;
  const nameError = false;
  const pwdError = false;

  return (
    <div className="user-editor__wrapper">
      <div className="user-editor__input-block">
        <div className="_title-block">
          <span className="_title-text">Создайте название учетной записи</span>
          <IconWithTooltip
            tooltipProps={{
              placement: 'bottomLeft',
              title: 'В качестве названия вы можете использовать любое сочетание букв и цифр, которое поможет вам идентифицировать этого пользователя в общей таблице. Это название видите только вы.',
              width: '340px',
            }}
            iconProps={{
              name: 'Question',
            }}
          />
        </div>
        <Input
          type="text"
          value={settingsStore.userName}
          onChangeValue={settingsStore.setUserName}
          placeholder="Ivanov-AG"
          maxLength={50}
          className={nameError ? '_error-placeholder' : ''}
          failure={nameError}
          block
        />
        <span className="error-msg">{nameError ? 'Какая-то ошибка =)' : ''}</span>
      </div>

      <div className="user-editor__input-block">
        <div className="_title-block">
          <span className="_title-text">Номер телефона пользователя</span>
          <IconWithTooltip
            tooltipProps={{
              placement: 'bottomLeft',
              title: 'Укажите номер телефона пользователя, который будет использоваться им для входа в личный кабинет.',
              width: '340px',
            }}
            iconProps={{
              name: 'Question',
            }}
          />
        </div>
        <Input
          type="regTel"
          value={settingsStore.userPhone}
          onChangePhone={settingsStore.setUserPhone}
          placeholder="+7 000 000-00-00"
          className={phoneError ? '_error-placeholder' : ''}
          failure={phoneError}
          block
        />
        <span className="error-msg">{phoneError ? 'Какая-то ошибка =)' : ''}</span>
      </div>

      <div className="user-editor__input-block">
        {
          settingsStore.operationStatus === 'createUser' && (
            <>
              <div className="_title-block">
              <span className="_title-text">Создайте пароль для пользователя</span>
              <IconWithTooltip
                tooltipProps={{
                  placement: 'bottomLeft',
                  title: 'Этот пароль нужно будет передать пользователю для его входа в личный кабинет.',
                  width: '340px',
                }}
                iconProps={{
                  name: 'Question',
                }}
              />
            </div>
            <Input
              type="password"
              value={settingsStore.userPassword}
              onChangeValue={settingsStore.setUserPassword}
              placeholder="Введите пароль"
              className="_pwd-input"
              failure={pwdError}
              withEye
              block
            />
            <span className="error-msg">{pwdError ? 'Пароль не соответствует требованиям' : ''}</span>
            <div className="_pwd_description">
              <ul>
                <li>Пароль должен содержать</li>
                <li>- Минимум 12 символов</li>
                <li>- Заглавную букву</li>
                <li>- Цифру</li>
              </ul>
            </div>
            </>
          )
        }
        <div className="user-editor__rights">
          <span className="_title">Права пользователя</span>
          <p className="_text">
            Пользователь сможет пользоваться бизнес-функционалом сервиса,
            но не может создавать/удалять пользователей, отключать/подключать сервис.
          </p>
        </div>
        <span className="error-msg">{settingsStore.createUserError}</span>
      </div>

    </div>
  );
});

export default UserEditor;
