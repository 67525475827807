import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentCategoryActiveButtonTextCssParam = (configuration: IFeedConfiguration): string => {
  const {
    categoryActiveButtonBorderRadius,
    categoryActiveButtonBorderWidth,
    categoryActiveButtonBorderColor,
    categoryActiveButtonBorderOpacity,
    categoryActiveButtonColor,
    categoryActiveButtonOpacity,
    categoryActiveButtonFontFamily,
    categoryActiveButtonTextColor,
    categoryActiveButtonTextOpacity,
    categoryActiveButtonFontWeight,
    categoryActiveButtonFontSize,
    categoryActiveButtonFontItalic,
    categoryActiveButtonTextLineThrough,
    categoryActiveButtonTextUnderline,
  } = configuration;
  
  return `
    border: ${categoryActiveButtonBorderWidth}px solid  ${hexToRgb(categoryActiveButtonBorderColor, categoryActiveButtonBorderOpacity)};
    border-radius: ${categoryActiveButtonBorderRadius}px;
    background-color: ${hexToRgb(categoryActiveButtonColor, categoryActiveButtonOpacity)};
    font-family: ${categoryActiveButtonFontFamily};
    color: ${hexToRgb(categoryActiveButtonTextColor, categoryActiveButtonTextOpacity)};
    font-weight: ${fontWeightValues[categoryActiveButtonFontWeight]};
    font-size: ${categoryActiveButtonFontSize}px;
    font-style: ${categoryActiveButtonFontItalic ? 'italic' : 'normal'};
    text-decoration: ${getTextDecoration(
      categoryActiveButtonTextLineThrough,
      categoryActiveButtonTextUnderline
    )};
  `.replace(/(\r\n|\n|\r|↵)/gm, '').trim();
};