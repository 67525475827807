import cx from 'classnames';
import React from 'react';
import RadioButton from '~/components/ui/RadioButton';
import './style.css';

interface ItemProps {
  label: React.ReactNode;
  value: string | number;
}

interface RadioGroupProps {
  checkedItem?: string;
  items: ItemProps[];
  className?: string;
  size?: 'medium' | 'small';
  onCheck: (value: string | number) => void;
  onLoad?: (value: string) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  checkedItem,
  items = [],
  className,
  size = 'medium',
  onCheck,
  onLoad,
}) => {
  const innerItems = items.map(({ label, value }) => {
    const isChecked = value === checkedItem;

    return (
      <RadioButton
        label={label}
        value={value}
        onCheck={onCheck}
        checked={isChecked}
        key={value}
      />
    );
  });

  onLoad && onLoad(checkedItem);

  return (
    <div
      className={cx('radio-group', {
        [`_size-${size}`]: size,
      }, className)}
    >
      {innerItems}
    </div>
  );
};

export default RadioGroup;

