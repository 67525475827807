import React from 'react';
import Icon from '~/components/Icon';
import { Select } from '~/components/Select/Select';
import ItemPosition, {
  KeyName,
} from '~/components/notification-editor/RadioGrid/ItemPosition';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { ColorChanger } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/ColorChanger';
import { IStepEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/EditDrawer';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { Info } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/Info';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';

export type IQuizAlignment =
  | 'left top'
  | 'center top'
  | 'right top'
  | 'left center'
  | 'center center'
  | 'right center'
  | 'left bottom'
  | 'center bottom'
  | 'right bottom';

const topItems: KeyName[] = [
  { name: 'AlignTopLeft', key: 'left top' },
  { name: 'AlignTopCenter', key: 'center top' },
  { name: 'AlignTopRight', key: 'right top' },
];

const centerItems: KeyName[] = [
  { name: 'AlignCenterLeft', key: 'left center' },
  { name: 'AlignCenter', key: 'center center' },
  { name: 'AlignCenterRight', key: 'right center' },
];

const bottomItems: KeyName[] = [
  { name: 'AlignBottomLeft', key: 'left bottom' },
  { name: 'AlignBottomCenter', key: 'center bottom' },
  { name: 'AlignBottomRight', key: 'right bottom' },
];

const visibleItems = [...topItems, ...centerItems, ...bottomItems];

const selectImageSizeOptions = [
  { value: 'none', text: 'Оригинал' },
  { value: 'contain', text: 'Уместить' },
  { value: 'fill', text: 'Заполнить' },
  { value: 'cover', text: 'Сохранить пропорции' },
];

interface ISelectImageSize {
  setConfiguration: (field: string, value: string) => void;
  value: string;
}

const SelectImageSize = ({ setConfiguration, value }: ISelectImageSize) => {
  const onSelect = (e: any) => {
    setConfiguration('imageSize', e.target.value);
  };

  return (
    <Select
      handleClick={onSelect}
      options={selectImageSizeOptions}
      selected={value}
      className={styles.coverSize}
    />
  );
};

export const CoverEditor = ({ step, onDelete }: IStepEditor) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample } = quizStore;

  const { getValue, setValue, getConfiguration, setConfiguration } =
    quizExample;

  const onChangeBonus = (v: string, key: number) => {
    const bonuses = [...getValue(step, 'bonuses')];
    bonuses[key] = v;
    setValue(step, 'bonuses', bonuses);

    const leads = quizExample
      ?.get('steps')
      .map((v, k) => ({ step: v, key: k }))
      .filter(v => v.step.type === 'lead');

    if (leads.length > 0) {
      setValue(leads[0].key || 0, 'bonuses', bonuses);
    }

  };

  const onDeleteBonus = (key: number) => {
    const bonuses = [...getValue(step, 'bonuses')];
    bonuses.splice(key, 1);
    setValue(step, 'bonuses', bonuses);

    if (bonuses.length === 0) {
      const leads = quizExample
        ?.get('steps')
        .map((v, k) => ({ step: v, key: k }))
        .filter(v => v.step.type === 'lead');

      if (leads.length > 0) {
        console.log({ leads });
        setValue(leads[0].key || 0, 'bonuses', []);
      }
    }
  };

  const onBonusAdd = (bonus?: string) => {
    const bonuses = [...getValue(step, 'bonuses')];
    bonuses.push(bonus || '');
    setValue(step, 'bonuses', bonuses);
  };

  const bonusesLength = getValue(step, 'bonuses')?.length;

  return (
    <>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>
          Установите изображение и цвета
        </div>
        <div className={styles.changeColorWrap}>
          <ColorChanger
            fieldName={'image'}
            name={'Изображение'}
            value={getConfiguration('image').url}
            setConfiguration={setConfiguration}
          />
          <ColorChanger
            fieldName={'backgroundColor'}
            name={'Цвет фона'}
            value={getConfiguration('backgroundColor')}
            setConfiguration={setConfiguration}
          />
          <ColorChanger
            fieldName={'textColor'}
            name={'Цвет текста'}
            value={getConfiguration('textColor')}
            setConfiguration={setConfiguration}
          />
          <ColorChanger
            fieldName={'buttonColor'}
            name={'Цвет кнопки'}
            value={getConfiguration('buttonColor')}
            setConfiguration={setConfiguration}
          />
          <ColorChanger
            fieldName={'buttonTextColor'}
            name={'Цвет текста кнопки'}
            value={getConfiguration('buttonTextColor')}
            setConfiguration={setConfiguration}
          />
        </div>
      </div>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>
          Расположение изображения
        </div>
        <div className={styles.changeSettingBody}>
          <div className={styles.settingsPosition}>
            <ItemPosition
              align={getConfiguration('imagePosition')}
              onSelect={(position) => {
                setConfiguration('imagePosition', position);
              }}
              title={''}
              customVisibleItems={visibleItems}
            />
            <SelectImageSize
              setConfiguration={setConfiguration}
              value={getConfiguration('imageSize')}
            />
          </div>
        </div>
      </div>
      <Info
        text={
          'Чтобы привлечь внимание пользователей, создайте уникальную обложку с призывом к действию'
        }
      />
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст заголовка</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'title')}
            placeholder={'Введите заголовок'}
            onChangeValue={(v: string) => setValue(step, 'title', v)}
            maxLength={120}
          />
        </div>
      </div>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст описания</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'description')}
            placeholder={'Введите описание'}
            onChangeValue={(v: string) => setValue(step, 'description', v)}
            maxLength={150}
          />
        </div>
      </div>
      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>Текст кнопки</div>
        <div className={styles.changeSettingBody}>
          <Input
            className={styles.settingsInput}
            value={getValue(step, 'buttonText')}
            placeholder={'Введите описание'}
            onChangeValue={(v: string) => setValue(step, 'buttonText', v)}
            maxLength={30}
          />
        </div>
      </div>

      <div className={styles.changeSetting}>
        <div className={styles.changeSettingTitle}>
          Бонусы после прохождения
        </div>
        <div className={styles.changeSettingsSubtitle}>
          Добавьте до 2х бонусных предложений
        </div>
        <div className={styles.bonusColors}>
          <div>Цвет бонусов:</div>
          <div>
            <ColorChanger
              fieldName={'bonusColor'}
              value={getConfiguration('bonusColor')}
              setConfiguration={setConfiguration}
            />
          </div>
          <div>Цвет текста бонусов</div>
          <div>
            <ColorChanger
              fieldName={'bonusTextColor'}
              value={getConfiguration('bonusTextColor')}
              setConfiguration={setConfiguration}
            />
          </div>
        </div>
        <div>
          {getValue(step, 'bonuses')?.map((v: string, k: number) => (
            <div key={k} className={styles.bonusInputWrap}>
              <Input
                onChangeValue={(v: string) => onChangeBonus(v, k)}
                value={v}
                className={styles.bonusInput}
                maxLength={60}
              />
              <Icon
                name={'Close'}
                className={styles.deleteIcon}
                onClick={() => onDeleteBonus(k)}
              />
            </div>
          ))}
        </div>
        <div className={styles.addBonusButtonWrap}>
          {bonusesLength < 2 && (
            <Button
              type="green-outline"
              onClick={() => onBonusAdd(`Бонус ${bonusesLength + 1}`)}
              icon={'Plus'}
              className={styles.addButton}
            >
              Добавить бонус
            </Button>
          )}
        </div>
        <div className={styles.bonusCheaps}>
          {bonusesLength < 2 &&
            [
              'Бесплатная доставка',
              'Купон на 1000 ₽',
              'Скидка 15%',
              'Подарочный сертификат',
              'Бесплатная консультация',
            ].map((v, k) => {
              return (
                <div
                  key={k}
                  style={{
                    background: getConfiguration('bonusColor'),
                    color: getConfiguration('bonusTextColor'),
                  }}
                  className={styles.bonusCheap}
                  onClick={() => onBonusAdd(v)}
                >
                  {v} +
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
