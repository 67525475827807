import { Button, Empty, Modal, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Access from '~/components/Access';
import UserModel from '~/models/UserModel';
import OldScreen from '~/screens/__deprecated/OldScreen';
import UsersStore from '~/screens/__deprecated/UsersScreen/store';
import { AuthStore } from '~/stores/authStore';
import { RolesStore } from '~/stores/rolesStore';
import './style.css';

interface Props {
  authStore?: AuthStore;
  rolesStore: RolesStore;
}

interface State {
  isDeleteModalVisible: boolean;
  removingLogin: string;
  pagination: TablePaginationConfig;
}

const pageSize = 10;

@inject('authStore', 'rolesStore')
@observer
class UsersScreen extends Component<Props, State> {
  store = new UsersStore();

  state: State = {
    isDeleteModalVisible: false,
    removingLogin: '',
    pagination: {
      current: 1,
      pageSize,
      total: pageSize * 2,
    },
  };

  async componentDidMount() {
    await this.props.rolesStore.fetchRoles();
    await this.props.rolesStore.fetchRights();
    await this.store.fetchUsers({ pagingCount: this.state.pagination.pageSize, pagingFrom: 0 });
  }

  handleTableChange = async ({ pageSize, current }: any) => {
    const result = await this.store.fetchUsers({
      pagingCount: pageSize,
      pagingFrom:  (current - 1) * pageSize + 1,
    });

    if (result && this.store.users.length === pageSize) {
      this.setState({
        pagination: { pageSize, current, total: pageSize * current + pageSize },
      });
    } else {
      this.setState({
        pagination: { ...this.state.pagination, current },
      });
    }
  }

  showDeleteConfirm = (user: UserModel) => {
    this.setState({
      isDeleteModalVisible: true,
      removingLogin: user.get('login'),
    });
  }

  cancelDeleteConfirm = () => {
    this.setState({
      isDeleteModalVisible: false,
    });
  }

  handleDeleteConfirm = async () => {
    await this.store.deleteUser(this.state.removingLogin);
    this.setState({ isDeleteModalVisible: false });
  }

  renderColumns = (): ColumnProps<UserModel>[] => {
    return [
      {
        key: 'id',
        title: 'ID',
        render: (user: UserModel) => user.get('id'),
      },
      {
        key: 'login',
        title: 'Логин',
        render: (user: UserModel) => {
          const isCurrentUser = user.get('id') === this.props.authStore.currentUser.get('id');

          return (
            <>
              <strong>{user.get('login')}</strong>
              {isCurrentUser && <Tag color="green">Это вы</Tag>}
            </>
          );
        },
      },
      {
        key: 'name',
        title: 'ФИО',
        render: (user: UserModel) => user.fullName,
      },
      {
        key: 'role',
        title: 'Роль',
        render: (user: UserModel) => user.role,
      },
      {
        key: 'actions',
        render: this.renderActions,
      },
    ];
  }

  renderActions = (user: UserModel) => {
    const isCurrentUser = user.get('id') === this.props.authStore.currentUser.get('id');

    return (
      <div className="table__actions">
        <Access to="ADMIN">
          <Link to={`/users/${user.get('id')}`}>
            <Button>Редактировать</Button>
          </Link>
        </Access>

        <Access to="ADMIN">
          <Button
            disabled={isCurrentUser}
            title={isCurrentUser ? 'Вы не можете удалить сами себя' : ''}
            type="default"
            onClick={() => this.showDeleteConfirm(user)}
          >
            Удалить
          </Button>
        </Access>
      </div>
    );
  }

  render() {
    const { isDeleteModalVisible, removingLogin } = this.state;
    const rolesLoaded = !!this.props.rolesStore.roles.length;

    return (
      <OldScreen
        className="users-screen"
        availableWithRule={['ADMIN']}
        documentTitle="Пользователи"
        title="Пользователи"
        titleExtra={(
          <Access to="ADMIN">
            <Link to="/users/create">
              <Button type="primary">Создать пользователя</Button>
            </Link>
          </Access>
        )}
      >

        {rolesLoaded && (
          <Table
            className="user-screen__table table"
            loading={this.store.isUsersLoading}
            columns={this.renderColumns()}
            dataSource={this.store.users.concat([])}
            locale={{ emptyText: <Empty description={false} /> }}
            size="small"
            pagination={{ ...this.state.pagination, disabled: false }}
            onChange={this.handleTableChange}
          />
        )}


        <Modal
          title="Удаление"
          visible={isDeleteModalVisible}
          onOk={this.handleDeleteConfirm}
          okText="Да"
          onCancel={this.cancelDeleteConfirm}
          cancelText="Отменить"
          confirmLoading={this.store.isUserDeleting}
        >
          Вы уверены, что хотите удалить пользователя <strong>{removingLogin}</strong>?
        </Modal>
      </OldScreen>
    );
  }
}

export default UsersScreen;
