export const getQuizShownConfiguration = (configuration: {
  image: { url: any };
}) => {
  return { ...configuration, image: configuration.image.url };
};

export const getQuizShownSteps = (steps: any) => {
  
  return steps.map(
    (v: { answerType: string; answers: { image: { url: any } }[] }) => {
      if (v.answerType === 'LIST') {
        return {
          ...v,
          answers: v.answers.map((answer: { image: { url: any } }) => ({
            ...answer,
            image: answer.image ? answer.image.url : undefined,
          })),
        };
      }

      return v;
    }
  );
};
