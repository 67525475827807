import qs from 'query-string';
import { ISbbIDLoginData } from '~/stores/authStore';

export const sbbLoginDataCollection = (): ISbbIDLoginData => {
  const { code, nonce, state } = qs.parse(
    location.search
  ) as {
    code: string;
    nonce: string;
    state: string;
  };
  const { origin, pathname } = location;

  return {
    state: typeof state !== 'undefined' ? state : '',
    nonce: typeof nonce !== 'undefined' ? nonce : '',
    code: typeof code !== 'undefined' ? code : '',
    location: `${origin}${pathname}`,
  };
};
