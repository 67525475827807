import { mjmlButton } from '~/components/Mailing/ui/MailingButton';
import { htmlLogoImage } from '~/components/Mailing/ui/MailingImage';
import { mjmlText } from '~/components/Mailing/ui/MailingText';

export const defaultImagePath = `${location.origin}/assets/images/mailing/image_empty.png`;

export const initialMailInstance: IMassMailingModel = {
  id: undefined,
  name: 'Рассылка',
  type: 'EmailDistributionTemplate',
  configuration: [
    {
      name: 'letter',
      uiName: 'Общие параметры',
      type: 'letter',
      editable: true,
      selected: true,
      configuration: () => '',
      params: {
        backgroundColor: '#f5f5f5',
        backgroundOpacity: 1,
      },
    },
    {
      name: 'logo',
      uiName: 'Изображение',
      type: 'image',
      editable: true,
      selected: false,
      configuration: htmlLogoImage,
      params: {
        imagePath: defaultImagePath,
        imageSize: 'contain',
        backgroundColor: '#ffffff',
        backgroundOpacity: 1,
      },
    },
    {
      name: 'head',
      uiName: 'Заголовок',
      type: 'text',
      editable: true,
      selected: false,
      configuration: mjmlText,
      params: {
        fontFamily: 'Roboto',
        padding: '12px 20px 4px 20px',
        color: '#4d4d4d',
        fontSize: '26px',
        lineHeight: '24px',
        text: 'Скидка 20%',
        fontWeight: 700,
      },
      editParams: {
        maxTextLength: 55,
      },
    },
    {
      name: 'subhead',
      uiName: 'Текст',
      type: 'text',
      editable: true,
      selected: false,
      configuration: mjmlText,
      params: {
        fontFamily: 'Roboto',
        padding: '4px 20px 6px 20px',
        color: '#808080',
        fontSize: '16px',
        lineHeight: '24px',
        text: 'Только сегодня и только сейчас супер-скидки у нас на сайте!',
        fontWeight: 500,
      },
      editParams: {
        maxTextLength: 255,
      },
    },
    {
      name: 'goToCart',
      uiName: 'Кнопка',
      type: 'button',
      editable: true,
      selected: false,
      configuration: mjmlButton,
      params: {
        fontFamily: 'Roboto',
        padding: '6px 20px 12px 20px',
        innerPadding: '12px 24px',
        color: '#ffffff',
        colorOpacity: 1,
        fontSize: '16px',
        backgroundColor: '#47a5ff',
        backgroundOpacity: 1,
        text: 'Перейти на сайт',
        borderRadius: '12px',
        align: 'left',
        verticalAlign: 'middle',
        height: '100%',
        lineHeight: '24px',
        href: '',
      },
      editParams: {
        maxTextLength: 25,
      },
    },
    {
      name: 'unsubscribeText',
      uiName: 'Текст',
      editable: true,
      selected: false,
      configuration: mjmlText,
      type: 'text',
      params: {
        fontFamily: 'Roboto',
        padding: '16px 0 4px 0',
        color: '#808080',
        fontSize: '12px',
        lineHeight: '24px',
        text: 'Вы получили это письмо, так как подписаны на рассылку',
        align: 'center',
        verticalAlign: 'bottom',
      },
      editParams: {
        maxTextLength: 255,
      },
    },
    {
      name: 'unsubscribeLink',
      editable: false,
      type: 'non_editable',
      selected: false,
      configuration: mjmlButton,
      params: {
        fontFamily: 'Roboto',
        align: 'center',
        padding: '0px 0px 12px 0px',
        borderRadius: '0px',
        color: '#808080',
        colorOpacity: 1,
        verticalAlign: 'middle',
        fontSize: '12px',
        height: '100%',
        backgroundColor: '#ffffff',
        text: 'Отписаться от рассылки',
        sectionBorderRadius: '0 0 12px 12px',
        innerPadding: '0px',
        textDecoration: 'underline',
        lineHeight: '24px',
      },
    },
  ],
  authorizationEmail: {
    address: '',
    registered: false,
    emailTopic: '',
    senderName: '',
  },
  imageCodes: [],
};

export const nameToConfiguration = (name: string): TMjmlFunctions => {
  const configurations: {[key: string]: TMjmlFunctions } = {
    letter: () => '',
    logo: htmlLogoImage,
    head: mjmlText,
    subhead: mjmlText,
    goToCart: mjmlButton,
    unsubscribeText: mjmlText,
    unsubscribeLink: mjmlButton,
  };

  return configurations[name];
};
