import React from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import './../style.css';

const StandardEmbedding: React.FC<{
  urlPattern: string;
  onChange: ({}: IPlacementPageParams) => void;
  isInvalid: boolean;
  onBlur: () => void;
  errorMessage: string;
}> = ({
  urlPattern,
  onChange,
  isInvalid,
  onBlur,
  errorMessage,
}) => {
  return (
    <div className="feed-editor-placement-pages">
      <NumberedBlock>
        <SetCatalogConfigLink
          title="Введите ссылку на любую карточку товара"
          onChange={(urlPattern) => {
            onChange({ urlPattern, checkedCountPage: 'MULTIPLE' });
          }}
          value={urlPattern}
          placeholder="https://example.ru/product-1"
          tooltipText={`Ссылка на страницу должна быть полной - включая «http://» или «https://»`}
          isInvalid={isInvalid}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
        <div className="feed-editor-placement-pages__info">
          На основании этой ссылки мы автоматически распознаем остальные страницы
          всех товаров вашего сайта.&nbsp; На каждой странице товара будет своя
          лента рекомендаций.
        </div>
      </NumberedBlock>
    </div>
  );
};

export default StandardEmbedding;
