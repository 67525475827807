import cx from 'classnames';
import React, { CSSProperties } from 'react';
import ButtonLike from '~/components/ui/ButtonLike';
import InputWrapper from '~/components/ui/InputWrapper';
import { TabSwitchItem } from '~/components/ui/TabSwitch/types';
import Tooltip from '~/components/ui/Tooltip';
import './style.css';

interface Props {
  items: TabSwitchItem[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  style?: CSSProperties;
}

const TabSwitch: React.FC<Props> = ({
  items,
  value,
  onChange,
  className,
  style,
}) => {
  const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
    const { key } = e.currentTarget.dataset;

    if (value !== key) {
      onChange(key);
    }
  };

  return (
    <InputWrapper className={cx('tab-switch', className)} style={style}>
      {items.map(item => (
        <Tooltip key={item.key} placement={'bottomLeft'} title={item.tooltip}>
          <div
            className={cx('tab-switch__item')}
            onClick={!item.disabled ? handleChange : null}
            data-key={item.key}
          >
            <ButtonLike
              className={cx('tab-switch__button', {
                [`${className}__button`]: className,
              })}
              icon={item.icon}
              type={item.key === value ? 'main' : 'outline'}
              size="x-small"
              borderless
              disabled={item.disabled}
            >
              {item.label}
            </ButtonLike>
          </div>
        </Tooltip>
      ))}
    </InputWrapper>
  );
};

export default React.memo(TabSwitch);
