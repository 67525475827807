import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import './style.css';

const ScenarioEditorConfigure: React.FC<IScenarioEditorConfigure> = ({
  onClick,
  className,
  text,
  hasPlayButton = true,
}) => (
  <div className={cx('scenario-editor-configure', className)}>
    <div className="scenario-editor-configure__text">{text}</div>
    <Button
      type="black-outline"
      className="scenario-editor-configure__button"
      onClick={onClick}
    >
      <div className="scenario-editor-configure__button-body">
        {hasPlayButton && (
          <div>
            <Icon
              name="PlayS"
              className="scenario-editor-configure__button-icon"
            />
          </div>
        )}
        <span>Как настроить этот шаг?</span>
      </div>
    </Button>
  </div>
);

export default ScenarioEditorConfigure;
