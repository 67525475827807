import React from 'react';
import Icon from '~/components/Icon';
import styles from './style.module.css';

interface INewElement {
  onAdd: () => void;
  name: string;
  isDisabled?: boolean;
}

export const NewElement = ({ name, onAdd, isDisabled }: INewElement) => {
  const onClick = () => {
    if (!isDisabled) {
      onAdd();
    }
  };

  return (
    <div
      className={styles.newElement}
      onClick={onClick}
    >
      <div className={styles.newMobile}>
        <Icon name={'DevicePhone'} className={styles.newIcon} />
      </div>
      <div className={styles.newDesktop}>
        <Icon name={'DeviceDesktop'} className={styles.newIcon} />
        <Icon name={'Plus'} className={styles.newPlus} />
        <div className={styles.newName}>{name}</div>
      </div>
    </div>
  );
};
