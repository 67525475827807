import React from 'react';
import styles from './style.module.css';

const TooltipForBlockCrossedPricePro: React.FC = () => {
  return (
    <div>
      Если у товаров в каталоге есть параметр &lt;
      <span className={styles.red_text}>oldprice</span>
      &gt;, то мы отобразим обе цены. Показываем если появилась скидка.
      <br /><br />
      <i>Пример:</i>
      <div className={styles.sample_wrapper}>
        <div>{(25000).toLocaleString()} &#8381;</div>
        <div>{(30000).toLocaleString()} &#8381;</div>
      </div>
    </div>
  );
};

export default TooltipForBlockCrossedPricePro;
