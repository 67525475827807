import { ListItem } from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import { InputDropdownItem } from '~/components/ui/InputDropdown';

export const LIST_ITEMS: ListItem[] = [
  {
    id: '1',
    text: 'Откройте страницу с любым товаром',
  },
  {
    id: '2',
    text: 'Скопируйте ссылку этой страницы',
  },
  {
    id: '3',
    text: 'Вставьте ее в это поле',
  },
];

export const DROPDOWN_ITEMS: InputDropdownItem<Operation>[] = [
  { key: 'CONTAINS', value: 'содержит' },
  { key: 'NOT_CONTAINS', value: 'не содержит' },
  { key: 'START_WITH', value: 'начинается с' },
  { key: 'EQUAL', value: 'соответствует' },
];
