import React, { useState } from 'react';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import Button from '~/components/ui/Button';
import FormItem from '~/components/ui/FormItem';
import InputDropdown from '~/components/ui/InputDropdown';
import Message from '~/components/ui/Message';
import { MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET } from '~/constants';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';
import commonStyles from '../style.module.css';

export const ImageEditElement = ({
  configuration,
  onEdit,
}: IEditElement) => {

  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const [fileName, setFileName] = useState<string>('');

  const onChangeImage = async (imageBase64: string, fileName: string): Promise<void> => {
    const changeImageResult = await mailingStore.processEmailImage(imageBase64, setIsShownErrorDialogBox);

    const imageCodes = changeImageResult ? [changeImageResult.code] : [];
    const imageUrl = changeImageResult ? changeImageResult?.url : '';
    changeImageResult && setFileName(`Загружен: ${fileName}`);
    mailingStore.mailingInstance.update({ imageCodes });

    onEdit(configuration.name, 'imagePath', imageUrl);
  };

  const [
    { isShownErrorDialogBox, titleErrorDialogBox, textErrorDialogBox },
    setIsShownErrorDialogBox,
  ] = useState<message>({
    isShownErrorDialogBox: false,
    titleErrorDialogBox: null,
    textErrorDialogBox: null,
  });

  const onChangeFile = async () => {
    setFileName('');
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.setAttribute('accept', 'image/jpeg, image/png');
    inputFile.click();
    inputFile.onchange = (e: any) => {
      const file = e.currentTarget.files[0];

      if (file.size > MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET) {
        setFileName('Превышен максимальный размер');
        setIsShownErrorDialogBox({
          isShownErrorDialogBox: true,
          titleErrorDialogBox: 'Превышен максимальный размер логотипа  :(',
          textErrorDialogBox: 'Попробуйте выбрать размер меньше',
        });

        return;
      }
      const readerFile = new FileReader();
      readerFile.readAsDataURL(file);
      readerFile.onloadend = async () => {
        const regex = new RegExp('(.*?).(jpg|png|jpeg)$');
        if (!regex.test(file?.name?.toLowerCase())) {
          setFileName('Тип не совпадает');
          setIsShownErrorDialogBox({
            isShownErrorDialogBox: true,
            titleErrorDialogBox: 'Тип не совпадает :(',
            textErrorDialogBox: 'Доступный формат: png, jpg, jpeg',
          });
        } else {
          await onChangeImage(readerFile.result.toString(), file.name);
        }
      };
    };
  };

  const closeErrorDialogBox = () => {
    setIsShownErrorDialogBox({
      isShownErrorDialogBox: false,
      titleErrorDialogBox: null,
      textErrorDialogBox: null,
    });
  };

  return (
    <>
      <div className={commonStyles.head}>{configuration.uiName}</div>

      <section className={commonStyles.section}>
        <FormItem
          noReservedPlaceForMessage
        >
          <Button onClick={onChangeFile} type="black-outline" block>
            Заменить изображение
          </Button>
          {fileName.length <= 30 ? fileName : `${fileName.slice(0, 30)}...`}
          <Message
            title={titleErrorDialogBox}
            textTop={textErrorDialogBox}
            applyText="Хорошо"
            visible={isShownErrorDialogBox}
            onApply={closeErrorDialogBox}
            onClose={closeErrorDialogBox}
            onExit={closeErrorDialogBox}
            showIcon={false}
          />
          <div className="scenario-editor-sidebar__informer">
            <p>
              Изображение должно быть формата PNG, JPG или JPEG.<br />Вес изображения – не больше 500 Кб.
            </p>
          </div>
        </FormItem>
      </section>

      <section className={commonStyles.section}>
        <FormItem
          title="Размер"
          noReservedPlaceForMessage
        >
          <InputDropdown
            className={'customClassName'}
            size="default"
            items={[
              { key: 'initial', value: 'Оригинал' },
              { key: 'cover', value: 'Заполнить' },
              { key: 'contain', value: 'Уместить' },
            ]}
            selectedKey={configuration.params.imageSize}
            onChange={(imageSize: TNotificationImageSize) => {
              onEdit(configuration.name, 'imageSize', imageSize);
            }}
          />
        </FormItem>
      </section>

      <section className={commonStyles.section}>
        <FormItem
          title="Цвет фона элемента"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={configuration.params.backgroundColor}
            alpha={Math.round(
              (configuration.params?.backgroundOpacity || 1) * 100
            )}
            onChange={({ alpha, color }) => {
              onEdit(configuration.name, 'backgroundColor', color);
              onEdit(configuration.name, 'backgroundOpacity', alpha / 100);
            }}
          />
        </FormItem>
      </section>
    </>
  );
};
