import React, { useEffect } from 'react';
import './style.css';

interface IFeedEditorNoIFrame {
  mobileFrameRef: React.MutableRefObject<HTMLDivElement>;
  feedString: string;
  addActionFeed: () => void;
}

const FeedMobile: React.FC<IFeedEditorNoIFrame> = ({
  feedString,
  addActionFeed,
  mobileFrameRef,
}) => {

  useEffect(() => {
    addActionFeed();
  }, [feedString]);

  return (
    <div
      ref={mobileFrameRef}
      className="feed-editor-step-selector-device-workspace-noframe-mobile__frame"
      dangerouslySetInnerHTML={{ __html: feedString }}
    />
  );
};

export default FeedMobile;
