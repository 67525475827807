import moment, { Moment } from 'moment';
import React from 'react';
import { NotificationProps } from '~/components/Notification';
import { ButtonEditor } from '~/components/ScenarioEditor/ScenarioEditorSidebar/ButtonEditor';
import { GeneralEditor } from '~/components/ScenarioEditor/ScenarioEditorSidebar/GeneralEditor';
import { ImageEditor } from '~/components/ScenarioEditor/ScenarioEditorSidebar/ImageEditor';
import BorderComponent from '~/components/notification-editor/BorderComponent';
import CouponBlock from '~/components/notification-editor/CouponBlock';
import FontSettings from '~/components/notification-editor/FontSettings';
import PayAttentionBlock from '~/components/notification-editor/PayAttentionBlock';
import TextTitleBlock from '~/components/notification-editor/TextTitleBlock';
import ValidityOfTheAction from '~/components/notification-editor/ValidityOfTheAction';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import TextAreaWithNote from '~/components/ui/TextArea/TextAreaWithNote';
import { DATE_FORMAT } from '~/constants';
import './style.css';

export type ScenarioEditorSidebarPreset =
  | 'general'
  | 'image'
  | 'title'
  | 'message'
  | 'expiration'
  | 'coupon'
  | 'button'
  | 'preview';

interface Props {
  preset: ScenarioEditorSidebarPreset;
  isMobile?: boolean;
  isSigned?: boolean;
  title?: string;
  notificationParams?: NotificationProps;
  onChangeTitle?: (title: string) => void;
  onChangeNotificationParams?: (params: NotificationProps) => void;
  onChangeSign?: (sign: boolean) => void;
}

const ScenarioEditorSidebar: React.FC<Props> = ({
  preset,
  isMobile,
  isSigned,
  title,
  notificationParams,
  onChangeTitle,
  onChangeNotificationParams,
  onChangeSign,
}) => {
  const nP: NotificationProps = notificationParams;

  const renderTitle = () => {
    return (
      <>
        <section className="scenario-editor-sidebar__section">
          <TextTitleBlock
            value={nP.title}
            onChange={(title) => {
              onChangeNotificationParams({ ...nP, title });
            }}
            hint="Вы можете ввести любой заголовок. Не пишите слишком длинный или сложный текст, клиенту будет тяжелее понять вашу мысль"
          />
        </section>
        <section className="scenario-editor-sidebar__section">
          <FontSettings
            isMobile={isMobile}
            type="title"
            hint={(
              <div>
                <p>
                  Тут вы можете выбрать шрифт надписи из выпадающего окна. Макет
                  слева отображает как будет выглядеть ваша надпись на виджете.
                </p>
                <p>
                  Здесь вы можете выбрать жирность надписи из выпадающего окна
                  (слева) и поменять размер надписи(справа). Макет слева
                  отображает как будет выглядеть ваша надпись на виджете.
                </p>
                <p>
                  Укажите HEX-кодировку цвета или выберите его на палитре. В
                  процентах укажите непрозрачность цвета (значение должно быть
                  кратно 10-ти). Макет слева отображает, как будет выглядеть
                  виджет в зависимости от выбранных значений.
                </p>
                <p>
                  Тут вы можете дополнительно обработать текст заголовка:
                  оставить текст прямым, курсив, без зачеркивания и
                  подчеркивания, надпись зачеркивается, надпись подчеркивается.
                  Для этого нужно нажать на соответствующий значок. Макет слева
                  отображает как будет выглядеть ваша надпись на виджете.
                </p>
              </div>
            )}
            data={{
              alpha: nP.titleOpacity * 100,
              color: nP.titleColor,
              fontFamily: nP.titleFontFamily,
              fontSize: isMobile ? nP.titleMobileFontSize : nP.titleFontSize,
              fontWeight: nP.titleFontWeight,
              isItalic: nP.titleFontItalic,
              isLineThrough: nP.titleTextLineThrough,
              isUnderline: nP.titleTextUnderline,
            }}
            onChange={(data) => {
              const props: Partial<INotification> = {
                titleOpacity: data.alpha / 100,
                titleColor: data.color,
                titleFontFamily: data.fontFamily,
                [isMobile ? 'titleMobileFontSize' : 'titleFontSize']:
                  data.fontSize,
                titleFontWeight: data.fontWeight,
                titleFontItalic: data.isItalic,
                titleTextLineThrough: data.isLineThrough,
                titleTextUnderline: data.isUnderline,
              };

              onChangeNotificationParams({
                ...nP,
                ...props,
              });
            }}
          />
        </section>
      </>
    );
  };

  const renderMessage = () => {
    return (
      <>
        <section className="scenario-editor-sidebar__section">
          <TextAreaWithNote
            hint="Вы можете ввести любой заголовок. Не пишите слишком длинный или сложный текст, клиенту будет тяжелее понять вашу мысль"
            title="Дополнительный текст"
            customNote="Не больше 120 символов"
            textAreaProps={{
              placeholder:
                'Чтобы применилась скидка, вставьте промокод в специальное поле при оформлении заказа.',
              maxLength: 120,
              rows: 4,
              value: nP.message,
              onChangeValue: (message) => {
                onChangeNotificationParams({ ...nP, message });
              },
            }}
          />
        </section>
        <section className="scenario-editor-sidebar__section">
          <FontSettings
            isMobile={isMobile}
            type="message"
            data={{
              alpha: nP.messageOpacity * 100,
              color: nP.messageColor,
              fontFamily: nP.messageFontFamily,
              fontSize: isMobile
                ? nP.messageMobileFontSize
                : nP.messageFontSize,
              fontWeight: nP.messageFontWeight,
              isItalic: nP.messageFontItalic,
              isLineThrough: nP.messageTextLineThrough,
              isUnderline: nP.messageTextUnderline,
            }}
            onChange={(data) => {
              const props: Partial<INotification> = {
                messageOpacity: data.alpha / 100,
                messageColor: data.color,
                messageFontFamily: data.fontFamily,
                [isMobile ? 'messageMobileFontSize' : 'messageFontSize']:
                  data.fontSize,
                messageFontWeight: data.fontWeight,
                messageFontItalic: data.isItalic,
                messageTextLineThrough: data.isLineThrough,
                messageTextUnderline: data.isUnderline,
              };

              onChangeNotificationParams({
                ...nP,
                ...props,
              });
            }}
            hint={(
              <div>
                <p>
                  Тут вы можете выбрать шрифт надписи из выпадающего окна.
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись на
                  виджете.
                </p>
                <p>
                  Здесь вы можете выбрать жирность надписи из выпадающего окна
                  (слева) и поменять размер надписи(справа).
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись на
                  виджете.
                </p>
                <p>
                  Укажите HEX-кодировку цвета или выберите его на палитре. В
                  процентах укажите непрозрачность цвета (значение должно быть
                  кратно 10-ти).
                  <br />
                  Макет слева отображает, как будет выглядеть виджет в
                  зависимости от выбранных значений.
                </p>
                <p>
                  Тут вы можете дополнительно обработать текст заголовка:
                  оставить текст прямым, курсив, без зачеркивания и
                  подчеркивания, надпись зачеркивается, надпись подчеркивается.
                  Для этого нужно нажать на соответствующий значок.
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись на
                  виджете.
                </p>
              </div>
            )}
          />
        </section>
      </>
    );
  };

  const renderCoupon = () => {
    return (
      <>
        <section className="scenario-editor-sidebar__section">
          <CouponBlock
            value={nP.coupon}
            onChange={(coupon) => {
              onChangeNotificationParams({ ...nP, coupon });
            }}
            hint="Вы можете ввести любое свое значение. Не указывайте слишком длинный или сложный промокод, клиенту будет сложно его использовать"
          />
        </section>
        <section className="scenario-editor-sidebar__section">
          <FontSettings
            isMobile={isMobile}
            type="coupon"
            data={{
              alpha: nP.couponOpacity * 100,
              color: nP.couponTextColor,
              fontFamily: nP.couponFontFamily,
              fontSize: isMobile ? nP.couponMobileFontSize : nP.couponFontSize,
              fontWeight: nP.couponFontWeight,
              isItalic: nP.couponFontItalic,
              isLineThrough: nP.couponTextLineThrough,
              isUnderline: nP.couponTextUnderline,
            }}
            onChange={(data) => {
              const props: Partial<INotification> = {
                couponOpacity: data.alpha / 100,
                couponTextColor: data.color,
                couponFontFamily: data.fontFamily,
                [isMobile ? 'couponMobileFontSize' : 'couponFontSize']:
                  data.fontSize,
                couponFontWeight: data.fontWeight,
                couponFontItalic: data.isItalic,
                couponTextLineThrough: data.isLineThrough,
                couponTextUnderline: data.isUnderline,
              };

              onChangeNotificationParams({
                ...nP,
                ...props,
              });
            }}
            hint={(
              <div>
                <p>
                  Тут вы можете выбрать шрифт надписи из выпадающего окна. Макет
                  слева отображает как будет выглядеть ваша надпись в поле
                  промокода.
                </p>
                <p>
                  Здесь вы можете выбрать жирность надписи из выпадающего окна
                  (слева) и поменять размер надписи(справа). Макет слева
                  отображает как будет выглядеть ваша надпись в поле промокода.
                </p>
                <p>
                  Укажите HEX-кодировку цвета или выберите его на палитре. В
                  процентах укажите непрозрачность цвета (значение должно быть
                  кратно 10-ти). Макет слева отображает, как будет выглядеть
                  виджет в зависимости от выбранных значений.
                </p>
                <p>
                  Тут вы можете дополнительно обработать надпись на промокоде:
                  оставить текст прямым, курсив, без зачеркивания и
                  подчеркивания, надпись зачеркивается, надпись подчеркивается.
                  Для этого нужно нажать на соответствующий значок. Макет слева
                  отображает как будет выглядеть ваша надпись в поле промокода.
                </p>
              </div>
            )}
          />
        </section>
        <section className="scenario-editor-sidebar__section">
          <BorderComponent
            limit={4}
            hint={() => {
              return (
                <>
                  В этом блоке вы можете:
                  <ul>
                    <li>
                      поменять толщину линии вокруг промокода (можно поставить
                      значения от 0 до 4)
                    </li>
                    <li>
                      выбрать цвет линии вокруг промокода и ее НЕпрозрачность в
                      процентах. Если вы не знаете обозначение желаемого цвета,
                      нажмите на квадрат с цветом. В открывшемся окне первой
                      строкой выберите нужный вам цветовой спектр и выберите
                      цвет на палитре выше (палитра выше соответствует
                      выбранному спектру), второй строкой выберите нужный вам
                      процент непрозрачности линии вокруг кнопки (можно
                      устанавливать только значения кратные 10-ти).
                    </li>
                    <li>
                      Скрыть/показать линию вокруг кнопки, нажав на значок глаза
                    </li>
                  </ul>
                  Макет слева отображает, как будет выглядеть промокод на
                  виджете.
                </>
              );
            }}
            borderWidth={nP.couponBorderWidth}
            onBorderWidthChange={(couponBorderWidth) => {
              onChangeNotificationParams({
                ...nP,
                couponBorderWidth,
              });
            }}
            colorPickerProps={{
              alpha: nP.couponBorderOpacity * 100,
              color: nP.couponBorderColor,
              onChange: ({ alpha, color }) => {
                onChangeNotificationParams({
                  ...nP,
                  couponBorderColor: color,
                  couponBorderOpacity: alpha / 100,
                });
              },
            }}
            enabled={nP.couponWithBorder}
            onChangeState={(couponWithBorder) => {
              onChangeNotificationParams({
                ...nP,
                couponWithBorder,
              });
            }}
          />
        </section>
      </>
    );
  };

  const renderExpiration = () => {
    return (
      <>
        <section className="scenario-editor-sidebar__section">
          <ValidityOfTheAction
            validityOfTheActionProps={{
              value: nP.expirationDate
                ? moment(nP.expirationDate, DATE_FORMAT)
                : undefined,
              onChange: (date: Moment) => {
                onChangeNotificationParams({
                  ...nP,
                  expirationDate: date.format(DATE_FORMAT),
                });
              },
            }}
            hint="Нажмите на поле ввода даты до и введите ее вручную через точку или при помощи календаря. Эта дата в качестве конечной будет отображаться на виджете, чтоб простимулировать посетителя на целевые действия."
          />
        </section>
        <section className="scenario-editor-sidebar__section">
          <FontSettings
            isMobile={isMobile}
            type="expiration"
            hint={(
              <div>
                <p>
                  Тут вы можете выбрать шрифт надписи из выпадающего окна.
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись.
                </p>
                <p>
                  Здесь вы можете выбрать жирность надписи из выпадающего окна
                  (слева) и поменять размер надписи(справа).
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись.
                </p>
                <p>
                  Укажите HEX-кодировку цвета или выберите его на палитре. В
                  процентах укажите непрозрачность цвета (значение должно быть
                  кратно 10-ти).
                  <br />
                  Макет слева отображает, как будет выглядеть виджет в
                  зависимости от выбранных значений.
                </p>
                <p>
                  Тут вы можете дополнительно обработать надпись на элементе
                  срок действия: оставить текст прямым, курсив, без зачеркивания
                  и подчеркивания, надпись зачеркивается, надпись
                  подчеркивается. Для этого нужно нажать на соответствующий
                  значок.
                  <br />
                  Макет слева отображает, как будет выглядеть ваша надпись.
                </p>
              </div>
            )}
            data={{
              alpha: nP.expirationDateOpacity * 100,
              color: nP.expirationDateColor,
              fontFamily: nP.expirationDateFontFamily,
              fontSize: isMobile
                ? nP.expirationMobileDateFontSize
                : nP.expirationDateFontSize,
              fontWeight: nP.expirationDateFontWeight,
              isItalic: nP.expirationDateFontItalic,
              isLineThrough: nP.expirationDateTextLineThrough,
              isUnderline: nP.expirationDateTextUnderline,
            }}
            onChange={(data) => {
              const props: Partial<INotification> = {
                expirationDateOpacity: data.alpha / 100,
                expirationDateColor: data.color,
                expirationDateFontFamily: data.fontFamily,
                [isMobile
                  ? 'expirationMobileDateFontSize'
                  : 'expirationDateFontSize']: data.fontSize,
                expirationDateFontWeight: data.fontWeight,
                expirationDateFontItalic: data.isItalic,
                expirationDateTextLineThrough: data.isLineThrough,
                expirationDateTextUnderline: data.isUnderline,
              };

              onChangeNotificationParams({
                ...nP,
                ...props,
              });
            }}
          />
        </section>
      </>
    );
  };

  const renderPreview = () => {
    return (
      <>
        <section className="scenario-editor-sidebar__section">
          <FormItem
            title="Название виджета"
            hint="Введите название вашего виджета. Рекомендуем сделать его уникальным и узнаваемым. Так, вам будет удобнее отслеживать статистику по этому виджету."
          >
            <Input value={title} onChangeValue={onChangeTitle} block />
          </FormItem>
        </section>
        <section className="scenario-editor-sidebar__section">
          <PayAttentionBlock
            checkboxProps={{
              checked: isSigned,
              onCheck: onChangeSign,
            }}
            isMobileConst={notificationParams.isMobileConst}
          />
        </section>
      </>
    );
  };

  return (
    <div className="scenario-editor-sidebar">
      {preset === 'general' && (
        <GeneralEditor
          notificationParams={notificationParams}
          onChangeNotificationParams={onChangeNotificationParams}
        />
      )}
      {preset === 'image' && (
        <ImageEditor
          notificationParams={notificationParams}
          onChangeNotificationParams={onChangeNotificationParams}
        />
      )}
      {preset === 'title' && renderTitle()}
      {preset === 'message' && renderMessage()}
      {preset === 'coupon' && renderCoupon()}
      {preset === 'button' && (
        <ButtonEditor
          notificationParams={notificationParams}
          onChangeNotificationParams={onChangeNotificationParams}
        />
      )}
      {preset === 'expiration' && renderExpiration()}
      {preset === 'preview' && renderPreview()}
    </div>
  );
};

export default ScenarioEditorSidebar;
