import React from 'react';
import { IServices } from '~/stores/settingsStore';
import ServiceBox from './ServiceBox';

import styles from './style.module.css';

interface ISwitchService {
  tariffPrice: string;
  visitsRange: string;
  services: IServices[];
  remoteService: (service: string, active: boolean) => void;
  isRedirect: boolean;
  withIndividualCalculation: boolean;
  isLastActiveService: boolean;
  inProcessing: boolean;
  resetProcessing: () => void;
  tarplanConnectionDate: string;
  isNotPaidTariff: boolean;
}

const SwitchService: React.FC<ISwitchService> = ({
  tariffPrice,
  visitsRange,
  services,
  remoteService,
  isRedirect,
  withIndividualCalculation,
  isLastActiveService,
  inProcessing,
  resetProcessing,
  tarplanConnectionDate,
  isNotPaidTariff,
}) => {
  const amountServicesWithUnpaidTariff = isNotPaidTariff ?
    services.reduce((acc, service) => {
      return service.isActive ? (acc + Number(service.price)) : acc;
    }, 0) : tariffPrice;

  return (
    <div>
      <div className={styles.title}>Ваш тариф</div>
      <div className={styles.wrapper}>
        <div className={styles.price_wrapper}>
          <span className={styles.price}>
            {withIndividualCalculation
              ? 'Индивидуальные условия'
              : `${amountServicesWithUnpaidTariff} ₽/мес`}
          </span>
          {!withIndividualCalculation && (
            <div className={styles.visits}>{visitsRange}</div>
          )}
        </div>
        <div className={styles.box}>
          {services.map((service: IServices) => {
            return (
              <ServiceBox
                service={service}
                remoteService={remoteService}
                isRedirect={isRedirect}
                key={service.code}
                isLastActiveService={isLastActiveService}
                inProcessing={inProcessing}
                resetProcessing={resetProcessing}
                withIndividualCalculation={withIndividualCalculation}
                tarplanConnectionDate={tarplanConnectionDate}
                tariffPrice={tariffPrice}
                isNotPaidTariff={isNotPaidTariff}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SwitchService;
