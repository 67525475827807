import cx from 'classnames';
import { TFeedCard } from 'feed';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { FEED_DEF_CONFIGURATION, FEED_DEF_MOBILE_CONFIGURATION } from '~/components/Feed/constants';
import Icon from '~/components/Icon';
import StepManual from '~/components/generic/StepManual';
import StepSelectorAndDesign from '~/components/generic/StepSelectorAndDesign';
import FooterButton from '~/components/generic/StepSelectorAndDesign/FooterButton';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Tabs from '~/components/ui/Menu/Tabs';
import Spinner from '~/components/ui/Spinner';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import StepFeedProduct from '~/screens/FeedLiteScreen/components/FeedConstructor/StepFeedProduct';
import StepLinkPages from '~/screens/FeedLiteScreen/components/FeedConstructor/StepLinkPages';
import {
  CheckedCountPage,
  FeedLiteStore,
} from '~/screens/FeedLiteScreen/store/feedLiteStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import { checkOnHttp } from '~/utils/validationUtils/checkOnHttp';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';
import './style.css';

const regexpSeparator = /^[a-zа-яё0-9\#\:\_\-\/\.]+$/;

const FeedConstructor = observer(
  ({
    setIsCreateFeedVideoShown,
  }: {
    setIsCreateFeedVideoShown: (v: boolean) => void;
  }) => {
    const { feedLiteStore, authStore } = useStores() as {
      feedLiteStore: FeedLiteStore;
      authStore: AuthStore;
    };

    const {
      isConstructorLiteOpen,
      setIsConstructorLiteOpen,
      reset,
      isIFrameAccessible,
      isPlacementPageInvalid,
      setIsPlacementPageInvalid,
      hasClickedOnInput,
      setHasClickedOnInput,
      isRechoosingSelector,
      setIsRechoosingSelector,
      isRechoosingSelectorMobile,
      setIsRechoosingSelectorMobile,
      emptyFeed,
      feed,
      isFetchingFeed,
      device,
      setDevice,
      setIsIFrameAccessible,
      lastSavedConfiguration,
      setLastSavedConfiguration,
      fetchSelector,
      setSelectorManual,
      products,
      fetchLiteStatistics,
      getProducts,
      getLength,
      checkedCountPage,
      setCheckedCountPage,
      isDisplayConditionsInvalid,
      setIsLinkPageDisplayConditionsInvalid,
      feedProcessId,
      constructorStore,
      blinkFinalDialog,
      setIsMessageBeforeExitingOpen,
    } = feedLiteStore;
    const { currentStep, changeCurrentStep, changeCurrentStepNextOrBack } =
      constructorStore;

    const [display, setDisplay] = useState<'flex' | 'none'>('none');
    const [contentHeight, setContentHeight] = useState('0');
    const [isSwitchFrameButton, setIsSwitchFrameButton] = useState(false);
    const [
      isOpenDialogManuallyAddingSelector,
      setIsOpenDialogManuallyAddingSelector,
    ] = useState<boolean>(false);
    const [errorMessageOnLinkPage, setErrorMessageOnPlacementPage] =
      useState('');
    const [
      errorMessageOnLinkPageDisplayConditions,
      setErrorMessageOnLinkPageDisplayConditions,
    ] = useState('');

    const containerRef = useRef<HTMLDivElement>();

    const isChanging = !!feed?.get('id');
    const currentFeed = feed || emptyFeed;

    const handleResize = () => {
      setDisplay('none');
      const height = containerRef?.current.getBoundingClientRect().height;
      setDisplay('flex');
      setContentHeight(`${height}px`);
    };

    useEffect(() => {
      handleResize();
      const debouncedHandleResize = debounce(handleResize, 400);
      window.addEventListener('resize', debouncedHandleResize);

      return () => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
    }, []);

    const checkOnDomain = (domain: string) => {
      const domains = authStore?.currentOrg?.domain || [];
      let isValid = false;
      domains.map((baseDomain: string, key: number) => {
        if (!isValid && domain.includes(baseDomain)) {
          isValid = true;
        }
      });

      return isValid;
    };

    const saveFeedLite = async (stage: Stage, status?: string) => {
      try {
        const response = await currentFeed.saveLite(
          stage,
          products,
          currentFeed.get('id'),
          feedProcessId,
          checkedCountPage,
          status
        );
        if (stage === 'TEMP') {
          const tempId = response?.data.payload[0].id;
          currentFeed.update({ id: tempId });
        }
        if (stage !== 'TEMP') blinkFinalDialog();
      } catch (e) {}
    };

    const handleClickNext = () => {
      if (currentStep === 'StepLinkPages') {
        saveFeedLite('TEMP');
      }
      changeCurrentStepNextOrBack(1);
    };
    const handleBackClick = () => changeCurrentStepNextOrBack(-1);
    const handleChangeStep = (step: EditorSteps) => {
      if (step === 'StepSelector') {
        saveFeedLite('TEMP');
      }
      changeCurrentStep(step);
    };

    const isButtonDisabled =
      currentStep === 'StepFeedProduct'
        ? getLength < 1
        : currentStep === 'StepLinkPages'
        ? checkedCountPage === CheckedCountPage.ONE
          ? !currentFeed.get('urlPattern') || isPlacementPageInvalid
          : !currentFeed.get('urlPattern') ||
            !currentFeed.get('displayConditions')?.url ||
            isPlacementPageInvalid ||
            isDisplayConditionsInvalid
        : currentStep === 'StepSelector'
        ? !(currentFeed.get('selector') || currentFeed.get('selectorMobile'))
        : currentStep === 'StepDesign'
        ? !(currentFeed.get('selector') || currentFeed.get('selectorMobile'))
        : false;

    const validateUrl = () => {
      const urlPattern = currentFeed.get('urlPattern');
      const operation = currentFeed.get('displayConditions')?.operation;
      const url = currentFeed.get('displayConditions')?.url;
      if (!isValidUrl(urlPattern) || !checkOnHttp(urlPattern)) {
        setErrorMessageOnPlacementPage(
          'Ссылка на страницу должна быть полной - включая «http://» или «https://»'
        );
        setIsPlacementPageInvalid(true);
      } else if (!checkOnDomain(urlPattern)) {
        setErrorMessageOnPlacementPage(
          'Ссылка не содержит указанный в профиле домен. Исправьте ссылку или вставьте другую'
        );
        setIsPlacementPageInvalid(true);
      } else {
        setErrorMessageOnPlacementPage(
          'Ссылка не содержит указанный в профиле домен. Исправьте ссылку или вставьте другую'
        );
        setIsPlacementPageInvalid(false);
      }

      if (checkedCountPage === CheckedCountPage.MULTIPLE) {
        if (operation === 'START_WITH' && !urlPattern.startsWith(url)) {
          setErrorMessageOnPlacementPage(
            'Ссылка не содержит общую часть, указанную во 2-ом пункте'
          );
          setIsPlacementPageInvalid(true);
        } else if (!urlPattern.includes(url)) {
          setErrorMessageOnPlacementPage(
            'Ссылка не содержит общую часть, указанную во 2-ом пункте'
          );
          setIsPlacementPageInvalid(true);
        }
      }
    };

    const validateDisplayConditions = () => {
      const url = currentFeed.get('displayConditions')?.url;
      const isValid = !regexpSeparator.test(url);

      if (isValid) {
        setErrorMessageOnLinkPageDisplayConditions(
          'Часть ссылки может содержать латинские и кириллические буквы в нижнем регистре [a-z], цифры [0-9], точку[.], слеш [/], дефис [-], нижнее подчеркивание [_], двоеточие [:], решетка [#].'
        );
      } else setErrorMessageOnLinkPageDisplayConditions(' ');

      setIsLinkPageDisplayConditionsInvalid(isValid);
    };

    const onBlur = () => {
      !hasClickedOnInput && setHasClickedOnInput(true);

      if (currentStep === 'StepLinkPages') {
        validateUrl();
      }
    };

    const handleSave = async () => {
      await saveFeedLite('FINAL');
      fetchLiteStatistics();
      setIsConstructorLiteOpen(false);
      reset();
    };

    const handleDraftSave = async () => {
      await saveFeedLite('FINAL', 'DRAFT');
      fetchLiteStatistics();
      setIsConstructorLiteOpen(false);
      reset();
    };

    const onChangeSeparator = (operation: IScenarioStepURLOperation) => {
      currentFeed.setDisplayOperation(operation);
      validateDisplayConditions();
      validateUrl();
    };

    const onChangeDisplayConditions = (displayConditions: string) => {
      currentFeed.setDisplayUrl(displayConditions);
      validateDisplayConditions();
      validateUrl();
    };

    const onChangeUrl = (urlPattern: string) => {
      currentFeed.setUrlPattern(urlPattern);
      isRechoosingSelector && setIsRechoosingSelector(false);
      isRechoosingSelectorMobile && setIsRechoosingSelectorMobile(false);
      validateUrl();
    };

    const productsToCards = (
      products: FeedConstructorModel[]
    ): TFeedCard[] => {
      return products.map((productModel: FeedConstructorModel) => {
        return {
          id: `${productModel.get('order')}`,
          urlLink: '',
          imgLink: productModel.get('imgLink'),
          name: productModel.get('name'),
          description: productModel.get('description'),
          price: Number(productModel.get('price')),
          crossedPrice: productModel.has('crossedPrice')
            ? Number(productModel.get('crossedPrice'))
            : null,
          labels: productModel.get('labels'),
        };
      });
    };

    return (
      <Drawer
        visible={isConstructorLiteOpen}
        onClose={() => setIsMessageBeforeExitingOpen(true)}
        title={`${isChanging ? 'Редактирование' : 'Создание'} ленты Lite`}
        size="large"
        className={cx('feed-constructor-screen__drawer', {
          _center: isFetchingFeed,
        })}
      >
        {getLength > 0 && (
          <div className="scenarios-screen-widget-button">
            <Button
              type="black-outline"
              className="scenario-editor-configure__button"
              onClick={() => setIsCreateFeedVideoShown(true)}
            >
              <div className="scenario-editor-configure__button-body">
                <div>
                  <Icon
                    name="PlayS"
                    className="scenario-editor-configure__button-icon"
                  />
                </div>
                <span>Как создать ленту рекомендаций?</span>
              </div>
            </Button>
          </div>
        )}
        {isFetchingFeed && <Spinner />}
        {!isFetchingFeed && (
          <>
            <Tabs
              className={'feed-constructor-screen__tabs'}
              items={[
                { key: 'StepFeedProduct', title: '1. Товары', disabled: false },
                {
                  key: 'StepLinkPages',
                  title: '2. Страницы размещения',
                  disabled: getLength < 1,
                },
                {
                  key: 'StepManual',
                  title: '3. Продажи',
                  disabled:
                    getLength < 1 || checkedCountPage === CheckedCountPage.ONE
                      ? !currentFeed.get('urlPattern') || isPlacementPageInvalid
                      : !currentFeed.get('urlPattern') ||
                        isPlacementPageInvalid ||
                        isDisplayConditionsInvalid,
                },
                {
                  key: 'StepSelector',
                  title: '4. Выбор расположения',
                  disabled:
                    getLength < 1 || checkedCountPage === CheckedCountPage.ONE
                      ? !currentFeed.get('urlPattern') || isPlacementPageInvalid
                      : !currentFeed.get('urlPattern') ||
                        isPlacementPageInvalid ||
                        isDisplayConditionsInvalid,
                },
                {
                  key: 'StepDesign',
                  title: '5. Дизайн',
                  disabled:
                    getLength < 1 || checkedCountPage === CheckedCountPage.ONE
                      ? !currentFeed.get('urlPattern') ||
                        isPlacementPageInvalid ||
                        !(
                          currentFeed.get('selector') ||
                          currentFeed.get('selectorMobile')
                        )
                      : !currentFeed.get('urlPattern') ||
                        isPlacementPageInvalid ||
                        isDisplayConditionsInvalid ||
                        !(
                          currentFeed.get('selector') ||
                          currentFeed.get('selectorMobile')
                        ),
                },
              ]}
              value={currentStep.toString()}
              onChange={activeKey =>
                handleChangeStep(activeKey as EditorSteps)
              }
            />
            <div
              ref={containerRef}
              className={cx('feed-constructor-screen__container', {
                _iframe:
                  currentStep === 'StepSelector' ||
                  currentStep === 'StepDesign',
                _background: currentStep === 'StepFeedProduct',
                background_gray: currentStep === 'StepManual',
              })}
            >
              <div
                style={{
                  display,
                  height: contentHeight,
                }}
                className={cx('feed-constructor-screen__wrapper', {
                  _iframe:
                    currentStep === 'StepSelector' ||
                    currentStep === 'StepDesign',
                  _fullWidth:
                    currentStep === 'StepSelector'
                      ? !isIFrameAccessible
                      : isIFrameAccessible,
                })}
              >
                {currentStep === 'StepFeedProduct' && <StepFeedProduct />}
                {currentStep === 'StepManual' && <StepManual />}
                {currentStep === 'StepLinkPages' && (
                  <StepLinkPages
                    value={currentFeed.get('urlPattern')}
                    onChangeUrl={onChangeUrl}
                    onChangeSeparator={onChangeSeparator}
                    onChangeDisplayConditions={onChangeDisplayConditions}
                    isInvalid={isPlacementPageInvalid}
                    isDisplayConditionsInvalid={isDisplayConditionsInvalid}
                    onBlur={onBlur}
                    errorMessage={errorMessageOnLinkPage}
                    errorMessageSeparator={
                      errorMessageOnLinkPageDisplayConditions
                    }
                    checkedCountPage={checkedCountPage}
                    setCheckedCountPage={setCheckedCountPage}
                    operation={currentFeed.get('displayConditions')?.operation}
                    url={currentFeed.get('displayConditions')?.url}
                  />
                )}

                {(currentStep === 'StepSelector' ||
                  currentStep === 'StepDesign') && (
                  <StepSelectorAndDesign
                    id={currentFeed.get('id')}
                    urlPattern={currentFeed.get('urlPattern')}
                    selector={currentFeed.get('selector')}
                    setSelector={currentFeed.setSelector}
                    selectorMobile={currentFeed.get('selectorMobile')}
                    setSelectorMobile={currentFeed.setSelectorMobile}
                    device={device}
                    setDevice={setDevice}
                    currentStep={currentStep}
                    isIFrameAccessible={isIFrameAccessible}
                    setIsIFrameAccessible={setIsIFrameAccessible}
                    feedConfiguration={{
                      ...(
                        device === 'mobile'
                          ? FEED_DEF_MOBILE_CONFIGURATION
                          : FEED_DEF_CONFIGURATION
                      ),
                      ...currentFeed.get('configuration'),
                    }}
                    contentHeight={contentHeight}
                    isRechoosingSelector={isRechoosingSelector}
                    setIsRechoosingSelector={setIsRechoosingSelector}
                    lastSavedConfiguration={lastSavedConfiguration}
                    setLastSavedConfiguration={setLastSavedConfiguration}
                    onChange={currentFeed.setConfiguration}
                    fetchSelector={fetchSelector}
                    setSelectorManual={setSelectorManual}
                    setIsSwitchFrameButton={setIsSwitchFrameButton}
                    isOpenDialogManuallyAddingSelector={
                      isOpenDialogManuallyAddingSelector
                    }
                    setIsOpenDialogManuallyAddingSelector={
                      setIsOpenDialogManuallyAddingSelector
                    }
                    cards={productsToCards(getProducts)}
                    feedMode={'lite'}
                    reqRec={'temp'}
                  />
                )}
              </div>
            </div>

            <DrawerFooter
              className="feed-constructor-drawer-footer__container"
              actions={(
                <>
                  {currentStep !== 'StepFeedProduct' && (
                    <Button type="grey" onClick={handleBackClick}>
                      Назад
                    </Button>
                  )}
                  {(
                    <Button
                      className="feed-constructor-screen__drawer-next-button"
                      onClick={
                        currentStep !== 'StepDesign'
                          ? handleClickNext
                          : handleSave
                      }
                      disabled={isButtonDisabled}
                    >
                      {currentStep !== 'StepDesign' ? 'Далее' : 'Сохранить'}
                    </Button>
                  )}
                  {(
                    <Button
                      type="text"
                      className="feed-constructor-screen__drawer-draft-button"
                      onClick={handleDraftSave}
                    >
                      Сохранить как черновик
                    </Button>
                  )}
                  {currentStep === 'StepSelector' && (
                    <Button
                      className="feed-constructor-screen__drawer-addition-selector"
                      type="text"
                      onClick={() =>
                        setIsOpenDialogManuallyAddingSelector(true)
                      }
                    >
                      Указать селектор вручную
                    </Button>
                  )}
                  {isSwitchFrameButton && currentStep === 'StepSelector' && (
                    <FooterButton
                      isIFrameAccessible={isIFrameAccessible}
                      setIsIFrameAccessible={setIsIFrameAccessible}
                    />
                  )}
                  {!isIFrameAccessible &&
                    !isRechoosingSelector &&
                    !isRechoosingSelectorMobile &&
                    currentFeed.get('selector') &&
                    currentFeed.get('selectorMobile') &&
                    currentStep === 'StepSelector' && (
                      <Button
                        className="feed-screen__drawer-clear-button"
                        type="text"
                        onClick={() => {
                          setIsRechoosingSelector(true);
                          setIsRechoosingSelectorMobile(true);
                        }}
                      >
                        Изменить расположение
                      </Button>
                    )}
                </>
              )}
              withSidebar={currentStep === 'StepDesign'}
              isFeed
            />
          </>
        )}
      </Drawer>
    );
  }
);

export default FeedConstructor;
