import React, { useState } from 'react';
import { CustomTooltip } from '~/components/generic/CustomTooltip';
import Message from '~/components/ui/Message';
import { MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET } from '~/constants';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';

interface IImageEditor {
  src: string;
  changeImage: (v: { code: string; url: string }) => void;
}

export const ImageEditor = ({ src, changeImage }: IImageEditor) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const [errorMessage, setErrorMessage] = useState({
    isShown: false,
    title: '',
    text: '',
  });

  const onCloseError = () => {
    setErrorMessage({
      isShown: false,
      title: '',
      text: '',
    });
  };

  const onChangeImage = async (
    imageBase64: string,
    fileName: string
  ): Promise<void> => {
    const changeImageResult = await quizStore.processImage(
      imageBase64,
      setErrorMessage
    );

    const imageUrl = !!changeImageResult ? changeImageResult?.url : {};
    // @ts-ignore typings
    imageUrl && changeImage(changeImageResult);
  };

  const onChangeFile = async () => {
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.setAttribute('accept', 'image/jpeg, image/png');
    inputFile.click();
    inputFile.onchange = (e: any) => {
      const file = e.currentTarget.files[0];

      if (file.size > MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET) {
        setErrorMessage({
          isShown: true,
          title: 'Превышен максимальный размер логотипа  :(',
          text: 'Попробуйте выбрать размер меньше',
        });

        return;
      }
      const readerFile = new FileReader();
      readerFile.readAsDataURL(file);
      readerFile.onloadend = async () => {
        const regex = new RegExp('(.*?).(jpg|png|jpeg)$');
        if (!regex.test(file?.name?.toLowerCase())) {
          setErrorMessage({
            isShown: true,
            title: 'Тип не совпадает :(',
            text: 'Доступный формат: png, jpg, jpeg',
          });
        } else {
          await onChangeImage(readerFile.result.toString(), file.name);
        }
      };
    };
  };

  const style = { backgroundImage: `url(${src})`, backgroundSize: 'cover' };

  return (
    <>
      {errorMessage.isShown && (
        <Message
          visible
          title={'Ошибка'}
          textTop={errorMessage.title}
          textBottom={errorMessage.text}
          onExit={onCloseError}
          onClose={onCloseError}
        />
      )}
      <CustomTooltip
        className={styles.tooltip}
        tooltipText={
          'Загружаемое изображение должно быть формата PNG, JPG или JPEG, весом не более 500Кб'
        }
      >
      <div
        className={styles.imageContainerExample}
        style={style}
        onClick={onChangeFile}
      />
      </CustomTooltip>
    </>
  );
};
