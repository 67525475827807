import cx from 'classnames';
import React, { useState } from 'react';
import { Code } from '~/components/generic/Code';
import Accordion from '~/components/ui/Accordion';
import Label, { Color } from '~/components/ui/Label';
import SwitchButton from '~/components/ui/SwitchButton';
import {
  codeFragment1,
  codeFragment2,
  codeFragment3,
  switchButtonsCreatingInstructions,
} from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/consts';
import styles from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/style.module.css';

const generation = (
  <div className={styles.accordionContent}>
    <p className={styles.normalText}>
      Самый простой и быстрый способ создать файл sitemap.xml с помощью онлайн
      генераторов, такие как:
    </p>
    <Label color={Color.PaleGreen} isBorderRadiusBig>
      Mysitemapgenerator
    </Label>
    <Label color={Color.PaleGreen} isBorderRadiusBig>
      Xml-sitemaps
    </Label>
    <Label color={Color.PaleGreen} isBorderRadiusBig>
      Gensitemap
    </Label>
  </div>
);

export const CreatingInstructions = () => {
  const [activeKey, setActiveKey] = useState<string>('generation');

  return (
    <div>
      <Accordion
        title={'1. Создание файла sitemap.xml'}
        className={styles.accordion}
      >
        <div className={styles.accordionContent}>
          <p className={styles.greyText}>
            Для создания SiteMap у вас есть два способа:
          </p>
          <SwitchButton
            activeKey={activeKey}
            onClick={setActiveKey}
            buttons={switchButtonsCreatingInstructions}
            className={styles.switcher}
            isEqualWidth={false}
          />
        </div>
        {activeKey === 'generation' && generation}
        {activeKey === 'manually' && (
          <div className={styles.accordionContent}>
            <p className={styles.listHeader}>
              Для создания файла sitemap.xml следуйте простым шагам:
            </p>
            <ol>
              <li className={styles.listItem}>
                Определите структуру вашего сайта, это могут быть главная
                страница, разделы, подразделы, посты и т.д.
              </li>
              <li className={styles.listItem}>
                Создайте XML-файл с именем "sitemap.xml" в текстовом редакторе
                или IDE, используйте кодировку <u>UTF-8</u>.
              </li>
              <li className={styles.listItem}>
                Добавьте в файл корневой элемент. Вставьте следующий код в ваш
                файл sitemap.xml:
                <Code lines={codeFragment1} className={styles.code} />
              </li>
              <li className={styles.listItem}>
                <p>
                  Добавьте URL-адреса страниц внутри корневого элемента{' '}
                  <span className={styles.tag}>{`<urlset>`}</span>, добавьте
                  элементы <span className={styles.tag}>{`<url> `}</span>
                  для каждой страницы вашего сайта, максимальное количество
                  ссылок 50 000, вы можете разделить sitemap на несколько
                  отдельных файлов и указать их в файле индекса Sitemap.
                </p>
                <p>Пример кода для одной страницы:</p>
                <Code lines={codeFragment2} className={styles.code} />
                <span
                  style={{ width: '85px', verticalAlign: 'top' }}
                  className={cx(styles.tag, styles.inlineBlock)}
                >{`<loc>`}</span>
                <span className={styles.inlineBlock} style={{ width: '480px' }}>
                  — URL-адрес страницы.
                </span>
                <br />
                <span
                  className={cx(styles.tag, styles.inlineBlock)}
                  style={{ width: '85px', verticalAlign: 'top' }}
                >
                  {`<lastmod>`}
                </span>
                <span className={styles.inlineBlock} style={{ width: '480px' }}>
                  — Дата последнего изменения страницы, формат:
                  ГГГГ-ММ-ДДTчч:мм:сс+00:00.
                </span>
              </li>
              <li className={styles.listItem}>
                <p>
                  Добавьте остальные URL-адреса: Повторите 4 шаг для каждой
                  страницы вашего сайта, добавляя элементы
                  <span className={styles.tag}>{`<url> `}</span> с информацией о
                  каждой странице.
                </p>
                <p>Общий пример кода:</p>
                <Code lines={codeFragment3} className={styles.code} />
                <span
                  style={{ width: '85px', verticalAlign: 'top' }}
                  className={cx(styles.tag, styles.inlineBlock)}
                >{`<loc>`}</span>
                <span className={styles.inlineBlock} style={{ width: '480px' }}>
                  — URL-адрес страницы.
                </span>
                <br />
                <span
                  className={cx(styles.tag, styles.inlineBlock)}
                  style={{ width: '85px', verticalAlign: 'top' }}
                >
                  {`<lastmod>`}
                </span>
                <span className={styles.inlineBlock} style={{ width: '480px' }}>
                  — Дата последнего изменения страницы, формат:
                  ГГГГ-ММ-ДДTчч:мм:сс+00:00.
                </span>
              </li>
              <li className={styles.listItem}>
                Сохраните файл, максимальный размер одного файла в несжатом виде
                50 МБ
              </li>
            </ol>
          </div>
        )}
      </Accordion>
      <Accordion title={'2. Добавление sitemap'} className={styles.accordion}>
        <div className={styles.accordionContent}>
          <p className={styles.boldText}>
            Загрузите файл sitemap.xml на сервер в корневую директорию вашего
            веб-сайта или в папку, которая доступна публично.
          </p>
          <p className={styles.greyText}>
            После загрузки файла sitemap.xml, убедитесь, что он открывается
            корректно по адресу
          </p>
          <Label color={Color.PaleGreen} isBorderRadiusBig>
            https://www.example.com/sitemap.xml
          </Label>
          <p className={styles.greyText}>заменив на адрес вашего сайта</p>
          <Label color={Color.PaleGreen} isBorderRadiusBig>
            www.example.com
          </Label>
        </div>
      </Accordion>
    </div>
  );
};
