import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

const Service = {
  getCurrentServices: (): AxiosPromise<IResponse> =>
    actionGet('/admin/totService', { operation: 'current' }),

  getAvailableServices: (): AxiosPromise<IResponse> =>
    actionGet('/admin/totService', { operation: 'available' }),

  setServices: (services: string[]): AxiosPromise<IResponse> =>
    actionSet(
      '/admin/totService',
      { operation: 'set' },
      { '@type': 'Services', 'codes': services }
    ),
};

export default Service;
