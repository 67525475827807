import React, { useState } from 'react';
import Icon from '~/components/Icon';
import { HorizontalScroll } from '~/components/generic/HorizontalScroll';
import { columns } from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/consts';
import styles from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/style.module.css';
import { Question } from '~/screens/QuizScreen/components/QuizStatistic/QuestionsStatistics/Question';
import { IQuestion } from '~/screens/QuizScreen/stores/quizStore';

export const TableRow = (value: {
  [x: string]: React.ReactNode;
  isShown: boolean;
  questions?: any;
}) => {
  const { questions, isShown } = value;
  const [isDetailShown, setIsDetailShown] = useState<boolean>(isShown);
  const iconName = isDetailShown ? 'ArrowSUp' : 'ArrowSDown';

  const onRowClick = () => setIsDetailShown(!isDetailShown);

  return (
    <div className={styles.tableRow}>
      <div className={styles.tableDataRow} onClick={onRowClick}>
        {columns.map((v, key) => {
          return (
            <div key={key} className={styles.tableCell}>
              {value[v.dataIndex]}
              {v.dataIndex === 'answers' && (
                <Icon className={styles.tableRowIcon} name={iconName} />
              )}
            </div>
          );
        })}
      </div>

      {isDetailShown && (
        <HorizontalScroll>
          <>
            {questions.map((v: IQuestion, k: number) => (
              <Question {...{ ...v, answerStatistics: v?.answers }} key={k} />
            ))}
          </>
        </HorizontalScroll>
      )}
    </div>
  );
};
