import { action, observable, IObservableArray } from 'mobx';
import { Users } from '~/api';
import UserModel from '~/models/UserModel';
import Store from '~/stores/store';

export default class UsersStore extends Store {
  @observable users: IObservableArray<UserModel> = [] as IObservableArray<UserModel>;
  @observable isUsersLoading = false;
  @observable isUserDeleting = false;

  @action
  fetchUsers = async ({ pagingCount, pagingFrom }: IListUsers) => {
    this.isUsersLoading = true;

    try {
      const response = await Users.list({ pagingCount, pagingFrom });
      const users = response.data.payload.map(u => new UserModel(this, u));
      if (users.length) {
        this.users.replace(users);

        return true;
      }

      return false;
    } catch (e) {
      throw e;
    } finally {
      this.isUsersLoading = false;
    }
  }

  @action
  deleteUser = async (login: string) => {
    const user = this.users.find(u => u.get('login') === login);

    this.isUserDeleting = true;

    try {
      await user.remove();
      this.users.remove(user);
    } catch (e) {
      throw e;
    } finally {
      this.isUserDeleting = false;
    }
  }
}
