import { Typography } from 'antd';
import React from 'react';

import './style.css';

interface Props {
  children: React.ReactNode;
  copyText: string;
}

const { Paragraph, Text } = Typography;

const CodeSnippet: React.FC<Props> = ({
  copyText,
  children,
}) => (
  <Paragraph>
    <div className="code-block">
      <Text className="copy-button" copyable={{ text: copyText }} />
      <pre>
        {children}
      </pre>
    </div>
  </Paragraph>
);

export default CodeSnippet;
