import { observer } from 'mobx-react';
import React from 'react';
import { WrapperContentDrawer } from '~/components/generic/WrapperContentDrawer';
import Drawer from '~/components/ui/Drawer';
import MassMailingConstructorContent from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent';
import MassMailingConstructorFooter from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorFooter';
import MassMailingConstructorTabs from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorTabs';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import './style.css';

interface IMassMailingConstructor {
  isConstructorOpen: boolean;
}

const MassMailingConstructor: React.FC<IMassMailingConstructor> = observer(
  ({ isConstructorOpen }) => {
    const {
      constructorStore,
      setIsBeforeClosePopup,
      mailingInstance,
    } = massMailingStore;

    const onClose = () => {
      mailingInstance.hasDataChange()
        ? setIsBeforeClosePopup(true)
        : constructorStore.setIsConstructorOpen(false);
    };

    return (
      <Drawer
        visible={isConstructorOpen}
        onClose={onClose}
        size="large"
        title={`${
          mailingInstance?.get('id') 
            ? 'Редактирование' 
            : 'Создание'
        } Еmail рекомендаций`}
      >
        <div className={'mailing-constructor__body-wrapper'}>
          <MassMailingConstructorTabs />
          <WrapperContentDrawer>
            <MassMailingConstructorContent
              step={constructorStore.currentStep}
            />
          </WrapperContentDrawer>
          <MassMailingConstructorFooter />
        </div>
      </Drawer>
    );
  }
);

export default MassMailingConstructor;
