import cx from 'classnames';
import React, { CSSProperties } from 'react';
import Menu, { MenuRoute } from '~/components/ui/Menu';
import './style.css';

export type Item = Pick<MenuRoute, 'key' | 'title' | 'tooltip' | 'disabled' | 'isInvalid'>;

interface Props {
  items: Item[];
  value: string;
  onChange: (key: string) => void;
  className?: string;
  style?: CSSProperties;
  noPadding?: boolean;
  direction?: 'vertical' | 'horizontal';
  withScroll?: boolean;
}

const Tabs: React.FC<Props> = ({
  value,
  items,
  onChange,
  className,
  style,
  noPadding,
  direction = 'horizontal',
  withScroll,
}) => {
  const handleSelect = (key: string, route: string[]) => {
    onChange(key);
  };

  return (
    <Menu
      className={cx('tabs', { [`_${direction}`]: direction }, className)}
      style={style}
      selected={[value]}
      routes={items}
      onSelect={handleSelect}
      noPadding={noPadding}
      withScroll={withScroll}
    />
  );
};

export default Tabs;
