import { observer } from 'mobx-react';
import React, { useState } from 'react';
import SearchInput from '~/components/generic/SearchInput';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRulesByFeed,
  BusinessRuleEntity,
  BusinessRuleMeta,
  FilterBusinessRuleEntityRequest,
} from '~/screens/BusinessRulesScreen/types';

const RecommendationFor: React.FC<{
  businessRule: BusinessRulesModel;
  metadata: BusinessRuleMeta;
  getBusinessRuleEntity: (
    parameters: FilterBusinessRuleEntityRequest
  ) => Promise<BusinessRuleEntity[]>;
  businessRulesByFeed: BusinessRulesByFeed;
}> = observer(
  ({ businessRule, metadata, getBusinessRuleEntity, businessRulesByFeed }) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    const [businessRuleEntityList, setBusinessRuleEntityList] = useState<
      BusinessRuleEntity[]
    >([]);
    const [timer, setTimer] = useState<NodeJS.Timeout>(null);
    const [paginationPageNum, setPaginationPageNum] = useState<number>(0);

    const requestBusinessRuleEntity = (
      currentQuery: string,
      paginationPageNum: number,
      start: boolean = false
    ): void => {
      getBusinessRuleEntity({
        recommendationForType: businessRule.get('recommendationFor').type,
        ymlUrl: businessRule.get('ymlUrl'),
        query: currentQuery,
        paginationPageNum,
        paginationPageSize: metadata.paginationMaxPageSize,
      }).then((response: BusinessRuleEntity[]) => {
        let oldItem = businessRuleEntityList;
        if (start) oldItem = [];
        if (response.length === 0 && start)
          response.push({
            '@type': 'FilterBusinessRuleEntity',
            'id': 0,
            'name': '',
            'uiText': 'Ничего не найдено',
          });
        setBusinessRuleEntityList([...oldItem, ...response]);
        setIsOpenDropdown(true);
      });
    };

    const startTimer = (currentQuery: string) => {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          requestBusinessRuleEntity(currentQuery, 0, true);
        }, metadata.inputSearchTimeoutInMilliseconds)
      );
      setPaginationPageNum(0);
    };

    const handleClickOutsite = () => {
      setBusinessRuleEntityList([]);
      setIsOpenDropdown(false);
    };

    return (
      <SearchInput
        className="business-rule-editor-recomendation-for__search"
        isOpenDropdown={isOpenDropdown}
        onChange={(value) => {
          businessRule.setRecommendationForEntities({
            '@type': 'FilterBusinessRuleEntity',
            'id': 0,
            'name': '',
            'uiText': value,
          });
          if (+value || value.length >= metadata.searchMinTextSymbols) {
            startTimer(value);
          }
        }}
        value={businessRule.get('recommendationFor').entities.uiText}
        resultSearchItem={businessRuleEntityList?.map((businessRuleEntity) => {
          const usedEarlier = businessRulesByFeed?.rules
            .map(rule => rule.recommendationFor.entities.id)
            .includes(businessRuleEntity.id);
          const disabled = businessRuleEntity.id === 0 || usedEarlier;

          return {
            key: businessRuleEntity.id,
            value: usedEarlier ? (
              <>
                {businessRuleEntity.uiText}
                <br />
                (использовано в другом правиле)
              </>
            ) : (
              businessRuleEntity.uiText
            ),
            disabled,
          };
        })}
        onClickSearchItem={(itemAttributes) => {
          businessRule.setRecommendationForEntities(
            businessRuleEntityList[itemAttributes.index]
          );
          setIsOpenDropdown(false);
        }}
        placeholder={'Введите название или ID категории'}
        clickOutside={handleClickOutsite}
        scrolledToEnd={() => {
          const newPageNumer = paginationPageNum + 1;
          requestBusinessRuleEntity(
            businessRule.get('recommendationFor').entities.uiText,
            newPageNumer
          );
          setPaginationPageNum(newPageNumer);
        }}
        failure={
          !businessRule.get('recommendationFor').entities.id &&
          businessRule.get('recommendationFor').entities.uiText !== ''
        }
      />
    );
  }
);

export default RecommendationFor;
