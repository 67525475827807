import React from 'react';
import styles from './style.module.css';

const GroupListItems: React.FC<{
  className?: string;
  onClick?: () => void;
}> = ({ children, className = '', onClick = null }) => {
  return (
    <div
      className={`${styles.group_list_items} ${className}`}
      onClick={onClick ? onClick : null}
    >
      {children}
    </div>
  );
};

export default GroupListItems;
