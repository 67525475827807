import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import { QuizItem } from '~/screens/QuizScreen/components/QuizList/QuizItem';
import { RenameQuiz } from '~/screens/QuizScreen/components/QuizList/RenameQuiz';
import { IQuizItem, QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import styles from '../../style.module.css';
import localStyles from './style.module.css';

export const className = {
  STARTED: 'started',
  STOPPED: 'paused',
  ERROR: 'error',
};

export const QuizList = observer(() => {
  const [isChangeNameDrawerVisible, setIsChangeNameDrawerVisible] =
    useState<boolean>(false);

  const [isMaxQuizCount, setIsMaxQuizCount] = useState(false);

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const quizList = quizStore.quizList;

  const openChangeNameDrawer = (selectedId: string) => {
    setIsChangeNameDrawerVisible(true);
    quizStore.setSelectedId(selectedId);
  };

  const onCloseChangeNameDrawer = () => {
    setIsChangeNameDrawerVisible(false);
    quizStore.setSelectedId(undefined);
  };

  const saveQuizName = (quizName: string) => {
    quizStore.updateQuizName(quizStore.selectedId, quizName);
    quizStore.setSelectedId(undefined);
    setIsChangeNameDrawerVisible(false);
    quizStore.quizExample = undefined;
    quizStore.templateData = undefined;
  };

  const deleteQuiz = (quizId: string) => {
    quizStore.deleteQuizById(quizId);
  };

  const copyQuiz = (quizId: string) => {

    if (quizList.length >= 10) {
      setIsMaxQuizCount(true);

      return;
    }

    quizStore.copyQuizById(quizId);
  };

  const openConstructor = (quizId: string) => {
    quizStore.setSelectedId(quizId);
    quizStore.setIsConstructorOpen(true);
  };

  const onConstructorOpen = () => {
    if (quizList.length >= 10) {
      setIsMaxQuizCount(true);

      return;
    }

    quizStore.setIsConstructorOpen(true);
  };

  return (
    <Screen documentTitle={'Карточка квиза'} className={styles.screen}>
      <div className={styles.title}>Карточки квиза</div>
      <div className={localStyles.quizList}>
        {quizList.map((v, key) => {
          const quizProps = {
            quizId: v.template.id,
            creationDate: v.creationDate,
            status: v.template.errors.length > 0 ? 'ERROR' : v.status,
            quizName: v.template?.name,
            configuration: v.template.body.configuration,
            steps: v.template.body?.steps,
            statistics: v.statistic,
          };

          return (
            // TODO typings
            // @ts-ignore
            <QuizItem
              key={key}
              {...quizProps}
              openDrawer={openChangeNameDrawer}
              deleteQuiz={deleteQuiz}
              copyQuiz={copyQuiz}
              openConstructor={openConstructor}
            />
          );
        })}
      </div>
      <div className={localStyles.quizListFooter}>
        <Button
          icon={'Plus'}
          className={localStyles.createButton}
          onClick={onConstructorOpen}
        >
          Создать квиз
        </Button>
        {/* TODO вернуть после MVP */}
        {/*<Button*/}
        {/*  icon={'Question'}*/}
        {/*  type="green-outline"*/}
        {/*  className={localStyles.createButton}*/}
        {/*>*/}
        {/*  Как создать квиз?*/}
        {/*</Button>*/}
      </div>
      {isChangeNameDrawerVisible && (
        <RenameQuiz
          selectedQuizId={quizStore.selectedId}
          onClose={onCloseChangeNameDrawer}
          saveQuizName={saveQuizName}
          initialQuizName={
            quizList.filter(
              (v: IQuizItem) => v.template.id === quizStore.selectedId
            )[0]?.template.name // TODO typings
          }
        />
      )}
      {isMaxQuizCount && (
        <Message
          visible={isMaxQuizCount}
          title={'Достигнуто максимальное количество созданных квизов'}
          textTop={'Достигнуто максимальное количество созданных квизов - 10 штук.'}
          applyText={'Хорошо'}
          onClose={() => setIsMaxQuizCount(false)}
          onExit={() => setIsMaxQuizCount(false)}
          onApply={() => setIsMaxQuizCount(false)}
        />
      )}
    </Screen>
  );
});
