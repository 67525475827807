import React from 'react';
import Button from '~/components/ui/Button';
import ToCopyArea from '~/components/ui/ToCopyArea';

interface IGuideHTMLProps {
  onCloseDrawer: () => void;
  clientCode: string;
}

export const GuideHTML: React.FC<IGuideHTMLProps> = ({
  onCloseDrawer,
  clientCode,
}) => {
  return (
    <ol className="_list">
      <li className="_item">
        <span className="_title">Перейдите к файлам вашего сайта</span>
        <p className="_text">
          Это можно сделать через сайт вашего хостинг-провайдера или через
          FTP-клиент.
        </p>
      </li>
      <li className="_item">
        <span className="_title">
          Откройте файл index сайта в редакторе кода
        </span>
        <p className="_text">
          Начните редактирование сайта с файла index - это основная страница.
        </p>
      </li>
      <li className="_item">
        <span className="_title">Скопируйте код</span>
        <ToCopyArea text={clientCode} className="connection-guide-all__code" />
      </li>
      <li className="_item">
        <span className="_title">Откройте редактор кода и вставьте код перед закрывающим тегом &lt;/head&gt;</span>
        <img
          src="assets/settings/code.png"
          alt="code"
          className="_code"
        />
      </li>
      <li className="_item">
        <span className="_title">Проверьте подключение</span>
        <p className="_text">
          Чтобы код заработал, просто перейдите на свой сайт, на который
          вставили код, а потом вернитесь и нажмите на кнопку «Проверить
          подключение»
        </p>
        <Button onClick={onCloseDrawer}>Проверить подключение</Button>
      </li>
    </ol>
  );
};
