import React, { useEffect } from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import SignOfUnificationDropdown from '~/components/generic/SignOfUnificationDropdown';
import FormItem from '~/components/ui/FormItem';
import RadioGroup from '~/components/ui/RadioButton/RadioGroup';
import './style.css';

class Option {
  label: string;
  value: string | number;
}

const options: Option[] = [
  { label: 'На одной странице', value: 'ONE' },
  { label: 'На нескольких страницах ', value: 'MULTIPLE' },
];

const StepLinkPages: React.FC<IFeedConstructorPlacementPages> = ({
  value,
  onChangeUrl,
  onChangeSeparator,
  onChangeDisplayConditions,
  isInvalid,
  isDisplayConditionsInvalid,
  onBlur,
  errorMessage,
  errorMessageSeparator,
  checkedCountPage,
  setCheckedCountPage,
  operation,
  url,
}) => {

  useEffect(() => {
    if (operation === 'EQUAL' && checkedCountPage === 'MULTIPLE') {
      onChangeSeparator('CONTAINS');
      onChangeDisplayConditions('');
    }
  }, [checkedCountPage]);

  return (
    <div className="feed-constructor-link-pages__container">
      <div className="feed-constructor-link-pages">
        <NumberedBlock number={1}>
          <FormItem
            title="На скольких страницах вы хотите разместить ленту?"
            noHTMLLabel
            noReservedPlaceForMessage
            className="feed-constructor-form-item"
          />
          <RadioGroup
            className="feed-constructor-link-pages__radio-group"
            size="medium"
            onCheck={(value) => {
              setCheckedCountPage(value as string);
            }}
            items={options}
            checkedItem={checkedCountPage as string}
          />
        </NumberedBlock>
        {checkedCountPage === 'MULTIPLE' && (
          <NumberedBlock number={2}>
            <SignOfUnificationDropdown
              title="По какому признаку объединены ссылки этих страниц?"
              operation={operation}
              onChangeSeparator={onChangeSeparator}
              isDisplayConditionsInvalid={isDisplayConditionsInvalid}
              onChangeDisplayConditions={onChangeDisplayConditions}
              onBlur={onBlur}
              errorMessageSeparator={errorMessageSeparator}
              url={url}
            />
          </NumberedBlock>
        )}
        <NumberedBlock number={checkedCountPage === 'ONE' ? 2 : 3}>
          <FormItem
            title={
              checkedCountPage === 'ONE'
                ? 'Вставьте ссылку на страницу'
                : 'Вставьте ссылку на любую страницу, которая соответствует условиям шага 2'
            }
            noHTMLLabel
            noReservedPlaceForMessage
            className="feed-constructor-form-item"
          />
          {checkedCountPage === 'MULTIPLE' && (
            <div className="feed-constructor-placement-pages__info">
              Эта ссылка необходима для выбора точного расположения ленты на
              странице. Когда&nbsp; вы выберите расположение на одной странице,
              лента появится на всех страницах, которые объединены по выбранному
              вами признаку на шаге 2.
            </div>
          )}
          <SetCatalogConfigLink
            onChange={onChangeUrl}
            value={value}
            placeholder="https://example.ru/category/product-1.html"
            isInvalid={isInvalid}
            errorMessage={errorMessage}
            tooltipText="Ссылка на страницу должна быть полной - включая «http://» или «https://»"
          />
        </NumberedBlock>
      </div>
    </div>
  );
};

export default StepLinkPages;
