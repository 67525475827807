import { observer } from 'mobx-react';
import React from 'react';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import TextAreaWithNote from '~/components/ui/TextArea/TextAreaWithNote';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import WheelsPreview from '../WheelsPreview';
import style from './style.module.css';

export const GeneralView: React.FC = observer(() => {
  const { wheelOfFortuneStore } = useStores() as {
    wheelOfFortuneStore: WheelOfFortuneStore;
  };
  const { wheelOfFortune, getSectors } = wheelOfFortuneStore;
  const isMobile = wheelOfFortune.get('isMobile');

  const wheelProps = {
    sectors: getSectors(),
    radius: 100,
    animationProps: {
      winSectorNumber: 8,
      start: false,
      onAnimationEnd: () => alert('animation ended'),
    },
  };

  return (
    <div className={style.wrapper}>
      <div className={style.title}>Общий вид колеса фортуны</div>
      <div className={style.subtitle}>
        Добавьте свой заголовок, кнопку и описание, используйте мотивирующий
        текст для привлечения внимания посетителя
      </div>
      <div className={style.content}>
        <div className={style.content_leftWrap}>
          <div className={style.content_left}>
            <WheelsPreview
              title={wheelOfFortune.get('title') ?? 'Испытайте удачу'}
              message={
                wheelOfFortune.get('message') ??
                'Крутите колесо и получайте гарантированные подарки!'
              }
              buttonBackgroundColor={
                wheelOfFortune.get('sectorUIParams')[1].backgroundColor
              }
              buttonTextColor={wheelOfFortune.get('sectorUIParams')[1].textColor}
              buttonText={wheelOfFortune.get('buttonText') ?? 'Крутите колесо'}
              hasCoupon={false}
              wheelProps={wheelProps}
              isMobile={isMobile}
            />
          </div>
        </div>
        <div className={style.content_right}>
          <div className={style.content_form}>
            <FormItem title={'Текст заголовка'} className="">
              <Input
                name="title"
                type="text"
                placeholder={'Испытайте удачу'}
                value={wheelOfFortune.get('title')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  wheelOfFortune.setTitle(e.target.value);
                }}
                maxLength={30}
                className={style.input}
              />
            </FormItem>
            <FormItem title={'Текст кнопки'} className="">
              <Input
                name="buttonText"
                type="text"
                placeholder={'Крутить колесо'}
                value={wheelOfFortune.get('buttonText')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  wheelOfFortune.setButtonText(e.target.value);
                }}
                maxLength={25}
                className={style.input}
              />
            </FormItem>

            <TextAreaWithNote
              hint=""
              title="Описание (Необязательно)"
              textAreaProps={{
                className: style.input,
                placeholder:
                  'Крутите колесо и получайте гарантированные подарки!',
                maxLength: 60,
                rows: 4,
                value: wheelOfFortune.get('message'),
                onChangeValue: (message) => {
                  wheelOfFortune.setMessage(message);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
