import React from 'react';
import styles from './style.module.css';

interface ICaptionProps {
  className?: string;
  backgroundColor?: Colors;
}

const Caption: React.FC<ICaptionProps> = ({
  children,
  className = '',
  backgroundColor = 'blue',
}) => {
  return (
    <div
      className={`${styles.container} ${className} ${styles[backgroundColor]}`}
    >
      {children}
    </div>
  );
};

export default Caption;
