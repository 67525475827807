import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { urlTruncateProtocol } from '~/utils/urlTruncateProtocol';
import useStores from '~/utils/useStores';
import './style.css';

interface ISiteUrlEditorProps {
  checkCode: () => void;
}

export const SiteUrlEditor: React.FC<ISiteUrlEditorProps> = observer(({ checkCode }) => {

  const { settingsStore } = useStores() as { settingsStore: SettingsStore };
  const { authStore } = useStores() as { authStore: AuthStore };
  
  const [domain, setDomain] = useState(urlTruncateProtocol(authStore.currentOrg?.domain[0]));
  const [domainError, setDomainError] = useState('');
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);

  const onApplyClick = async () => {
    setIsRequestProcessing(true);
    try {
      setDomainError('');
      await authStore.addDomain(domain);      
      await authStore.fetchOrgData();
      await checkCode();
      await settingsStore.setToggleDrawer(false);
    } catch (e) {
      if (e?.response?.data?.errorData?.errorText) {
        setDomainError(e.response.data.errorData.errorText.split(':')[0]);
      } else {
        throw e;
      }
    }
    setIsRequestProcessing(false);
  };

  return (
    <div className="site-url-editor__wrapper" >
      <Input 
        value={domain}
        onChangeValue={newDomain => setDomain(urlTruncateProtocol(newDomain))}
        className="site-url-editor__input"
      />
      <Button
        loading={isRequestProcessing}
        onClick={onApplyClick}
        className="site-url-editor__btn"
      >
        Сохранить
      </Button>

      <div className="site-url-editor__error">{domainError}</div>

      <div className="site-url-editor__description-wrapper">
        <div><Icon name="TriangleWarning" className="settings-status-bar__icon" /></div>
        <p className="site-url-editor__warning">
          Если вы решили изменить сайт, на котором планируете использовать сервис, тогда 
          на новый сайт нужно установить код продукта.
        </p>
      </div>
    </div>
  );
});
