type SynthTMPParams = 'loginDCB';

type RealUTMParams =
  | 'utm_source'
  | 'utm_medium'
  | 'utm_campaign'
  | 'utm_term'
  | 'utm_content';

export type UTMParams = RealUTMParams | SynthTMPParams;

export const utmParameters: UTMParams[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'loginDCB',
];

export interface IUTMData {
  data: { [key: string]: string };
  sent: boolean;
}
