import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import BorderComponent from '~/components/notification-editor/BorderComponent';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FontSettings from '~/components/notification-editor/FontSettings';
import FormItem from '~/components/ui/FormItem';

interface IHandCategoryActiveButtonParamsProps {
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}

const HandCategoryActiveButtonParams: React.FC<IHandCategoryActiveButtonParamsProps> = ({
  isActive,
  configuration,
  onChange,
  onChangeCssParam,
}) => {
  const {
    categoryActiveButtonBorderRadius,
    categoryActiveButtonBorderWidth,
    categoryActiveButtonBorderColor,
    categoryActiveButtonBorderOpacity,
    hasCategoryActiveButtonBorder,
    categoryActiveButtonColor,
    categoryActiveButtonOpacity,
    categoryActiveButtonFontFamily,
    categoryActiveButtonTextColor,
    categoryActiveButtonTextOpacity,
    categoryActiveButtonFontWeight,
    categoryActiveButtonFontSize,
    categoryActiveButtonFontItalic,
    categoryActiveButtonTextLineThrough,
    categoryActiveButtonTextUnderline,
  } = configuration;

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FontSettings
          className="feed-editor-appearance__font"
          type="title"
          title="Шрифт выбранной категории"
          overlayClassName="feed-editor-appearance__tooltip"
          data={{
            fontFamily: categoryActiveButtonFontFamily,
            color: categoryActiveButtonTextColor,
            alpha: categoryActiveButtonTextOpacity,
            fontWeight: categoryActiveButtonFontWeight,
            fontSize: categoryActiveButtonFontSize,
            isItalic: categoryActiveButtonFontItalic,
            isLineThrough: categoryActiveButtonTextLineThrough,
            isUnderline: categoryActiveButtonTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                categoryActiveButtonFontFamily: data.fontFamily,
                categoryActiveButtonTextColor: data.color,
                categoryActiveButtonTextOpacity: data.alpha,
                categoryActiveButtonFontWeight: data.fontWeight,
                categoryActiveButtonFontSize: data.fontSize as TFontSize,
                categoryActiveButtonFontItalic: data.isItalic,
                categoryActiveButtonTextLineThrough: data.isLineThrough,
                categoryActiveButtonTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
      <section className="feed-editor-appearance__section">
        <FormItem
          className="feed-editor-appearance__font"
          overlayClassName="feed-editor-appearance__tooltip"
          title="Цвет выбранной кнопки категории"
          noHTMLLabel
          noReservedPlaceForMessage
        >
          <ColorPicker
            color={categoryActiveButtonColor}
            alpha={categoryActiveButtonOpacity}
            onChange={data =>
              onChange(
                {
                  ...configuration,
                  categoryActiveButtonColor: data.color,
                  categoryActiveButtonOpacity: data.alpha,
                },
                true
              )
            }
            className="feed-editor-appearance__color-picker"
          />
        </FormItem>
      </section>
      <section className="feed-editor-appearance__section">
        <BorderComponent
          className="feed-editor-appearance__font"
          limit={8}
          title="Обводка выбранной кнопки категории"
          overlayClassName="feed-editor-appearance__tooltip"
          withRadius
          borderRadius={categoryActiveButtonBorderRadius}
          onBorderRadiusChange={(categoryActiveButtonBorderRadius) => {
            onChange({
              ...configuration,
              categoryActiveButtonBorderRadius,
            });
          }}
          borderWidth={categoryActiveButtonBorderWidth}
          onBorderWidthChange={categoryActiveButtonBorderWidth =>
            onChange(
              {
                ...configuration,
                categoryActiveButtonBorderWidth,
              },
              true
            )
          }
          colorPickerProps={{
            alpha: categoryActiveButtonBorderOpacity,
            color: categoryActiveButtonBorderColor,
            onChange: ({ alpha, color }) =>
              onChange(
                {
                  ...configuration,
                  categoryActiveButtonBorderColor: color,
                  categoryActiveButtonBorderOpacity: alpha,
                },
                true
              ),
          }}
          enabled={hasCategoryActiveButtonBorder}
          onChangeState={hasCategoryActiveButtonBorder =>
            onChange(
              {
                ...configuration,
                hasCategoryActiveButtonBorder,
              },
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandCategoryActiveButtonParams;
