import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import Steps from '~/components/ui/Steps';
import { CODE_TO_COPY_FIELD_TEXT } from '~/constants';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { setGtm } from '~/utils/setGtm';
import './style.css';

interface State {
  current: number;
  copied: boolean;
}

type Props = {
  authStore?: AuthStore;
};

@inject('authStore')
@observer
class GuideScreenForDeveloper extends Component<Props> {
  state: State = {
    current: 0,
    copied: false,
  };

  handleNextStepClick = () => {
    this.setState({ current: this.state.current + 1 });
  }

  handleCopyCode = () => {
    this.setState({ copied: true, current: this.state.current + 1 });
  }

  render() {
    /**
     * Отправка события admin_by_specialist для GA
     */
    setGtm('admin_by_specialist');

    const items = [{}, {}, {}];

    return (
      <Screen className="guide-for-dev">
        <div className="guide-navigation">
          <Button className="guide-back-button" type="text" icon="ArrowMLeft" size="x-small" to="/guide">
            К выбору способа подключения
          </Button>
          <h2 className="guide-name">
            Отправка инструкции IT-специалисту
          </h2>
        </div>
        <div className="guide-for-dev__container">
          {this.state.current === 0 && (
            <div className="guide-for-dev__wrapper">
              <h1 className="guide-for-dev__title">Скопируйте ссылку</h1>
              <CodeToCopy
                tag="input"
                fieldText={CODE_TO_COPY_FIELD_TEXT}
                onCopy={this.handleCopyCode}
              />
            </div>
          )}
          {this.state.current === 1 && (
            <div className="guide-for-dev__content">
              <h1 className="guide-for-dev__title">Отправьте ссылку своему IT-специалисту</h1>
              <Button size="large" onClick={this.handleNextStepClick}>
                Готово! Следующий шаг
              </Button>
            </div>
          )}
          {this.state.current === 2 && (
            <div className="guide-for-dev__wrap">
              <h1 className="guide-for-dev__title">
                Дождитесь установки кода<br />
                IT - специалистом и проверьте подключение
              </h1>
              <CheckCodeButton />
            </div>
          )}
        </div>
        <Steps items={items} current={this.state.current} />
        <div className="guide-for-dev__link-wrapper">
          <Link className="guide-for-dev__link" to="/guide/html">
            Подробная инструкция
          </Link>
        </div>
      </Screen>
    );
  }
}

export default React.memo(GuideScreenForDeveloper);
