import React, { useEffect } from 'react';
import './style.css';

interface IFeedEditorNoIFrame {
  feedString: string;
  addActionFeed: () => void;
}

const FeedDesctop: React.FC<IFeedEditorNoIFrame> = ({
  feedString,
  addActionFeed,
}) => {

  useEffect(() => {
    addActionFeed();
  }, [feedString]);

  return (
    <div
      className="feed-editor-step-selector-device-workspace-noframe__feed"
      dangerouslySetInnerHTML={{ __html: feedString }}
    />
  );
};

export default FeedDesctop;
