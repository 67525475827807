import { Tooltip as AntTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import './style.css';

export interface TooltipProps {
  placement: TooltipPlacement;
  title: ReactNode;
  loading?: boolean;
  trigger?: 'hover' | 'focus' | 'click';
  children?: React.ReactElement;
  overlayClassName?: string;
  noArrow?: boolean;
  width?: string;
  height?: string;
  className?: string;
  onVisibleChange?: (visible: boolean) => void;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  placement,
  children,
  loading,
  noArrow = true,
  width,
  height,
  onVisibleChange,
  overlayClassName,
}) => {
  return (
    <AntTooltip
      placement={placement}
      title={title}
      overlayClassName={cx('tooltip', overlayClassName, { _loading: loading, _noArrow: noArrow })}
      overlayStyle={{ maxWidth: width, height }}
      mouseLeaveDelay={0.15}
      mouseEnterDelay={0.15}
      getPopupContainer={() => document.body}
      align={{
        offset: [
          ['right', 'rightTop', 'rightBottom'].includes(placement) ? -2 : 4,
          ['top', 'topLeft', 'topRight'].includes(placement) ? 8 : -4,
        ],
      }}
      onVisibleChange={onVisibleChange}
    >
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
