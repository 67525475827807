import cx from 'classnames';
import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import Checkbox, { CheckboxProps } from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import './style.css';

interface ButtonTextBlockProps {
  checkboxProps?: Pick<CheckboxProps, 'checked' | 'onCheck'>;
  isMobileConst?: boolean;
}

const PayAttentionBlock: React.FC<ButtonTextBlockProps> = ({
  checkboxProps,
  isMobileConst,
}) => {
  return (
    <div className="pay-attention-block">
      <FormItem title="Обратите внимание!" noHTMLLabel>
        <div className="pay-attention-text-wrapper">
          <p className="pay-attention-text">Если на вашем сайте при оформлении заказа нельзя ввести промокод,
            предложите клиентам указывать его в комментариях или сообщать оператору по телефону.</p>
        </div>
        <p className={cx('pay-attention-text', 'pay-attention-mobile-const')}>
          {isMobileConst ? `Событие «Попытка закрыть сайт со страниц(ы)» не совместимо с
          мобильными устройствами.` :
            `Не забудьте создать виджет для другого типа устройств (для мобильных устройств или компьютеров и планшетов). ` }
        </p>
        <div className="pay-attention-block__checkbox-wrapper">
          <Checkbox
            label="Я ознакомлен"
            size="small"
            {...checkboxProps}
          />
          <IconWithTooltip
            tooltipProps={{
              overlayClassName: 'pay-attention-block__tooltip',
              placement: 'bottomRight',
              width: '219px',
              title: 'Поставьте галочку в поле, нажав на него. Таким образом вы подтвердите, что ознакомились с блоком "Обратите внимание"',
            }}
            iconProps={{
              name: 'Question',
              className: 'pay-attention-block__icon',
            }}
          />
        </div>
      </FormItem>
    </div>
  );
};

export default PayAttentionBlock;
