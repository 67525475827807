import { action, get, observable, reaction } from 'mobx';
import Image from '~/api/image';
import Mailing from '~/api/mailing';
import MailingModel from '~/screens/MailingScreen/models/MailingModel';
import {
  initialMailInstance,
  nameToConfiguration,
} from '~/screens/MailingScreen/stores/initial';
import { MailingGeneralStore } from '~/stores/mailingGeneralStore';
import { getError } from '~/utils/getError';

export class MailingStore extends MailingGeneralStore<TMailingConstructorSteps> {
  @observable mailingStatistics: IMailingTemplateWithStatistic[] = [];
  @observable mailingInstance: MailingModel;
  @observable isYmlValid: boolean = true;
  @observable isIntervalInValid: boolean = false;

  constructor() {
    super([
      'TabSelectType',
      'TabCatalog',
      'TabParameters',
      'TabConstructor',
    ]);
    reaction(() => this.constructorStore.isConstructorOpen, this.setMailingInstance);
  }

  getMailingInstanceById = (
    id: number | string
  ): IMailingTemplateWithStatistic => {
    return (
      this.mailingStatistics.length > 0 &&
      this.mailingStatistics.filter(v => v?.template?.id === id)[0]
    );
  }

  setIsIntervalInValid = (isIntervalValid: boolean) => {
    this.isIntervalInValid = isIntervalValid;
  }

  setMailingInstance = () => {
    if (!this.selectedId) {
      this.mailingInstance = new MailingModel(this, initialMailInstance);

      return;
    }
    const selectedMailInstance = this.mailingStatistics.filter(
      (v: IMailingTemplateWithStatistic) => v.template.id === this.selectedId
    )[0];

    const configuration = (
      selectedMailInstance.template?.emailTemplate?.data?.configuration || []
    ).map((v: MailItem) => ({
      ...v,
      configuration: nameToConfiguration(v.name),
    }));

    const selectedItem: IMailingModel = {
      id: selectedMailInstance.template?.id,
      time: {
        value: selectedMailInstance.template?.emailTrigger?.timeout,
        format: selectedMailInstance.template?.emailTrigger
          ?.timeUnit as TMailingTimeFormat,
      },
      name: selectedMailInstance.template?.name,
      type: 'EmailDistributionTemplate' as 'EmailDistributionTemplate',
      ymlCatalog: selectedMailInstance.template?.emailTrigger?.ymlCatalogUrl,
      authorizationEmail: {
        address: selectedMailInstance.template?.senderEmailAddress?.address,
        registered:
          selectedMailInstance.template?.senderEmailAddress?.registered,
        emailTopic: selectedMailInstance.template?.emailTrigger?.emailTopic,
        senderName: selectedMailInstance.template?.senderName,
      },
      configuration,
      imageCodes: selectedMailInstance.imageCodes.map((v: any) =>
        typeof v === 'string' ? v : v.code
      ),
    };
    this.mailingInstance = new MailingModel(this, selectedItem);
    this.getSelectedItemYmlCatalogStatus() === 'LOADING_CATALOG_ERROR' &&
      this.constructorStore.changeCurrentStep('TabCatalog');
  }

  @get
  getSelectedItemYmlCatalogStatus = (): TMailingStatus => {
    if (!this.selectedId) {
      return 'LOADING_CATALOG';
    }

    const selectedMailInstance = this.mailingStatistics.filter(
      (v: IMailingTemplateWithStatistic) => v.template.id === this.selectedId
    )[0];

    return selectedMailInstance.status;
  }

  @get
  getLastId = () => {
    if (!this.mailingStatistics.length) {
      return undefined;
    }
    const result = this.mailingStatistics
      .slice()
      // @ts-ignore
      .sort(
        (
          v1: IMailingTemplateWithStatistic,
          v2: IMailingTemplateWithStatistic
        ) => {
          return v1.template.id > v2.template.id ? -1 : 1;
        }
      );

    return result[0].template.id;
  }

  @get
  getSelectedItemYmlCatalogErrors = (): string => {
    if (!this.selectedId) {
      return '';
    }

    const selectedMailInstance = this.mailingStatistics.filter(
      (v: IMailingTemplateWithStatistic) => v.template.id === this.selectedId
    )[0];

    return selectedMailInstance.errors;
  }

  @action
  setIsYmlValid = (isYmlValid: boolean) => (this.isYmlValid = isYmlValid)

  @action
  sendTestLetter = async (email: string) => {
    this.setErrorMessage(this.errorMessage);
    try {
      await Mailing.sendTestLetter(
        email,
        this.mailingInstance.get('id'),
        this.mailingInstance.getDataToSend()
      );

      return true;
    } catch (e) {
      const error = getError({ error: e, defaultError: 'Что-то пошло не так' });
      this.setErrorMessage({ title: 'Что-то пошло не так', body: error });

      return false;
    }
  }

  @action
  setMailingStatistics = (
    mailingStatistics: IMailingTemplateWithStatistic[] = []
  ) => {
    this.mailingStatistics = mailingStatistics;
  }

  @action
  fetchMailingList = async () => {
    this.setIsLoading(true);
    try {
      const response = await Mailing.getList();
      this.setMailingStatistics(response?.data?.payload || []);
    } catch (e) {
      this.setMailingStatistics([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  processEmailImage = async (imageBase64: string, errorCallback: Function) => {
    try {
      const result = await Image.set(imageBase64);
      const payload = result?.data?.payload;

      if (payload?.length > 0) {
        return payload[0];
      }

      errorCallback({
        isShownErrorDialogBox: true,
        titleErrorDialogBox: 'Ошибка загрузки изображения',
        textErrorDialogBox: 'Неизвестная ошибка',
      });

      return false;
    } catch (e) {
      const error = getError({ error: e });

      errorCallback({
        isShownErrorDialogBox: true,
        titleErrorDialogBox: 'Ошибка загрузки изображения',
        textErrorDialogBox: error,
      });

      return false;
    }
  }

  @action
  openConstructorForApproveEmail = () => {
    this.setIsShowEmailNotRegisterMessage(false);
    this.constructorStore.changeCurrentStep('TabParameters');
    this.constructorStore.setIsConstructorOpen(true);
  }

  @action
  start = async (id: number) => {
    if (!this.mailingStatistics
      .find(mailing => mailing.template.id === id)
      .template.senderEmailAddress.registered
    ) {
      this.setSelectedId(id);
      this.setIsShowEmailNotRegisterMessage(true);

      return true;
    }
    try {
      const response = await Mailing.start(id);
      this.mailingStatistics = response?.data?.payload;

      return true;
    } catch (e) {
      const error = getError({ error: e, defaultError: 'Что-то пошло не так' });
      this.setErrorMessage({ title: 'Что-то пошло не так', body: error });

      return false;
    }
  }

  @action
  delete = async (id: string | number) => {
    try {
      const response = await Mailing.delete(id);
      this.mailingStatistics = response?.data?.payload;

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  stop = async (id: string | number) => {
    try {
      const response = await Mailing.stop(id);
      this.mailingStatistics = response?.data?.payload;

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  editTitle = async (title: string, id: string | number) => {
    try {
      const response = await Mailing.editTitle(title, id);
      this.mailingStatistics = response?.data?.payload;

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  save = async () => {
    try {
      const response = await Mailing.save(this.mailingInstance.getDataToSend());
      this.mailingStatistics = response?.data?.payload;
      this.setErrorMessage(this.errorMessage);

      return true;
    } catch (error) {
      this.setErrorMessage({ title: getError({ error }), body: '' });

      return false;
    }
  }

  @action
  closeConstructor = () => {
    this.constructorStore.setIsConstructorOpen(false);
    this.mailingInstance.clearDataToSend();
    this.constructorStore.changeCurrentStep('TabSelectType');
  }

  @action
  saveMailing = async () => {
    this.setIsSavedMailing(true);
    const result = await this.save();
    this.setIsSavedMailing(false);
    if (result) {
      !this.selectedId && this.setIsMailingWasCreatedPopup(true);
      this.closeConstructor();

      return true;
    }
    !result && this.setIsErrorMessageShown(true);

    return false;
  }
}

export default new MailingStore();
