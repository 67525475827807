import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import './style.css';

interface IToCopyAreaProps {
  onCopy?: () => void;
  className?: string;
  text?: string;
  height?: string;
  style?: React.CSSProperties;
  styleIcon?: React.CSSProperties;
  iconNameInCodeBlock?: string;
}

const ToCopyArea: React.FC<IToCopyAreaProps> = ({
  onCopy,
  className = null,
  text = '',
  height = '280px',
  style = {},
  styleIcon,
  iconNameInCodeBlock = null,
}) => {
  return (
    <div className={`to-copy-area ${className ? className : ''}`}>
      <div className="to-copy-area__code-block-wrapper">
        {iconNameInCodeBlock && (
          <div className="to-copy-area__code-block-icon">
            <Icon name={iconNameInCodeBlock} style={styleIcon} />
          </div>
        )}
        <div className="to-copy-area__code-block">
          <textarea
            readOnly
            value={text}
            className="to-copy-area__input"
            style={{ height }}
          />
        </div>
      </div>
      <div className="to-copy-area__separator" />
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button block size="large" icon="Copy" type="text" style={style}>
          Скопировать код
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default ToCopyArea;
