import React from 'react';
import Button from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { toBitrix24, toInsales, toJoomla, toWordpress } from '~/constants';
import './style.css';

interface ICMSConstructorProps {
  onCloseDrawer: () => void;
  clientCode: string;
}

export const CMSConstructor: React.FC<ICMSConstructorProps> = ({
  onCloseDrawer,
  clientCode,
}) => {
  return (
    <ol className="_list">
      <li className="_item">
        <span className="_title">Выберите Конструктор сайта или CMS</span>
        <div className="cms-constructor__wrapper">
          <Link to={toBitrix24} className="cms-constructor__item">
            <img
              src="assets/settings/image-bitrix.png"
              alt="bitrix"
              className="_bitrix"
            />
          </Link>
          <Link to={toWordpress} className="cms-constructor__item">
            <img
              src="assets/settings/image-word-press.png"
              alt="bitrix"
              className="_word-press"
            />
          </Link>
          <Link to={toJoomla} className="cms-constructor__item">
            <img
              src="assets/settings/image-joomla.png"
              alt="bitrix"
              className="_joomla"
            />
          </Link>
          <Link to={toInsales} className="cms-constructor__item">
            <img
              src="assets/settings/image-insales.png"
              alt="bitrix"
              className="_insales"
            />
          </Link>
        </div>
      </li>
      <li className="_item">
        <span className="_title">Перейдите в панель настроек сайта</span>
        <p className="_text">
          Перейдите в раздел административной панели сайта (чаще всего, это
          страница «Настройки»), выберите нужный проект (сайт), если их
          несколько.
        </p>
      </li>
      <li className="_item">
        <span className="_title">Откройте редактор кода</span>
        <p className="_text">
          Найдите в настройках раздел редакции кода сайта.
        </p>
      </li>
      <li className="_item">
        <span className="_title">Скопируйте и вставьте код</span>
        <p className="_text">
          Вернитесь в редактор кода и вставьте код сразу после открытия тега
          {' <body>'}. Рекомендуем разместить его внутри элементов head или body
          как можно ближе к началу страницы. Нажмите сохранить.
        </p>
        <ToCopyArea text={clientCode} className="connection-guide-all__code" />
      </li>
      <li className="_item">
        <span className="_title">Проверьте подключение</span>
        <p className="_text">
          Чтобы код заработал, просто перейдите на свой сайт, на который
          вставили код, а потом вернитесь и нажмите на кнопку «Проверить
          подключение»
        </p>
        <Button onClick={onCloseDrawer}>Проверить подключение</Button>
      </li>
    </ol>
  );
};
