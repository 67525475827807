import { wheel } from 'feed';
import { observer } from 'mobx-react';
import React from 'react';
import {
  INotificationWithWheel,
} from '~/components/generic/NotificationWithWheel';
import { DeviceSwitcher } from '~/components/ui/DeviceSwitcher';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import WheelDesktop from './WheelDesktop';
import WheelMobile from './WheelMobile';
import style from './style.module.css';

const WheelsPreview: React.FC<INotificationWithWheel> = observer(
  ({
    title,
    coupon,
    message,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
    wheelProps,
    hasCoupon = false,
    isMobile = false,
  }) => {
    const { wheelOfFortuneStore } = useStores() as {
      wheelOfFortuneStore: WheelOfFortuneStore;
    };
    const { wheelOfFortune } = wheelOfFortuneStore;

    const handleClick = (device: Device) => {
      wheelOfFortune.setIsMobile(device);
    };

    const wheelCreator = new wheel.WheelCreator(
      isMobile ? '536741' : '536742', // wheelId
      isMobile ? 'mobile' : 'desktop', // TDevice
      {
        title,
        coupon,
        message,
        buttonText,
        buttonTextColor,
        buttonBackgroundColor,
        hasCoupon: false,
      }, // configuration
      {
        ...wheelProps,
      } // wheelProps
    );

    return (
      <>
        <DeviceSwitcher handleClick={handleClick} isMobile={isMobile} />
        <div className={style.content_left_device}>
          <div className={style.device_wrapper}>
            {isMobile ? (
              <WheelMobile wheelString={wheelCreator.compileWheelElements().outerHTML} />
            ) : (
              <WheelDesktop wheelString={wheelCreator.compileWheelElements().outerHTML} />
            )}
          </div>
        </div>
      </>
    );
  }
);

export default WheelsPreview;
