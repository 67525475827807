import { Moment } from 'moment';
import React from 'react';
import DateRangePicker, { IDateRangePickerProps } from '~/components/ui/DateRangePicker';

export type DatePickerProps = Omit<IDateRangePickerProps, 'singleDate'> & {
  value: Moment;
  onChange?: (date: Moment, dateString: string) => void;
};

const DatePicker: React.FC<DatePickerProps> = props => (
  <DateRangePicker {...props} singleDate />
);

export default DatePicker;
