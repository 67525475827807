import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import FeedStatisticTable from '~/components/generic/FeedStatisticTable';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import Footer from '~/screens/FeedProScreen/components/FeedProStatistics/Footer';
import Screen from '~/screens/Screen';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const FeedApiStatistics: React.FC = observer(() => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };

  const {
    feedAmount,
    feedLimit,
    createFeedApi,
    feedApiStatistics,
    onClickActionButton,
    isFetchingRequest,
  } = feedApiStore;

  const tableRef = useRef<HTMLDivElement>();
  const [heightTableBlock, setHeightTableBlock] = useState<number>(0);
  const [wightTableBlock, setWightTableBlock] = useState<number>(0);

  useEffect(() => {
    setHeightTableBlock(tableRef.current.offsetHeight - 158);
    setWightTableBlock(tableRef.current.offsetWidth);
  }, []);

  return (
    <Screen
      header={'Интеграции рекомендаций с помощью API'}
      footer={(
        <Footer
          amount={feedAmount}
          limit={feedLimit}
          create={createFeedApi}
        />
      )}
      noPadding
    >
      <div ref={tableRef} className={styles.table_wrapper}>
        <FeedStatisticTable
          heightTableBlock={heightTableBlock}
          wightTableBlock={wightTableBlock}
          statistics={feedApiStatistics}
          onClickActionButton={onClickActionButton}
          isFetchingRequest={isFetchingRequest}
          excludedСolumns={['feedDevice', 'feedTitle']}
        />
      </div>
    </Screen>
  );
});

export default FeedApiStatistics;
