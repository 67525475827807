import { observer } from 'mobx-react';
import React from 'react';
import Caption from '~/components/generic/Caption';
import Button from '~/components/ui/Button';
import ButtonLike from '~/components/ui/ButtonLike';
import Drawer from '~/components/ui/Drawer';
import Parameter from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/Parameters/Parameter';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import { BusinessRuleMeta } from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const Parameters: React.FC<{
  isOpenParameters: boolean;
  closeEditor: () => void;
  metadata: BusinessRuleMeta;
  businessRule: BusinessRulesModel;
}> = observer(({ isOpenParameters, closeEditor, metadata, businessRule }) => {
  return (
    <Drawer
      className="business_rule_editor__drawer"
      title={'Выберите, по каким параметрам подбирать схожие товары'}
      visible={isOpenParameters}
      onClose={closeEditor}
      size="x-small"
      actions={
        <ButtonLike onClick={closeEditor}>Готово</ButtonLike>
      }
    >
      <div className={styles.content_wrapper}>
        По этим параметрам будут подбираться схожие товары к выбранным
        категориям.
        {businessRule.get('similarityType').map((similarityType, index) => {
          return (
            <Parameter
              key={index}
              index={index}
              metadata={metadata}
              selectedItemKey={similarityType}
              onSelect={similarityType =>
                businessRule.changeSimilarityType(index, similarityType)
              }
              onDelete={businessRule.deleteParameter}
            />
          );
        })}
        {businessRule.get('similarityType').length < metadata.similarityTypeDict.length && (
          <Button
            icon={'Plus'}
            type={'grey'}
            block
            onClick={() => businessRule.addSimilarityType()}
          >
            Добавить параметр подбора
          </Button>
        )}
        <Caption className={styles.caption}>
          Чем выше параметр в списке, тем выше у него приоритет.
        </Caption>
      </div>
    </Drawer>
  );
});

export default Parameters;
