import { AxiosPromise } from 'axios';
import { actionGet, actionSet, post } from './client';

type LeadsResponse = IResponse<[LeadsPayload]>;
type RemoveLeadResponse = IResponse<[]>;
type TelegramInitialState = IResponse<[TelegramStatePayload]>;
type BitrixInitialState = IResponse<[BitrixStatePayload]>;
type BitrixHookState = IResponse<[BitrixHookPayload]>;
type SberCRMInitialState = IResponse<[SberCRMStatePayload]>;
type SberCRMHookState = IResponse<[SberCRMHookPayload]>;
type MacroCRMInitialState = IResponse<[SberCRMStatePayload]>;
type MacroCRMHookState = IResponse<[SberCRMHookPayload]>;
type AmoCRMInitialState = IResponse<[AmoCRMStatePayload]>;
type AmoCRMHookState = IResponse<[AmoCRMHookPayload]>;

export enum SortOrder {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

const Leads = {
  fetchLeads: (page: number, order: SortOrder = SortOrder.ASC): AxiosPromise<LeadsResponse> =>
    post(
      '/admin/feedBackWidgetData',
      {
        action: 'get',
        payload: {
          '@type': 'Pagination',
          'pageNum': page,
          'pageSize': 25,
          'orders': [
            { fieldName: 'datetime', order },
          ],
        },
      }
    ),

  removeLead: (id: string): AxiosPromise<RemoveLeadResponse> =>
    actionSet('/admin/feedBackWidgetData', { feedbackId: id }, null),

  getTelegramInitialState: (): AxiosPromise<TelegramInitialState> =>
    actionGet('/admin/telegram', {}),

  disableTelegram: () =>
    actionSet('/admin/telegram', { action: 'unbind' }, null),

  getBitrixInitialState: (): AxiosPromise<BitrixInitialState> =>
    actionGet('/admin/bitrix', {}),

  switchBitrix: (isEnabled: boolean, bindingUrl?: string): AxiosPromise<BitrixHookState> =>
    actionSet('/admin/bitrix', {}, {
      '@type': 'BitrixBindingData',
      isEnabled,
      bindingUrl,
    }),

  getSberCRMInitialState: (): AxiosPromise<SberCRMInitialState> =>
    actionGet('/admin/sberCRM', {}),

  switchSberCRM: (isEnabled: boolean, bindingUrl?: string): AxiosPromise<SberCRMHookState> =>
    actionSet('/admin/sberCRM', {}, {
      '@type': 'SberCRMBindingData',
      isEnabled,
      bindingUrl,
    }),

  getMacroCRMInitialState: (): AxiosPromise<MacroCRMInitialState> =>
    actionGet('/admin/macroCRM', {}),

  switchMacroCRM: (isEnabled: boolean, bindingUrl?: string): AxiosPromise<MacroCRMHookState> =>
    actionSet('/admin/macroCRM', {}, {
      '@type': 'MacroCRMBindingData',
      isEnabled,
      bindingUrl,
    }),

  getAmoCRMInitialState: (): AxiosPromise<AmoCRMInitialState> =>
    actionGet('/admin/amocrm', {}),

  switchAmoCRM: (isBinding: boolean, subdomain?: string, token?: string): AxiosPromise<AmoCRMHookState> =>
    actionSet('/admin/amocrm', {}, {
      '@type': 'AmoCRMBindingData',
      isBinding,
      subdomain,
      token,
    }),
};

export default Leads;
