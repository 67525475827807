import { List, Typography } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import CodeSnippet from '~/components/__deprecated/CodeSnippet';
import OldScreen from '~/screens/__deprecated/OldScreen';

import './style.css';

const { Paragraph, Text, Title } = Typography;

type Props = RouteComponentProps;

class InstallationManualScreen extends Component<Props> {
  renderManual = () => {
    const copyText = `<script type="text/javascript">\n\t(function(c,s,t,r,e,a,m){c[e]=c[e]||function(){(c[e].q=c[e].q||[]).push(arguments)},c[e].p=r,a=s.createElement(t),m=s.getElementsByTagName(t)[0],a.async=1,a.src=r,m.parentNode.insertBefore(a,m)})(window,document,'script','https://nlb-clickstream.sberbank.ru/clickstream.bundle.js','csa');\n\tcsa('init', {\n\t\tuserId: 'ПОСТОЯННЫЙ_ИДЕНТИФИКАТОР_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ',\n\t\tuserLogin: 'ПОСТОЯННЫЙ_ЛОГИН_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ',\n\t}, true, true);\n</script>`;
    const displayText = <>{`<script type="text/javascript">\n\t(function(c,s,t,r,e,a,m){c[e]=c[e]||function(){(c[e].q=c[e].q||[]).push(arguments)},c[e].p=r,a=s.createElement(t),m=s.getElementsByTagName(t)[0],a.async=1,a.src=r,m.parentNode.insertBefore(a,m)})(window,document,'script','https://nlb-clickstream.sberbank.ru/clickstream.bundle.js','csa');\n\tcsa('init', {\n\t\tuserId: '`}<Text strong type="warning">ПОСТОЯННЫЙ_ИДЕНТИФИКАТОР_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ</Text>{`',\n\t\tuserLogin: '`}<Text strong type="warning">ПОСТОЯННЫЙ_ЛОГИН_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ</Text>{`',\n\t}, true, true);\n</script>`}</>;

    const stepsList = [
      {
        title: 'а) Вставка через HTML',
        description: 'Вставьте код сразу после открытия тега <body>. Если нет такой возможности, то желательно расположить код как можно ближе к началу страницы.',
      },
      {
        title: 'б) Вставка через Google Tag Manager (или другие системы управления тегами)',
        description: 'Создайте новый тег с шаблоном Custom HTML и вставьте код туда. Не забудьте добавить триггер "Все страницы", чтобы код загружался с любой страницы сайта. В других системах управления тегами предусмотрено аналогичное поле для вставки HTML-кода.',
      },
      {
        title: 'в) Вставка через CMS',
        description: 'Необходимо найти место, где есть возможность редактировать исходный код заголовка всех страниц сайта (header). Код можно вставить туда ближе к началу.',
      },
    ];

    return (
        <Typography>
          <Title level={4}>Шаг 1. Скопируйте код и подставьте в него требуемые значения.</Title>
          <CodeSnippet copyText={copyText}>
            {displayText}
          </CodeSnippet>
          <Paragraph>
            <List
              header={<div>Значения:</div>}
              split={false}
              dataSource={[
                'ПОСТОЯННЫЙ_ИДЕНТИФИКАТОР_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ',
                'ПОСТОЯННЫЙ_ЛОГИН_ПОЛЬЗОВАТЕЛЯ_ЕСЛИ_ЕСТЬ',
              ]}
              renderItem={(item: String) => (<List.Item>• <b>{item}</b></List.Item>)}
            />
          </Paragraph>
          <Paragraph>
            <Text>
              Все поля опциональные. Если вы не хотите или не знаете как использовать какое-то из значений,
              указанных выше, то можете просто стереть из кода строчку с этим значением.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>В случае, если какие-то параметры изменились, то код также нужно обновить.</Text>
          </Paragraph>

          <Title level={4}>Шаг 2. Вставьте код на страницу.</Title>
          <List
            itemLayout="horizontal"
            split={false}
            grid={{ gutter: 16 }}
            dataSource={stepsList}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Typography>
    );
  }

  render() {
    return (
      <OldScreen
        className="installation-manual-screen"
        title="Установка скрипта ClickStream Analytics на сайт"
      >
        {this.renderManual()}
      </OldScreen>
    );
  }
}

export default InstallationManualScreen;
