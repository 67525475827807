import './style.css';

import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import RadioGroupWrapper from '~/components/notification-editor/RadioGroupWrapper';
import FormItem from '~/components/ui/FormItem';

interface NotificationWidthProps {
  fitContent?: boolean;
  onChange?: (fitContent: boolean) => void;
  hint?: string;
}

type Orientation = 'fitBlock' | 'fitContent';

const NotificationWidth: React.FC<NotificationWidthProps> = ({ onChange, fitContent: fitContent = false, hint }) => {
  const selectedIcon = <Icon name="CheckmarkS" className="notification-width__selected-icon" />;
  const handleChange = useCallback
    (
      (key: Orientation) => onChange?.(key === 'fitContent'),
      [fitContent, onChange]
    );

  return (
    <div className="notification-width">
      <FormItem
        title="Ширина"
        hint={hint}
        noHTMLLabel
        noReservedPlaceForMessage
      >
        <RadioGroupWrapper
          className="notification-width__radiogroup"
          onChange={handleChange}
          items={[
            {
              elemBefore: fitContent ? selectedIcon : '',
              item: <div key={'fitContent'}>По размеру текста</div>,
              elemAfter: <Icon name="FitContent" />,
            },
            {
              elemBefore: fitContent ? '' : selectedIcon,
              item: <div key={'fitBlock'}>По ширине блока</div>,
              elemAfter: <Icon name="FitBlock" />,
            },
          ]}
        />
      </FormItem>
    </div>
  );
};


export default NotificationWidth;
