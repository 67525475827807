import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Loading from '~/components/Loading';
import FeedInfo from '~/components/generic/FeedInfo';
import { EFeedType } from '~/components/generic/FeedInfo/types';
import TableMessages from '~/components/generic/FeedStatisticTable/TableMessages';
import EmptyScreen from '~/screens/FeedProScreen/components/EmptyScreen';
import FeedProEditor from '~/screens/FeedProScreen/components/FeedProEditor';
import FeedTable from '~/screens/FeedProScreen/components/FeedProStatistics';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import LoadingScreen from '~/screens/LoadingScreen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import './style.css';

const FeedScreen: React.FC = observer(() => {
  const { feedProStore, authStore } = useStores() as {
    feedProStore: FeedProStore;
    authStore: AuthStore;
  };

  const {
    isConstructorOpen,
    feedStatistics,
    getFeedProStatistics,
    isFetchingStatistics,
    fetchingStatisticsError,
    isStoppingDialogOpen,
    setIsStoppingDialogOpen,
    onClickActionButton,
    isShowWaitMessage,
    setIsShowWaitMessage,
    isRemoveDialogOpen,
    setIsRemoveDialogOpen,
    feedStatisticsActiveRow,
    isShowExceedingLimitMessage,
    setIsShowExceedingLimitMessage,
    isBeforeEditOpen,
    isFetchingRequest,
    isShowPreviewMobileFeedMessage,
    feed,
    isMessageBeforeExitingOpen,
    setIsMessageBeforeExitingOpen,
    setIsConstructorOpen,
    setIsBeforeEditOpen,
    isOpenRenameDialog,
    setIsOpenRenameDialog,
    renameFeed,
    isInfoOpen,
    setIsInfoOpen,
    isOpenFinalDialog,
    isShowTabooTypeChangesMessage,
    setIsShowTabooTypeChangesMessage,
  } = feedProStore;

  useEffect(() => {
    getFeedProStatistics();

    return () => feedProStore.destroy();
  }, []);

  if (isFetchingStatistics) {
    return <LoadingScreen />;
  }

  const loadingStatus = () => {

    if (isFetchingStatistics) return 'Loading';
    if (fetchingStatisticsError) return 'Error';

    return 'Success';
  };

  const loadedStatus = loadingStatus();
  const hasRight = authStore.currentUser.hasRightTo('VIEW_FEEDS');
  const showFeed = hasRight && feedStatistics && feedStatistics.length > 0 && loadedStatus === 'Success';
  const showFirstEntry = hasRight && feedStatistics && feedStatistics.length === 0;

  return (
    <>
      {hasRight && <Loading status={loadedStatus} onClick={getFeedProStatistics} />}
      {(!hasRight || showFirstEntry) && (
        <EmptyScreen hasRight={hasRight} />
      )}
      {showFeed && <FeedTable />}
      {isConstructorOpen && <FeedProEditor />}
      <FeedInfo
        isInfoDrawerOpen={isInfoOpen}
        setIsInfoDrawerOpen={setIsInfoOpen}
        onClickActionButton={onClickActionButton}
        feedStatistic={feedStatisticsActiveRow}
        feedType={EFeedType.PRO}
      />
      <TableMessages
        isStoppingDialogOpen={isStoppingDialogOpen}
        setIsStoppingDialogOpen={setIsStoppingDialogOpen}
        onClickActionButton={onClickActionButton}
        isChanging={!!feed?.get('id')}
        isMessageBeforeExitingOpen={isMessageBeforeExitingOpen}
        setIsMessageBeforeExitingOpen={setIsMessageBeforeExitingOpen}
        setIsConstructorOpen={setIsConstructorOpen}
        isShowWaitMessage={isShowWaitMessage}
        setIsShowWaitMessage={setIsShowWaitMessage}
        isRemoveDialogOpen={isRemoveDialogOpen}
        setIsRemoveDialogOpen={setIsRemoveDialogOpen}
        feedStatisticsActiveRow={feedStatisticsActiveRow}
        isShowExceedingLimitMessage={isShowExceedingLimitMessage}
        setIsShowExceedingLimitMessage={setIsShowExceedingLimitMessage}
        isBeforeEditOpen={isBeforeEditOpen}
        isFetchingRequest={isFetchingRequest}
        isShowPreviewMobileFeedMessage={isShowPreviewMobileFeedMessage}
        setIsBeforeEditOpen={setIsBeforeEditOpen}
        isOpenRenameDialog={isOpenRenameDialog}
        setIsOpenRenameDialog={setIsOpenRenameDialog}
        renameFeed={renameFeed}
        isOpenFinalDialog={isOpenFinalDialog}
        isShowTabooTypeChangesMessage={isShowTabooTypeChangesMessage}
        setIsShowTabooTypeChangesMessage={setIsShowTabooTypeChangesMessage}
      />
    </>
  );
});

export default FeedScreen;
