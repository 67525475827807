import React from 'react';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';
import './style.css';

type ILabelProps = {
  text: string;
  labelBackgroundColor: string;
  labelBackgroundOpacity: number;
  labelShadow: boolean;
  labelTextColor: string;
  labelTextColorOpacity: number;
  labelFontFamily: INotificationFont;
  labelFontWeight: INotificationFontWeight;
  labelFontSize: INotificationFontSize;
  labelFontItalic: boolean;
  labelTextLineThrough: boolean;
  labelTextUnderline: boolean;
  onClick?: () => void;
};

const Label: React.FC<ILabelProps> = ({
  text = 'Хит',
  labelBackgroundColor = '#D54242',
  labelBackgroundOpacity = 100,
  labelShadow = false,
  labelTextColor = '#ffffff',
  labelTextColorOpacity = 100,
  labelFontFamily = 'Inter',
  labelFontWeight = 'Medium',
  labelFontSize = 13,
  labelFontItalic = false,
  labelTextLineThrough = false,
  labelTextUnderline = false,
  onClick = null,
}) => (
  <div
    className={`label-color-editor-label__container ${labelShadow ? 'shadow' : ''}`}
    style={{
      backgroundColor: hexToRgb(labelBackgroundColor, labelBackgroundOpacity ?? 100),
      fontFamily: labelFontFamily,
      color: hexToRgb(labelTextColor, labelTextColorOpacity ?? 100),
      fontWeight: fontWeightValues[labelFontWeight],
      fontSize: `${labelFontSize}px`,
      fontStyle: labelFontItalic ? 'italic' : 'normal',
      textDecoration: getTextDecoration(labelTextUnderline, labelTextLineThrough),
    }}
    onClick={onClick}
  >
    {text}
  </div>
);

export default Label;
