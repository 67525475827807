import { Typography } from 'antd';
import React from 'react';
import Icon from '~/components/Icon';
import ButtonLike from '~/components/ui/ButtonLike';
import Spinner from '~/components/ui/Spinner';
import './style.css';

export type IState = 'Never' | 'Loading' | 'Error' | 'Success';

interface Props {
  status: IState;
  onClick: () => void;
}

const { Text } = Typography;

const getStatusRequest = (props: Props) => {
  if (props.status === 'Loading') {
    return <Spinner color="green" />;
  } else if (props.status === 'Error') {
    return (
      <div className="loading__wrapper">
        <Icon name="SadSmiley" className="loading__icon" />
        <div className="loading__text-wrapper">
          <Text className="loading__error-text">Во время загрузки данных произошла ошибка</Text>
        </div>
        <ButtonLike className="loading__button" type="outline" onClick={props.onClick}>Обновить страницу</ButtonLike>
      </div>
    );
  } else {
    return null;
  }
};

const Loading: React.FC<Props> = (props: Props) => {
  return (
    props.status !== 'Success' && (
      <div className="loading">
        {getStatusRequest(props)}
      </div>
    ));
};

export default Loading;
