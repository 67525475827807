import React, { useState } from 'react';
import PageHeader from '~/components/PageHeader';
import { SwitchUI } from '~/components/SwitchUI';
import WelcomePopUp from '~/components/WelcomePopUp';
import WidgetFromNonCustomers from '~/components/WidgetFromNonCustomers';
import Button from '~/components/ui/Button';
import Tabs from '~/components/ui/Menu/Tabs';
import Screen from '~/screens/Screen';
import { Connection } from '~/screens/SettingsScreen/Connection';
import { Documents } from '~/screens/SettingsScreen/Documents';
import { MyTariff } from '~/screens/SettingsScreen/MyTariff';
import UsersComponent from '~/screens/SettingsScreen/UsersComponent';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { parseGetParam } from '~/utils/parseGetParam';
import useStores from '~/utils/useStores';
import LoadingScreen from '../LoadingScreen';
import './style.css';

interface IItem {
  key: string;
  title: string;
  rights?: IRightCode[];
  uiSwitch?: TSwitchCode;
  onlyFintech?: boolean;
}

const usersSwitchCode = 'SettingsScreen#Users';
const userWelcomePopupSwitchCode = 'SettingsScreen#WelcomePopup';

const SettingsScreen: React.FC = () => {
  const { authStore } = useStores() as { authStore: AuthStore };
  const { settingsStore } = useStores() as { settingsStore: SettingsStore };
  const { uiSwitchStore } = useStores() as { uiSwitchStore: UiSwitchStore };
  const [showWidgetNonCustomer, toggleWidgetNonCustomer] = useState(false);

  const switchCodes = uiSwitchStore.getCodesToHide();
  const user = authStore.currentUser;

  const checkRights = (v: IItem) =>
    (!v.rights || (v.rights && v.rights.some((code: IRightCode) => user.hasRightTo(code))));

  const checkSwitch = (v: IItem) =>
     !(v.uiSwitch && switchCodes.includes(v.uiSwitch));

  const checkFintech = (v: IItem) =>
    !v.onlyFintech || (v.onlyFintech && user.isFintech);

  // @ts-ignore
  const items = [
    {
      key: '1',
      title: 'Мой тариф',
      rights: ['ADMIN'],
    },
    {
      key: '2',
      title: 'Подключение',
    },
    {
      key: '3',
      title: 'Пользователи',
      uiSwitch: usersSwitchCode,
      rights: ['ADMIN'],
    },
    {
      key: '4',
      title: 'Документы',
      rights: ['ADMIN'],
      onlyFintech: true,
    },
  ]
    .filter(v => checkRights(v as IItem))
    .filter(v => checkSwitch(v as IItem))
    .filter(v => checkFintech(v as IItem));

  const tabFromUrl = parseGetParam(location?.search, 'tab');

  const [currentMenuTab, setCurrentMenuTab] = useState(tabFromUrl || items[0].key);

  if (settingsStore.isFetching) {
    return <LoadingScreen />;
  }

  return (
    <Screen className="settings-screen__wrapper" noPadding>
      <PageHeader
        title="Настройки"
        aside={(
          <Button
            type="outline"
            loading={authStore.isLoggingOut}
            onClick={authStore.logout}
            icon="Logout"
          >
            Выйти из аккаунта
          </Button>
        )}
        className="settings-screen__header"
      />
      <Tabs
        items={items}
        value={currentMenuTab}
        onChange={(key) => {
          setCurrentMenuTab(key);
        }}
        noPadding
      />
      {settingsStore.showPopUp &&
        authStore.clientState === 'USER_PROFILE_NOT_FILLED' && (
          <SwitchUI elementCode={userWelcomePopupSwitchCode}>
            <WelcomePopUp toggle={toggleWidgetNonCustomer} />
          </SwitchUI>
        )
      }
      {showWidgetNonCustomer && (
        <WidgetFromNonCustomers 
          visible={showWidgetNonCustomer} 
          toggle={toggleWidgetNonCustomer}
        />
      )}
      {currentMenuTab === '1' && <MyTariff />}
      {currentMenuTab === '2' && <Connection />}
      {currentMenuTab === '3' && (
        <SwitchUI elementCode={usersSwitchCode}>
          <UsersComponent />
        </SwitchUI>
      )}
      {currentMenuTab === '4' && <Documents />}
    </Screen>
  );
};

export default React.memo(SettingsScreen);
