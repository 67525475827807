import { feed, IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import ButtonName from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Button/ButtonName';
import HandButtonsParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Button/HandButtonsParams';
import { currentButtonCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Button/currentButtonCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import SwitchButton from '~/components/ui/SwitchButton';
import './style.css';

interface IButtonProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
}

export const Button: React.FC<IButtonProps> = ({ configuration, onChange, onBlur }) => {
  const {
    css = {},
    hasCardButton,
    hasButtonIcon,
    buttonIconColor,
    buttonIconOpacity,
    buttonIconName,
  } = configuration;
  const [switchButtonState, setSwitchButtonState] = useState<string>(css?.button ? 'CSS' : 'HAND');
  const onChangeCssParam = (value: string) => {
    css.button = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Отображение"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <Checkbox
            label="Показать кнопку"
            className="feed-editor-appearance__checkbox"
            checked={hasCardButton}
            onCheck={checked =>
              onChange({ ...configuration, hasCardButton: checked }, true)
            }
          />
        </FormItem>
      </section>
      {hasCardButton && (
        <>
          <ButtonName
            configuration={configuration}
            onChange={onChange}
            onBlur={onBlur}
          />
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Иконка кнопки"
              overlayClassName="feed-editor-appearance__tooltip"
              noHTMLLabel
            >
              <Checkbox
                label="Добавить иконку кнопке"
                className="feed-editor-appearance__checkbox"
                checked={hasButtonIcon}
                onCheck={checked =>
                  onChange({ ...configuration, hasButtonIcon: checked }, true)
                }
              />
              {hasButtonIcon && (
                <>
                  <ColorPicker
                    color={buttonIconColor}
                    alpha={buttonIconOpacity}
                    onChange={data =>
                      onChange(
                        {
                          ...configuration,
                          buttonIconColor: data.color,
                          buttonIconOpacity: data.alpha,
                        },
                        true
                      )
                    }
                    className="feed-editor-appearance__color-picker"
                  />
                  <div className="feed-editor-appearance-button__icons">
                    {feed.icons.map((icon) => {
                      const background = icon.name === buttonIconName ? '#b4ebd8' : null;

                      return (
                        <div 
                          style={{
                            backgroundColor: background,
                            borderRadius: '8px',
                          }}
                          key={icon.name}
                          dangerouslySetInnerHTML={{__html: icon.svg(
                            buttonIconColor,
                            buttonIconOpacity
                            )}}
                          onClick={() => {
                            onChange(
                              {
                                ...configuration,
                                buttonIconName: icon.name,
                              },
                              true
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </FormItem>
          </section>
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Как настраивать стили"
              className="feed-editor-appearance__font"
            >
              <SwitchButton
                buttons={cssSwitchButtons}
                activeKey={switchButtonState}
                onClick={setSwitchButtonState}
              />
            </FormItem>
            {switchButtonState === 'CSS' && (
              <ParamCss
                onChangeCssParam={onChangeCssParam}
                value={css?.button ?? currentButtonCssParam(configuration)}
              />
            )}
          </section>
          {switchButtonState === 'HAND' && (
            <HandButtonsParams
              isActive={switchButtonState === 'HAND'}
              configuration={configuration}
              onChange={onChange}
              onChangeCssParam={onChangeCssParam}
            />
          )}
        </>
      )}
    </>
  );
};
