import React from 'react';
import Button from '~/components/ui/Button';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { IPlug } from '~/screens/LeadsSettingsScreen/telegram/types';

export const PlugByComputer = ({ link }: IPlug) => {
  const redirectToTelegram = (
    e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <SettingsItem
      Content={(
        <>
          <Button
            block
            onClick={redirectToTelegram}
            className="leads-settings__item-button"
          >
            Подключить через компьютер
          </Button>
          <div>
            Если у вас есть десктопное приложение Telegram или вы авторизованы в
            веб-версии Telegram
          </div>
        </>
      )}
    />
  );
};
