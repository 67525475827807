import React from 'react';
import styles from './style.module.css';

interface IErrorsList {
  errors: string[];
}

export const ErrorsList = ({ errors }: IErrorsList) => {
  return (
    <>
      {errors.map((v: string, index: number) => {
        return (
          <div key={index} className={styles.error}>
            <div className={styles.marker} />
            <div className={styles.text}>{v}</div>
          </div>
        );
      })}
    </>
  );
};
