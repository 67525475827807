import { observer } from 'mobx-react';
import React from 'react';
import QRCode from 'react-qr-code';
import Message from '~/components/ui/Message';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const PreviewMobileFeedMessage: React.FC = observer(() => {
  const { feedProStore } = useStores() as {
    feedProStore: FeedProStore;
  };
  const {
    urlForPreviewFeed,
    isShowPreviewMobileFeedMessage,
    setIsShowPreviewMobileFeedMessage,
  } = feedProStore;

  return (
    <Message
      showIcon={false}
      visible={isShowPreviewMobileFeedMessage}
      title={(
        <div className={styles.title}>
          <div className={styles.code}>
            <QRCode
              value={urlForPreviewFeed}
              size={100}
            />
          </div>
          Отсканируйте QR-код
        </div>
      )}
      textTop="Отсканируйте QR-код камерой смартфона и сможете увидеть, как лента рекомендаций будет выглядеть на мобильном устройстве."
      applyText="Закрыть"
      onClose={() => {
        setIsShowPreviewMobileFeedMessage(false);
      }}
      onApply={async () => {
        setIsShowPreviewMobileFeedMessage(false);
      }}
      isErrorBlock={false}
    />
  );
});

export default PreviewMobileFeedMessage;
