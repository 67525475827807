import React from 'react';
import { Mail } from './Mail';
import { MailSettings } from './MailSettings';
import styles from './style.module.css';

export const TabConstructor = () => {
  return (
    <div className={styles.constructor}>
      <div className={styles.result} id="letter-constructor-container">
        <Mail />
      </div>
      <div className={styles.letterSettings} id="mail_settings">
        <MailSettings />
      </div>
    </div>
  );
};
