import { IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import HandPriceParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Price/HandPriceParams';
import { currentCrossedPriceCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Price/currentCrossedPriceCssParam';
import { currentPriceCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Price/currentPriceCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import PricePosition from '~/components/notification-editor/RadioGrid/PricePosition';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import SwitchButton from '~/components/ui/SwitchButton';

interface IPriceProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
}

export const Price: React.FC<IPriceProps> = ({ configuration, onChange }) => {
  const [switchButtonState, setSwitchButtonState] = useState<string>(
    configuration.css?.cardPrice ? 'CSS' : 'HAND'
  );
  const onChangeCssParam = (value: string, isCrossed: boolean = false) => {
    const css = configuration.css ?? {};
    isCrossed ? (css.cardCrossedPrice = value) : (css.cardPrice = value);
    onChange({ ...configuration, css }, true);
  };

  const onChangeCssForHandParam = (value: string) => {
    const css = configuration.css ?? {};
    css.cardCrossedPrice = value;
    css.cardPrice = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Отображение"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <Checkbox
            label="Показать цену"
            className="feed-editor-appearance__checkbox"
            checked={configuration.hasCardPrice}
            onCheck={checked =>
              onChange({ ...configuration, hasCardPrice: checked }, true)
            }
          />
        </FormItem>
      </section>

      {configuration.hasCardPrice && (
        <>
          {configuration.hasCardCrossedPrice && (
            <section className="feed-editor-appearance__section">
              <FormItem
                title="Расположение старой цены"
                overlayClassName="feed-editor-appearance__tooltip"
                noHTMLLabel
              >
                <PricePosition
                  className="feed-editor-appearance-position__block"
                  activePosition={configuration.cardCrossedPricePosition}
                  onClick={position =>
                    onChange(
                      {
                        ...configuration,
                        cardCrossedPricePosition: position,
                      },
                      true
                    )
                  }
                />
              </FormItem>
            </section>
          )}
          <section className="feed-editor-appearance__section">
            <FormItem
              title="Настройка стиля "
              overlayClassName="feed-editor-appearance__tooltip"
              className="feed-editor-appearance__font"
            >
              <SwitchButton
                buttons={cssSwitchButtons}
                activeKey={switchButtonState}
                onClick={setSwitchButtonState}
              />
            </FormItem>
            {switchButtonState === 'CSS' && (
              <>
                <FormItem
                  title={'Основная цена'}
                  overlayClassName="feed-editor-appearance__tooltip"
                  className="feed-editor-appearance__font"
                >
                  <ParamCss
                    onChangeCssParam={onChangeCssParam}
                    value={
                      configuration.css?.cardPrice ??
                      currentPriceCssParam(configuration)
                    }
                  />
                </FormItem>
                {configuration.hasCardCrossedPrice && (
                  <FormItem
                    title={'Старая цена'}
                    overlayClassName="feed-editor-appearance__tooltip"
                    className="feed-editor-appearance__font"
                  >
                    <ParamCss
                      onChangeCssParam={params =>
                        onChangeCssParam(params, true)
                      }
                      value={
                        configuration.css?.cardCrossedPrice ??
                        currentCrossedPriceCssParam(configuration)
                      }
                    />
                  </FormItem>
                )}
              </>
            )}
          </section>
          {switchButtonState === 'HAND' && (
            <HandPriceParams
              isActive={switchButtonState === 'HAND'}
              configuration={configuration}
              onChange={onChange}
              onChangeCssParam={onChangeCssForHandParam}
            />
          )}
        </>
      )}
    </>
  );
};
