import { Steps as AntSteps } from 'antd';
import cx from 'classnames';
import React from 'react';
import './style.css';

interface ItemProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
}

export interface StepsProps {
  items: ItemProps[];
  current: number;
  initial?: number;
  className?: string;
  direction?: 'horizontal' | 'vertical';
  size?: 'default' | 'small';
}

const getDirection = (direction: 'horizontal' | 'vertical' | undefined) => {

  if (!direction && window.screen.width < 480) {
    return 'vertical';
  }

  return direction;
};

const Steps: React.FC<StepsProps> = ({
  items = [],
  className,
  current,
  direction,
  initial,
  size = 'small',
}) => {
  const innerItems = items.map(({ title, subTitle, description, disabled }, index) => {
    const { Step } = AntSteps;

    return (
      <Step
        title={title}
        subTitle={subTitle}
        description={description}
        key={index}
        disabled={disabled}
      />
    );
  });

  return (
    <AntSteps
      current={current}
      className={cx('steps', className)}
      direction={getDirection(direction)}
      initial={initial}
      size={size}
    >
      {innerItems}
    </AntSteps>
  );
};

export default Steps;
