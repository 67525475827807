import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/ui/Button';
import { QuizPossibilities } from '~/screens/QuizScreen/components/QuizStartScreen/QuizPossibilities';
import { QuizTypes } from '~/screens/QuizScreen/components/QuizStartScreen/QuizTypes';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

export const QuizStartScreen = () => {
  const { authStore, quizStore, settingsStore } = useStores() as {
    authStore: AuthStore;
    quizStore: QuizStore;
    settingsStore: SettingsStore;
  };

  const hasWrites = authStore.currentUser.hasRightTo('LIST_QUIZ');

  const { setIsConstructorOpen } = quizStore;

  const [isNonIndividual, setIsNonIndividual] = useState<boolean>(false);
  const [isActionButtonLoading, setIsActionButtonLoading] = useState<boolean>(
    !hasWrites
  );

  useEffect(() => {
    if (hasWrites) return;

    (async () => {
      setIsActionButtonLoading(true);
      await settingsStore.getServices();

      const isNonIndividualCheck = !!settingsStore.services.filter((v, k) => {
        return v.price !== 0 && !v.isActive && v.code === 'QUIZ_SRV';
      })?.length;

      setIsNonIndividual(isNonIndividualCheck);
      setIsActionButtonLoading(false);
    })();
  }, []);

  const history = useHistory();

  const handleCreate = () => {
    setIsConstructorOpen(true);
  };

  const getActionButton = () => {
    if (isActionButtonLoading) {
      return {
        name: '',
        loading: isActionButtonLoading,
      };
    }

    if (hasWrites) {
      return {
        onClick: handleCreate,
        icon: 'Plus',
        name: 'Создать квиз',
      };
    }

    if (isNonIndividual) {
      return {
        onClick: () => history.push('/settings'),
        icon: 'Plus',
        name: 'Подключить инструмент Квизы',
      };
    }

    return {
      onClick: redirectToClickform,
      icon: 'Plus',
      name: 'Оставить заявку',
    };
  };

  const actionButton = getActionButton();

  const { loading, icon, onClick, name } = actionButton;


  return (
    <div className={styles.quizPromo}>
      <QuizPossibilities
          {...actionButton}
      />
      <QuizTypes />
      <div className={styles.left}>
        <Button
            loading={loading}
            icon={icon}
            className={styles.button}
            onClick={onClick}
        >
          {name}
        </Button>
      </div>
      {/* TODO пока не используем слайдер */}
      {/*<QuizPreview {...actionButton} />*/}
    </div>
  );
};
