import feedApiStore from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import businessRuleStore from '~/screens/BusinessRulesScreen/store/businessRuleStore';
import feedLiteStore from '~/screens/FeedLiteScreen/store/feedLiteStore';
import feedProStore from '~/screens/FeedProScreen/store/feedProStore';
import mailingStore from '~/screens/MailingScreen/stores/mailingStore';
import quizStore from '~/screens/QuizScreen/stores/quizStore';
import wheelOfFortuneStore from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import uiSwitchStore from '~/stores/UiSwitchStore';
import appStore from '~/stores/appStore';
import authStore from '~/stores/authStore';
import dateRangeStore from '~/stores/dateRangeStore';
import feedStore from '~/stores/feedStore';
import leadsStore from '~/stores/leadsStore';
import mediaQueriesStore from '~/stores/mediaQueriesStore';
import profileStore from '~/stores/profileStore';
import rolesStore from '~/stores/rolesStore';
import serviceStore from '~/stores/serviceStore';
import settingsStore from '~/stores/settingsStore';
import tariffStore from '~/stores/tariffStore';

export default {
  appStore,
  authStore,
  businessRuleStore,
  dateRangeStore,
  feedApiStore,
  feedLiteStore,
  feedProStore,
  feedStore,
  leadsStore,
  mailingStore,
  mediaQueriesStore,
  profileStore,
  quizStore,
  rolesStore,
  serviceStore,
  settingsStore,
  tariffStore,
  uiSwitchStore,
  wheelOfFortuneStore,
};
