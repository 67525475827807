import React, { useEffect } from 'react';
import DeviceSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import Desktop from '~/components/generic/StepSelectorAndDesign/NoFrameInstructions/Desktop';
import Mobile from '~/components/generic/StepSelectorAndDesign/NoFrameInstructions/Mobile';
import TopPanel from '~/components/generic/StepSelectorAndDesign/TopPanel';
import optionsForDeviceSelector from '~/components/generic/StepSelectorAndDesign/optionsForDeviceSelector';
import './style.css';

interface IFeedEditorInstructions {
  id?: number | string;
  urlPattern: string;
  device: Device;
  setDevice: (device: Device) => void;
  fetchSelector: (id: string, type: 'desktop' | 'mobile') => void;
  uuid: string;
  reqRec: 'off' | 'temp';
  feedTypeName?: string;
}

const INTERVAL_FOR_REQUESTING_SELECTOR: number = 3000;

const NoFrameInstructions: React.FC<IFeedEditorInstructions> = ({
  id,
  urlPattern,
  device,
  setDevice,
  fetchSelector,
  uuid,
  reqRec = 'off',
  feedTypeName = '',
}) => {
  const feedIdString = id ? `&feedId=${id}` : '&feedId=0';
  const urlParams = `type=initEmbeddingFeed&platform=${device}&uuid=${uuid}${feedIdString}&reqRec=${reqRec}`;
  const url = `${urlPattern}#${urlParams}`;

  const onFocus = () => {
    itsTimeToCheckSelectorInBack();
  };

  const itsTimeToCheckSelectorInBack = () => {
    if (document.hasFocus()) {
      fetchSelector(uuid, device);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    const timer = setInterval(
      itsTimeToCheckSelectorInBack,
      INTERVAL_FOR_REQUESTING_SELECTOR
    );

    return () => {
      window.removeEventListener('focus', onFocus);
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <TopPanel urlPattern={urlPattern} feedTypeName={feedTypeName} />
      <div className="feed-editor-instructions">
        <div className="feed-editor-instructions__wrapper">
          <NumberedBlock number={1}>
            <DeviceSelectorRadioButton
              title={<>Где вы хотите показывать ленту рекомендаций?</>}
              currentDevice={device}
              onChange={setDevice}
              listOptions={optionsForDeviceSelector(device)}
            />
          </NumberedBlock>
          {device === 'desktop' && <Desktop url={url} />}
          {device === 'mobile' && <Mobile url={url} />}
        </div>
      </div>
    </>
  );
};

export default NoFrameInstructions;
