type ParseGetParams = { [key: string]: string };

export const parseGetParams = (
  queryString: string,
  params?: string[]
): ParseGetParams | false => {
  try {
    const searchParams = new URLSearchParams(queryString);

    if (!params?.length) {
      const allKeyValuesObject: { [key: string]: string } = {};
      searchParams.forEach((value, key) => (allKeyValuesObject[key] = value));

      return allKeyValuesObject;
    } else {
      const selectedValuesObject: { [key: string]: string } = {};
      searchParams.forEach((value, key) => {
        if (params?.includes(key)) {
          selectedValuesObject[key] = value;
        }
      });

      return selectedValuesObject;
    }
  } catch (e) {
    console.log({ e });

    return false;
  }
};
