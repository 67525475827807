import { TCodeServices } from '~/api/settings';
import { IServices } from '~/stores/settingsStore';

export interface IServiceBox {
  service: IServices;
  remoteService: (service: string, active: boolean) => void;
  isRedirect: boolean;
  withIndividualCalculation: boolean;
  isLastActiveService: boolean;
  inProcessing: boolean;
  resetProcessing: () => void;
  tarplanConnectionDate: string;
  tariffPrice: string;
  isNotPaidTariff: boolean;
}

export const SERVICES_INFO = {
  [TCodeServices.SCENARIO_SRV]: {
    title: 'Виджеты и Лиды',
    icon: 'Leads',
  },
  [TCodeServices.FEED_LITE_SRV]: {
    title: 'Ленты рекомендаций Lite',
    icon: 'Constructor',
  },
  [TCodeServices.FEED_PRO_SRV]: {
    title: 'Ленты рекомендаций Pro',
    icon: 'Target',
  },
  [TCodeServices.FORTUNE_WHEEL_SRV]: {
    title: 'Колесо фортуны',
    icon: 'Wheel',
  },
  [TCodeServices.QUIZ_SRV]: {
    title: 'Квизы',
    icon: 'Quiz',
  },
};

interface IModalOptions {
  title: string;
  textTop: string;
  textBottom?: {
    textBottomPrice?: string;
    textBottomDate?: string;
  };
  applyText: string;
  cancelText?: string;
}

interface IModal {
  enableService: IModalOptions;
  enablePromoService: IModalOptions;
  disableService: IModalOptions;
  lastDisableService: IModalOptions;
  isPaidServices: IModalOptions;
}

export const modalOptions: IModal = {
  enableService: {
    title: 'Хотите подключить',
    textTop: 'Функционал станет доступен в дату следующего списания – ',
    textBottom: {
      textBottomPrice: 'Итоговая стоимость составит:',
    },
    applyText: 'Подключить',
    cancelText: 'Отмена',
  },
  enablePromoService: {
    title: 'Хотите подключить',
    textTop:
      'При подключении срок пробного периода этого функционала будет до ',
    textBottom: {
      textBottomPrice: 'Итоговая стоимость составит:',
      textBottomDate: 'Дата следующего списания:',
    },
    applyText: 'Подключить',
    cancelText: 'Отмена',
  },
  disableService: {
    title: 'Вы уверены, что хотите отключить',
    textTop: 'Функционал станет недоступен сразу после отключения.',
    applyText: 'Отключить',
    cancelText: 'Отмена',
  },
  lastDisableService: {
    title: 'Вы уверены, что хотите отключить',
    textTop: `Отключив сервис, Вы отказываетесь от услуг СберЛид. Соглашение об использовании сервиса
      будет расторгнуто, оплата подписки остановлена и сбор данных по Вашему сайту будет прекращен,
      вся функциональность сервиса отключена.`,
    applyText: 'Отмена',
    cancelText: 'Отключить сервис',
  },
  isPaidServices: {
    title: 'Оплата не прошла :(',
    textTop: `Возможно, на вашей карте недостаточно средств.
     Проверьте баланс или попробуйте ввести данные другой карты.`,
    applyText: 'Закрыть',
  },
};
