export interface IProductItem {
  name: string;
  price: number;
  count: number;
  url: string;
  picture: string;
}

export const ProductItems = (params: any, items: IProductItem[]) => {
  const { backgroundColor } = params;

  return items.map((item: IProductItem) => {
    return `<mj-wrapper
      padding="4px 20px"
      background-color="${backgroundColor}">
      <mj-section padding="0" border-radius="12px" background-color='#e5e5e5'>
        <mj-group>
         <mj-column padding="0px" css-class='mobileItemPhoto'>
                <mj-image
                width="100%"
                fluid-on-mobile="true"
                padding="12px"
                href="${item.url}"
                target="_blank"
                align="center"
                border-radius='8px'
                src='${item.picture}'></mj-image>
         </mj-column>
         <mj-column padding="0px" css-class='mobileItemText'>
            <mj-button
               href="${item.url}"
               background-color="#e5e5e5"
               color="#4d4d4d"
               text-align='left'
               font-family='Roboto'
               font-weight='600'
               font-size='16px'
               line-height="24px"
               width="100%"
               align="left"
               padding="0px"
               inner-padding="12px 0px 4px 12px"
               >
               ${item.name}
            </mj-button>
            <mj-button
               href="${item.url}"
               color="#808080"
               align='left'
               text-align="left"
               padding='0px'
               font-family='Roboto'
               font-weight='500'
               font-size='16px'
               line-height="24px"
               background-color="#e5e5e5"
               width="100%"
               inner-padding="0px 0px 12px 12px"
               >

               Количество ${item.count}
            </mj-button>
            <mj-button
                  href="${item.url}"
                  color="#4d4d4d"
                  align='left'
                  text-align="left"
                  padding='0px'
                  font-family='Roboto'
                  font-weight='700'
                  line-height='24px'
                  font-size="26px"
                  inner-padding="0px 0px 12px 12px"
                  width="100%"
                  background-color="#e5e5e5"
                  >
               ${new Intl.NumberFormat('ru-RU').format(item.price / 100)} ₽
            </mj-button>
         </mj-column>
        </mj-group>

        </mj-section>
      </mj-wrapper>`;
  });
};
