import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import FontSettings from '~/components/notification-editor/FontSettings';
import LabelColorEditor, {
  ILabelColorEditorData,
} from '~/components/notification-editor/LabelColorEditor';
import ItemLabelPosition from '~/components/notification-editor/RadioGrid/ItemLabelPosition';
import Accordion from '~/components/ui/Accordion';
import Checkbox from '~/components/ui/CheckBox';
import FormItem from '~/components/ui/FormItem';
import './style.css';

interface ILabelProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
}

export const Label: React.FC<ILabelProps> = ({ configuration, onChange }) => {
  return (
    <>
      <section className="feed-editor-appearance__section">
        <Accordion
          className="feed-editor-appearance-label__accordion"
          title={'Настроить теги'}
          subTitle={'Изменить цвет и название тегов'}
        >
          <div className="feed-editor-appearance-label__block">
            {configuration.labels.map((label) => {
              return (
                <LabelColorEditor
                  key={label.uuid}
                  text={label.text}
                  labelBackgroundColor={label.labelBackgroundColor}
                  labelBackgroundOpacity={label.labelBackgroundOpacity ?? 100}
                  feedConfiguration={configuration}
                  onChangeColor={(data: ILabelColorEditorData) => {
                    const labels = configuration.labels;
                    labels.find(
                      labelInConfiguration =>
                        labelInConfiguration.uuid === label.uuid
                    ).labelBackgroundColor = data.labelColor;
                    labels.find(
                      labelInConfiguration =>
                        labelInConfiguration.uuid === label.uuid
                    ).labelBackgroundOpacity = data.labelOpacity;
                    onChange({
                      ...configuration,
                      labels,
                    }, true);
                  }}
                  isPossibleTextEditing
                  onChangeName={(text: string) => {
                    const labels = configuration.labels;
                    labels.find(
                      labelInConfiguration =>
                        labelInConfiguration.uuid === label.uuid
                    ).text = text;
                    onChange({
                      ...configuration,
                      labels,
                    }, true);
                  }}
                  step={10}
                />
              );
            })}
          </div>
        </Accordion>
      </section>
      <section className="feed-editor-appearance__section">
        <FontSettings
          className="feed-editor-appearance__font"
          type="label"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          data={{
            fontFamily: configuration.labelFontFamily,
            color: configuration.labelTextColor,
            alpha: configuration.labelTextColorOpacity ?? 100,
            fontWeight: configuration.labelFontWeight,
            fontSize: configuration.labelFontSize as TFontSize,
            isItalic: configuration.labelFontItalic,
            isLineThrough: configuration.labelTextLineThrough,
            isUnderline: configuration.labelTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                labelFontFamily: data.fontFamily,
                labelTextColor: data.color,
                labelTextColorOpacity: data.alpha,
                labelFontWeight: data.fontWeight,
                labelFontSize: data.fontSize,
                labelFontItalic: data.isItalic,
                labelTextLineThrough: data.isLineThrough,
                labelTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Расположение"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <ItemLabelPosition
            className="feed-editor-appearance-position__block"
            activePosition={configuration.labelsPosition}
            onClick={position =>
              onChange(
                {
                  ...configuration,
                  labelsPosition: position,
                },
                true
              )
            }
          />
        </FormItem>
      </section>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Тени"
          overlayClassName="feed-editor-appearance__tooltip"
          noHTMLLabel
        >
          <Checkbox
            label="Добавить тень"
            className="feed-editor-appearance__checkbox"
            checked={configuration.labelShadow}
            onCheck={checked =>
              onChange({ ...configuration, labelShadow: checked }, true)
            }
          />
        </FormItem>
      </section>
    </>
  );
};
