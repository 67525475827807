import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

type IChipProps = {
  id: string;
  name: string;
  onClick: (id: string) => void;
  isActive?: boolean;
  className?: string;
};

const Chip: React.FC<IChipProps> = ({
  id,
  name,
  isActive,
  onClick,
  className,
}) => {
  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHintShown, setIsHintShown] = useState(true);
  const [innerWidth, setInnerWidth] = useState(null);
  const [shiftWidth, setShiftWidth] = useState(null);
  const [hintHeight, setHeight] = useState(null);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const outerWidth = outerRef?.current.getBoundingClientRect().width;
    const outerHeight = outerRef?.current.getBoundingClientRect().height;
    const innerWidth = innerRef?.current.getBoundingClientRect().width;

    setInnerWidth(innerWidth);
    setHeight(outerHeight);
    setShiftWidth(innerWidth - (innerWidth - outerWidth));
  }, []);

  useEffect(() => {
    const outerWidth = outerRef?.current.getBoundingClientRect().width;
    const innerWidth = innerRef?.current.getBoundingClientRect().width;

    if (isHovered && !isHintShown && innerWidth > outerWidth) {
      setIsHintShown(true);
    }

    if (!isHovered && isHintShown) {
      setIsHintShown(false);
    }
  }, [isHovered]);

  return (
    <>
      <div
        className={`${styles.chip} ${isActive ? styles.active : ''} ${
          className || ''
        }`}
        onClick={() => onClick(id)}
        ref={outerRef}
      >
        <span ref={innerRef} onMouseEnter={onMouseEnter}>
          {name}
        </span>
      </div>
      <div className={styles.hintWrap} onMouseLeave={onMouseLeave}>
        {isHintShown && (
          <div
            onClick={() => onClick(id)}
            className={styles.hint}
            style={{
              left: `-${shiftWidth - 10}px`,
              width: `${innerWidth}px`,
              top: `-${17}px`,
              height: `${hintHeight}`,
            }}
          >
            {name}
          </div>
        )}
      </div>
    </>
  );
};

export default Chip;
