import 'rc-color-picker/assets/index.css';
import './style.css';

import cx from 'classnames';
import RcColorPicker from 'rc-color-picker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from '~/components/ui/Input';
import InputGroup from '~/components/ui/InputGroup';
import {
  alphaStepVerification,
  colorToView,
  completeHexCode,
  formatAlpha,
  formatColor,
  isValidHexColor,
  isWhite,
} from '~/utils/hexUtils';

export interface ColorData {
  color?: string;
  alpha?: number;
}

export interface ColorPickerProps extends ColorData {
  className?: string;
  step?: number;
  onChange?: (data: ColorData) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  color = '#000000',
  alpha = 100,
  className,
  step = 10,
  onChange,
}) => {
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const [prevSuccessValue, setPrevSuccessValue] = useState(
    formatColor(color, true)
  );
  const [colorHexValue, setColorHexValue] = useState(formatColor(color, true));
  const [alphaInputValue, setAlphaInputValue] = useState(
    formatAlpha(alpha, true)
  );

  useEffect(() => {
    setColorHexValue(color);
    setAlphaInputValue(formatAlpha(alpha, true));
  }, [color]);

  const onColorBlur = useCallback(() => {
    const completedColor = completeHexCode(colorHexValue);
    const finalColorValue = isValidHexColor(completedColor)
      ? completedColor
      : prevSuccessValue;
    setPrevSuccessValue(finalColorValue);
    setColorHexValue(finalColorValue);
    onChange?.({ color: finalColorValue, alpha });
  }, [colorHexValue, alpha, onChange]);

  const onColorFocus = useCallback(
    () => setColorHexValue(formatColor(color)),
    [color]
  );

  const onColorChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const tempColor: string = e.currentTarget.value;
    const color = tempColor[0] === '#' ? tempColor.slice(1) : tempColor;

    if (color.length > 0 && !/^[0-9A-F]+$/i.test(color)) {

      return;
    }
    setColorHexValue(color);
  }, [color]);

  const onAlphaBlur = useCallback(() => {
    const alphaVerified = alphaStepVerification(alpha, step);
    setAlphaInputValue(formatAlpha(alphaVerified, true));
    onChange?.({ color, alpha: alphaVerified });
  }, [alpha, onChange]);

  const onAlphaFocus = useCallback(
    () => setAlphaInputValue(formatAlpha(alpha)),
    [alpha]
  );

  const onAlphaChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const trimmed = (e.currentTarget.value || '0').substring(0, 3);
      const alpha = parseInt(trimmed, 10);
      if (isNaN(alpha) || alpha > 100) {

        return;
      }

      setAlphaInputValue(formatAlpha(alpha));
      onChange?.({ color, alpha });
    },
    [alpha, onChange]
  );

  const onPickerChange = useCallback(
    ({ color, alpha }: ColorPickerProps) => {
      const alphaVerified = alphaStepVerification(alpha, step);
      setAlphaInputValue(formatAlpha(alphaVerified, true));
      setColorHexValue(color);
      onChange?.({ color, alpha: alphaVerified });
    },
    [color, alpha, onChange]
  );

  return (
    <InputGroup
      className={cx('color-picker', className)}
      block
      ref={inputGroupRef}
    >
      <Input
        className={cx('color-picker__hex')}
        value={colorHexValue}
        maxLength={7}
        elemBefore={(
          <RcColorPicker
            className={cx('color-picker__color-button', {
              _outline: isWhite(color),
            })}
            color={colorToView(color)}
            alpha={alpha}
            style={{ zIndex: 1004 }}
            onChange={onPickerChange}
            onBlur={onPickerChange}
            getCalendarContainer={() => inputGroupRef.current}
          />
        )}
        onBlur={onColorBlur}
        onFocus={onColorFocus}
        onChange={onColorChange}
      />
      <Input
        className={cx('color-picker__alpha')}
        value={alphaInputValue}
        maxLength={3}
        onBlur={onAlphaBlur}
        onFocus={onAlphaFocus}
        onChange={onAlphaChange}
      />
    </InputGroup>
  );
};

export default ColorPicker;
