import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import Tooltip from '~/components/ui/Tooltip';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import WheelsPreview from '../WheelsPreview';
import style from './style.module.css';

interface IChanceToWin {
  setIsButtonDisabled: (v: boolean) => void;
}

export const ChanceToWin: React.FC<IChanceToWin> = observer(
  ({ setIsButtonDisabled }) => {
    const { wheelOfFortuneStore } = useStores() as {
      wheelOfFortuneStore: WheelOfFortuneStore;
    };
    const {
      wheelOfFortune,
      getSectors,
      targetSectorToEdit,
      getSectorByIndex,
      changeChance,
    } = wheelOfFortuneStore;

    const wheelProps = {
      sectors: getSectors(),
      radius: 100,

      animationProps: {
        winSectorNumber: 4,
        start: false,
        onAnimationEnd: () => alert('animation ended'),
      },
    };

    const t = wheelOfFortune.get('sectorCount') * 100;
    const placeholder = 10000 / t;
    const isTargetSectorCoupon = targetSectorToEdit?.coupon !== '';

    useEffect(() => {
      if (wheelOfFortuneStore.selectedId) return;

      const inputGiftsLength = wheelOfFortuneStore.inputGifts.length;
      const probabilityAvg = 100 / inputGiftsLength;
      const maxIndex = inputGiftsLength - 1;

      let maxProbability = 0;

      wheelOfFortuneStore.inputGifts.map((v, index) => {
        if (maxIndex === index) {
          wheelOfFortuneStore.inputGifts[index].probability =
            100 - maxProbability;

          return;
        }
        wheelOfFortuneStore.inputGifts[index].probability = Math.ceil(
          probabilityAvg
        );
        maxProbability += Math.ceil(probabilityAvg);
      });
    }, []);

    const calcSumPercent = wheelOfFortuneStore.inputGifts.reduce(
      (a, b) => a + b.probability,
      0
    );

    useEffect(() => {
      if (calcSumPercent === 100) {
        setIsButtonDisabled(false);

        return;
      }

      setIsButtonDisabled(true);
    }, [calcSumPercent]);

    const staticPlaceholder = (
      <div className={style.staticPlaceholder}>
        <Icon name="Percent" className={style.placeHolderIcon} />
        <Tooltip
          placement="bottom"
          title={(
            <div className={style.placeHolderText}>
                После того как вы добавили процент вероятности у одного подарка,
                укажите процент для всех остальных подарков
            </div>
          )}
        >
          <div>
            <Icon name="Question" className={style.hintIcon} />
          </div>
        </Tooltip>
      </div>
    );

    return (
      <div className={style.wrapper}>
        <div className={style.title}>Шансы выигрыша подарков</div>
        <div className={style.subtitle}>
          Для изменения шанса, укажите процент для каждого подарка, сумма
          которых составит 100%
        </div>
        <div className={style.content}>
          <div className={style.content_leftWrap}>
            <div className={style.content_left}>
              <WheelsPreview
                title={targetSectorToEdit?.name}
                coupon={targetSectorToEdit?.coupon}
                buttonBackgroundColor={
                  wheelOfFortune.get('sectorUIParams')[1].backgroundColor
                }
                buttonTextColor={
                  wheelOfFortune.get('sectorUIParams')[1].textColor
                }
                buttonText={isTargetSectorCoupon ? 'Копировать' : 'Закрыть'}
                hasCoupon={isTargetSectorCoupon}
                wheelProps={wheelProps}
                isMobile={wheelOfFortune.get('isMobile')}
              />
            </div>
          </div>
          <div className={style.content_right}>
            <div className={style.content_form}>
              <div className={style.contentFormTitle}>
                <div className={style.contentDescription}>Шанс выигрыша:</div>
                <div className={style.contentPercent}>
                  <span
                    className={
                      calcSumPercent === 100
                        ? style.contentPercentSuccess
                        : style.contentPercentError
                    }
                  >
                    {calcSumPercent}
                  </span>{' '}
                  из 100%
                </div>
              </div>
              {wheelOfFortune.get('sectors').map((sector, index) => {
                if (index >= wheelOfFortune.get('sectorCount')) return null;

                return (
                  <div key={index}>
                    <FormItem title={sector.name}>
                      <Input
                        type="text"
                        placeholder={`${placeholder.toFixed(2)} %`}
                        value={sector.probability.toString()}
                        onChange={(event: any) => {
                          changeChance(event, index);
                        }}
                        onFocus={() => getSectorByIndex(index)}
                        autoFocus={index === 0}
                        maxLength={2}
                        elemAfter={staticPlaceholder}
                        className={style.percentInput}
                      />
                    </FormItem>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
