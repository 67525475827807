import { observer } from 'mobx-react';
import React from 'react';
import HelpBlock from '~/components/generic/HelpBlock';
import RecommendationsApiBlock from '~/components/generic/RecommendationsApiBlock';

import styles from './style.module.css';

const StepManual: React.FC = observer(() => {
  return (
    <div className={styles.content}>
      <RecommendationsApiBlock />
      <a href={'/assets/doc/feed_api_instruction.pdf'} className={styles.link} target="_blank">
        Скачать полную инструкцию .pdf
      </a>
      <HelpBlock />
    </div>
  );
});

export default StepManual;
