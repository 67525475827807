import React from 'react';
import Icon from '~/components/Icon';
import HelpBlock from '~/components/generic/HelpBlock';
import NumberedBlock from '~/components/generic/NumberedBlock';
import Button from '~/components/ui/Button';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { useGetHelpEmailHook } from '~/customHooks/useGetHelpEmail/useGetHelpEmail';
import styles from './style.module.css';

const StepManual = () => {

  const helpEmail = useGetHelpEmailHook();

  return (
    <div className={styles.block_wrapper}>
      <div
        className={`${styles.label_big} ${styles.black} ${styles.margin_bottom} ${styles.label_padding}`}
      >
        1. Скопируйте и вставьте этот код во все кнопки добавления товара в
        корзину
      </div>
      <div
        className={`${styles.label_middle} ${styles.black} ${styles.margin_bottom} ${styles.label_padding}`}
      >
        Эти кнопки могут быть на страницах товаров, в превью товаров на главной,
        в корзине, на промо-страницах и других местах.
      </div>
      <div className={styles.margin_bottom}>
        <ToCopyArea
          className={styles.copy_block}
          text={
            'onclick="try { window.sberLeadApi.addToBasket(<product_id>, <product_count>) } catch(e) {}"'
          }
          height={'40px'}
          style={{
            color: '#00BD7E',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        />
      </div>
      <NumberedBlock className={styles.item_button}>
        <div className={styles.manual}>
          <div className={styles.manual_item}>
            <Icon name={'Lamp'} />
          </div>
          <div
            className={`${styles.manual_item} ${styles.label_middle} ${styles.gray}`}
          >
            После установки этого кода вы сможете видеть, сколько товаров из
            ленты рекомендаций было добавлено в корзину.
          </div>
        </div>
      </NumberedBlock>

      <div
        className={`${styles.label_big} ${styles.black} ${styles.margin_bottom} ${styles.margin_top} ${styles.label_padding}`}
      >
        2. Скопируйте и вставьте этот код на страницу успешного оформления
        заказа
      </div>
      <div
        className={`${styles.label_middle} ${styles.black} ${styles.margin_bottom} ${styles.label_padding}`}
      >
        Это та страница, на которой пользователю сообщают, что заказ сформирован
        или благодарят за покупку.
      </div>
      <div className={styles.margin_bottom}>
        <ToCopyArea
          text={
            '<script type="text/javascript">' +
            '(window["sberLeadApiQueue"] = window["sberLeadApiQueue"] || []).push(' +
            '{' +
            'method: "addToPayment", ' +
            'transactionId: <transaction_id>, ' +
            'items: [' +
            '{ productId: <product_id>, quantity: <quantity>, price: <price> },' +
            '{ productId: <product_id>, quantity: <quantity>, price: <price> },' +
            ']' +
            '}' +
            ');' +
            '</script>'
          }
          height={'100px'}
          style={{
            color: '#00BD7E',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        />
      </div>
      <NumberedBlock className={styles.item_button}>
        <div className={styles.manual}>
          <div className={styles.manual_item}>
            <Icon name={'Lamp'} />
          </div>
          <div
            className={`${styles.manual_item} ${styles.label_middle} ${styles.gray}`}
          >
            После установки этого кода вы сможете видеть сумму и количество
            купленных товаров из ленты рекомендаций.
          </div>
        </div>
      </NumberedBlock>

      <img src="/assets/feed/feed-statistic.jpg" className={styles.image} style={{ marginTop: 40 }} />

      <div className={styles.button}>
        <Button
          tag={'button'}
          to={'/assets/doc/instructions_for_setting_events.pdf'}
          target={'_blank'}
        >
          Скачать полную инструкцию .pdf
        </Button>
      </div>

      <HelpBlock mail={helpEmail} />
    </div>
  );
};

export default StepManual;
