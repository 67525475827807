import { action, observable } from 'mobx';
import Settings from '~/api/settings';
import Model from '~/models/Model';
import { getError } from '~/utils/getError';

export default class DocumentsModel extends Model<Documents> {
  @observable isFetching = false;
  @observable error: string | Element;

  @action
  fetch = async () => {
    this.isFetching = true;

    try {
      const result = await Settings.getDocuments();
      const documents = result.data.payload;
      this.update({ documents });
    } catch (e) {
      this.error = getError({ error: e, defaultError: 'Ошибка получения данных' });

    } finally {
      this.isFetching = false;
    }
  }

}
