import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';

interface IApproveEmailButton {
  disabled: boolean;
  isEmailNew: boolean;
  authorizationEmailObj: {
    address: string;
    registered: boolean;
  };
  checkEmail: (email: string) => void;
  approveEmail: (email: string) => Promise<boolean>;
}

const ApproveEmailButton = observer(
  ({
    disabled,
    isEmailNew,
    authorizationEmailObj,
    checkEmail,
    approveEmail,
  }: IApproveEmailButton) => {
    let timer1: NodeJS.Timeout = null;
    let timer2: NodeJS.Timeout = null;
    const { registered, address } = authorizationEmailObj;

    const [isEmailSent, setIsEmailSent] = useState(false);
    const [timer, setTimer] = useState(59);
    const [checkTimer, setCheckTimer] = useState(0);

    const tick = (timer: number) => {
      if (timer > 1) {
        setTimer(timer - 1);
      } else {
        setTimer(60);
        setIsEmailSent(false);
      }
    };

    const check = (timer: number) => {
      if (registered) return;
      setCheckTimer(timer + 1);
      if (timer % 20 === 0 && isEmailSent) {
        checkEmail(address);
      }
    };

    useEffect(() => {
      if (!disabled) {
        timer1 = setTimeout(() => check(checkTimer), 1000);
      }
      if (isEmailSent) {
        timer2 = setTimeout(() => tick(timer), 1000);
      }

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }, [isEmailSent, timer, checkTimer]);

    const buttonDescription = isEmailSent
      ? `Отправить ссылку повторно через ${timer}`
      : isEmailNew
      ? 'Добавить и подтвердить Email'
      : 'Подтвердить новый Email';

    const handleApproveEmail = async () => {
      const status = await approveEmail(address);
      if (status) setIsEmailSent(true);
    };

    return registered ? null : (
      <Button
        onClick={handleApproveEmail}
        disabled={disabled || isEmailSent}
        block
      >
        {buttonDescription}
      </Button>
    );
  }
);

export default ApproveEmailButton;
