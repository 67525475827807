import { observer } from 'mobx-react';
import React from 'react';
import BusinessRuleCard from '~/screens/BusinessRulesScreen/components/BusinessRuleList/BusinessRuleCard';
import { BusinessRule } from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const BusinessRuleList: React.FC<{
  recommendationForTypeDict: Map<string, string>;
  recommendationOfWhatTypeDict: Map<string, string>;
  recommendationTypeDict: Map<string, string>;
  similarityTypeDict: Map<string, string>;
  businessRuleList: BusinessRule[];
  editBusinessRule: (ruleId: number) => void;
  editBusinessRuleName: (ruleId: number) => void;
  startProcessDeleteRule: (ruleId: number) => void;
}> = observer(({
  recommendationForTypeDict,
  recommendationOfWhatTypeDict,
  recommendationTypeDict,
  similarityTypeDict,
  businessRuleList,
  editBusinessRule,
  editBusinessRuleName,
  startProcessDeleteRule,
}) => {
  const getRecommendationForTypeDictDisplayText = (key: string): string => {
    return recommendationForTypeDict.get(key);
  };
  const getRecommendationOfWhatTypeDictDisplayText = (key: string): string => {
    return recommendationOfWhatTypeDict.get(key);
  };
  const getRecommendationTypeDictDisplayText = (key: string): string => {
    return recommendationTypeDict.get(key);
  };
  const getSimilarityTypeDictDisplayText = (key: string): string => {
    return similarityTypeDict.get(key);
  };

  return (
    <div className={styles.business_rule_list}>
      {businessRuleList.map((businessRule, index) => {
        return (
          <BusinessRuleCard
            key={index}
            businessRule={businessRule}
            getRecommendationForTypeDictDisplayText={
              getRecommendationForTypeDictDisplayText
            }
            getRecommendationOfWhatTypeDictDisplayText={
              getRecommendationOfWhatTypeDictDisplayText
            }
            getRecommendationTypeDictDisplayText={
              getRecommendationTypeDictDisplayText
            }
            getSimilarityTypeDictDisplayText={getSimilarityTypeDictDisplayText}
            editBusinessRule={editBusinessRule}
            editBusinessRuleName={editBusinessRuleName}
            startProcessDeleteRule={startProcessDeleteRule}
          />
        );
      })} 
    </div>
  );
});

export default BusinessRuleList;
