export const codeFragment1 = [
  [
    {
      color: 'var(--Grey-500)',
      text: `<?xml version="1.0" encoding="UTF-8"?>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<urlset`,
    },
    {
      color: 'var(--Green-500)',
      text: ` xmlns`,
    },
    {
      color: 'var(--Green-600)',
      text: `="http://www.sitemaps.org/schemas/sitemap/0.9"`,
    },
    {
      color: 'var(--Blue-600)',
      text: `>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `</urlset>`,
    },
  ],
];

export const codeFragment2 = [
  [
    {
      color: 'var(--Grey-500)',
      text: `<?xml version="1.0" encoding="UTF-8"?>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<urlset`,
    },
    {
      color: 'var(--Green-500)',
      text: ` xmlns`,
    },
    {
      color: 'var(--Green-600)',
      text: `="http://www.sitemaps.org/schemas/sitemap/0.9"`,
    },
    {
      color: 'var(--Blue-600)',
      text: `>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `</urlset>`,
    },
  ],
];

export const codeFragment3 = [
  [
    {
      color: 'var(--Grey-500)',
      text: `<?xml version="1.0" encoding="UTF-8"?>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<urlset`,
    },
    {
      color: 'var(--Green-500)',
      text: ` xmlns`,
    },
    {
      color: 'var(--Green-600)',
      text: `="http://www.sitemaps.org/schemas/sitemap/0.9"`,
    },
    {
      color: 'var(--Blue-600)',
      text: `>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<url>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<loc>`,
    },
    {
      color: 'var(--Grey-600)',
      text: `https://sberlead.ru`,
    },
    {
      color: 'var(--Blue-600)',
      text: `</loc>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<lastmod>`,
    },
    {
      color: 'var(--Grey-600)',
      text: `2023-06-30T11:37:51+00:00`,
    },
    {
      color: 'var(--Blue-600)',
      text: `</lastmod>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `</url>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<url>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<loc>`,
    },
    {
      color: 'var(--Grey-600)',
      text: `https://sberlead.ru/vidgets`,
    },
    {
      color: 'var(--Blue-600)',
      text: `</loc>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `<lastmod>`,
    },
    {
      color: 'var(--Grey-600)',
      text: `2023-06-30T11:37:51+00:00`,
    },
    {
      color: 'var(--Blue-600)',
      text: `</lastmod>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `</url>`,
    },
  ],
  [
    {
      color: 'var(--Blue-600)',
      text: `</urlset>`,
    },
  ],
];

export const switchButtonsInstructions = [
  {
    key: 'CMS',
    name: 'Конструкторы сайтов и CSM',
  },
  {
    key: 'creating',
    name: 'Создание и добавление sitemap в корень сайта',
  },
];

export const switchButtonsCreatingInstructions = [
  {
    key: 'generation',
    name: 'Онлайн генератор карты сайта',
  },
  {
    key: 'manually',
    name: 'Создание файла вручную',
  },
];

