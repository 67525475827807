import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FontSettings from '~/components/notification-editor/FontSettings';

const HandPriceParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({
  isActive,
  configuration,
  onChange,
  onChangeCssParam,
}) => {
  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <>
        <section className="feed-editor-appearance__section">
          <FontSettings
            type="title"
            title="Шрифт текущей цены"
            overlayClassName="feed-editor-appearance__tooltip"
            className="feed-editor-appearance__font"
            data={{
              fontFamily: configuration.cardPriceFontFamily,
              color: configuration.cardPriceColor,
              alpha: configuration.cardPriceOpacity,
              fontWeight: configuration.cardPriceFontWeight,
              fontSize: configuration.cardPriceFontSize,
              isItalic: configuration.cardPriceFontItalic,
              isLineThrough: configuration.cardPriceTextLineThrough,
              isUnderline: configuration.cardPriceTextUnderline,
            }}
            onChange={data =>
              onChange(
                {
                  ...configuration,
                  cardPriceFontFamily: data.fontFamily,
                  cardPriceColor: data.color,
                  cardPriceOpacity: data.alpha,
                  cardPriceFontWeight: data.fontWeight,
                  cardPriceFontSize: data.fontSize as TFontSize,
                  cardPriceFontItalic: data.isItalic,
                  cardPriceTextLineThrough: data.isLineThrough,
                  cardPriceTextUnderline: data.isUnderline,
                },
                true
              )
            }
          />
        </section>
        {configuration.hasCardCrossedPrice && (
          <>
            <section className="feed-editor-appearance__section">
              <FontSettings
                type="title"
                title="Шрифт старой цены"
                overlayClassName="feed-editor-appearance__tooltip"
                className="feed-editor-appearance__font"
                data={{
                  fontFamily: configuration.cardCrossedPriceFontFamily,
                  color: configuration.cardCrossedPriceColor,
                  alpha: configuration.cardCrossedPriceOpacity,
                  fontWeight: configuration.cardCrossedPriceFontWeight,
                  fontSize: configuration.cardCrossedPriceFontSize as TFontSize,
                  isItalic: configuration.cardCrossedPriceFontItalic,
                  isLineThrough: configuration.cardCrossedPriceTextLineThrough,
                  isUnderline: configuration.cardCrossedPriceTextUnderline,
                }}
                onChange={data =>
                  onChange(
                    {
                      ...configuration,
                      cardCrossedPriceFontFamily: data.fontFamily,
                      cardCrossedPriceColor: data.color,
                      cardCrossedPriceOpacity: data.alpha,
                      cardCrossedPriceFontWeight: data.fontWeight,
                      cardCrossedPriceFontSize: data.fontSize,
                      cardCrossedPriceFontItalic: data.isItalic,
                      cardCrossedPriceTextLineThrough: data.isLineThrough,
                      cardCrossedPriceTextUnderline: data.isUnderline,
                    },
                    true
                  )
                }
              />
            </section>
          </>
        )}
      </>
    </ParamHand>
  );
};

export default HandPriceParams;
