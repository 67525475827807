import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

type TSendSayAccountsResponse = IResponse<ISendSayAccount[]>;

const SendSayAccountElementsApi = {
  getSendSayAccounts: (): AxiosPromise<TSendSayAccountsResponse> =>
    actionGet('/admin/sendSayAccount', { action: 'getAll' }),

  saveSendSayAccount: (apiLogin: string, apiKey: string): AxiosPromise<TSendSayAccountsResponse> =>
    actionSet('/admin/sendSayAccount', { apiLogin, apiKey }, null),
};

export default SendSayAccountElementsApi;
