import { Alert, Layout } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import './style.css';

const LoadingErrorScreen: React.FC = observer(() => {
  const { authStore } = useStores() as { authStore: AuthStore };

  return (
    <Layout className="loading-error-screen">
      <Alert
        message="Ошибка загрузки сервиса"
        type="error"
        showIcon
        style={{ marginBottom: 24 }}
      />
      <Button
        size="large"
        loading={authStore.isLoggingOut}
        onClick={authStore.logout}
      >
        Logout
      </Button>
    </Layout>
  );
});

export default LoadingErrorScreen;
