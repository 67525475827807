import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '~/components/Icon';
import { Wheel } from '~/components/generic/Wheel';
import Button from '~/components/ui/Button';
import { sectorsExample, wheelPattern } from '~/screens/WheelOfFortune/data';
import { WheelOfFortuneStore } from '~/screens/WheelOfFortune/stores/wheelOfFortuneStore';
import useStores from '~/utils/useStores';
import style from './style.module.css';

const TOTAL_SLIDES = wheelPattern.length - 1;

export const Pattern: React.FC = observer(() => {
  const { wheelOfFortuneStore } = useStores() as {
    wheelOfFortuneStore: WheelOfFortuneStore;
  };

  const selectedTemplate  = wheelOfFortuneStore.selectedTemplate;

  const selectedWheel = selectedTemplate?.params;

  const extraHTMLConfiguration = selectedWheel?.extraHTMLConfiguration;

  const sectorUIParams = (extraHTMLConfiguration?.sectorUIParams as ISectorParams[])?.map(
    v => ({ ...v })
  );
  const arrowColor = extraHTMLConfiguration?.arrowColor;

  useEffect(() => {

    let currentIndex = 0;

    wheelPattern.map((v, key) => {

      const condition = (v.sectorUIParams[0].backgroundColor === sectorUIParams[0].backgroundColor) &&
        (v.sectorUIParams[1].backgroundColor === sectorUIParams[1].backgroundColor);

      if (condition) {
        currentIndex = key;
      }

    });

    currentIndex && setCurrent(currentIndex);

  }, [sectorUIParams[0].backgroundColor, sectorUIParams[1].backgroundColor, arrowColor]);

  const { setSectorUIParams, setTargetArrowColor } = wheelOfFortuneStore;
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);

  const next = () => {
    if (current >= TOTAL_SLIDES) return;
    else setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 0) return;
    else setCurrent(current - 1);
  };

  React.useEffect(() => {
    ref.current.style.transition = 'all 0.2s ease-in-out';
    ref.current.style.transform = `translateX(-${current}00%)`;
  }, [current]);

  const radius = 135;
  const start = false;
  const sectorNumber = 8;
  const winSectorNumber = 4;
  const isMobile = false;

  const onClick = () => {
    setSectorUIParams(wheelPattern[current]?.sectorUIParams);
    setTargetArrowColor(wheelPattern[current].arrowColor);
    wheelOfFortuneStore.stepsStore.changeCurrentStepNextOrBack(+1);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.wrapper_title}>
        Выберите оформление колеса фортуны
      </div>
      <div className={style.frame}>
        <div className={style.button_container}>
          {current !== 0 && (
            <div className={style.button_left} onClick={prev}>
              <Icon style={{ height: 30 }} name={'ArrowMLeft'} />
            </div>
          )}
          {current !== TOTAL_SLIDES && (
            <div className={style.button_right} onClick={next}>
              <Icon style={{ height: 30 }} name={'ArrowMRight'} />
            </div>
          )}
        </div>
        <div className={style.box_container} ref={ref}>
          {wheelPattern.map((pattern, index) => {
            const arrowColor = pattern.arrowColor;
            const wheelProps = {
              radius: current === index ? radius * 1.15 : radius,
              start,
              winSectorNumber,
              onAnimationEnd: () => console.log('animation End'),
            };
            const sectors = sectorsExample.map((sector, key) => {
              const n = Number(key + 1);
              const isEven = n === 0 || !!(n && !(n % 2));

              return {
                ...sector,
                backgroundColor: isEven
                  ? pattern.sectorUIParams[0].backgroundColor
                  : pattern.sectorUIParams[1].backgroundColor,
                size: radius * 2,
                degree: 360 / sectorNumber,
                textColor: isEven
                  ? pattern.sectorUIParams[0].textColor
                  : pattern.sectorUIParams[1].textColor,
              };
            });

            const handleClick = () => {
              setCurrent(index);
            };

            return (
              <div className={style.box} onClick={handleClick} key={index}>
                <Wheel
                  sectors={sectors || []}
                  animationProps={wheelProps}
                  isMobile={isMobile}
                  arrowColor={arrowColor}
                  radius={radius}
                  key={index}
                />
              </div>
            );
          })}
        </div>
        <div className={style.button_select_container}>
          <Button onClick={onClick} type="main" block style={{ height: 50 }}>
            Выбрать
          </Button>
        </div>
      </div>
    </div>
  );
});
