import React from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import { LABELS_LIMIT } from '~/screens/FeedLiteScreen/constants';
import './style.css';

interface ITitleLabelsBlockProps {
  currentLabelsLength: number;
}

const TitleLabelsBlock: React.FC<ITitleLabelsBlockProps> = ({
  currentLabelsLength,
}) => {
  return (
    <div className="add-product-labels-title__block">
      <div className="add-product-labels-title__wrapper">
        Теги
        <IconWithTooltip
          tooltipProps={{
            placement: 'bottomRight',
            title:
              'Ограничение связано с тем, что большее кол-во тегов будет перекрывать изображение товара',
          }}
          iconProps={{
            name: 'Question',
            style: { marginLeft: 6 },
          }}
        />
      </div>
      <div className="add-product-labels-title__score">
        {currentLabelsLength} / {LABELS_LIMIT}
      </div>
    </div>
  );
};

export default TitleLabelsBlock;
