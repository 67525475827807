export const sectorsExample = [
  { name: 'Подарочек 1', backgroundColor: '#1B1F27' },
  { name: 'Подарочек 2', backgroundColor: '#E1BD00' },
  { name: 'Подарочек 3', backgroundColor: '#1B1F27' },
  { name: 'Подарочек 4', backgroundColor: '#E1BD00' },
  { name: 'Подарочек 5', backgroundColor: '#1B1F27' },
  { name: 'Подарочек 6', backgroundColor: '#E1BD00' },
  { name: 'Подарочек 7', backgroundColor: '#1B1F27' },
  { name: 'Подарочек 8', backgroundColor: '#E1BD00' },
];

export const giftExample = [
  'Скидка N%',
  'N бонусов в подарок',
  'Скидка на второй товар',
  'Бесплатная доставка',
  'Скидка N% при регистрации',
  'Скидка на следующую покупку',
  'Дарим вам улыбку =)',
  'Комплимент при посещении магазина',
  'Подарочный сертификат',
  'N дней подписки в подарок',
];

export const wheelPattern = [
  {
    sectorUIParams: [
      {
        backgroundColor: '#B8DFFB',
        textColor: '#333333',
      },
      {
        backgroundColor: '#DFF0FF',
        textColor: '#333333',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#242C39',
        textColor: '#ffffff',
      },
      {
        backgroundColor: '#DFEBF5',
        textColor: '#333333',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#0057D9',
        textColor: '#fff',
      },
      {
        backgroundColor: '#F11154',
        textColor: '#fff',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#FBB8D8',
        textColor: '#333333',
      },
      {
        backgroundColor: '#E6DFFF',
        textColor: '#333333',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#02549F',
        textColor: '#fff',
      },
      {
        backgroundColor: '#1B1F27',
        textColor: '#fff',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#11C3DB',
        textColor: '#fff',
      },
      {
        backgroundColor: '#007989',
        textColor: '#fff',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#D0D3F1',
        textColor: '#333333',
      },
      {
        backgroundColor: '#D6F5F2',
        textColor: '#333333',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#8E0B5A',
        textColor: '#fff',
      },
      {
        backgroundColor: '#00337E',
        textColor: '#fff',
      },
    ],
    arrowColor: '#fff',
  },
  {
    sectorUIParams: [
      {
        backgroundColor: '#E21036',
        textColor: '#fff',
      },
      {
        backgroundColor: '#FCB500',
        textColor: '#fff',
      },
    ],
    arrowColor: '#fff',
  },
];

export interface IGetItems {
  currentStep: WheelEditorSteps;
  isEditing: boolean;
  hasAllGiftNameFilled: boolean;
  isStepGeneralViewValid: boolean;
}

export const getItems = ({
  currentStep,
  isEditing,
  hasAllGiftNameFilled,
  isStepGeneralViewValid,
}: IGetItems) => {
  return [
    {
      key: 'StepWheelPatterns',
      title: '1. Оформление',
      disabled: false,
    },
    {
      key: 'StepGifts',
      title: '2. Подарки',
      disabled: !isEditing && currentStep === 'StepWheelPatterns',
    },
    {
      key: 'StepGeneralView',
      title: '3. Общий вид',
      disabled: hasAllGiftNameFilled,
    },
    {
      key: 'StepChanceToWin',
      title: '4. Шанс выигрыша',
      disabled:
        !isStepGeneralViewValid ||
        hasAllGiftNameFilled ||
        (!['StepGeneralView', 'StepChanceToWin'].includes(currentStep) &&
          !isEditing),
    },
  ];
};
