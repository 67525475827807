import { IFeedConfiguration } from 'feed';
import React from 'react';
import DeviceSelectorRadioButton from '~/components/generic/DeviceSelectorRadioButton';
import NumberedBlock from '~/components/generic/NumberedBlock';
import FeedEditorAppearance from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance';
import СhoiceInfo from '~/components/generic/StepSelectorAndDesign/Sidebar/СhoiceInfo';
import SuccessfulChoice from '~/components/generic/StepSelectorAndDesign/SuccessfulChoice';
import optionsForDeviceSelector from '~/components/generic/StepSelectorAndDesign/optionsForDeviceSelector';
import './style.css';

interface ISidebarProps {
  currentStep: EditorSteps;
  feedConfiguration: IFeedConfiguration;
  device: Device;
  setDevice: (device: Device) => void;
  isIFrameAccessible: boolean;
  selector: string;
  rechoosing: () => void;
  onValueChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  handleUpdate: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  feedMode: FeedMode;
  activeMenu: StepDesignMenuElements;
  setActiveMenu: (activeMenu: StepDesignMenuElements) => void;
}

const Sidebar: React.FC<ISidebarProps> = ({
  currentStep,
  feedConfiguration,
  device,
  setDevice,
  isIFrameAccessible,
  selector,
  rechoosing,
  onValueChange,
  handleUpdate,
  feedMode,
  activeMenu,
  setActiveMenu,
}) => {
  return (
    <div className="feed-editor-step-selector-device-sidebar__container">
      {currentStep === 'StepSelector' && !selector && (
        <>
          <div className="feed-editor-step-selector-device-sidebar-choice__block">
            <NumberedBlock number={1}>
              <DeviceSelectorRadioButton
                title={(
                  <>
                    Где вы хотите показывать
                    <br />
                    ленту рекомендаций?
                  </>
                )}
                currentDevice={device}
                onChange={setDevice}
                listOptions={optionsForDeviceSelector(device)}
              />
            </NumberedBlock>
            {isIFrameAccessible && (
              <NumberedBlock number={2}>
                <СhoiceInfo device={device} />
              </NumberedBlock>
            )}
          </div>
        </>
      )}
      {currentStep === 'StepSelector' && selector && (
        <div className="feed-editor-step-selector-device-sidebar-choice__successful">
          <SuccessfulChoice rechoosing={rechoosing} />
        </div>
      )}
      {currentStep === 'StepDesign' && selector && (
        <FeedEditorAppearance
          configuration={feedConfiguration}
          onChange={onValueChange}
          onBlur={handleUpdate}
          device={device}
          feedMode={feedMode}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  );
};

export default Sidebar;
