import { observer } from 'mobx-react';
import React from 'react';
import { hasAccess } from '~/components/SwitchUI/utils';
import Button from '~/components/ui/Button';
import { multipleRunDisabledError } from '~/screens/MailingScreen/consts';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import useStores from '~/utils/useStores';

export const GetAside: React.FC = observer(() => {
  const { mailingStore, uiSwitchStore } = useStores() as {
    mailingStore: MailingStore;
    uiSwitchStore: UiSwitchStore;
  };
  const {
    mailingInstance,
    mailingStatistics,
    setErrorMessage,
    setIsErrorMessageShown,
    save,
    selectedId,
    getLastId,
    start,
    constructorStore,
  } = mailingStore;
  const authorizationEmailObj = mailingInstance.get('authorizationEmail');
  const thisId = mailingInstance.get('id');
  const checkOnStartList = mailingStatistics?.filter(
    v => v.status === 'STARTED' && thisId !== v?.template?.id
  );
  const checkOnStart = checkOnStartList?.length;
  const codesToHide = uiSwitchStore.getCodesToHide();
  const isNotMultipleCreatingAllowed = hasAccess({
    codesToHide,
    elementCode: 'UnlimitedStartEmail',
  });
  const saveAndRunButtonDisabled = !authorizationEmailObj?.registered;
  const isMultipleRunDisabledPopup =
    checkOnStart > 0 && isNotMultipleCreatingAllowed;

  const saveAndRun = async () => {
    if (isMultipleRunDisabledPopup) {
      setErrorMessage(multipleRunDisabledError);
      setIsErrorMessageShown(true);

      return;
    }
    const saveResult = await save();
    !saveResult && setIsErrorMessageShown(true);
    if (saveResult) {
      const id = selectedId || getLastId();
      const result = start(id);
      if (!result) {
        setIsErrorMessageShown(true);

        return;
      }
      constructorStore.setIsConstructorOpen(false);
      mailingInstance.clearDataToSend();
    }
  };

  return (
    <>
      {constructorStore.currentStep === 'TabConstructor' && (
        <Button
          disabled={saveAndRunButtonDisabled}
          onClick={saveAndRun}
        >
          Сохранить и запустить
        </Button>
      )}
    </>
  );
});
