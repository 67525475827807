import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '~/components/Icon';
import Caption from '~/components/generic/Caption';
import FeedStatisticTable from '~/components/generic/FeedStatisticTable';
import ScriptIsNotWorkingMessage from '~/components/generic/ScriptIsNotWorkingMessage';
import Button from '~/components/ui/Button';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import VideoMessage from '~/components/ui/VideoMessage';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import LoadingScreen from '~/screens/LoadingScreen';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const ListCreatedFeed: React.FC = observer(() => {
  const { feedLiteStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
  };
  const {
    setIsConstructorLiteOpen,
    reset,
    feedAmount,
    feedLimit,
    hasPermissionToCreateFeed,
    setIsShowExceedingLimitMessage,
    feedLiteStatistics,
    onClickActionButton,
  } = feedLiteStore;
  const { authStore } = useStores() as { authStore: AuthStore };
  const [isVisibleScriptIsNotWorkingMessage, setIsVisibleScriptIsNotWorkingMessage] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>();
  const [heightTableBlock, setHeightTableBlock] = useState<number>(0);
  const [wightTableBlock, setWightTableBlock] = useState<number>(0);
  const [isStatisticLeadVideoShown, setIsStatisticLeadVideoShown] = useState<boolean>(false);

  useEffect(() => {
    setHeightTableBlock(tableRef?.current.getBoundingClientRect().height);
    setWightTableBlock(tableRef?.current.offsetWidth);
  }, []);

  const openFeedConstructor = () => {
    reset();
    setIsConstructorLiteOpen(true);
  };

  const onClickActionButtonWrapper = (
    actionType: FeedActionButtonType,
    feedStatistics: FeedLiteStatistics
  ) => {
    if (actionType === 'start') {
      if (authStore?.clientState !== 'READY') {
        setIsVisibleScriptIsNotWorkingMessage(true);

        return;
      }
    }
    onClickActionButton(actionType, feedStatistics);
  };

  return (
    <Screen
      header={'Ленты рекомендаций Lite'}
      footer={(
        <div className="feed-screen-footer__wrapper">
          <DrawerFooter
            className="feed-screen__footer"
            actions={(
              <Button
                icon="Plus"
                onClick={() => {
                  hasPermissionToCreateFeed
                    ? openFeedConstructor()
                    : setIsShowExceedingLimitMessage(true);
                }}
              >
                Создать ленту
              </Button>
            )}
            aside={(
              <div className="feed-screen__footer-aside">
                <div className="feed-screen__footer-aside-element">
                  Созданных лент: {feedAmount}/{feedLimit}
                </div>
              </div>
            )}
          />
        </div>
      )}
      noPadding
    >

      <div className="scenarios-screen-widget-button">
        <Button
          type="black-outline"
          className="scenario-editor-configure__button"
          onClick={() => setIsStatisticLeadVideoShown(true)}
        >
          <div className="scenario-editor-configure__button-body">
            <div><Icon name="PlayS" className="scenario-editor-configure__button-icon" /></div>
            <span>Сбор статистики ленты рекомендаций</span>
          </div>
        </Button>
        {isStatisticLeadVideoShown && (
          <VideoMessage
            visible={isStatisticLeadVideoShown}
            title={'Эффективность ленты рекомендаций'}
            onClose={() => setIsStatisticLeadVideoShown(false)}
            onExit={() => setIsStatisticLeadVideoShown(false)}
            videoSrc="https://www.youtube.com/embed/yWoNzstBM_o"
          />
        )}
      </div>

      <Caption
        className="feed-pro-screen-table__caption"
        backgroundColor={'grey'}
      >
        <div className={styles.information}>
          <div className={styles.icon}>
            <Icon name={'ChartLine'} />
          </div>
          <div className={styles.text}>
            Расчет статистики ведется с момента первого запуска ленты по
            настоящее время
          </div>
        </div>
      </Caption>

      <div ref={tableRef} className={styles.table_wrapper}>
        {heightTableBlock !== 0 ? (
          <FeedStatisticTable
            heightTableBlock={heightTableBlock}
            wightTableBlock={wightTableBlock}
            statistics={feedLiteStatistics}
            onClickActionButton={onClickActionButtonWrapper}
            isFetchingRequest={false}
            excludedСolumns={['ymlCatalogStatus']}
          />
        ) : (
          <LoadingScreen />
        )}
      </div>
      <ScriptIsNotWorkingMessage
        isVisible={isVisibleScriptIsNotWorkingMessage}
        setIsVisible={setIsVisibleScriptIsNotWorkingMessage}
      />
    </Screen>
  );
});

export default ListCreatedFeed;
