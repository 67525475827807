/**
 * Хук позволяет удобно управлять данными в SessionStorage
 * 
 * const sessionStorge = useSessionStorageController('имя хранилища');
 * sessionStorge.key - имя хранилища
 * sessionStorge.has() - проверяет наличие хранилища
 * sessionStorge.add() - принимает данные на хранение
 * sessionStorge.get() - возвращает данные из хранилища
 * 
 * const {key, has, add, get} = useSessionStorageController('имя хранилища');
 * 
 * @param key 
 * @returns 
 */
export const useSessionStorageController = (key: string): {
  key: string;
  has: () => boolean;
  add: (value: any) => void;
  get: () => any;
} => {

  return {
    key,
    has: () => !!window.sessionStorage.getItem(key),
    add: (value: any) => window.sessionStorage.setItem(key, JSON.stringify(value)),
    get: () => JSON.parse(window.sessionStorage.getItem(key)),
  };
};