import { IFeedConfiguration } from 'feed';
import { fontWeightValues, getTextDecoration } from '~/components/Notification';
import { hexToRgb } from '~/utils/hexUtils';

export const currentButtonCssParam = (configuration: IFeedConfiguration): string => {
  const {
    hasButtonBorder,
    buttonBorderWidth,
    buttonBorderColor,
    buttonBorderOpacity,
    buttonColor,
    buttonOpacity,
    buttonTextColor,
    buttonTextOpacity,
    buttonFontFamily,
    buttonFontWeight,
    buttonFontSize,
    buttonFontItalic,
    buttonTextUnderline,
    buttonTextLineThrough,
  } = configuration;
  
  return `
    border: ${
      hasButtonBorder
        ? `${buttonBorderWidth}px solid ${hexToRgb(
            buttonBorderColor,
            buttonBorderOpacity
          )}`
        : `none`
    };
    background-color: ${hexToRgb(
      buttonColor,
      buttonOpacity
    )};
    color: ${hexToRgb(
      buttonTextColor,
      buttonTextOpacity
    )};
    font-family: ${buttonFontFamily};
    font-weight: ${fontWeightValues[buttonFontWeight]};
    font-size: ${buttonFontSize}px;
    font-style: ${buttonFontItalic ? `italic` : `normal`};
    text-decoration: ${getTextDecoration(
      buttonTextUnderline,
      buttonTextLineThrough
    )};
    border-radius: 4px;`
    .replace(/(\r\n|\n|\r|↵)/gm, '')
    .trim();
};