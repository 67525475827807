import { action, computed, observable } from 'mobx';
import moment from 'moment';
import Image from '~/api/image';
import MassMailingApi from '~/screens/MassMailingScreen/api/massMailingApi';
import MassMailingModel from '~/screens/MassMailingScreen/models/MassMailingModel';
import {
  initialMailInstance,
  nameToConfiguration,
} from '~/screens/MassMailingScreen/stores/initial';
import { MailingGeneralStore } from '~/stores/mailingGeneralStore';
import { getError } from '~/utils/getError';

export class MassMailingStore extends MailingGeneralStore<TMassMailingConstructorSteps> {
  @observable mailingStatistics: IMassMailingTemplateWithStatistic[] = [];
  @observable mailingInstance: MassMailingModel;

  constructor() {
    super([
      'TabParameters',
      'TabConstructor',
    ]);
  }

  @action
  getMailingInstanceById = (
    id: number
  ): IMassMailingTemplateWithStatistic => {
    return (
      this.mailingStatistics.length > 0 &&
      this.mailingStatistics.filter(v => v?.template?.id === id)[0]
    );
  }

  @action
  createMassMailing = () => {
    this.constructorStore.changeCurrentStep('TabParameters');
    this.mailingInstance = new MassMailingModel(this, {
      ...initialMailInstance,
      name: `Рассылка от ${moment().format('DD.MM.YYYY HH:mm').toString()}`,
    });
    this.constructorStore.setIsConstructorOpen(true);
  }

  @action
  editMassMailing = (id: number) => {
    this.constructorStore.changeCurrentStep('TabParameters');
    const selectedMailInstance = this.mailingStatistics.filter(
      (v: IMassMailingTemplateWithStatistic) => v.template.id === id
    )[0];

    const configuration = (
      selectedMailInstance.template?.emailTemplate?.data?.configuration || []
    ).map((v: IMassMailConfiguration) => ({
      ...v,
      configuration: nameToConfiguration(v.name),
    }));

    const selectedItem: IMassMailingModel = {
      id: selectedMailInstance.template?.id,
      name: selectedMailInstance.template?.name,
      type: 'EmailDistributionTemplate' as 'EmailDistributionTemplate',
      authorizationEmail: {
        address: selectedMailInstance.template?.senderEmailAddress?.address,
        registered:
          selectedMailInstance.template?.senderEmailAddress?.registered,
        emailTopic: selectedMailInstance.template?.emailTrigger?.emailTopic,
        senderName: selectedMailInstance.template?.senderName,
        startSendDateTime: selectedMailInstance.template?.emailTrigger?.startSendDateTime,
      },
      configuration,
      imageCodes: selectedMailInstance.imageCodes.map((v: any) =>
        typeof v === 'string' ? v : v.code
      ),
    };
    this.mailingInstance = new MassMailingModel(this, selectedItem);
    this.constructorStore.setIsConstructorOpen(true);
  }

  @action
  copyMassMailing = (id: number) => {
    this.editMassMailing(id);
    this.mailingInstance.update({ id: null });
    this.mailingInstance.update({
      name: `Рассылка от ${moment().format('DD.MM.YYYY HH:mm').toString()}`,
    });
    this.mailingInstance.update({authorizationEmail: {
      ...this.mailingInstance.get('authorizationEmail'),
      startSendDateTime: moment().add(15, 'm').toISOString(true),
    }});
  }

  @action
  sendTestLetter = async (email: string) => {
    this.setErrorMessage(this.errorMessage);
    try {
      await MassMailingApi.sendTestLetter(
        email,
        this.mailingInstance.get('id'),
        this.mailingInstance.getDataToSend()
      );

      return true;
    } catch (e) {
      const error = getError({ error: e, defaultError: 'Что-то пошло не так' });
      this.setErrorMessage({ title: 'Что-то пошло не так', body: error });

      return false;
    }
  }

  @action
  setMailingStatistics = (mailingStatistics: IMassMailingTemplateWithStatistic[] = []) => {
    this.mailingStatistics = mailingStatistics;
  }
  
  @action
  loadMailingList = async () => {
    const response = await MassMailingApi.getList();
    this.setMailingStatistics(response?.data?.payload);
  }

  @action
  fetchMailingList = async () => {
    try {
      this.setIsLoading(true);
      await this.loadMailingList();
    } catch (e) {
      this.setMailingStatistics([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  processEmailImage = async (imageBase64: string, errorCallback: Function) => {
    try {
      const result = await Image.set(imageBase64);
      const payload = result?.data?.payload;

      if (payload?.length > 0) {
        return payload[0];
      }

      errorCallback({
        isShownErrorDialogBox: true,
        titleErrorDialogBox: 'Ошибка загрузки изображения',
        textErrorDialogBox: 'Неизвестная ошибка',
      });

      return false;
    } catch (e) {
      const error = getError({ error: e });

      errorCallback({
        isShownErrorDialogBox: true,
        titleErrorDialogBox: 'Ошибка загрузки изображения',
        textErrorDialogBox: error,
      });

      return false;
    }
  }

  /**
   * Открывает конструктор на шаге подтверждения Email-а
   */
  @action
  openConstructorForApproveEmail = () => {
    this.setIsShowEmailNotRegisterMessage(false);
    this.editMassMailing(this.selectedId);
  }

  @action
  start = async (id: number) => {
    if (!this.mailingStatistics
      .find(mailing => mailing.template.id === id)
      .template.senderEmailAddress.registered
    ) {
      this.setSelectedId(id);
      this.setIsShowEmailNotRegisterMessage(true);

      return true;
    }
    try {
      await MassMailingApi.start(id);
      this.loadMailingList();

      return true;
    } catch (e) {
      const error = getError({ error: e, defaultError: 'Что-то пошло не так' });
      this.setErrorMessage({ title: 'Что-то пошло не так', body: error });

      return false;
    }
  }

  @action
  delete = async (id: string | number) => {
    try {
      await MassMailingApi.delete(id);
      this.loadMailingList();

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  stop = async (id: string | number) => {
    try {
      await MassMailingApi.stop(id);
      this.loadMailingList();

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  editTitle = async (title: string, id: string | number) => {
    try {
      await MassMailingApi.editTitle(title, id);
      this.loadMailingList();

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  @action
  save = async () => {
    try {
      this.constructorStore.setIsWaitingResponse(true);
      await MassMailingApi.save(this.mailingInstance.getDataToSend());
      this.loadMailingList();
      this.setErrorMessage(this.errorMessage);

      return true;
    } catch (error) {
      this.setErrorMessage({ title: getError({ error }), body: '' });

      return false;
    } finally {
      this.constructorStore.setIsWaitingResponse(false);
    }
  }

  @action
  reset = () => {
    this.constructorStore.isConstructorOpen = false;
  }

  @action
  closeConstructor = () => {
    this.constructorStore.setIsConstructorOpen(false);
    this.mailingInstance.clearDataToSend();
    this.constructorStore.changeCurrentStep('TabParameters');
  }

  @action
  saveMailing = async () => {
    this.setIsSavedMailing(true);
    const result = await this.save();
    this.setIsSavedMailing(false);
    if (result) {
      !this.selectedId && this.setIsMailingWasCreatedPopup(true);
      this.closeConstructor();

      return true;
    }
    !result && this.setIsErrorMessageShown(true);

    return false;
  }

  @action
  saveAndRun = async () => {
    await this.save();
    this.constructorStore.setIsWaitingResponse(true);
    await this.start(this.mailingInstance.get('id'));
    this.loadMailingList();
    this.constructorStore.setIsConstructorOpen(false);
    this.mailingInstance.clearDataToSend();
    this.constructorStore.setIsWaitingResponse(false);
  }

  @action
  isValidateEmail = (email: string = '') => {
    if (
      email.includes('@yandex.ru') ||
      email.includes('@gmail.com') ||
      email.includes('@mail.ru') ||
      !email.includes('@') ||
      !/.+@.+\..+/i.test(email)
    ) {
      return false;
    }
    
    return true;
  }

  @computed
  get authorizationEmailObj() {
    return this.mailingInstance?.get('authorizationEmail');
  }

  @computed
  get isSenderName() {
    return !!this.authorizationEmailObj.senderName.length;
  }

  @computed
  get isEmailTheme() {
    return !!this.authorizationEmailObj.emailTopic?.length;
  }

  @computed
  get isSenderAddress() {
    return !!this.authorizationEmailObj.address &&
      this.isValidateEmail(this.authorizationEmailObj.address);
  }
  
  @computed
  get isButtonNextDisabled() {
    return this.constructorStore.currentStep === 'TabParameters' &&
      (!this.isSenderName ||
        !this.isEmailTheme ||
        !this.isSenderAddress
      );
  }
}

export default new MassMailingStore();
