import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { TariffInfoCard } from '~/screens/Auth/AttendanceScreen/TariffInfo/TariffInfoCard';
import LoadingScreen from '~/screens/LoadingScreen';
import { ServiceStore } from '~/stores/serviceStore';
import { TariffStore } from '~/stores/tariffStore';
import useStores from '~/utils/useStores';
import './style.css';

export const TariffInfo = observer(() => {
  const { tariffStore, serviceStore } = useStores() as {
    tariffStore: TariffStore;
    serviceStore: ServiceStore;
  };

  const nextPaymentDate = `Бесплатный период до ${moment(
    tariffStore.freePeriod
  ).format('LL')}`;

  if (
    !serviceStore.isFetchCurrentServices &&
    !tariffStore.tariffOptions &&
    !tariffStore.errorMessage
  ) {
    return <LoadingScreen />;
  }

  return (
    <div className="first-step">
      <div className="wrapper">
        <div className="block">
          <span className="_header">{nextPaymentDate}</span>
          <TariffInfoCard
            currentServices={serviceStore.currentServices}
            tariffStore={tariffStore}
          />
        </div>
      </div>
    </div>
  );
});
