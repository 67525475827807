import { observer } from 'mobx-react';
import React from 'react';
import Input from '~/components/ui/Input';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import { getMaxLengthMessage } from '~/screens/MassMailingScreen/utils';
import styles from '../style.module.css';

const EmailTopic: React.FC = observer(() => {
  const { mailingInstance } = massMailingStore;
  const authorizationEmailObj = mailingInstance?.get('authorizationEmail');
  const emailTopic = authorizationEmailObj.emailTopic;

  const setEmailTopic = (emailTopic: string) => {
    mailingInstance.update({
      authorizationEmail: { ...authorizationEmailObj, emailTopic },
    });
  };

  const maxTextLength = 55;
  const isErrorShown = emailTopic?.length >= maxTextLength;

  return (
    <div className={styles.input_block}>
      <h3>Введите тему письма</h3>
      <Input
        placeholder="График работы службы доставки в праздничные дни"
        className={styles.input}
        value={emailTopic}
        onChangeValue={setEmailTopic}
        maxLength={maxTextLength}
        failure={isErrorShown}
      />
      {isErrorShown && (
        <div className={styles.error}>
          {getMaxLengthMessage(maxTextLength)}
        </div>
      )}
    </div>
  );
});

export default EmailTopic;
