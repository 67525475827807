import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const PlugBitrix = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;
  const [bitrixUrl, setBitrixUrl] = useState<string>();
  const bitrixError = leadSettings.get('bitrixUrlError');

  useEffect(() => {
    leadSettings.update({ bitrixUrlError: '' });
  }, []);

  const onChangeValue = (bitrixUrl: string) => {
    setBitrixUrl(bitrixUrl);
    leadSettings.update({ bitrixUrlError: '' });
  };

  const enableBitrix = async () => {
    await leadSettings.enableBitrix(bitrixUrl);
  };

  return (
    <SettingsItem
      title={
        'Скопируйте полученный образец URL, вставьте его в это поле и нажмите «Подключить»'
      }
      number={2}
      Content={(
        <>
          <Input
            className={'leads-settings__item-input'}
            onChangeValue={onChangeValue}
            failure={!!bitrixError}
            placeholder={'https://example.bitrix24.ru/rest/1/mS5iadOm3yd16dgrg/'}
          />
          {<div className={'leads-settings__item-error'}>{bitrixError}</div>}
          <Button
            className="leads-settings__item-button"
            onClick={enableBitrix}
          >
            Подключить
          </Button>
        </>
      )}
    />
  );
});
