import { action, computed, observable } from 'mobx';
import { Organization } from '~/api';
import Model from '~/models/Model';

export default class OrgModel extends Model<IOrganization> {
  @observable isFetching = false;

  @action
  fetch = async () => {
    this.isFetching = true;

    try {
      const response = await Organization.getOrganization(this.get('id'));
      this.update(response.data.payload[0]);
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @computed
  get domain() {
    if (!this.get('domains')) return '';
    const domain = this.get('domains');

    return domain;
  }

  @computed
  get tarplan() {
    if (!this.get('tarplans')) return '';
    const tarplan = this.get('tarplans');

    return tarplan;
  }

  @computed
  get hasNonPromoTarplan() {
    if (!this.get('tarplans')?.length) return '';

    return !!this.get('tarplans')?.filter(v => v.code !== 'PROMO').length;
  }

  @computed
  get orgName() {
    if (!this.get('name')) return '';

    return this.get('name');
  }

  @computed
  get key() {
    return this.get('id');
  }
}
