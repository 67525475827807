import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import { FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import { ActionButton } from './ActionButton';
import InfoBlock from './InfoBlock';
import { EFeedType } from './types';

import './style.css';

const items = [
  { key: 'CONTAINS', value: 'Ссылки содержат' },
  { key: 'NOT_CONTAINS', value: 'Ссылки не содержат' },
  { key: 'START_WITH', value: 'Ссылки начинаются с' },
];

const FeedInfo: React.FC<{
  isInfoDrawerOpen: boolean;
  setIsInfoDrawerOpen: (isOpen: boolean) => void;
  onClickActionButton: (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics | FeedApiStatistics | FeedStatistics
  ) => void;
  feedStatistic: FeedProStatistics | FeedApiStatistics | FeedStatistics;
  feedType: EFeedType;
}> = observer(
  ({
    isInfoDrawerOpen,
    setIsInfoDrawerOpen,
    feedStatistic,
    onClickActionButton,
    feedType,
  }) => {

    const { feedLiteStore, feedProStore, feedApiStore } = useStores() as {
      feedLiteStore: FeedLiteStore;
      feedProStore: FeedProStore;
      feedApiStore: FeedApiStore;
    };
    const { feed: feedLite, feedConfiguration, getProducts } = feedLiteStore;
    const { feed: feedPro, checkedCountPage } = feedProStore;
    const { feedApi } = feedApiStore;

    const feedProRecType = feedPro?.get('recType');
    const feedProYmlUrl = feedPro?.get('ymlUrl');
    const feedProSelector = feedPro?.get('selector');
    const feedProErrorDesc = feedPro?.get('errorDesc');
    const feedApiErrorDesc = feedApi?.get('errorDesc');
    const feedProSelectorMobile = feedPro?.get('selectorMobile');

    const getFeedName = (feedType: EFeedType): string => {
      switch (feedType) {
        case 'LITE': {
          return feedLite?.get('feedName');
        }
        case 'PRO': {
          return feedPro?.get('feedName');
        }
        case 'API': {
          return feedApi?.get('feedName');
        }
        default: {
          return 'Без названия';
        }
      }
    };

    let operation: string;
    let urlPattern: string;
    let feedApiYml: string;

    if (feedType === EFeedType.LITE) {
      if (feedLite?.get('displayConditions')?.operation !== 'EQUAL') {
        operation = `${
          items.find((item) => {
            return item.key === feedLite?.get('displayConditions')?.operation;
          })?.value
        }: ${feedLite?.get('displayConditions')?.url}`;
      } else {
        operation = 'На одной странице';
      }

      urlPattern = feedLite?.get('urlPattern');
    }

    if (feedType === EFeedType.PRO) {
      if (
        feedProRecType === 'POPULAR' ||
        feedProRecType === 'NEW_PRODUCTS' ||
        feedProRecType === 'CROSS_SELL'
      ) {
        if (checkedCountPage === 'ONE') {
          feedProRecType === 'CROSS_SELL'
            ? (operation = 'На странице корзины')
            : (operation = 'На одной любой странице сайта');
        } else {
          operation = 'На всех страницах товаров';
        }

        urlPattern = feedPro?.get('urlPattern');
      } else {
        operation = 'На любой странице сайта';
        urlPattern = feedPro?.get('urlPattern');
      }
    }

    if (feedType === EFeedType.API) {
      feedApiYml = feedApi?.get('ymlUrl');
    }

    return (
      <Drawer
        title={(
          <div className="drawer_title_wrapper">
            <div className="drawer_title">{getFeedName(feedType)}</div>
            <ActionButton
              feedStats={feedStatistic}
              onClickActionButton={onClickActionButton}
              setIsInfoDrawerOpen={setIsInfoDrawerOpen}
            />
          </div>
        )}
        visible={isInfoDrawerOpen}
        onClose={() => setIsInfoDrawerOpen(false)}
        size="large"
        className="drawer-feed"
      >
        <div className="drawer_feed_content">
          <InfoBlock
            operation={operation}
            urlPattern={urlPattern}
            feedProYmlUrl={feedProYmlUrl || feedApiYml}
            feedProSelector={feedProSelector}
            feedProSelectorMobile={feedProSelectorMobile}
            feedConfiguration={feedConfiguration}
            getProducts={getProducts}
            feedType={feedType}
            errorDesc={feedProErrorDesc || feedApiErrorDesc}
          />
          <DrawerFooter
            actions={(
              <>
                <Button
                  onClick={() => {
                    onClickActionButton('edit', feedStatistic);
                    setIsInfoDrawerOpen(false);
                  }}
                >
                  Редактировать
                </Button>
                <Button
                  type="grey"
                  onClick={() => {
                    onClickActionButton('copy', feedStatistic);
                    setIsInfoDrawerOpen(false);
                  }}
                >
                  Дублировать
                </Button>
              </>
          )}
          />
        </div>
      </Drawer>
    );
  }
);

export default FeedInfo;
