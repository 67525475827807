import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import Loading from '~/components/Loading';
import MailingPreview from '~/components/generic/MailingPreview';
import SendSayAccountElements from '~/components/generic/SendSayAccountElements';
import Message from '~/components/ui/Message';
import EmptyScreen from '~/screens/MailingScreen/components/EmptyScreen';
import { MailingConstructor } from '~/screens/MailingScreen/components/MailingConstructor';
import MailingList from '~/screens/MailingScreen/components/MailingList';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const MailingScreen = observer(() => {
  const { mailingStore, authStore } = useStores() as {
    mailingStore: MailingStore;
    authStore: AuthStore;
  };
  const {
    isPreviewShown,
    mailingStatistics,
    isLoading,
    isBeforeClosePopup,
    isMailingWasCreatedPopup,
    selectedId,
    isErrorMessageShown,
    setIsErrorMessageShown,
    fetchMailingList,
    setIsBeforeClosePopup,
    setSelectedId,
    setMailingInstance,
    errorMessage,
    setIsMailingWasCreatedPopup,
    constructorStore,
    mailingInstance,
    setIsPreviewShown,
    sendTestLetter,
  } = mailingStore;
  const {
    isConstructorOpen,
    setIsConstructorOpen,
  } = constructorStore;

  // Права для управления
  const hasRight = authStore.currentUser.hasRightTo('MODIFY_TRIGGER_MAILING');
  // Лоадер
  const showLoader = isLoading;
  // Статистика
  const showStatistics = mailingStatistics?.length > 0 && !showLoader;
  // Заглушка
  const showEmptyScreen = !showStatistics && !showLoader;

  useEffect(() => {
    fetchMailingList();
  }, []);

  const onClose = () => {
    setIsBeforeClosePopup(false);
  };

  const onApply = () => {
    setIsBeforeClosePopup(false);
    setIsConstructorOpen(false);
    constructorStore.changeCurrentStep('TabSelectType');
    setSelectedId(undefined);
    setMailingInstance();
  };

  const closePopupDescription = selectedId ? 'редактирование' : 'создание';

  return (
    <>
      {showLoader && <Loading status={'Loading'} onClick={() => null} />}
      {showEmptyScreen && <EmptyScreen hasRight={hasRight} />}
      {showStatistics && <MailingList hasRightToModify={hasRight} />}
      <MailingConstructor isConstructorOpen={isConstructorOpen} />
      <MailingPreview
        configuration={mailingInstance?.get('configuration')}
        isPreviewShown={isPreviewShown}
        setIsPreviewShown={setIsPreviewShown}
        email={mailingInstance?.get('authorizationEmail')?.address ?? ''}
        sendTestLetter={sendTestLetter}
        errorMessage={errorMessage.body}
        unsubscribeLinkHref={'/mockEmailLinkResult'}
      />
      <SendSayAccountElements />
      {isErrorMessageShown && (
        <Message
          visible
          applyText={'Хорошо'}
          onApply={() => setIsErrorMessageShown(false)}
          title={errorMessage.title}
          textTop={errorMessage.body as string}
          showIcon={false}
        />
      )}
      {isBeforeClosePopup && (
        <Message
          className={styles.errorMessage}
          visible
          onClose={onClose}
          onApply={onApply}
          onExit={onClose}
          applyText={`Прекратить ${closePopupDescription}`}
          cancelText={`Отмена`}
          title={`Прекратить ${closePopupDescription} рассылки?`}
          textTop="Настройки и данные не сохранятся"
          showIcon={false}
        />
      )}
      {isMailingWasCreatedPopup && (
        <Message
          visible
          showIcon={false}
          onApply={() => {
            setIsMailingWasCreatedPopup(false);
          }}
          applyText={'Хорошо'}
          title={(
            <div>
              <Icon name={'CheckmarkXxl'} className={styles.popupIcon} />
              Рассылка создана! Не забудьте её запустить
            </div>
            )}
        />
      )}
    </>
  );
});

export default MailingScreen;
