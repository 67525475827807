import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Message from '~/components/ui/Message';
import { EditDrawer } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/EditDrawer';
import { generateEmptyStep } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/utils';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import { NewElement } from './NewElement';
import { Step } from './Step';
import styles from './style.module.css';

export const ConstructQuiz = observer(() => {
  const [isMaxQuestionsAdded, setIsMaxQuestionsAdded] = useState(false);

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample, currentStep, setCurrentStep } = quizStore;

  if (!quizExample) return null;

  const isEditDrawerShown = !!currentStep?.toString();

  const onDrawerClose = () => setCurrentStep(undefined);

  const overlayRef = useRef(null);

  // TODO возможно получится оптимизировать - в useEffect внести
  const questionsKeys = quizExample
    ?.get('steps')
    ?.map((v, k) => {
      return { type: v.type, k };
    })
    .filter(v => v.type === 'question')
    .map((v, k) => {
      return v.k;
    });

  useEffect(() => { return; }, [quizExample?.get('configuration')]);

  const hasCover =
    quizExample?.get('steps').filter((v, k) => v.type === 'cover')?.length > 0;

  const hasQuestions =
    quizExample?.get('steps').filter((v, k) => v.type === 'question').length >
    0;

  const hasLead =
    quizExample?.get('steps').filter((v, k) => v.type === 'lead').length > 0;

  const hasFinal =
    quizExample?.get('steps').filter((v, k) => v.type === 'final').length > 0;

  const isDisabled = !hasCover;

  const addCover = () => {
    quizExample.update({
      steps: [generateEmptyStep('cover')],
    });
    setCurrentStep(0);
  };

  const addLead = () => {
    const steps = [...quizExample.get('steps')];

    const leadTemplate = generateEmptyStep('lead');
    steps.splice(lastQuestionKey + 1, 0, leadTemplate);

    quizExample.update({ steps });
  };

  const addFinal = () => {
    const steps = [...quizExample.get('steps')];
    steps.push(generateEmptyStep('final'));
    quizExample.update({
      steps,
    });
    setCurrentStep(steps.length - 1);
  };

  const lastQuestionKey = questionsKeys[questionsKeys?.length - 1] || 0;

  const cover = quizExample
    ?.get('steps')
    .map((v, k) => ({ step: v, key: k }))
    .filter(v => v.step.type === 'cover')[0];

  const questions = quizExample
    ?.get('steps')
    .map((v, k) => ({ step: v, key: k }))
    .filter(v => v.step.type === 'question');

  const leads = quizExample
    ?.get('steps')
    .map((v, k) => ({ step: v, key: k }))
    .filter(v => v.step.type === 'lead');

  const final = quizExample
    ?.get('steps')
    .map((v, k) => ({ step: v, key: k }))
    .filter(v => v.step.type === 'final');

  const [isQuestionsOpened, setIsQuestionOpened] = useState(true);

  const addQuestion = () => {
    if (questions.length >= 10) {
      setIsMaxQuestionsAdded(true);

      return;
    }
    const steps = [...quizExample.get('steps')];
    const questionEmptyTemplate = generateEmptyStep('question');
    steps.splice(lastQuestionKey + 1, 0, questionEmptyTemplate);
    quizStore.setCurrentStep(quizStore.currentStep + 1);

    quizExample.update({ steps });
  };

  return (
    <div
      className={cx(
        styles.constructorStep,
        isEditDrawerShown && styles.isEditDrawerShown
      )}
      ref={overlayRef}
    >
      {hasCover ? (
        <Step
          step={cover.key}
          template={quizExample}
          key={cover.key}
          setEditWindow={setCurrentStep}
          stepTitle={'Обложка'}
          isActive={cover.key === currentStep}
          className={styles.cover}
        />
      ) : (
        <div className={styles.emptyCover}>
          <NewElement onAdd={addCover} name="Добавить обложку" />
        </div>
      )}

      {hasQuestions ? (
        <div className={styles.questionsWrap}>
          <div className={styles.stepSectionName}>
            <div>Вопросы ({questions.length})</div>
            <div
              className={styles.switch}
              onClick={() => setIsQuestionOpened(!isQuestionsOpened)}
            >
              {isQuestionsOpened ? 'Свернуть' : 'Развернуть'}
            </div>
          </div>
          <div className={isQuestionsOpened ? '' : styles.preview}>
            {questions.map((v, k) => {
              return (
                <Step
                  step={v.key}
                  template={quizExample}
                  key={k}
                  setEditWindow={setCurrentStep}
                  isActive={v.key === currentStep}
                />
              );
            })}
            <div className={styles.stepCover}>
              <NewElement
                onAdd={addQuestion}
                name="Добавить вопросы"
                isDisabled={isDisabled}
              />
              {/* TODO реализовать библиотеку вопросов */}
              {/*<div*/}
              {/*  onClick={() => console.log('add questions from library')}*/}
              {/*  className={styles.addQuestionFromLibrary}*/}
              {/*>*/}
              {/*  Добавить из библиотеки вопросов*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cx(
            styles.emptyQuestionCover,
            isDisabled && styles.newElementDisabled
          )}
        >
          <div className={styles.stepSectionName}>Вопросы (0)</div>
          <NewElement
            onAdd={addQuestion}
            name="Добавить вопросы"
            isDisabled={isDisabled}
          />
          {/* TODO реализовать библиотеку вопросов */}
          {/*<div*/}
          {/*  onClick={() => console.log('add questions from library')}*/}
          {/*  className={styles.addQuestionFromLibrary}*/}
          {/*>*/}
          {/*  Добавить из библиотеки вопросов*/}
          {/*</div>*/}
        </div>
      )}

      {hasLead ? (
        leads.map((v, k) => {
          return (
            <Step
              step={v.key}
              template={quizExample}
              key={k}
              setEditWindow={setCurrentStep}
              stepTitle={'Форма сбора контактов'}
              isActive={v.key === currentStep}
              className={styles.cover}
            />
          );
        })
      ) : (
        <div
          className={cx(
            styles.stepCover,
            isDisabled && styles.newElementDisabled
          )}
        >
          <NewElement
            onAdd={addLead}
            name="Добавить форму обратной связи"
            isDisabled={isDisabled}
          />
        </div>
      )}

      {hasFinal ? (
        <>
          <div className={styles.stepSectionName}>Результаты</div>
          {final.map((v, k) => {
            return (
              <Step
                step={v.key}
                template={quizExample}
                key={k}
                setEditWindow={setCurrentStep}
                isActive={v.key === currentStep}
                className={styles.cover}
              />
            );
          })}
        </>
      ) : (
        <div
          className={cx(
            styles.stepCover,
            isDisabled && styles.newElementDisabled
          )}
        >
          <NewElement
            onAdd={addFinal}
            name="Добавить результаты"
            isDisabled={isDisabled}
          />
        </div>
      )}
      {isMaxQuestionsAdded && (
        <Message
          onClose={() => setIsMaxQuestionsAdded(false)}
          onExit={() => setIsMaxQuestionsAdded(false)}
          visible
          title={'Не более 10-ти вопросов'}
          textTop={'Нельзя создать больше десяти вопросов'}
        />
      )}
      {isEditDrawerShown && (
        <EditDrawer
          onClose={onDrawerClose}
          step={currentStep}
          setIsMaxQuestionsAdded={() => setIsMaxQuestionsAdded(true)}
        />
      )}
    </div>
  );
});
