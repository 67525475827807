import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import MassMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';

export const GetActions: React.FC = observer(() => {
  const {
    constructorStore,
    setIsPreviewShown,
    saveMailing,
    isButtonNextDisabled,
  } = MassMailingStore;
  const { currentStep, changeCurrentStepNextOrBack, isWaitingResponse } = constructorStore;

  return (
    <div className="btn-group">
      {currentStep !== 'TabParameters' && (
        <Button
          onClick={async () => changeCurrentStepNextOrBack(-1)}
          type="grey"
        >
          Назад
        </Button>
      )}
      {currentStep !== 'TabConstructor' && (
        <Button
          disabled={isButtonNextDisabled}
          onClick={async () => changeCurrentStepNextOrBack(1)}
        >
          Далее
        </Button>
      )}
      {currentStep === 'TabConstructor' && (
        <>
          <Button
            loading={isWaitingResponse}
            disabled={false}
            onClick={saveMailing}
          >
            Сохранить
          </Button>
          <Button
            type="grey"
            icon="Eye"
            onClick={() => setIsPreviewShown(true)}
          >
            Предпросмотр
          </Button>
        </>
      )}
    </div>
  );
});
