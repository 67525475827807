import React, { useState } from 'react';
import Icon from '~/components/Icon';
import './style.css';

type AccordionProps = {
  title: string;
  subTitle?: string;
  className?: string;
};

const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  subTitle = null,
  className = null,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openAccordion = () => {
    setIsOpen(true);
  };

  const closeAccordion = () => {
    setIsOpen(false);
  };

  return (
    <div className={`ui-accordion__block ${className ? className : ''}`}>
      <div
        className="ui-accordion__head"
        onClick={() => (isOpen ? closeAccordion() : openAccordion())}
      >
        <div className="ui-accordion-title__wrapper">
          <div className="ui-accordion__title">{title}</div>
          <div className="ui-accordion__subtitle">
            {subTitle !== null ? subTitle : ''}
          </div>
        </div>
        <div
          className="ui-accordion__controll"
          onClick={() => (isOpen ? closeAccordion() : openAccordion())}
        >
          <Icon name={isOpen ? 'ArrowSUp' : 'ArrowSDown'} />
        </div>
      </div>
      {isOpen && <div className="ui-accordion__body">{children}</div>}
    </div>
  );
};

export default Accordion;
