import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';

const optionsForDeviceSelector = (
  currentDevice: Device,
  tooltips: string[] = [
    'Предлагаем начать создание ленты рекомендаций для мобильных телефонов пользователей - на них приходится 80 процентов интернет-трафика российского рынка. Данная настройка подойдет в том числе для планшетов.',
    '',
  ]
): IListOptions[] => {
  return [
    {
      device: 'mobile',
      label: <>На мобильных телефонах</>,
      icon: (
        <Icon
          name="DevicePhone"
          className={cx('device-selector-radio-button__item-icon', {
            _active: currentDevice === 'mobile',
          })}
        />
      ),
      tooltip: tooltips ? tooltips[0] : null,
    },
    {
      device: 'desktop',
      label: <>На компьютерах</>,
      icon: (
        <Icon
          name="DeviceDesktop"
          className={cx('device-selector-radio-button__item-icon', {
            _active: currentDevice === 'desktop',
          })}
        />
      ),
      tooltip: tooltips ? tooltips[1] : null,
    },
  ];
};

export default optionsForDeviceSelector;
