import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from './client';

export type RolesListResponse = IResponse<IRole[]>;

export type RoleResponse = IResponse<[IRole]>;

const Roles = {
  list: (): AxiosPromise<RolesListResponse> =>
    actionGet('/admin/roles', {}),

  get: (id: number): AxiosPromise<RoleResponse> =>
    actionGet(`/admin/roles`, { id }),

  create: (role: IRole): AxiosPromise<RoleResponse> =>
    actionSet('/admin/roles', null, role),

  update: (id: number, role: IRole): AxiosPromise<RoleResponse> =>
    actionSet(`/admin/roles`, { id }, role),

  nameLookup: (name: string): AxiosPromise<RoleResponse> =>
    actionGet(`/admin/roles`, { name }),

  codeLookup: (code: string): AxiosPromise<RoleResponse> =>
    actionGet(`/admin/roles`, { code }),
};

export default Roles;
