import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import { SettingsStore } from '~/stores/settingsStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';
import './style.css';

export const GuideForHimselfSettings: React.FC = observer(() => {

  const { settingsStore } = useStores() as { settingsStore: SettingsStore };

  const handleClick = (index: number) => {
    settingsStore.setDrawerComponent(index);
    settingsStore.setToggleDrawer(true);
  };

  return (
    <div className="guide-for-him-self__container">
      <span className="guide-for-him-self__ways-header">Выберите, как вам удобно установить код</span>
      <div className="guide-for-him-self__block-ways">
        <Button
          onClick={() => handleClick(1)}
          className="guide-for-him-self__ways-btn"
          type="text"
          block
        >
          Конструкторы сайтов и CMS
        </Button>
        <Button
          onClick={() => handleClick(2)}
          className="guide-for-him-self__ways-btn"
          type="text"
          block
        >
          HTML
        </Button>
        <Button
          onClick={() => handleClick(3)}
          className="guide-for-him-self__ways-btn"
          type="text"
          block
        >
          Google Tag Manager
        </Button>
        <Button
          onClick={() => handleClick(4)}
          className="guide-for-him-self__ways-btn"
          type="text"
          block
        >
          Отправка инструкции специалисту
        </Button>
      </div>
      <div className="guide-for-him-self__btn-wrapper">
        <Button
          onClick={redirectToClickform}
          className="guide-for-him-self__btn-with-manager"
          type="text"
          block
        >
          Подключить с помощью менеджера
        </Button>
      </div>
    </div>
  );
});
