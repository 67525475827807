import React from 'react';
import formatLongNumbers from '~/utils/formatLongNumbers';
import pluralize from '~/utils/pluralize';

const convertStatusToString = (v: TMassMailingStatus) =>
  ({
    STARTED: 'Запущена',
    STOPPED: 'Остановлена',
    FINISHED: 'Завершена',
    WAITING: 'Ожидание',
  }[v]);

const convertStatusToLabelStatus = (v: TMassMailingStatus) =>
  ({
    STARTED: 'success',
    STOPPED: 'error',
    FINISHED: 'default',
    WAITING: 'warning',
  }[v]);

const convertEmailStatusToString = (v: EmailStatus) =>
  ({
    approved: 'Email: подтвержден',
    unapproved: 'Email: не подтвержден',
  }[v]);

const convertEmailStatusToLabelStatus = (v: EmailStatus) =>
  ({
    approved: 'default',
    unapproved: 'error',
  }[v]);

const getLabels = (
  v: IMassMailingTemplateWithStatistic,
  showMessageEmailNotRegisterMessage: (id: number) => void
) => {
  const labels = [];
  if (v.status) {
    labels.push({
      name: convertStatusToString(v.status as TMassMailingStatus),
      status: convertStatusToLabelStatus(v.status as TMassMailingStatus) as TFloatTableRowLabelStatus,
    });
  }

  if (v.template?.senderEmailAddress) {
    const emailLabel = v.template?.senderEmailAddress?.registered
      ? 'approved'
      : 'unapproved';

    labels.push({
      name: convertEmailStatusToString(emailLabel),
      status: convertEmailStatusToLabelStatus(
        emailLabel
      ) as TFloatTableRowLabelStatus,
      onClick: () => {
        if (emailLabel === 'unapproved' && v?.template?.id) {
          showMessageEmailNotRegisterMessage(v.template.id);
        }
      },
    });
  }

  return labels;
};

const getData = (v: IMassMailingTemplateWithStatistic) => {
  const data = [];

  const formatValue = (
    percent: number = 0,
    count: number = 0
  ): {
    subHeadData: string;
    headData: string;
  } => {
    return count === 0 || count === null
      ? {
        subHeadData: '',
        headData: '—',
      }
      : {
        subHeadData: percent === null ? '' : `${percent}%`,
        headData: formatLongNumbers(count),
      };
  };

  if (v.statistic?.sent) {
    data.push({
      icon: 'Telegram',
      label: 'Отправлено',
      labelNotification:
        'Количество писем, отправленных посетителям вашего сайта',
      ...formatValue(v.statistic.sent.percent, v.statistic.sent.count),
    });
  }

  if (v.statistic?.delivered) {
    data.push({
      icon: 'Check',
      label: 'Доставлено',
      labelNotification: (
        <>
          Количество писем, которые были доставлены посетителям вашего сайта{' '}
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(
        v.statistic?.delivered.percent,
        v.statistic?.delivered.count
      ),
      backgroundColor: '#F5F7FF',
    });
  }

  if (v.statistic?.read) {
    data.push({
      icon: 'CheckedDouble',
      label: 'Прочитано',
      labelNotification: (
        <>
          Количество писем, которые были прочитаны посетителями вашего сайта
          <br />
          Процент считается от доставленных писем
        </>
      ),
      ...formatValue(v.statistic?.read.percent, v.statistic?.read.count),
    });
  }

  if (v.statistic?.followedLink) {
    data.push({
      icon: 'MouseArrowWithLoader',
      label: 'Переходы',
      labelNotification: (
        <>
          Количество переходов, которые посетители вашего сайта производили по
          ссылкам из писем
          <br />
          Процент считается от прочитанных писем
        </>
      ),
      ...formatValue(
        v.statistic?.followedLink.percent,
        v.statistic?.followedLink.count
      ),
      backgroundColor: '#E4F5E5',
    });
  }

  if (v.statistic?.error) {
    data.push({
      icon: 'Alert',
      label: 'Ошибки',
      labelNotification: (
        <>
          Количество писем, по которым происходили ошибки в доставке писем
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(v.statistic?.error?.percent, v.statistic?.error?.count),
    });
  }

  if (v.statistic?.unsubscribed) {
    data.push({
      icon: 'Stop',
      label: 'Отписки',
      labelNotification: (
        <>
          Столько раз посетитель вашего сайта отписался от получения писем по
          ссылке из письма
          <br />
          Процент считается от отправленных писем
        </>
      ),
      ...formatValue(
        v.statistic?.unsubscribed?.percent,
        v.statistic?.unsubscribed?.count
      ),
      backgroundColor: '#F5F7FF',
    });
  }

  return data;
};

export const convertMailingStatisticToFloatTableData = (
  data: IMassMailingTemplateWithStatistic[],
  showMessageEmailNotRegisterMessage: (id: number) => void
): IFloatTableRow[] => {
  return data
    .map((v: IMassMailingTemplateWithStatistic) => {
      const labels = getLabels(v, showMessageEmailNotRegisterMessage);
      const data = getData(v);

      return {
        title: v?.template?.name || '',
        id: v?.template?.id,
        labels,
        data,
      };
    })
    .sort((a, b) => a.id - b.id);
};

export const getMaxLengthMessage = (maxLength: number): string =>
  `Не больше ${maxLength} ${pluralize(maxLength, 'символа', 'символов', 'символов')}`;
