import cx from 'classnames';
import RcColorPicker from 'rc-color-picker';
import React, { useState } from 'react';
import { CustomTooltip } from '~/components/generic/CustomTooltip';
import Message from '~/components/ui/Message';
import { MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET } from '~/constants';
import styles from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/style.module.css';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import { colorToView, getOpacity, hexToRgb } from '~/utils/hexUtils';
import useStores from '~/utils/useStores';

interface IColorChange {
  name?: string;
  fieldName: string;
  value: string;
  setConfiguration: (fieldName: string, value: string) => void;
}

export const ColorChanger = ({
  name,
  fieldName,
  value,
  setConfiguration,
}: IColorChange) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  const [errorMessage, setErrorMessage] = useState({
    isShown: false,
    title: '',
    text: '',
  });

  const isImage = fieldName === 'image';
  const isBonusColor = ['bonusColor', 'bonusTextColor'].includes(fieldName);
  const isColor = fieldName !== 'image';

  const style = isImage
    ? { backgroundImage: `url(${value})`, backgroundSize: 'cover' }
    : {};

  const onCloseError = () => {
    setErrorMessage({
      isShown: false,
      title: '',
      text: '',
    });
  };

  const onChangeImage = async (
    imageBase64: string,
    fileName: string
  ): Promise<void> => {
    const changeImageResult = await quizStore.processImage(
      imageBase64,
      setErrorMessage
    );

    // const imageCodes = changeImageResult ? [changeImageResult.code] : [];
    const imageUrl = changeImageResult ? changeImageResult?.url : '';

    // mailingStore.mailingInstance.update({ imageCodes });
    // @ts-ignore
    imageUrl && setConfiguration('image', changeImageResult || {}); // TODO typings
  };

  const onChangeFile = async () => {
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.setAttribute('accept', 'image/jpeg, image/png');
    inputFile.click();
    inputFile.onchange = (e: any) => {
      const file = e.currentTarget.files[0];

      if (file.size > MAX_UPLOAD_IMAGE_SIZE_IN_WIDGET) {
        setErrorMessage({
          isShown: true,
          title: 'Превышен максимальный размер логотипа  :(',
          text: 'Попробуйте выбрать размер меньше',
        });

        return;
      }
      const readerFile = new FileReader();
      readerFile.readAsDataURL(file);
      readerFile.onloadend = async () => {
        const regex = new RegExp('(.*?).(jpg|png|jpeg)$');
        if (!regex.test(file?.name?.toLowerCase())) {
          setErrorMessage({
            isShown: true,
            title: 'Тип не совпадает :(',
            text: 'Доступный формат: png, jpg, jpeg',
          });
        } else {
          await onChangeImage(readerFile.result.toString(), file.name);
        }
      };
    };
  };

  const [alpha, setAlpha] = useState(getOpacity(value));

  const onColorChange = (v: any) => {

    setAlpha(v.alpha);

    setConfiguration(fieldName, hexToRgb(v.color, v?.alpha || 0));
  };

  return (
    <>
      {errorMessage.isShown && (
        <Message
          visible
          title={'Ошибка'}
          textTop={errorMessage.title}
          textBottom={errorMessage.text}
          onExit={onCloseError}
          onClose={onCloseError}
        />
      )}
      <div
        className={cx(styles.colorChanger, isBonusColor && styles.isBonusColor)}
      >
        {isImage && (
          <CustomTooltip
            className={styles.tooltip}
            tooltipText={
              'Загружаемое изображение должно быть формата PNG, JPG или JPEG, весом не более 500Кб'
            }
          >
            <>
              <div
                className={styles.imageContainerExample}
                style={style}
                onClick={onChangeFile}
              />
              {name && <div className={styles.imageColorName}>{name}</div>}
            </>
          </CustomTooltip>
        )}
        {isColor && (
          <RcColorPicker
            className={styles.imageColorExample}
            color={colorToView(value)}
            alpha={alpha}
            style={{ zIndex: 1004 }}
            onChange={onColorChange}
            onBlur={onColorChange}
          />
        )}
        {name && isColor && <div className={styles.imageColorName}>{name}</div>}
      </div>
    </>
  );
};
