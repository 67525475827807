import { IAction } from '~/components/generic/Promo/Actions';
import { getActionButton } from '~/components/generic/Promo/utilities';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import { redirectToClickform } from '~/utils/redirectToClickform';
import useStores from '~/utils/useStores';

export const emptyScreenButtonsCreator = (
  hasRight: boolean
): IAction[] => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };
  const {
    createEmptyFeedApi,
  } = feedApiStore;

  return getActionButton(
    [hasRight, !hasRight],
    [
      {
        onClick: createEmptyFeedApi,
        icon: 'Plus',
        type: 'default',
        name: 'Создать рекомендательную систему с помощью API  ',
      }, {
        onClick: redirectToClickform,
        icon: 'Plus',
        type: 'default',
        name: 'Оставить заявку на подключение',
      },
    ]
  );
};