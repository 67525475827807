import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Input from '~/components/ui/Input';
import { AuthStore } from '~/stores/authStore';
import { SettingsStore } from '~/stores/settingsStore';
import { getError } from '~/utils/getError';
import useStores from '~/utils/useStores';
import { validateEmail } from '~/utils/validationUtils/validateEmail';
import Message from '../ui/Message';
import './style.css';

interface IWelcomePopUpProps {
  toggle: (props: boolean) => void;
}

const WelcomePopUp = observer((props: IWelcomePopUpProps) => {

  const { authStore } = useStores() as { authStore: AuthStore };
  const { settingsStore } = useStores() as { settingsStore: SettingsStore };
  const [errorEmail, setErrorEmail] = useState('');

  const validateInn = (inn: string) => {
    if (!inn.length) {
      return true;
    }

    return inn.length <= 12 && /^\+?\d+$/.test(inn);
  };

  const welcomeTitleImg = () => {
    return(
      <img
        src="assets/welcome/welcome-popup.jpg"
        alt="welcome"
        className="welcome_pop-up__img"
      />
    );
  };

  const welcomeTitleText = () => {
    return(
      <h2 className="welcome_pop-up__title">
        Приветствуем вас <br />
        в сервисе СберЛид
      </h2>
    );
  };

  const textBottom = () => (
    <p className="welcome_pop-up__text">
      Укажите свой email и мы сможем информировать вас
      о наиболее эффективных стратегиях настройки
      виджетов, специальных предложениях и новых
      инструментах
    </p>
    );

  const onApplyClick = async () => {

    if (!validateEmail(settingsStore.email)) {
      setErrorEmail('Неверно введен email');
    } else if (!validateInn(settingsStore.inn))  {
      setErrorEmail('ИНН должен быть числом не более 12 символов');
    } else {
      try {
        await settingsStore.sendEmail();
        settingsStore.toggleVisiblePopUp(false);
        !authStore.currentUser.isFintech && (props.toggle(true));
        setErrorEmail('');
      } catch (e) {
        const error = getError({ error: e, defaultError: 'Неверно введен email' });
        setErrorEmail(error as string);
      }
    }
  };

  const emailInput = () => {
    return (
      <>
        <Input
          type="email"
          value={settingsStore.email}
          onChangeValue={settingsStore.setEmail}
          placeholder="E-mail"
          className="welcome_pop-up__input"
        />
        <Input
          type="text"
          value={settingsStore.inn}
          onChangeValue={settingsStore.setInn}
          placeholder="ИНН"
          className="welcome_pop-up__input"
        />
      </>

    );
  };

  return (
    <>
      <Message
        visible={settingsStore.showPopUp}
        showIcon={false}
        showLine={false}
        title={welcomeTitleImg()}
        emailInput={emailInput()}
        textBottom={textBottom()}
        applyText="Готово!"
        textTop={welcomeTitleText()}
        emailInputError={errorEmail}
        isErrorBlock
        onApply={onApplyClick}
        className="welcome_pop-up"
      />
    </>
  );
});

export default WelcomePopUp;
