import cx from 'classnames';
import React from 'react';
import './style.css';

export interface SidebarTab {
  key: string;
  label: React.ReactNode;
}

interface Props {
  tabs: SidebarTab[];
  value: string;
  onChange: (value: string) => void;
}

const SidebarTabs: React.FC<Props> = ({
  tabs,
  value,
  onChange,
  children,
}) => {
  const handleTabClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { key } = e.currentTarget.dataset;

    if (value !== key) {
      onChange(key);
    }
  };

  return (
    <div className="sidebar-tabs">
      <div className="sidebar-tabs__sidebar">
        <ul className="sidebar-tabs-list">
          {tabs.map(tab => (
            <li
              className={cx('sidebar-tabs-list__item', { _active: tab.key === value })}
              key={tab.key}
              data-key={tab.key}
              onClick={handleTabClick}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-tabs__content">
        {children}
      </div>
    </div>
  );
};

export default SidebarTabs;
