import React from 'react';
import styles from '~/screens/DeadZoneScreen/EmptyScreen/Instructions/style.module.css';

export const CMSInstructions = () => (
  <div className={styles.cms}>
    Во всех популярных системах управления сайтом (CMS) есть возможность создать
    XML-карту автоматически или через плагины. Файлы sitemap в таких случаях
    обновляются автоматически, вам не придется генерировать карту сайта заново
    после каждой добавленной страницы.
  </div>
);
