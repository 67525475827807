import { Dropdown, Menu, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthStore } from '~/stores/authStore';
import './style.css';

interface Props {
  authStore?: AuthStore;
}

@inject('authStore')
@observer
class NavProfile extends Component<Props> {
  handleLogout = async () => {
    const { authStore } = this.props;

    authStore.logout();
  }

  renderProfileBlock = () => {
    const { currentUser } = this.props.authStore;

    const menu = (
      <Menu>
        <Menu.Item key="edit">
          <Link to={`/users/${currentUser.get('id')}`}>
            Редактировать профиль
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <a onClick={this.handleLogout}>
            Выйти
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
      >
        <a>
          {currentUser.fullName
            ? currentUser.fullName
            : currentUser.get('login')
          } v
        </a>
      </Dropdown>
    );
  }

  render() {
    const { authStore } = this.props;

    return (
      <div className="nav-profile">
        {authStore.isProfileLoading
          ? <Spin />
          : this.renderProfileBlock()
        }
      </div>
    );
  }
}

export default NavProfile;
