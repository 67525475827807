import { observer } from 'mobx-react';
import React from 'react';
import SetCatalog from '~/components/generic/SetCatalog';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';
import './style.css';

const defaultErrorDescription = 'Исправьте каталог в соответствии с требованиями, вставьте еще раз ссылку в поле ниже или измените ее на новую. Наш сервис автоматически проверит исправленный каталог и обновит его статус.';

const TabCatalog: React.FC = observer(() => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const { mailingInstance } = mailingStore;

  const setCatalog = (ymlCatalog: string) =>
    mailingInstance.update({ ymlCatalog });

  const validateCatalog = (ymlCatalog: string) => {

    mailingStore.setIsYmlValid(false);

    if (ymlCatalog.includes('http://') || ymlCatalog.includes('https://')) {
      mailingStore.setIsYmlValid(true);
    }
  };

  const isInvalid = !mailingStore.isYmlValid;

  const value = mailingInstance.get('ymlCatalog');

  const isConfigInvalid = mailingStore.getSelectedItemYmlCatalogStatus() === 'LOADING_CATALOG_ERROR';

  const errorDesc = [
    mailingStore.getSelectedItemYmlCatalogErrors() || defaultErrorDescription,
  ];

  const configLinkError = isInvalid ? 'Указанная ссылка некорректна' : '';


  return (
    <div className="tab-catalog">
      <SetCatalog
        value={value}
        onChange={setCatalog}
        errorDesc={errorDesc}
        isInvalid={isInvalid}
        isConfigInvalid={isConfigInvalid}
        onBlur={() => validateCatalog(value)}
        hasClickedOnInput={null}
        configLinkError={configLinkError}
        notification={'Данные о товарах в письме всегда будут актуальными, так как будетиспользоваться информация из вашего текущего каталога'}
      />
    </div>
  );
});

export default React.memo(TabCatalog);
