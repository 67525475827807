import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TConsentToMarketing } from '~/api/profile';
import ConsentToMarketing from '~/components/generic/ConsentToMarketing';
import Button from '~/components/ui/Button';
import { clickform, paymentSuccessUrl } from '~/constants';
import { useIsSBSHook } from '~/customHooks/useIsSbsHook';
import { MsgViewSiteVisits } from '~/screens/Auth/AttendanceScreen/MsgViewSiteVisits';
import ServiceSelection from '~/screens/Auth/AttendanceScreen/ServiceSelection';
import LoadingScreen from '~/screens/LoadingScreen';
import Screen from '~/screens/Screen';
import { AuthStore } from '~/stores/authStore';
import { ProfileStore } from '~/stores/profileStore';
import { ServiceStore } from '~/stores/serviceStore';
import { TariffStore } from '~/stores/tariffStore';
import { firstPageAfterAuthorization } from '~/utils/checkingUtils/firstPageAfterAuthorization';
import { getFromLocaleStorage } from '~/utils/localStorageUtils/getFromLocaleStorage';
import { getObjectFromLocaleStorage } from '~/utils/localStorageUtils/getObjectFromLocaleStorage';
import { urlTruncateProtocol } from '~/utils/urlTruncateProtocol';
import useStores from '~/utils/useStores';
import { IUTMData } from '~/utils/utm/types';
import { SecondStepOfChoosingTariff } from './SecondStep';
import { TariffInfo } from './TariffInfo';
import './style.css';
import styles from './style.module.css';

export const AttendanceScreen = observer(() => {
  if (
    getFromLocaleStorage('isSbbolMobileScreen') === 'true' &&
    !getFromLocaleStorage('isMobileScreenWasShown')
  ) {
    location.href = '/register_mobile';

    return null;
  }

  const { tariffStore, authStore, profileStore, serviceStore } =
    useStores() as {
      tariffStore: TariffStore;
      authStore: AuthStore;
      profileStore: ProfileStore;
      serviceStore: ServiceStore;
    };

  const {
    availableServices,
    serviceSelection,
    setService,
    setServiceSelection,
    getCurrentServices,
    currentStep,
    changeCurrentStep,
  } = serviceStore;

  const filledDomain =
    authStore.currentOrg?.domain.length > 0
      ? authStore.currentOrg?.domain[0]
      : '';
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const isSBS = useIsSBSHook();

  const [consentToMarketing, setConsentToMarketing] =
    useState<TConsentToMarketing>(
      isSBS
        ? {
          sber: true,
          sbs: true,
        }
        : {
          sber: true,
          tot: true,
        }
    );

  const history = useHistory();

  const selectService = async () => {
    setLoading(true);
    Promise.all([
      await setService(),
      await getCurrentServices(),
      await tariffStore.fetchCurrentTariff(),
      await tariffStore.fetchAvailableTariffs(),
    ])
      .then(() => {
        changeCurrentStep(2);
      })
      .finally(() => setLoading(false));
  };

  const onSend = async () => {
    await profileStore.sendUserAgreements(consentToMarketing);

    if (tariffStore.sendApplication) {
      window.open(clickform);
    } else {
      if (!filledDomain) {
        await tariffStore.addDomain(urlTruncateProtocol(tariffStore.domain));
      }
      if (!tariffStore.domainErrorMessage) {
        await tariffStore.applyTariff();
      }
    }

    await authStore.fetchProfile();
  };

  useEffect(() => {
    changeCurrentStep(1);
    (async () => {
      const utm = getObjectFromLocaleStorage<IUTMData>('utm');
      if (utm && utm.data && !utm.sent) {
        const { data } = utm;
        await authStore.setUTM({
          data,
        });
      }
      await tariffStore.fetchCurrentTariff();
      await tariffStore.fetchAvailableTariffs();
    })();
  }, []);

  if (!tariffStore.tariffOptions && !tariffStore.errorMessage) {
    return <LoadingScreen />;
  }

  if (tariffStore.isTariffSuccessfullyChosen) {
    (async () => {
      await authStore.fetchClientState();
      if (authStore.clientState === 'CARD_NOT_ATTACHED') {
        history.push(paymentSuccessUrl);
      } else {
        !authStore?.currentOrg.get('domains')?.length &&
          (await authStore.fetchOrgData());
        history.push(
          firstPageAfterAuthorization(
            authStore.clientState,
            authStore.currentUser.rightsModel
          )
        );
      }
    })();
  }

  return (
    <Screen className="attendance-screen" noPadding>
      <MsgViewSiteVisits
        visible={showMessage}
        setShowMessage={setShowMessage}
      />
      <div className={currentStep === 2 && 'main-wrapper'}>
        <h1 className={cx('attendance-screen__header', styles.header)}>
          {currentStep === 1
            ? 'Выберите услуги, которые хотите подключить:'
            : 'Тариф'}
        </h1>
        {currentStep === 1 && (
          <>
            <ServiceSelection
              serviceSelection={serviceSelection}
              setServiceSelection={setServiceSelection}
              availableServices={availableServices}
            />
            <div className="attendance-screen__button-container">
              <Button
                className="attendance-screen__button"
                disabled={
                  !serviceSelection.FEED_LITE_SRV &&
                  !serviceSelection.SCENARIO_SRV &&
                  !serviceSelection.FORTUNE_WHEEL_SRV &&
                  !serviceSelection.QUIZ_SRV
                }
                loading={loading}
                onClick={selectService}
              >
                Далее
              </Button>
            </div>
          </>
        )}
        {currentStep === 2 && (
          <>
            <div className="attendance-screen__second-step">
              <TariffInfo />
              <SecondStepOfChoosingTariff
                onSend={onSend}
                filledDomain={filledDomain}
              />
              <div className="consent-to-marketing">
                <ConsentToMarketing
                  consentToMarketing={consentToMarketing}
                  setConsentToMarketing={setConsentToMarketing}
                />
              </div>
            </div>
            <Button
              className="attendance-screen__button"
              type="grey"
              onClick={() => {
                changeCurrentStep(1);
              }}
            >
              Назад
            </Button>
          </>
        )}
      </div>
    </Screen>
  );
});
