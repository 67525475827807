import React from 'react';
import './style.css';

interface IDraggableElementsProps {
  elements: any[];
  onChange: (resultArray: any[]) => void;
  className?: string;
  isSwap?: boolean;
  isDraggable?: boolean;
  isOnLighting?: boolean;
}

/**
 * Перетаскивание элементов в списке
 *
 * isSwap = false - вставляет выбранный элемент между выбранными (по умолчанию)
 * isSwap = true - меняет местами элементы
 * isDraggable = true - включает перемещение объектов (по умолчанию)
 * isDraggable = false - отключает перемещение объектов
 * isOnLighting = true - включает побсветку места вставки (по умолчанию)
 * isOnLighting = false - отключает побсветку места вставки
 * @param массив с элементами
 * @returns измененный массив с элементами
 */
const DraggableElements: React.FC<IDraggableElementsProps> = ({
  elements,
  onChange,
  className = '',
  isSwap = false,
  isDraggable = true,
  isOnLighting = true,
}) => {
  const isDownWard = (newPositionIndex: number, moveElementIndex: number) => {
    return newPositionIndex > moveElementIndex;
  };

  const onDragStart = (event: React.DragEvent, elementIndex: number) => {
    event.dataTransfer.setData('text/plan', elementIndex.toString());
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    if (isOnLighting && isDraggable) event.currentTarget.classList.add('__bodrer-on');
  };

  const onDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.currentTarget.classList.remove('__bodrer-on');
  };

  const movementUp = (
    newPositionIndex: number,
    moveElementIndex: number,
    elements: any[]
  ): any[] => {
    const tempElements = [...elements];
    const deleteElement = tempElements.splice(moveElementIndex, 1);
    const part1 =
      newPositionIndex === 0 ? [] : elements.slice(0, newPositionIndex);
    const part2 = elements.slice(newPositionIndex + 1, moveElementIndex);
    const part3 = elements.slice(moveElementIndex + 1);

    return [
      ...part1,
      ...deleteElement,
      elements[newPositionIndex],
      ...part2,
      ...part3,
    ];
  };

  const movementDown = (
    newPositionIndex: number,
    moveElementIndex: number,
    elements: any[]
  ): any[] => {
    const tempElements = [...elements];
    const deleteElement = tempElements.splice(moveElementIndex, 1);
    const part1 = elements.slice(0, moveElementIndex);
    const part2 = elements.slice(moveElementIndex + 1, newPositionIndex);
    const part3 = elements.slice(newPositionIndex + 1);

    return [
      ...part1,
      ...part2,
      elements[newPositionIndex],
      ...deleteElement,
      ...part3,
    ];
  };

  const moving = (
    newPositionIndex: number,
    moveElementIndex: number,
    elements: any[]
  ): any[] => {
    return isDownWard(newPositionIndex, moveElementIndex)
      ? movementDown(newPositionIndex, moveElementIndex, elements)
      : movementUp(newPositionIndex, moveElementIndex, elements);
  };

  const swap = (elements: any[], param: number[]) => {
    const resultArray = [...elements];
    resultArray[param[1]] = resultArray.splice(
      param[0],
      1,
      resultArray[param[1]]
    )[0];

    return resultArray;
  };

  const onDrop = (
    event: React.DragEvent,
    newPositionIndex: number,
    elements: any[]
  ) => {
    if (isDraggable) {
      const moveElementIndex = parseInt(
        event.dataTransfer.getData('text/plan'),
        10
      );
      if (newPositionIndex !== moveElementIndex) {
        const resultArray = isSwap
          ? swap(elements, [newPositionIndex, moveElementIndex])
          : moving(newPositionIndex, moveElementIndex, elements);
        onChange([...resultArray]);
      }
      event.dataTransfer.clearData();
      event.currentTarget.classList.remove('__bodrer-on');
    }
  };

  return (
    <div className={className}>
      {elements?.map((element, index, elements) => {
        return (
          <div
            key={index}
            className="components-ui-draggable-elements__element"
            draggable={isDraggable}
            onDragStart={event => onDragStart(event, index)}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={event => onDrop(event, index, elements)}
          >
            {element}
          </div>
        );
      })}
    </div>
  );
};

export default DraggableElements;
