import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CheckCodeButton from '~/components/CheckCodeButton';
import CodeToCopy from '~/components/CodeToCopy';
import Button from '~/components/ui/Button';
import Tabs from '~/components/ui/Menu/Tabs';
import Screen from '~/screens/Screen';
import { scrollReaction } from '~/utils/scrollReaction';
import './style.css';

const items = [
  { key: '1', title: '1. Перейдите к файлам Вашего сайта', scrollToId: 'go-to-file' },
  { key: '2', title: 'Через хостинг', scrollToId: 'hosting' },
  { key: '3', title: 'Через FTP-клиент', scrollToId: 'ftp' },
  { key: '4', title: '2. Откройте HTML-файл в редакторе кода', scrollToId: 'open-ide' },
  { key: '5', title: '3. Скопируйте код', scrollToId: 'copy-code' },
  { key: '6', title: '4. Вставьте код', scrollToId: 'put-code' },
  { key: '7', title: '5. Проверьте код', scrollToId: 'check-code' },
  { key: '8', title: '6. «Счётчик успешно установлен!»', scrollToId: 'install-ok' },
  { key: '9', title: '«Счётчик не установлен»', scrollToId: 'install-failed' },
];

const InstallGuideHTMLScreen: React.FC = observer(() => {
  const [copied, setCopied] = useState<boolean>(false);
  const [value, changeValue] = useState<string>('1');

  const reaction = () => {
    scrollReaction(items, changeValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(reaction, 50));
    
    return () => {
      window.removeEventListener('scroll', reaction);
    };
  }, [value]);

  return (
    <Screen className="guide-html-screen html">
      <div className="guide-html__container">
        <Button type="text" icon="ArrowMLeft" size="x-small" to="/guide/developer">
          Назад
        </Button>
        <h1 className="guide-html__title">Установка кода в исходный код сайта (через HTML)</h1>
        <h2 className="guide-html__subtitle" id={items[0].scrollToId}>1. Перейдите к файлам Вашего сайта</h2>
        <ul className="guide-html-list">
          <li className="guide-html-list__item">
            <h3 className="guide-html-list__item-title" id={items[1].scrollToId}>Через хостинг</h3>
            <p className="guide-html-list__item-txt">
              Откройте область (обычно это хостинг), где хранятся ресурсы вашего сайта.
              Для того, чтобы туда попасть, Вам будут нужны административные права.
            </p>
          </li>
          <li className="guide-html-list__item">
            <h3 className="guide-html-list__item-title" id={items[2].scrollToId}>Через FTP-клиент</h3>
            <p className="guide-html-list__item-txt">
              Если доступ к административной области затруднен, попробуйте воспользоваться FTP-клиентом
              (File Transfer Protocol – протокол передачи файлов) – программа для упрощенного доступа
              к удаленному FTP-серверу в режиме текстовой консоли. Например, доступный и удобный FTP-клиент:
              FileZilla (https://filezilla.ru/).
            </p>
          </li>
        </ul>
        <h2 className="guide-html__subtitle" id={items[3].scrollToId}>2. Откройте HTML-файл в редакторе кода</h2>
        <p className="guide-html__txt">Откройте на редактирование нужный html-файл с коревой страницей
          (чтобы вставляемый код подвязался на все страницы сайта). Вам должна открыться страница с кодом вашего сайта.
          Если ее не удается открыть, то проверьте наличие текстового редактора кода. Наиболее удобные редакторы:
          Sublime Text 3; Notepad++; Atom; Visual Studio Code.</p>
        <h2 className="guide-html__subtitle" id={items[4].scrollToId}>3. Скопируйте код</h2>
        <p className="guide-html__txt">Скопируйте код из личного кабинета «СберЛид». </p>
        <div className="guide-html__code-area">
         <CodeToCopy onCopy={() => setCopied(copied)} />
        </div>
        <h2 className="guide-html__subtitle" id={items[5].scrollToId}>4. Вставьте код</h2>
        <p className="guide-html__txt">После того, как откроете исходный код сайта, перейдите к началу кода и
          вставьте скопированный код сразу после открытия тега &lt;body&gt; или
          как можно ближе к началу страницы (после тега &lt;head&gt;).
        </p>
        <p className="guide-html__txt">Этот код асинхронно загружает JS-библиотеку и создает глобальный объект
          «СберЛид», к которому нужно обращаться для взаимодействия с API. После установки сервиса
          на ваш сайт, скрипт автоматически начинает действовать, в том числе на всех поддоменах сайта.
        </p>
        <div className="install-code-image image" />
        <h2 className="guide-html__subtitle" id={items[6].scrollToId}>5. Проверьте подключение</h2>
        <p className="guide-html__txt">После установки кода счетчика вернитесь на начальный экран «СберЛид»
          и кликните на кнопку «Проверить код».
        </p>
        <CheckCodeButton />
        <h2 className="guide-html__subtitle" id={items[7].scrollToId}>6. «Счётчик успешно установлен!»</h2>
        <p className="guide-html__txt">Если код счетчика установлен корректно, то через некоторое время Вы
          заметите появление данных с вашего сайта и сможете начать с ними работать.
        </p>
        <div className="success-install__image image" />
        <h2 className="guide-html__subtitle" id={items[8].scrollToId}>«Счётчик не установлен»</h2>
        <p className="guide-html__txt">Если код счетчика установлен некорректно, то при нажатии кнопки «Проверить код»,
          Вы не сможете перейти на Главную страницу Личного кабинета. В этом случае, проверьте корректность
          установки кода счетчика на своем сайте еще раз.
        </p>
        <div className="guide-html__separator" />
      </div>      
      <Tabs
        items={items}
        value={value}
        onChange={changeValue}
        direction="vertical"
        className="guide-html__tabs"
        withScroll
      />
    </Screen>
  );
});

export default React.memo(InstallGuideHTMLScreen);
