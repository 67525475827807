import cx from 'classnames';
import React, { useState } from 'react';
import styles from './style.module.css';

interface ICustomTooltip {
  children: JSX.Element;
  tooltipText: string;
  className?: string;
}

export const CustomTooltip = ({ children, tooltipText, className }: ICustomTooltip) => {
  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  return (
    <div className={styles.tooltipWrap}>
      <div
        className={styles.tooltip}
        onMouseEnter={() => setIsTooltipShown(true)}
        onMouseLeave={() => setIsTooltipShown(false)}
      >
        {children}
    </div>
      {isTooltipShown && (
        <div className={cx(styles.tooltipText, className)}>{tooltipText}</div>
      )}
    </div>
  );
};
