import { IFeedConfiguration, TFeedCard } from 'feed';
import React from 'react';
import TopPanel from '~/components/generic/StepSelectorAndDesign/TopPanel';
import Frame from '~/components/generic/StepSelectorAndDesign/Workspace/Frame';
import NoFrameDemonstration from '~/components/generic/StepSelectorAndDesign/Workspace/NoFrameDemonstration';
import './style.css';

interface IWorkspaceProps {
  feedTypeName: string;
  currentStep: EditorSteps;
  device: Device;
  id?: number | string;
  urlPattern: string;
  isIFrameAccessible: boolean;
  contentHeight: string;
  isRechoosingSelector: boolean;
  selector: string;
  selectorMobile: string;
  feedConfiguration: IFeedConfiguration;
  frameRef: React.MutableRefObject<HTMLIFrameElement>;
  mobileFrameRef: React.MutableRefObject<HTMLIFrameElement>;
  showPrototipFeedInFrame: (configuration: IFeedConfiguration) => void;
  uuid: string;
  reqRec: 'off' | 'temp';
  isOpenDialogManuallyAddingSelector: boolean;
  cards: TFeedCard[];
  feedMode: FeedMode;
  setActiveMenu: (activeMenu: StepDesignMenuElements) => void;
}

const Workspace: React.FC<IWorkspaceProps> = ({
  feedTypeName,
  currentStep,
  device,
  id,
  urlPattern,
  isIFrameAccessible,
  contentHeight,
  isRechoosingSelector,
  selector,
  selectorMobile,
  feedConfiguration,
  mobileFrameRef,
  frameRef,
  showPrototipFeedInFrame,
  uuid,
  reqRec,
  isOpenDialogManuallyAddingSelector,
  cards,
  feedMode,
  setActiveMenu,
}) => {
  const showFrame = isIFrameAccessible;
  const showNoFrameDemonstration = !isIFrameAccessible && currentStep === 'StepDesign';

  return (
    <div className="feed-editor-step-selector-device-workspace__container">
      <TopPanel urlPattern={urlPattern} feedTypeName={feedTypeName} />

      {showFrame && !isOpenDialogManuallyAddingSelector && (
        <Frame
          device={device}
          urlPattern={urlPattern}
          contentHeight={contentHeight}
          selector={selector}
          selectorMobile={selectorMobile}
          isRechoosingSelector={isRechoosingSelector}
          id={id}
          feedConfiguration={feedConfiguration}
          frameRef={frameRef}
          mobileFrameRef={mobileFrameRef}
          currentStep={currentStep}
          showPrototipFeedInFrame={showPrototipFeedInFrame}
          uuid={uuid}
          reqRec={reqRec}
          feedMode={feedMode}
        />
      )}

      {showNoFrameDemonstration && (
        <NoFrameDemonstration
          device={device}
          feedConfiguration={feedConfiguration}
          cards={cards}
          contentHeight={contentHeight}
          feedMode={feedMode}
          setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  );
};

export default Workspace;
