import React from 'react';
import { Header } from '~/components/generic/Header';
import { Actions, IAction } from '~/components/generic/Promo/Actions';
import { Items, IItem } from '~/components/generic/Promo/Items';
import styles from './style.module.css';

export interface IPromo {
  header: string | JSX.Element;
  subheader: string | JSX.Element;
  items: IItem[];
  text1?: string;
  text2?: string | undefined;
  img: string | JSX.Element;
  actions: IAction[];
}

export const Promo = ({
  header,
  subheader,
  text1,
  text2,
  img,
  items,
  actions,
}: IPromo) => {

  return (
    <div className={styles.promo}>
      <div className={styles.leftWrap}>
        <Header header={header} subheader={subheader} />
        <div className={styles.body}>
          <Items items={items} />
          {text1 && <div className={styles.text1}>{text1}</div>}
          {text2 && <div className={styles.text2}>{text2}</div>}
        </div>
        <Actions actions={actions} />
      </div>
      <div className={styles.rightWrap}>
        {typeof img === 'string' ? <img className={styles.promoImage} src={img} alt="" /> :  img}
      </div>
    </div>
  );
};
