import cx from 'classnames';
import React from 'react';
import Toolbar from '~/components/ui/Toolbar';
import './style.css';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  actions?: React.ReactNode;
  aside?: React.ReactNode;
  withSidebar?: boolean;
  isFeed?: boolean;
}

const DrawerFooter: React.FC<Props> = ({
  className,
  style,
  actions,
  aside,
  withSidebar,
  isFeed,
}) => (
  <div
    className={cx('drawer-footer', { '_with-sidebar': withSidebar, '_feed': isFeed && withSidebar }, className)}
    style={style}
  >
    <Toolbar
      left={actions}
      right={aside}
    />
  </div>
);

export default DrawerFooter;
