import cx from 'classnames';
import React from 'react';
import RadioGrid from '~/components/notification-editor/RadioGrid';
import FormItem from '~/components/ui/FormItem';
import {
  IQuizAlignment
} from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/CoverEditor/CoverEditor';
import './style.css';

interface ItemsPositionProps {
  title: string;
  align?:  INotificationAlignment | IQuizAlignment;
  showTopRow?: boolean;
  showCenterRow?: boolean;
  showBottomRow?: boolean;
  onSelect?: (align:  INotificationAlignment | IQuizAlignment) => void;
  hint?: string;
  customVisibleItems?:  KeyName[];
}

export interface KeyName {
  name: string;
  key: INotificationAlignment | IQuizAlignment;
}

const topItems: KeyName[] = [
  { name: 'AlignTopLeft', key: 'top-left' },
  { name: 'AlignTopCenter', key: 'top-center' },
  { name: 'AlignTopRight', key: 'top-right' },
];

const centerItems: KeyName[] = [
  { name: 'AlignCenterLeft', key: 'center-left' },
  { name: 'AlignCenter', key: 'center' },
  { name: 'AlignCenterRight', key: 'center-right' },
];

const bottomItems: KeyName[] = [
  { name: 'AlignBottomLeft', key: 'bottom-left' },
  { name: 'AlignBottomCenter', key: 'bottom-center' },
  { name: 'AlignBottomRight', key: 'bottom-right' },
];

const ItemPosition: React.FC<ItemsPositionProps> = ({
  title,
  align,
  onSelect,
  showTopRow = true,
  showCenterRow = true,
  showBottomRow = true,
  hint,
  customVisibleItems,
}) => {
  let visibleItems: KeyName[] = [];

  if (customVisibleItems) {
    visibleItems = customVisibleItems;
  } else {
    showTopRow && (visibleItems = [...visibleItems, ...topItems]);
    showCenterRow && (visibleItems = [...visibleItems, ...centerItems]);
    showBottomRow && (visibleItems = [...visibleItems, ...bottomItems]);
  }

  return (
    <FormItem
      noHTMLLabel
      noReservedPlaceForMessage
      title={title}
      hint={hint}
    >
      <div className="item-position">
        <RadioGrid
          onSelect={onSelect}
          items={(visibleItems).map(({ key }) => (
            <div key={key} className={cx('item-position__dot-wrapper', { _selected: align === key })}>
              <div className="item-position__dot" />
            </div>
          ))}
        />
      </div>
    </FormItem>
  );
};

export default ItemPosition;
