import React from 'react';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import FormItem from '~/components/ui/FormItem';
import RadioGroup from '~/components/ui/RadioButton/RadioGroup';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import { CheckedCountPage, FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import './../style.css';

interface ICrossSellEmbeddingProps {
  urlPattern: string;
  onChange: ({ }: IPlacementPageParams) => void;
  isInvalid: boolean;
  onBlur: () => void;
  errorMessage: string;
  checkedCountPage: CheckedCountPage;
  externalKey: string;
}

const CrossSellEmbedding: React.FC<ICrossSellEmbeddingProps> = ({
  urlPattern,
  onChange,
  isInvalid,
  onBlur,
  errorMessage,
  checkedCountPage,
  externalKey,
}) => {
  const { feedProStore } = useStores() as {
    feedProStore: FeedProStore;
  };
  const { feed } = feedProStore;

  const operation = feed.get('displayConditions')?.operation;
  const url = feed.get('displayConditions')?.url;

  const options = [
    {
      label: 'На странице корзины',
      value: 'ONE',
    },
    { label: 'На всех карточках товаров', value: 'MULTIPLE' },
  ];

  return (
    <div className="feed-editor-placement-pages">
      <NumberedBlock number={1}>
        <FormItem
          title="Где вы хотите разместить ленту?"
          noHTMLLabel
          noReservedPlaceForMessage
          className="feed-editor-placement-pages__form-item"
        />
        <RadioGroup
          className="feed-editor-placement-pages__radio-group"
          size="medium"
          onCheck={(checkedCountPage) => {
            onChange({ checkedCountPage: checkedCountPage as CheckedCountPage });
          }}
          items={options}
          checkedItem={checkedCountPage}
        />
      </NumberedBlock>

      <NumberedBlock number={2}>
        <SetCatalogConfigLink
          title={
            checkedCountPage === 'ONE'
              ? 'Вставьте ссылку на страницу корзины'
              : 'Введите ссылку на любую карточку товара'
          }
          onChange={(urlPattern) => {
            onChange({
              urlPattern,
              url,
              checkedCountPage: checkedCountPage as CheckedCountPage,
              operation,
            });
          }}
          value={urlPattern}
          placeholder={
            checkedCountPage === 'ONE'
              ? 'https://example.ru/basket'
              : 'https://example.ru/product'
          }
          tooltipText={`Ссылка на страницу должна быть полной - включая «http://» или «https://»`}
          isInvalid={isInvalid}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
        {checkedCountPage === 'MULTIPLE' && (
          <div className="feed-editor-placement-pages__info">
            На основании этой ссылки мы автоматически распознаем остальные
            страницы всех товаров вашего сайта.&nbsp; На каждой странице товара
            будет своя лента рекомендаций.
          </div>
        )}
      </NumberedBlock>

      {checkedCountPage === 'ONE' && (
        <>
          <NumberedBlock number={3}>
            <div className="feed-editor-placement-pages__title">
              Скопируйте этот код
            </div>
            <div className="feed-editor-placement-pages__info">
              Этот код нужен, чтобы передавать ID товаров из корзины для
              формирования ленты рекомендаций. Без этого кода лента рекомендаций
              работать не будет.
            </div>
            <ToCopyArea
              text={
                '<script type="text/javascript">' +
                '(window["sberLeadApiQueue"] = window["sberLeadApiQueue"] || []).push(' +
                '{' +
                'method: "setProductsInBasket",' +
                'items: [' +
                '{ productId: <product_id>, quantity: <quantity>, price: <price> },' +
                '{ productId: <product_id>, quantity: <quantity>, price: <price> },' +
                '],' +
                `externalKey: "${externalKey}"` +
                '}' +
                ');' +
                '</script>'
              }
              height={'116px'}
              style={{
                color: '#00bd7e',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </NumberedBlock>

          <NumberedBlock number={4}>
            <div className="feed-editor-placement-pages__title">
              Вставьте скопированный код на страницу корзины вашего сайта перед
              закрывающим тегом &lt;/head&gt; и до основного кода СберЛид
            </div>
            <img
              src="/assets/settings/code.png"
              alt="code"
              className="feed-editor-placement-pages-code__img"
            />
          </NumberedBlock>

          <div className="feed-editor-placement-pages__big_label">
            Возникли трудности? Отправьте инструкцию вашему специалисту
          </div>
          <NumberedBlock>
            <a
              href="/assets/doc/instructions_for_setting_events.pdf"
              target="_blank"
            >
              <div className="feed-editor-placement-pages__manual">
                <div className="feed-editor-placement-pages__manual_item">
                  <Icon name={'Download'} className="feed-editor-placement-pages__green_style" />
                </div>
                <div className="feed-editor-placement-pages__green_style">Скачать инструкцию</div>
              </div>
            </a>
          </NumberedBlock>
        </>
      )}
    </div>
  );
};

export default CrossSellEmbedding;
