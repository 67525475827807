import { IFeedConfiguration } from 'feed';
import React, { useState } from 'react';
import ParamCss from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamCss';
import HandCardParams from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/General/Card/HandCardParams';
import { currentCardCssParam } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/General/Card/currentCardCssParam';
import { cssSwitchButtons } from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/constants';
import FormItem from '~/components/ui/FormItem';
import SwitchButton from '~/components/ui/SwitchButton';

interface ICardProps {
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  isDesctop: boolean;
}

const Card: React.FC<ICardProps> = ({ configuration, onChange, isDesctop }) => {
  const [switchButtonState, setSwitchButtonState] = useState<string>(
    configuration.css?.card ? 'CSS' : 'HAND'
  );
  const onChangeCssParam = (value: string) => {
    const css = configuration.css ?? {};
    css.card = value;
    onChange({ ...configuration, css }, true);
  };

  return (
    <>
      <section className="feed-editor-appearance__section">
        <FormItem
          title="Настроить стиль карточек товаров"
          overlayClassName="feed-editor-appearance__tooltip"
          className="feed-editor-appearance__font"
        >
          <SwitchButton
            buttons={cssSwitchButtons}
            activeKey={switchButtonState}
            onClick={setSwitchButtonState}
          />
        </FormItem>
        {switchButtonState === 'CSS' && (
          <ParamCss
            onChangeCssParam={onChangeCssParam}
            value={
              configuration.css?.card ??
              currentCardCssParam()
            }
          />
        )}
      </section>
      {switchButtonState === 'HAND' && isDesctop && (
        <HandCardParams
          isActive={switchButtonState === 'HAND'}
          configuration={configuration}
          onChange={onChange}
          onChangeCssParam={onChangeCssParam}
        />
      )}
    </>
  );
};

export default Card;
