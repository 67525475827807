import { InputDropdownItem } from '~/components/ui/InputDropdown';

export const getItems = (length: number) => {
  const items: InputDropdownItem<string>[] = [];

  for (let i = 1; i <= length; i++) {
    items.push({
      key: i.toString(),
      value: i.toString(),
    });
  }
  
  return items;
};