import React from 'react';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import Link from '~/components/ui/Link';
import styles from './../style.module.css';

const InfoBlock: React.FC = () => (
  <div className={styles.info}>
    <div className={styles.info_title}>
      Инструкции по созданию каталога и предварительная проверка
    </div>
    <div className={styles.info_block}>
      <NumberedBlock>
        <div className={styles.info_item}>
          <Icon name={'Question'} className={styles.info_icon} />
          <Link
            target="_blank"
            to="https://yandex.ru/support/partnermarket/export/cms.html"
          >
            Инструкция по созданию каталога в формате YML
          </Link>
        </div>
        <div className={styles.line} />
        <div className={styles.info_item}>
          <Icon name={'NavDeadZone'} className={styles.info_icon} />
          <Link
            target="_blank"
            to="https://www.google.com/retail/solutions/merchant-center/#?modal_active=none"
          >
            Проверка с помощью сервиса Google Merchant
          </Link>
        </div>
      </NumberedBlock>
    </div>
  </div>
);

export default InfoBlock;
