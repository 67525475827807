import { IFeedConfiguration, TFontSize } from 'feed';
import React from 'react';
import ParamHand from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/ParamHand';
import FontSettings from '~/components/notification-editor/FontSettings';

const HandTitleParams: React.FC<{
  isActive: boolean;
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onChangeCssParam: (params: string) => void;
}> = ({ isActive, configuration, onChange, onChangeCssParam }) => {

  return (
    <ParamHand
      isActive={isActive}
      configuration={configuration}
      onChange={onChange}
      onChangeCssParam={onChangeCssParam}
    >
      <section className="feed-editor-appearance__section">
        <FontSettings
          className="feed-editor-appearance__font"
          type="title"
          title="Шрифт"
          overlayClassName="feed-editor-appearance__tooltip"
          data={{
            fontFamily: configuration.feedTitleFontFamily,
            color: configuration.feedTitleColor,
            alpha: configuration.feedTitleOpacity,
            fontWeight: configuration.feedTitleFontWeight,
            fontSize: configuration.feedTitleFontSize,
            isItalic: configuration.feedTitleFontItalic,
            isLineThrough: configuration.feedTitleTextLineThrough,
            isUnderline: configuration.feedTitleTextUnderline,
          }}
          onChange={data =>
            onChange(
              {
                ...configuration,
                feedTitleFontFamily: data.fontFamily,
                feedTitleColor: data.color,
                feedTitleOpacity: data.alpha,
                feedTitleFontWeight: data.fontWeight,
                feedTitleFontSize: data.fontSize as TFontSize,
                feedTitleFontItalic: data.isItalic,
                feedTitleTextLineThrough: data.isLineThrough,
                feedTitleTextUnderline: data.isUnderline,
              },
              true
            )
          }
        />
      </section>
    </ParamHand>
  );
};

export default HandTitleParams;
