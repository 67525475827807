import React from 'react';
import { ISector } from '~/components/generic/Wheel/components/Sector/type';
import styles from './styles.module.css';


export const Sector = ({
  name = 'Сюрприз в магазине',
  backgroundColor = '#808080',
  size = 135,
  degree = 45,
  number = 1,
  textColor = 'white',
}: ISector) => {
  const degreeToPercent = 100 * degree / 360;

  const circleDiameter = size * 2;

  const style = {
    background: `conic-gradient(${backgroundColor} ${degreeToPercent}%, transparent 0)`,
    transform: `rotate(${degree * (number - 1)}deg)`,
    height: `${circleDiameter}px`,
    width: `${circleDiameter}px`,
  };

  const textStyle = {
    transform: `rotate(-${90 - degree / 2}deg)`,
  };

  const textInnerStyle = {
    color: textColor,
    fontSize: `${circleDiameter * .035}px`,
    lineHeight: `${circleDiameter * .035}px`,
  };

  return (
    <div className={styles.sector} style={{ ...style }}>
      <div className={styles.textWrap} style={textStyle}>
        <div className={styles.text} style={textInnerStyle}>
          {name === '' ? 'Нет подарка' : name}
        </div>
      </div>
    </div>
  );
};
