import React from 'react';
import Icon, { Icons } from '~/components/Icon';
import styles from './style.module.css';

export interface IItem {
  iconSrc: Icons;
  text: string;
}

export const Items = ({ items }: { items: IItem[] }) => {
  return (
    <div className={styles.items}>
      {items.map((item, key) => (
        <div key={key} className={styles.item}>
          <div className={styles.iconWrap}>
            <Icon name={item.iconSrc} className={styles.icon} />
          </div>
          <div className={styles.itemText}>{item.text}</div>
        </div>
      ))}
    </div>
  );
};
