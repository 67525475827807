export const columns = [
  {
    title: 'Дата',
    dataIndex: 'dateTime',
    hasSortingButton: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
  },
  {
    title: 'Количество ответов на вопросы',
    dataIndex: 'answers',
  },
];

export const getContactsStatistics = (byContacts: any[]) => {
  return byContacts.map((contactStatistic, key) => ({
    ...contactStatistic,
    dateTime: new Date(contactStatistic.dateTime).toLocaleString(),
    answers: `${contactStatistic?.questions?.length} из ${
      contactStatistic?.questions?.length || 0
    } вопросов`,
  }));
};
