import { IFeedConfiguration } from 'feed';
import React from 'react';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import Switch from '~/components/ui/Switch';
import './style.css';

interface IAdditionalParamsProps {
  labelMaxCount: number;
  labelPriorityOrderYml: boolean;
  onChangeConfigurationParam: (params: Partial<IFeedConfiguration>) => void;
  labelMaxCountLimit: number;
}

const AdditionalParams: React.FC<IAdditionalParamsProps> = ({
  labelMaxCount,
  labelPriorityOrderYml,
  onChangeConfigurationParam,
  labelMaxCountLimit,
}) => {
  const validateLabelMaxCount = (count: string) => {
    const validatValue = parseInt(count, 10);
    onChangeConfigurationParam({
      labelMaxCount:
        validatValue > labelMaxCountLimit ? labelMaxCountLimit : validatValue,
    });
  };

  const onCangeLabelMaxCount = (value: string) => {
    onChangeConfigurationParam({ labelMaxCount: parseInt(value, 10) || 0 });
  };

  return (
    <div className={'feed-pro-editor-step-catalog__block-params'}>
      <div
        className={`feed-pro-editor-step-catalog__block-param ${
          labelMaxCount > labelMaxCountLimit ? '__alert' : ''
        }`}
      >
        Максимальное количество тегов, отображаемое на одном товаре
        <FormItem
          message={
            labelMaxCount > labelMaxCountLimit
              ? 'Превышено максимальное значение'
              : ''
          }
        >
          <Input
            style={{
              width: 46,
              minHeight: 46,
              borderColor:
                labelMaxCount > labelMaxCountLimit ? `var(--Red-300)` : '',
            }}
            value={labelMaxCount?.toString() ?? labelMaxCountLimit.toString()}
            maxLength={5}
            onChangeValue={onCangeLabelMaxCount}
            onBlur={validateLabelMaxCount}
            failure={labelMaxCount > labelMaxCountLimit}
          />
        </FormItem>
      </div>
      <div className={'feed-pro-editor-step-catalog__block-param'}>
        Приоритетность определять вашим каталогом
        <Switch
          checked={labelPriorityOrderYml}
          onChange={(status) => {
            onChangeConfigurationParam({ labelPriorityOrderYml: status });
          }}
        />
      </div>
    </div>
  );
};

export default AdditionalParams;
