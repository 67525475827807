import { observer } from 'mobx-react';
import React from 'react';
import Message from '~/components/ui/Message';
import { AuthStore } from '~/stores/authStore';
import { ProfileStore } from '~/stores/profileStore';
import useStores from '~/utils/useStores';
import './style.css';

const ServiceShutdown = observer(() => {

  const { authStore } = useStores() as { authStore: AuthStore };
  const { profileStore } = useStores() as { profileStore: ProfileStore };

  return (
    <>
      { profileStore.step === 1 ?
        (
          <Message
            className="service-shutdown"
            visible={profileStore.step === 1}
            title="Вы уверены, что хотите отключить сервис?"
            textTop="Отключив сервис, Вы отказываетесь от услуг СберЛид. Соглашение об использовании сервиса будет расторгнуто, оплата подписки остановлена и сбор данных по Вашему сайту будет прекращен, вся функциональность сервиса отключена."
            applyText="Отменить"
            cancelText="Отключить сервис"
            showIcon={false}
            onClose={() => profileStore.changeStep(0)}
            onApply={() => profileStore.closeContract()}
            swapActions
          />
        )
        : ''}
      { profileStore.step === 2 ?
        (
          <Message
            className="service-shutdown"
            visible={profileStore.step === 2}
            title="Вы успешно отписались от сервиса СберЛид"
            textTop="Выход из учетной записи будет произведен автоматически"
            textBottom="Мы работаем над улучшением сервиса и благодарим за использование СберЛид. Надеемся на скорое возвращение!"
            applyText="ОК"
            showIcon={false}
            onApply={() => {
              profileStore.changeStep(0);
              authStore.logout();
            }}
          />
        )
        : ''}
    </>
  );
});

export default ServiceShutdown;
