import React, { useEffect, useState } from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import { CoverEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/CoverEditor/CoverEditor';
import {
  FinalEditor
} from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/FinalEditor/FinalEditor';
import {
  LeadEditor
} from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/LeadEditor/LeadEditor';
import { QuestionEditor } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/QuestionEditor';
import { LibraryOfQuestions } from '~/screens/QuizScreen/components/constructorSteps/ConstructQuiz/EditDrawer/QuestionEditor/LibraryOfQuestions';
import { QuizStore } from '~/screens/QuizScreen/stores/quizStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IEditDrawer {
  onClose: () => void;
  step: number;
  setIsMaxQuestionsAdded: () => void;
}

export interface IStepEditor {
  onDelete: () => void;
  step: number;
}

export const EditDrawer = ({ onClose, step, setIsMaxQuestionsAdded }: IEditDrawer) => {
  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };
  const { quizExample } = quizStore;
  const { deleteStep } = quizExample;

  const [isShowLibraryOfQuestions, setIsShowLibraryOfQuestions] = useState<boolean>(false);

  const actualStepInfo = (quizExample.get('steps').length &&
    quizExample.get('steps')[step]) || { type: '' };


  const { type } = actualStepInfo;

  useEffect(() => {

    if (!type) {
      onClose();
    }
  }, [type]);

  const onDelete = () => {
    deleteStep(step);
    onClose();
  };

  let stepEditor: JSX.Element = (
    <div>это не обложка, вставить другой редактор</div>
  );
  let title = 'Такого блока не существует';
  let description = 'Такого блока реально не существует. ' + actualStepInfo?.type;

  switch (type) {
    case 'cover': {
      title = 'Настройте обложку';
      description = 'Меняйте под себя. Все сохранится автоматически!';
      stepEditor = <CoverEditor step={step} onDelete={onDelete} />;
      break;
    }
    case 'question': {
      switch (isShowLibraryOfQuestions) {
        case true: {
          title = 'Библиотека вопросов';
          description = 'Добавте или выберите готовые вопросы и ответы';
          stepEditor = <LibraryOfQuestions setIsMaxQuestionsAdded={setIsMaxQuestionsAdded} />;
          break;
        }
        default: {
          title = 'Вопрос ' + step;
          description = 'Придумайте или выберите готовые вопросы и ответы';
          stepEditor = (
            <QuestionEditor
              step={step}
              onDelete={onDelete}
              setIsShowLibraryOfQuestions={setIsShowLibraryOfQuestions}
            />
          );

        }
      }
      break;
    }
    case 'lead': {
      title = 'Настройте сбор контактов';
      description = 'Вы можете собирать данные пользователя. Выберите просто какие нужны вам.';
      stepEditor = <LeadEditor step={step} onDelete={onDelete} />;
      break;
    }

    case 'final': {
      title = 'Настройте результат';
      description = 'Меняйте под себя. Все сохранится автоматически.';
      stepEditor = <FinalEditor step={step} onDelete={onDelete} />;
    }
  }

  return (
    <div className={styles.editDrawer}>
      {isShowLibraryOfQuestions && (
        <Button
          onClick={() => setIsShowLibraryOfQuestions(false)}
          icon={'ArrowSLeft'}
          type={'default'}
          className={styles.backButton}
        >
          Назад
        </Button>
      )}
      <Icon name={'Close'} className={styles.close} onClick={onClose} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      {stepEditor}
    </div>
  );
};
