import useMutationObserver from '@rooks/use-mutation-observer';
import cx from 'classnames';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Notification, { NotificationProps } from '~/components/Notification';
import { ScenarioEditorSidebarPreset } from '~/components/ScenarioEditor/ScenarioEditorSidebar';
import './style.css';

interface Props {
  notificationProps: NotificationProps;
  part?: ScenarioEditorSidebarPreset;
  onChangePart: (part: ScenarioEditorSidebarPreset) => void;
  disabled?: boolean;
  showPrivacyPolicy?: () => void;
  tabStep?: number;
}

const elementIsInside = (clickedElement: HTMLElement, parentElement: HTMLElement): boolean => {
  if (!parentElement) return false;

  return clickedElement === parentElement || parentElement.contains(clickedElement);
};

const NotificationPartSelector: React.FC<Props> = ({
  notificationProps,
  part,
  onChangePart,
  disabled,
  showPrivacyPolicy,
  tabStep,
}) => {
  const nP = notificationProps;

  const wrapperRef = useRef();

  const [overlayHeight, setOverlayHeight] = useState<number>();
  const [opacity, setOpacity] = useState<number>(0);
  const [outlineTop, setOutlineTop] = useState<number>(0);
  const [outlineLeft, setOutlineLeft] = useState<number>(0);
  const [outlineWidth, setOutlineWidth] = useState<number>(0);
  const [outlineHeight, setOutlineHeight] = useState<number>(0);

  const recalcOverlayHeight = useCallback(() => {
    if (!wrapperRef.current) return;

    const container: HTMLDivElement = (wrapperRef.current as HTMLDivElement).querySelector('.notification');

    setOverlayHeight(container.offsetHeight);
  }, [wrapperRef.current]);

  useEffect(recalcOverlayHeight, []);

  useEffect(() => {
    if (!wrapperRef.current) return;

    recalcOverlayHeight();

    const wrapper = wrapperRef.current as HTMLDivElement;

    const setOutline = (selector: string, vertical = 10, horizontal = 10) => {
      const element: HTMLElement = wrapper.querySelector(selector);

      setOutlineTop(element.offsetTop - vertical);
      setOutlineLeft(element.offsetLeft - horizontal);
      setOutlineWidth(element.offsetWidth + horizontal * 2);
      setOutlineHeight(element.offsetHeight + vertical * 2);
    };

    if (disabled) return;

    if (part === 'general') {
      return setOpacity(0);
    }

    setOpacity(1);

    switch (part) {
      case 'title':
        return setOutline('.notification__title', 5);

      case 'message':
        return setOutline('.notification__message', 5);

      case 'coupon':
        return setOutline('.notification__coupon');

      case 'expiration':
        return setOutline('.notification__expiration', 5);

      case 'button':
        return setOutline('.notification__button');

      case 'image':
        const container: HTMLDivElement = wrapper.querySelector('.notification');
        setOutlineTop(0);
        setOutlineLeft(0);
        setOutlineWidth(container.offsetWidth);
        setOutlineHeight(container.offsetHeight);

        return;
    }
  }, [part, notificationProps, wrapperRef.current]);

  useMutationObserver(wrapperRef, throttle(() => {
    recalcOverlayHeight();
  }, 100, { trailing: true, leading: false }));

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;

    event.preventDefault();

    const target = event.target as HTMLElement;
    const wrapper = wrapperRef.current as HTMLDivElement;

    const title: HTMLDivElement = wrapper.querySelector('.notification__title');
    if (elementIsInside(target, title)) {
      return onChangePart('title');
    }

    const message: HTMLDivElement = wrapper.querySelector('.notification__message');
    if (elementIsInside(target, message)) {
      return onChangePart('message');
    }

    const expiration: HTMLDivElement = wrapper.querySelector('.notification__expiration');
    if (elementIsInside(target, expiration)) {
      return onChangePart('expiration');
    }

    const coupon: HTMLDivElement = wrapper.querySelector('.notification__coupon');
    if (elementIsInside(target, coupon)) {
      return onChangePart('coupon');
    }

    const button: HTMLButtonElement = wrapper.querySelector('.notification__button');
    if (elementIsInside(target, button)) {
      return onChangePart('button');
    }

    const container: HTMLDivElement = wrapper.querySelector('.notification');
    if (elementIsInside(target, container)) {
      return onChangePart('image');
    }
  }, [wrapperRef.current]);

  const overlayClassName = cx(
    'notification',
    `_position-${nP.position}`,
    `_image-position-${nP.imagePosition}`,
    {
      '_horizontal': nP.isHorizontal,
      '_mobile': nP.isMobile,
      '_has-image': nP.hasImage,
    },
    'notification-part-selector__overlay'
  );

  return (
    <div
      className="notification-part-selector"
      onClick={handleClick}
    >
      <div className="notification-part-selector__wrapper" ref={wrapperRef}>
        <Notification {...nP} isPreview={!disabled} showPrivacyPolicy={showPrivacyPolicy} tabStep={tabStep} />
      </div>

      <div className={overlayClassName} style={{ height: overlayHeight, display: disabled ? 'none' : '' }}>
        <div
          className={cx('notification-part-selector__outline', {
            '_no-bottom-radius': part === 'image' && nP.isMobile,
          })}
          style={{
            opacity,
            width: outlineWidth,
            height: outlineHeight,
            transform: `translate(${outlineLeft}px, ${outlineTop}px)`,
          }}
        />
      </div>
    </div>
  );
};

export default NotificationPartSelector;
