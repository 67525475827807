import React from 'react';
import Warning from '~/components/ui/Warning';

const WarningDemo: React.FC<{ visible: boolean }> = ({ visible }) => (
  <Warning visible={visible}>
    Вы находитесь в демо-режиме. Для того, чтобы начать собирать данные со своего сайта, необходимо добавить сайт.
  </Warning>
);

export default WarningDemo;
