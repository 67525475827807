import React from 'react';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import '../style.css';

const Desktop: React.FC<{ url: string }> = ({ url }) => {
  const handleOpenNewWindow = () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <NumberedBlock number={2}>
        <h2 className="feed-editor-instructions__step-title">
          Нажмите&nbsp;
          <div
            className="feed-editor-instructions__step-link"
            onClick={handleOpenNewWindow}
          >
            сюда&nbsp;
          </div>
          и в соседней вкладке откроется пример десктопной страницы Вашего сайта
        </h2>
      </NumberedBlock>
      <NumberedBlock number={3}>
        <h2 className="feed-editor-instructions__step-title">
          Выберите расположение блока
        </h2>
        <div className="feed-editor-instructions__step-info">
          Пролистните страницу до нужного места, наведите курсор мыши на нужную
          область и кликните по ней. В этом месте появится блок с лентой
          рекомендаций.
        </div>
        <Icon
          className="feed-editor-instructions__step-img"
          name="FeedPlacementSmall"
        />
      </NumberedBlock>
      <NumberedBlock number={4}>
        <h2 className="feed-editor-instructions__step-title">
          Подтвердите расположение
        </h2>
        <div className="feed-editor-instructions__step-info">
          Если Вам нравится выбранное место расположения, просто нажмите "Ок".
          <br />
          Если хотите изменить место, то нажмите "Отмена" и выберите новое
          место.
        </div>
      </NumberedBlock>
    </>
  );
};

export default Desktop;
