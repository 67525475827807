import React from 'react';
import Timer from '~/components/Timer';

export const getLoggingErrorWithTimer = (error: string) => {
  const pattern = /\{(.*)\}/i;
  const checkOnTime = pattern.test(error);

  if (!checkOnTime) return error;

  const timeInSeconds = error.match(pattern)[1]
    ? parseInt(error.match(pattern)[1], 10)
    : 600;
  const errorString =
    error.split('через')[0] ||
    'Пользователь временно заблокирован. Повторите попытку';

  return (
    <>
      {errorString}{' '}
      {(
        <Timer
          timestamp={+new Date() + timeInSeconds * 1000}
          whenReady={'прямо сейчас'}
        />
      )}
    </>
  );
};
