import { IFeedConfiguration } from 'feed';
import { FEED_DEF_CONFIGURATION, FEED_DEF_MOBILE_CONFIGURATION } from '~/components/Feed/constants';

export const LABELS_LIMIT = 3;

export const FEED_LITE_DEF_CONFIGURATION: IFeedConfiguration = {
  ...FEED_DEF_CONFIGURATION,
  feedTitle: 'Рекомендованные товары',
};

export const FEED_LITE_DEF_MOBILE_CONFIGURATION: IFeedConfiguration = {
  ...FEED_LITE_DEF_CONFIGURATION,
  ...FEED_DEF_MOBILE_CONFIGURATION,
};