import cx from 'classnames';
import React from 'react';
import './style.css';

interface IMobile {
  mobileContainerRef: React.MutableRefObject<HTMLDivElement>;
  mobileFrameRef: React.MutableRefObject<HTMLIFrameElement>;
  isResizing: boolean;
  urlPattern: string;
  currentStep: EditorSteps;
}

const Mobile: React.FC<IMobile> = ({
  mobileContainerRef,
  mobileFrameRef,
  isResizing,
  urlPattern,
  currentStep,
}) => {
  return (
    <div
      className={cx(
        'feed-editor-step-selector-device-workspace-frame-mobile__container',
        { _demonstration: currentStep === 'StepDesign' }
      )}
      ref={mobileContainerRef}
      style={{ display: isResizing ? 'none' : null }}
    >
      <iframe
        ref={mobileFrameRef}
        frameBorder="0"
        className="feed-editor-step-selector-device-workspace-frame-mobile__frame"
        src={urlPattern}
      />
    </div>
  );
};

export default Mobile;
