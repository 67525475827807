import React from 'react';
import { Promo } from '~/components/generic/Promo';
import { TButton } from '~/components/ui/ButtonLike';
import {
  header,
  items,
  subheader,
  text1,
  text2,
} from '~/screens/DeadZoneScreen/EmptyScreen/consts';

interface IEmptyScreen {
  showInstructions: () => void;
}

export const EmptyScreen = ({ showInstructions }: IEmptyScreen) => {
  const actionButton = {
    onClick: showInstructions,
    name: 'Инструкция подключения Sitemap',
    type: 'default' as TButton,
  };

  return (
    <Promo
      header={header}
      actions={[actionButton]}
      img={'/assets/images/promo/blindZones.png'}
      items={items}
      subheader={subheader}
      text1={text1}
      text2={text2}
    />
  );
};
