import { cloneDeep } from 'lodash';
import { action, observable } from 'mobx';
import BusinessRuleApi from '~/screens/BusinessRulesScreen/api/BusinessRuleApi';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRule,
  BusinessRulesByFeed,
  BusinessRuleEntity,
  BusinessRuleMeta,
  FeedForBusinessRule,
  FilterBusinessRuleEntityRequest,
  MetaBlock,
} from '~/screens/BusinessRulesScreen/types';
import Store from '~/stores/store';

export class BusinessRuleStore extends Store {
  // Список типов к которым будет что-то рекомендоваться (каталог / товар)
  recommendationForTypeDict: Map<string, string> = new Map();
  recommendationOfWhatTypeDict: Map<string, string> = new Map(); // То, что будет рекомендоваться (Товары из категории)
  recommendationTypeDict: Map<string, string> = new Map(); // Список типов действий (Рекомендовать / не рекомендовать)
  similarityTypeDict: Map<string, string> = new Map(); // Типы похожести (По цене / По производителю)

  @observable isNotUploadedBusinessRuleList: boolean = true; // Список созданных правил еще не загружен
  @observable metadata: BusinessRuleMeta = null; // Все мета данные по правилам
  @observable businessRuleList: BusinessRule[]; // Список созданных правил
  @observable businessRule: BusinessRulesModel = new BusinessRulesModel(
    this,
    {}
  ); // Модель бизнес-правила
  @observable isOpenBusinessRuleEditor: boolean = false; // Статус окна создания/редактирования првила
  @observable feedForBusinessRule: FeedForBusinessRule[] = []; // Список лент для привязки правила
  @observable businessRulesByFeed: BusinessRulesByFeed; // Список созданных правил к ленте
  @observable isOpenRuleRenameDialog: boolean = false; // Статус окна переименования првила
  @observable isOpenDeleteDialog: boolean = false; // Статус окна удаления првила
  @observable isOpenCreationSuccessInfoDialog: boolean = false; // Статус окна информации при создании правила

  @action
  setIsFetchingBusinessRuleList = (status: boolean) => {
    this.isNotUploadedBusinessRuleList = status;
  }

  @action
  fetchBusinessRuleList = async () => {
    try {
      if (!this.metadata) {
        const metadataResponse = await BusinessRuleApi.getMetedata();
        this.metadata = metadataResponse?.data?.payload[0];
        this.metadata.recommendationForTypeDict.map((meta: MetaBlock) => {
          this.recommendationForTypeDict.set(meta.key, meta.uiText);
        });
        this.metadata.recommendationOfWhatTypeDict.map((meta: MetaBlock) => {
          this.recommendationOfWhatTypeDict.set(meta.key, meta.uiText);
        });
        this.metadata.recommendationTypeDict.map((meta: MetaBlock) => {
          this.recommendationTypeDict.set(meta.key, meta.uiText);
        });
        this.metadata.similarityTypeDict.map((meta: MetaBlock) => {
          this.similarityTypeDict.set(meta.key, meta.uiText);
        });
      }
      const ruleListResponse = await BusinessRuleApi.getRuleList();
      this.businessRuleList = ruleListResponse?.data?.payload;
      this.setIsFetchingBusinessRuleList(false);
    } catch (e) {
      this.setIsFetchingBusinessRuleList(true);
      throw e;
    }
  }

  @action
  setIsOpenBusinessRuleEditor = (status: boolean) => {
    this.isOpenBusinessRuleEditor = status;
  }

  @action
  setFeedForBusinessRule = async () => {
    try {
      const response = await BusinessRuleApi.getFeedForBusinessRule();
      this.feedForBusinessRule = response.data.payload;
    } catch (e) {
      throw e;
    }
  }

  @action
  resetBusinessRule = () => {
    this.businessRule = new BusinessRulesModel(this, {
      ruleName: `Правило ${this.businessRuleList.length + 1}`,
      recommendationFor: {
        type: 'CATEGORY',
        entities: {
          '@type': 'FilterBusinessRuleEntity',
          'id': 0,
          'name': '',
          'uiText': '',
        },
      },
      recommendationType: 'RECOMMEND',
      recommendationOfWhat: {
        type: 'CATEGORY',
        entities: [
          {
            '@type': 'FilterBusinessRuleEntity',
            'id': 0,
            'name': '',
            'uiText': '',
            'itemCount': 1,
          },
        ],
      },
      similarityType: [],
    });
  }

  @action
  createNewBusinessRule = () => {
    this.setFeedForBusinessRule();
    this.resetBusinessRule();
    this.setIsOpenBusinessRuleEditor(true);
  }

  @action
  getBusinessRulesByFeed = async (feedId: string) => {
    try {
      const response = await BusinessRuleApi.getBusinessRulesByFeed(feedId);
      this.businessRulesByFeed = response.data.payload[0];
    } catch (e) {
      throw e;
    }
  }

  @action
  editBusinessRule = (ruleId: number) => {
    this.setFeedForBusinessRule();
    const currentRule = this.businessRuleList.find(
      rule => rule.ruleId === ruleId
    );
    if (currentRule) {
      this.businessRule = new BusinessRulesModel(
        this,
        cloneDeep(currentRule)
      );
      this.getBusinessRulesByFeed(currentRule.feedId);
      this.setIsOpenBusinessRuleEditor(true);
    }
  }

  @action
  getBusinessRuleEntity = async (
    requestParameters: FilterBusinessRuleEntityRequest
  ): Promise<BusinessRuleEntity[]> => {
    try {
      const response = await BusinessRuleApi.getBusinessRuleEntity(
        requestParameters
      );

      return response.data.payload;
    } catch (e) {
      throw e;
    }
  }

  @action
  setIsOpenCreationSuccessInfoDialog = (status: boolean) => {
    this.isOpenCreationSuccessInfoDialog = status;
  }

  @action
  saveBusinessRule = async () => {
    try {
      await BusinessRuleApi.saveBusinessRules(this.businessRule);
      this.setIsOpenBusinessRuleEditor(false);
      this.fetchBusinessRuleList();
      this.setIsOpenCreationSuccessInfoDialog(true);
    } catch (e) {
      throw e;
    }
  }

  @action
  setIsOpenRuleRenameDialog = (status: boolean) => {
    this.isOpenRuleRenameDialog = status;
  }

  @action
  editBusinessRuleName = (ruleId: number) => {
    const currentRule = this.businessRuleList.find(
      rule => rule.ruleId === ruleId
    );
    if (currentRule) {
      this.businessRule = new BusinessRulesModel(
        this,
        cloneDeep(currentRule)
      );
      this.setIsOpenRuleRenameDialog(true);
    }
  }

  @action
  renameBusinessRules = async () => {
    try {
      await BusinessRuleApi.renameBusinessRules(this.businessRule);
      this.setIsOpenRuleRenameDialog(false);
      this.fetchBusinessRuleList();
    } catch (e) {
      throw e;
    }
  }

  @action
  setIsOpenDeleteDialog = (status: boolean) => {
    this.isOpenDeleteDialog = status;
  }

  @action
  startProcessDeleteRule = (ruleId: number) => {
    const currentRule = this.businessRuleList.find(
      rule => rule.ruleId === ruleId
    );
    if (currentRule) {
      this.businessRule = new BusinessRulesModel(
        this,
        cloneDeep(currentRule)
      );
      this.setIsOpenDeleteDialog(true);
    }
  }

  @action
  deleteRule = async () => {
    try {
      await BusinessRuleApi.deleteBusinessRules(this.businessRule);
      this.setIsOpenDeleteDialog(false);
      this.fetchBusinessRuleList();
    } catch (e) {
      throw e;
    }
  }
}

export default new BusinessRuleStore();
