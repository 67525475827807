import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { paymentSuccessUrl } from '~/constants';

const RedirectToPaymentScreen = withRouter(({ location }: RouteComponentProps) =>
  <Redirect to={{ pathname: paymentSuccessUrl, search: `?redirect=${location.pathname}` }} />
);

export default RedirectToPaymentScreen;
