import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { hasAccess } from '~/components/SwitchUI/utils';
import { FloatTable } from '~/components/generic/FloatTable';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import Message from '~/components/ui/Message';
import NoticeInfo from '~/components/ui/NoticeInfo';
import { multipleRunDisabledError } from '~/screens/MailingScreen/consts';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import { convertMailingStatisticToFloatTableData } from '~/screens/MailingScreen/utils';
import { UiSwitchStore } from '~/stores/UiSwitchStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

interface IMailingStatisticsProps {
  hasRightToModify?: boolean;
}

const MailingStatistics: React.FC<IMailingStatisticsProps> = observer(({
  hasRightToModify = false,
}) => {
  const { mailingStore, uiSwitchStore } = useStores() as {
    mailingStore: MailingStore;
    uiSwitchStore: UiSwitchStore;
  };
  const {
    mailingStatistics,
    setIsErrorMessageShown,
    setErrorMessage,
    setSelectedId,
    getMailingInstanceById,
    editTitle,
    delete: deleteMailingStore,
    start: startMailingStore,
    stop,
    isShowEmailNotRegisterMessage,
    setIsShowEmailNotRegisterMessage,
    openConstructorForApproveEmail,
    constructorStore,
  } = mailingStore;
  const history = useHistory();
  const [isDeletePopupShown, setIsDeletePopupShown] = useState<boolean>(false);
  const [mailingDeletedId, setMailingDeletedId] = useState<
    string | number | undefined
  >(undefined);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [mailingEditedTitle, setMailingEditedTitle] = useState<string>('');
  const [mailingEditedId, setMailingEditedId] = useState<
    string | number | undefined
  >(undefined);

  const openConstructor = () => {
    constructorStore.setIsConstructorOpen(true);
  };

  const showMessageEmailNotRegisterMessage = (id: number) => {
    setSelectedId(id);
    setIsShowEmailNotRegisterMessage(true);
  };

  const tableData = convertMailingStatisticToFloatTableData(
    mailingStatistics,
    showMessageEmailNotRegisterMessage
  );

  const deleteMailing = () => {
    const result = deleteMailingStore(mailingDeletedId);
    setMailingDeletedId(undefined);
    setIsDeletePopupShown(false);

    !result && setIsErrorMessageShown(true);
  };

  const codesToHide = uiSwitchStore.getCodesToHide();
  const isNotMultipleCreatingAllowed = hasAccess({
    codesToHide,
    elementCode: 'UnlimitedStartEmail',
  });

  const start = async (id: any) => {
    const check = mailingStatistics.filter(v => v.status === 'STARTED')
      .length;

    if (check > 0 && isNotMultipleCreatingAllowed) {
      setErrorMessage(multipleRunDisabledError);
      setIsErrorMessageShown(true);

      return;
    }

    const result = await startMailingStore(id);
    !result && setIsErrorMessageShown(true);
  };

  const edit = async (id: any) => {
    setSelectedId(id);
    openConstructor();
  };

  const approveEmail = (id: any) => {
    setSelectedId(id);
    openConstructorForApproveEmail();
  };

  const pause = (id: any) => {
    const result = stop(id);
    !result && setIsErrorMessageShown(true);
  };

  const rename = (row: any) => {
    setMailingEditedId(row.id);
    setMailingEditedTitle(row.title);
    setIsDrawerVisible(true);
  };

  const deleteEmail = (id: string) => {
    setMailingDeletedId(id);
    setIsDeletePopupShown(true);
  };

  const contextMenu: Function = (row: IFloatTableRow) => {
    return [
      {
        icon: 'Subtract',
        name: 'Подтвердить email',
        callback: approveEmail,
        callbackArgument: row.id,
        visible: !getMailingInstanceById(row.id)?.template?.senderEmailAddress?.registered,
        disabled: !hasRightToModify,
      },
      {
        icon: 'PlayS',
        name: 'Запустить',
        callback: start,
        callbackArgument: row.id,
        visible: getMailingInstanceById(row.id)?.status !== 'STARTED',
        disabled: !hasRightToModify,
      },
      {
        icon: 'PauseS',
        name: 'Остановить',
        callback: pause,
        callbackArgument: row.id,
        visible: getMailingInstanceById(row.id)?.status === 'STARTED',
        disabled: !hasRightToModify,
      },
      {
        icon: 'Pencil',
        name: 'Редактировать',
        callback: edit,
        callbackArgument: row.id,
        disabled: !hasRightToModify,
      },
      {
        icon: 'Rename',
        name: 'Переименовать',
        callback: rename,
        callbackArgument: row,
        visible: true,
        disabled: !hasRightToModify,
      },
      {
        icon: 'Trash',
        name: 'Удалить',
        callback: deleteEmail,
        callbackArgument: row.id,
        color: 'red',
        disabled: !hasRightToModify,
      },
    ];
  };

  const save = () => {
    setIsDrawerVisible(false);
    const result = editTitle(mailingEditedTitle, mailingEditedId);
    !result && setIsErrorMessageShown(true);
    setIsDrawerVisible(false);
    setMailingEditedTitle('');
    setMailingEditedId(undefined);
  };

  const cancelSave = () => {
    setIsDrawerVisible(false);
    setMailingEditedTitle('');
    setMailingEditedId(undefined);
  };

  return (
    <div className={styles['mailing-list']}>
      <FloatTable
        rows={tableData}
        contextMenu={contextMenu}
        onRowClick={id => hasRightToModify && edit(id)}
      />
      <Drawer
        title={'Название рассылки'}
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        size={'x-small'}
        actions={(
          <>
            <Button onClick={save}>Сохранить</Button>
            <Button type="grey" onClick={cancelSave}>
              Отмена
            </Button>
          </>
        )}
      >
        <div className={styles['mailing-list__drawer-inner']}>
          <FormItem title={''} message={'Не больше 35 символов'}>
            <Input
              name="name"
              type="text"
              placeholder={'Название рассылки'}
              value={mailingEditedTitle}
              block
              maxLength={35}
              onChange={e => setMailingEditedTitle(e.currentTarget.value)}
            />
          </FormItem>
        </div>
      </Drawer>
      {isDeletePopupShown && (
        <Message
          visible={isDeletePopupShown}
          applyText={'Удалить'}
          cancelText={'Отмена'}
          onApply={deleteMailing}
          onClose={() => setIsDeletePopupShown(false)}
          title={'Удалить рассылку?'}
          textTop={'При продолжении рассылка прекратится и будет удалена.'}
          showIcon={false}
        />
      )}
      {isShowEmailNotRegisterMessage && (
        <Message
          visible={isShowEmailNotRegisterMessage}
          applyText={'Подтвердить email'}
          cancelText={'Отмена'}
          onApply={openConstructorForApproveEmail}
          onClose={() => setIsShowEmailNotRegisterMessage(false)}
          onExit={() => setIsShowEmailNotRegisterMessage(false)}
          title={'Email не подтвержден'}
          textTop={'Для запуска рассылки подтвердите email, с которого будет осуществляться отправка'}
          showIcon={false}
        />
      )}
      {!hasRightToModify && (
        <NoticeInfo
          visible={!hasRightToModify}
          title={'Услуга неактивна'}
          text={'Закончился период оплаты. Для управления рассылками необходимо подключить услугу.'}
          actionText={'Перейти в тарифы'}
          onActionClick={() => {
            history.push('/settings?tab=1');
          }}
        />
      )}
    </div>
  );
});

export default MailingStatistics;
