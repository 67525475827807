import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import styles from './style.module.css';

interface IRenameDialogProps {
  feedStatisticsActiveRow: FeedProStatistics | FeedApiStatistics | FeedLiteStatistics;
  isOpenRenameDialog: boolean;
  setIsOpenRenameDialog: (status: boolean) => void;
  renameFeed: (id: number, feedName: string) => Promise<void>;
}

const RenameDialog: React.FC<IRenameDialogProps> = observer(({
  feedStatisticsActiveRow,
  isOpenRenameDialog,
  setIsOpenRenameDialog,
  renameFeed,
}) => {

  const [feedName, setFeedName] = useState<string>(feedStatisticsActiveRow?.feedName);

  useEffect(() => {
    setFeedName(feedStatisticsActiveRow?.feedName);
  }, [feedStatisticsActiveRow?.feedName]);

  const closeDialog = () => {
    setIsOpenRenameDialog(false);
  };

  const saveNewFeedName = () => {
    renameFeed(feedStatisticsActiveRow.id, feedName);
    closeDialog();
  };

  return (
    <Drawer
      visible={isOpenRenameDialog}
      onClose={closeDialog}
      size={'x-small'}
      title={'Название ленты'}
    >
      <div className={styles.wrapper}>
        <FormItem message="Не больше 35 символов">
          <Input
            value={feedName}
            onChangeValue={setFeedName}
            maxLength={35}
            block
          />
        </FormItem>
      </div>

      <div className={styles.wrapper}>
        Это название отображается только в общем списке лент и будет видно
        только вам. Для заголовка ленты на сайте будет другое поле.
      </div>

      <DrawerFooter
        className={styles.footer}
        actions={(
          <>
            <Button onClick={saveNewFeedName}>Сохранить</Button>
            <Button type="text" onClick={closeDialog} style={{ marginLeft: 16 }} >
              Отмена
            </Button>
          </>
        )}
      />
    </Drawer>
  );
});

export default RenameDialog;
