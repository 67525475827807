import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLike, { ButtonLikeProps } from '~/components/ui/ButtonLike';
import './style.css';

type ButtonProps = ButtonLikeProps & {
  to?: string;
  tag?: 'a' | 'button';
  wrapperClassName?: string;
  ref?: React.Ref<HTMLDivElement>;
  target?: '_self' | '_blank';
};

const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const {
    disabled,
    to,
    wrapperClassName,
    target = '_self',
  } = props;

  const onlyButtonLikeProps: ButtonProps = {
    className: cx('button', props.className),
    withInteractions: true,
    ...props,
    ref,
  };

  delete onlyButtonLikeProps.to;

  const buttonLike = (
    <ButtonLike {...onlyButtonLikeProps} />
  );

  return to
    ? (
      <Link
        to={to}
        className={cx('button-link', wrapperClassName, { _disabled: disabled })}
        target={target}
      >
        {buttonLike}
      </Link>
    )
    : buttonLike
    ;
});

export default Button;
