import React from 'react';
import Message from '~/components/ui/Message';
import './style.css';

interface IMsgViewSiteVisits {
  visible: boolean;
  setShowMessage: (showMessage: boolean) => void;
}

export const MsgViewSiteVisits = (props: IMsgViewSiteVisits) => {

  const yandexMetrica = 'https://yandex.ru/support/partner2/statistics/metrika-visitors-statistics.html#metrika-visitors-statistics__metrika-reports-visits';
  const googleAnalytics = 'https://support.google.com/analytics/answer/6339208?hl=ru#zippy=%2Cсодержание';

  return (
    <Message
      visible={props.visible}
      title="Как посмотреть посещаемость сайта?"
      onClose={() => props.setShowMessage(false)}
      onExit={() => props.setShowMessage(false)}
      showLine={false}
      isFooter={false}
      className="view-site-visits"
      textTop={(
        <div className="msg-wrapper">
          <span className="_list-title">
            Через интерфейс&nbsp;<a href={yandexMetrica} target="_blank">Яндекс.Метрика</a>
          </span>
          <ul>
            <li>
              Нажмите кнопку <b>Отчеты</b> в левом меню, далее нажмите <b>Посещаемость.</b>
            </li>
            <li>Выберите период <b>Месяц.</b></li>
            <li>Обратите внимание на колонку <b>Визиты.</b> Это число необходимо узнать при выборе тарифа.</li>
          </ul>

          <span className="_list-title">
            Через интерфейс&nbsp;<a href={googleAnalytics} target="_blank">Google Аналитика</a>
          </span>
          <ul>
            <li>
              Нажмите кнопку <b>Обзор</b> в левом меню, далее нажмите <b>Посещения.</b>
            </li>
            <li>Выберите период <b>Месяц.</b></li>
            <li>Обратите внимание на колонку <b>Посещения.</b> Это число необходимо узнать при выборе тарифа.</li>
          </ul>
        </div>
      )}
    />
  );
};
