import React from 'react';
import Message from '~/components/ui/Message';

const CloseDialog: React.FC<{
  visible: boolean;
  onApply: () => void;
  onClose: () => void;
  isChanging: boolean;
}> = ({ visible, onApply, onClose, isChanging }) => {
  return (
    <Message
      className="feed-screen__message-box"
      showIcon={false}
      onClose={onClose}
      onApply={onApply}
      title={
        isChanging ? 'Прекратить редактирование?' : 'Прекратить создание правила?'
      }
      textTop={
        isChanging
          ? 'При продолжении операции изменения не сохранятся.'
          : 'При продолжении операции изменения и данные не сохранятся.'
      }
      visible={visible}
      applyText={
        isChanging ? 'Прекратить редактирование' : 'Прекратить создание'
      }
      cancelText={'Отмена'}
    />
  );
};

export default CloseDialog;
