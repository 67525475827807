import cx from 'classnames';
import React, { useState } from 'react';
import Icon, { IconProps } from '../Icon';
import Tooltip, { TooltipProps } from '../ui/Tooltip';
import './style.css';

type IconWithTooltipProps = {
  tooltipProps: TooltipProps;
  iconProps: IconProps;
  iconWrapperClassName?: string;
};

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({ tooltipProps, iconProps, iconWrapperClassName }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = (isVisible: boolean) => {
    setIsVisible(isVisible);
    tooltipProps.onVisibleChange?.(isVisible);
  };

  return (
    <Tooltip
      {...tooltipProps}
      overlayClassName={cx('icon-with-tooltip__tooltip-overlay', tooltipProps.overlayClassName)}
      onVisibleChange={handleVisibility}
    >
      <div className={cx('icon-with-tooltip__wrapper', iconWrapperClassName)}>
        <Icon
          {...iconProps}
          className={cx('icon-with-tooltip__icon', iconProps.className, { _mouseOver: isVisible })}
        />
      </div>
    </Tooltip>
  );
};

export default IconWithTooltip;

