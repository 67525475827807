import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Icon from '~/components/Icon';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import Message from '~/components/ui/Message';
import Table from '~/components/ui/Table';
import LoadingScreen from '~/screens/LoadingScreen';
import Screen from '~/screens/Screen';
import UserEditor from '~/screens/SettingsScreen/UsersComponent/UserEditor';
import { SettingsStore } from '~/stores/settingsStore';
import useStores from '~/utils/useStores';
import './style.css';

interface IUserDataResponse {
  id: number;
  login: string;
  accountName: string;
  status: 'ACTIVE';
}

const UsersComponent: React.FC = observer(() => {
  const { settingsStore } = useStores() as { settingsStore: SettingsStore };
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const users = await settingsStore.getUsers();
        setUserData(users);
      } catch (e) {
        console.log({ e });
      }
    })();
  }, [setUserData]);

  const addUser = async () => {
    try {
      const users = await settingsStore.addUser();
      users && setUserData(users);

    } catch (e) {
      console.log({ e });
    }
  };

  const deleteUser = async () => {
    try {
      const result = await settingsStore.deleteUser();
      setUserData(result);
    }  catch (e) {
      console.log({ e });
    }
  };

  const btnDisabled = () => {

    return settingsStore.userPhone.length < 11 ||
      (settingsStore.operationStatus === 'createUser' && settingsStore.userPassword.length < 12)
      || settingsStore.userName.length === 0;
  };

  const columns = [
    {
      key: 'accountName',
      title: 'Название учетной записи',
      dataIndex: 'accountName',
    },
    {
      key: 'login',
      title: 'Логин (номер телефона пользователя)',
      dataIndex: 'login',
    },
    {
      key: 'editUser',
      dataIndex: 'editUser',
      render: (_: any, rowData: IUserDataResponse) => (
        <Button
          type="lightgrey"
          onClick={(e) => {
            e.stopPropagation();
            settingsStore.setOperationStatus('editUser');
            settingsStore.setUserId(rowData.id);
            settingsStore.setUserName(rowData.accountName);
            settingsStore.setUserPhone(rowData.login);
            settingsStore.setUserPassword('');
            settingsStore.setToggleUserDrawer(true);
          }}
        >
          Редактировать
        </Button>
      ),
    },
    {
      key: 'deleteUser',
      dataIndex: 'deleteUser',
      render: (_: any, rowData: IUserDataResponse) => (
        <Button
          type="lightgrey"
          onClick={(e) => {
            e.stopPropagation();
            settingsStore.setUserId(rowData.id);
            settingsStore.setUserName(rowData.accountName);
            settingsStore.setUserPhone(rowData.login);
            settingsStore.setUserPassword('');
            settingsStore.setDeleteUserPopUp(true);
          }}
        >
          Удалить
        </Button>
      ),
    },
  ];

  const handleAddUser = () => {
    settingsStore.clearUserData();
    settingsStore.setToggleUserDrawer(true);
  };

  const handleClose = () => {
    settingsStore.setToggleUserDrawer(false);
    settingsStore.clearUserData();
  };

  const copyLoginAndPassword = async () => {
    await navigator.clipboard.writeText(
      `${settingsStore.userPhone}:${settingsStore.userPassword}`
    );

    settingsStore.setLoginPasswordCopied(true);
    setTimeout(() => settingsStore.setLoginPasswordCopied(false), 5000);
  };

  const closeCreateUserPopup = () => {
    settingsStore.clearUserData();
    settingsStore.setUserWasCreated(false);
  };

  if (settingsStore.loadingUsers) {
    return <LoadingScreen />;
  }

  return (
    <Screen noPadding className="user-screen">
      {settingsStore.loadingUsers && <LoadingScreen />}
      {!!userData?.length && (
        <div>
          <Table
            className="user-screen__users-table"
            columns={columns}
            rows={userData}
            rowKey={e => e.id.toString()}
            rowHover
          />
          <DrawerFooter
            className="feed-screen__footer"
            actions={(
              <Button icon="Plus" onClick={handleAddUser}>
                Добавить пользователя
              </Button>
            )}
          />
        </div>
      )}
      {!userData?.length && (
        <div className="user-screen__empty-list">
          <span className="_text">
            Список пользователей пока пуст.
            <br /> Добавьте первого пользователя
          </span>
          <Button icon="Plus" onClick={handleAddUser}>
            Добавить пользователя
          </Button>
        </div>
      )}
      <Drawer
        visible={settingsStore.toggleUserDrawer}
        title={settingsStore.userEditTitle}
        size="x-small"
        onClose={handleClose}
        actions={(
          <div className="user-screen__drawer-footer">
            <Button
              className="_button"
              onClick={addUser}
              disabled={btnDisabled()}
              block
            >
              {settingsStore.userEditButtonText}
            </Button>
            <Button type="text" onClick={handleClose}>
              Отмена
            </Button>
          </div>
        )}
      >
        <UserEditor />
      </Drawer>
      {settingsStore.deleteUserPopUp && (
        <Message
          className="user-screen__delete-user"
          visible={settingsStore.deleteUserPopUp}
          title="Удалить пользователя?"
          textTop="При удалении пользователь потеряет доступ в личный кабинет."
          applyText="Удалить"
          cancelText="Отмена"
          onApply={deleteUser}
          onClose={() => settingsStore.setDeleteUserPopUp(false)}
          onExit={() => settingsStore.setDeleteUserPopUp(false)}
        />
      )}
      {settingsStore.userWasCreated && (
        <Message
          className="user-screen__successfully-created"
          visible={settingsStore.userWasCreated}
          title={(
            <div>
              <Icon name={'CheckmarkXxl'} className={'_icon'} />
              <br />
              {settingsStore.getCompletedOperationStatusHeader}
            </div>
          )}
          textTop="Отправьте созданные логин и пароль своему сотруднику, чтобы он зашел в личный кабинет."
          applyText={{ text: settingsStore.withCopiedLoginPwdText, icon: settingsStore.withCopiedLoginPwdIcon }}
          onApply={copyLoginAndPassword}
          onClose={closeCreateUserPopup}
          onExit={closeCreateUserPopup}
        />
      )}
    </Screen>
  );
});

export default React.memo(UsersComponent);
