import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Input from '~/components/ui/Input';
import AddressBaseHint from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/AddressBase/AddressBaseHint';
import FileUpload from '~/screens/MassMailingScreen/components/MassMailingConstructor/MassMailingConstructorContent/TabParameters/AddressBase/FileUpload';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import styles from '../style.module.css';
import AddressBaseFooter from './AddressBaseFooter';

const ACCEPTABLE_FILE_TYPE = ['xlsx', 'xls'];

const AddressBase: React.FC = () => {
  const { mailingInstance } = massMailingStore;

  const [isManualMethod, setIsManualMethod] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [currentManualEmailData, setCurrentManualEmailData] = useState<string>('');
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<string>('');

  const convertAddressBase = (str: string): TMassMailingEmailDataData[] => {
    return str
      .split(' ')
      .map((email: string) => ({
        email,
      }))
      .filter(emailObject => emailObject.email !== '');
  };

  const isAcceptableFileType = (name: string) => {
    return ACCEPTABLE_FILE_TYPE.includes(name.split('.').pop().toLowerCase());
  };

  const changeAddressBase = (
    value:
      | string
      | {
        email: string;
      }[]
  ) => {
    mailingInstance.update({
      authorizationEmail: {
        ...mailingInstance?.get('authorizationEmail'),
        emailData: {
          status: 'MODIFIED',
          data: typeof value === 'string' ? convertAddressBase(value) : value,
        },
      },
    });
  };

  const handleChangeAddressBase = (
    value: string
  ) => {
    setCurrentManualEmailData(value);
    changeAddressBase(value);
    setIsFileUploaded(false);
    setFileName('');
  };

  const handleChangeFile = async (file: File) => {
    setFileName('');
    if (!file) return;
    if (!isAcceptableFileType(file.name)) {
      setFileUploadErrorMessage('Допустимые форматы .xls или .xlsx');

      return;
    }
    setFileUploadErrorMessage('');
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const jsonData = XLSX.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]]
    );
    const resultData = jsonData.map(({ email }: any) => ({
      email,
    }));
    if (!resultData.map((item: { email: any }) => item.email).join('')) {
      setFileUploadErrorMessage('Не удалось получить данные, проверте файл на соответствии шаблону');

      return;
    }
    setFileName(file.name);
    setIsFileUploaded(true);
    setCurrentManualEmailData('');
    changeAddressBase(resultData);
  };

  const uploadFile = async () => {
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.setAttribute('accept', 'xlsx, xls');
    inputFile.click();
    inputFile.onchange = (event) => {
      handleChangeFile((event.target as HTMLInputElement).files[0]);
    };
  };

  const deleteFile = () => {
    changeAddressBase(' ');
    setIsFileUploaded(false);
    setFileName('');
  };

  return (
    <div className={styles.tabSendSection}>
      <div className={styles.title_block}>
        Список получателей
        <AddressBaseHint isManualMethod={isManualMethod} />
      </div>
      <p>
        Загрузите Excel-файл с адресами получателей или перечислите адреса получателей через пробел
      </p>
      {isManualMethod && (
        <Input
          value={currentManualEmailData}
          placeholder="Вставьте список e-mail в это поле"
          block
          onChangeValue={handleChangeAddressBase}
          size={'large'}
        />
      )}
      {!isManualMethod && (
        <FileUpload
          isFileUploaded={isFileUploaded}
          fileName={fileName}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          handleChangeFile={handleChangeFile}
          fileUploadErrorMessage={fileUploadErrorMessage}
        />
      )}
      <AddressBaseFooter
        isManualMethod={isManualMethod}
        setIsManualMethod={setIsManualMethod}
      />
      {!isManualMethod && (
        <img src="assets/images/mailing/excelTemplateAddressBase.png" />
      )}
    </div>
  );
};

export default AddressBase;
