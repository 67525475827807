import React, { useEffect, useRef, useState } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import { getHint } from '~/components/ScenarioEditor/ScenarioEditorSteps';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import InputGroup from '~/components/ui/InputGroup';
import InputWrapper from '~/components/ui/InputWrapper';
import pluralize from '~/utils/pluralize';
import './style.css';

interface Props {
  step: IScenarioTemplateStepIdle | IScenarioTemplateStepTime;
  type: string;
  group: IScenarioTemplateStepGroupBase;
  updateSteps: (unit: IScenarioStepTimeUnit) => void;
  onChangeValue: (timeout: string) => void;
  groupIndex: number;
  stepIndex: number;
  addCurrentValidationStatus: (groupIndex: number, stepIndex: number, status: boolean) => void;
}

const TimeStep: React.FC<Props> = React.memo(({
  step,
  type,
  group,
  updateSteps,
  onChangeValue,
  groupIndex,
  stepIndex,
  addCurrentValidationStatus,
}) => {

  const inputRef = useRef(null);
  const [failure, toggleFailure] = useState(false);

  const validation = (timeout: string): boolean => {
    // TODO - можно добавить более сложную валидацию
    const numTimeout = Number(timeout);
    if (!isNaN(parseFloat(timeout)) && !isNaN(numTimeout - 0) && timeout !== '' && timeout !== '0') return true;

    return false;
  };

  const handleChangeValue = (timeout: string) => {
    const resultValidation = validation(timeout);
    toggleFailure(!resultValidation);
    addCurrentValidationStatus(groupIndex, stepIndex, resultValidation);
    onChangeValue(timeout);
  };

  useEffect(() => {
    handleChangeValue(inputRef.current.value);
  }, []);

  return (
    <>
      <InputWrapper
        className="scenario-editor-step__input"
        elemAfter={
          getHint(step.ui.caption, 'disabled-input', step.operation, type, group.ui.caption) ? (
            <IconWithTooltip
              tooltipProps={{
                placement: 'topRight',
                title:
                  getHint(step.ui.caption, 'disabled-input', step.operation, type, group.ui.caption),
                overlayClassName: 'scenario-editor-step__tooltip-overlay',
              }}
              iconProps={{
                name: 'Question',
                className: 'scenario-editor-step__tooltip-icon',
              }}
            />
          ) : undefined}
        disabled
      >
        {step.ui.caption}
      </InputWrapper>
      <div className="scenario-editor-step__separator" />
      <FormItem
        className="scenario-editor-steps-time-step__input"
        failure={failure}
        message={failure ? 'Обязательное' : ''}
        noReservedPlaceForMessage
      >
        <InputGroup
          className="scenario-editor-step__group"
          size="large"
        >
          <Input
            ref={inputRef}
            failure={failure}
            value={step.timeout ? step.timeout.toString().replace(/[^0-9]/g, '') : ''}
            maxLength={3}
            style={{ width: 80 }}
            onChangeValue={handleChangeValue}
          />
          <InputDropdown
            className="time-step__dropdown"
            selectedKey={step.timeUnitType}
            items={step.availableTimeoutUnits.map(
              (unit: IScenarioStepTimeUnit) => ({
                key: unit,
                value:
                  unit === 'MINUTE'
                    ? pluralize(
                      step.timeout,
                      'Минута',
                      'Минуты',
                      'Минут'
                    )
                    : pluralize(
                      step.timeout,
                      'Секунда',
                      'Секунды',
                      'Секунд'
                    ),
              })
            )}
            onChange={updateSteps}
            theme="light"
            overlayPlacement="bottom"
          />
          {getHint(step.ui.caption, 'input', step.operation, type, group.ui.caption) ? (
            <IconWithTooltip
              tooltipProps={{
                placement: 'topRight',
                title: getHint(step.ui.caption, 'input', step.operation, type, group.ui.caption),
                overlayClassName: 'scenario-editor-step__tooltip-overlay',
              }}
              iconProps={{
                name: 'Question',
                className: 'scenario-editor-step__tooltip-icon',
              }}
              iconWrapperClassName="scenario-editor-step__tooltip-icon-wrapper"
            />
          ) : <></>}
        </InputGroup>
      </FormItem>
      <div className="scenario-editor-step__spacer" />
    </>
  );
});

export default TimeStep;