import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

interface MenuItemProps {
  active?: boolean;
  disabled?: boolean;
  withDot?: boolean;
  withArrow?: boolean;
  withCheckmark?: boolean;
  onMouseEnter?: () => void;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  active,
  disabled,
  onClick,
  onMouseEnter,
  withDot,
  withArrow,
  withCheckmark,
}) => {
  return (
    <div
      className={cx('menu-item', {
        '_disabled': disabled,
        '_active': active,
        '_with-dot': withDot,
        '_with-checkmark': withCheckmark,
        '_with-arrow': withArrow,
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Icon name="CheckmarkS" className="menu-item__icon-left" />
      {children}
      <Icon name="ArrowMRight" className="menu-item__icon-right" />
    </div>
  );
};

export default MenuItem;
