import React, { useCallback } from 'react';
import IconWithTooltip from '~/components/IconWithTooltip';
import getListOptions from '~/components/generic/DeviceSelectorRadioButton/getListOptions';
import FormItem from '~/components/ui/FormItem';
import RadioButton from '~/components/ui/RadioButton';
import './style.css';

const DeviceSelectorRadioButton: React.FC<DeviceSelectorRadioButtonProps> = ({
  currentDevice = 'mobile',
  onChange,
  title,
  message,
  tooltipForTitle,
  tooltipsForItems,
  infoLabelForItems = [],
  reverseOrderOfItems = false,
  listOptions = getListOptions(currentDevice, tooltipsForItems),
  disabled = [],
  className = '',
  colorSelectedDescription = false,
}) => {
  const handleChange = useCallback(
    (key: any) => {
      onChange?.(key);
    },
    [onChange]
  );

  if (reverseOrderOfItems) listOptions.reverse();

  return (
    <div className={`device-selector-radio-button__box ${className ? className : ''}`}>
      <div className="device-selector-radio-button__heder">
        <FormItem
          title={title}
          message={message}
          hint={tooltipForTitle}
          noHTMLLabel
          noReservedPlaceForMessage
        />
      </div>
      <div className="device-selector-radio-button__container">
        {listOptions.map((option, index) => {
          const isDisabled = disabled?.includes(option.device);

          return (
            <div key={`${option.device}${index}`}>
              <div
                className="device-selector-radio-button__wrapper-item"
                onClick={isDisabled ? null : () => handleChange(option.device)}
              >
                <div className="device-selector-radio-button__item">
                  <RadioButton
                    size={'medium'}
                    checked={currentDevice === option.device}
                    onCheck={() => true}
                    label
                    disabled={isDisabled}
                  />
                </div>
                <div className={`device-selector-radio-button__item-label ${isDisabled ? '__is_disabled' : ''}`}>
                  <div>
                    {option.label}
                    {option.tooltip && (
                      <IconWithTooltip
                        tooltipProps={{
                          placement: 'bottomLeft',
                          title: option.tooltip,
                          width: '340px',
                          overlayClassName: 'feed-editor-config-link__tooltip',
                        }}
                        iconProps={{
                          name: 'Question',
                        }}
                        iconWrapperClassName="device-selector-radio-button__item-tooltip"
                      />
                    )}
                  </div>
                </div>
                <div className="device-selector-radio-button__element-after">
                  {option.icon}
                </div>
              </div>
              {infoLabelForItems[index] ? (
                <div 
                  className={`device-selector-radio-button__info-label ${
                    (colorSelectedDescription &&
                      currentDevice === option.device) ||
                      (currentDevice === option.device &&
                        infoLabelForItems[index]?.actionReaction) ? '__success' : ''
                  }`}
                >
                  {infoLabelForItems[index]?.text}
                </div>
              ) : ''}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeviceSelectorRadioButton;
