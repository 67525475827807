import Model from '~/models/Model';
import { IQuiz } from '~/screens/QuizScreen/types';

interface ITriggerData {
  timeoutSecond: 3 | 5| 10;
  urls: string[];
}

interface IQuizModel extends IQuiz {
  triggerData: ITriggerData;
}

export default class QuizModel extends Model<IQuizModel> {

  getConfiguration = (field: string) => {

    // @ts-ignore
    return this.get('configuration')[field] || '';
  }

  setTriggerData = (field: string, value: number | string | string[]) => {
    const triggerData = { ...this.get('triggerData') };
    // @ts-ignore
    triggerData[field] = value || '';

    this.update({ triggerData });
  }

  getTriggerData = (field: string) => {
    const triggerData = { ...this.get('triggerData') };

    // @ts-ignore
    return triggerData[field];
  }

  setConfiguration = (field: string, value: string) => {
    const configuration = { ...this.get('configuration') };
    // @ts-ignore
    configuration[field] = value || '';

    console.log(configuration, field, value);

    this.update({ configuration, steps: this.get('steps') });
  }

  setValue = (
    step: number,
    field: string,
    newValue: string | any[] | boolean
  ) => {
    const steps = [...this.get('steps')];
    const stepValues = steps[step];
    // @ts-ignore
    stepValues[field] = newValue;
    steps[step] = stepValues;

    this.update({ steps });
  }

  getValue = (step: number, field: string) => {
    const stepValues = this.get('steps')[step];

    // @ts-ignore
    return stepValues[field];
  }

  deleteStep = (step: number) => {
    const steps = [...this.get('steps')];
    steps.splice(step, 1);
    this.update({ steps });
  }

  // duplicateStep = (step: number) => {
  //   const steps = [...this.get('steps')];
  //   const stepToDuplicate = { ...steps[step] };
  //   steps.splice(step, 0, stepToDuplicate);
  //   this.update({ steps: [...steps] });
  // }

}
