import './style.css';

import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import RadioGroupWrapper from '~/components/notification-editor/RadioGroupWrapper';
import FormItem from '~/components/ui/FormItem';

interface NotificationOrientationProps {
  isHorizontal?: boolean;
  onChange?: (isHorizontal: boolean) => void;
  hint?: React.ReactNode;
}

type Orientation = 'vertical' | 'horizontal';

const NotificationOrientation: React.FC<NotificationOrientationProps> = ({ onChange, isHorizontal = false, hint }) => {
  const selectedIcon = <Icon name="CheckmarkS" className="notification-orientation__selected-icon" />;
  const handleChange = useCallback((key: Orientation) => {
    onChange?.(key === 'horizontal');
  }, [isHorizontal, onChange]);

  return (
    <div className="notification-orientation">
      <FormItem
        title="Ориентация виджета"
        hint={hint}
        noHTMLLabel
        noReservedPlaceForMessage
      >
        <RadioGroupWrapper
          className="notification-orientation__radiogroup"
          onChange={handleChange}
          items={[
            {
              elemBefore: isHorizontal ? '' : selectedIcon,
              item: <div key={'vertical'}>Вертикальная</div>,
              elemAfter: <Icon className="_vertical" name="RectVertical" />,
            },
            {
              elemBefore: isHorizontal ? selectedIcon : '',
              item: <div key={'horizontal'}>Горизонтальная</div>,
              elemAfter: <Icon className="_horizontal" name="RectHorizontal" />,
            },
          ]}
        />
      </FormItem>
    </div>
  );
};


export default NotificationOrientation;
