import { observer } from 'mobx-react';
import React from 'react';
import TypeSelectionCard from '~/components/generic/TypeSelectionCard';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const TabSelectType: React.FC = observer((): any => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const { mailingInstance } = mailingStore;

  const cardsData = [
    {
      id: '1',
      title: 'Напоминание о товарах в корзине',
      description: `Рассылка поможет вернуть клиентов, которые положили товары в корзину, но не оформили заказ. Например, зашли просто посмотреть или отвлеклись на этапе покупки. Рассылка напомнит пользователям об оставленной корзине — автоматически сформирует и отправит письма с товарами, которые их заинтересовали.`,
      image: 'assets/images/mailing/abandoned-cart.png',
      type: 'EmailDistributionTemplate',
      isChosen: mailingInstance.get('type') === 'EmailDistributionTemplate',
    },
  ];

  const handleClick = (type: TMailType) => {
    !mailingInstance.get('type') ||
    (mailingInstance.get('type') && mailingInstance.get('type') !== type)
      ? mailingInstance.update({ type })
      : mailingInstance.update({ type: undefined });
  };

  return cardsData.map((e) => {
    return (
      <div className={styles.wrapper} key={e.id}>
        <TypeSelectionCard
          id={e.id}
          title={e.title}
          description={e.description}
          image={e.image}
          isChosen={e.isChosen}
          onClick={() => handleClick(e.type as TMailType)}
          className={styles.mailingCartSelectorWrapper}
        />
      </div>
      
    );
  });
});

export default React.memo(TabSelectType);
