import { IFeedConfiguration } from 'feed';
import { action, computed, observable, IObservableArray } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Feeds } from '~/api';
import { useAppSettings } from '~/customHooks/useAppSettings';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import FeedLiteApi from '~/screens/FeedLiteScreen/api/feedLiteApi';
import { FEED_LITE_DEF_CONFIGURATION, FEED_LITE_DEF_MOBILE_CONFIGURATION } from '~/screens/FeedLiteScreen/constants';
import FeedLiteModel from '~/screens/FeedLiteScreen/model/FeedLiteModel';
import ConstructorStore, { IConstructorStore } from '~/stores/constructorStore';
import { FeedStore } from '~/stores/feedStore';

export enum CheckedCountPage {
  ONE = 'ONE',
  MULTIPLE = 'MULTIPLE',
}

export class FeedLiteStore extends FeedStore {
  @observable constructorStore: IConstructorStore<EditorSteps>;
  @observable isConstructorLiteOpen = false;
  @observable isMessageOpen = false;
  @observable isIFrameAccessible = false;
  @observable isPlacementPageInvalid = false;
  @observable isConfigLinkInvalid = false;
  @observable scroll: ScrollEnum = 'NONE';
  @observable selectedFeedId: number;
  @observable emptyFeed = new FeedLiteModel(this, {
    urlPattern: '',
    displayConditions: {
      operation: 'CONTAINS',
      url: '',
    },
    recommendationList: [],
    configuration: { ...FEED_LITE_DEF_MOBILE_CONFIGURATION },
  });
  @observable hasClickedOnInput = false;
  @observable isTariffInfoOpen = false;
  @observable isRechoosingSelector = false;
  @observable isRechoosingSelectorMobile = false;
  @observable feed: FeedLiteModel;
  @observable device: Device = 'mobile';
  @observable isFetchingFeed = false;
  @observable isPausingFeed = false;
  @observable isActivatingFeed = false;
  @observable fetchedSelector: string | undefined;
  @observable fetchedSelectorMobile: string | undefined;
  @observable isDeviceModalOpen = true;
  @observable lastSavedConfiguration: IFeedConfiguration;
  @observable nameSelectedFeed: string = 'false';
  @observable feedLiteStatistics: FeedLiteStatistics[];
  @observable isFetchingLiteStatistics = true;
  @observable fetchingLiteStatisticsError: boolean = false;
  @observable feedProcessId: string = '';
  @observable products: IObservableArray<FeedConstructorModel> =
    [] as IObservableArray<FeedConstructorModel>;
  @observable product: FeedConstructorModel = {} as FeedConstructorModel;
  @observable isFetchingUploadImage: boolean;
  @observable isLoadingDeleteImage: boolean;
  @observable image: IImage;
  @observable updateProductId: string = null;
  @observable isOpenDrawen: IDrawenMode = {
    create: false,
    update: false,
  };
  @observable checkedCountPage = CheckedCountPage.ONE;
  @observable isDisplayConditionsInvalid = false;
  @observable feedLiteCurrentStatistic: FeedLiteStatistics;
  @observable isCrossedPrice: boolean;
  @observable isFeedDuplicate: boolean = false;

  constructor() {
    super();
    this.constructorStore = new ConstructorStore([
      'StepFeedProduct',
      'StepLinkPages',
      'StepManual',
      'StepSelector',
      'StepDesign',
    ]);
  }

  @action
  setIsCrossedPrice = (status: boolean) => {
    this.isCrossedPrice = status;
  }

  @action
  setIsFeedDuplicate = (status: boolean) => {
    this.isFeedDuplicate = status;
  }

  @action
  setFeedLiteCurrentStatistic = (feedStat: FeedLiteStatistics) => {
    this.feedLiteCurrentStatistic = feedStat;
  }

  @action
  setIsLinkPageDisplayConditionsInvalid = (isInvalid: boolean) => {
    this.isDisplayConditionsInvalid = isInvalid;
  }

  @action
  setCheckedCountPage = (value: CheckedCountPage) => {
    this.checkedCountPage = value;
  }

  @action
  setIsOpenDrawen = (mode: IDrawenMode, imageUuid: string = null) => {
    this.setIdUpdatedProduct(imageUuid);
    this.isOpenDrawen = mode;
  }

  @action
  setFeedLiteStatistics = (statistics: FeedLiteStatistics[]) => {
    this.feedLiteStatistics = statistics;
    this.setFeedAmount(statistics?.length ?? 0);
  }

  @action
  fetchLiteStatistics = async () => {
    this.fetchingLiteStatisticsError = false;
    try {
      useAppSettings()
        .getAppSetting('MAX_FEED_COUNT_LITE')
        .then(settingsValue => 
          this.setFeedLimit(settingsValue as number)
        );
      const response = await FeedLiteApi.getStatisticsLite();
      this.setFeedLiteStatistics(response?.data?.payload);
      this.isFetchingLiteStatistics = false;
    } catch (e) {
      this.fetchingLiteStatisticsError = true;
      throw e;
    } finally {
      this.isFetchingLiteStatistics = false;
    }
  }

  @action
  createFeed = async (
    productProps: IProduct,
    uuid: string,
    picture: string | ArrayBuffer
  ) => {
    try {
      await this.uploadImage(uuid, picture);
      productProps.imageUuid = this.image.imageUuid;
      productProps.imgLink = `${this.image.imageUrl}`;
      const result = new FeedConstructorModel(this, productProps);
      this.products.splice(result.get('order') - 1, 0, result);
    } catch (e) {
      throw e;
    }
  }

  @action
  updateProduct = async (updateProduct: IProduct, picture: string) => {
    try {
      // Если режим редактирования и картинка не загружена
      const productIndexAtId = this.products.findIndex(
        pet => pet.get('imageUuid') === this.updateProductId
      );
      if (picture !== null) {
        await FeedLiteApi.deleteImage(this.feedProcessId, this.products[productIndexAtId].get('imageUuid'));
        await this.uploadImage(this.feedProcessId, picture);
        updateProduct.imageUuid = this.image.imageUuid;
        updateProduct.imgLink = `${this.image.imageUrl}`;
      } else {
        updateProduct.imageUuid =
          this.products[productIndexAtId].get('imageUuid');
        updateProduct.imgLink = this.products[productIndexAtId].get('imgLink');
      }
      const result = new FeedConstructorModel(this, updateProduct);
      this.products.splice(productIndexAtId, 1);
      this.products.splice(updateProduct.order - 1, 0, result);
      this.updatePosition();
    } catch (e) {
      throw e;
    }
  }

  @action
  updatePosition = () => {
    this.products.forEach((item: FeedConstructorModel, index: number) => {
      item.update({ order: index + 1 });
    });
  }

  @action
  uploadImage = async (
    feedProcessId: string,
    imageB64: string | ArrayBuffer
  ) => {
    this.isFetchingUploadImage = true;

    try {
      const response = await FeedLiteApi.uploadImage(feedProcessId, imageB64);
      this.image = response.data.payload[0];
      this.feedProcessId = this.image?.feedProcessId;
    } catch (e) {
      throw e;
    } finally {
      this.isFetchingUploadImage = false;
    }
  }

  @action
  setIdUpdatedProduct = (productID: string) => {
    this.updateProductId = productID;
  }

  @action
  deleteProduct = async (productID: string) => {
    this.isLoadingDeleteImage = true;

    try {
      await FeedLiteApi.deleteImage(this.feedProcessId, productID);
      const petIndexAtId = this.products.findIndex(
        (product: FeedConstructorModel) =>
          product.get('imageUuid') === productID
      );
      if (petIndexAtId > -1) {
        this.products.splice(petIndexAtId, 1);
        this.updatePosition();
      }
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingDeleteImage = false;
    }
  }

  @action
  pauseFeed = async (id: number) => {
    this.isPausingFeed = true;

    try {
      await Feeds.stop(id);
      this.fetchLiteStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.isPausingFeed = false;
    }
  }

  @action
  removeFeed = async (id: number) => {
    try {
      await Feeds.delete(id);
      this.fetchLiteStatistics();
    } catch (e) {
      throw e;
    }
  }

  @action
  fetchFeedLiteById = async (id: number, copy: boolean = false) => {
    this.isFetchingFeed = true;
    const arr: FeedConstructorModel[] = [];
    try {
      const response = await FeedLiteApi.getFeedLiteById(id);
      const payload = response.data.payload[0];
      payload.recommendationList.forEach((item) => {
        const obj = new FeedConstructorModel(this, item);
        arr.push(obj);
      });
      if (copy) delete payload.id;
      this.feed = new FeedLiteModel(this, payload);
      this.products.replace(arr);
      this.checkedCountPage = payload.displayConditions.operation === 'EQUAL' 
        ? CheckedCountPage.ONE 
        : CheckedCountPage.MULTIPLE; 
    } catch (e) {
      throw e;
    } finally {
      this.isFetchingFeed = false;
    }
  }

  @action
  playFeed = async (id: number) => {
    this.isActivatingFeed = true;

    try {
      await Feeds.start(id);
      this.fetchLiteStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.isActivatingFeed = false;
    }
  }

  @action
  setFetchedSelector = (fetchedSelector?: string) => {
    this.fetchedSelector = fetchedSelector;
  }

  @action
  setFetchedSelectorMobile = (fetchedSelectorMobile?: string) => {
    this.fetchedSelectorMobile = fetchedSelectorMobile;
  }

  @action
  fetchSelector = async (id: string) => {
    try {
      const response = await Feeds.getSelector(id);
      const currentFeed = this.feed || this.emptyFeed;

      if (this.device === 'desktop') {
        this.fetchedSelector = response.data.payload[0].selector;
        currentFeed.setSelector(response.data.payload[0].selector);
      } else {
        this.fetchedSelectorMobile = response.data.payload[0].selectorMobile;
        currentFeed.setSelectorMobile(response.data.payload[0].selectorMobile);
      }
    } catch (e) {
      throw e;
    }
  }

  @action
  setSelectorManual = (selector: string) => {
    try {
      const currentFeed = this.feed || this.emptyFeed;

      if (this.device === 'desktop') {
        this.fetchedSelector = selector;
        currentFeed.setSelector(selector);
      } else {
        this.fetchedSelectorMobile = selector;
        currentFeed.setSelectorMobile(selector);
      }
    } catch (e) {
      throw e;
    }
  }

  @action
  setDevice = (device: Device) => {
    this.device = device;
    if (!this.currentFeed?.get('id') && !this.isFeedDuplicate) {
      this.currentFeed?.update({configuration: device === 'mobile' 
        ? {
          ...this.currentFeed.get('configuration'),
          feedTitleFontSize: 16,
          cardTitleFontSize: 10,
          cardDescriptionFontSize: 10,
          cardPriceFontSize: 14,
          cardCrossedPriceFontSize: 10,
          buttonFontSize: 10,
          labelFontSize: 10,
        } : {
          ...this.currentFeed.get('configuration'),
          feedTitleFontSize: 20,
          cardTitleFontSize: 16,
          cardDescriptionFontSize: 14,
          cardPriceFontSize: 22,
          cardCrossedPriceFontSize: 20,
          buttonFontSize: 16,
          labelFontSize: 13,
        },
      });
    }
  }

  @action
  setIsRechoosingSelector = (status: boolean) => {
    this.isRechoosingSelector = status;
  }

  @action
  setIsRechoosingSelectorMobile = (status: boolean) => {
    this.isRechoosingSelectorMobile = status;
  }

  @action
  setHasClickedOnInput = (status: boolean) => {
    this.hasClickedOnInput = status;
  }

  @action
  setIsTariffInfoOpen = (isOpen: boolean) => {
    this.isTariffInfoOpen = isOpen;
  }

  @action
  setSelectedFeedId = (id: number) => {
    this.selectedFeedId = id;
  }

  @action
  setNameSelectedFeed = (feedName: string) => {
    this.nameSelectedFeed = feedName;
  }

  @action
  setScroll = (position: ScrollEnum) => {
    this.scroll = position;
  }

  @action
  setIsIFrameAccessible = (isAccessible: boolean) => {
    this.isIFrameAccessible = isAccessible;
  }

  @action
  setIsPlacementPageInvalid = (isInvalid: boolean) => {
    this.isPlacementPageInvalid = isInvalid;
  }

  @action
  setIsConfigLinkInvalid = (isInvalid: boolean) => {
    this.isConfigLinkInvalid = isInvalid;
  }

  @action
  setFeedProcessId = () => {
    this.feedProcessId = uuidv4();
  }

  @action
  setIsConstructorLiteOpen = (isOpen: boolean) => {
    this.setFeedProcessId();
    this.isConstructorLiteOpen = isOpen;
  }

  @action
  setIsMessageOpen = (isOpen: boolean) => {
    this.isMessageOpen = isOpen;
  }

  @action
  setIsDeviceModalOpen = (isOpen: boolean) => {
    this.isDeviceModalOpen = isOpen;
  }

  @action
  reset = () => {
    this.isConstructorLiteOpen = false;
    this.isMessageOpen = false;
    this.constructorStore.currentStep = 'StepFeedProduct';
    this.isIFrameAccessible = true;
    this.isPlacementPageInvalid = false;
    this.isConfigLinkInvalid = false;
    this.emptyFeed = new FeedLiteModel(this, {
      urlPattern: '',
      displayConditions: { operation: 'CONTAINS', url: '' },
      configuration: this.device === 'mobile'
        ? { ...FEED_LITE_DEF_MOBILE_CONFIGURATION }
        : { ...FEED_LITE_DEF_CONFIGURATION },
    });
    this.hasClickedOnInput = false;
    this.isRechoosingSelector = false;
    this.isRechoosingSelectorMobile = false;
    this.feed = undefined;
    this.device = 'mobile';
    this.isDeviceModalOpen = true;
    this.fetchedSelector = undefined;
    this.fetchedSelectorMobile = undefined;
    this.feedProcessId = '';
    this.products.clear();
    this.checkedCountPage = CheckedCountPage.ONE;
    this.setIsFeedDuplicate(false);
  }

  @action
  setLastSavedConfiguration = (configuration: IFeedConfiguration) => {
    this.lastSavedConfiguration = { ...configuration };
  }

  @action
  removeUnfinishedFeedLite = async () => {
    if (this.feedProcessId && !this.feed?.get('id')) {
      try {
        await FeedLiteApi.removeLite(this.feedProcessId);
      } catch (e) {
        throw e;
      }
    }
  }

  /**
   * Переименование ленты
   */
  @action
  renameFeed = async (id: number, feedName: string) => {
    try {
      await Feeds.rename(id, feedName);
      await this.fetchLiteStatistics();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Обработка акшен-кнопок и суппорт-кнопок
   * @param avtionType
   * @param feedStatistics
   */
  @action
  onClickActionButton = (actionType: FeedActionButtonType, feedStatistics: FeedLiteStatistics) => {
    this.setFeedLiteCurrentStatistic(feedStatistics);
    switch (actionType) {
      // Запуск ленты
      case 'start': {
        this.playFeed(feedStatistics.id);
        break;
      }
      // Остановка ленты
      case 'stop': {
        const stopFeed = () => {
          this.setIsStoppingDialogOpen(false);
          this.pauseFeed(feedStatistics.id);
        };
        this.isStoppingDialogOpen
          ? stopFeed()
          : this.setIsStoppingDialogOpen(true);
        break;
      }
      // Редактирование ленты
      case 'edit': {
        const editFeed = async (feedId: number) => {
          await this.fetchFeedLiteById(feedId);
          this.constructorStore.changeCurrentStep('StepFeedProduct');
          this.setIsConstructorLiteOpen(true);
        };
        const stopAndEditFeed = async (feedId: number) => {
          this.setIsBeforeEditOpen(false);
          await this.pauseFeed(feedId);
          editFeed(feedId);
        };
        switch (feedStatistics.status) {
          case 'WORK':
            this.isBeforeEditOpen
              ? stopAndEditFeed(feedStatistics.id)
              : this.setIsBeforeEditOpen(true);
            break;
          default:
            editFeed(feedStatistics.id);
            break;
        }
        break;
      }
      // Информация о ленте
      case 'info': {
        const infoFeed = async (feedId: number) => {
          await this.fetchFeedLiteById(feedId);
          this.setIsInfoOpen(true);
        };
        infoFeed(feedStatistics.id);
        break;
      }
      // Копирование ленты
      case 'copy': {
        const copyFeedPro = async (feedId: number) => {
          await this.fetchFeedLiteById(feedId, true);
          this.setIsFeedDuplicate(true);
          this.setIsConstructorLiteOpen(true);
        };
        this.hasPermissionToCreateFeed
          ? copyFeedPro(feedStatistics.id)
          : this.setIsShowExceedingLimitMessage(true);
        break;
      }
      // Удаление ленты
      case 'delete': {
        const deleteFeed = (feedId: number) => {
          this.removeFeed(feedId);
          this.setIsRemoveDialogOpen(false);
          this.reset();
        };
        this.isRemoveDialogOpen
          ? deleteFeed(feedStatistics.id)
          : this.setIsRemoveDialogOpen(true);
        break;
      }
      // Переименование ленты
      case 'rename': {
        this.setIsOpenRenameDialog(true);
        break;
      }
    }
  }

  @computed
  get feedConfiguration() {
    const currentFeed = this.feed || this.emptyFeed;

    return currentFeed.get('configuration');
  }

  @computed
  get currentFeed() {
    return this.feed || this.emptyFeed;
  }

  @computed
  get getProducts() {
    return this.products && this.products;
  }

  @computed
  get getLength() {
    return this.products?.length || 0;
  }

  @computed
  get getProduct() {
    return (
      this.product &&
      this.products.find((product) => {
        return product.get('imageUuid') === this.updateProductId;
      })
    );
  }

  @computed
  get computedPosition() {
    if (this.products.length === 0) return 1;
    
    return this.products.length + 1;
  }
}

export default new FeedLiteStore();
