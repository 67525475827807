import React from 'react';
import Icon from '~/components/Icon';
import { EAvailableServices } from '~/stores/serviceStore';
import styles from './style.module.css';

interface Props {
  title: string;
  description: string;
  image: string;
  checked: boolean;
  availableService: EAvailableServices;
  serviceSelection: any;
  setServiceSelection: (checked: EAvailableServices) => void;
}

const ServiceTypeCard: React.FC<Props> = ({
  title,
  description,
  image,
  checked,
  availableService,
  serviceSelection,
  setServiceSelection,
}) => {
  return (
    <div
      className={`${styles.card} ${checked ? styles.choice : ''}`}
      onClick={() => {
        setServiceSelection({
          ...serviceSelection,
          [availableService]: !checked,
        });
      }}
    >
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <div className={styles.icon_wrapper}>
          <Icon
            name={checked ? 'CheckedCircle' : 'EmptyCircle'}
            className={styles.icon}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ServiceTypeCard);
