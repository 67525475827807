import cx from 'classnames';
import React, { useCallback } from 'react';
import Icon from '~/components/Icon';
import {
  buttonFontSizes,
  couponFontSizes,
  couponMobileFontSizes,
  fonts,
  fontWeights,
  labelFontSizes,
  messageFontSizes,
  messageMobileFontSizes,
  titleFontSizes,
  titleMobileFontSizes,
} from '~/components/Notification';
import ColorPicker, { ColorData } from '~/components/notification-editor/ColorPicker';
import FormItem from '~/components/ui/FormItem';
import InputDropdown, { InputDropdownItem } from '~/components/ui/InputDropdown';
import TabSwitch from '~/components/ui/TabSwitch';
import { textDecorationBooleanToString, textFormatBooleanToString, TextDecoration, TextFormat } from '~/utils/fontSettingsUtils';
import './style.css';

interface IFontsData {
  fontFamily: INotificationFont;
  fontWeights: INotificationFontWeight[];
}

const FONTS_DEFAULT_DATA: IFontsData[] = [
  {
    fontFamily: 'Inter',
    fontWeights,
  },
  {
    fontFamily: 'Open Sans',
    fontWeights: ['Light', 'Normal', 'Medium', 'Semi-Bold', 'Bold', 'Extra Bold'],
  },
  {
    fontFamily: 'PT Sans',
    fontWeights: ['Normal', 'Bold'],
  },
  {
    fontFamily: 'PT Serif',
    fontWeights: ['Normal', 'Bold'],
  },
  {
    fontFamily: 'Roboto',
    fontWeights: ['Thin', 'Light', 'Normal', 'Medium', 'Bold', 'Black'],
  },
  {
    fontFamily: 'Roboto Slab',
    fontWeights,
  },
  {
    fontFamily: 'Roboto Condensed',
    fontWeights: ['Light', 'Normal', 'Bold'],
  },
  {
    fontFamily: 'Oswald',
    fontWeights: ['Extra Light', 'Light', 'Normal', 'Medium', 'Semi-Bold', 'Bold'],
  },
  {
    fontFamily: 'Lato',
    fontWeights: ['Thin', 'Light', 'Normal', 'Bold', 'Black'],
  },
  {
    fontFamily: 'Ubuntu',
    fontWeights: ['Light', 'Normal', 'Medium', 'Bold'],
  },
  {
    fontFamily: 'Montserrat',
    fontWeights,
  },
  {
    fontFamily: 'Exo 2',
    fontWeights,
  },
  {
    fontFamily: 'Playfair Display',
    fontWeights: ['Normal', 'Medium', 'Semi-Bold', 'Bold', 'Extra Bold', 'Black'],
  },
  {
    fontFamily: 'Pacifico',
    fontWeights: ['Normal'],
  },
  {
    fontFamily: 'Raleway',
    fontWeights,
  },
  {
    fontFamily: 'Fira Sans',
    fontWeights,
  },
  {
    fontFamily: 'Philosopher',
    fontWeights: ['Normal', 'Bold'],
  },
  {
    fontFamily: 'Neucha',
    fontWeights: ['Normal'],
  },
  {
    fontFamily: 'Yeseva One',
    fontWeights: ['Normal'],
  },
];

export const ComponentTypes = ['title', 'message', 'coupon', 'expiration', 'button', 'label'] as const;
export type ComponentType = typeof ComponentTypes[number];

export interface FontSettingsData {
  fontFamily?: INotificationFont;
  fontWeight?: INotificationFontWeight;
  fontSize?: INotificationFontSize;
  isItalic?: boolean;
  isLineThrough?: boolean;
  isUnderline?: boolean;
  color?: string;
  alpha?: number;
}

interface FontSettingsProps {
  data?: FontSettingsData;
  onChange?: (data: FontSettingsData) => void;
  isMobile?: boolean;
  type: ComponentType;
  title?: string;
  hint?: string | React.ReactNode;
  dropdown?: boolean;
  className?: string;
  overlayClassName?: string;
}

const FontSettings: React.FC<FontSettingsProps> = ({
  isMobile,
  type,
  onChange,
  data,
  title,
  hint,
  dropdown,
  className,
  overlayClassName,
}) => {
  const onFontFamilyChange = useCallback((fontFamily: INotificationFont) => {
    const currentFontWeights = getFontDefaultData(fontFamily).fontWeights;
    onChange?.({
      ...data,
      fontWeight: currentFontWeights.includes(data?.fontWeight) ? data?.fontWeight : currentFontWeights[0],
      fontFamily,
    });
  }, [data]);

  const onFontWeightChange = useCallback((fontWeight: INotificationFontWeight) => onChange?.({
    ...data,
    fontWeight,
  }), [data]);

  const onFontSizeChange = useCallback((fontSize: string) =>
    onChange?.({ ...data, fontSize: parseInt(fontSize, 10) as INotificationFontSize }), [data]);

  const onColorChange = useCallback(({ color, alpha }: ColorData) => onChange?.({ ...data, color, alpha }), [data]);

  const onTextFormatChange = useCallback((format: string) =>
    onChange?.({ ...data, isItalic: format === TextFormat.Italic }), [data]);

  const onTextDecorationChange = useCallback((decoration: string) =>
    onChange?.({
      ...data,
      isUnderline: decoration === TextDecoration.Underline,
      isLineThrough: decoration === TextDecoration.LineThrough,
    }), [data]);

  return (
    <FormItem
      className={cx('font-settings', className)}
      noHTMLLabel
      noReservedPlaceForMessage
      title={title || 'Шрифт'}
      hint={hint}
      dropdown={dropdown}
      overlayClassName={overlayClassName}
    >
      <div className="font-settings__container">
        <InputDropdown
          onChange={onFontFamilyChange}
          selectedKey={data?.fontFamily}
          className="font-settings__font-family"
          items={fonts.map(font => ({
            key: font,
            value: font,
          }))}
          renderItem={item => (
            <span style={{
              fontFamily: item.value,
              fontStyle: data?.isItalic ? 'italic' : 'normal',
            }}>
              {item.value}
            </span>
          )}
          block
        />
        <InputDropdown
          onChange={onFontWeightChange}
          className="font-settings__font-weight"
          selectedKey={data?.fontWeight}
          items={getFontDefaultData(data?.fontFamily)?.fontWeights.map(weight => ({
            key: weight,
            value: weight,
          }))}
        />
        <InputDropdown
          onChange={onFontSizeChange}
          className="font-settings__font-size"
          selectedKey={data?.fontSize?.toString()}
          items={getFontSizes(isMobile, type)}
        />
        <ColorPicker
          color={data?.color}
          alpha={data?.alpha}
          onChange={onColorChange}
          className="font-settings__font-color"
        />
        <TabSwitch
          className="font-settings__text-format"
          value={textFormatBooleanToString(data?.isItalic)}
          onChange={onTextFormatChange}
          items={[
            { label: <Icon name="NoItalic" className="font-settings__icon" />, key: 'bold', tooltip: 'Обычный' },
            { label: <Icon name="Italic" className="font-settings__icon" />, key: 'italic', tooltip: 'Наклонный' },
          ]}
        />
        <TabSwitch
          className="font-settings__text-decoration"
          value={textDecorationBooleanToString(data?.isUnderline, data?.isLineThrough)}
          onChange={onTextDecorationChange}
          items={[
            { label: <Icon name="NoDecoration" className="font-settings__icon" />, key: 'no-decoration', tooltip: 'Нет стиля' },
            { label: <Icon name="LineThrough" className="font-settings__icon" />, key: 'line-through', tooltip: 'Зачеркнутый' },
            { label: <Icon name="Underline" className="font-settings__icon" />, key: 'underline', tooltip: 'Подчеркнутый' },
          ]}
        />
      </div>
    </FormItem>
  );
};

const getFontSizes = (isMobile: boolean, type: ComponentType) => {
  switch (type) {
    case 'button':
      return getItemsFromArray(Array.from(isMobile ? messageMobileFontSizes : buttonFontSizes));
    case 'message':
    case 'expiration':
      return getItemsFromArray(Array.from(isMobile ? messageMobileFontSizes : messageFontSizes));
    case 'coupon':
      return getItemsFromArray(Array.from(isMobile ? couponMobileFontSizes : couponFontSizes));
    case 'title':
      return getItemsFromArray(Array.from(isMobile ? titleMobileFontSizes : titleFontSizes));
    case 'label':
      return getItemsFromArray(Array.from(labelFontSizes));
    default:
      return [];
  }
};

const getItemsFromArray = (array: number[]): InputDropdownItem<string>[] =>
  array.map(size => ({ key: size.toString(), value: size.toString() }));

const getFontDefaultData = (fontFamily: INotificationFont) =>
  FONTS_DEFAULT_DATA.find(font => font.fontFamily === fontFamily);

export default FontSettings;
