import React from 'react';
import QRCode from 'react-qr-code';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { IPlug } from '~/screens/LeadsSettingsScreen/telegram/types';

export const PlugByQrCode = ({ link = '' }: IPlug) => {
  return (
    <SettingsItem
      Content={(
        <>
          <QRCode value={link} size={164} />
          <div>
            Отсканируйте QR-код камерой смартфона, если у вас есть только мобильное приложение Telegram
          </div>
        </>
      )}
    />
  );
};
