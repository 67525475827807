import moment from 'moment';
import React from 'react';
import { path } from '~/api/client';
import Icon from '~/components/Icon';
import Loading from '~/components/Loading';
import TableCell from '~/components/ui/Table/TableCell';
import fullDateFormatWithoutYear from '~/utils/fullDateFormatWithoutYear';
import './style.css';

export const columns = [
  {
    key: 'period',
    width: 300,
    title: 'Период',
    render: (v: IDocuments) => {
      return ( 
        <TableCell title={(
          <div>
            <span>{`с ${fullDateFormatWithoutYear(moment(v.startDate).format('LL'))}`}</span> <br />
            <span>{`по ${fullDateFormatWithoutYear(moment(v.endDate).format('LL'))}`}</span>
          </div>
          )}
        /> 
      );
    },
  },
  {
    key: 'documents',
    title: 'Документы',
    render: (v: IDocuments) => (
      <TableCell
        className="documents__list"
        title={(
          <div key={v.id}>
            <Icon name="Doc" className="documents__icon" />
            <a
              href={path(v.link)}
              id={`download-${v.id}`}
              download={v.name}
            >
              {v.name}
            </a>
          </div>
        )}
      />
    ),
  },
  /* TODO пока прячем эту колонку. */
  /*  {
    key: 'downloadButton',
    width: 150,
    title: '',
    render: (v: IDocuments) => {
      return (
        <TableCell
          title={(
            <Button
              type={'grey'}
              icon={'Download'}
              className={'documents__download-button'}
              onClick={() => {
                v.documents.map((k: IDocument) => {
                  const elem = document.getElementById(
                    `download-${v.id}${k.id}`
                  );
                  elem?.click();
                });
              }}
            >
              Загрузить все
            </Button>
          )}
        />
      );
    },
  },*/
];

interface IPlug {
  isLoading: boolean;
  error?: string | Element;
}

export const Plug = ({ isLoading, error }: IPlug) => {
  const status = isLoading ? 'Loading' : error ? 'Error' : 'Success';

  return <Loading status={status} onClick={() => location.reload()} />;
};

export const rowKey = (row: any) =>
  row.id?.toString() || Math.random().toString();
