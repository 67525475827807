import React from 'react';
import Spinner from '~/components/ui/Spinner';
import './style.css';

const LoadingScreen: React.FC = () => (
  <div className="loading-screen">
    <Spinner color="green" />
  </div>
);

export default LoadingScreen;
