import cx from 'classnames';
import { quiz, TQuizEventType } from 'feed';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import { DeviceSwitcher } from '~/components/ui/DeviceSwitcher';
import Message from '~/components/ui/Message';
import QuizModel from '~/screens/QuizScreen/stores/QuizModel';
import {
  IQuizTemplate,
  QuizStore,
} from '~/screens/QuizScreen/stores/quizStore';
import {
  getQuizShownConfiguration,
  getQuizShownSteps,
} from '~/screens/QuizScreen/utils';
import useStores from '~/utils/useStores';
import styles from './style.module.css';

const ID_DIV_BLOCK_FROM_QUIZ_PREVIEW = 'quizStartQuizInSelector';
const { QuizCreator } = quiz;

interface IPreviewSample {
  onClose: () => void;
  template: IQuizTemplate;
  isEditButtonShown?: boolean;
}

interface IPreviewHeader {
  title: string;
  isMobile: boolean;
  setIsMobile: () => void;
  onEdit: () => void;
  isEditButtonShown?: boolean;
}

const PreviewHeader = ({
  title,
  isMobile,
  setIsMobile,
  onEdit,
  isEditButtonShown = true,
}: IPreviewHeader) => (
  <div className={styles.previewHeader}>
    <div className={styles.header}>{title}</div>
    <div className={styles.actions}>
      <DeviceSwitcher
        handleClick={setIsMobile}
        isMobile={isMobile}
        className={styles.switcher}
      />
      {isEditButtonShown && (
        <Button
          className={styles.editButton}
          icon={'EditCircle'}
          type={'sber'}
          onClick={onEdit}
        >
          {isMobile ? '' : 'Редактировать'}
        </Button>
      )}
    </div>
  </div>
);

export const PreviewSample = ({
  onClose,
  template,
  isEditButtonShown = true,
}: IPreviewSample) => {
  const {
    quizMetaId,
    // @ts-ignore
    body: { steps, configuration }, // TODO typings
  } = template || {};
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const { quizStore } = useStores() as {
    quizStore: QuizStore;
  };

  useEffect(() => {
    const quiz = new QuizCreator(
      quizMetaId,
      getQuizShownSteps(steps),
      getQuizShownConfiguration(configuration),
      isMobile
    );
    quiz.quizStyle.addStyles();
    quiz.callbackFunction = (eventType: TQuizEventType) => {
      if (eventType === 'CLOSE') onClose();
    };
    quiz.start(`#${ID_DIV_BLOCK_FROM_QUIZ_PREVIEW}`);

    return () => {
      if (quiz.hasQuizInPage()) quiz.removeQuiz();
    };
  }, [isMobile]);

  const onEdit = () => {
    const quizData = quizStore.quizTemplates.filter(
      (v: IQuizTemplate) => v.quizMetaId === quizMetaId
    )[0].body;
    const quizTemplate = quizStore.quizTemplates.filter(
      (v: IQuizTemplate) => v.quizMetaId === quizMetaId
    )[0];
    quizStore.quizExample = new QuizModel(null, quizData);
    quizStore.templateData = quizTemplate;
    quizStore.setQuizConstructorStep(3);
  };

  return (
    <Message
      visible
      title={(
        <PreviewHeader
          isMobile={isMobile}
          setIsMobile={() => setIsMobile(!isMobile)}
          title={template.head.caption}
          onEdit={onEdit}
          isEditButtonShown={isEditButtonShown}
        />
      )}
      textBottom={<div id={ID_DIV_BLOCK_FROM_QUIZ_PREVIEW} className={styles.previewContainer} />}
      onExit={onClose}
      className={cx(styles.preview, isMobile && styles.isMobile)}
      textTop={template.head.description}
    />
  );
};
