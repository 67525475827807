import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const PlugMacroCRM = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;
  const [macroCRMUrl, setMacroCRMUrl] = useState<string>();
  const macroCRMUrlError = leadSettings.get('macroCRMUrlError');

  useEffect(() => {
    leadSettings.update({ macroCRMUrlError: '' });
  }, []);

  const onChangeValue = (sberCRMUrl: string) => {
    setMacroCRMUrl(sberCRMUrl);
    leadSettings.update({ macroCRMUrlError: '' });
  };

  const enableMacroCRM = async () => {
    await leadSettings.enableMacroCRM(macroCRMUrl);
  };

  return (
    <SettingsItem
      title={
        'Скопируйте полученный WebHook, вставьте его в это поле и нажмите «Подключить»'
      }
      number={3}
      Content={(
        <>
          <Input
            className={'leads-settings__item-input'}
            onChangeValue={onChangeValue}
            failure={!!macroCRMUrlError}
            placeholder={
              'https://app.sbercrm.com/react-gateway/api/webhook/63190d07'
            }
          />
          {(
            <div className={'leads-settings__item-error'}>
              {macroCRMUrlError}
            </div>
          )}
          <Button
            className="leads-settings__item-button"
            onClick={enableMacroCRM}
          >
            Подключить
          </Button>
        </>
      )}
    />
  );
});
