import React from 'react';
import Icon from '~/components/Icon';
import Tooltip from '~/components/ui/Tooltip';

export const getHint = (key: string) => {
  let title = '';
  let body = '';

  if (key === 'shown') {
    title = 'Показы колеса';
    body = 'Столько раз пользователи видели колесо фортуны';
  }

  if (key === 'interactions') {
    title = 'Сколько раз крутили колесо';
    body = 'Столько раз пользователи использовали колесо фортуны';
  }

  return (
    <div className="scenarios-list-question-description">
      <span className="scenarios-list-question-title">{title}</span>
      <Tooltip
        placement="topLeft"
        title={(
          <div className="scenarios-list__tooltip-text-wrapper">
            <p>{body}</p>
          </div>
        )}
      >
        <div>
          <Icon name="Question" className="scenarios-list__hint-icon" />
        </div>
      </Tooltip>
    </div>
  );
};

export const validateSectorsProbability = (sectors: ISectorB[]) => {

  const probabilitySum = sectors.reduce((prev, current, key) => {

    return current.probability + prev;
  }, 0);

  return (probabilitySum === 100);
};
