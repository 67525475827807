import useMutationObserver from '@rooks/use-mutation-observer';
import cx from 'classnames';
import isString from 'lodash/isString';
import React, { useCallback, useEffect, useRef, useState, CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';
import usePortal from 'react-useportal';
import useOnClickOutside from 'use-onclickoutside';
import Icon from '~/components/Icon';
import IconWithTooltip from '~/components/IconWithTooltip';
import InputWrapper from '~/components/ui/InputWrapper';
import './style.css';

const OVERLAY_GAP = 8;

export interface InputDropdownItem<T> {
  key: T;
  value: string;
}

export interface InputDropdownProps<T> {
  className?: string;
  style?: CSSProperties;
  size?: FormElementSize;
  block?: boolean;
  disabled?: boolean;
  success?: boolean;
  failure?: boolean;
  theme?: 'light' | 'dark';
  items?: InputDropdownItem<T>[];
  renderLabel?: (item?: InputDropdownItem<T>) => React.ReactNode;
  renderItem?: (item?: InputDropdownItem<T>) => React.ReactNode;
  selectedKey?: T;
  onChange?: (key: T) => void;
  overlayPlacement?: 'bottom' | 'over';
  noArrow?: boolean;
  hint?: string | ((value: string) => string);
  isDefaultShown?: boolean;
}

const InputDropdown = <T extends string>({
  className,
  style,
  block,
  disabled,
  success,
  failure,
  selectedKey,
  onChange,
  size = 'default',
  theme = 'dark',
  items = [],
  renderLabel = item => item && item.value,
  renderItem = item => item && item.value,
  overlayPlacement = 'over',
  noArrow = false,
  hint,
  isDefaultShown = false,
}: InputDropdownProps<T>) => {
  const overlayRef = useRef<HTMLDivElement>();
  const overlayListRef = useRef<HTMLUListElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const [visible, setVisible] = useState(false);
  const [left, setLeft] = useState<number>();
  const [top, setTop] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [overlayHeight, setOverlayHeight] = useState<number>();

  const { Portal } = usePortal();

  useMutationObserver(overlayRef, () => {
    if (overlayListRef.current) {
      const overlayListHeight = overlayListRef.current.offsetHeight;
      const overlayBottomPosition = top + overlayListHeight + 10;
      const delta = overlayBottomPosition - window.innerHeight;

      if (delta > 0) {
        setOverlayHeight(overlayListHeight - delta);
      } else {
        setOverlayHeight(undefined);
      }
    }
  });

  const showOverlay = useCallback(() => {
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const scrollOffset = window.scrollY;

    if (overlayPlacement === 'over') {
      setTop(scrollOffset + wrapperRect.top);
    } else {
      setTop(scrollOffset + wrapperRect.top + wrapperRect.height + OVERLAY_GAP);
    }

    setWidth(wrapperRect.width);
    setLeft(wrapperRect.left);

    setVisible(true);
  }, [overlayPlacement]);

  const hideOverlay = useCallback(() => {
    setVisible(false);
  }, []);

  useOnClickOutside(overlayRef, () => {
    visible && hideOverlay();
  });

  const handleInputClick = useCallback(() => {
    !visible && showOverlay();
  }, [overlayPlacement]);

  const handleItemClick = useCallback((event) => {
    const key = event.currentTarget.dataset.key;

    onChange(key);
    hideOverlay();
  }, [onChange]);

  useEffect(() => {
    isDefaultShown && setTimeout(showOverlay, 50);
  }, []);

  return (
    <>
      <InputWrapper
        ref={wrapperRef}
        className={cx('input-dropdown', className)}
        style={style}
        size={size}
        block={block}
        disabled={disabled}
        success={success}
        failure={failure}
        onClick={handleInputClick}
        active={overlayPlacement === 'bottom' && visible}
        elemAfter={noArrow !== true ? <Icon name="ArrowSDown" className="input-dropdown__arrow" /> : null}
      >
        <div className="input-dropdown__label">
          {renderLabel(items.find(item => item.key === selectedKey))}
        </div>
      </InputWrapper>
      <Portal>
        <CSSTransition
          in={visible}
          timeout={200}
          unmountOnExit
          mountOnEnter
          classNames="_animated"
        >
          <InputWrapper
            ref={overlayRef}
            className={cx('input-dropdown-overlay', `_theme-${theme}`, { _visible: visible })}
            style={{
              left,
              top,
              height: overlayHeight,
              minWidth: width,
            }}
            withoutPadding
          >
            <ul className="input-dropdown-overlay__list" ref={overlayListRef}>
              {items.map(item => (
                <li
                  className={cx('input-dropdown-overlay__item')}
                  key={item.key}
                  data-key={item.key}
                  onClick={handleItemClick}
                >
                  <a className="input-dropdown-overlay-item">
                    <div className="input-dropdown-overlay-item__icon">
                      {item.key === selectedKey && (
                        <Icon
                          className="input-dropdown-overlay-item__svg"
                          name="CheckmarkS"
                        />
                      )}
                    </div>
                    <div className={cx('input-dropdown-overlay-item__label', { _withMargin: hint })}>
                      {renderItem(item)}
                    </div>
                    {hint && (
                      <IconWithTooltip
                        tooltipProps={{
                          placement: 'topLeft',
                          title: isString(hint) ? hint : hint(item.key),
                          overlayClassName: 'input-dropdown-overlay-item__tooltip',
                        }}
                        iconProps={{
                          name: 'Question',
                          className: 'input-dropdown-overlay-item__tooltip-icon',
                        }}
                      />
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </InputWrapper>
        </CSSTransition>
      </Portal>
    </>
  );
};

export default InputDropdown;
