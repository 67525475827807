import { IFeedConfiguration } from 'feed';
import React from 'react';
import FunctionName from '~/components/generic/StepSelectorAndDesign/Sidebar/FeedEditorAppearance/Tools/Button/ButtonName/FunctionName';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';

const ButtonName: React.FC<{
  configuration: IFeedConfiguration;
  onChange: (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => void;
  onBlur: (configuration: IFeedConfiguration, isLoad?: boolean) => void;
}> = ({ configuration, onChange, onBlur }) => {
  return (
    <section className="feed-editor-appearance__section">
      <FormItem
        title="Текст кнопки"
        message="Не больше 15 символов"
        overlayClassName="feed-editor-appearance__tooltip"
        noHTMLLabel
        className="feed-editor-appearance__font"
      >
        <Input
          value={configuration.buttonName}
          onChangeValue={value =>
            onChange({ ...configuration, buttonName: value })
          }
          maxLength={15}
          onBlur={() => onBlur(configuration)}
          block
        />
      </FormItem>
      <FunctionName
        configuration={configuration}
        onChange={onChange}
        onBlur={onBlur}
      />
    </section>
  );
};

export default ButtonName;
