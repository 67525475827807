export function formatNumbers(number: number) {
  const grouping = 3;
  const numberString = ('' + number).split('.');
  const separator = ' ';

  let currentLength = numberString[0].length;
  let currentPosition;
  let value = '';

  while (currentLength > grouping) {
    currentPosition = currentLength - grouping;
    value = separator + numberString[0].slice(currentPosition, currentLength) + value;
    currentLength = currentPosition;
  }

  value = numberString[0].slice(0, currentLength) + value;
  numberString[0] = value;

  return numberString.join('.');
}
