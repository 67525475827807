import React from 'react';
import { Sector } from '~/components/generic/Wheel/components/Sector';
import styles from '~/components/generic/Wheel/styles.module.css';
import { TSectors } from '~/components/generic/Wheel/types';

interface IWheelSectors {
  sectors: TSectors;
  radius: number;
}

export const WheelSectors = ({ sectors, radius }: IWheelSectors) => {
  const degree = 360 / sectors.length;

  return (
    <div className={styles.wheelSectorsStyles}>
      {sectors?.map((sector, key) => (
        <Sector {...sector} number={key} key={key} size={radius} degree={degree} />
      ))}
    </div>
  );
};
