import { action, observable, reaction, runInAction } from 'mobx';
import DeadZone, { IBlindZonesInfo } from '~/api/deadZone';
import { IState } from '~/components/Loading';
import Store from '~/stores/store';
import getDatesByPreset from '~/utils/getDatesByPreset';

export default class DeadZoneStore extends Store {
  @observable preset: DatePreset;
  @observable dateFrom: string;
  @observable dateTo: string;
  @observable requestStatus: IState = 'Never';
  @observable isDeadZoneData: boolean = false;
  @observable deadZoneData: IBlindZonesInfo;
  @observable loadData: boolean = false;

  deadZoneDataUpdate = reaction(
    () => this.loadData,
    (value: boolean) => value && this.getReport()
  );

  constructor() {
    super();
    this.setRangePreset('week');
  }

  @action
  setCustomRange = (dateFrom: string, dateTo: string) => {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;

    this.setRangePreset('custom');
  }

  @action
  setRangePreset = (preset: DatePreset) => {
    if (!this.loadData) { this.loadData = true; }

    if (this.preset === preset && preset !== 'custom') return;

    const [dateFrom, dateTo] = getDatesByPreset(preset, [this.dateFrom, this.dateTo]);

    this.preset = preset;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  @action
  convertResponseDeadZoneData = (data: IBlindZonesInfo): IBlindZonesInfo => {
    return {
      ['@type']: 'BlindZonesInfo',
      blindPagesNumber: data.blindPagesNumber ? data.blindPagesNumber : 0,
      allPagesNumber: data.allPagesNumber ? data.allPagesNumber : 0,
      percentageComparison: data.percentageComparison ? Number((data.percentageComparison * 100).toFixed()) : 0,
      isEnabled: data.isEnabled,
      isSuccessful: data.isSuccessful,
      blindPages: data.blindPages ? data.blindPages : null,
    };
  }

  @action
  getReport = async () => {

    try {
      const response = await DeadZone.report(this.dateFrom, this.dateTo);
      this.requestStatus = 'Loading';
      this.isDeadZoneData = await (response?.data.payload && response.data.payload.length > 0);

      runInAction(() => {
        this.requestStatus = 'Success';
        this.deadZoneData = this.isDeadZoneData && this.convertResponseDeadZoneData(response.data.payload[0]);
      });
    } catch (error) {
      this.requestStatus = 'Error';
    } finally {
      this.loadData = false;
    }
  }
}
