import { AxiosPromise } from 'axios';
import { actionGet } from './client';

export type OrganizationResponse = IResponse<[IOrganization]>;

const Organization = {
  getOrganization: (id: number): AxiosPromise<OrganizationResponse> =>
    actionGet('/admin/orgInfo', { id }),

  orgProfile: (): AxiosPromise<OrganizationResponse> =>
    actionGet('/admin/orgInfo', null),
};

export default Organization;
