import { pki, util } from 'node-forge';

const encrypt = function (str: string, key: string) {
  const publicKey = pki.publicKeyFromPem(key);
  const encrypted = publicKey.encrypt(util.encodeUtf8(str), 'RSAES-PKCS1-V1_5');

  return util.encode64(encrypted);
};

export default encrypt;
