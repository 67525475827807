export const exapleProductList = [
  {
    name: 'Спагетти',
    price: 50000,
    count: 1,
    url: `${location.origin}/mockEmailLinkResult`,
    picture: `${location.origin}/assets/images/mailing/examples/1.png`,
  },
  {
    name: 'Тыквенный суп',
    price: 40000,
    count: 1,
    url: `${location.origin}/mockEmailLinkResult`,
    picture: `${location.origin}/assets/images/mailing/examples/2.png`,
  },
  {
    name: 'Пицца Маргарита',
    price: 60000,
    count: 1,
    url: `${location.origin}/mockEmailLinkResult`,
    picture: `${location.origin}/assets/images/mailing/examples/3.png`,
  },
];