import React from 'react';
import styles from './style.module.css';

const TooltipForBlockCategoryPro: React.FC = () => {
  return (
    <div>
      Вы можете автоматически категоризировать товары 
      из вашего каталога. Пользователю будет легче найти 
      нужный товар.
      <br /><br />
      <i>Пример:</i>
      <div className={styles.sample_list}>
        <div>Аксессуары</div>
        <div>Чехлы</div>
        <div>Защитные стекла</div>
      </div>
    </div>
  );
};

export default TooltipForBlockCategoryPro;
