import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import ButtonLike from '~/components/ui/ButtonLike';
import './style.css';

interface Props {
  domain: string;
  abbreviation: string;
  userName: string;
  lastLoginDate: string;
  isLoggingOut: boolean;
  logout: () => void;
}

const SidebarUser: React.FC<Props> = ({
  abbreviation,
  domain,
  userName,
  lastLoginDate,
  isLoggingOut,
  logout }) => {
  const [visible, setVisible] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);

  const toggleInfoBlock = () => {
    setVisible(!visible);
    if (!rotateArrow) {
      setRotateArrow(true);
    }
  };

  const overlayRef = useRef(null);
  useOnClickOutside(overlayRef, toggleInfoBlock);

  const overlayHTML = (
    <div className="sidebar-user-desc-info" ref={overlayRef}>
      <div className="sidebar-user-desc-info__block">
        <span className="sidebar-user-desc-info__light-str">{userName}</span>
        <span className="sidebar-user-desc-info__dark-str">{domain}</span>
      </div>
      {lastLoginDate && (
        <div className="sidebar-user-desc-info__block _last-block">
          <span className="sidebar-user-desc-info__light-str">{lastLoginDate}</span>
          <span className="sidebar-user-desc-info__dark-str">Предыдущий вход</span>
        </div>
      )}
        <ButtonLike
          className="sidebar-user-desc__logout"
          loading={isLoggingOut}
          onClick={logout}
          icon="Logout"
          block
          borderless
          type="text"
          size="large"
        >
          Выйти из аккаунта
        </ButtonLike>
      </div>
    );

  return (
    <>
      <Link className="sidebar-user" to="/profile">
        <div className="sidebar-user__avatar">
          <div className="sidebar-user-avatar">{abbreviation}</div>
        </div>
        <div className="sidebar-user__body">
          <div className="sidebar-user__title">{domain}</div>
          <div className="sidebar-user__subtitle">{userName}</div>
        </div>
      </Link>
      <div className="sidebar-user-desc">
        {visible && overlayHTML}
        <ButtonLike
          icon="ArrowMDown"
          className={cx('dropdown-rc__svg', {
            ['_dropdown-rc-arrow-up']: visible,
            _rotatable: rotateArrow,
          })}
          type="text"
          size="x-small"
          onClick={toggleInfoBlock}
        />
      </div>
    </>
  );
};

export default SidebarUser;
