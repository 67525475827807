import cx from 'classnames';
import React from 'react';
import './style.css';

interface IDivider {
  content?: string;
  className?: string;
}

export const Divider = ({ content = 'или', className }: IDivider) => {
  return (
    <div className={cx('element-divider', className)}>
      <span>{content}</span>
    </div>
  );
};
