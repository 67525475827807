import cx from 'classnames';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import VideoMessage from '~/components/ui/VideoMessage';
import styles from './styles.module.css';

interface IVideoSize {
  width?: string;
  height?: string;
}

interface IVideo {
  title: string;
  content: string;
  link: string;
  previewImage?: string;
  size?: IVideoSize;
  className?: string;
}

const PlayBtn = () => {
  return (
    <div className={styles.playBtn}>
      <Icon name="Play" className={styles.playIcon} />
    </div>
  );
};

export const Video = ({
  title,
  content,
  link,
  size = {},
  className = '',
  previewImage,
}: IVideo) => {
  const [isPreviewShown, setIsPreviewShown] = useState<boolean>(true);
  const isVideoShown = !isPreviewShown;

  const onPreviewClick = () => setIsPreviewShown(false);
  const onClose = () => setIsPreviewShown(true);

  return (
    <div className={cx(styles.video, className)} style={size}>
      {isPreviewShown && (
        <div className={styles.preview} onClick={onPreviewClick}>
          <div className={styles.leftWrap}>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{content}</div>
          </div>
          <PlayBtn />
        </div>
      )}
      <div className={styles.background} />
      <img src={previewImage} alt={title} className={styles.previewImg} />
      {isVideoShown && (
        <VideoMessage
          visible={isVideoShown}
          title={title}
          onClose={onClose}
          onExit={onClose}
          videoSrc={link}
        />
      )}
    </div>
  );
};
