import { action, computed, observable } from 'mobx';
import Scenarios from '~/api/scenarios';
import Model from '~/models/Model';

export default class ScenarioTemplateModel extends Model<IScenarioTemplate> {
  static unwrapTriggerParams(wrappedParams: IWrappedNotification): INotification {
    const isMobile = wrappedParams.isMobile.value;
    const isMobileConst = wrappedParams.isMobile.isConst;
    const isHorizontal = wrappedParams.isHorizontal.value;
    const unwrapped: { [key: string]: any } = {};
    for (const key in wrappedParams) if (wrappedParams.hasOwnProperty(key)) {
      const mobileVrappedParams = wrappedParams[key as keyof IWrappedNotification].valueMobile;
      const verticalVrappedParams = wrappedParams[key as keyof IWrappedNotification].value;
      const horizontalVrappedParams = wrappedParams[key as keyof IWrappedNotification].valueHorizontal;
      let actualVrappedParams = verticalVrappedParams;

      if (isMobile && !(key === 'isHorizontal' || key === 'isMobile')) {
        actualVrappedParams = mobileVrappedParams;
      }

      if (!isMobile && isHorizontal && !(key === 'isHorizontal' || key === 'isMobile')) {
        actualVrappedParams = horizontalVrappedParams;
      }

      unwrapped[key] = actualVrappedParams;
    }

    return { ...unwrapped, isMobileConst } as INotification;
  }

  groupIndex: number;
  @observable isSaving = false;
  @observable isFetching = false;
  @observable isFetched = false;

  wrapPartialTriggerParams(unwrappedParams: Partial<INotification>): Partial<IWrappedNotification> {
    const modelData: { [key: string]: any } = this.copy().triggerGroups?.[0].triggers[0].params;
    const isMobile = modelData.isMobile.value;
    const isHorizontal = modelData.isHorizontal.value;
    for (const key in unwrappedParams) if (unwrappedParams.hasOwnProperty(key)) {
      if (key === 'isMobileConst') continue;
      const unwrappedParamsKey = unwrappedParams[key as keyof INotification];
      const mobileVrappedParams = { valueMobile: unwrappedParamsKey };
      const verticalVrappedParams = { value: unwrappedParamsKey };
      const horizontalVrappedParams = { valueHorizontal: unwrappedParamsKey };

      let actualVrappedParams: { [key: string]: any } = verticalVrappedParams;

      if (isMobile && !(key === 'isHorizontal' || key === 'isMobile')) {
        actualVrappedParams = mobileVrappedParams;
      }

      if (!isMobile && isHorizontal && !(key === 'isHorizontal' || key === 'isMobile')) {
        actualVrappedParams = horizontalVrappedParams;
      }

      modelData[key] = { ...modelData[key], ...actualVrappedParams };
    }

    return modelData as Partial<IWrappedNotification>;
  }

  wrapTriggerParams(unwrappedParams: INotification): IWrappedNotification {
    return this.wrapPartialTriggerParams(unwrappedParams) as IWrappedNotification;
  }

  setGroup = (groupIndex: number) => {
    this.groupIndex = groupIndex;
  }

  @action
  replaceStepGroups = (stepGroups: IScenarioTemplateStepGroupBase[]) => {
    const modelData = this.copy();
    modelData.stepGroups = stepGroups;
    this.replace(modelData);
  }

  @action
  replaceTriggerParams = (params: INotification) => {
    const modelData = this.copy();
    modelData.triggerGroups[0].triggers[0].params = this.wrapTriggerParams(params);
    this.replace(modelData);
  }

  @action
  save = async () => {
    this.isSaving = true;

    try {
      const result = await Scenarios.save(this.copy());

      if (this.get('id') === 0) {
        const sorted = result.data.payload.sort((v: { id: number }, k: { id: number }) => k.id - v.id);

        return sorted[0].id;
      } else {
        return this.get('id');
      }

    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  fetch = async () => {
    this.isFetching = true;

    try {
      const res = await Scenarios.template(this.get('id'));
      this.update(res.data.payload[0].scenarioTemplate);
      this.isFetched = true;
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @computed
  get mobileOnly() {
    return this.stepGroups.some((group) => {
      return group.steps.some((step) => {
        return step.deviceLimitation && step.deviceLimitation.length === 1 && step.deviceLimitation[0] === 'MOBILE';
      });
    });
  }

  @computed
  get stepGroups() {
    return this.copy().stepGroups;
  }

  @computed
  get triggerParams() {
    const uwrappedParams = this.copy().triggerGroups?.[0].triggers[0].params;
    let currentTriggerParams: INotification;
    currentTriggerParams = ScenarioTemplateModel.unwrapTriggerParams(uwrappedParams);

    return currentTriggerParams;
  }

  @computed
  get id() {
    return this.get('scenarioCode');
  }

  @computed
  get key() {
    return this.get('scenarioCode');
  }
}
