import React, { ReactNode } from 'react';
import styles from './style.module.css';

interface IBlankScreenMessageProps {
  title?: ReactNode;
  action?: ReactNode;
}

const BlankScreenMessage: React.FC<IBlankScreenMessageProps> = ({
  title = null,
  action = null,
}) => {
  return (
    <div className={`${styles.wrapper} ${styles.center}`}>
      <div className={styles.center}>
        <div className={styles.label}>
          {title !== null && title}
        </div>
          {action !== null && action}
      </div>
    </div>
  );
};

export default BlankScreenMessage;
