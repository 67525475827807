import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import styles from '~/screens/QuizScreen/components/QuizStatistic/ContactsStatistics/style.module.css';

export interface IHeaderCell {
  title: string;
  hasSortingButton?: boolean;
}

export const HeaderCell = ({
  title,
  hasSortingButton,
}: IHeaderCell) => {
  return (
    <div className={cx(styles.tableCell)}>
      {title}
      {hasSortingButton && <Icon className={styles.arrow} name="ArrowSDown" />}
    </div>
  );
};
