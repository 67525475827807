import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import Icon from '~/components/Icon';
import Message from '~/components/ui/Message';
import { mainLoginUrl } from '~/constants';
import ToggleSwitch from '../ToggleSwitch';
import { modalOptions, IServiceBox, SERVICES_INFO } from './data';

import styles from './style.module.css';

const ServiceBox: React.FC<IServiceBox> = ({
  service,
  remoteService,
  isRedirect,
  withIndividualCalculation,
  isLastActiveService,
  inProcessing,
  resetProcessing,
  tarplanConnectionDate,
  tariffPrice,
  isNotPaidTariff,
}) => {
  const {
    code,
    isActive,
    price,
    endPromoDate,
    isPromoAvilable,
    isPlannedOn,
  } = service;

  const [isToggled, setIsToggled] = React.useState(isActive);
  const [
    isRemoteServiceModalOpen,
    setIsRemoteServiceModalOpen,
  ] = React.useState(false);

  useEffect(() => {
    if (isRedirect) {
      location.href = mainLoginUrl;
    }
  }, [isRedirect]);

  const lastPromoDate = moment(endPromoDate).format('DD.MM.YYYY');

  const createModalData = () => {
    if (isNotPaidTariff) {
      return modalOptions.isPaidServices;
    } else {
      if (isActive) {
        if (isLastActiveService) {
          return modalOptions.lastDisableService;
        }

        return modalOptions.disableService;
      } else {
        if (isPromoAvilable) {
          return modalOptions.enablePromoService;
        }

        return modalOptions.enableService;
      }
    }
  };

  const modalData = createModalData();

  const title = SERVICES_INFO[code].title;

  const messageTitle = isNotPaidTariff
    ? `${modalData.title}`
    : `${modalData.title} «${SERVICES_INFO[code]?.title}»?`;

  const messageTextTop = `${modalData.textTop} ${
    !isActive && !isNotPaidTariff
      ? isPromoAvilable
        ? `${lastPromoDate} г.`
        : `${tarplanConnectionDate} г.`
      : ''
  }`;

  const messageTextBottom =
    !isActive && !isNotPaidTariff ? (
      <div>
        {`${modalData.textBottom?.textBottomPrice} ${
          price + Number(tariffPrice.split(' ').join(''))
        } ₽/мес`}
        <br />
        {isPromoAvilable &&
          `${modalData.textBottom?.textBottomDate} ${tarplanConnectionDate} г.`}
      </div>
    ) : null;

  const isSwapAction = isNotPaidTariff || (isActive && isLastActiveService);

  const handleApply = () => {
    remoteService(code, !isActive);
    setIsRemoteServiceModalOpen(false);
    setIsToggled(!isToggled);
  };

  const handleClose = () => {
    setIsRemoteServiceModalOpen(false);
  };

  return (
    <>
      <div className={styles.service}>
        <Icon
          className={styles.service_icon}
          name={SERVICES_INFO[code]?.icon}
        />
        <div className={styles.separator}>
          <div className={styles.service_wrapper_text}>
            <span>{title}</span>
            {!withIndividualCalculation && (
              <div className={styles.service_wrapper_text_price}>
                {isPromoAvilable &&
                  (isActive
                    ? `Пробный период до ${lastPromoDate}, после – `
                    : `Активируйте пробный период до ${lastPromoDate}, после – `)}
                {isPlannedOn &&
                  `Функционал будет доступен с ${tarplanConnectionDate} г. `}
                {isNotPaidTariff && isActive && `Платеж не прошел, услуга –  `}
                {`${price} ₽/мес`}
              </div>
            )}
          </div>
          {!withIndividualCalculation && (
            <div>
              <ToggleSwitch
                isActive={isActive}
                setIsRemoteServiceModalOpen={setIsRemoteServiceModalOpen}
                disabled={isPlannedOn}
              />
            </div>
          )}
        </div>
      </div>
      {isRemoteServiceModalOpen && (
        <Message
          visible={isRemoteServiceModalOpen}
          showIcon={false}
          title={messageTitle}
          textTop={messageTextTop}
          textBottom={messageTextBottom}
          swapActions={isSwapAction}
          turnOffClickBackground={isSwapAction}
          onClose={handleClose}
          onApply={handleApply}
          applyText={modalData.applyText}
          cancelText={modalData.cancelText}
        />
      )}
      {inProcessing && (
        <Message
          showIcon={false}
          visible={inProcessing}
          title={'Извините, идёт процесс оплаты'}
          textTop={
            'В данный момент идет процесс оплаты текущего платежа, поэтому управление услугами недоступно'
          }
          onApply={() => {
            resetProcessing();
          }}
          applyText={'Хорошо'}
        />
      )}
    </>
  );
};

export default observer(ServiceBox);
