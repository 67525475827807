import { Alert, Button, Divider, Form, Input, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { AuthStore } from '~/stores/authStore';
import './style.css';

const { Title, Paragraph } = Typography;

interface FormValues {
  oldPassword?: string;
  newPassword1?: string;
  newPassword2?: string;
}

type Props = {
  authStore?: AuthStore;
} & RouteComponentProps<{
  redirect?: string;
}>;

interface State {
  confirmDirty: boolean;
}

@inject('authStore')
@observer
class ChangePasswordScreen extends Component<Props> {
  state: State = {
    confirmDirty: false,
  };

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  checkAuth = () => {
    const { authStore, history, location } = this.props;
    const params = qs.parse(location.search) as { redirect: string };

    if (authStore.shouldChangePassword === false) {
      if (params.redirect) {
        history.push(params.redirect);
      } else {
        history.push('/');
      }

      return;
    }
  }

  handleConfirmBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleFinish = async (values: FormValues) => {
    const { authStore } = this.props;

    const { oldPassword, newPassword1 } = values;
    const login = authStore.currentUser.get('login');
    const newPassword = newPassword1;

    try {
      await authStore.mandatoryChangePassword(login, oldPassword, newPassword);
      authStore.shouldChangePassword = false;
    } catch (e) {
      throw e;
    }
  }

  handleLogout = async () => {
    const { authStore, history } = this.props;

    authStore.currentUser.update({ login: undefined });
    authStore.shouldChangePassword = false;
    history.push('/auth');
  }

  render() {
    const { authStore } = this.props;

    return (
      <div className="change-password-screen">
        <Typography>
          <Title
            className="login-screen__title"
            level={3}
          >
            Установка пароля
          </Title>
          <Divider />
          <Paragraph>
            Укажите новый пароль. Пароль должен соответствовать следующим условиям:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Должен содержать заглавную букву</li>
              <li>Должен содержать не менее одной цифры</li>
              <li>Длина не менее 8 символов</li>
              <li>В пароле отсутствуют три рядом стоящих знака из последовательностей на клавиатуре
                (как слева направо, так справа налево)</li>
              <li>В пароле не должно содержатся более трех уникальных символов</li>
            </ul>
          </Paragraph>
        </Typography>

        {authStore.isPasswordChangingError && (
          <Alert
            message="Ошибка смены пароля"
            type="error"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}

        <Form
          onFinish={this.handleFinish}
          name="change-password"
        >
          <Form.Item
            label="Временный пароль"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: 'Укажите ваш временный пароль',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Новый пароль"
            hasFeedback
            name="newPassword1"
            rules={[
              {
                required: true,
                message: 'Укажите новый пароль',
              },
              {
                min: 8,
                message: 'Должен содержать не менее 8 символов',
              },
              {
                max: 32,
                message: 'Должен содержать не более 32 символов',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Подтвердите новый пароль"
            name="newPassword2"
            rules={[{
              required: true,
              message: 'Подтвердите новый пароль',
            }, ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword1') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Введённые пароли не совпадают');
              },
            })]}
            hasFeedback
          >
            <Input.Password onBlur={this.handleConfirmBlur} />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="login-form-button"
              loading={authStore.isPasswordChanging}
              block
            >
              Сохранить
            </Button>
          </Form.Item>

          <Button
            onClick={this.handleLogout}
            type="link"
            block
          >
            Сменить пользователя
          </Button>
        </Form>
      </div>
    );
  }
}

export default ChangePasswordScreen;
