import { action, observable, reaction } from 'mobx';
import { Auth, Organization, Users } from '~/api';
import { ISendPassword } from '~/api/auth';
import { mobileScreenWasShownConst } from '~/constants';
import OrgModel from '~/models/OrgModel';
import UserModel from '~/models/UserModel';
import rolesStore from '~/stores/rolesStore';
import Store from '~/stores/store';
import encrypt from '~/utils/encrypt';
import eventEmitter from '~/utils/eventEmitter';
import { getError } from '~/utils/getError';
import { saveObjectToLocaleStorage } from '~/utils/localStorageUtils/saveObjectToLocaleStorage';
import { saveToLocaleStorage } from '~/utils/localStorageUtils/saveToLocaleStorage';
import { setGtm } from '~/utils/setGtm';
import { checkErrorHasTime } from '~/utils/validationUtils/checkErrorHasTime';

export interface ISbbIDLoginData {
  state: string;
  nonce: string;
  code: string;
  location: string;
}

export class AuthStore extends Store {
  @observable currentUser: UserModel = new UserModel(this);
  @observable currentOrg: OrgModel = new OrgModel(this);
  @observable loggingErrorResponse: IResponse;
  @observable isAuthorized: boolean;
  @observable isLoggingIn = false;
  @observable isProfileLoading = false;
  @observable isLoggingOut = false;
  @observable isPasswordChanging = false;
  @observable isPasswordChangingError = false;
  @observable cantFetchStaticData = false;
  @observable isLoaded = false;
  @observable sbbIDLoginData: ISbbIDLoginData;
  @observable shouldChangePassword = false;
  @observable isOrganizationLoading = false;
  @observable isInstalledSuccessfully = false;
  @observable isCodeChecking = false;
  @observable isAddingDomain = false;
  @observable freePayment = false;

  @observable registrationPhoneError: string | Element = '';
  @observable smsCodeError: string | Element = '';
  @observable offerError: string | Element = '';
  @observable passwordError: string | Element = '';

  @observable registrationStep: 1 | 2 = 1;
  @observable offer: IOffer[] = [];
  @observable isOfferFetching: boolean = false;
  @observable isFetchingOnRegistration = false;
  @observable phone: string = '';
  @observable smsRegCode: string = '';
  @observable isRecovery = false;
  @observable isRegistration = false;

  @observable paymentError: string | Element = '';

  @observable clientState: TClientState | undefined;

  @observable isTechnicalLogin: boolean = false;

  constructor() {
    super();

    this.reactions.loginSideEffects = reaction(
      () => this.isAuthorized,
      this.loginSideEffects
    );

    eventEmitter.on('logout', this.setUnauthorized);
  }

  @action
  contractOwnerCode = async () => {
    try {
      const result = await Auth.contractOwnerCode();

      return result?.data?.payload[0]?.contractOwnerCode || '';
    } catch (e) {
      console.log({ e });

      return false;
    }
  }

  @action
  loginSideEffects = async (isAuthorized: boolean) => {
    this.isLoaded = false;

    if (isAuthorized) {
      try {
        await this.fetchProfile();
        await this.fetchOrgData();
        await this.fetchClientState();
        if (this.currentUser.isFintech) {
          await Promise.all([
            rolesStore.fetchRoles(),
            rolesStore.fetchRights(),
          ]);
        }
      } catch (e) {
        this.cantFetchStaticData = true;
        throw e;
      }
    }

    if (!isAuthorized) {
      this.currentUser.clean();
      this.currentOrg.clean();
    }

    this.isLoaded = true;
  }

  @action
  setUnauthorized = () => {
    this.isAuthorized = false;
  }

  @action
  fetchClientState = async () => {
    try {
      const response = await Auth.checkClientState();
      this.clientState = response?.data?.payload[0]?.clientState || undefined;
    } catch (e) {
      console.log({ fetchClientStateError: e });
    }
  }

  @action
  fetchProfile = async () => {
    this.isProfileLoading = true;

    try {
      const response = await Users.profile();
      this.currentUser.update(response.data.payload[0]);
    } catch (e) {
      throw e;
    } finally {
      this.isProfileLoading = false;
    }
  }

  @action
  setUTM = async (payload: Pick<ISetUTM, 'data'>) => {
    try {
      await Auth.setUTM({
        '@type': 'UtmInfo',
        'data': payload.data,
      });
      saveObjectToLocaleStorage('utm', {
        data: payload.data,
        sent: true,
      });
    } catch (e) {
      console.log('SetUTM error', e);
    }
  }

  @action
  fetchOrgData = async () => {
    this.isOrganizationLoading = true;

    try {
      const response = await Organization.orgProfile();
      this.currentOrg.update(response.data.payload[0]);
      this.paymentType(response);
    } catch (e) {
      throw e;
    } finally {
      this.isOrganizationLoading = false;
    }
  }

  @action
  paymentType = async (e: any) => {
    const tarplans = e.data.payload[0].tarplans;
    const payType = tarplans.filter((e: any) => e.extendedTariffInfo !== null);
    payType[0] ? (this.freePayment = false) : (this.freePayment = true);
  }

  @action
  login = async (login: string, password: string) => {
    this.isLoggingIn = true;
    this.loggingErrorResponse = undefined;

    try {
      const response = await Auth.getPasswordEncryptionKey();
      const { enabled, key } = response.data.payload[0];

      const respLogin =
        enabled && key
          ? await Auth.login(
              // @ts-ignore
              encrypt(login, key),
              encrypt(password, key),
              true,
              true
            )
          : await Auth.login(login, password);

      const { token } = respLogin.data;
      saveToLocaleStorage('token', token);

      this.shouldChangePassword = false;
      this.isAuthorized = true;
    } catch (e) {
      this.isAuthorized = false;

      try {
        if (
          e.response.data.errorData.errorType === 'MustChangePasswordException'
        ) {
          this.currentUser.update({ login });
          this.shouldChangePassword = true;
        } else {
          this.loggingErrorResponse = e.response.data;
        }
      } catch (e) {}

      throw e;
    } finally {
      this.isLoggingIn = false;
    }
  }

  @action
  getFintechRedirectData = () => {
    this.loggingErrorResponse = undefined;
    this.isLoggingIn = true;

    try {
      Auth.getFintechRedirectData().then((response) => {
        const loginWithSberIDRedirectURL = response.data.payload[0].message;
        if (loginWithSberIDRedirectURL) {
          window.location.href = loginWithSberIDRedirectURL;
        } else {
          throw new Error('Unable to fetch Sberbank ID login URL data.');
        }
      });
      // Конец авторизации в методе loginWithSberID
    } catch (e) {
      this.isLoggingIn = false;
      throw e;
    }
  }

  @action
  getAuthTechFintechRedirectData = () => {
    this.loggingErrorResponse = undefined;
    this.isLoggingIn = true;

    try {
      Auth.getAuthTechFintechRedirectData().then((response) => {
        const loginWithSberIDRedirectURL = response.data.payload[0].message;
        if (loginWithSberIDRedirectURL) {
          window.location.href = loginWithSberIDRedirectURL;
        } else {
          throw new Error('Unable to fetch Sberbank ID login URL data.');
        }
      });
    } catch (e) {
      this.isLoggingIn = false;
      throw e;
    }
  }

  @action
  storeSbbIDData = (sbbIDData: ISbbIDLoginData) => {
    this.sbbIDLoginData = sbbIDData;
  }

  @action
  loginWithSberID = async () => {
    this.isProfileLoading = true;
    this.isLoggingIn = true;

    try {
      const response = await Auth.loginWithSberID(this.sbbIDLoginData);
      saveToLocaleStorage('token', response.data.token);

      this.shouldChangePassword = false;
      this.isAuthorized = true;
      this.sbbIDLoginData = null;

      /**
       * Отправка события login_success для GA
       */
      setGtm('login_success');
    } catch (e) {
      this.isAuthorized = false;
      this.loggingErrorResponse = e.response.data;

      throw e;
    } finally {
      this.isLoggingIn = false;
    }
  }

  @action
  loginTechWithSberID = async () => {
    this.isLoggingIn = true;
    try {
      const response = await Auth.loginTechWithSberID(this.sbbIDLoginData);
      if (!response.data.errorData.errorFlag) {
        this.isTechnicalLogin = true;
      }
    } catch (e) {
      this.loggingErrorResponse = e.response.data;
      this.isTechnicalLogin = false;
      throw e;
    } finally {
      this.isLoggingIn = false;
    }
  }

  @action
  logout = async () => {
    this.isLoggingOut = true;

    try {
      await Auth.logout();
      localStorage.removeItem('token');
      localStorage.removeItem(mobileScreenWasShownConst);
      localStorage.removeItem('isSbbolMobileScreen');
      this.isAuthorized = false;
    } catch (e) {
      this.isAuthorized = false;
      localStorage.removeItem('token');
      throw e;
    } finally {
      this.isLoggingOut = false;
    }
  }

  @action
  check = async () => {
    try {
      await Auth.check();
      this.isAuthorized = true;
    } catch (e) {
      this.isAuthorized = false;
      throw e;
    }
  }

  @action
  changePassword = async (password: string) => {
    this.isPasswordChanging = true;
    this.isPasswordChangingError = false;

    try {
      const response = await Auth.getPasswordEncryptionKey();
      const { enabled, key } = response.data.payload[0];

      if (enabled && key) {
        // @ts-ignore
        await Auth.changePassword(encrypt(password, key), true);
      } else {
        await Auth.changePassword(password);
      }
    } catch (e) {
      this.isPasswordChangingError = true;
      throw e;
    } finally {
      this.isPasswordChanging = false;
    }
  }

  @action
  mandatoryChangePassword = async (
    login: string,
    oldPassword: string,
    newPassword: string
  ) => {
    this.isPasswordChanging = true;
    this.isPasswordChangingError = false;

    try {
      const response = await Auth.getPasswordEncryptionKey();
      const { enabled, key } = response.data.payload[0];

      if (enabled && key) {
        await Auth.mandatoryChangePassword(
          // @ts-ignore
          encrypt(login, key),
          encrypt(oldPassword, key),
          encrypt(newPassword, key),
          true,
          true
        );
      } else {
        await Auth.mandatoryChangePassword(login, oldPassword, newPassword);
      }
    } catch (e) {
      this.isPasswordChangingError = true;
      throw e;
    } finally {
      this.isPasswordChanging = false;
    }
  }

  @action
  checkCode = async () => {
    this.isCodeChecking = true;
    this.isInstalledSuccessfully = false;

    try {
      await Auth.checkCodeInstalled();
      this.isInstalledSuccessfully = true;
    } catch (e) {
      throw e;
    } finally {
      this.isCodeChecking = false;
    }
  }

  @action
  addDomain = async (domain: string) => {
    this.isAddingDomain = true;

    try {
      const model: IDomainInfo = {
        '@type': 'DomainInfo',
        'origin': domain,
        'status': 'CREATE',
      };
      await Auth.addDomain(model);
    } catch (e) {
      throw e;
    } finally {
      this.isAddingDomain = false;
    }
  }

  @action
  setPhone = (phone: string) => {
    this.registrationPhoneError = '';
    this.phone = phone;
  }

  @action
  setIsRecovery = (isRecovery: boolean) => {
    this.isRecovery = isRecovery;
  }

  @action
  setIsRegistration = (isRegistration: boolean) => {
    this.isRegistration = isRegistration;
  }

  sendPhoneNumber = async () => {
    this.isFetchingOnRegistration = true;
    this.registrationPhoneError = '';

    try {
      await Auth.sendPhoneNumber({
        phone: this.phone.replace(/[^\d]/g, ''),
        reason: this.isRecovery ? 'FORGOT_PASSWORD' : 'REGISTRATION',
      });
      this.registrationStep = 2;
    } catch (e) {
      this.registrationPhoneError = getError({
        error: e,
        defaultError: 'Ошибка получения оферты, попробуйте позже.',
      });
    } finally {
      this.isFetchingOnRegistration = false;
    }
  }

  sendCode = async () => {
    this.isFetchingOnRegistration = true;
    this.smsCodeError = '';

    try {
      await Auth.sendCode({
        code: this.smsRegCode,
      });

      return true;
    } catch (e) {
      this.smsCodeError = getError({
        error: e,
        defaultError: 'Ошибка подтверждение кода регистрации.',
      });

      return false;
    } finally {
      this.isFetchingOnRegistration = false;
    }
  }

  @action
  setSmsRegCode = (smsRegCode: string) => {
    if (!checkErrorHasTime(this.smsCodeError)) {
      this.smsCodeError = '';
    }
    this.smsRegCode = smsRegCode;
  }

  @action
  setPasswordError = (error: string) => {
    this.passwordError = error;
  }

  sendPassword = async (data: ISendPassword) => {
    this.isFetchingOnRegistration = true;
    try {
      await Auth.sendPassword(data);

      if (!this.isRecovery) {
        const login = this.phone.replace(/[^\d]/g, '');
        await this.login(login, data.password);
      }

      this.clearRegistrationData();

      return true;
    } catch (e) {
      this.passwordError = getError({
        error: e,
        defaultError: 'Ошибка установки пароля',
      });

      return false;
    } finally {
      this.isFetchingOnRegistration = false;
    }
  }

  sendForgotPassword = async (password: string) => {
    this.isFetchingOnRegistration = true;
    try {
      await Auth.sendForgotPassword(password);

      if (!this.isRecovery) {
        const login = this.phone.replace(/[^\d]/g, '');
        await this.login(login, password);
      }

      this.clearRegistrationData();

      return true;
    } catch (e) {
      this.passwordError = getError({
        error: e,
        defaultError: 'Ошибка установки пароля',
      });

      return false;
    } finally {
      this.isFetchingOnRegistration = false;
    }
  }

  clearRegistrationData = () => {
    this.registrationPhoneError = '';
    this.smsCodeError = '';
    this.offerError = '';
    this.passwordError = '';

    this.registrationStep = 1;
    this.offer = [];
    this.isOfferFetching = false;
    this.isFetchingOnRegistration = false;
    this.phone = '';
    this.smsRegCode = '';
    this.isRecovery = false;
  }

  getPaymentUrl = async () => {
    try {
      const result = await Auth.getPaymentUrl();

      return result?.data?.payload[0].paymentUrl;
    } catch (e) {
      this.paymentError = getError({ error: e });

      return false;
    }
  }

  checkPayment = async () => {
    try {
      await Auth.checkPaymentUrl();

      return true;
    } catch (e) {
      this.paymentError = getError({ error: e });

      return false;
    }
  }

  getFormattedPhone = () => {
    if (this.phone) {
      return `+${this.phone[0]} (${this.phone[1]}${this.phone[2]}${this.phone[3]}) ${this.phone[4]}${this.phone[5]}${this.phone[6]}-${this.phone[7]}${this.phone[8]}-${this.phone[9]}${this.phone[10]}`;
    }

    return '';
  }
}

export default new AuthStore();
