import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from './client';

const Mailing = {
  getList: (): AxiosPromise<MailingListResponse> =>
    actionGet('/admin/emailDistribution', { type: 'ABANDONED_CARD' }),

  start: (id: string | number): AxiosPromise<MailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'start', id }, null),

  stop: (id: string | number): AxiosPromise<MailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'stop', id }, null),

  delete: (id: string | number): AxiosPromise<MailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'delete', id }, null),

  editTitle: (newName: string, id: string | number): AxiosPromise<MailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'rename', newName, id }, null),

  // TODO typings for payload
  save: (payload: any): AxiosPromise<MailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'save' }, payload),

  unsubscribe: (utmContent: string): AxiosPromise<IResponse> =>
    actionSet('/api/unsubscribe?utm_content=' + utmContent, {}, null),

  sendTestLetter: (receiverEmail: string, id: number | string, payload: IDataToSend): any =>
    actionSet('/admin/emailDistribution', { action: 'sendTestLetter', id, receiverEmail }, payload),
};

export default Mailing;
