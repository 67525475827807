import React, { ReactNode } from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import styles from './style.module.css';

interface IDubleInfoBlockProps {
  topContent?: ReactNode;
  bottomContent: ReactNode;
  className?: string;
}

const DubleInfoBlock: React.FC<IDubleInfoBlockProps> = ({
  topContent,
  bottomContent,
  className = null,
}) => (
  <NumberedBlock className={className ? className : ''}>
    {topContent && (
      <>
        <div>{topContent}</div>
        <div className={styles.line} />
      </>
    )}

    <div>{bottomContent}</div>
  </NumberedBlock>
);

export default DubleInfoBlock;
