import { getFromLocaleStorage } from '~/utils/localStorageUtils/getFromLocaleStorage';

type TLocalStorageValue = {[key: string]: any} | boolean;

export const getObjectFromLocaleStorage = <T = TLocalStorageValue>(key: TLocalStorageKeys): T | false => {
  try {
    const stringData = getFromLocaleStorage(key);

    return JSON.parse(stringData) as T;
  } catch (e) {
    console.log('getObjectFromLocaleStorage error', e);

    return false;
  }
};
