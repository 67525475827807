import { observer } from 'mobx-react';
import React from 'react';
import Input from '~/components/ui/Input';
import InputDropdown from '~/components/ui/InputDropdown';
import InputGroup from '~/components/ui/InputGroup';
import { MailingStore } from '~/screens/MailingScreen/stores/mailingStore';
import pluralize from '~/utils/pluralize';
import useStores from '~/utils/useStores';
import styles from '../style.module.css';

export interface ITestTime {
  key: 'hour' | 'day';
}

export const formatTime: ITestTime[] = [{ key: 'hour' }, { key: 'day' }];

const validateMinValue = (time: string) => !(parseInt(time, 10) < 1 || !time.length);

const validateOnMaxValue = (time: string, timeFormat: string) => {
  if (timeFormat === 'day' && parseInt(time, 10) > 15) {
    return false;
  }

  return !(timeFormat === 'hour' && parseInt(time, 10) > 360);
};

export const SendInterval: React.FC = observer(({}) => {
  const { mailingStore } = useStores() as {
    mailingStore: MailingStore;
  };

  const { mailingInstance, isIntervalInValid, setIsIntervalInValid } = mailingStore;
  const timeObj = mailingInstance.get('time');
  const time = timeObj?.value?.toString() || '1';
  const format = timeObj?.format?.toLowerCase() || 'minute';

  const setTime = (value: string) =>
    mailingInstance.update({
      time: { ...timeObj, value: parseInt(value, 10) || 0 },
    });

  const setFormat = (format: string) =>
    mailingInstance.update({
      time: { ...timeObj, format: format.toUpperCase() as TMailingTimeFormat },
    });


  const defaultTimeFormat = formatTime[0].key;

  const getFormatTimeValue = (key: string): any => {
    if (key === 'hour') return pluralize(Number(time), 'Час', 'Часа', 'Часов');
    if (key === 'day') return pluralize(Number(time), 'День', 'Дня', 'Дней');
  };


  const changeTime = (v: string) => {
    setTime(v);
    if (!validateMinValue(v) || !validateOnMaxValue(v, format)) {
      setIsIntervalInValid(true);
    } else {
      setIsIntervalInValid(false);
    }
  };

  const changeFormat = (v: string) => {
    setFormat(v);
    if (!validateOnMaxValue(time, v)) {
      setIsIntervalInValid(true);
    } else {
      setIsIntervalInValid(false);
    }
  };

  return (
    <div className={styles.tabSendSection}>
      <h3>
        Через какое время отправлять письмо, после того как посетитель бросил
        корзину?
      </h3>
      <p>
        Корзина считается брошенной, когда посетитель ушел с сайта и его сессия
        завершилась. Мы рекомендуем выставить значение от 1 до 3 дней.
      </p>
      <InputGroup>
        <Input
          value={time ? time.toString().replace(/[^0-9]/g, '') : ''}
          maxLength={4}
          style={{ width: 80 }}
          onChangeValue={changeTime}
        />
        <InputDropdown
          className="time-step__dropdown"
          selectedKey={format || defaultTimeFormat}
          items={formatTime.map((e: ITestTime) => ({
            key: e.key,
            value: getFormatTimeValue(e.key),
          }))}
          onChange={changeFormat}
          theme="light"
          overlayPlacement="bottom"
        />
      </InputGroup>
      {isIntervalInValid && (
        <div className="time-step__error">Минимальное значение — 1 час. Максимальное значение — 15 дней</div>
      )}
    </div>
  );
});
