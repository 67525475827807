import { saveToLocaleStorage } from '~/utils/localStorageUtils/saveToLocaleStorage';

export const saveObjectToLocaleStorage = (
  key: TLocalStorageKeys,
  value: any
) => {
  try {
    const stringifiedData = JSON.stringify(value);
    saveToLocaleStorage(key, stringifiedData);
  } catch (e) {
    console.log('saveObjectToLocaleStorage', 'error', e);
  }
};
