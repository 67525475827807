import cx from 'classnames';
import React from 'react';
import Icon, { Icons } from '~/components/Icon';
import Spinner from '~/components/ui/Spinner';
import getTextColorByBackground from '~/utils/getTextColorByBackground';
import './style.css';

export type TButton = 'default' | 'main' | 'white' | 'grey' | 'lightgrey' | 'outline' | 'text' | 'black-outline' | 'green-outline' | 'sber';

export interface ButtonLikeProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => void | PromiseLike<any>;
  icon?: Icons;
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
  borderless?: boolean;
  withInteractions?: boolean;
  size?: FormElementSize;
  type?: TButton;
  tag?: 'div' | 'button' | 'a';
  htmlType?: 'submit' | 'button' | 'reset';
  color?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const ButtonLike: React.FC<ButtonLikeProps> = React.forwardRef((props, ref) => {
  const {
    children,
    style,
    className,
    onClick,
    icon,
    block,
    disabled,
    loading,
    borderless,
    withInteractions,
    size = 'default',
    type = 'main',
    tag = 'div',
    htmlType,
    color,
  } = props;
  const isOnlyIcon = icon && !children;

  let styleProp = style;

  if (color) {
    styleProp = {
      backgroundColor: color,
      borderColor: color,
      color: getTextColorByBackground(color),
      ...style,
    };
  }

  const classNames = cx('button-like', {
    [`_type-${type}`]: type,
    [`_size-${size}`]: size,
    '_icon': icon,
    '_only-icon': isOnlyIcon,
    '_block': block,
    '_loading': loading,
    '_disabled': disabled,
    '_with-interactions': withInteractions,
    '_borderless': borderless,
  }, className);

  const spinnerColor = type === 'main' || type === 'sber' ? 'white' : 'black';

  const buttonLikeBody = (
    <>
      {loading && type !== 'text' && (
        <Spinner type="button" color={spinnerColor} className="button-like__spinner" />
      )}
      <div className="button-like__container">
        {icon && (
          <div className="button-like__icon">
            <Icon className="button-like__svg" name={icon} />
          </div>
        )}
        {children && (
          <div className="button-like__text">
            {children}
          </div>
        )}
      </div>
    </>
  );

  let buttonLike;

  if (tag === 'a') {
    buttonLike = (
      <a
        className={classNames}
        onClick={onClick}
        style={styleProp}
      >
        {buttonLikeBody}
      </a>
    );
  }

  if (tag === 'div') {
    buttonLike = (
      <div
        className={classNames}
        onClick={onClick}
        style={styleProp}
        ref={ref}
      >
        {buttonLikeBody}
      </div>
    );
  }

  if (tag === 'button') {
    buttonLike = (
      <button
        type={htmlType}
        className={classNames}
        onClick={onClick}
        disabled={disabled || loading}
        style={styleProp}
      >
        {buttonLikeBody}
      </button>
    );
  }

  return buttonLike;
});

export default ButtonLike;
