import React from 'react';

export const header = 'Добро пожаловать в раздел «Слепые зоны»';

export const subheader = (
  <>
    <p>
      Инструмент определит и покажет страницы сайта, на которые посетители не
      заходили за определённый промежуток времени.
    </p>
    <p>
      Зная свои слепые зоны и пользуясь СберЛид, вы сможете:
    </p>
  </>
);

export const items = [
  {
    iconSrc: 'DemoBlindZones',
    text: 'повысить эффективность сайта, направляя клиентов на нужные страницы',
  },
  {
    iconSrc: 'DemoBlindZones',
    text: 'улучшить навигацию по сайту',
  },
  {
    iconSrc: 'DemoBlindZones',
    text: 'ускорить загрузку и снизить затраты на поддержку, удаляя не нужные страницы',
  },
];

export const text1 = 'Для запуска инструмента надо лишь добавить sitemap к вашему сайт и первые данные будут доступны уже на следующий день.';

export const text2 = 'Подключение sitemap улучшает вероятность выдачи вашего сайта в поисковых системах.';