import React from 'react';
import { QuizType } from '~/screens/QuizScreen/components/constructorSteps/SelectQuizType/QuizType';
import { quizTypes } from '~/screens/QuizScreen/consts';
import styles from './style.module.css';

export const SelectQuizType = () => {
  return (
    <div className={styles.quizTypes}>
      {quizTypes.map((props, key) => (
        <QuizType {...props} key={key} />
      ))}
    </div>
  );
};
