import moment from 'moment';
import { DATE_FORMAT } from '~/constants';

const getDatesByPreset = (preset: DatePreset, customDates?: [string, string]): [string, string] | [] => {
  const yesterday = moment()
    .subtract(1, 'day')
    .format(DATE_FORMAT);

  switch (preset) {
    case 'all':
      return ['2020-01-01', yesterday];

    case 'yesterday':
      return [yesterday, yesterday];

    case 'today':
      const today = moment().format(DATE_FORMAT);

      return [today, today];

    case 'week':
      const weekAgo = moment()
        .subtract(1, 'week')
        .format(DATE_FORMAT);

      return [weekAgo, yesterday];

    case 'month':
      const monthAgo = moment()
        .subtract(30, 'days')
        .format(DATE_FORMAT);

      return [monthAgo, yesterday];

    case 'year':
      const yearAgo = moment()
        .subtract(365, 'days')
        .format(DATE_FORMAT)
      ;
      
      return [yearAgo, yesterday];
  }

  return customDates || [];
};

export default getDatesByPreset;
