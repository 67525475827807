import { action, observable } from 'mobx';
import Service from '~/api/service';
import Store from '~/stores/store';

export enum EAvailableServices {
  SCENARIO_SRV = 'SCENARIO_SRV',
  FEED_LITE_SRV = 'FEED_LITE_SRV',
  FORTUNE_WHEEL_SRV = 'FORTUNE_WHEEL_SRV',
  QUIZ_SRV = 'QUIZ_SRV',
}

export type TCurrentServices = {
  '@type': 'TotServiceDto';
  code: EAvailableServices;
  dateFrom: string;
  dateTo: string;
};

export class ServiceStore extends Store {
  @observable currentStep: number;
  @observable availableServices: EAvailableServices[] = [
    'SCENARIO_SRV',
    'FEED_LITE_SRV',
    'FORTUNE_WHEEL_SRV',
    'QUIZ_SRV',
  ] as EAvailableServices[];
  @observable currentServices: TCurrentServices[] = [] as TCurrentServices[];
  @observable isSetService = false;
  @observable isFetchService = false;
  @observable isFetchCurrentServices = false;
  @observable serviceSelection = {
    SCENARIO_SRV: false,
    FEED_LITE_SRV: false,
    FORTUNE_WHEEL_SRV: false,
    QUIZ_SRV: false,
  };

  constructor() {
    super();
  }

  @action
  changeCurrentStep = (step: number) => {
    this.currentStep = step;
  }

  @action
  setServiceSelection = (selection: any) => {
    this.serviceSelection = selection;
  }

  @action
  getCurrentServices = async () => {
    this.isFetchCurrentServices = true;

    try {
      const response = await Service.getCurrentServices();
      this.currentServices = response.data.payload;
    } catch (e) {
      throw e;
    } finally {
      this.isFetchCurrentServices = false;
    }
  }

  @action
  setService = async () => {
    this.isSetService = true;
    try {
      await Service.setServices(this.searchService());
    } catch (e) {
      throw e;
    } finally {
      this.isSetService = false;
    }
  }

  searchService = () => {
    const arr = [];
    for (const [key, value] of Object.entries(this.serviceSelection)) {
      if (value) {
        arr.push(key);
      }
    }

    return arr;
  }
}

export default new ServiceStore();
