import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import useStores from '~/utils/useStores';

const Footer: React.FC = observer(() => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };

  const {
    setIsMessageBeforeExitingOpen,
    setIsConstructorOpen,
    constructorStore,
    feedApi,
    isInvalidYmlUrl,
    getFeedApiStatistics,
    isConfigInvalid,
    onClickActionButton,
    blinkFinalDialog,
  } = feedApiStore;
  const {
    currentStep,
    changeCurrentStepNextOrBack,
  } = constructorStore;

  const handleSave = async () => {
    try {
      await feedApi.saveFeedApi();
      setIsConstructorOpen(false);
      getFeedApiStatistics();
      blinkFinalDialog();
    } catch (error) {}
  };

  return (
    <DrawerFooter
      actions={(
        <>
          {currentStep === 'StepFeedType' && (
            <>
              <Button
                type={'grey'}
                onClick={() => {
                  feedApi.hasDataChange()
                    ? setIsMessageBeforeExitingOpen(true)
                    : setIsConstructorOpen(false);
                }}
              >
                Отмена
              </Button>
              <Button
                disabled={!feedApi.get('recType')}
                onClick={() => changeCurrentStepNextOrBack(1)}
              >
                Далее
              </Button>
            </>
          )}
          {currentStep === 'StepCatalog' && (
            <>
              {!isConfigInvalid && (
                <>
                  <Button
                    type={'grey'}
                    onClick={() => changeCurrentStepNextOrBack(-1)}
                  >
                    Назад
                  </Button>
                  <Button
                    disabled={
                      !feedApi.get('recType') ||
                      isInvalidYmlUrl === null ||
                      isInvalidYmlUrl
                    }
                    onClick={() => changeCurrentStepNextOrBack(1)}
                  >
                    Далее
                  </Button>
                </>
              )}
              {isConfigInvalid && (
                <>
                  <Button
                    className="feed-screen__drawer-next-button"
                    onClick={() => onClickActionButton('fix', null)}
                    disabled={false}
                  >
                    Исправить
                  </Button>
                </>
              )}
            </>
          )}
          {currentStep === 'StepManual' && (
            <>
              <Button
                type={'grey'}
                onClick={() => changeCurrentStepNextOrBack(-1)}
              >
                Назад
              </Button>
              <Button disabled={!feedApi.get('recType')} onClick={handleSave}>
                Сохранить
              </Button>
            </>
          )}
        </>
      )}
    />
  );
});

export default Footer;
