import { action, computed, observable } from 'mobx';
import { Users } from '~/api';
import Model from '~/models/Model';
import rolesStore from '~/stores/rolesStore';

export default class UserModel extends Model<IUser> {
  @observable isFetching = false;
  @observable isSaving = false;
  @observable isRemoving = false;

  hasRightTo = (rightCode: IRightCode) => {
    return this.rightsModel?.includes(rightCode);
  }

  hasAccessTo = (screenCode: string) => {
    return true;
  }

  @action
  fetch = async () => {
    this.isFetching = true;

    try {
      const response = await Users.get(this.get('id'));
      this.update(response.data.payload[0]);
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  @action
  save = async () => {
    this.isSaving = true;

    try {
      if (this.has('id')) {
        const response = await Users.update(this.get('id'), this.copy());
        this.update(response.data.payload[0]);
      } else {
        const response = await Users.create(this.copy());
        this.update(response.data.payload[0]);
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  remove = async () => {
    this.isRemoving = true;

    try {
      this.update({ state: 'INACTIVE' });
      await this.save();
    } catch (e) {
      throw e;
    } finally {
      this.isRemoving = false;
    }
  }

  @computed
  get abbreviation() {
    const firstName = this.get('firstName') ? this.get('firstName')[0] : '';
    const lastName = this.get('lastName') ? this.get('lastName')[0] : '';
    if (firstName || lastName) {
      return `${lastName}${firstName}`.toUpperCase();
    } else {
      if (this.get('login')) {
        return this.get('login')[0].toUpperCase();
      }

      return '';
    }
  }

  @computed
  get needToChange() {
    if (this.get('needToChange') === 1) return true;
    if (this.get('needToChange') === 0) return false;

    return undefined;
  }

  @computed
  get isNew() {
    return !this.has('id');
  }

  @computed
  get fullName() {
    const name: string[] = [];

    const lastName = this.get('lastName');
    const middleName = this.get('middleName');
    const firstName = this.get('firstName');

    if (lastName) name.push(lastName);
    if (firstName) name.push(firstName);
    if (middleName) name.push(middleName);

    return name.join(' ');
  }

  @computed
  get role() {
    if (!this.get('role')) return '—';

    const role = rolesStore?.getRoleById(this.get('role').id);

    return role?.get('name');
  }

  @computed
  get rights(): IRightCode[] {
    if (!this.get('role')) return [];

    const role = rolesStore.getRoleById(this.get('role').id);

    return role?.get('rights').map(right => rolesStore?.getRightById(right.id)?.get('code'));
  }

  @computed
  get rightsModel(): IRightCode[] {
    return this.get('role')?.rights?.map(e => e.code);
  }

  @computed
  get key() {
    return this.get('id');
  }

  @computed
  get lastLoginDate() {
    if (!this.get('lastLoginDate')) return '';

    return this.get('lastLoginDate');
  }

  @computed
  get isFintech() {
    if (!this.get('isFintech')) return false;

    return this.get('isFintech');
  }

  @computed
  get login() {

    return this.get('login');
  }
}
