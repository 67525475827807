import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

type TMassMailingListResponse = IResponse<IMassMailingTemplateWithStatistic[]>;

const MassMailingApi = {
  getList: (): AxiosPromise<TMassMailingListResponse> =>
    actionGet('/admin/emailDistribution', { type: 'MASS_MAIL' }),

  start: (id: string | number): AxiosPromise<TMassMailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'start', id }, null),

  stop: (id: string | number): AxiosPromise<TMassMailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'stop', id }, null),

  delete: (id: string | number): AxiosPromise<TMassMailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'delete', id }, null),

  editTitle: (newName: string, id: string | number): AxiosPromise<TMassMailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'rename', newName, id }, null),

  save: (payload: IMassMailingToSendTemplate): AxiosPromise<TMassMailingListResponse> =>
    actionSet('/admin/emailDistribution', { action: 'save' }, payload),

  sendTestLetter: (receiverEmail: string, id: number | string, payload: IMassMailingToSendTemplate): any =>
    actionSet('/admin/emailDistribution', { action: 'sendTestLetter', id, receiverEmail }, payload),
};

export default MassMailingApi;
