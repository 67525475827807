import { Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { getColumns, ColumnKey } from '~/components/generic/FeedStatisticTable/columns';
import parentWithTheClass from '~/utils/parentWithTheClass';
import './style.css';

const FeedStatisticTable: React.FC<{
  heightTableBlock: number;
  wightTableBlock: number;
  statistics: (FeedProStatistics | FeedApiStatistics | FeedLiteStatistics)[];
  onClickActionButton: (
    actionType: FeedActionButtonType,
    statistics: FeedProStatistics | FeedApiStatistics | FeedLiteStatistics
  ) => void;
  isFetchingRequest: boolean;
  excludedСolumns?: ColumnKey[];
}> = observer(
  ({
    heightTableBlock,
    wightTableBlock,
    statistics,
    onClickActionButton,
    isFetchingRequest,
    excludedСolumns = [],
  }) => {
    /**
     * Подсвечивает кнопку меню
     * @param event
     */
    const showSupportingButtons = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
      const el = parentWithTheClass(event.target as Node, 'ant-table-row');
      if (el) {
        const supportButtons = el.querySelector('.support');
        if (supportButtons) {
          supportButtons.classList.add('_hover__');
        }
      }
    };

    /**
     * Отменяет подсветку кнопки меню
     * @param event
     */
    const hideSupportingButtons = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
      const el = parentWithTheClass(event.target as Node, 'ant-table-row');
      if (el) {
        const supportButtons = el.querySelector('.support');
        supportButtons?.classList.remove('_hover__');
      }
    };

    return (
      <div>
        {!!heightTableBlock && (
          <Table
            className="generic-feed-statistic__table"
            columns={getColumns({
              onClickActionButton,
              isFetchingRequest,
            }).filter(
              column => !excludedСolumns.includes(column.key as ColumnKey)
            )}
            dataSource={statistics}
            rowKey={(feedStats: FeedApiStatistics) => feedStats.id.toString()}
            onRow={(currentFeedApi: FeedApiStatistics) => {
              return {
                onClick: () => {
                  onClickActionButton('info', currentFeedApi);
                },
                onMouseEnter: (event) => {
                  showSupportingButtons(event);
                },
                onMouseLeave: (event) => {
                  hideSupportingButtons(event);
                },
              };
            }}
            tableLayout={'fixed'}
            scroll={{ x: wightTableBlock, y: heightTableBlock }}
            pagination={false}
          />
        )}
      </div>
    );
  }
);

export default FeedStatisticTable;
