import React from 'react';
import Icon from '~/components/Icon';
import Dropdown from '~/components/ui/Dropdown';
import { BusinessRuleMeta } from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const Parameter: React.FC<{
  index: number;
  metadata: BusinessRuleMeta;
  selectedItemKey: string;
  onSelect: (key: string) => void;
  onDelete: (index: number) => void;
}> = ({ index, metadata, selectedItemKey, onSelect, onDelete }) => {
  return (
    <div className={styles.wrapper}>
      <Dropdown
        className={styles.dropdown}
        items={metadata.similarityTypeDict.map((similarity) => {
          return {
            key: similarity.key,
            value: similarity.uiText,
          };
        })}
        size={'large'}
        selectedItemKey={selectedItemKey}
        onSelect={onSelect}
      />
      <div
        className={styles.delete}
        onClick={() => onDelete(index)}
      >
        <Icon name={'Trash'} />
      </div>
    </div>
  );
};

export default Parameter;
