import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

const MailingAuthorizationEmailBlockApi = {
  getEmailSenders: (): AxiosPromise<IEmailSenders> =>
    actionGet('/admin/emailSender', {}),

  approveEmail: (email: string): AxiosPromise<IResponse> =>
    actionSet('/admin/emailSender', { action: 'resendValidateLetter', email }, null),

  checkEmail: (email: string): AxiosPromise<IEmailSenders> =>
    actionSet('/admin/emailSender', { action: 'check', email }, null),
};

export default MailingAuthorizationEmailBlockApi;
