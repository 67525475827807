import { IFeedConfiguration } from 'feed';
import React from 'react';
import { SwitchUI } from '~/components/SwitchUI';
import Label from '~/components/notification-editor/LabelColorEditor/Label';
import FeedConstructorModel from '~/models/FeedConstructorModel';
import floatNumberWithSpaces from '~/utils/floatNumberWithSpaces';

interface Props {
  productModel: FeedConstructorModel;
  feedConfiguration: IFeedConfiguration;
  witNothVisibleLink?: boolean;
}

const ProductCard: React.FC<Props> = ({
  productModel,
  feedConfiguration,
  witNothVisibleLink = false,
}) => {
  return (
    <>
      <div className="feed-constructor__table-card-image">
        <img
          src={productModel.get('imgLink')}
        />
      </div>
      <div className="feed-constructor__table-card__wrapper">
        <div className="feed-constructor__table-card__wrapper__text-box">
          <div className="feed-constructor__table-card__wrapper-title">
            {productModel.get('name')}
          </div>
          {productModel.get('offerId') && (
            <div className="feed-constructor__table-card__wrapper-offer">
              ({productModel.get('offerId')})
            </div>
          )}
        </div>
        <div className="feed-constructor__table-card__wrapper-description">
          {productModel.get('description')}
        </div>
        {!witNothVisibleLink && (
          <div className="feed-constructor__table-card__wrapper-link">
            <a target="_blank" href={productModel.get('urlLink')}>
              {productModel.get('urlLink')}
            </a>
          </div>
        )}
        <SwitchUI elementCode={'FeedLiteLabels'}>
          <div className="feed-constructor__table-card__wrapper-label">
            {productModel.get('labels')?.map((labelUuid) => {
              const labelForDrawing = feedConfiguration.labels.find(
                label => label.uuid === labelUuid
              );

              return labelForDrawing ? (
                <Label
                  text={labelForDrawing?.text}
                  labelBackgroundColor={labelForDrawing?.labelBackgroundColor}
                  labelBackgroundOpacity={
                    labelForDrawing?.labelBackgroundOpacity
                  }
                  labelShadow={false}
                  labelTextColor={feedConfiguration.labelTextColor}
                  labelTextColorOpacity={
                    feedConfiguration.labelTextColorOpacity
                  }
                  labelFontFamily={feedConfiguration.labelFontFamily}
                  labelFontWeight={feedConfiguration.labelFontWeight}
                  labelFontSize={feedConfiguration.labelFontSize as INotificationFontSize}
                  labelFontItalic={feedConfiguration.labelFontItalic}
                  labelTextLineThrough={feedConfiguration.labelTextLineThrough}
                  labelTextUnderline={feedConfiguration.labelTextUnderline}
                  key={labelUuid}
                />
              ) : (
                ''
              );
            })}
          </div>
        </SwitchUI>
        <div className="feed-constructor__table-card__wrapper-price">
          <div className="feed-constructor__table-card__price">
            {productModel.get('price') &&
              `${floatNumberWithSpaces(productModel.get('price'))} ₽`}
          </div>
          <div className="feed-constructor__table-card__crossed-price">
            {productModel.get('crossedPrice') &&
              `${floatNumberWithSpaces(productModel.get('crossedPrice'))} ₽`}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
