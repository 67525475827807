import { computed, observable } from 'mobx';
import Model from '~/models/Model';

export default class TariffModel extends Model<ITariff> {
  @observable isFetching = false;

  @computed
  get price() {
    return this.get('price');
  }

  get paymentDate() {
    return this.get('paymentDate');
  }

  @computed
  get code() {
    return this.get('code');
  }

  @computed
  get minVisit() {
    return this.get('ui').minVisit;
  }

  @computed
  get maxVisit() {
    return this.get('ui').maxVisit;
  }
}
