import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import SearchInput from '~/components/generic/SearchInput';
import EntityCount from '~/screens/BusinessRulesScreen/components/BusinessRuleEditor/SettingRule/RecommendationOfWhat/EntityCount';
import BusinessRulesModel from '~/screens/BusinessRulesScreen/model/BusinessRulesModel';
import {
  BusinessRuleEntity,
  BusinessRuleMeta,
  FilterBusinessRuleEntityRequest,
} from '~/screens/BusinessRulesScreen/types';
import styles from './style.module.css';

const RecommendationOfWhat: React.FC<{
  businessRule: BusinessRulesModel;
  metadata: BusinessRuleMeta;
  entities: BusinessRuleEntity;
  index: number;
  getBusinessRuleEntity: (
    parameters: FilterBusinessRuleEntityRequest
  ) => Promise<BusinessRuleEntity[]>;
}> = observer(
  ({ businessRule, metadata, entities, index, getBusinessRuleEntity }) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    const [businessRuleEntityList, setBusinessRuleEntityList] = useState<
      BusinessRuleEntity[]
    >([]);
    const [timer, setTimer] = useState<NodeJS.Timeout>(null);
    const [paginationPageNum, setPaginationPageNum] = useState<number>(0);
    const [isFailure, setIsFailure] = useState<boolean>(false);

    const checkValue = () => {
      setIsFailure(!businessRule.get('recommendationOfWhat').entities[index]?.id);
    };

    const requestBusinessRuleEntity = (
      currentQuery: string,
      paginationPageNum: number,
      start: boolean = false
    ): void => {
      getBusinessRuleEntity({
        recommendationForType: businessRule.get('recommendationOfWhat').type,
        ymlUrl: businessRule.get('ymlUrl'),
        query: currentQuery,
        paginationPageNum,
        paginationPageSize: metadata.paginationMaxPageSize,
      }).then((response: BusinessRuleEntity[]) => {
        let oldItem = businessRuleEntityList;
        if (start) oldItem = [];
        if (response.length === 0 && start)
          response.push({
            '@type': 'FilterBusinessRuleEntity',
            'id': 0,
            'name': '',
            'uiText': 'Ничего не найдено',
            'itemCount': 0,
          });
        setBusinessRuleEntityList([...oldItem, ...response]);
        setIsOpenDropdown(true);
      });
    };

    const startTimer = (currentQuery: string) => {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          requestBusinessRuleEntity(currentQuery, 0, true);
        }, metadata.inputSearchTimeoutInMilliseconds)
      );
      setPaginationPageNum(0);
    };

    const handleClickOutsite = () => {
      setBusinessRuleEntityList([]);
      setIsOpenDropdown(false);
    };

    return (
      <div className={styles.input_wrapper}>
        <div className={styles.search}>
          <SearchInput
            className="business-rule-editor-recomendation-for__search"
            isOpenDropdown={isOpenDropdown}
            onChange={(value) => {
              businessRule.setRecommendationOfWhat(index, {
                '@type': 'FilterBusinessRuleEntity',
                'id': 0,
                'name': '',
                'uiText': value,
                'itemCount':
                  businessRule.get('recommendationOfWhat').entities[index]
                    .itemCount ?? 1,
              });
              if (+value || value.length >= metadata.searchMinTextSymbols) {
                startTimer(value);
              }
            }}
            value={entities.uiText}
            resultSearchItem={businessRuleEntityList?.map(
              businessRuleEntity => ({
                key: businessRuleEntity.id,
                value: businessRuleEntity.uiText,
                disabled:
                  businessRule
                    .get('recommendationOfWhat')
                    .entities.map(entuty => entuty.id)
                    .includes(businessRuleEntity.id),
              })
            )}
            onClickSearchItem={(itemAttributes) => {
              businessRule.setRecommendationOfWhat(index, {
                ...businessRuleEntityList[itemAttributes.index],
                itemCount:
                  businessRule.get('recommendationOfWhat').entities[index]
                    .itemCount ?? 1,
              });
              setIsOpenDropdown(false);
              checkValue();
            }}
            placeholder={'Название или ID категории'}
            clickOutside={handleClickOutsite}
            scrolledToEnd={() => {
              const newPageNumer = paginationPageNum + 1;
              requestBusinessRuleEntity(
                businessRule.get('recommendationFor').entities.uiText,
                newPageNumer
              );
              setPaginationPageNum(newPageNumer);
            }}
            failure={isFailure}
            onBlur={checkValue}
          />
        </div>
        <div className={styles.product}>
          <EntityCount
            businessRule={businessRule}
            index={index}
            maxItemsInRule={metadata.maxItemsInRule}
          />
        </div>
        {index > 0 && (
          <div
            className={styles.delete}
            onClick={() => {
              businessRule.deleteEntity(index);
              checkValue();
            }}
          >
            <Icon name={'Trash'} />
          </div>
        )}
      </div>
    );
  }
);

export default RecommendationOfWhat;
