import React from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import FormItem from '~/components/ui/FormItem';
import RadioGroup from '~/components/ui/RadioButton/RadioGroup';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import { CheckedCountPage, FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import './../style.css';

interface INewProductsEmbeddingProps {
  urlPattern: string;
  onChange: ({ }: IPlacementPageParams) => void;
  isInvalid: boolean;
  onBlur: () => void;
  errorMessage: string;
  checkedCountPage: CheckedCountPage;
}

const NewProductsEmbedding: React.FC<INewProductsEmbeddingProps> = ({
  urlPattern,
  onChange,
  isInvalid,
  onBlur,
  errorMessage,
  checkedCountPage,
}) => {
  const { feedProStore } = useStores() as {
    feedProStore: FeedProStore;
  };
  const { feed } = feedProStore;
  const operation = feed.get('displayConditions')?.operation;
  const url = feed.get('displayConditions')?.url;
  const options = [
    { label: 'На одной любой странице сайта', value: 'ONE' },
    { label: 'На всех карточках товаров', value: 'MULTIPLE' },
  ];

  return (
    <div className="feed-editor-placement-pages">
      <NumberedBlock number={1}>
        <FormItem
          title="Где вы хотите разместить ленту?"
          noHTMLLabel
          noReservedPlaceForMessage
          className="feed-editor-placement-pages__form-item"
        />
        <RadioGroup
          className="feed-editor-placement-pages__radio-group"
          size="medium"
          onCheck={(checkedCountPage) => {
            onChange({ checkedCountPage: checkedCountPage as CheckedCountPage });
          }}
          items={options}
          checkedItem={checkedCountPage}
        />
      </NumberedBlock>

      <NumberedBlock number={2}>
        <SetCatalogConfigLink
          title={
            checkedCountPage === 'ONE'
              ? 'Вставьте ссылку на страницу'
              : 'Введите ссылку на любую карточку товара'
          }
          onChange={(urlPattern) => {
            onChange({
              urlPattern,
              url,
              checkedCountPage: checkedCountPage as CheckedCountPage,
              operation,
            });
          }}
          value={urlPattern}
          placeholder={'https://example.ru/product'}
          tooltipText={`Ссылка на страницу должна быть полной - включая «http://» или «https://»`}
          isInvalid={isInvalid}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
        {checkedCountPage === 'MULTIPLE' && (
          <div className="feed-editor-placement-pages__info">
            На основании этой ссылки мы автоматически распознаем остальные
            страницы всех товаров вашего сайта.&nbsp; На каждой странице товара
            будет своя лента рекомендаций.
          </div>
        )}
      </NumberedBlock>
    </div>
  );
};

export default NewProductsEmbedding;
