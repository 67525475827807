import { IFeedConfiguration } from 'feed';
import React from 'react';
import LabelColorEditor, {
  ILabelColorEditorData,
} from '~/components/notification-editor/LabelColorEditor';
import DraggableElements from '~/components/ui/DraggableElements';
import AliasHint from '~/screens/FeedProScreen/components/FeedProEditor/StepCatalog/BlockLabelsPro/LabelsList/AliasHint';
import './style.css';

interface ILabelsListProps {
  feedConfiguration: IFeedConfiguration;
  onChangeConfigurationParam: (params: Partial<IFeedConfiguration>) => void;
}

const LabelsList: React.FC<ILabelsListProps> = ({
  feedConfiguration,
  onChangeConfigurationParam,
}) => {

  const [isDraggable, setIsDraggable] = React.useState(true);

  const handleOpen = () => {
    setIsDraggable(false);
  };

  const handleClose = () => {
    setIsDraggable(true);
  };
  
  return (
    <DraggableElements
      className="feed-pro-editor-step-catalog__block-labels"
      elements={feedConfiguration.labels?.map((label) => {
        return (
          <LabelColorEditor
            key={label.uuid}
            text={label.text}
            labelBackgroundColor={label.labelBackgroundColor}
            labelBackgroundOpacity={label.labelBackgroundOpacity}
            feedConfiguration={feedConfiguration}
            onChangeColor={(data: ILabelColorEditorData) => {
              const labels = feedConfiguration.labels;
              labels.find(
                labelInConfiguration =>
                  labelInConfiguration.uuid === label.uuid
              ).labelBackgroundColor = data.labelColor;
              labels.find(
                labelInConfiguration =>
                  labelInConfiguration.uuid === label.uuid
              ).labelBackgroundOpacity = data.labelOpacity;
              onChangeConfigurationParam({ labels });
            }}
            isPossibleTextEditing
            onChangeName={(text: string) => {
              const labels = feedConfiguration.labels;
              labels.find(
                labelInConfiguration =>
                  labelInConfiguration.uuid === label.uuid
              ).text = text;
              onChangeConfigurationParam({ labels });
            }}
            step={10}
            isShowCheckBox
            checked={label.isActive}
            onCheck={(status) => {
              const labels = feedConfiguration.labels;
              labels.find(
                labelInConfiguration =>
                  labelInConfiguration.uuid === label.uuid
              ).isActive = status;
              onChangeConfigurationParam({ labels });
            }}
            isShowTrashButton
            onDelete={() => {
              const labels = feedConfiguration.labels;
              labels.splice(
                labels.findIndex(
                  labelInConfiguration =>
                    labelInConfiguration.uuid === label.uuid
                ),
                1
              );
              onChangeConfigurationParam({ labels });
            }}
            hasAlias
            aliasName={label.aliasName}
            onChangeAlias={(alias: string) => {
              const labels = feedConfiguration.labels;
              labels.find(
                labelInConfiguration =>
                  labelInConfiguration.uuid === label.uuid
              ).aliasName = alias;
              onChangeConfigurationParam({ labels });
            }}
            hint={
              <AliasHint aliasName={label.aliasName || label.text || 'Хит'} />
            }
            placeholder={'Признак из каталога'}
            isShowMoveIcon={!feedConfiguration?.labelPriorityOrderYml}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        );
      })}
      onChange={(newArray) => {
        const labels = newArray.map(objectNode => ({
          isActive: objectNode.props.checked,
          aliasName: objectNode.props.aliasName,
          uuid: objectNode.key,
          text: objectNode.props.text,
          labelBackgroundColor: objectNode.props.labelBackgroundColor,
          labelBackgroundOpacity: objectNode.props.labelBackgroundOpacity,
        }));
        onChangeConfigurationParam({ labels });
      }}
      isDraggable={!feedConfiguration?.labelPriorityOrderYml && isDraggable}
    />
  );
};

export default LabelsList;
