import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import { Instructions } from '~/screens/LeadsSettingsScreen/Instructions/Instructions';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { AmoCRMInstructions } from '~/screens/LeadsSettingsScreen/amo/AmoCRMInstructions';
import { leadsInstructionlist } from '~/screens/LeadsSettingsScreen/consts';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';


export const AmoCRMSettings = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;

  const isAmoCRMLoading = leadSettings.get('isAmoCRMLoading');
  const isAmoCRMSet = leadSettings.get('isAmoCRMSet');
  const amoCRMError = leadSettings.get('amoCRMError');

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isDisablePopupOpen, setIsDisablePopupOpen] = useState<boolean>(false);
  const [isAmoCRMErrorPopupOpen, setIsAmoCRMErrorPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    !isMounted && leadsStore.leadSettings.getAmoCRMIntegrationState();
  }, [isMounted]);

  useEffect(() => {
    amoCRMError && setIsAmoCRMErrorPopupOpen(true);
  }, [amoCRMError]);

  const isSuccessShow = isAmoCRMSet && !isAmoCRMLoading && !amoCRMError;
  const isPlugFormShow = !isAmoCRMSet && !isAmoCRMLoading;

  const disableAmoCRM = async () => {
    await leadSettings.disableAmoCRM();
    setIsDisablePopupOpen(false);
  };

  const disableAmoCRMError = () => {
    setIsAmoCRMErrorPopupOpen(false);
  };

  return (
    <div className="leads-settings__inner-container">
      {isAmoCRMLoading && (
        <Loading status={'Loading'} onClick={() => null} />
      )}
      {isPlugFormShow && (
        <>
          <h3 className="leads-settings__description">
            Для того, чтобы получать заявки в «amoCRM», вам нужно:
          </h3>
          <AmoCRMInstructions />
          <Instructions type={'amoCRM'} />
        </>
      )}
      {isSuccessShow && (
        <>
          <SettingsItem
            title="Ура! Теперь вы можете получать заявки с виджетов прямо в amoCRM!"
            isSuccess
          />
          <SettingsItem
            title="Инструкция по отключению"
            Content={(
              <>
                <div>
                  <p className="leads-settings__subtitle-content ">
                    Удалите интеграцию в Личном кабинете amoCRM (необязательно)
                  </p>
                  <ol>
                    {leadsInstructionlist.map((item, index) => (
                      <li key={index}>
                        {item}
                      </li>
                    ))}
                  </ol>
                </div>
                <Button
                  className="tgm-settings__content-btn"
                  type="grey"
                  onClick={() => setIsDisablePopupOpen(true)}
                  block
                >
                  Отключить сбор заявок в amoCRM
                </Button>
              </>
            )}
          />
        </>
      )}
      {isDisablePopupOpen && (
        <Message
          onApply={disableAmoCRM}
          visible={isDisablePopupOpen}
          title="Отключить сбор заявок в amoCRM?"
          textTop={(
            <div className="leads-settings__padding-wrap">
              При продолжении операции, заявки больше не будут приходить в личный кабинет amoCRM,
              пока Вы снова их не подключите.
            </div>
          )}
          applyText={'Отключить'}
          cancelText={'Отмена'}
          onClose={() => setIsDisablePopupOpen(false)}
          showIcon={false}
        />
      )}
      {isAmoCRMErrorPopupOpen && (
        <Message
          onApply={disableAmoCRMError}
          visible={isAmoCRMErrorPopupOpen}
          title={amoCRMError as string}
          applyText={'Понятно'}
          onClose={disableAmoCRMError}
          showIcon={false}
        />
      )}
    </div>
  );
});
