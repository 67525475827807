import { ButtonEditElement } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/ButtonEditElement';
import { ImageEditElement } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/ImageEditElement';
import {
  LetterEditElement
} from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/LetterEditElement';
import { MailingPlug } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/MailingPlug';
import { ProductItemElement } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/ProductItemElement';
import { TextEditElement } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/MailSettings/settingsElements/TextEditElement';

export const getSettingsElement = (name: TMailItemName) => {
  return {
    letter: LetterEditElement,
    logo: ImageEditElement,
    head: TextEditElement,
    subhead: TextEditElement,
    goToCart: ButtonEditElement,
    productItem: ProductItemElement,
    unsubscribeText: TextEditElement,
    unsubscribeLink: MailingPlug,
  }[name];
};
