interface Items {
  key: string;
  title: string;
  scrollToId: string;
}

type CangeValue = (key: string) => void;

const getElementBottom = (id: string): number => {
  return Math.abs(document.getElementById(id).getBoundingClientRect().bottom);
};

export const scrollReaction = (items: Items[], changeValue: CangeValue) => {
  const currentItem = items.reduce((previous: Items, item: Items) => {
    const previousBottom = getElementBottom(previous.scrollToId);
    const currentItemBottom = getElementBottom(item.scrollToId);
    if (previousBottom < currentItemBottom) {

      return previous;
    }

    return item;
  }, items[0]);
  changeValue(currentItem.key);
};
