import React from 'react';
import { SwitchUI } from '~/components/SwitchUI';
import { SERVICE_INFO } from '~/screens/Auth/AttendanceScreen/ServiceSelection/constants';
import { EAvailableServices } from '~/stores/serviceStore';
import styles from './style.module.css';

interface IConnectedTariffs {
  tariffs: EAvailableServices[];
}

export const ConnectedTariffs = ({ tariffs }: IConnectedTariffs) => {
  return (
    <div className={styles.tariffs}>
      {tariffs.map((tariff: EAvailableServices, key) => (
        <SwitchUI elementCode={SERVICE_INFO[tariff].uiSwitch} key={key}>
          <div className={styles.tariff} key={key}>
            <div className={styles.tariffImage} style={{ backgroundImage: `url(${SERVICE_INFO[tariff].image})` }} />
            <div className={styles.description}>
              <div className={styles.title}>{SERVICE_INFO[tariff].title}</div>
              <div className={styles.text}>
                {SERVICE_INFO[tariff].description}
              </div>
            </div>
          </div>
        </SwitchUI>
      ))}
    </div>
  );
};
