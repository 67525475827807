import cx from 'classnames';
import React from 'react';
import BorderRadiusSelector from '~/components/notification-editor/BorderRadiusSelector';
import ColorPicker from '~/components/notification-editor/ColorPicker';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import { getMaxLengthMessage } from '~/screens/MailingScreen/utils';
import { isValidUrl } from '~/utils/validationUtils/isValidUrl';
import commonStyles from '../style.module.css';
import styles from './styles.module.css';

export const ButtonEditElement = ({ configuration, onEdit }: IEditElement) => {
  const { editParams = {} } = configuration;
  const maxTextLength = editParams?.maxTextLength;
  const value = configuration.params.text;
  const isErrorShown = value.length >= maxTextLength;
  const href = configuration.params.href;
  const isHrefValid = isValidUrl(href);

  return (
    <div className={styles.wrap}>
      <div className={commonStyles.head}>{configuration.uiName}</div>
      <section className={commonStyles.section}>
        <FormItem title="Ссылка на корзину" noReservedPlaceForMessage>
          <Input
            failure={!isHrefValid}
            value={href}
            onChangeValue={(buttonText) => {
              onEdit(configuration.name, 'href', buttonText);
            }}
            placeholder="https://example.ru/cart.html"
            block
          />
          <div
            className={cx(
              commonStyles.inputDescription,
              !isHrefValid && commonStyles.error
            )}
          >
            Ссылка на страницу должна быть полной - включая «http://» или
            «https://» Если ссылка на корзину всегда разная (имеет уникальные
            значения), вы можете вставить её с этими уникальными значениями.
          </div>
        </FormItem>
      </section>

      <section className={commonStyles.section}>
        <FormItem title="Текст кнопки" noReservedPlaceForMessage 
          message={getMaxLengthMessage(maxTextLength)} 
          failure={isErrorShown}
        >
          <Input
            value={value}
            onChangeValue={(buttonText) => {
              onEdit(configuration.name, 'text', buttonText);
            }}
            placeholder="Текст кнопки"
            maxLength={maxTextLength}
            failure={isErrorShown}
            block
          />
        </FormItem>
      </section>
      <section className={commonStyles.section}>
        <FormItem title="Цвет кнопки" noHTMLLabel noReservedPlaceForMessage>
          <ColorPicker
            color={configuration.params.backgroundColor}
            alpha={Math.round(
              (configuration.params?.backgroundOpacity || 1) * 100
            )}
            onChange={({ alpha, color }) => {
              onEdit(configuration.name, 'backgroundColor', color);
              onEdit(configuration.name, 'backgroundOpacity', alpha / 100);
            }}
          />
        </FormItem>
      </section>

      <section className={commonStyles.section}>
        <FormItem title="Цвет текста" noHTMLLabel noReservedPlaceForMessage>
          <ColorPicker
            color={configuration.params.color}
            alpha={Math.round((configuration.params?.colorOpacity || 1) * 100)}
            onChange={({ alpha, color }) => {
              onEdit(configuration.name, 'color', color);
              onEdit(configuration.name, 'colorOpacity', alpha / 100);
            }}
          />
        </FormItem>
      </section>

      <section className={commonStyles.section}>
        <BorderRadiusSelector
          borderRadius={parseInt(configuration.params.borderRadius, 10)}
          onChangeValue={borderRadius =>
            onEdit(configuration.name, 'borderRadius', borderRadius + 'px')
          }
        />
      </section>
    </div>
  );
};
