import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paymentSuccessUrl, TARIFF_TAB } from '~/constants';
import { ErrorPaymentScreen } from '~/screens/Auth/PaymentScreen/components/ErrorScreen';
import { SuccessPaymentScreen } from '~/screens/Auth/PaymentScreen/components/SuccessScreen';
import { AuthStore } from '~/stores/authStore';
import useStores from '~/utils/useStores';

enum EPaymentStatus {
  success = 'success',
  request = 'request',
  error = 'error',
}

export const PaymentScreen = observer(() => {
  const { status: statusFromUrl } = useParams();
  const [status, setStatus] = useState<EPaymentStatus>(statusFromUrl || EPaymentStatus.request);
  const { authStore } = useStores() as { authStore: AuthStore };
  const [mounted, setMounted] = useState<boolean>(false);
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
  const [nextStep, setNextStep] = useState<boolean>(false);

  useEffect(() => {

    if (status === EPaymentStatus.request && !mounted) {
      (async () => {
        setMounted(true);
        const paymentUrl = await authStore.getPaymentUrl();
        if (paymentUrl) {
          setTimeout(() => {
            setPaymentUrl(paymentUrl);
          }, 5000);
        } else {
          setStatus(EPaymentStatus.error);
        }
      })();
    }

    if (status === EPaymentStatus.success && !mounted) {
      (async () => {
        setMounted(true);
        const result = await authStore.checkPayment();

        if (result) {
          setTimeout(() => {
            setNextStep(true);
          }, 2000);
        } else {
          setStatus(EPaymentStatus.error);
        }
      })();
    }
  });

  if (paymentUrl && mounted && status === EPaymentStatus.request) {
    location.href = paymentUrl;

    return null;
  }

  if (nextStep) {
    location.href = `/settings?tab=${TARIFF_TAB}`;

    return null;
  }

  return (
    <>
      {status === EPaymentStatus.request && <div>Запрос к платежной системе...</div>}
      {status === EPaymentStatus.success && <SuccessPaymentScreen />}
      {status === EPaymentStatus.error &&
      <ErrorPaymentScreen error={authStore?.paymentError} link={paymentSuccessUrl} />}
    </>
  );
});
