import React from 'react';
import Icon from '~/components/Icon';
import { HiddenText } from '~/components/generic/HiddenText';
import Tooltip from '~/components/ui/Tooltip';
import { IStatistic } from '~/screens/QuizScreen/stores/quizStore';
import styles from './style.module.css';

export const StatisticItem = ({ name, count, percent, hint }: IStatistic) => {
  return (
    <div className={styles.statisticItem}>
      <div className={styles.name}>
        <Icon name={'Eye'} className={styles.icon} />
          <HiddenText className={styles.textToHide}>{name}</HiddenText>
      </div>
      <div className={styles.percent}>{percent}%</div>
      <div className={styles.count}>
        {count.toLocaleString()}
        <Tooltip placement={'topLeft'} title={hint} className={styles.tooltip}>
          <Icon name="Question" className={styles.tooltipIcon} />
        </Tooltip>
      </div>
    </div>
  );
};
