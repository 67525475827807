import { observer } from 'mobx-react';
import React from 'react';
import Button from '~/components/ui/Button';
import MassMailingStatistics from '~/screens/MassMailingScreen/components/MassMailingList/MassMailingStatistics';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';
import Screen from '~/screens/Screen';
import styles from './style.module.css';

const SCREEN_TITLE = 'Ручные Email рекомендации';

interface IMassMailingListProps {
  hasRightToModify?: boolean;
}

const MassMailingList: React.FC<IMassMailingListProps> = observer(({
  hasRightToModify = false,
}) => {
  const {
    createMassMailing,
  } = massMailingStore;

  return (
    <Screen
      className={styles.screen}
      header={SCREEN_TITLE}
      documentTitle={SCREEN_TITLE}
      footer={(
        <Button icon="Plus" onClick={() => {
          hasRightToModify && createMassMailing();
        }} disabled={!hasRightToModify}>
          Создать
        </Button>
      )}
      noPadding
    >
      <div className={styles.block}>
        <MassMailingStatistics hasRightToModify={hasRightToModify} />
      </div>
    </Screen>
  );
});

export default MassMailingList;
