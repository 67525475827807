import { AxiosPromise } from 'axios';
import { del, get, post } from '~/api/client';

interface IUpdateQuiz {
  action: 'start' | 'stop' | 'delete' | 'clone' | 'save' | 'save-and-run';
  quizId?: string;
  quizObject?: { [key: string]: any } | null;
}

interface IProcessQuiz {
  quizId: string;
}

const Quiz = {
  fetchQuizList: (): AxiosPromise<any> => get('/admin/quizTemplate/list'),
  fetchQuizTemplates: (): AxiosPromise<any> =>
    get('/admin/quizTemplate/templates'),

  fetchQuizLeads: ({
    quizId,
    paginator,
  }: {
    quizId?: string;
    paginator: { pageNum: number; pageSize: number };
  }): AxiosPromise<any> => {
    const urlParams = quizId ? `?quizId=${quizId}` : '';

    return post(`/admin/quizContact/getPaginated${urlParams}`, {
      '@type': 'Pagination',
      'pageNum': paginator.pageNum,
      'pageSize': paginator.pageSize,
      'orders': [
        {
          fieldName: 'dateTime',
          order: 'DESC',
        },
      ],
    });
  },

  deleteQuiz: ({ quizId }: IProcessQuiz) =>
    del(`/admin/quizTemplate?quizId=${quizId}`),

  updateQuiz: ({
    quizId,
    quizObject,
    action,
  }: IUpdateQuiz): AxiosPromise<any> => {
    const urlParams = quizId ? `?quizId=${quizId}` : '';

    return post(
      `/admin/quizTemplate/${action}${urlParams}`,
      !!quizObject ? quizObject : undefined
    );
  },
  fetchStatistic: ({ quizId }: IProcessQuiz): AxiosPromise<any> =>
    get(`/admin/quizStatistic/get?quizId=${quizId}`),
};

export default Quiz;
