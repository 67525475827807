import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from '~/api/client';

type FreeResponse = IResponse<[]>;
type GetSelectorResponse = IResponse<[FeedSelector]>;

const Feeds = {
  getSelector: (selectorUuid: string): AxiosPromise<GetSelectorResponse> =>
    actionGet('/admin/feed_v2', { selectorUuid }),

  rename: (id: number, feedName: string): AxiosPromise<FreeResponse> =>
    actionSet(
      '/admin/feed_v2',
      { operation: 'rename', id, feedName },
      null
    ),

  start: (id: number): AxiosPromise<FreeResponse> =>
    actionSet('/admin/feed_v2', { operation: 'start', id }, null),

  stop: (id: number): AxiosPromise<FreeResponse> =>
    actionSet('/admin/feed_v2', { operation: 'stop', id }, null),

  delete: (id: number): AxiosPromise<FreeResponse> =>
    actionSet('/admin/feed_v2', { operation: 'delete', id }, null),
};

export default Feeds;
