import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import NotificationPartSelector from '~/components/ScenarioEditor/NotificationPartSelector';
import ScenarioEditorConfigure from '~/components/ScenarioEditor/ScenarioEditorConfigure';
import ScenarioEditorSidebar, {
  ScenarioEditorSidebarPreset,
} from '~/components/ScenarioEditor/ScenarioEditorSidebar';
import './style.css';

interface Props {
  triggerParams: INotification;
  onChangeTriggerParams?: (triggerParams: INotification) => void;
  isPreview?: boolean;
  title?: string;
  onChangeTitle?: (title: string) => void;
  isSigned?: boolean;
  onChangeSign?: (isSigned: boolean) => void;
  showIsWidgetStepDrawerOpen?: () => void;
  tabStep?: number;
}

const ScenarioEditorWidget: React.FC<Props> = observer(
  ({
    triggerParams,
    onChangeTriggerParams,
    isPreview,
    title,
    isSigned = false,
    onChangeTitle,
    onChangeSign,
    showIsWidgetStepDrawerOpen,
    tabStep,
  }) => {
    const [sidebarPreset, setSidebarPreset] =
      useState<ScenarioEditorSidebarPreset>('general');

    const handleClickContainer = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (
          (event.target as HTMLDivElement).classList.contains(
            'scenario-editor-widget__container'
          )
        ) {
          setSidebarPreset('general');
        }
      },
      []
    );

    const notification = (
      <NotificationPartSelector
        notificationProps={{
          ...triggerParams,
          isMobile: triggerParams.isMobile,
          isHorizontal: triggerParams.isHorizontal,
        }}
        part={sidebarPreset}
        onChangePart={setSidebarPreset}
        disabled={isPreview}
        tabStep={tabStep}
      />
    );

    const sidebar = (
      <aside className="scenario-editor-widget-sidebar">
        <ScenarioEditorSidebar
          preset={isPreview ? 'preview' : sidebarPreset}
          isMobile={triggerParams.isMobile}
          notificationParams={triggerParams}
          onChangeNotificationParams={onChangeTriggerParams}
          title={title}
          onChangeTitle={onChangeTitle}
          isSigned={isSigned}
          onChangeSign={onChangeSign}
        />
      </aside>
    );

    return (
      <>
        <div
          className={cx(
            'scenario-editor-widget',
            `${
              (triggerParams.hasImage && !triggerParams.isHorizontal) ||
              triggerParams.isMobile
                ? 'with-image__container'
                : ''
            }`
          )}
        >
          {!isPreview && (
            <ScenarioEditorConfigure
              className="scenario-editor-widget__configure"
              onClick={showIsWidgetStepDrawerOpen}
              text="На данном шаге вы можете настроить внешний вид вашего виджета"
            />
          )}
          <div
            className={cx('scenario-editor-widget__container', {
              _secondStep: !isPreview,
            })}
            onClick={handleClickContainer}
          >
            {triggerParams.isMobile && (
              <div className="scenario-editor-widget__mobile-container">
                {/*<Icon*/}
                {/*  name="MobileSubstrate"*/}
                {/*  className="scenario-editor-widget__mobile-background"*/}
                {/*/>*/}
                <div className="scenario-editor-widget__mobile-background">
                  {notification}
                </div>

              </div>
            )}

            {!triggerParams.isMobile && notification}
          </div>
        </div>

        <div className="scenario-editor__sidebar">
          {sidebar}
        </div>
      </>
    );
  }
);

export default ScenarioEditorWidget;
