import { IFeedConfiguration } from 'feed';
import { action, computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Feeds } from '~/api';
import { useAppSettings } from '~/customHooks/useAppSettings';
import FeedProApi from '~/screens/FeedProScreen/api/feedProApi';
import FeedProModel from '~/screens/FeedProScreen/model/FeedProModel';
import ConstructorStore, { IConstructorStore } from '~/stores/constructorStore';
import { FeedStore } from '~/stores/feedStore';

export type CheckedCountPage = 'ONE' | 'MULTIPLE';

export class FeedProStore extends FeedStore {
  mapFeedRecTypeName: Map<RecType, string> = new Map(); // Код и наименование лент type => description
  @observable constructorStore: IConstructorStore<EditorSteps>;
  @observable feed: FeedProModel; // Объект лены
  @observable feedStatistics: FeedProStatistics[]; // Статистика лент
  @observable isConfigLinkInvalid = false;
  @observable feedStatisticsActiveRow: FeedProStatistics = null; // Объект активной ленты в таблице статистики
  @observable device: Device = 'mobile'; // Устройсво показа mobile | desktop
  @observable isFetchingFeed = false; // Статус запроса ленты по id
  // Статус сообщения с qr-code для мобильного предпросмотра
  @observable isShowPreviewMobileFeedMessage: boolean = false;
  @observable urlForPreviewFeed: string = ''; // Адрес для предпрасмотра ленты
  @observable isIFrameAccessible = false;
  @observable isPlacementPageInvalid = false;
  @observable hasClickedOnInput = false;
  @observable isRechoosingSelector = false;
  @observable isRechoosingSelectorMobile = false;
  @observable lastSavedConfiguration: IFeedConfiguration;
  @observable checkedCountPage: CheckedCountPage = 'ONE';
  @observable isOpenDialogSettingsById: boolean;
  @observable labelMaxCountLimit: number = 0;

  constructor() {
    super();
    this.constructorStore = new ConstructorStore([
      'StepFeedType',
      'StepPlacementPages',
      'StepCatalog',
      'StepManual',
      'StepSelector',
      'StepDesign',
    ]);
  }

  @action
  setIsOpenDialogSettingsById = (isOpen: boolean) => {
    this.isOpenDialogSettingsById = isOpen;
  }

  @action
  setLabelMaxCountLimit = (limit: number) => {
    this.labelMaxCountLimit = limit;
  }

  @action
  setDevice = (device: Device) => {
    this.device = device;
    if (!this.feed?.get('id')) {
      this.feed?.update({configuration: device === 'mobile' 
        ? {
          ...this.feed.get('configuration'),
          cardTitleFontSize: 10,
          cardDescriptionFontSize: 10,
          cardPriceFontSize: 14,
          cardCrossedPriceFontSize: 10,
          buttonFontSize: 10,
          labelFontSize: 10,
        } : {
          ...this.feed.get('configuration'),
          cardTitleFontSize: 16,
          cardDescriptionFontSize: 14,
          cardPriceFontSize: 22,
          cardCrossedPriceFontSize: 20,
          buttonFontSize: 16,
          labelFontSize: 13,
        },
      });
    }
  }

  @action
  setIsFetchingFeed = (status: boolean) => {
    this.isFetchingFeed = status;
  }

  @action
  fetchRecommendationTypes = async () => {
    if (this.recommendationTypes.length === 0) {
      try {
        const response = await FeedProApi.getRecommendationTypes();
        const types = response.data.payload;
        this.setRecommendationTypes(types);
        types.map((recType: FeedRecType) => {
          this.mapFeedRecTypeName.set(recType.type, recType.description);
        });
      } catch (e) {
        throw e;
      }
    }
  }

  @action
  setFeedProStatistics = (statistics: FeedProStatistics[]) => {
    this.feedStatistics = statistics;
    this.setFeedAmount(statistics?.length ?? 0);
  }

  @action
  updateFeedProStatistics = async () => {
    const response = await FeedProApi.getFeedProStatistics();
    this.setFeedProStatistics(response?.data?.payload);
  }

  @action
  getFeedProStatistics = async () => {
    try {
      this.setFetchingStatisticsError(false);
      this.setFetchingStatistics(true);
      this.fetchRecommendationTypes();
      this.updateFeedProStatistics();
    } catch (e) {
      this.setFetchingStatisticsError(true);
      throw e;
    } finally {
      this.setFetchingStatistics(false);
      useAppSettings()
        .getAppSetting(['MAX_FEED_COUNT_PRO', 'MAX_FEED_PRO_LABEL_COUNT'])
        .then((settingsValueArray) => {
          const [MAX_FEED_COUNT_PRO, MAX_FEED_PRO_LABEL_COUNT] = settingsValueArray;
          this.setFeedLimit(MAX_FEED_COUNT_PRO as number);
          this.setLabelMaxCountLimit(MAX_FEED_PRO_LABEL_COUNT as number);
        });
    }
  }

  @action
  setFeedPro = (feedPro: FeedPro) => {
    this.feed = new FeedProModel(this, feedPro);
  }

  @action
  getFeedProById = async (id: number, copy: boolean = false) => {
    try {
      this.setIsFetchingFeed(true);
      const response = await FeedProApi.getFeedProById(id);
      const payload = response.data.payload[0];
      if (copy) {
        delete payload.id;
        delete payload.status;
        delete payload.ymlCatalogStatus;
        this.constructorStore.changeCurrentStep('StepFeedType');
      }
      this.setFeedPro(payload);
      this.checkedCountPage = payload.displayConditions ? 'ONE' : 'MULTIPLE';
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingFeed(false);
    }
  }

  @action
  fetchSelector = async (id: string) => {
    try {
      const response = await Feeds.getSelector(id);
      const currentFeed = this.feed;
      this.device === 'desktop'
        ? currentFeed.setSelector(response.data.payload[0].selector)
        : currentFeed.setSelectorMobile(
            response.data.payload[0].selectorMobile
          );
    } catch (e) {
      throw e;
    }
  }

  @action
  setFeedStatisticsActiveRow = (feedStatistics: FeedProStatistics) => {
    this.feedStatisticsActiveRow = feedStatistics;
  }

  /**
   * Старт ленты
   * @param id
   */
  @action
  startFeedPro = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.start(id);
      await this.updateFeedProStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Остановка ленты
   */
  @action
  stopFeedPro = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.stop(id);
      await this.updateFeedProStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Переименование ленты
   */
  @action
  renameFeed = async (id: number, feedName: string) => {
    try {
      await Feeds.rename(id, feedName);
      await this.updateFeedProStatistics();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Предпросмотр ленты
   */
  @action
  setIsShowPreviewMobileFeedMessage = (status: boolean) => {
    this.isShowPreviewMobileFeedMessage = status;
  }
  @action
  setUrlFromPreviewFeed = (url: string) => {
    this.urlForPreviewFeed = url;
  }

  /**
   * Удаление ленты
   */
  @action
  deleteFeedPro = async (id: number) => {
    try {
      this.setIsFetchingRequest(true);
      await Feeds.delete(id);
      await this.updateFeedProStatistics();
    } catch (e) {
      throw e;
    } finally {
      this.setIsFetchingRequest(false);
    }
  }

  /**
   * Обработка акшен-кнопок и суппорт-кнопок
   * @param actionType
   * @param feedStatistics
   */
  @action
  onClickActionButton = (
    actionType: FeedActionButtonType,
    feedStatistics: FeedProStatistics
  ) => {
    this.setFeedStatisticsActiveRow(feedStatistics);
    switch (actionType) {
      // Запуск ленты
      case 'start': {
        this.startFeedPro(feedStatistics.id);
        break;
      }
      // Остановка ленты
      case 'stop': {
        const stopFeed = () => {
          this.setIsStoppingDialogOpen(false);
          this.stopFeedPro(feedStatistics.id);
        };
        this.isStoppingDialogOpen
          ? stopFeed()
          : this.setIsStoppingDialogOpen(true);
        break;
      }
      // Редактирование ленты
      case 'edit': {
        const editFeedPro = async (feedId: number) => {
          await this.getFeedProById(feedId);
          this.constructorStore.changeCurrentStep('StepFeedType');
          this.setIsConstructorOpen(true);
        };
        const stopAndEditFeedPro = async (feedId: number) => {
          this.setIsBeforeEditOpen(false);
          await this.stopFeedPro(feedId);
          editFeedPro(feedId);
        };
        switch (feedStatistics.status) {
          case 'WORK':
            this.isBeforeEditOpen
              ? stopAndEditFeedPro(feedStatistics.id)
              : this.setIsBeforeEditOpen(true);
            break;
          case 'VERIFY':
            this.setIsShowWaitMessage(true);
            break;
          default:
            editFeedPro(feedStatistics.id);
            break;
        }
        break;
      }
      // Информация о ленте
      case 'info': {
        const infoFeed = async (feedId: number) => {
          await this.getFeedProById(feedId);
          this.setIsInfoOpen(true);
        };
        infoFeed(feedStatistics.id);
        break;
      }
      // Переименование ленты
      case 'rename': {
        this.setIsOpenRenameDialog(true);
        break;
      }
      // Копирование ленты
      case 'copy': {
        const copyFeedPro = async (feedId: number) => {
          await this.getFeedProById(feedId, true);
          this.feed.update({ externalKey: uuidv4() });
          this.setIsConstructorOpen(true);
        };
        this.hasPermissionToCreateFeed
          ? copyFeedPro(feedStatistics.id)
          : this.setIsShowExceedingLimitMessage(true);
        break;
      }
      // Удаление ленты
      case 'delete': {
        const deleteFeed = (feedId: number) => {
          this.deleteFeedPro(feedId);
          this.setIsRemoveDialogOpen(false);
        };
        this.isRemoveDialogOpen
          ? deleteFeed(feedStatistics.id)
          : this.setIsRemoveDialogOpen(true);
        break;
      }
      // Предпросмотр
      case 'preview': {
        const getFeedUrlPattern = async (feedId: number) => {
          await this.getFeedProById(feedId, true);
          this.setUrlFromPreviewFeed(
            `${this.feed?.get('urlPattern')}#type=startPreview&feedId=${feedId}`
          );
          switch (feedStatistics.device) {
            case 'mobile':
              this.setIsShowPreviewMobileFeedMessage(true);
              break;
            case 'desktop':
              window.open(this.urlForPreviewFeed, '_blank');
              break;
          }
        };
        getFeedUrlPattern(feedStatistics.id);
        break;
      }
    }
  }

  @action
  resetToFactorySettings = () => {
    this.constructorStore.changeCurrentStep('StepFeedType');
    this.setDevice('mobile');
    this.setFeedStatisticsActiveRow(null);
  }

  @action
  createEmptyFeed = () => {
    this.resetToFactorySettings();
    this.feed = null;
    this.feed = new FeedProModel(this, {
      '@type': 'FeedTemplate',
      'ymlUrl': '',
      'urlPattern': '',
      'displayConditions': {
        operation: 'CONTAINS',
        url: '',
      },
      'externalKey': uuidv4(),
    });
    this.setIsConstructorOpen(true);
  }

  @action
  createFeedPro = async () => {
    this.hasPermissionToCreateFeed
      ? await this.createEmptyFeed()
      : this.setIsShowExceedingLimitMessage(true);
  }

  // КОНСТРУКТОР ЛЕНТ
  @action
  setIsIFrameAccessible = (isAccessible: boolean) => {
    this.isIFrameAccessible = isAccessible;
  }

  @action
  setIsPlacementPageInvalid = (isInvalid: boolean) => {
    this.isPlacementPageInvalid = isInvalid;
  }

  @action
  setIsConfigLinkInvalid = (isInvalid: boolean) => {
    this.isConfigLinkInvalid = isInvalid;
  }
  
  @action
  setHasClickedOnInput = (status: boolean) => {
    this.hasClickedOnInput = status;
  }

  @action
  setIsRechoosingSelector = (status: boolean) => {
    this.isRechoosingSelector = status;
  }

  @action
  setIsRechoosingSelectorMobile = (status: boolean) => {
    this.isRechoosingSelectorMobile = status;
  }

  @action
  setLastSavedConfiguration = (configuration: IFeedConfiguration) => {
    this.lastSavedConfiguration = { ...configuration };
  }

  @action
  setCheckedCountPage = (value: CheckedCountPage) => {
    this.checkedCountPage = value;
  }

  @action
  setSelectorManual = (selector: string) => {
    this.setIsRechoosingSelector(false);
    try {
      if (this.device === 'desktop') {
        this.feed.setSelector(selector);
      } else {
        this.feed.setSelectorMobile(selector);
      }
    } catch (e) {
      throw e;
    }
  }

  // Вычисления

  @computed
  get feedTitle() {
    return this.feedStatistics?.find(
      stat => stat.id === +this.feedStatisticsActiveRow?.id
    )?.feedTittle;
  }

  @computed
  get isConfigInvalid() {
    return (
      this.feed?.get('ymlCatalogStatus') === 'YML_ERROR' 
        || this.feed?.get('ymlCatalogStatus') === 'UPLOAD_CACHED'
    );
  }
}

export default new FeedProStore();
