import { observer } from 'mobx-react';
import React from 'react';
import RecTypeComponent from '~/screens/ApiScreen/FeedApi/components/FeedApiEditor/steps/StepFeedType/RecTypeComponent';
import { FeedApiStore } from '~/screens/ApiScreen/FeedApi/store/feedApiStore';
import { FEED_TYPE_INFO } from '~/screens/FeedProScreen/components/FeedProEditor/StepFeedType/constants';
import useStores from '~/utils/useStores';

const StepFeedType: React.FC = observer(() => {
  const { feedApiStore } = useStores() as {
    feedApiStore: FeedApiStore;
  };
  const { recommendationTypes } = feedApiStore;

  return (
    <>
      {FEED_TYPE_INFO.map(
        recType =>
          recommendationTypes.find(({ type }) => type === recType.type) && (
            <RecTypeComponent
              key={recType.type}
              recType={recType.type}
              title={recType.title}
              description={recType.description}
            />
          )
      )}
    </>
  );
});

export default StepFeedType;
