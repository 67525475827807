import React from 'react';
import Icon from '~/components/Icon';
import './style.css';

export const AuthTechSuccessScreen = () => {
  return (
    <div className="auth-tech-success__container">
      <Icon name="CheckmarkXxl" className="auth-tech-success__mark" />
      <div className="auth-tech-success__title">Успех!</div>
      <div className="auth-tech-success__description">
        Вход по Sberbank Business ID прошел успешно
      </div>
    </div>
  );
};
