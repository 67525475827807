import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import PageHeader from '~/components/PageHeader';
import Link from '~/components/ui/Link';
import { HELP_PHONE, HELP_PHONE_CLEAN } from '~/constants';
import { useGetHelpEmailHook } from '~/customHooks/useGetHelpEmail/useGetHelpEmail';
import Screen from '~/screens/Screen';
import styles from './style.module.css';

const HelpScreen: React.FC = () => {

  const helpEmail = useGetHelpEmailHook();

  return (
    <Screen>
      <PageHeader title="Связаться с нами" />
      <div className={styles.infoBlockHead}>
        Всегда готовы помочь и ответить на возникающие вопросы по сервису
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.infoItem}>
          <Icon name={'Subtract'} className={styles.infoIcon} />
          <Link
            className={styles.text}
            to="#"
            onClick={(e) => {
              window.location.href = `mailto:${helpEmail}`;
              e.preventDefault();
            }}
          >
            {helpEmail}
          </Link>
        </div>
        <div className={styles.line} />
        <div className={styles.infoItem}>
          <Icon name={'Phone'} className={styles.infoIcon} />
          <Link
            to="#"
            className={styles.text}
            onClick={(e) => {
              window.location.href = `tel:${HELP_PHONE_CLEAN}`;
              e.preventDefault();
            }}
          >
            {HELP_PHONE}
          </Link>
        </div>
      </div>
      <div className={styles.infoBlockHead}>Режим работы</div>
      <div className={styles.infoBlock}>
        <div className={styles.infoItem}>
          <Icon name={'Calendar'} className={styles.infoIcon} />
          <div className={cx(styles.text, styles.blackText)}>
            Пн — Пт: с 7:00 до 18:00
          </div>
        </div>
        <div className={cx(styles.infoItem, styles.text, styles.greyText)}>Кроме праздничных и выходных дней</div>
      </div>
    </Screen>
  );
};

export default HelpScreen;
