import React from 'react';
import Button from '~/components/ui/Button';
import { isMobile } from '~/utils/isMobile';
import styles from './style.module.css';

const MockMassMailLinkResultScreen: React.FC = () => {
  const onClose = () => {
    window.open('', '_parent', '');
    window.close();
  };
  const imagePath = isMobile()
    ? '/assets/images/mailing/examples/mockMassMailLinkResultMobile.png'
    : '/assets/images/mailing/examples/mockMassMailLinkResultDesktop.png';
    
  return (
    <div className={styles.mockEmailResult}>
      <div className={styles.head}>Email рассылки для ваших клиентов</div>
      <div className={styles.subhead}>
        Что увидит клиент, перейдя по кнопкам в письме:
      </div>
      <div className={styles.image}>
        <img src={imagePath} alt="example image" />
        <div className={styles.buttonContainer}>
          <Button onClick={onClose}>
            Продолжить настройку Email рекомендации
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MockMassMailLinkResultScreen;