import cx from 'classnames';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import PopupElement from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/Mail/MailElement/PopupElement';
import { POPUP_CONTENT } from '~/screens/MailingScreen/components/MailingConstructor/TabConstructor/Mail/MailElement/PopupElement/constants';
import './style.css';

interface IMailElementProps {
  children: ReactNode;
  name: TMailItemName;
  selected: boolean;
  onClick: () => void;
}

const MailElement: React.FC<IMailElementProps> = ({
  children,
  name,
  selected,
  onClick,
}) => {
  const popupContent = POPUP_CONTENT.find(content => content.name === name);

  return (
    <div
      className={cx('mailing-screen-constructor-tab-mail__element', {
        __selected: selected,
      })}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {popupContent &&
        createPortal(
          (
            <PopupElement
              content={popupContent}
              isVisible={selected}
              onClick={onClick}
            >
              {children}
            </PopupElement>
          ), document.body
        )}
      {children}
    </div>
  );
};

export default MailElement;
