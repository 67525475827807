import React from 'react';
import './style.css';

interface IAliasHintProps {
  aliasName: string;
}

const AliasHint: React.FC<IAliasHintProps> = ({ aliasName }) => (
  <div className="feed-pro-editor-step-catalog__label-alias-hint">
    Пример признака, который нужно ввести
    только из {'><'}
    <br /><br />
    {'<'}param name=”label”{'>'}
    <span>{aliasName}</span>
    {'</'}param{'>'}
  </div>
);

export default AliasHint;