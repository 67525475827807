import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Button from '~/components/ui/Button';
import Screen from '~/screens/Screen';
import { setGtm } from '~/utils/setGtm';
import './style.css';

@observer
class GuideScreenForHimself extends Component {
  render() {
    /**
     * Отправка события admin_by_himself для GA
     */
    setGtm('admin_by_himself');

    return (
      <Screen className="guide-screen">
        <div className="guide-screen-for-him-self__container">
          <h1 className="guide-screen__title">Выберите, как вам удобно подключить сервис</h1>
          <Button
            block
            type="outline"
            className="guide-himself__btn"
            to="/guide/himself/cms"
            wrapperClassName="guide-himself__btn-wrapper"
          >
            Конструкторы сайтов и CMS
          </Button>
          <Button
            block
            type="outline"
            className="guide-himself__btn"
            to="/guide/himself/html"
            wrapperClassName="guide-himself__btn-wrapper"
          >
            HTML
          </Button>
          <Button
            block
            type="outline"
            className="guide-himself__btn"
            to="/guide/himself/gtm"
            wrapperClassName="guide-himself__btn-wrapper"
          >
            Google Tag Manager
          </Button>
        </div>
      </Screen>
    );
  }
}

export default React.memo(GuideScreenForHimself);
