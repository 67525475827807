import { IFeedConfiguration, TFeedCard } from 'feed';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AdditionSelector from '~/components/generic/StepSelectorAndDesign/AdditionSelector';
import NoFrameInstructions from '~/components/generic/StepSelectorAndDesign/NoFrameInstructions';
import Sidebar from '~/components/generic/StepSelectorAndDesign/Sidebar';
import SuccessfulChoice from '~/components/generic/StepSelectorAndDesign/SuccessfulChoice';
import Workspace from '~/components/generic/StepSelectorAndDesign/Workspace';
import Message from '~/components/ui/Message';
import Spinner from '~/components/ui/Spinner';
import './style.css';

interface IFeedParameters {
  selector: string;
  configuration: IFeedConfiguration;
  feedId: string | number;
}

interface IStepSelectorAndDesignProps {
  id: string | number;
  urlPattern: string;
  feedTypeName?: string;
  selector?: string;
  setSelector: (selector: string) => void;
  selectorMobile?: string;
  setSelectorMobile: (selectorMobile: string) => void;
  device: Device;
  setDevice: (device: Device) => void;
  currentStep: EditorSteps;
  isIFrameAccessible: boolean;
  setIsIFrameAccessible: (isAccessible: boolean) => void;
  feedConfiguration: IFeedConfiguration;
  contentHeight: string;
  isRechoosingSelector: boolean;
  setIsRechoosingSelector: (status: boolean) => void;
  setLastSavedConfiguration: (configuration: IFeedConfiguration) => void;
  lastSavedConfiguration: IFeedConfiguration;
  onChange: (configuration: IFeedConfiguration) => void;
  fetchSelector: (id: string, type: 'desktop' | 'mobile') => void;
  setSelectorManual: (selector: string) => void;
  setIsSwitchFrameButton: (status: boolean) => void;
  isOpenDialogManuallyAddingSelector: boolean;
  setIsOpenDialogManuallyAddingSelector: (status: boolean) => void;
  cards: TFeedCard[];
  feedMode: FeedMode;
  reqRec?: 'off' | 'temp';
}

/**
 * Задержка для проверки доступа к сайту клиента через frame
 * Про успешном отклике проверка завершается досрочно
 */
const TIMEOUT = 2500;

const StepSelectorAndDesign: React.FC<IStepSelectorAndDesignProps> = ({
  id = 0,
  urlPattern,
  feedTypeName,
  selector,
  setSelector,
  selectorMobile,
  setSelectorMobile,
  device,
  setDevice,
  currentStep,
  isIFrameAccessible,
  setIsIFrameAccessible,
  feedConfiguration,
  contentHeight,
  isRechoosingSelector,
  setIsRechoosingSelector,
  setLastSavedConfiguration,
  lastSavedConfiguration,
  onChange,
  fetchSelector,
  setIsSwitchFrameButton,
  setSelectorManual,
  isOpenDialogManuallyAddingSelector,
  setIsOpenDialogManuallyAddingSelector,
  cards,
  feedMode,
  reqRec = 'off',
}) => {
  const timeIntervalRef = useRef<NodeJS.Timeout>();
  const testFrame = useRef<HTMLIFrameElement>();
  const frameRef = useRef<HTMLIFrameElement>();
  const mobileFrameRef = useRef<HTMLIFrameElement>();

  const [isTimeOutStart, setIsTimeOutStart] = useState(false);
  const [isNoSpaceModalOpen, setIsNoSpaceModalOpen] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const [activeMenu, setActiveMenu] = useState<StepDesignMenuElements>('CONTENT');

  /**
   * Задержка для тестирования возможности использования frame
   */
  const timeOutEnd = () => {
    clearTimeout(timeIntervalRef.current);
    setIsTimeOutStart(false);
  };

  /**
   * Прерывает проверку frame, если сайт клиента доступен
   */
  const comeAnswerFromFrame = () => {
    timeOutEnd();
    setIsIFrameAccessible(true);
    setIsSwitchFrameButton(true);
  };

  /**
   * Отображает ленту во фрейме
   */
  const showPrototipFeedInFrame = (configuration: IFeedConfiguration) => {
    let currentFrame = mobileFrameRef.current;
    let currentSelector = selectorMobile;
    if (device === 'desktop') {
      currentFrame = frameRef.current;
      currentSelector = selector;
    }
    const feedParameters: IFeedParameters = {
      selector: currentSelector,
      configuration: JSON.parse(JSON.stringify(configuration)),
      feedId: id,
    };
    currentFrame.contentWindow.postMessage(
      {
        type: 'showFeedParameters',
        feedParameters,
        reqRec,
      },
      '*'
    );
  };

  /**
   * Сброс, для повторного выбота селектора
   */
  const rechoosing = () => {
    setSelector(undefined);
    setSelectorMobile(undefined);
    setIsRechoosingSelector(true);
    setUuid(uuidv4());
  };

  /**
   * Перерисовывает ленту при изменении параметра и сохраняет новый параметр
   * @param configuration
   * @param isLoad
   */
  const handleUpdate = (configuration: IFeedConfiguration, isLoad?: boolean) => {
    if (isIFrameAccessible) {
      const isNew =
        JSON.stringify(configuration) !==
        JSON.stringify(lastSavedConfiguration);
      if (isNew || isLoad) {
        showPrototipFeedInFrame(configuration);
      }
      setLastSavedConfiguration(configuration);
    }
  };

  /**
   * Обновляет конфигурацию при изменении параметров
   * @param configuration
   * @param isDesignUpdate
   */
  const onValueChange = (
    configuration: IFeedConfiguration,
    isDesignUpdate?: boolean
  ) => {
    onChange(configuration);
    if (isDesignUpdate) {
      handleUpdate(configuration, isDesignUpdate);
    }
  };

  /**
   * Старт тестирования фрейма
   */
  useEffect(() => {
    if (isTimeOutStart) {
      const testSystemEmbedding = () => {
        setTimeout(() => {
          frameFromTest.contentWindow.postMessage(
            { type: 'testSystemEmbedding' },
            '*'
          );
        }, 0);
      };
      const frameFromTest = testFrame.current;
      frameFromTest.onload = () => {
        testSystemEmbedding();
      };
    }
  }, [isTimeOutStart]);

  /**
   * Слушает ответ от фрейма и запускает проверку на фрейм
   */
  useEffect(() => {
    // Определение типа device
    setDevice('mobile');
    if (selector) {
      setDevice('desktop');
    }

    // Старт проверки frame
    setIsIFrameAccessible(false);
    setIsTimeOutStart(true);
    setIsSwitchFrameButton(false);
    timeIntervalRef.current = setTimeout(timeOutEnd, TIMEOUT);

    // Обработка ответов postMessage
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.platform === 'desktop') {
        setIsRechoosingSelector(false);
        setSelector(event.data.selector);
      }
      if (event.data?.platform === 'mobile') {
        setIsRechoosingSelector(false);
        setSelectorMobile(event.data.selector);
      }
      if (
        event.data.type === 'answerTestSystemEmbedding' &&
        event.data.status === 'success'
      ) {
        comeAnswerFromFrame();
      }
      if (event.data.type === 'isNotLargeEnough') {
        setIsNoSpaceModalOpen(true);
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(timeIntervalRef.current);
    };
  }, []);

  // Условия показа экранов
  const showSpiner = isTimeOutStart;
  const showNoFrameSuccessfulChoice =
    !isIFrameAccessible &&
    currentStep === 'StepSelector' &&
    (selector || selectorMobile) &&
    !showSpiner;
  const showNoFrameInstructions =
    !isIFrameAccessible &&
    currentStep === 'StepSelector' &&
    !selector &&
    !selectorMobile &&
    !showSpiner;
  const showWorkspaceAndSidebar =
    !showNoFrameSuccessfulChoice &&
    (currentStep === 'StepSelector' || currentStep === 'StepDesign') &&
    !showNoFrameInstructions &&
    !showSpiner;

  return (
    <>
      {showSpiner && (
        <div className="feed-editor-step-selector-device__spiner">
          <iframe
            ref={testFrame}
            src={urlPattern}
            style={{ display: 'none' }}
          />
          <Spinner />
        </div>
      )}
      {showNoFrameSuccessfulChoice && (
        <SuccessfulChoice rechoosing={rechoosing} />
      )}
      {showNoFrameInstructions && (
        <NoFrameInstructions
          id={id}
          urlPattern={urlPattern}
          device={device}
          setDevice={setDevice}
          fetchSelector={fetchSelector}
          uuid={uuid}
          reqRec={reqRec}
          feedTypeName={feedTypeName}
        />
      )}
      {showWorkspaceAndSidebar && (
        <div className="feed-editor-step-selector-device__workblock">
          <Workspace
            feedTypeName={feedTypeName}
            currentStep={currentStep}
            device={device}
            id={id}
            urlPattern={urlPattern}
            isIFrameAccessible={isIFrameAccessible}
            contentHeight={contentHeight}
            selector={selector}
            selectorMobile={selectorMobile}
            isRechoosingSelector={isRechoosingSelector}
            feedConfiguration={feedConfiguration}
            mobileFrameRef={mobileFrameRef}
            frameRef={frameRef}
            showPrototipFeedInFrame={showPrototipFeedInFrame}
            uuid={uuid}
            reqRec={reqRec}
            isOpenDialogManuallyAddingSelector={isOpenDialogManuallyAddingSelector}
            cards={cards}
            feedMode={feedMode}
            setActiveMenu={setActiveMenu}
          />
          <Sidebar
            currentStep={currentStep}
            feedConfiguration={feedConfiguration}
            device={device}
            setDevice={setDevice}
            isIFrameAccessible={isIFrameAccessible}
            selector={selector || selectorMobile}
            rechoosing={rechoosing}
            onValueChange={onValueChange}
            handleUpdate={handleUpdate}
            feedMode={feedMode}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        </div>
      )}

      {isNoSpaceModalOpen && (
        <Message
          showIcon={false}
          className="feed-editor-site__no-space-modal"
          visible={isNoSpaceModalOpen}
          title="Недостаточный размер для встраивания"
          textTop="Выбранная область расположения имеет недостаточный размер для встраивания ленты. Пожалуйста, выберите другое место"
          onExit={() => {
            setIsNoSpaceModalOpen(false);
          }}
          onApply={() => {
            setIsNoSpaceModalOpen(false);
          }}
          applyText="Хорошо"
        />
      )}

      <AdditionSelector
        isOpenDialogManuallyAddingSelector={isOpenDialogManuallyAddingSelector}
        setIsOpenDialogManuallyAddingSelector={setIsOpenDialogManuallyAddingSelector}
        setSelectorManual={setSelectorManual}
      />
    </>
  );
};

export default StepSelectorAndDesign;
