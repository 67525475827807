import cx from 'classnames';
import React from 'react';
import './style.css';

/*enum Color {
  Grey = '#b3b3b3',
  Red = '#ef546b',
  Green = '#00bd7e',
  Blue = '#3f5cd1',
  Yellow = '#fad155',
  Black = '#000',
  White = '#fff',
}*/

export enum Color {
  Grey = 'grey',
  Red = 'red',
  Green = 'green',
  PaleGreen = 'palegreen',
  Blue = 'blue',
  Yellow = 'yellow',
  Black = 'black',
  White = 'white',
}

interface LabelProps {
  children: string;
  className?: string;
  color?: Color;
  value?: number;
  isBorderRadiusBig?: boolean;
}

const Label: React.FC<LabelProps> = ({
  children,
  className,
  value,
  color = Color.Grey,
  isBorderRadiusBig = false,
}) => {
  let finalColor: Color = color;

  if (value) {
    if (value > 0) finalColor = Color.Green;
    if (value < 0) finalColor = Color.Red;
    if (value === 0) finalColor = Color.Grey;
  }

  return (
    <div
      className={cx('label', {
        [`_color-${finalColor}`]: finalColor,
      }, isBorderRadiusBig && '_border-radius-big')}
    >
      {children}
    </div>
  );
};

export default React.memo(Label);
