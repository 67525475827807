import cx from 'classnames';
import React from 'react';
import Icon from '~/components/Icon';
import style from './style.module.css';

interface IDeviceSwitcher {
  handleClick: (device: Device) => void;
  isMobile: boolean;
  className?: string;
}

export const DeviceSwitcher = ({ handleClick, isMobile, className }: IDeviceSwitcher) => {
  return (
    <div className={cx(style.content_left_device_controls, className)}>
      <div
        style={isMobile ? { background: '#E2E5EC' } : { background: '#fff' }}
        className={cx(style.content_left_device_control, isMobile && 'active')}
        onClick={() => handleClick('mobile')}
      >
        <Icon style={{ width: 30 }} name="DevicePhone" />
      </div>
      <div
        style={isMobile ? { background: '#fff' } : { background: '#E2E5EC' }}
        className={cx(style.content_left_device_control, !isMobile && 'active')}
        onClick={() => handleClick('desktop')}
      >
        <Icon style={{ width: 30 }} name="DeviceDesktop" />
      </div>
    </div>
  );
};
