import { Drawer as AntDrawer } from 'antd';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import Icon from '~/components/Icon';
import DrawerFooter from '~/components/ui/Drawer/DrawerFooter';
import './style.css';

export interface DrawerProps {
  title?: ReactNode;
  description?: ReactNode;
  actions?: React.ReactNode;
  actionsAside?: React.ReactNode;
  size?: Exclude<FormElementSize, 'x-large'>;
  visible: boolean;
  onClose: () => void | PromiseLike<any>;
  zIndex?: number;
  className?: string;
  style?: React.CSSProperties;
  flexBody?: boolean;
  handleClickHeader?: () => void;
  isVisibleCloseButton?: boolean;
  headerName?: string;
}

const DEFAULT_DRAWER_Z_INDEX = 1001;

const DrawerSizes: Partial<Record<FormElementSize, number>> = {
  'x-small': 446,
  'small': 652,
  'default': 952,
  'large': 1240,
};

const Drawer: React.FC<DrawerProps> = ({
  title,
  description,
  actions,
  actionsAside,
  size = 'default',
  visible,
  onClose,
  zIndex = DEFAULT_DRAWER_Z_INDEX,
  className,
  style,
  children,
  flexBody,
  handleClickHeader,
  isVisibleCloseButton = true,
  headerName = null,
}) => {
  return (
    <AntDrawer
      className={cx('drawer', { _visible: visible }, className)}
      style={style}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      zIndex={zIndex}
      width={DrawerSizes[size] ? DrawerSizes[size] : DrawerSizes.default}
      keyboard
    >
      <div className="drawer__close">
        {isVisibleCloseButton && (
          <button className="drawer-close">
            <Icon className="drawer-close__icon" name="Close" />
          </button>
        )}
      </div>
      <div className="drawer__layout drawer-layout">
        {!!headerName && (
          <div className="drawer-adaptive-head__wrapper">
            <div className="drawer-adaptive-head__title">
              {headerName}
            </div>
            <div className="drawer-adaptive-head__button" onClick={onClose}>
              <Icon name={'Close'} className="drawer-adaptive-head__icon" />
            </div>
          </div>
        )}
        {title && <div className="drawer-layout__title" onClick={handleClickHeader}>{title}</div>}
        {description && <div className="drawer-layout__description">{description}</div>}

        <div className={cx('drawer-layout__body', { _flex: flexBody })}>{children}</div>

        {(actions || actionsAside) && (
          <div className="drawer-layout__footer">
            <DrawerFooter
              actions={actions}
              aside={actionsAside}
            />
          </div>
        )}
      </div>
    </AntDrawer>
  );
};

export default Drawer;
