import { action, get } from 'mobx';
import Model from '~/models/Model';
import { initialMailInstance } from '~/screens/MailingScreen/stores/initial';

export default class MailingModel extends Model<IMailingModel> {
  selectSection = (name: TMailItemName) => {
    this.update({
      configuration: this.get('configuration').map(item => ({
        ...item,
        selected: name === item.name,
      })),
    });
  }

  @get
  getSelectedSection = () => {
    const hasSelectedSection = this.get('configuration').filter(
      item => item.selected
    );

    return hasSelectedSection?.length ? hasSelectedSection[0] : undefined;
  }

  changeValue = (sectionName: string, propName: string, value: string) => {
    this.update({
      configuration: this.get('configuration').map((item) => {
        if (sectionName === item.name) {
          return { ...item, params: { ...item.params, [propName]: value } };
        } else return item;
      }),
    });
  }

  getConvertedConfiguration = (configuration: IMailInstance) => {
    return configuration.map((v) => {

      return { ...v, configuration: undefined, selected: v.type === 'letter' };
    });
  }

  @action
  clearDataToSend = () => {
    this.update(initialMailInstance);
    this.parent.selectedId = undefined;
  }

  @get
  getDataToSend = (): IDataToSend => {

    const convertedConfiguration = this.getConvertedConfiguration(
      this.get('configuration')
    );

    const cartUrl = this.get('configuration').filter(v => v.name === 'goToCart')[0].params.href;

    return {
      '@type': this.get('type'),
      'id': this.get('id'),
      'name': this.get('name'),
      'senderEmailAddress': this.get('authorizationEmail')?.address || '',
      'emailTemplate': {
        code: '_',
        data: {
          configuration: convertedConfiguration,
        },
      },
      'emailTrigger': {
        '@type': 'AbandonedCart',
        'ymlCatalogUrl': this.get('ymlCatalog'),
        'cartUrl': cartUrl,
        'timeout': this.get('time').value,
        'timeUnit': this.get('time').format,
        'emailTopic': this.get('authorizationEmail')?.emailTopic || '',
      },
      'senderName': this.get('authorizationEmail')?.senderName || '',
      'imageCodes': this.get('imageCodes') || [],
    };
  }
}
