import { AxiosPromise } from 'axios';
import { actionGet, actionSet } from './client';

type FetchAttendanceResponse = IResponse<[IAttendance]>;

export enum TCodeServices {
  'FEED_LITE_SRV' = 'FEED_LITE_SRV',
  'SCENARIO_SRV' = 'SCENARIO_SRV',
  'FEED_PRO_SRV' = 'FEED_PRO_SRV',
  'FORTUNE_WHEEL_SRV' = 'FORTUNE_WHEEL_SRV',
  'QUIZ_SRV' = 'QUIZ_SRV',
}

const Settings = {
  fetchAttendance: (): AxiosPromise<FetchAttendanceResponse> =>
    actionGet('/admin/stats', {}),

  setUser: (user: IUserSettings): AxiosPromise<any> =>
    actionSet('/admin/users', null, user),

  getUsers: (): AxiosPromise<any> => actionGet('/admin/users', null),

  sendEmail: (email: string, additionalInn: string): AxiosPromise<any> =>
    actionSet('/admin/userProfile', null, {
      '@type': 'UserProfile',
      email,
      additionalInn,
    }),

  getDocuments: (): AxiosPromise<any> =>
    actionGet('/admin/fintechDocument', null),

  getServices: (): AxiosPromise<any> =>
    actionGet('/admin/totService', { operation: 'settings' }),

  remoteService: (service: string, active: boolean): AxiosPromise<any> =>
    actionSet('/admin/totService', null, {
      '@type': 'ServiceStatus',
      'code': service,
      'isActive': active,
    }),
};

export default Settings;
