import React from 'react';
import NumberedBlock from '~/components/generic/NumberedBlock';
import SetCatalogConfigLink from '~/components/generic/SetCatalog/SetCatalogConfigLink';
import SignOfUnificationDropdown from '~/components/generic/SignOfUnificationDropdown';
import FormItem from '~/components/ui/FormItem';
import RadioGroup from '~/components/ui/RadioButton/RadioGroup';
import ToCopyArea from '~/components/ui/ToCopyArea';
import { IPlacementPageParams } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages';
import { DROPDOWN_ITEMS } from '~/screens/FeedProScreen/components/FeedProEditor/StepPlacementPages/constants';
import { CheckedCountPage, FeedProStore } from '~/screens/FeedProScreen/store/feedProStore';
import useStores from '~/utils/useStores';
import './../style.css';

interface IPopularEmbeddingProps {
  urlPattern: string;
  onChange: ({ }: IPlacementPageParams) => void;
  isInvalid: boolean;
  onBlur: () => void;
  errorMessage: string;
  checkedCountPage: CheckedCountPage;
  externalKey: string;
  url: string;
  operation: Operation;
  recommendationGenerationType: RecommendationGenerationType;
}

const PopularEmbedding: React.FC<IPopularEmbeddingProps> = ({
  urlPattern,
  onChange,
  isInvalid,
  onBlur,
  errorMessage,
  checkedCountPage,
  externalKey,
  url,
  operation,
  recommendationGenerationType,
}) => {
  const { feedProStore } = useStores() as {
    feedProStore: FeedProStore;
  };
  const { feed } = feedProStore;
  const options = [
    { label: 'На страницах, указанных вручную', value: 'ONE' },
    { label: 'На всех карточках товаров', value: 'MULTIPLE' },
  ];
  const optionFormingFeed = [
    {
      label: 'Из всего каталога',
      value: 'OVER_ALL',
    },
    {
      label: 'Из категории товара, на которой находится посетитель',
      value: 'BY_CATEGORY',
    },
  ];

  return (
    <div className="feed-editor-placement-pages">
      <NumberedBlock number={1}>
        <FormItem
          title="Где вы хотите разместить ленту?"
          noHTMLLabel
          noReservedPlaceForMessage
          className="feed-editor-placement-pages__form-item"
        />
        <RadioGroup
          className="feed-editor-placement-pages__radio-group"
          size="medium"
          onCheck={(checkedCountPage) => {
            onChange({ checkedCountPage: checkedCountPage as CheckedCountPage });
          }}
          items={options}
          checkedItem={checkedCountPage}
        />
      </NumberedBlock>

      {checkedCountPage === 'ONE' && (
        <NumberedBlock>
          <SignOfUnificationDropdown
            title="Укажите условия, на каких страницах показывать ленту"
            operation={operation}
            onChangeSeparator={(operation) => {
              onChange({
                urlPattern,
                url,
                checkedCountPage: checkedCountPage as CheckedCountPage,
                operation: operation as Operation,
              });
            }}
            url={url}
            onChangeDisplayConditions={(url) => {
              onChange({
                urlPattern,
                url,
                checkedCountPage: checkedCountPage as CheckedCountPage,
                operation,
              });
            }}
            withFlexDirectionColumn={false}
          />
        </NumberedBlock>
      )}

      <NumberedBlock number={2}>
        <FormItem
          title="Формировать ленту популярных товаров"
          noHTMLLabel
          noReservedPlaceForMessage
          className="feed-editor-placement-pages__form-item"
        />
        <RadioGroup
          className="feed-editor-placement-pages__radio-group"
          size="medium"
          onCheck={(check) => {
            feed.setRecommendationGenerationType(
              check as RecommendationGenerationType
            );
          }}
          items={optionFormingFeed}
          checkedItem={recommendationGenerationType}
        />
      </NumberedBlock>

      <NumberedBlock>
        <div className="feed-editor-placement-pages__title">
          ID категории
        </div>
        <div className="feed-editor-placement-pages__info">
          Этот код нужен, чтобы формировать ленту популярных товаров из категорий товара, где находится пользователь
        </div>
        <ToCopyArea
          text={
            '<script type="text/javascript">' +
            '(window["sberLeadApiQueue"] = window["sberLeadApiQueue"] || []).push(' +
            '{' +
            'method: "setCategoryInPage",' +
            'items: [' +
            '{ categoryId: <category_id> },' +
            '{ categoryId: <category_id> },' +
            '],' +
            `externalKey: "${externalKey}"` +
            '}' +
            ');' +
            '</script>'
          }
          height={'84px'}
          style={{
            color: '#0D7380',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        />
      </NumberedBlock>

      <NumberedBlock number={3}>
        <SetCatalogConfigLink
          title={
            checkedCountPage === 'ONE'
              ? `Вставьте ссылку на страницу, которая ${DROPDOWN_ITEMS.find(
                item =>
                  item.key === feed.get('displayConditions')?.operation
              )?.value
              } ${feed.get('displayConditions')?.url}`
              : `Вставьте ссылку на любую ${recommendationGenerationType === 'OVER_ALL' ? 'карточку товара' : 'страницу категории'}`
          }
          listItems={[{
            id: '1',
            text: 'Ссылка, соответствующая заданным в шаге 1 условиям, необходима для уточнения расположения ленты популярных товаров',
            dontAddSequenceNumber: true,
          }]}
          onChange={(urlPattern) => {
            onChange({
              urlPattern,
              url,
              checkedCountPage: checkedCountPage as CheckedCountPage,
              operation,
            });
          }}
          value={urlPattern}
          placeholder={'https://example.ru/product-1.html'}
          tooltipText={`Ссылка на страницу должна быть полной - включая «http://» или «https://»`}
          isInvalid={isInvalid}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
      </NumberedBlock>
    </div>
  );
};

export default PopularEmbedding;
