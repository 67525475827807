import { AxiosPromise } from 'axios';
import { actionGet } from '~/api/client';
import { STORAGE_NAME_FOR_APP_SETTINGS } from '~/constants';
import { useSessionStorageController } from '~/customHooks/useSessionStorageController';

interface IAppSettingsData {
  '@type': 'SettingsValue';
  settingsCode: string;
  settingsPath: string;
  settingsHash: string;
  settingsValue: any;
  settingsDomain: string;
  settingsItems: IAppSettingsData[];
}

type AppSettingsResponse = IResponse<IAppSettingsData[]>;

/**
 * Хук позволяет получить значение из параметра настройки приложения.
 * В вызываемый метод getAppSetting нужно передать settingsCode параметра.
 * settingsCode можно передать в виде массива если необходимо получить сразу несколько значений
 *
 * Возвращает Promise в который передает значение нужного параметра
 * (или массив значений)
 *
 * useAppSettings()
 *  .getAppSetting(settingsCode)
 *  .then(settingsValue => {...}));
 *
 * Настройки хранятся в SessionStorage,
 * запрашиваются по api только когда их нет в сессии
 *
 * @returns settingsValue: any | any[]
 */
export const useAppSettings = () => {
  const AppSettingsApi = {
    getAllAppSettingData: (): AxiosPromise<AppSettingsResponse> =>
      actionGet(`/admin/SettingsUI`, {}),
  };

  const sessionStorge = useSessionStorageController(
    STORAGE_NAME_FOR_APP_SETTINGS
  );

  const findSetting = (
    settings: IAppSettingsData[],
    settingsCode: string | string[]
  ): any =>
    settings.find(
      (setting: IAppSettingsData) => setting.settingsCode === settingsCode
    )?.settingsValue ?? null;

  const packagingSetting = (
    settings: IAppSettingsData[],
    settingsCode: string | string[]
  ): any => {
    if (Array.isArray(settingsCode)) {
      return settingsCode.map(code => findSetting(settings, code));
    }

    return findSetting(settings, settingsCode);
  };

  const runCallback = (
    callback: (settingValue: any) => void,
    settings: IAppSettingsData[],
    settingsCode: string | string[]
  ): void => {
    callback(packagingSetting(settings, settingsCode));
  };

  const returnAppSetting = (
    callback: (settingValue: any) => void,
    settingsCode: string | string[]
  ) => {
    if (sessionStorge.has()) {
      runCallback(callback, sessionStorge.get(), settingsCode);

      return;
    }
    AppSettingsApi.getAllAppSettingData().then((response) => {
      const settings: IAppSettingsData[] = response.data.payload;
      sessionStorge.add(settings);
      runCallback(callback, settings, settingsCode);
    });
  };

  /**
   * Возвращает Promise в который передает значение нужного параметра
   * useAppSettings()
   *  .getAppSetting('MAX_FEED_PRO_LABEL_COUNT')
   *  .then(settingsValue => {...}));
   *
   * @param settingsCode
   * @returns Promise
   */
  const getAppSetting = (settingsCode: string | string[]) =>
    new Promise((callback: (settingValue: any) => void) => {
      returnAppSetting(callback, settingsCode);
    });

  return {
    getAppSetting,
  };
};
