import { observer } from 'mobx-react';
import React from 'react';
import Tabs from '~/components/ui/Menu/Tabs';
import massMailingStore from '~/screens/MassMailingScreen/stores/massMailingStore';

const MassMailingConstructorTabs: React.FC = observer(() => {
  const { constructorStore, isButtonNextDisabled } = massMailingStore;
  const tabs: {
    key: string;
    title: string;
    disabled: boolean;
  }[] = [
    {
      key: 'TabParameters',
      title: '1. Параметры',
      disabled: false,
    },
    {
      key: 'TabConstructor',
      title: '2. Дизайн и отправка',
      disabled: isButtonNextDisabled,
    },
  ];

  return (
    <Tabs
      items={tabs}
      value={constructorStore.currentStep}
      onChange={step =>
        constructorStore.changeCurrentStep(step as TMassMailingConstructorSteps)
      }
    />
  );
});

export default MassMailingConstructorTabs;
