import { observer } from 'mobx-react';
import React, { useState } from 'react';
import BlankScreenMessage from '~/components/generic/BlankScreenMessage';
import { Video } from '~/components/generic/Video';
import Button from '~/components/ui/Button';
import Drawer from '~/components/ui/Drawer';
import Message from '~/components/ui/Message';
import FeedConstructorTable from '~/screens/FeedLiteScreen/components/FeedConstructor/StepFeedProduct/FeedConstructorTable';
import FeedConstuctorAddProduct from '~/screens/FeedLiteScreen/components/FeedConstructor/StepFeedProduct/FeedConstuctorAddProduct';
import { FeedLiteStore } from '~/screens/FeedLiteScreen/store/feedLiteStore';
import useStores from '~/utils/useStores';
import './style.css';

const StepFeedProduct: React.FC = observer(() => {
  const { feedLiteStore } = useStores() as {
    feedLiteStore: FeedLiteStore;
  };
  const { getLength, isOpenDrawen, setIsOpenDrawen } = feedLiteStore;
  const [isMessageOpen, setIsMessageOpen] = React.useState<boolean>(false);
  const [picture, setPicture] = useState<string>(null);

  const handleApply = () => {
    setIsMessageOpen(false);
    setIsOpenDrawen({
      create: false,
      update: false,
    });
    setPicture(null);
  };

  const isShowListCreatedFeeds = getLength > 0;

  return (
    <>
      {isShowListCreatedFeeds && <FeedConstructorTable />}
      {!isShowListCreatedFeeds && (
        <BlankScreenMessage
          title={
            'Добавьте от 1 до 30 товаров, чтобы создать ленту рекомендаций'
          }
          action={(
            <>
              <Button
                type="main"
                onClick={() => {
                  setIsOpenDrawen({
                    create: true,
                    update: false,
                  });
                }}
                icon={'Plus'}
              >
                Добавить товар
              </Button>
              <br />
              <br />
              <Video
                title="Как создать ленту Lite?"
                content="Мы подготовили подробную видеоинструкцию, с помощью которой вы легко настроите любую ленту"
                link="https://www.youtube.com/embed/GbVIFtvbIU8"
                previewImage="/assets/images/videoPreview/CreateFeed.png"
              />
            </>
          )}
        />
      )}
      <Drawer
        visible={isOpenDrawen.create || isOpenDrawen.update}
        onClose={() => {
          setIsMessageOpen(true);
        }}
        title={
          isOpenDrawen.create ? 'Добавление товара' : 'Редактировать товар'
        }
        size={'x-small'}
      >
        <div className="feed-constructor_drawer-container">
          <div className="feed-constructor_drawer-wrapper">
            <FeedConstuctorAddProduct
              picture={picture}
              setPicture={setPicture}
            />
          </div>
        </div>
        {isMessageOpen && (
          <Message
            className="feed-screen__message-box"
            showIcon={false}
            onExit={() => {
              setIsMessageOpen(false);
            }}
            onClose={() => {
              setIsMessageOpen(false);
            }}
            onApply={handleApply}
            title={
              isOpenDrawen.update
                ? 'Прекратить редактирование товара?'
                : 'Прекратить добавление товара?'
            }
            textTop={
              isOpenDrawen.update
                ? 'При продолжении операции изменения не сохранятся.'
                : 'При продолжении операции изменения и данные не сохранятся.'
            }
            visible={isMessageOpen}
            applyText={
              isOpenDrawen.update
                ? 'Прекратить редактирование'
                : 'Прекратить добавление'
            }
            cancelText={'Отмена'
            }
          />
        )}
      </Drawer>
    </>
  );
});

export default StepFeedProduct;
