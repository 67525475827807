import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import { SwitchUI } from '~/components/SwitchUI';
import Button from '~/components/ui/Button';
import Message from '~/components/ui/Message';
import { Instructions } from '~/screens/LeadsSettingsScreen/Instructions/Instructions';
import { SettingsItem } from '~/screens/LeadsSettingsScreen/SettingsItem';
import { PlugSberCRM } from '~/screens/LeadsSettingsScreen/sberCRM/PlugSberCRM';
import { SberCRMInstructions } from '~/screens/LeadsSettingsScreen/sberCRM/SberCRMInstructions';
import { LeadsStore } from '~/stores/leadsStore';
import useStores from '~/utils/useStores';

export const SberCRMSettings = observer(() => {
  const { leadsStore } = useStores() as { leadsStore: LeadsStore };
  const { leadSettings } = leadsStore;

  const isSberCRMLoading = leadSettings.get('isSberCRMLoading');
  const isSberCRMSet = leadSettings.get('isSberCRMSet');
  const sberCRMError = leadSettings.get('sberCRMError');

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isDisablePopupOpen, setIsDisablePopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    !isMounted && leadsStore.leadSettings.getSberCRMIntegrationState();
  }, [isMounted]);

  const isSuccessShow = isSberCRMSet && !isSberCRMLoading && !sberCRMError;
  const isPlugFormShow = !isSberCRMSet && !isSberCRMLoading && !sberCRMError;

  const disableSberCRM = async () => {
    await leadSettings.disableSberCRM();
    setIsDisablePopupOpen(false);
  };

  return (
    <SwitchUI elementCode={'LeadSettings#SberCRM'}>
      <>
        <div className="leads-settings__inner-container">
          {isSberCRMLoading && <Loading status={'Loading'} onClick={() => null} />}
          {sberCRMError && (
            <Loading status={'Error'} onClick={() => window.location.reload()} />
          )}
          {isPlugFormShow && (
            <>
              <h3 className="leads-settings__description">
                Для того, чтобы получать заявки в «Sber CRM», вам нужно:
              </h3>
              <SberCRMInstructions />
              <PlugSberCRM />
              <Instructions type={'sberSRM'} />
            </>
          )}
          {isSuccessShow && (
            <SettingsItem
              title="Ура! Теперь вы можете получать заявки с виджетов прямо в SberCRM! "
              Content={(
                <Button
                  className="tgm-settings__content-btn"
                  type="grey"
                  onClick={() => setIsDisablePopupOpen(true)}
                  block
                >
                  Отключить сбор заявок в SberCRM
                </Button>
              )}
              isSuccess
            />
          )}
        </div>
        {isDisablePopupOpen && (
          <Message
            onApply={disableSberCRM}
            visible={isDisablePopupOpen}
            title="Отключить сбор заявок в SberCRM?"
            textTop={'При продолжении операции, заявки больше не будут приходить в личный кабинет SberCRM, пока Вы снова их не подключите.'}
            applyText={'Отключить'}
            cancelText={'Отмена'}
            onClose={() => setIsDisablePopupOpen(false)}
            showIcon={false}
          />
        )}
      </>
    </SwitchUI>

  );
});
