import { observer } from 'mobx-react';
import React from 'react';
import FormItem from '~/components/ui/FormItem';
import Input from '~/components/ui/Input';
import InputDropdown, {
  InputDropdownItem,
} from '~/components/ui/InputDropdown';
import styles from './style.module.css';

interface Props {
  title: string;
  operation: Operation;
  onChangeSeparator?: (text: string) => void;
  isDisplayConditionsInvalid?: boolean;
  onChangeDisplayConditions?: (text: string) => void;
  onBlur?: () => void;
  errorMessageSeparator?: string;
  url?: string;
  withFlexDirectionColumn?: boolean;
}

type InputDropdownItemWithText = InputDropdownItem<Operation> & {
  text: string;
};

export const listItems: InputDropdownItemWithText[] = [
  {
    key: 'CONTAINS',
    value: 'Если ссылки содержат',
    text:
      'Лента отобразится на всех страницах, в адресе которых содержится указанная часть ссылки',
  },
  {
    key: 'NOT_CONTAINS',
    value: 'Если ссылки не содержат',
    text:
      'Лента отобразится на всех страницах, в адресе которых не содержится указанная часть ссылки',
  },
  {
    key: 'START_WITH',
    value: 'Если ссылки начинаются с',
    text:
      'Лента отобразится на всех страницах, адрес которых начинается с указанной части ссылки',
  },
  {
    key: 'EQUAL',
    value: 'Если ссылка соответствует',
    text:
      'Лента отобразится на странице, адрес которой полностью соответствует указанной ссылке',
  },
];

const SignOfUnificationDropdown: React.FC<Props> = observer(
  ({
    title,
    operation,
    onChangeSeparator,
    isDisplayConditionsInvalid,
    onChangeDisplayConditions,
    onBlur,
    errorMessageSeparator,
    url = '',
    withFlexDirectionColumn = false,
  }) => {
    const description = listItems.find((item) => {
      return operation && item.key === operation;
    });

    const inputPlaceholder =
      operation === 'EQUAL'
        ? 'https://example.ru/promo-offer?id=1'
        : operation === 'CONTAINS'
        ? 'promo-offer'
        : operation === 'NOT_CONTAINS'
        ? 'unwanted-category'
        : operation === 'START_WITH'
        ? 'https://www.example.ru/basket/'
        : '';

    return (
      <>
        <div
          className={styles.title_dropdown}
        >
          {title}
        </div>
        <div
          className={
            withFlexDirectionColumn
              ? styles.input_dropdown_wrapper_colomn
              : styles.input_dropdown_wrapper
          }
        >
          <InputDropdown<IScenarioStepURLOperation>
            className={styles.input_dropdown}
            size="large"
            theme="light"
            overlayPlacement="bottom"
            selectedKey={operation}
            items={listItems}
            onChange={onChangeSeparator}
          />

          <div className={styles.input_url_wrapper}>
            <Input
              failure={isDisplayConditionsInvalid}
              value={url}
              size="large"
              onBlur={onBlur}
              onChangeValue={onChangeDisplayConditions}
              placeholder={inputPlaceholder}
            />
            <FormItem
              failure={isDisplayConditionsInvalid}
              message={errorMessageSeparator}
              noReservedPlaceForMessage
            />
          </div>
        </div>
        <div className={styles.separator_description}>
          <span>«{description?.value}».</span> {description?.text}
        </div>
      </>
    );
  }
);

export default SignOfUnificationDropdown;
