import { inject, observer } from 'mobx-react';
import { Component, ReactNode } from 'react';
import { AuthStore } from '~/stores/authStore';

interface Props {
  authStore?: AuthStore;
  to: IRightCode | IRightCode[];
  message?: ReactNode;
  children: ReactNode;
}

@inject('authStore')
@observer
class Access extends Component<Props> {
  render() {
    const { authStore, to, children, message } = this.props;

    const user = authStore.currentUser;
    const codes = Array.isArray(to) ? to : [to];
    const hasAccess = codes.some(code => user.hasRightTo(code));

    // TODO: Make this component ready to receive children as a function
    // const correspondRights = codes.filter(code => user.hasRightTo(code));

    if (!hasAccess) {
      return message ? message : null;
    }

    return children;
  }
}

export default Access;
