import React from 'react';
import styles from './styles.module.css';

export const ProductItemElement = () => {

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>Блок Item</div>
      <div className={styles.title}>Товары отобразятся автоматически</div>
      <div className={styles.desc}>
        Вы можете посмотреть как отображаются ваши товары по кнопке
        «Предпросмотр». Если каталог товаров не успеет загрузиться, вместо ваших
        товаров будут наши примеры.
      </div>
    </div>
  );
};
