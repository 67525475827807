import { action, observable } from 'mobx';
import Store from '~/stores/store';
import logger from '~/utils/logger';

const log = logger.register('MediaQuery', 1);

const switchLog = (size: string, flag: boolean) => {
  log(flag === true ? `Switched to "${size}"` : `Switched from "${size}"`);
};

export class MediaQueriesStore extends Store {
  @observable xs = false;
  @observable sm = false;
  @observable md = false;
  @observable lg = false;
  @observable xl = false;
  @observable xxl = false;
  @observable mobile = false;
  @observable notMobile = false;
  @observable min1400 = false;

  private queryLists = {
    xs: window.matchMedia('(max-width:767px)'),
    sm: window.matchMedia('(min-width:768px) and (max-width:1023px)'),
    md: window.matchMedia('(min-width:1024px) and (max-width:1263px)'),
    lg: window.matchMedia('(min-width:1264px) and (max-width:1423px)'),
    xl: window.matchMedia('(min-width:1424px) and (max-width:1853px)'),
    xxl: window.matchMedia('(min-width:1853px)'),
    mobile: window.matchMedia('(max-width:767px)'),
    notMobile: window.matchMedia('(min-width:768px)'),
    min1400: window.matchMedia('(min-width:1400px)'),
  };

  constructor() {
    super();

    this.initMediaQueryListening();
  }

  @action
  initMediaQueryListening = () => {
    this.xs = this.queryLists.xs.matches;
    this.sm = this.queryLists.sm.matches;
    this.md = this.queryLists.md.matches;
    this.lg = this.queryLists.lg.matches;
    this.xl = this.queryLists.xl.matches;
    this.xxl = this.queryLists.xxl.matches;
    this.mobile = this.queryLists.mobile.matches;
    this.notMobile = this.queryLists.notMobile.matches;
    this.min1400 = this.queryLists.min1400.matches;

    this.queryLists.xs.addListener((ev) => {
      this.xs = ev.matches;
      switchLog('xs', ev.matches);
    });

    this.queryLists.sm.addListener((ev) => {
      this.sm = ev.matches;
      switchLog('sm', ev.matches);
    });

    this.queryLists.md.addListener((ev) => {
      this.md = ev.matches;
      switchLog('md', ev.matches);
    });

    this.queryLists.xl.addListener((ev) => {
      this.xl = ev.matches;
      switchLog('xl', ev.matches);
    });

    this.queryLists.xxl.addListener((ev) => {
      this.xxl = ev.matches;
      switchLog('xxl', ev.matches);
    });

    this.queryLists.mobile.addListener((ev) => {
      this.mobile = ev.matches;
      switchLog('mobile', ev.matches);
    });

    this.queryLists.notMobile.addListener((ev) => {
      this.notMobile = ev.matches;
      switchLog('notMobile', ev.matches);
    });

    this.queryLists.min1400.addListener((ev) => {
      this.min1400 = ev.matches;
      switchLog('min1400', ev.matches);
    });

  }

}

export default new MediaQueriesStore();
