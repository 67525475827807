import cx from 'classnames';
import React, { Component } from 'react';
import Access from '~/components/Access';
import Title from '~/components/Title';
import OldPageHeader from '~/components/__deprecated/OldPageHeader';

interface Props {
  className?: string;
  availableWithRule?: IRightCode | IRightCode[];
  title?: React.ReactNode;
  titleExtra?: React.ReactNode;
  titleOnBack?: (e: React.MouseEvent<HTMLDivElement>) => void;
  documentTitle?: string;
}

class OldScreen extends Component<Props> {
  renderTitle = () => {
    const {
      title,
      titleExtra,
      titleOnBack,
    } = this.props;

    if (!title) return null;

    return (
      <OldPageHeader
        title={title}
        extra={titleExtra}
        onBack={titleOnBack}
      />
    );
  }

  render() {
    const {
      className,
      availableWithRule,
      children,
      documentTitle,
    } = this.props;

    const screen = (
      <div className={cx('screen', className)}>
        <Title>{documentTitle}</Title>
        {this.renderTitle()}
        {children}
      </div>
    );

    if (availableWithRule) {
      return (
        <Access
          to={availableWithRule}
          message="У вас нет доступа к соответствующему экрану"
        >
          {screen}
        </Access>
      );
    }

    return screen;
  }
}

export default OldScreen;
