import React from 'react';
import './style.css';

interface IDashProps {
  className?: string;
}

const Dash: React.FC<IDashProps> = ({ className }) => (
  <div className={`component-ui__dash ${className ? className : ''}`} />
);

export default Dash;
