import React from 'react';
import QRCode from 'react-qr-code';
import Icon from '~/components/Icon';
import NumberedBlock from '~/components/generic/NumberedBlock';
import '../style.css';

const Mobile: React.FC<{ url: string }> = ({ url }) => {
  return (
    <>
      <NumberedBlock number={2}>
        <div className="feed-editor-step-selection-no-frame-inst__parent-block">
          <div className="feed-editor-step-selection-no-frame-inst__child-block">
            <h2 className="feed-editor-instructions__step-title">
              Отсканируйте QR-код камерой смартфона
            </h2>
            <div className="feed-editor-instructions__step-info">
              Чтобы перейти на мобильную версию вашего сайта с возможностью
              выбора расположения.
            </div>
          </div>
          <div className="feed-editor-step-selection-no-frame-inst__child-block">
            <QRCode value={url} size={116} />
          </div>
        </div>
      </NumberedBlock>
      <NumberedBlock number={3}>
        <div className="feed-editor-step-selection-no-frame-inst__parent-block">
          <div className="feed-editor-step-selection-no-frame-inst__child-block">
            <h2 className="feed-editor-instructions__step-title">
              Выберите место для блока с лентой
            </h2>
            <div className="feed-editor-instructions__step-info">
              Пролистните страницу до нужного места так, чтобы голубой курсор
              справа указывал на то место, где вы хотите расположить ленту. В
              этом месте появится синяя полоска. Если они не появляются, значит,
              это место не подходит для встраивания. Если всё хорошо, нажмите на
              эту область, и в этом месте появится блок с примером ленты
              рекомендаций. Для перехода к следующему шагу нажмите «Применить».
            </div>
          </div>
          <div className="feed-editor-step-selection-no-frame-inst__child-block">
            <Icon
              className="feed-editor-instructions__step-img"
              name="FeedPlacementMobile2"
            />
          </div>
        </div>
      </NumberedBlock>
    </>
  );
};

export default Mobile;
