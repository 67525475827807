import React from 'react';
import { statusItems } from '~/screens/SettingsScreen/Connection/SettingsStatusBar/constants';
import './style.css';

interface ISettingsStatusBarProps {
  isScriptInstalled: boolean;
}

const SettingsStatusBar: React.FC<ISettingsStatusBarProps> = ({ isScriptInstalled }) => {
  return (
    <div className="settings-status-bar__wrapper">
      {isScriptInstalled ? statusItems.READY : statusItems.INSTALL_CODE}
    </div>
  );
};

export default SettingsStatusBar;
