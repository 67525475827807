import React from 'react';
import Icon from '~/components/Icon';
import Checkbox from '~/components/ui/CheckBox';
import './style.css';

interface IContentMenuItemProps {
  menuKey: StepDesignMenuElements;
  name: string;
  checked: boolean;
  onCheck: (status: boolean) => void;
  onEdit: (menuKey: StepDesignMenuElements) => void;
  isShowMoveIcon?: boolean;
}

const ContentMenuItem: React.FC<IContentMenuItemProps> = ({
  menuKey,
  name,
  checked,
  onCheck,
  onEdit,
  isShowMoveIcon = false,
}) => {

  return (
    <div className="feed-editor-appearance-tools-content__menu-item">
      {isShowMoveIcon && (
        <Icon name="Move" className="feed-editor-appearance-tools-content-menu-item__icon-move" />
      )}
      <div className="feed-editor-appearance-tools-content-menu-item__group">
        <Checkbox
          className={'feed-editor-appearance-tools-content-menu-item__checkbox'}
          checked={checked}
          onCheck={onCheck}
        />
        <div
          className={`feed-editor-appearance-tools-content-menu-item__name ${checked ? '' : '__disable'}`}
          onClick={() => onEdit(menuKey)}
        >
          {name}
        </div>
        <Icon
          name={'Pencil'}
          className={'label-color-editor__icon'}
          onClick={() => onEdit(menuKey)}
        />
      </div>
    </div>
  );
};

export default ContentMenuItem;
